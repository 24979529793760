/* eslint-disable @typescript-eslint/no-non-null-assertion,no-await-in-loop,no-loop-func */
import React, { useState, useEffect } from 'react';

import { Form } from 'antd';

import { ICreateKitItem } from 'api/kitItems';
import KitItemModal from 'components/KitItemModal';
import { IKitItem, useKitItems } from 'context/kitItems';
import { useProjectKitItems } from 'context/projectKitItems';
import { useProjects } from 'context/projects';
import { IUpload, useUploads } from 'context/uploads';
import { uploadFileToS3, waitForUploadedFile } from 'utils/fileUploading';
import { NewUploadEntry, UploadEntry } from 'components/KitItemModal/types';
import Notification from 'utils/notification';

interface IUseKitItemsModalProps {
  fetchKitItems?: boolean;
}

const useKitItemsModal = ({ fetchKitItems = true }: IUseKitItemsModalProps) => {
  const { project } = useProjects();
  const { createUpload, getUpload, deleteUpload, updateUpload } = useUploads();
  const { createKitItem, updateKitItem, deleteKitItem, kitItems, getKitItems, getKitItem } = useKitItems();
  const { createProjectKitItem, deleteProjectKitItem, getProjectKitItems } = useProjectKitItems();
  const [form] = Form.useForm();
  const [modalVisible, setModalVisible] = useState(false);
  const [modalPopulated, setModalPopulated] = useState(false);
  const [selectedKitItem, setSelectedKitItem] = useState<IKitItem | null>(null);
  const [working, setWorking] = useState(false);

  const inProjectView = typeof project === 'object' && project !== null && Object.keys(project).length > 0;

  useEffect(() => {
    if (fetchKitItems) {
      getKitItems();
    }
  }, []);

  const openModal = () => {
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
    form.resetFields();
    setSelectedKitItem(null);
  };

  const closeCallback = () => {
    setWorking(false);
    setModalPopulated(true);
    closeModal();
  };

  const uploadNewFiles = async (kitItemId: string, files: UploadEntry[]): Promise<boolean> => {
    const newFiles = files.filter((file) => file.status === 'new') as NewUploadEntry[];

    let hasUploadErrors = false;

    for (const fileInfo of newFiles) {
      const payload = {
        parentId: kitItemId,
        parentType: 'kitItem',
        fileName: fileInfo.file.name,
        fileType: fileInfo.file.type,
        locale: fileInfo.locale,
        tag: fileInfo.tag,
        textConfig: fileInfo.textConfig,
      };

      const upload = await new Promise<IUpload | null>((resolve) => {
        createUpload(payload, undefined, (newUpload) => {
          resolve(newUpload);
        });
      });

      if (upload) {
        const uploadError = await uploadFileToS3(upload.uploadInfo!, fileInfo.file);

        if (uploadError) {
          Notification({
            duration: 7,
            type: 'error',
            message: 'File upload failed',
            description: uploadError,
          });

          return true;
        }

        await waitForUploadedFile(async () => {
          const refreshedUpload = await new Promise<IUpload>((resolve) => {
            getUpload(upload.id, undefined, (item) => {
              resolve(item!);
            });
          });

          const isUploadError = refreshedUpload.status === 'error';

          hasUploadErrors = hasUploadErrors || isUploadError;

          if (isUploadError) {
            Notification({
              duration: 7,
              type: 'error',
              message: 'File upload failed',
              description: refreshedUpload.notes!,
            });
          }

          return refreshedUpload.status === 'ready' || isUploadError;
        });
      } else {
        hasUploadErrors = true;
      }
    }

    return hasUploadErrors;
  };

  const handleCreateKitItem = (data: ICreateKitItem | null, files: UploadEntry[], kitItemId?: string) => {
    setWorking(true);

    // Update existing kit item mode
    if (kitItemId) {
      // we're only linking an existing item to the project
      // no new kit item is being created
      createProjectKitItem({ projectId: project!.id, kitItemId, viewOrder: 0 }, async () => {
        getProjectKitItems(project!.id, closeCallback);
      });

      return;
    }

    // Crete new kit item mode
    const itemCreatedCallback = async (newItem: IKitItem | null): Promise<void> => {
      if (newItem) {
        await new Promise((resolve) => {
          createProjectKitItem({ projectId: project!.id, kitItemId: newItem.id, viewOrder: 0 }, () => {
            closeCallback();
            resolve(1);
          });
        });
      } else {
        // an error occurred
        setWorking(false);
      }
    };

    if (data) {
      // Callback that runs after creation of kit items.
      const uploadFilesCallback = async (newKitItem: IKitItem | null): Promise<void> => {
        const hasUploadErrors = await uploadNewFiles(newKitItem!.id!, files);

        // If there are upload errors, re-open the newly created item in edit mode.
        if (hasUploadErrors) {
          setWorking(false);

          // Reload the new kit item with hydrated uploads, in case some files
          // were actually uploaded.
          const hydratedKitItem = await new Promise<IKitItem | null>((r) => {
            getKitItem(newKitItem!.id, { hydration: ['uploads'] }, (item) => {
              r(item);
            });
          });

          if (hydratedKitItem) {
            setSelectedKitItem(hydratedKitItem);
          }

          return;
        }

        if (inProjectView) {
          await itemCreatedCallback(newKitItem);
        } else {
          await closeCallback();
        }
      };

      createKitItem(data, uploadFilesCallback);
    }
  };

  const handleEditKitItem = async (kitItemId: string, data: ICreateKitItem, files: UploadEntry[]) => {
    setWorking(true);

    const kitItemSuccessfullyUpdated = await new Promise<boolean>((resolve) => {
      updateKitItem(kitItemId, data, (updatedItem) => {
        resolve(updatedItem !== null);
      });
    });

    // If for some reason kit item update failed, exit now.
    // Don't try to upload files and don't close the modal.
    if (!kitItemSuccessfullyUpdated) {
      setWorking(false);
      return;
    }

    const hasFileUploadErrors = await uploadNewFiles(kitItemId, files);

    const filesToDelete = files.filter((file) => file.status === 'deleted');

    const filesToUpdate = files.filter(
      (file) =>
        file.status === 'uploaded' &&
        (file.locale !== file.originalUpload!.locale ||
          file.tag !== file.originalUpload!.tag ||
          JSON.stringify(file.textConfig ?? '') !== JSON.stringify(file.originalUpload!.textConfig ?? ''))
    );

    let hasFileDeleteErrors = false;

    if (filesToDelete.length > 0) {
      for (const file of filesToDelete) {
        await deleteUpload(file.originalUpload!.id, undefined, (affectedItem) => {
          hasFileDeleteErrors = hasFileDeleteErrors || affectedItem === null;
        });
      }
    }

    let hasFileUpdateErrors = false;

    if (filesToUpdate.length > 0) {
      for (const file of filesToUpdate) {
        await updateUpload(
          file.originalUpload!.id,
          {
            locale: file.locale,
            tag: file.tag,
            textConfig: file.textConfig,
          },
          undefined,
          (affectedItem) => {
            hasFileUpdateErrors = hasFileUpdateErrors || affectedItem === null;
          }
        );
      }
    }

    if (hasFileUploadErrors || hasFileDeleteErrors || hasFileUpdateErrors) {
      setWorking(false);
      return;
    }

    closeCallback();
  };

  /* ----------------------- FOR EXPORT ------------------------- */

  const onCreateKitItem = () => {
    setModalPopulated(false);
    setSelectedKitItem(null);
    form.setFieldsValue({});
    openModal();
  };

  const onEditKitItem = async (kitItem: IKitItem) => {
    // Reload kit item with hydrated uploads
    const hydratedKitItem = await new Promise<IKitItem>((resolve) => {
      getKitItem(kitItem.id, { hydration: ['uploads'] }, (item) => {
        resolve(item!);
      });
    });

    setModalPopulated(false);
    setSelectedKitItem(hydratedKitItem);
    form.setFieldsValue(hydratedKitItem);
    openModal();
  };

  const onDeleteKitItem = (id: string, isProjectKitItem = false) => {
    setModalPopulated(false);
    setWorking(true);

    if (isProjectKitItem) {
      deleteProjectKitItem(id, async () => {
        getProjectKitItems(project!.id, closeCallback);
      });
    } else {
      deleteKitItem(id, closeCallback);
    }
  };

  const Modal = (
    <KitItemModal
      form={form}
      projectId={inProjectView ? project!.id : undefined}
      kitItem={selectedKitItem}
      kitItems={kitItems}
      visible={modalVisible}
      onOk={selectedKitItem ? (data, files) => handleEditKitItem(selectedKitItem.id, data, files) : handleCreateKitItem}
      onCancel={closeCallback}
      working={working}
      standalone={!inProjectView}
      selectOnly={false}
    />
  );

  return { Modal, onCreateKitItem, onEditKitItem, onDeleteKitItem, modalPopulated };
};

export default useKitItemsModal;
