import { httpClient } from 'services/index';
import { PagingRequest } from 'utils/types';

export const getProjectAnalyteLinks = (projectId: string, paging: PagingRequest): Promise<any> =>
  httpClient
    .get('/projectAnalyteLinks', {
      params: { projectIds: projectId, ...paging },
      headers: { 'tasso-hydration': 'analyte' },
    })
    .then((_) => _.data);

export const getProjectAnalyteLink = (id: string): Promise<any> =>
  httpClient
    .get(`/projectAnalyteLinks/${id}`, { headers: { 'tasso-hydration': 'analyte' } })
    .then((_) => _.data.results);

export type CreateProjectAnalyteLinkPayload = {
  projectId: string;
  analyteId: string;
  viewOrder: number;
};
export const createProjectAnalyteLink = (data: CreateProjectAnalyteLinkPayload): Promise<any> =>
  httpClient
    .post('/projectAnalyteLinks', data, { headers: { 'tasso-hydration': 'analyte' } })
    .then((_) => _.data.results);

export type UpdateProjectAnalyteLinkPayload = {
  viewOrder: number;
};
export const updateProjectAnalyteLink = (id: string, data: UpdateProjectAnalyteLinkPayload): Promise<any> =>
  httpClient
    .patch(`/projectAnalyteLinks/${id}`, data, { headers: { 'tasso-hydration': 'analyte' } })
    .then((_) => _.data.results);

export const deleteProjectAnalyteLink = (id: string): Promise<any> =>
  httpClient.delete(`/projectAnalyteLinks/${id}`).then((_) => _.data.results);
