/* eslint-disable @typescript-eslint/no-non-null-assertion,no-await-in-loop,no-loop-func */
import React, { useState, useEffect } from 'react';

import { Form } from 'antd';

import KitItemModal from 'components/KitItemModal';
import { IKitItem, useKitItems } from 'context/kitItems';
import { UploadEntry } from 'components/KitItemModal/types';

interface IUseSelectKitItemsModalProps {
  fetchKitItems?: boolean;
  onSelectKitItem: (kitItemId: string) => void;
}

const useSelectKitItemsModal = ({ fetchKitItems = true, onSelectKitItem }: IUseSelectKitItemsModalProps) => {
  const { kitItems, getKitItems } = useKitItems();

  const [form] = Form.useForm();
  const [modalVisible, setModalVisible] = useState(false);
  const [modalPopulated, setModalPopulated] = useState(false);
  const [selectedKitItem, setSelectedKitItem] = useState<IKitItem | null>(null);
  const [working, setWorking] = useState(false);

  useEffect(() => {
    if (fetchKitItems) {
      getKitItems();
    }
  }, []);

  const openModal = () => {
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
    form.resetFields();
    setSelectedKitItem(null);
  };

  const closeCallback = () => {
    setWorking(false);
    setModalPopulated(true);
    closeModal();
  };

  /* ----------------------- FOR EXPORT ------------------------- */

  const openSelectModal = async () => {
    setModalPopulated(false);
    setSelectedKitItem(null);
    form.setFieldsValue(null);
    openModal();
  };

  const Modal = (
    <KitItemModal
      form={form}
      projectId={undefined}
      kitItem={selectedKitItem}
      kitItems={kitItems}
      visible={modalVisible}
      onOk={(_newKitItemData: any, _files: UploadEntry[], kitItemId: string) => {
        onSelectKitItem(kitItemId);
        closeCallback();
      }}
      onCancel={closeCallback}
      working={working}
      standalone={false}
      selectOnly
    />
  );

  return { Modal, openSelectModal, modalPopulated };
};

export default useSelectKitItemsModal;
