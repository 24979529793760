export const ROUTES = {
  login: '/login',
  projects: '/projects',
  settings: '/settings',
  logout: '/logout',
  resetPassword: '/reset-password',
  confirmPassword: '/confirm',
  patientSearch: '/patient-search',
  manifests: '/manifests',
};
