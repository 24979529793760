/* eslint-disable no-await-in-loop */
import React, { FC, createContext, useReducer } from 'react';

import { usersApi } from 'api';
import { CreateUserPayload } from 'api/users';
import { makeReducer, normalizePagingResponse } from 'utils/functions';
import { getErrorMessage } from 'utils/issues';
import Notification from 'utils/notification';
import { PagingInfo } from 'utils/types';

import { Context, State, GetUsersFunction, UpdateUserFunction } from './types';

const getInitialState = (): State => ({
  users: [],
  user: null,
  error: null,
  loading: false,
});

const UsersContext = createContext<Context>({
  ...getInitialState(),
  getUsers: async () => {},
  getUser: async () => {},
  createUser: async () => {},
  updateUser: async () => {},
  deleteUser: async () => {},
  resetIdentity: async () => {},
  resetTemporaryPassword: async () => {},
  resetUsers: () => {},
});

const UsersProvider: FC<any> = (props) => {
  const [state, setState] = useReducer(makeReducer<State>(), getInitialState());

  const getUsers: GetUsersFunction = async (params, callback?) => {
    const skipStateUpdate = params?.skipStateUpdate === true;

    if (!skipStateUpdate) {
      setState({ error: null, loading: true });
    }

    const { sortBy } = params;
    const isDescending = params.sortOrder !== 'asc';

    const query: Record<string, string> = {};

    if (typeof params.ids === 'string') {
      query.ids = params.ids;
    }

    if (typeof params.usernameLike === 'string') {
      query.usernameLike = params.usernameLike;
    }

    if (typeof params.firstNameLike === 'string') {
      query.firstNameLike = params.firstNameLike;
    }

    if (typeof params.lastNameLike === 'string') {
      query.lastNameLike = params.lastNameLike;
    }

    if (typeof params.roles === 'string') {
      query.roles = params.roles;
    }

    if (typeof params.isActive === 'boolean') {
      query.isActive = `${params.isActive}`;
    }

    try {
      const results: any = [];
      let paging: PagingInfo;

      if (query.ids) {
        const idList = query.ids === '' ? [] : query.ids.split(',');
        const promises: Promise<any>[] = [];

        for (let i = 0; i < idList.length; i += 50) {
          const idSubset = idList.slice(i, i + 50);
          promises.push(usersApi.getUsers({ ...query, ids: idSubset.join(',') }, { page: 1, pageLength: 50 }));

          if (promises.length === 5) {
            const data = await Promise.all(promises.splice(0, promises.length));
            results.push(...data.reduce((acc, val) => [...acc, ...val.results], []));
          }
        }

        if (promises.length > 0) {
          const data = await Promise.all(promises.splice(0, promises.length));
          results.push(...data.reduce((acc, val) => [...acc, ...val.results], []));
        }

        paging = {
          page: 1,
          pageLength: results.length,
          sortBy: 'createdAt',
          sortOrder: 'desc',
          totalCount: results.length,
        };
      } else {
        const data = await usersApi.getUsers(query, {
          page: params.page,
          pageLength: params.pageLength,
          sortBy,
          isDescending,
          includeTotalCount: params.includeTotalCount,
        });

        results.push(...data.results);
        paging = normalizePagingResponse(data.paging);
      }

      if (!skipStateUpdate) {
        setState({
          users: results,
        });
      }

      if (callback) {
        await callback(results, paging);
      }
    } catch (e) {
      const errorMsg = getErrorMessage(e);

      if (!skipStateUpdate) {
        setState({
          error: errorMsg,
        });
      }

      Notification({
        type: 'error',
        message: 'Cannot get users',
        description: errorMsg,
      });

      if (callback) {
        await callback(null, null);
      }
    }

    if (!skipStateUpdate) {
      setState({ loading: false });
    }
  };

  const getUser = async (id: string, callback?: any): Promise<void> => {
    try {
      setState({ error: null, loading: true });

      const user = await usersApi.getUser(id);

      setState({
        user,
        loading: false,
      });

      if (callback) {
        await callback(user);
      }
    } catch (e) {
      const errorMsg = getErrorMessage(e);

      setState({
        loading: false,
        error: errorMsg,
      });

      Notification({
        type: 'error',
        message: 'Cannot get user details',
        description: errorMsg,
      });
    }
  };

  const createUser = async (data: CreateUserPayload, callback?: any): Promise<void> => {
    try {
      setState({ error: null, loading: true });

      const user = await usersApi.createUser(data);

      setState({
        users: [...state.users, user],
        loading: false,
      });

      Notification({ message: 'User created', type: 'success', description: '' });

      if (callback) {
        await callback(user);
      }
    } catch (e) {
      const errorMsg = getErrorMessage(e);

      setState({
        loading: false,
        error: errorMsg,
      });

      Notification({
        type: 'error',
        message: 'Cannot create user',
        description: errorMsg,
      });
    }
  };

  const updateUser: UpdateUserFunction = async (id, data, callback) => {
    setState({ error: null, loading: true });

    try {
      const user = await usersApi.updateUser(id, data);

      setState({
        users: state.users.map((u) => (u.id === id ? user : u)),
      });

      Notification({ message: 'User updated', type: 'success', description: '' });

      if (callback) {
        await callback(user);
      }
    } catch (e) {
      const errorMsg = getErrorMessage(e);

      setState({
        error: errorMsg,
      });

      Notification({
        type: 'error',
        message: 'Cannot update user',
        description: errorMsg,
      });

      if (callback) {
        await callback(null);
      }
    } finally {
      setState({ loading: false });
    }
  };

  const resetIdentity = async (id: string, callback?: () => Promise<void>): Promise<void> => {
    try {
      setState({ error: null, loading: true });

      await usersApi.resetIdentity(id);

      setState({
        loading: false,
      });

      Notification({ message: 'User identity reset', type: 'success', description: '' });

      if (callback) {
        await callback();
      }
    } catch (e) {
      const errorMsg = getErrorMessage(e);

      setState({
        loading: false,
        error: errorMsg,
      });

      Notification({
        type: 'error',
        message: 'Cannot reset user',
        description: errorMsg,
      });
    }
  };

  const resetTemporaryPassword = async (id: string, callback?: () => Promise<void>): Promise<void> => {
    try {
      setState({ error: null, loading: true });

      await usersApi.resetTemporaryPassword(id);

      setState({
        loading: false,
      });

      Notification({ message: 'Temporary password reset', type: 'success', description: '' });

      if (callback) {
        await callback();
      }
    } catch (e) {
      const errorMsg = getErrorMessage(e);

      setState({
        loading: false,
        error: errorMsg,
      });

      Notification({
        type: 'error',
        message: "Cannot reset user's temporary password",
        description: errorMsg,
      });
    }
  };

  const resetUsers = (): void => {
    setState(getInitialState());
  };

  const deleteUser = async (id: string, callback?: any): Promise<void> => {
    try {
      setState({ error: null, loading: true });

      const user = await usersApi.deleteUser(id);

      setState({
        users: state.users.filter((u) => u.id !== id),
        loading: false,
      });

      Notification({ message: 'User deleted', type: 'success', description: '' });

      if (callback) {
        await callback(user);
      }
    } catch (e) {
      const errorMsg = getErrorMessage(e);

      setState({
        loading: false,
        error: errorMsg,
      });

      Notification({
        type: 'error',
        message: 'Cannot delete user',
        description: errorMsg,
      });
    }
  };

  return (
    <UsersContext.Provider
      value={{
        users: state.users,
        user: state.user,
        loading: state.loading,
        error: state.error,
        getUsers,
        getUser,
        createUser,
        updateUser,
        deleteUser,
        resetIdentity,
        resetTemporaryPassword,
        resetUsers,
      }}
      {...props}
    />
  );
};

const useUsers = (): Context => React.useContext(UsersContext);

export { UsersProvider, useUsers };
