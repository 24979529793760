import React, { FC, ReactNode } from 'react';

import { Collapse as ACollapse } from 'antd';

import { CaretRightOutlined } from 'components/icons';

interface ICollapsePanel {
  title: string | ReactNode;
  extra: any;
  content: ReactNode;
  key: any;
}

interface ICollapse {
  items: ICollapsePanel[];
  initiallyCollapsed: boolean;
}

const { Panel } = ACollapse;

const Collapse: FC<ICollapse> = ({ items, initiallyCollapsed = false }) => (
  <ACollapse
    className="Collapse"
    bordered={false}
    defaultActiveKey={initiallyCollapsed ? undefined : [items[0].key]}
    expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
  >
    {items.map((item) => (
      <Panel className="Collapse__Panel" header={item.title} extra={item.extra} key={item.key}>
        {item.content}
      </Panel>
    ))}
  </ACollapse>
);

export default Collapse;
