import React, { FC, useEffect } from 'react';

import { Table, Button, Card, Popconfirm } from 'antd';

import { EditOutlined, PlusCircleOutlined, DeleteOutlined } from 'components/icons';
import { Spinner } from 'components/index';
import { useKitItems, IKitItem } from 'context/kitItems';
import useKitItemsModal from 'hooks/useKitItemsModal';
import { KIT_ITEM_TYPES_LABELS, USER_ROLES } from 'constants/index';
import { useAuth } from 'context/auth';

const KitItemsContainer: FC = () => {
  const { profile } = useAuth();
  const isAdmin = (profile?.role || '') === USER_ROLES.internalAdmin;

  const { getKitItems, kitItems, loading: kitItemsLoading } = useKitItems();

  const {
    Modal: KitItemModal,
    onCreateKitItem,
    onEditKitItem,
    onDeleteKitItem,
  } = useKitItemsModal({
    fetchKitItems: false,
  });

  useEffect(() => {
    getKitItems();
  }, []);

  return (
    <Card style={{ height: `calc(100vh - 64px - 64px - 48px - 64px)`, overflow: 'auto' }}>
      {isAdmin && (
        <Button type="primary" icon={<PlusCircleOutlined />} onClick={onCreateKitItem} style={{ marginBottom: '10px' }}>
          New kit item
        </Button>
      )}
      {KitItemModal}
      <Spinner spinning={kitItemsLoading}>
        {Array.isArray(kitItems) && kitItems.length > 0 ? (
          <Table
            dataSource={[...kitItems]}
            columns={[
              {
                title: 'Key',
                dataIndex: 'key',
              },
              {
                title: 'Part number',
                dataIndex: 'partNumber',
                sorter: (a: IKitItem, b: IKitItem) => (a.partNumber || '').localeCompare(b.partNumber || ''),
              },
              {
                title: 'Lot number',
                dataIndex: 'lotNumber',
                sorter: (a: IKitItem, b: IKitItem) => (a.lotNumber || '').localeCompare(b.lotNumber || ''),
              },
              {
                title: 'Kit Item Type',
                dataIndex: 'kitItemType',
                sorter: (a: IKitItem, b: IKitItem) => (a.kitItemType || '').localeCompare(b.kitItemType || ''),
                render: (value: string) =>
                  value ? KIT_ITEM_TYPES_LABELS[value as keyof typeof KIT_ITEM_TYPES_LABELS] : 'None',
              },
              {
                title: 'Description',
                dataIndex: 'description',
                sorter: (a: IKitItem, b: IKitItem) => a.description.localeCompare(b.description),
              },
              isAdmin
                ? {
                    title: 'Actions',
                    render: (item: any) => (
                      <div>
                        <EditOutlined className="EditAction" onClick={() => onEditKitItem(item)} />
                        <Popconfirm
                          title="Are you sure you want to delete this kit item?"
                          okText="Yes"
                          cancelText="No"
                          onConfirm={() => onDeleteKitItem(item.id, false)}
                        >
                          <DeleteOutlined className="DeleteAction" style={{ marginLeft: '20px' }} />
                        </Popconfirm>
                      </div>
                    ),
                  }
                : {},
            ].filter((column) => Object.keys(column).length > 0)} // Hide empty columns if they exist
            rowKey="id"
            pagination={{
              defaultPageSize: 20,
              showSizeChanger: true,
              showTotal: (n) => `${n} kit item${n === 1 ? '' : 's'} in total`,
            }}
            showSorterTooltip={false}
          />
        ) : null}
      </Spinner>
    </Card>
  );
};

export default KitItemsContainer;
