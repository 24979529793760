import { DatePicker, Radio } from 'antd';
import React, { CSSProperties, useState } from 'react';
import moment from 'moment';

type PickerType = 'date' | 'month' | 'year';

interface Props {
  value: string | null;
  style?: CSSProperties;
  onChange: (date: string | null) => void;
}

const DatePickerWithType: React.FC<Props> = ({ value, style, onChange }) => {
  const [pickerType, setPickerType] = useState<PickerType>('date');

  const handleChange = (v: moment.Moment | null): void => {
    if (!v) {
      onChange(null);
      return;
    }

    let date: string | null;

    if (pickerType === 'date') {
      date = v.format('YYYY-MM-DD');
    } else if (pickerType === 'month') {
      date = v.format('YYYY-MM');
    } else if (pickerType === 'year') {
      date = v.format('YYYY');
    } else {
      date = null;
    }

    onChange(date);
  };

  const changePickerType = (v: PickerType): void => {
    setPickerType(v);
    handleChange(null);
  };

  const parseValue = (v: string | null): moment.Moment | undefined => {
    if (!v) {
      return undefined;
    }

    let date: moment.Moment | null;

    if (pickerType === 'date') {
      date = moment(v, 'YYYY-MM-DD', true);
    } else if (pickerType === 'month') {
      date = moment(v, 'YYYY-MM', true);
    } else if (pickerType === 'year') {
      date = moment(v, 'YYYY', true);
    } else {
      date = null;
    }

    return date && date.isValid() ? date : undefined;
  };

  return (
    <div style={style}>
      <Radio.Group
        style={{ width: '100%', marginBottom: 8 }}
        onChange={(e) => changePickerType(e.target.value)}
        value={pickerType}
        size="small"
      >
        <Radio.Button value="date" style={{ width: '33.3%', textAlign: 'center' }}>
          Date
        </Radio.Button>
        <Radio.Button value="month" style={{ width: '33.3%', textAlign: 'center' }}>
          Month
        </Radio.Button>
        <Radio.Button value="year" style={{ width: '33.3%', textAlign: 'center' }}>
          Year
        </Radio.Button>
      </Radio.Group>

      <div>
        <DatePicker
          style={{ width: '100%' }}
          picker={pickerType}
          value={parseValue(value)}
          onChange={handleChange}
          allowClear={false}
        />
      </div>
    </div>
  );
};

export default DatePickerWithType;
