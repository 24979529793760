/* eslint-disable no-await-in-loop */
import { Reducer } from 'react';

import {
  AdminPendingOrderStatuses,
  DEVICE_REPLACEMENT_REASONS,
  LEGACY_DEVICE_REPLACEMENT_REASONS,
  PendingOrderStatuses,
} from 'constants/index';
import { RequestConfig } from 'services';

import { Order } from 'context/orders';
import { isRequestCancelled } from 'services/httpClient';
import { ApiCall, PagingInfo, PagingParams, PagingResponse, UserRole } from './types';

export const wait = (ms: number): Promise<boolean> => new Promise((resolve) => setTimeout(() => resolve(true), ms));

export const makeReducer =
  <T>(): Reducer<T, Partial<T>> =>
  (current, next) => ({ ...current, ...next });

export const loadAllPages = async <R = any, P = any>(
  apiCall: ApiCall<P, R>,
  payload: P,
  paging: PagingParams = {},
  configOverride: RequestConfig = {}
): Promise<R[]> => {
  const results: R[] = [];

  const pageLength = paging.pageLength || undefined;
  const sortBy = paging.sortBy || undefined;
  const isDescending = paging.isDescending || undefined;
  let page = 1;
  let totalPages = -1;

  do {
    const data = await apiCall(
      payload,
      { page, pageLength, sortBy, isDescending, includeTotalCount: page === 1 },
      configOverride
    );

    if (data.isCancelledByClient) {
      return [];
    }

    if (data && data.paging && data.paging.totalCount) {
      const totalCount = parseInt(data.paging.totalCount, 10); // currently totalCount comes back as string (bug?)
      totalPages = Math.ceil(totalCount / data.paging.pageLength);
    }
    results.push(...data.results);
    page += 1;
  } while (page <= totalPages);

  return results;
};

/**
 * Resolves a batch of promises in parallel and aggregates the results into the provided array. This
 * function will return false if any of the promises resolves to an error indicating the request was
 * cancelled (axios `isCancel(error)`).
 *
 * @param promises Collection of promises to resolve
 * @param resultsCollection Array to push results into
 * @returns True if successful, false if the request was cancelled
 */
const resolvePromises = async <R extends any>(promises: Promise<R[]>[], resultsCollection: R[]): Promise<boolean> => {
  const resolvedData = await Promise.all(promises);
  for (let j = 0; j < resolvedData.length; j += 1) {
    if (isRequestCancelled(resolvedData[j])) {
      return false;
    }
    resultsCollection.push(...resolvedData[j]);
  }
  return true;
};

/**
 * Given a long list of ids, break it up into multiple groups (batches).
 * Each group means a separate HTTP request. At the end join all results
 * together into a single list of results.
 *
 * Send requests with 30 ids at a time. Core API allows at most 2,048 characters
 * in query strings, so it's important to stay below that limit to avoid errors.
 * UUID v4 length is 36 characters, so 36 * 30 = 1,080 characters.
 * With 29 comas that separate these ids the number becomes 1,109 characters.
 * The remanining 2,048 - 1,109 = 939 characters are available for other uses.
 */
export const loadByIdsInBatches = async <R extends any>(
  apiCall: ApiCall<any, R>,
  idParameterName: string,
  idParameterValues: string[],
  otherQueryStringParameters: Record<string, string | boolean | number> = {},
  packIntoQueryParameter = false,
  configOverride: RequestConfig = {}
): Promise<R[]> => {
  const idGroupSize = 30;

  const promises: Promise<R[]>[] = [];
  const results: R[] = [];

  for (let i = 0; i < idParameterValues.length; i += idGroupSize) {
    const idList = idParameterValues.slice(i, i + idGroupSize);

    const payload = { ...otherQueryStringParameters, [idParameterName]: idList.join(',') };
    const query = packIntoQueryParameter ? { query: payload } : payload;

    promises.push(
      loadAllPages<R, any>(
        apiCall,
        query,
        {
          pageLength: 1000,
          sortBy: 'createdAt',
          isDescending: true,
        },
        configOverride
      )
    );

    if (promises.length === 5) {
      if (!(await resolvePromises(promises, results))) {
        return []; // requests cancelled by client
      }
      // Reset the promises array so that `resolvePromises` doesn't repeat.
      promises.splice(0, promises.length);
    }
  }

  if (promises.length > 0) {
    if (!(await resolvePromises(promises, results))) {
      return []; // requests cancelled by client
    }
  }

  return results;
};

export const copyToClipboard = (str: string) => {
  const el = document.createElement('textarea');
  el.value = str;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};

export const normalizePagingResponse = (paging: PagingResponse): PagingInfo => {
  const result: PagingInfo = {
    page: paging.page,
    pageLength: paging.pageLength,
    sortBy: paging.sortBy,
    sortOrder: paging.isDescending ? 'desc' : 'asc',
    totalCount: typeof paging.totalCount === 'string' ? parseInt(paging.totalCount, 10) : paging.totalCount || -1,
  };

  return result;
};

export const isCustomerRole = (role: any): boolean => {
  const roles: UserRole[] = ['customerAdmin', 'customerOrganizer', 'customerSponsor'];

  return roles.includes(role);
};

type ReplacementReason = keyof typeof DEVICE_REPLACEMENT_REASONS;

export const getReplacementReason = (reason: string): string => {
  if (reason in LEGACY_DEVICE_REPLACEMENT_REASONS) {
    return LEGACY_DEVICE_REPLACEMENT_REASONS[reason as keyof typeof LEGACY_DEVICE_REPLACEMENT_REASONS];
  }

  const key = reason as ReplacementReason;
  if (DEVICE_REPLACEMENT_REASONS[key]) {
    // direct match
    return DEVICE_REPLACEMENT_REASONS[key] as string;
  }

  const allKeys = Object.keys(DEVICE_REPLACEMENT_REASONS).filter(
    (k) => typeof DEVICE_REPLACEMENT_REASONS[k as ReplacementReason] === 'object'
  );

  for (let i = 0; i < allKeys.length; i += 1) {
    if (key.startsWith(allKeys[i])) {
      const subkey = key.replace(`${allKeys[i]}_`, '');
      return (
        (DEVICE_REPLACEMENT_REASONS[allKeys[i] as ReplacementReason] as any[]).find((k) => k.value === subkey).label ||
        ''
      );
    }
  }

  return '';
};

/**
 * An order is considered "pending" until it reaches at least
 * the "readyToShip" status.
 */
export const isPendingOrder = (order: Order, isAdmin: boolean, isCustomerService: boolean): boolean =>
  isAdmin || isCustomerService ? AdminPendingOrderStatuses.has(order.status) : PendingOrderStatuses.has(order.status);

export const openDocument = (url: string, download = false): void => {
  const link = document.createElement('a');
  link.href = url;

  if (download) {
    link.download = url.split('?')[0].split('/').pop()!;
  } else {
    link.target = '_blank';
    link.rel = 'noopener';
  }

  link.click();
};
