import React, { FC, useEffect, useState } from 'react';
import { IDevice, useDevices } from 'context/devices';
import { useCustomers } from 'context/customers';
import { usePatients } from 'context/patients';
import { useProjects } from 'context/projects';
import { useFulfillmentOrders } from 'context/fulfillmentOrders';
import { Button, Divider, Form, Input, Row } from 'antd';
import { FulfillmentOrder } from '@tassoinc/core-api-client';
import DeviceList from './DeviceList';

const FulfillmentOrderSearch: FC = () => {
  const { devices, getDevices, resetDevices, loading: devicesLoading } = useDevices();
  const { customers, getCustomers, resetCustomers } = useCustomers();
  const { patients, resetPatients, getPatients, loading: patientsLoading } = usePatients();
  const { projects, getProjects, resetProjects } = useProjects();
  const { getFulfillmentOrders } = useFulfillmentOrders();
  const isSearching = patientsLoading || devicesLoading;
  const [form] = Form.useForm();
  const [searched, setSearched] = useState(false);

  useEffect(() => {
    getCustomers();
    getProjects(false);

    return () => {
      resetCustomers();
      resetPatients();
      resetProjects();
      resetDevices();
    };
  }, []);

  const stopSearch = () => {
    resetPatients();
    resetDevices();
    setSearched(false);
  };

  const resetForm = (): void => {
    resetPatients();
    resetDevices();
    form.resetFields();
    setSearched(false);
  };

  const findDevicesByFulfillmentOrderIds = async ({
    fulfillmentOrderId,
    thirdPartyId,
    orderId,
  }: {
    fulfillmentOrderId: string;
    thirdPartyId: string;
    orderId: string;
  }): Promise<IDevice[] | null> => {
    if (!fulfillmentOrderId && !thirdPartyId && !orderId) {
      return null;
    }
    const results: IDevice[] = [];

    const q: any = {};
    if (fulfillmentOrderId) {
      q.ids = [fulfillmentOrderId];
    }
    if (thirdPartyId) {
      q.thirdPartyIds = [thirdPartyId];
    }
    if (orderId) {
      q.orderIds = [orderId];
    }

    const orderIds: string[] = [];
    if (fulfillmentOrderId || thirdPartyId) {
      await getFulfillmentOrders(q, async (fulfillmentOrderResults: FulfillmentOrder[] | null) => {
        fulfillmentOrderResults?.forEach((fulfillmentOrder) => {
          orderIds.push(fulfillmentOrder.orderId);
        });

        if (orderId) {
          orderIds.push(orderId);
        }

        if (orderIds.length > 0) {
          await getDevices({ orderIds } as any, async (foundDevices: IDevice[]) => {
            results.push(...foundDevices);
          });
        }
      });
    } else {
      // If only orderId is being searched, just search devices and not fulfillmentOrders
      await getDevices({ orderIds: [orderId] } as any, async (foundDevices: IDevice[]) => {
        results.push(...foundDevices);
      });
    }

    return results;
  };

  const performSearch = async (values: any) => {
    if (isSearching) {
      return;
    }

    stopSearch();

    const foundDevices = await findDevicesByFulfillmentOrderIds({
      fulfillmentOrderId: values.fulfillmentOrderId.trim(),
      thirdPartyId: values.thirdPartyId.trim(),
      orderId: values.orderId.trim(),
    });

    if (!foundDevices) {
      setSearched(true);
      return;
    }

    const queryParams: any = {};
    const patientIds = foundDevices.map((d) => d.patientId).filter((id) => !!id);
    if (patientIds.length > 0) {
      const uniquePatientIds = [...new Set(patientIds)];
      queryParams.ids = uniquePatientIds.join(',');
    }

    if (Object.keys(queryParams).length > 0) {
      await getPatients(queryParams);
    }

    setSearched(true);
  };

  return (
    <div style={{ padding: '0 0 40px 0' }}>
      <Form
        form={form}
        size="middle"
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 12 }}
        layout="horizontal"
        initialValues={{
          fulfillmentOrderId: '',
          thirdPartyId: '',
          orderId: '',
        }}
        labelAlign="left"
        onFinish={performSearch}
      >
        <div>All ids are searched exactly.</div>
        <br />
        <Form.Item label="fulfillment order id">
          <Input.Group>
            <Row align="middle">
              <Form.Item name="fulfillmentOrderId" noStyle>
                <Input style={{ width: '70%' }} />
              </Form.Item>
            </Row>
          </Input.Group>
        </Form.Item>
        <Form.Item label="third party id">
          <Input.Group>
            <Row align="middle">
              <Form.Item name="thirdPartyId" noStyle>
                <Input style={{ width: '70%' }} />
              </Form.Item>
            </Row>
          </Input.Group>
        </Form.Item>
        <Form.Item label="order id">
          <Input.Group>
            <Row align="middle">
              <Form.Item name="orderId" noStyle>
                <Input style={{ width: '70%' }} />
              </Form.Item>
            </Row>
          </Input.Group>
        </Form.Item>
        <Form.Item colon={false} style={{ textAlign: 'right' }} labelCol={{ span: 4 }} label=" ">
          <div style={{ width: '70%' }}>
            <Button type="text" onClick={resetForm} style={{ marginRight: 10 }} htmlType="button">
              Reset
            </Button>
            <Button type="primary" htmlType="submit" loading={isSearching} disabled={isSearching}>
              Search
            </Button>
          </div>
        </Form.Item>
      </Form>

      {searched && <Divider />}

      {isSearching && (
        <h2>
          Searching...
          <Button style={{ marginLeft: 15 }} size="small" onClick={stopSearch}>
            Cancel
          </Button>
        </h2>
      )}
      {searched && !isSearching && (
        <h2>
          {devices.length > 50 ? (
            <>
              Showing only top 50 matches of {devices.length} device{devices.length === 1 ? '' : 's'} (refine your
              search)
            </>
          ) : (
            <>
              Found {devices.length} device{devices.length === 1 ? '' : 's'}
            </>
          )}
        </h2>
      )}
      {searched && devices.length > 0 && (
        <DeviceList
          patients={patients.slice(0, 50)}
          devices={devices.slice(0, 50)}
          projects={projects}
          customers={customers}
          loading={isSearching}
        />
      )}
    </div>
  );
};
export default React.memo(FulfillmentOrderSearch);
