import React, { FC, useEffect } from 'react';

import { Form, Input, Modal } from 'antd';

import { User } from 'context/users/types';

interface ContactValues {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  username: string;
}

interface IContactModal {
  form: any;
  contact: User | null;
  visible: boolean;
  loading: boolean;
  onOk: (contact: ContactValues) => void;
  onCancel: () => void;
}

const ContactModal: FC<IContactModal> = ({ form, contact, visible, loading, onOk, onCancel }) => {
  useEffect(() => {
    if (visible && !contact) {
      form.resetFields();
    }
  }, [visible]);

  const handleFormSubmit = () => {
    form
      .validateFields()
      .then((values: any) => {
        const phoneNumber = values.phoneNumber.replace(/[^\d]/g, '');
        onOk({ ...values, phoneNumber });
      })
      .catch((info: any) => {
        console.log('Validate Failed:', info);
      });
  };

  return (
    <Modal
      wrapClassName="Modal ContactModal"
      visible={visible}
      title={contact ? 'Edit Contact' : <>Create Contact</>}
      okText={contact ? 'Save' : 'Create'}
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={handleFormSubmit}
      confirmLoading={loading}
      centered
      width="360px"
      forceRender
    >
      <Form className="Form ContactModal__Form" form={form} layout="vertical" name="ContactModalForm" hideRequiredMark>
        <Form.Item
          name="firstName"
          label="First Name"
          rules={[{ required: false, message: 'Please input first name!' }]}
        >
          <Input placeholder="Enter first name" />
        </Form.Item>

        <Form.Item name="lastName" label="Last Name" rules={[{ required: true, message: 'Please input last name!' }]}>
          <Input placeholder="Enter last name" />
        </Form.Item>

        <Form.Item
          name="phoneNumber"
          label="Phone (e.g., 1-541-754-3010)"
          rules={[{ required: true, message: 'Please input a phone!' }]}
        >
          <Input placeholder="Enter phone" />
        </Form.Item>

        <Form.Item
          name="username"
          label="Username (email)"
          rules={[
            { type: 'email', message: 'Invalid email address!' },
            { required: true, message: 'Please input email address!' },
          ]}
        >
          <Input placeholder="Enter email" disabled={contact !== null} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ContactModal;
