/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useState } from 'react';

import { Input, Select, Divider } from 'antd';

import { PlusOutlined } from 'components/icons';
import { IDeviceLot } from 'context/deviceLots';

interface Props {
  lots: IDeviceLot[];
  selectedId: string;
  onAdd: (key: string) => Promise<void>;
  onSelect: (lotId: string) => void;
  loading: boolean;
  [key: string]: any;
}

export const DeviceLotDropdown: FC<Props> = ({ lots, selectedId, onAdd, onSelect, loading, ...otherProps }) => {
  const [newLotName, setNewLotName] = useState('');

  const handleAddLot = (e: any): void => {
    e.preventDefault();

    if (loading || !newLotName) {
      return;
    }

    onAdd(newLotName);
    setNewLotName('');
  };

  return (
    <Select
      loading={loading}
      showSearch
      value={selectedId || ''}
      filterOption={(input, option: any) => option.label.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0}
      style={{ width: 240 }}
      onSelect={(lotId: string) => onSelect(lotId)}
      placeholder="Select a lot"
      allowClear
      onClear={() => onSelect('')}
      dropdownRender={(menu) => (
        <div>
          {menu}
          <Divider style={{ margin: '4px 0' }} />
          <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
            <Input style={{ flex: 'auto' }} value={newLotName} onChange={(e) => setNewLotName(e.currentTarget.value)} />
            <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }} onClick={handleAddLot}>
              <PlusOutlined /> Add lot
            </a>
          </div>
        </div>
      )}
      options={lots.map((lot) => ({ label: lot.key, value: lot.id }))}
      {...otherProps}
    />
  );
};
