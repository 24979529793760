import React, { FC } from 'react';

import { Form, Input, Button, Typography } from 'antd';

import { LockOutlined } from 'components/icons';

interface ISetNewPasswordForm {
  loading: boolean;
  onSubmit: (password: string) => void;
}

const { Title } = Typography;

const SetNewPasswordForm: FC<ISetNewPasswordForm> = ({ loading, onSubmit }) => {
  const [form] = Form.useForm();

  const handleFormSubmit = () => {
    form
      .validateFields()
      .then((values: any) => {
        onSubmit(values.password);
      })
      .catch((info: any) => {
        console.log('Validate Failed:', info);
      });
  };

  return (
    <Form
      className="Login__Form"
      form={form}
      layout="vertical"
      name="SetNewPasswordForm"
      hideRequiredMark
      initialValues={{ password: '', confirmPassword: '' }}
      onFinish={handleFormSubmit}
    >
      <Title level={4} type="secondary">
        Set new password
      </Title>

      <Form.Item
        name="password"
        rules={[
          {
            required: true,
            message: 'Please input your password!',
          },
          {
            min: 8,
            message: 'Password must be at least 8 characters long!',
          },
        ]}
      >
        <Input.Password prefix={<LockOutlined />} placeholder="Enter new password" />
      </Form.Item>

      <Form.Item
        name="confirmPassword"
        dependencies={['password']}
        rules={[
          {
            required: true,
            message: 'Please confirm your password!',
          },
          ({ getFieldValue }) => ({
            validator(_rule, value): Promise<any> {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }

              // eslint-disable-next-line prefer-promise-reject-errors
              return Promise.reject('Two passwords must match!');
            },
          }),
        ]}
      >
        <Input.Password prefix={<LockOutlined />} placeholder="Confirm new password" />
      </Form.Item>

      <Form.Item>
        <Button block type="primary" htmlType="submit" loading={loading}>
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default SetNewPasswordForm;
