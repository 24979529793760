import React, { FC, useState } from 'react';

import { Button, Modal } from 'antd';

import { CopyOutlined, CheckCircleTwoTone } from 'components/icons';
import { copyToClipboard } from 'utils/functions';

const json = [
  {
    page: 1,
    x: 10,
    y: 10,
    type: 'placeholder',
    value: 'Hi, {{data:patient.lastName}}! The year is {{function:timestamp,zone=UTC,format=yyyy}}',
    font: 'NotoSansMedium',
  },
  {
    page: 1,
    x: 28,
    y: 196,
    type: 'data',
    entity: 'patient',
    field: 'lastName',
    defaultValue: 'n/a',
  },
  {
    page: 1,
    x: 10,
    y: 50,
    type: 'data',
    entity: 'patient',
    field: 'lastName',
  },
  {
    page: 1,
    x: 310,
    y: 545,
    type: 'function',
    function: 'timestamp',
    parameters: {
      zone: 'America/Los_Angeles',
      format: 'yyyy-MM-dd',
    },
  },
  {
    page: 1,
    x: 30,
    y: 397,
    type: 'static',
    value: 'x',
    fontSize: 12,
  },
  {
    page: 1,
    x: 222,
    y: 508,
    type: 'static',
    value: 'Tasso Inc.',
  },
];

type Props = {
  open?: boolean;
  onClose: () => void;
};

const TextConfigHelpModal: FC<Props> = ({ open, onClose }) => {
  const [copied, setCopied] = useState(false);

  const copyText = () => {
    copyToClipboard(JSON.stringify(json, null, 2));
    setCopied(true);
    setTimeout(() => setCopied(false), 3000);
  };

  return (
    <Modal
      wrapClassName="Modal TextConfigHelpModal"
      centered
      width="540px"
      visible={open}
      title="textConfig sample"
      onCancel={onClose}
      cancelText="Close"
      okButtonProps={{ hidden: true }}
    >
      <div style={{ maxHeight: '50vh', overflow: 'auto' }}>
        <p>
          For data that may contain non-English characters (e.g., first or last name), fonts with good Unicode support
          are recommended: InterRegular, InterMedium, InterBold, NotoSansRegular, NotoSansMedium, NotoSansBold.
        </p>
        <p>
          Fields <code>page</code>, <code>defaultValue</code>, <code>font</code> and <code>fontSize</code> are optional.
        </p>
        <div className="codeBlock">
          <Button
            icon={copied ? <CheckCircleTwoTone /> : <CopyOutlined />}
            className="copyCode"
            size="small"
            title="Copy to clipboard"
            onClick={copyText}
          />
          <pre>{JSON.stringify(json, null, 2)}</pre>
        </div>
      </div>
    </Modal>
  );
};

export default TextConfigHelpModal;
