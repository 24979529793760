/* eslint-disable no-await-in-loop */
import React, { FC, useState, createContext, useRef } from 'react';

import moment from 'moment';

import { patientsApi } from 'api';
import { makeCancelTokenSource, CancelTokenSource } from 'services';
import { isRequestCancelled } from 'services/httpClient';
import { loadAllPages, loadByIdsInBatches, normalizePagingResponse } from 'utils/functions';
import * as issues from 'utils/issues';
import Notification from 'utils/notification';
import { PagingInfo } from 'utils/types';
import { dateToRange } from 'utils/date';

export interface IPatient {
  id: string;
  createdAt: string;
  updatedAt: string;
  projectId: string;
  customerId: string;
  firstName: string;
  lastName: string;
  dob: string;
  gender: string;
  assignedSex: string;
  address1: string;
  address2: string;
  city: string;
  district1: string;
  postalCode: string;
  country: string;
  email: string;
  phoneNumber: string;
  subjectId: string;
  race: string;
  createdAtString: string;
  createdAtTimestamp: number;
  deviceReadyToShipOccurredAt: string;
  smsConsent: string; // timestamp of consent or empty string if no consent was given
}

type ApiPatient = {
  id: string;
  projectId: string;
  customerId: string;
  firstName: string | null;
  lastName: string;
  dob: string | null;
  gender: string | null;
  assignedSex: string | null;
  address1: string;
  address2: string | null;
  address3: string | null;
  city: string;
  district1: string;
  district2: string | null;
  postalCode: string;
  country: string;
  email: string | null;
  phoneNumber: string | null;
  subjectId: string | null;
  race: string | null;
  createdAt: string;
  updatedAt: string | null;
  deviceReadyToShipOccurredAt: string | null;
  smsConsent: string | null;
};

interface State {
  patients: IPatient[];
  patient: IPatient | null;
  error: string | null;
  loading: boolean;
}

type LoadPatientsCallback = (patients: IPatient[] | null, paging: PagingInfo | null) => Promise<void>;

export interface LoadPatientsPayload {
  patientIds?: string[];
  deviceStatuses?: string[];
  projectId: string;
  page: number;
  pageLength: number;
  includeTotalCount?: boolean;
  sortOrder?: 'asc' | 'desc';
  sortBy?: string;
  search: { by: string; term: string } | null;
  orderTemplateId?: string;
}

type GetPatientsCallback = (patients: IPatient[] | null) => Promise<void>;
type GetPatientsFunction = (
  projectId: string | Record<string, string>,
  callback?: GetPatientsCallback
) => Promise<void>;

interface Context extends State {
  loadPatients: (params: LoadPatientsPayload, callback?: LoadPatientsCallback) => Promise<void>;
  getPatients: GetPatientsFunction;
  resetPatients: () => void;
  getPatient: (id: string, callback?: (patient: IPatient) => Promise<void>) => Promise<void>;
  createPatient: (data: Record<string, any>, callback?: (patient: IPatient) => Promise<void>) => Promise<void>;
  updatePatient: (
    id: string,
    data: Record<string, any>,
    callback?: (patient: IPatient) => Promise<void>
  ) => Promise<void>;
  deletePatient: (id: string, callback?: (patient: IPatient) => Promise<void>) => Promise<void>;
}

const formatPatient = (item: ApiPatient): IPatient => {
  let dob: string;

  if (item.dob) {
    if (/^\d{4}-\d{2}-\d{2}$/.test(item.dob)) {
      dob = item.dob;
    } else {
      dob = moment(item.dob).utc().format('YYYY-MM-DD');
    }
  } else {
    dob = '';
  }

  const createdAtString = moment(item.createdAt).utc().format('YYYY-MM-DD');
  const deviceReadyToShipOccurredAt = item.deviceReadyToShipOccurredAt || item.createdAt;
  const deviceReadyToShipOccurredAtString = moment(deviceReadyToShipOccurredAt).utc().format('YYYY-MM-DD');
  const smsConsent = item.smsConsent ?? '';

  return {
    id: item.id,
    createdAt: item.createdAt,
    updatedAt: item.updatedAt || '',
    projectId: item.projectId,
    customerId: item.customerId,
    firstName: item.firstName || '',
    lastName: item.lastName,
    dob,
    gender: item.gender || '',
    assignedSex: item.assignedSex || '',
    address1: item.address1,
    address2: item.address2 || '',
    city: item.city,
    district1: item.district1,
    postalCode: item.postalCode,
    country: item.country,
    email: item.email || '',
    phoneNumber: item.phoneNumber || '',
    subjectId: item.subjectId || '',
    race: item.race || '',
    createdAtString,
    createdAtTimestamp: new Date(item.createdAt).getTime(),
    deviceReadyToShipOccurredAt: deviceReadyToShipOccurredAtString,
    smsConsent,
  };
};

const getInitialState = (): State => ({
  patients: [],
  patient: null,
  error: null,
  loading: false,
});

const PatientsContext = createContext<Context>({
  ...getInitialState(),
  loadPatients: async () => {},
  getPatients: async () => {},
  resetPatients: () => {},
  getPatient: async () => {},
  createPatient: async () => {},
  updatePatient: async () => {},
  deletePatient: async () => {},
});

const PatientsProvider: FC = (props) => {
  const [state, setState] = useState<State>(getInitialState);
  const cancelTokenSource = useRef<CancelTokenSource | null>(null);

  const loadPatients = async (params: LoadPatientsPayload, callback?: LoadPatientsCallback): Promise<void> => {
    setState((s) => ({
      ...s,
      error: null,
      loading: true,
    }));

    try {
      cancelTokenSource.current = makeCancelTokenSource();

      const sortBy = params.sortBy || 'createdAt';
      const isDescending = params.sortOrder !== 'asc';
      const query: Record<string, string> = {};

      if (params.patientIds) {
        query.ids = params.patientIds.join(',');
      }

      if (params.deviceStatuses) {
        query.deviceStatuses = params.deviceStatuses.join(',');
      }
      if (params.orderTemplateId) {
        query.orderTemplateId = params.orderTemplateId;
      }

      if (params.projectId) {
        query.projectIds = params.projectId;
      }

      if (params.search) {
        if (params.search.by === 'createdAt') {
          // Search either by date only (most common use case) or by exact timestamp
          const range = dateToRange(params.search.term.replace(/[^0-9-]/g, ''));
          if (range) {
            const [createdAtMin, createdAtMax] = range;
            query.createdAtMin = createdAtMin;
            query.createdAtMax = createdAtMax;
          } else {
            query.createdAtMin = params.search.term;
            query.createdAtMax = params.search.term;
          }
        } else if (params.search.by === 'deviceReadyToShipOccurredAt') {
          // Search either by date only (most common use case) or by exact timestamp
          const range = dateToRange(params.search.term);
          if (range) {
            const [deviceReadyToShipOccurredAtMin, deviceReadyToShipOccurredAtMax] = range;
            query.deviceReadyToShipOccurredAtMin = deviceReadyToShipOccurredAtMin;
            query.deviceReadyToShipOccurredAtMax = deviceReadyToShipOccurredAtMax;
          } else {
            query.deviceReadyToShipOccurredAtMin = params.search.term;
            query.deviceReadyToShipOccurredAtMax = params.search.term;
          }
        } else {
          query[params.search.by] = params.search.term;
        }
      }

      const data = await patientsApi.loadPatients(
        { query },
        {
          page: params.page,
          pageLength: params.pageLength,
          sortBy,
          isDescending,
          includeTotalCount: !!params.includeTotalCount,
        },
        { cancelToken: cancelTokenSource.current.token }
      );

      if (isRequestCancelled(data)) {
        // requests cancelled by client, don't do anything to state
        // component unmount logic should take care of state resetting
        return;
      }

      const results = data.results as ApiPatient[];
      const pagingResponse = normalizePagingResponse({ ...data.paging, sortBy });

      const patients = results.map(formatPatient);

      setState((s) => ({
        ...s,
        patients,
      }));

      if (callback) {
        await callback(patients, pagingResponse);
      }
    } catch (e) {
      cancelTokenSource.current = null;

      const errorMessage = issues.getErrorMessage(e);

      setState((s) => ({
        ...s,
        error: errorMessage,
      }));

      Notification({
        type: 'error',
        message: 'Cannot load patients',
        description: errorMessage,
      });

      if (callback) {
        await callback(null, null);
      }
    }

    setState((s) => ({
      ...s,
      loading: false,
    }));
  };

  const getPatients: GetPatientsFunction = async (params, callback) => {
    setState((s) => ({
      ...s,
      error: null,
      loading: true,
    }));

    try {
      cancelTokenSource.current = makeCancelTokenSource();

      const requestParams = typeof params === 'string' ? { projectIds: params } : params;

      const patients: ApiPatient[] = [];

      if (typeof requestParams.ids === 'string') {
        const { ids, ...otherParams } = requestParams;
        const allPatientIds = ids !== '' ? [...new Set(ids.split(','))] : [];

        const results = await loadByIdsInBatches<ApiPatient>(
          patientsApi.getPatients,
          'ids',
          allPatientIds,
          otherParams,
          false,
          { cancelToken: cancelTokenSource.current.token }
        );

        patients.push(...results);
      } else {
        type PayloadType = Parameters<typeof patientsApi.getPatients>[0];
        const results = await loadAllPages<ApiPatient, PayloadType>(
          patientsApi.getPatients,
          requestParams,
          {
            pageLength: 50,
            sortBy: 'createdAt',
            isDescending: false,
          },
          { cancelToken: cancelTokenSource.current.token }
        );

        patients.push(...results);
      }

      const formattedPatients = patients.map(formatPatient);

      setState((s) => ({
        ...s,
        patients: patients.map(formatPatient),
      }));

      if (callback) {
        await callback(formattedPatients);
      }
    } catch (e) {
      cancelTokenSource.current = null;

      const errorMessage = issues.getErrorMessage(e);

      setState((s) => ({
        ...s,
        error: errorMessage,
      }));

      Notification({
        type: 'error',
        message: 'Cannot get patients',
        description: errorMessage,
      });

      if (callback) {
        await callback(null);
      }
    } finally {
      setState((s) => ({
        ...s,
        loading: false,
      }));
    }
  };

  const resetPatients = (): void => {
    if (cancelTokenSource.current) {
      cancelTokenSource.current.cancel();
    }
    setState(getInitialState());
  };

  const getPatient = async (id: string, callback?: (patient: IPatient) => Promise<void>): Promise<void> => {
    try {
      const patient: ApiPatient = await patientsApi.getPatient(id);
      const formattedPatient = formatPatient(patient);

      setState((s) => ({
        ...s,
        patient: formattedPatient,
      }));

      if (callback) {
        await callback(formattedPatient);
      }
    } catch (e) {
      const errorMessage = issues.getErrorMessage(e);

      setState((s) => ({
        ...s,
        loading: false,
        error: errorMessage,
      }));

      Notification({
        type: 'error',
        message: 'Cannot get patient details',
        description: errorMessage,
      });
    }
  };

  const createPatient = async (
    data: Record<string, any>,
    callback?: (newPatient: IPatient) => Promise<void>
  ): Promise<void> => {
    try {
      setState((s) => ({
        ...s,
        loading: true,
        error: null,
      }));

      const newPatient: ApiPatient = await patientsApi.createPatient(data);
      const formattedPatient = formatPatient(newPatient);

      setState((s) => ({
        ...s,
        loading: false,
        patients: [...s.patients, formattedPatient],
      }));

      Notification({ type: 'success', message: 'Patient successfully created' });

      if (callback) {
        await callback(formattedPatient);
      }
    } catch (e) {
      const errorMessage = issues.getErrorMessage(e);

      setState((s) => ({
        ...s,
        loading: false,
        error: errorMessage,
      }));

      Notification({
        type: 'error',
        message: 'Patient was not created',
        description: errorMessage,
      });
    }
  };

  const updatePatient = async (
    id: string,
    data: Record<string, any>,
    callback?: (patient: IPatient) => Promise<void>
  ): Promise<void> => {
    try {
      setState((s) => ({
        ...s,
        loading: true,
        error: null,
      }));

      const updatedPatient: ApiPatient = await patientsApi.updatePatient(id, data);
      const formattedPatient = formatPatient(updatedPatient);

      setState((s) => ({
        ...s,
        patients: s.patients.map((p) => (p.id === id ? formattedPatient : p)),
        patient: s.patient && s.patient.id === id ? formattedPatient : s.patient,
        loading: false,
      }));

      Notification({ type: 'success', message: 'Patient successfully updated' });

      if (callback) {
        await callback(formattedPatient);
      }
    } catch (e) {
      const errorMessage = issues.getErrorMessage(e);

      setState((s) => ({
        ...s,
        loading: false,
        error: errorMessage,
      }));

      Notification({
        type: 'error',
        message: 'Patient was not updated',
        description: errorMessage,
      });
    }
  };

  const deletePatient = async (id: string, callback?: (patient: IPatient) => Promise<void>): Promise<void> => {
    try {
      setState((s) => ({
        ...s,
        loading: true,
        error: null,
      }));

      const deletedPatient = await patientsApi.deletePatient(id);
      const formattedPatient = formatPatient(deletedPatient);

      setState((s) => ({
        ...s,
        patients: s.patients.filter((p) => p.id !== id),
        patient: s.patient && s.patient.id === id ? null : s.patient,
        loading: false,
      }));

      Notification({ type: 'success', message: 'Patient successfully deleted' });

      if (callback) {
        await callback(formattedPatient);
      }
    } catch (e) {
      const errorMessage = issues.getErrorMessage(e);

      setState((s) => ({
        ...s,
        loading: false,
        error: errorMessage,
      }));

      Notification({
        type: 'error',
        message: 'Patient was not deleted',
        description: errorMessage,
      });
    }
  };

  return (
    <PatientsContext.Provider
      value={{
        patients: state.patients,
        patient: state.patient,
        loading: state.loading,
        error: state.error,
        loadPatients,
        getPatients,
        resetPatients,
        getPatient,
        createPatient,
        updatePatient,
        deletePatient,
      }}
      {...props}
    />
  );
};

const usePatients = (): Context => React.useContext(PatientsContext);

export { PatientsProvider, usePatients };
