/* eslint-disable no-await-in-loop */
import React, { FC, useReducer, createContext } from 'react';

import { downloadsApi } from 'api';
import { makeReducer, openDocument } from 'utils/functions';
import { serializeForDisplay } from 'utils/issues';
import Notification from 'utils/notification';

const wait = (ms: number): Promise<boolean> => new Promise((resolve) => setTimeout(() => resolve(true), ms));

export interface IDownloadsContext {
  loading: boolean;
  error: any;
  downloadBarcode: (deviceIds: string[], disposition?: string) => Promise<void>;
  downloadOrderFulfillmentSummary: (deviceIds: string[], disposition?: string) => Promise<void>;
  downloadCustomerLetters: (deviceIds: string[], disposition?: string) => Promise<void>;
  downloadShippingLabels: (deviceIds: string[], disposition?: string) => Promise<void>;
  downloadShippingLabelsV2: (shipmentIds: string[], disposition?: string) => Promise<void>;
  downloadActivationLabels: (deviceIds: string[], disposition?: string) => Promise<void>;
  downloadRequisitionForm: (patientIds: string[], disposition?: string) => Promise<void>;
  downloadShipmentReport: (date?: string, disposition?: string) => Promise<void>;
}

const DownloadsContext = createContext<IDownloadsContext>({
  loading: false,
  error: null,
  downloadBarcode: async () => {},
  downloadOrderFulfillmentSummary: async () => {},
  downloadCustomerLetters: async () => {},
  downloadShippingLabels: async () => {},
  downloadShippingLabelsV2: async () => {},
  downloadActivationLabels: async () => {},
  downloadRequisitionForm: async () => {},
  downloadShipmentReport: async () => {},
});

type State = {
  error: string | null;
  loading: boolean;
};

const initialState: State = { error: null, loading: false };

const DownloadsProvider: FC = (props) => {
  const [state, setState] = useReducer(makeReducer<State>(), { ...initialState });

  const download = async (apiCall: () => Promise<any>): Promise<void> => {
    try {
      setState({ loading: true });

      const response = await apiCall();
      // After TD-1057 is on prod, these can be simplified to just response.results.getUrl/headUrl
      const getUrl = response?.results.getUrl || response.getUrl;
      const headUrl = response?.results.headUrl || response.headUrl;

      await wait(1000);

      let isReady = await downloadsApi.isDownloadReady(headUrl);
      let maxAttempts = 30; // about a minute of waiting at most

      while (!isReady && maxAttempts > 0) {
        await wait(2000);
        isReady = await downloadsApi.isDownloadReady(headUrl);
        maxAttempts -= 1;
      }

      if (!isReady) {
        Notification({ type: 'error', message: 'Document generation failed' });
      } else {
        openDocument(getUrl);
      }
    } catch (e: any) {
      console.log(e);

      let errorMsg = 'Document generation failed';
      if (e.response && e.response.data && e.response.data.issues) {
        errorMsg = serializeForDisplay(e.response.data.issues) || errorMsg;
      }

      Notification({
        type: 'error',
        message: errorMsg,
        description: e.message,
      });
    } finally {
      setState({ loading: false });
    }
  };

  const directDownload = async (apiCall: () => Promise<any>): Promise<void> => {
    try {
      setState({ loading: true });

      const { results, issues } = await apiCall();

      if (issues && issues.errors && issues.errors.length > 0) {
        Notification({ type: 'error', message: serializeForDisplay(issues) || '' });
      } else {
        openDocument(results.getUrl);
      }
    } catch (e: any) {
      console.log(e);
      Notification({
        type: 'error',
        message: 'Document generation failed',
        description: e.message,
      });
    } finally {
      setState({ loading: false });
    }
  };

  const downloadBarcode = async (deviceIds: string[], disposition?: string): Promise<void> =>
    download(() => downloadsApi.downloadBarcode(deviceIds, disposition));

  const downloadOrderFulfillmentSummary = async (deviceIds: string[], disposition?: string): Promise<void> =>
    download(() => downloadsApi.downloadOrderFulfillmentSummary(deviceIds, disposition));

  const downloadCustomerLetters = async (deviceIds: string[], disposition?: string): Promise<void> =>
    download(() => downloadsApi.downloadCustomerLetters(deviceIds, disposition));

  const downloadShippingLabels = async (deviceIds: string[], disposition?: string): Promise<void> =>
    directDownload(() => downloadsApi.downloadShippingLabels(deviceIds, disposition));

  const downloadShippingLabelsV2 = async (shipmentIds: string[], disposition?: string): Promise<void> =>
    directDownload(() => downloadsApi.downloadShippingLabelsV2(shipmentIds, disposition));

  const downloadActivationLabels = async (deviceIds: string[], disposition?: string): Promise<void> =>
    download(() => downloadsApi.downloadActivationLabels(deviceIds, disposition));

  const downloadRequisitionForm = async (patientIds: string[], disposition?: string): Promise<void> =>
    download(() => downloadsApi.downloadRequisitionForm(patientIds, disposition));

  const downloadShipmentReport = async (date?: string, disposition?: string): Promise<void> =>
    directDownload(() => downloadsApi.downloadShipmentReport(date, disposition));

  return (
    <DownloadsContext.Provider
      value={{
        loading: state.loading,
        error: state.error,
        downloadBarcode,
        downloadOrderFulfillmentSummary,
        downloadCustomerLetters,
        downloadShippingLabels,
        downloadShippingLabelsV2,
        downloadActivationLabels,
        downloadRequisitionForm,
        downloadShipmentReport,
      }}
      {...props}
    />
  );
};

const useDownloads = (): IDownloadsContext => React.useContext(DownloadsContext);

export { DownloadsProvider, useDownloads };
