import React, { FC, useState } from 'react';

import { Form, DatePicker, Modal } from 'antd';

import DeviceLotDropdown from 'components/DeviceLotDropdown';
import { IDeviceLot } from 'context/deviceLots';
import moment from 'moment';

interface IDeviceBulkEditModal {
  form: any;
  visible: boolean;
  devices: string[];
  onOk: (data: any) => void;
  onCancel: () => void;
  loading: boolean;
  lots: IDeviceLot[];
  onAddLot: (key: string) => Promise<void>;
  onSelectLot: (deviceLotId: string) => void;
}

const DeviceBulkEditModal: FC<IDeviceBulkEditModal> = ({
  form,
  visible,
  onOk,
  onCancel,
  loading,
  devices,
  lots,
  onAddLot,
  onSelectLot,
}) => {
  const [selectedDeviceLotId, setSelectedDeviceLotId] = useState<string>('');
  const [isExpiresAtInThePast, setIsExpiresAtInThePast] = useState<boolean>(false);

  const handleFormSubmit = () => {
    form
      .validateFields()
      .then((values: any) => {
        onOk({
          deviceLotId: selectedDeviceLotId || null,
          expiresAt: values.expiresAt ? values.expiresAt.format('YYYY-MM-DD') : null,
        });
      })
      .catch((info: any) => {
        console.log('Validate Failed:', info);
      });
  };

  const onExpiresAtChange = (value: moment.Moment | null) => {
    const dayOnlyValue = value?.format('YYYY-MM-DD');
    const dayOnlyToday = moment(moment.now()).format('YYYY-MM-DD');
    setIsExpiresAtInThePast(moment(dayOnlyValue).isBefore(dayOnlyToday));
  };

  const onFormCancel = () => {
    setIsExpiresAtInThePast(false);
    onCancel();
  };

  return (
    <Modal
      wrapClassName="Modal DeviceBulkEditModal"
      visible={visible}
      title={`Update ${devices.length} Device${devices.length > 0 ? 's' : ''}`}
      okText="Update"
      okButtonProps={{ disabled: isExpiresAtInThePast }}
      cancelText="Cancel"
      onCancel={onFormCancel}
      onOk={handleFormSubmit}
      centered
      width="350px"
      confirmLoading={loading}
      closable={!loading}
      cancelButtonProps={{ disabled: loading }}
    >
      <Form
        className="Form DeviceBulkEditModal__Form"
        form={form}
        layout="vertical"
        name="DeviceBulkEditModalForm"
        hideRequiredMark
      >
        <Form.Item name="lot" label="Lot">
          <DeviceLotDropdown
            lots={lots}
            onAdd={onAddLot}
            onSelect={(id) => {
              setSelectedDeviceLotId(id);
              onSelectLot(id);
            }}
            loading={loading}
            selectedId={selectedDeviceLotId}
            style={{ width: '100%' }}
          />
        </Form.Item>

        <Form.Item
          name="expiresAt"
          label="Expiry date"
          validateStatus={isExpiresAtInThePast ? 'error' : undefined}
          help={isExpiresAtInThePast ? 'Expiry date cannot be in the past.' : undefined}
        >
          <DatePicker placeholder="YYYY-MM-DD" format="YYYY-MM-DD" onChange={onExpiresAtChange} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default DeviceBulkEditModal;
