import React, { FC, useEffect } from 'react';

import { Form, FormInstance, Input, Modal, Select } from 'antd';

import { IdPool } from 'context/idPools';
import { Customer } from 'context/customers';

interface IdPoolModalProps {
  form: FormInstance;
  idPool: IdPool | null;
  customers: Customer[];
  visible: boolean;
  working: boolean;
  isLoadingData: boolean;
  onOk: (data: { name: string; customerId: string }) => void;
  onCancel: () => void;
}

const IdPoolModal: FC<IdPoolModalProps> = ({
  form,
  idPool,
  customers,
  visible,
  working,
  isLoadingData,
  onOk,
  onCancel,
}) => {
  useEffect(() => {
    if (visible) {
      if (!idPool) {
        form.resetFields();
      }
    }
  }, [visible]);

  const handleFormSubmit = () => {
    form
      .validateFields()
      .then((values: { name: string; customerId: string }) => {
        onOk({
          name: (values.name || '').trim(),
          customerId: values.customerId,
        });
      })
      .catch((info) => {
        console.log('Validation failed:', info);
      });
  };

  const getTitle = (): string => {
    if (idPool) {
      return 'Edit Id Pool';
    }

    return 'Create Id Pool';
  };

  return (
    <Modal
      wrapClassName="Modal AnalyteModal"
      visible={visible}
      title={getTitle()}
      okText="Submit"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={handleFormSubmit}
      confirmLoading={working}
      centered
      width="360px"
      forceRender
      okButtonProps={{ disabled: isLoadingData }}
    >
      <Form className="Form AnalyteModal__Form" form={form} layout="vertical" name="IdPoolModalForm" hideRequiredMark>
        <Form.Item name="name" label="Pool name" rules={[{ required: true, message: 'Please enter a pool name!' }]}>
          <Input placeholder="Enter a pool name" />
        </Form.Item>

        <Form.Item
          name="customerId"
          label="Customer"
          rules={[{ required: true, message: 'Please select a customer!' }]}
        >
          <Select
            showSearch
            placeholder="Select a customer"
            optionFilterProp="children"
            filterOption={(input, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            filterSort={(optionA: any, optionB: any) =>
              optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
            }
            loading={isLoadingData}
            disabled={!!idPool}
          >
            {customers.map((item) => (
              <Select.Option key={item.id} value={item.id}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default IdPoolModal;
