/* eslint-disable react/jsx-no-target-blank */
import React, { FC, useEffect, useState } from 'react';

import { Link, useRouteMatch } from 'react-router-dom';

import { Breadcrumb, Table, Button, Card, Popconfirm, TablePaginationConfig, Space } from 'antd';

import {
  EditOutlined,
  PlusCircleOutlined,
  DeleteOutlined,
  ReloadOutlined,
  ClearOutlined,
  CloudUploadOutlined,
  CheckCircleOutlined,
  LoadingOutlined,
} from 'components/icons';
import { Spinner } from 'components/index';
import { useIdPools } from 'context/idPools';
import { GetIdPoolIdentifiersParams, useIdPoolIdentifiers } from 'context/idPoolIdentifiers';
import useIdPoolIdentifierModal from 'hooks/useIdPoolIdentifierModal';
import useIdPoolIdentifierUploadModal from 'hooks/useIdPoolIdentifierUploadModal';
import { ColumnsType, FilterValue, SorterResult } from 'antd/lib/table/interface';
import useIntegratedColumnSearch from 'hooks/useIntegratedColumnSearch';
import { DISTRIBUTION_MODEL_SOURCES, USER_ROLES } from 'constants/index';
import { useAuth } from 'context/auth';
import { FetchDataParameters, PagingConfig, SearchableColumnKey, SearchConfig, TableRecord } from './types';

const getDefaults = (): FetchDataParameters => ({
  page: 1,
  pageLength: 100,
  sortBy: 'createdAt',
  sortOrder: 'desc',
  search: null,
  includeTotalCount: true,
});

const IdPoolIdentifiersContainer: FC = () => {
  const {
    params: { id: idPoolId },
  } = useRouteMatch<{ id: string }>();

  const { idPool, getIdPool, loading: idPoolsLoading, resetIdPools } = useIdPools();
  const { profile } = useAuth();
  const isAdmin = (profile?.role || '') === USER_ROLES.internalAdmin;

  const {
    getIdPoolIdentifiers,
    idPoolIdentifiers,
    resetIdPoolIdentifiers,
    deleteIdPoolIdentifier,
    loading: idPoolIdentifiersLoading,
  } = useIdPoolIdentifiers();

  const { Modal: IdPoolIdentifierModal, onCreateIdPoolIdentifier, onEditIdPoolIdentifier } = useIdPoolIdentifierModal();
  const { Modal: IdPoolIdentifierUploadModal, onUploadIdPoolIdentifiers } = useIdPoolIdentifierUploadModal();

  const { getColumnSearchProps } = useIntegratedColumnSearch<TableRecord>();

  const [fetchParams, setFetchParams] = useState<FetchDataParameters>(getDefaults());

  const [tablePagination, setTablePagination] = useState<TablePaginationConfig>({
    current: 0,
    defaultCurrent: fetchParams.page,
    defaultPageSize: fetchParams.pageLength,
    pageSizeOptions: ['10', '20', '50', '100'],
    hideOnSinglePage: false,
    showSizeChanger: true,
    showQuickJumper: true,
    showTotal: (n) => `${n} identifier${n === 1 ? '' : 's'}`,
  });

  const [sorting, setSorting] = useState<{
    key: string;
    order: 'ascend' | 'descend';
  }>({
    key: 'createdAt',
    order: 'descend',
  });

  const [totalIdPoolCount, setTotalIdPoolCount] = useState(0);

  const [resetFilters, setResetFilters] = useState(false);

  const fetchData = async (params: FetchDataParameters): Promise<void> => {
    const { page, pageLength, sortBy, sortOrder, includeTotalCount } = params;
    const reqParams: GetIdPoolIdentifiersParams = { page, pageLength, sortBy, sortOrder, includeTotalCount };

    if (params.search !== null) {
      const { search } = params;

      if (search.by === 'value') {
        reqParams.valueLike = search.term;
      }
    }

    reqParams.idPoolIds = [idPoolId];

    await getIdPoolIdentifiers(
      reqParams,
      { hydrate: ['customer', 'project', 'device'] },
      async (loadedItems, pagingInfo) => {
        if (!loadedItems) {
          resetIdPoolIdentifiers();
          return;
        }

        if (params.includeTotalCount && pagingInfo) {
          setTotalIdPoolCount(pagingInfo.totalCount);
        }
      }
    );
  };

  const updatePaging = (params: PagingConfig): void => {
    const pageChanged = params.page !== fetchParams.page;
    const pageLengthChanged = params.pageLength !== fetchParams.pageLength;
    const sortChanged = params.sortBy !== fetchParams.sortBy || params.sortOrder !== fetchParams.sortOrder;
    const searchChanged = JSON.stringify(params.search) !== JSON.stringify(fetchParams.search);

    const configChanged = pageChanged || pageLengthChanged || sortChanged || searchChanged;
    const updateTotal = pageLengthChanged || sortChanged || searchChanged;

    if (configChanged) {
      if (updateTotal) {
        setFetchParams((s) => ({ ...s, ...params, page: 1, includeTotalCount: updateTotal }));
      } else {
        setFetchParams((s) => ({ ...s, ...params }));
      }
    }
  };

  const handleTableChange = (
    paginationParams: TablePaginationConfig,
    filterParams: Record<string, FilterValue | null>,
    sorterParams: SorterResult<TableRecord> | SorterResult<TableRecord>[]
  ): void => {
    const page = paginationParams.current!;
    const pageLength = paginationParams.pageSize!;

    const sorter = sorterParams as SorterResult<TableRecord>;

    let sortBy: string;
    let sortOrder: 'asc' | 'desc';

    if (typeof sorter.order !== 'undefined') {
      sortBy = sorter.columnKey as string;
      sortOrder = sorter.order === 'ascend' ? 'asc' : 'desc';
    } else {
      sortBy = 'createdAt';
      sortOrder = 'desc';
    }

    setTablePagination((s) => ({ ...s, current: page, pageSize: pageLength }));
    setSorting({ key: sortBy, order: sortOrder === 'asc' ? 'ascend' : 'descend' });

    // ++ search
    let search: SearchConfig | null;
    const activeFilters = Object.keys(filterParams).filter((k) => filterParams[k] !== null);
    if (activeFilters.length > 1) {
      // allow only one filter at a time
      if (fetchParams.search !== null) {
        const alreadyExecutedFilterIndex = activeFilters.findIndex((f) => f === fetchParams.search!.by);
        if (alreadyExecutedFilterIndex > -1) {
          activeFilters.splice(alreadyExecutedFilterIndex, 1);
        }
      }
    }

    const newActiveFilterName = activeFilters.length === 1 ? activeFilters[0] : null;

    if (newActiveFilterName !== null) {
      const value = filterParams[newActiveFilterName] === null ? '' : filterParams[newActiveFilterName]!.join(',');
      search = {
        by: newActiveFilterName as SearchableColumnKey,
        term: value,
      };
    } else {
      search = null;
    }
    // -- search

    updatePaging({ page, pageLength, sortBy, sortOrder, search });
  };

  const resetPagination = () => {
    updatePaging({
      page: tablePagination.defaultCurrent!,
      pageLength: tablePagination.defaultPageSize!,
      sortBy: 'createdAt',
      sortOrder: 'desc',
      search: null,
    });

    setTablePagination((s) => ({
      ...s,
      current: tablePagination.defaultCurrent!,
      pageSize: tablePagination.defaultPageSize!,
    }));
  };

  const reloadData = async (): Promise<void> => {
    await fetchData(fetchParams);
  };

  const columns: ColumnsType<TableRecord> = [
    {
      key: 'value',
      title: 'Value',
      dataIndex: 'value',
      sorter: true,
      sortOrder: sorting.key === 'value' ? sorting.order : undefined,
      ...getColumnSearchProps(fetchParams.search, 'value', 'search'),
    },
    {
      key: 'customOrder',
      title: 'Order',
      dataIndex: 'customOrder',
      sorter: true,
      sortOrder: sorting.key === 'customOrder' ? sorting.order : undefined,
    },
    {
      key: 'device',
      title: 'Device',
      dataIndex: ['device', 'id'],
      align: 'center',
      render: (_row, record) => {
        if (record.deviceId) {
          if (record.project && record.project.distributionModel === DISTRIBUTION_MODEL_SOURCES.atCustomerSite) {
            return (
              <a
                href={`/projects/${record.projectId}/unassigned-devices?deviceId=${record.deviceId}`}
                target="_blank"
                rel="noopener"
              >
                <CheckCircleOutlined />
              </a>
            );
          }
          return (
            <a
              href={`/projects/${record.projectId}/devices?deviceId=${record.deviceId}`}
              target="_blank"
              rel="noopener"
            >
              <CheckCircleOutlined />
            </a>
          );
        }

        return '';
      },
    },
    {
      key: 'project',
      title: 'Project',
      dataIndex: ['project', 'name'],
      render: (_row, record) => {
        if (record.projectId) {
          return (
            <a href={`/projects/${record.projectId}/shipments`} target="_blank" rel="noopener">
              {record.project ? record.project.name : record.projectId}
            </a>
          );
        }
        return '';
      },
    },
    {
      key: 'customer',
      title: 'Customer',
      dataIndex: ['customer', 'name'],
    },
    isAdmin
      ? {
          key: 'actions',
          title: 'Actions',
          width: 90,
          align: 'center',
          render: (_row: any, record) =>
            record.deviceId !== null ? (
              <>
                <EditOutlined
                  className="EditAction disabled"
                  disabled
                  title="Consumed identifiers cannot be modified."
                />
                <DeleteOutlined
                  className="DeleteAction disabled"
                  disabled
                  title="Consumed identifiers cannot be modified."
                />
              </>
            ) : (
              <>
                <EditOutlined className="EditAction" onClick={() => onEditIdPoolIdentifier(record)} />
                <Popconfirm
                  title="Are you sure you want to delete this identifier?"
                  okText="Yes"
                  cancelText="No"
                  onConfirm={() => deleteIdPoolIdentifier(record.id, {}, async () => reloadData())}
                >
                  <DeleteOutlined className="DeleteAction" />
                </Popconfirm>
              </>
            ),
        }
      : {},
  ];

  // if fetch configuration changes, fetch data again
  useEffect(() => {
    fetchData(fetchParams);
  }, [JSON.stringify(fetchParams)]);

  // if total count change, update pagination in the table
  useEffect(() => {
    setTablePagination((s) => ({ ...s, total: totalIdPoolCount }));
  }, [totalIdPoolCount]);

  // if page or page length change, update table pagination
  useEffect(() => {
    setTablePagination((s) => ({ ...s, current: fetchParams.page, pageSize: fetchParams.pageLength }));
  }, [fetchParams.page, fetchParams.pageLength]);

  useEffect(() => {
    setSorting({ key: 'createdAt', order: 'descend' });
    setFetchParams(getDefaults());
  }, [resetFilters]);

  useEffect(() => {
    resetPagination();

    return () => {
      resetIdPools();
      resetIdPoolIdentifiers();
    };
  }, []);

  useEffect(() => {
    getIdPool(idPoolId);
  }, [idPoolId]);

  return (
    <Card>
      <Breadcrumb>
        <Breadcrumb.Item key="id-pools">
          <Link to="/settings/id-pools">Id Pools</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item key={`id-pools/${idPoolId}`}>
          {idPoolsLoading || !idPool ? <LoadingOutlined /> : idPool.name}
        </Breadcrumb.Item>
      </Breadcrumb>

      <Space style={{ marginTop: 15, marginBottom: 15 }}>
        {isAdmin && (
          <Button
            type="primary"
            icon={<CloudUploadOutlined />}
            onClick={() => onUploadIdPoolIdentifiers(idPoolId, reloadData)}
          >
            Upload identifiers
          </Button>
        )}

        {isAdmin && (
          <Button
            type="default"
            icon={<PlusCircleOutlined />}
            onClick={() => onCreateIdPoolIdentifier(idPoolId, reloadData)}
          >
            Create identifier
          </Button>
        )}

        <Button type="default" icon={<ReloadOutlined />} size="middle" onClick={reloadData}>
          Reload
        </Button>

        <Button type="default" icon={<ClearOutlined />} size="middle" onClick={() => setResetFilters((s) => !s)}>
          Reset all filters
        </Button>
      </Space>

      {IdPoolIdentifierModal}

      {IdPoolIdentifierUploadModal}

      <Spinner spinning={idPoolIdentifiersLoading}>
        <Table
          className="IdPoolIdentifiers"
          rowKey="id"
          columns={columns}
          dataSource={idPoolIdentifiers}
          bordered
          size="small"
          pagination={tablePagination}
          onChange={handleTableChange}
          showSorterTooltip={false}
        />
      </Spinner>
    </Card>
  );
};

export default IdPoolIdentifiersContainer;
