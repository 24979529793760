import React, { FC } from 'react';

import { Button, Form, Input, DatePicker, Row, Col, Typography } from 'antd';

interface ITestResultsForm {
  onOk: (values: any) => void;
  onCancel: () => void;
}

const { Title } = Typography;

const TestResultsForm: FC<ITestResultsForm> = ({ onOk, onCancel }) => {
  const [form] = Form.useForm();

  const handleFormSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        form.resetFields();
        onOk(values);
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
  };

  return (
    <Form className="TestResultsForm" form={form} layout="vertical" hideRequiredMark onFinish={handleFormSubmit}>
      <Title level={4}>Enter Test Results</Title>

      <Row gutter={24}>
        <Col span={12}>
          <Form.Item
            name="resultDate"
            label="Result Date"
            rules={[{ required: true, message: 'Please select result date!' }]}
          >
            <DatePicker placeholder="YYYY-MM-DD" format="YYYY-MM-DD" />
          </Form.Item>

          <Form.Item name="notes" label="Notes" rules={[{ required: true, message: 'Please input some notes!' }]}>
            <Input.TextArea rows={3} />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name="totalCholesterol"
            label="Total Cholesterol"
            rules={[{ required: true, message: 'Please input total cholesterol!' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item name="hdl" label="HDL" rules={[{ required: true, message: 'Please input HDL!' }]}>
            <Input />
          </Form.Item>

          <Form.Item name="ldl" label="LDL" rules={[{ required: true, message: 'Please input LDL!' }]}>
            <Input />
          </Form.Item>

          <Form.Item
            name="triglycerides"
            label="Triglycerides"
            rules={[{ required: true, message: 'Please input triglycerides!' }]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item className="TestResultsForm__Actions">
        <Button htmlType="submit" type="primary">
          Submit
        </Button>

        <Button htmlType="button" onClick={onCancel}>
          Cancel
        </Button>
      </Form.Item>
    </Form>
  );
};

export default TestResultsForm;
