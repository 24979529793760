import React, { useState } from 'react';

import { Form } from 'antd';

import IdPoolIdentifierModal from 'components/IdPoolIdentifierModal';
import { IdPoolIdentifier, useIdPoolIdentifiers } from 'context/idPoolIdentifiers';

type FinishedCallback = () => Promise<void>;

const useIdPoolIdentifierModal = () => {
  const { createIdPoolIdentifier, updateIdPoolIdentifier } = useIdPoolIdentifiers();
  const [form] = Form.useForm();
  const [modalVisible, setModalVisible] = useState(false);
  const [modalPopulated, setModalPopulated] = useState(false);
  const [selectedIdPoolIdentifier, setSelectedIdPoolIdentifier] = useState<IdPoolIdentifier | null>(null);
  const [working, setWorking] = useState(false);
  const [finishedCallback, setFinishedCallback] = useState<FinishedCallback | null>(null);
  const [idPoolId, setIdPoolId] = useState<string>('');

  const openModal = () => {
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
    form.resetFields();
    setSelectedIdPoolIdentifier(null);
    setFinishedCallback(null);
    setIdPoolId('');
  };

  const callback = () => {
    setWorking(false);
    setModalPopulated(true);
    closeModal();
  };

  const handleCreateIdPoolIdentifier = (data: { value: string; customOrder?: number }) => {
    setWorking(true);

    createIdPoolIdentifier({ ...data, idPoolId }, { hydrate: ['customer', 'project', 'device'] }, async () => {
      if (finishedCallback) {
        await finishedCallback();
      }
      callback();
    });
  };

  const handleUpdateIdPoolIdentifier = (id: string, data: { value: string; customOrder?: number }) => {
    setWorking(true);

    // changing of customerId is not allowed, so don't send it in the update request
    updateIdPoolIdentifier(
      id,
      { value: data.value, ...(data.customOrder !== undefined ? { customOrder: data.customOrder } : {}) },
      { hydrate: ['customer', 'project', 'device'] },
      async () => {
        if (finishedCallback) {
          await finishedCallback();
        }
        callback();
      }
    );
  };

  const onCreateIdPoolIdentifier = (newIdPoolId: string, cb?: FinishedCallback) => {
    setModalPopulated(false);
    setSelectedIdPoolIdentifier(null);
    form.setFieldsValue({});
    setFinishedCallback(cb ? () => () => cb() : null);
    setIdPoolId(newIdPoolId);
    openModal();
  };

  const onEditIdPoolIdentifier = (idPoolIdentifier: IdPoolIdentifier, cb?: FinishedCallback) => {
    setModalPopulated(false);
    setSelectedIdPoolIdentifier(idPoolIdentifier);
    form.setFieldsValue({
      value: idPoolIdentifier.value,
      customOrder: idPoolIdentifier.customOrder,
    });
    setFinishedCallback(cb ? () => cb() : null);
    openModal();
  };

  const Modal = (
    <IdPoolIdentifierModal
      form={form}
      idPoolIdentifier={selectedIdPoolIdentifier}
      visible={modalVisible}
      onOk={
        selectedIdPoolIdentifier
          ? (data) => handleUpdateIdPoolIdentifier(selectedIdPoolIdentifier.id, data)
          : handleCreateIdPoolIdentifier
      }
      onCancel={closeModal}
      isSubmitting={working}
    />
  );

  return { Modal, onCreateIdPoolIdentifier, onEditIdPoolIdentifier, modalPopulated };
};

export default useIdPoolIdentifierModal;
