import React, { FC, useState, createContext } from 'react';

import { contactsApi } from 'api';
import { loadAllPages } from 'utils/functions';
import { getErrorMessage } from 'utils/issues';
import Notification from 'utils/notification';
import { UserRole } from 'utils/types';

import { User } from './users/types';

interface State {
  contacts: User[];
  contact: User | null;
  error: string | null;
  loading: boolean;
}

type SigleUserCallback = (user: User | null) => Promise<void>;
type MultipleUsersCallback = (users: User[] | null) => Promise<void>;

interface GetContactsParams {
  customerId: string;
  roles?: UserRole[];
}
type GetContactsFunction = (params: GetContactsParams, callback?: MultipleUsersCallback) => Promise<void>;

export interface CreateContactData {
  customerId: string;
  username: string;
  role: UserRole;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
}

type CreateContactFunction = (data: CreateContactData, callback?: SigleUserCallback) => Promise<void>;

export interface UpdateContactData {
  customerId?: string;
  role?: UserRole;
  firstName?: string | null;
  lastName?: string | null;
  phoneNumber?: string | null;
}

type UpdateContactFunction = (id: string, data: UpdateContactData, callback?: SigleUserCallback) => Promise<void>;

type DeleteContactFunction = (id: string, callback?: SigleUserCallback) => Promise<void>;

type GetContactFunction = (id: string, callback?: SigleUserCallback) => Promise<void>;

type LocalCreateContactFunction = (user: User) => void;

type LocalUpdateContactFunction = (id: string, data: Record<string, any>) => void;

type ResetContactsFunction = () => void;

interface Context extends State {
  getContacts: GetContactsFunction;
  getContact: GetContactFunction;
  createContact: CreateContactFunction;
  updateContact: UpdateContactFunction;
  deleteContact: DeleteContactFunction;
  localCreateContact: LocalCreateContactFunction;
  localUpdateContact: LocalUpdateContactFunction;
  resetContacts: ResetContactsFunction;
}

const getInitialState = (): State => ({
  contacts: [] as User[],
  contact: null as User | null,
  error: null,
  loading: false,
});

const ContactsContext = createContext<Context>({
  ...getInitialState(),
  getContacts: async () => {},
  getContact: async () => {},
  createContact: async () => {},
  updateContact: async () => {},
  deleteContact: async () => {},
  localCreateContact: () => {},
  localUpdateContact: () => {},
  resetContacts: () => {},
});

const ContactsProvider: FC = (props) => {
  const [state, setState] = useState<State>(getInitialState());

  const getContacts: GetContactsFunction = async (params, callback?) => {
    setState((s) => ({
      ...s,
      loading: true,
      error: null,
    }));

    const roles: UserRole[] = !Array.isArray(params.roles) ? [] : params.roles;

    try {
      type PayloadType = Parameters<typeof contactsApi.getContacts>[0];
      const contacts = await loadAllPages<User, PayloadType>(
        contactsApi.getContacts,
        { customerId: params.customerId, roles: roles.join(',') },
        {
          pageLength: 50,
          sortBy: 'createdAt',
          isDescending: false,
        }
      );

      setState((s) => ({
        ...s,
        contacts,
      }));

      if (callback) {
        await callback(contacts);
      }
    } catch (e) {
      const errorMessage = getErrorMessage(e);

      setState((s) => ({
        ...s,
        error: errorMessage,
      }));

      Notification({
        type: 'error',
        message: 'Cannot get contacts',
        description: errorMessage,
      });

      if (callback) {
        await callback(null);
      }
    }

    setState((s) => ({
      ...s,
      loading: false,
    }));
  };

  const getContact: GetContactFunction = async (id, callback?) => {
    setState((s) => ({
      ...s,
      loading: true,
      error: null,
    }));

    try {
      const contact: User = await contactsApi.getContact(id);

      setState((s) => ({
        ...s,
        contact,
      }));

      if (callback) {
        await callback(contact);
      }
    } catch (e) {
      const errorMessage = getErrorMessage(e);

      setState((s) => ({
        ...s,
        error: errorMessage,
      }));

      Notification({
        type: 'error',
        message: 'Cannot get contact details',
        description: errorMessage,
      });

      if (callback) {
        await callback(null);
      }
    }

    setState((s) => ({
      ...s,
      loading: false,
    }));
  };

  const createContact: CreateContactFunction = async (data, callback?) => {
    setState((s) => ({
      ...s,
      loading: true,
      error: null,
    }));

    try {
      const newContact: User = await contactsApi.createContact(data);

      setState((s) => ({
        ...s,
        contacts: [...state.contacts, newContact],
      }));

      Notification({ type: 'success', message: 'Contact created' });

      if (callback) {
        await callback(newContact);
      }
    } catch (e) {
      const errorMessage = getErrorMessage(e);

      setState((s) => ({
        ...s,
        error: errorMessage,
      }));

      Notification({
        type: 'error',
        message: 'Contact was not created',
        description: errorMessage,
      });

      if (callback) {
        await callback(null);
      }
    }

    setState((s) => ({
      ...s,
      loading: false,
    }));
  };

  const updateContact: UpdateContactFunction = async (id, data, callback?) => {
    setState((s) => ({
      ...s,
      loading: true,
    }));

    try {
      const contact: User = await contactsApi.updateContact(id, data);

      setState((s) => ({
        ...s,
        contacts: s.contacts.map((c) => (c.id === contact.id ? contact : c)),
      }));

      Notification({ type: 'success', message: 'Contact updated' });

      if (callback) {
        await callback(contact);
      }
    } catch (e) {
      const errorMessage = getErrorMessage(e);

      setState((s) => ({
        ...s,
        error: errorMessage,
      }));

      Notification({
        type: 'error',
        message: 'Contact was not updated',
        description: errorMessage,
      });

      if (callback) {
        await callback(null);
      }
    }

    setState((s) => ({
      ...s,
      loading: false,
    }));
  };

  const deleteContact: DeleteContactFunction = async (id, callback?) => {
    setState((s) => ({
      ...s,
      loading: true,
      error: null,
    }));

    try {
      const contact: User = await contactsApi.deleteContact(id);

      setState((s) => ({
        ...s,
        contacts: s.contacts.filter((c) => c.id !== id),
        contact: s.contact && s.contact.id === id ? null : s.contact,
      }));

      Notification({ type: 'success', message: 'Contact successfully deleted' });

      if (callback) {
        await callback(contact);
      }
    } catch (e) {
      const errorMessage = getErrorMessage(e);

      setState((s) => ({
        ...s,
        error: errorMessage,
      }));

      Notification({
        type: 'error',
        message: 'Contact was not deleted',
        description: errorMessage,
      });
    }

    setState((s) => ({
      ...s,
      loading: false,
    }));
  };

  const localCreateContact: LocalCreateContactFunction = (user) => {
    setState((s) => ({
      ...s,
      contacts: [...s.contacts, user],
    }));
  };

  const localUpdateContact: LocalUpdateContactFunction = (id, data) => {
    setState((s) => ({
      ...s,
      contacts: s.contacts.map((c) => (c.id === id ? { ...c, ...data } : c)),
      contact: s.contact && s.contact.id === id ? { ...s.contact, ...data } : s.contact,
    }));
  };

  const resetContacts: ResetContactsFunction = () => {
    setState(getInitialState());
  };

  return (
    <ContactsContext.Provider
      value={{
        contacts: state.contacts,
        contact: state.contact,
        loading: state.loading,
        error: state.error,
        getContacts,
        getContact,
        createContact,
        updateContact,
        deleteContact,
        localCreateContact,
        localUpdateContact,
        resetContacts,
      }}
      {...props}
    />
  );
};

const useContacts = (): Context => React.useContext(ContactsContext);

export { ContactsProvider, useContacts };
