import { useState, useEffect } from 'react';

const getWindowSize = () => ({
  width: window.innerWidth,
  height: window.innerHeight,
});

const useWindowSize = () => {
  const [size, setSize] = useState(getWindowSize());

  useEffect(() => {
    let mounted = true;

    let timeoutId: null | NodeJS.Timeout = null;
    const resizeListener = () => {
      if (timeoutId !== null) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        if (!mounted) {
          return;
        }
        setSize(getWindowSize());
        timeoutId = null;
      }, 150);
    };

    window.addEventListener('resize', resizeListener);

    return () => {
      mounted = false;
      window.removeEventListener('resize', resizeListener);
    };
  }, []);

  return size;
};

export default useWindowSize;
