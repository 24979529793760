import React, { FC, useState, useEffect } from 'react';

import { Modal, Select, Typography } from 'antd';

import { IPatient } from 'context/patients';

const makeLabel = (patient: IPatient): string => {
  let str = '';

  if (patient.lastName && patient.firstName) {
    str = `${patient.lastName}, ${patient.firstName}`;
  } else if (patient.lastName) {
    str = patient.lastName;
  } else if (patient.firstName) {
    str = patient.firstName;
  }

  if (patient.subjectId) {
    str = str.length > 0 ? `${str} (${patient.subjectId})` : `(${patient.subjectId})`;
  }

  return str;
};

type Props = {
  open?: boolean;
  loading: boolean;
  patients: IPatient[];
  onClose: () => void;
  onSubmit: (patientId: string) => void;
};

const AssignDeviceModal: FC<Props> = ({ open, loading, patients, onClose, onSubmit }) => {
  const [value, setValue] = useState<string>('');

  const handleSubmit = () => {
    if (loading || !value) {
      return;
    }

    onSubmit(value);
    onClose();
  };

  useEffect(() => {
    setValue('');
  }, [open]);

  return (
    <Modal
      wrapClassName="Modal Normal"
      centered
      width="460px"
      visible={open}
      title="Assign device to patient"
      onCancel={onClose}
      cancelText="Cancel"
      okText="Assign"
      onOk={handleSubmit}
      confirmLoading={loading}
      okButtonProps={{ disabled: loading || value === '' }}
      cancelButtonProps={{ disabled: loading }}
    >
      <Typography.Paragraph>
        Once assigned, the device will be moved to <strong>Shipments</strong>.
      </Typography.Paragraph>
      <Select
        showArrow
        showSearch
        value={value}
        filterOption={(input, option: any) => option.label.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0}
        loading={loading}
        options={patients.map((p) => ({ label: makeLabel(p), value: p.id }))}
        style={{ width: '100%' }}
        placeholder="Select a patient"
        size="large"
        onChange={(v) => setValue(v as string)}
      />
    </Modal>
  );
};

export default AssignDeviceModal;
