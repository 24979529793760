import { Alert } from 'antd';
import React from 'react';

export const ArchivedProjectBanner: React.FC = () => (
  <Alert
    message="Archived project"
    description="Creation of new patients, devices, orders is disabled."
    type="warning"
    showIcon
    closable
  />
);

export default ArchivedProjectBanner;
