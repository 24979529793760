/* eslint-disable no-template-curly-in-string */
import React, { FC, useState, useRef, useEffect } from 'react';

import { Form, Input, Modal, Select, FormInstance } from 'antd';

import { CreateUserPayload, UpdateUserPayload } from 'api/users';
import { ALL_USER_ROLES } from 'constants/index';
import { useContacts } from 'context/contacts';
import { Customer } from 'context/customers';
import { User } from 'context/users/types';
import { isCustomerRole } from 'utils/functions';

type Props = {
  visible: boolean;
  loading: boolean;
  user?: User | null;
  customers: Customer[];
  lockedCustomerId?: string | null;
  onClose: () => void;
  createUser: (data: CreateUserPayload, modalCallback: (user: User) => Promise<void>) => void;
  updateUser: (
    userId: string,
    data: UpdateUserPayload,
    callback?: (user: User | null) => Promise<void>
  ) => Promise<void>;
  createUpdateCallback?: (user: User) => Promise<void>;
};

const UserModal: FC<Props> = ({
  visible,
  loading,
  user,
  customers,
  lockedCustomerId,
  onClose,
  createUser,
  updateUser,
  createUpdateCallback,
}) => {
  const isLockedToCustomer = typeof lockedCustomerId === 'string';

  const [showCustomerList, setShowCustomerList] = useState<boolean>(false);
  const { localUpdateContact, localCreateContact } = useContacts();

  const form = useRef<FormInstance>(null);

  const handleRoleChange = (value: any): void => {
    setShowCustomerList(isCustomerRole(value));
  };

  const handleClose = (): void => {
    form.current?.resetFields();
    setShowCustomerList(false);
    onClose();
  };

  const handleUserCreate = async (data: CreateUserPayload, callback: () => void): Promise<void> => {
    createUser(data, async (userData: User) => {
      localCreateContact(userData);
      if (createUpdateCallback) {
        await createUpdateCallback(userData);
      }
      callback();
    });
  };

  const handleUserUpdate = async (userId: string, data: UpdateUserPayload, callback: () => void): Promise<void> => {
    updateUser(userId, data, async (userData) => {
      if (userData) {
        localUpdateContact(userId, userData);
        if (createUpdateCallback) {
          await createUpdateCallback(userData);
        }
      }
      callback();
    });
  };

  const handleSubmit = (): void => {
    form.current
      ?.validateFields()
      .then((values) => {
        if (user) {
          const updateValues: UpdateUserPayload = Object.keys(values)
            .filter((k) => k !== 'username') // disallow email changes
            .reduce((acc, k) => ({ ...acc, [k]: values[k] || null }), {});

          if (!isCustomerRole(updateValues.role)) {
            updateValues.customerId = null; // reset field since it's not applicable in non-customer roles
          }

          if (updateValues.phoneNumber) {
            updateValues.phoneNumber = updateValues.phoneNumber.replace(/[^\d]/g, '');
          }

          handleUserUpdate(user.id, updateValues, handleClose);
        } else {
          const filteredValues = Object.keys(values).reduce((acc, k) => {
            if (values[k]) {
              return { ...acc, [k]: values[k].trim() };
            }
            return { ...acc };
          }, {} as CreateUserPayload);

          if (filteredValues.phoneNumber) {
            filteredValues.phoneNumber = filteredValues.phoneNumber.replace(/[^\d]/g, '');
          }

          handleUserCreate(filteredValues, handleClose);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not a valid email!',
    },
  };

  useEffect(() => {
    if (visible) {
      const role = isLockedToCustomer ? user?.role || 'customerAdmin' : user?.role || '';

      form.current?.setFieldsValue({
        username: user?.username || '',
        role,
        customerId: lockedCustomerId || user?.customerId || '',
        firstName: user?.firstName || '',
        lastName: user?.lastName || '',
        phoneNumber: user?.phoneNumber || '',
      });

      handleRoleChange(role);
    }
  }, [visible]);

  return (
    <Modal
      visible={visible}
      wrapClassName="Modal AnalyteModal"
      title={user ? 'Edit user' : 'Create user'}
      okText={user ? 'Update' : 'Create'}
      cancelText="Cancel"
      onCancel={handleClose}
      onOk={handleSubmit}
      okButtonProps={{ 'data-testid': 'user-form-submit' } as any} // 'data-testid' is not a recognized key, but it gets passed along nonetheless.
      confirmLoading={loading}
      centered
      width={360}
    >
      <Form
        ref={form}
        className="Form AnalyteModal__Form"
        layout="vertical"
        validateMessages={validateMessages}
        requiredMark="optional"
      >
        <Form.Item name="username" label="Email" required rules={[{ type: 'email', required: true }]}>
          <Input data-testid="user-form_email-input" placeholder="Enter email" disabled={!!user} type="email" />
        </Form.Item>
        <Form.Item name="role" label="Role" required rules={[{ required: true }]}>
          <Select data-testid="user-form_role-select" placeholder="Select role" onChange={handleRoleChange}>
            {Object.keys(ALL_USER_ROLES)
              .filter((role) => (isLockedToCustomer ? role.startsWith('customer') : true))
              .map((role) => (
                <Select.Option value={role} key={role}>
                  {ALL_USER_ROLES[role as keyof typeof ALL_USER_ROLES]}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
        {showCustomerList && (
          <Form.Item name="customerId" label="Customer" required rules={[{ required: true }]}>
            <Select placeholder="Select customer" disabled={isLockedToCustomer}>
              {customers.map((customer) => (
                <Select.Option value={customer.id} key={customer.id}>
                  {customer.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}
        <Form.Item name="firstName" label="First name">
          <Input data-testid="user-form_firstName-input" placeholder="Enter first name" />
        </Form.Item>
        <Form.Item name="lastName" label="Last name">
          <Input data-testid="user-form_lastName-input" placeholder="Enter last name" />
        </Form.Item>
        <Form.Item name="phoneNumber" label="Phone number">
          <Input data-testid="user-form_phone-input" placeholder="Enter phone number" type="tel" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UserModal;
