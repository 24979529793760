/* eslint-disable no-await-in-loop */
import React, { useState, useEffect } from 'react';

import ProjectAnalyteLinkModal from 'components/ProjectAnalyteLinkModal';
import { Analyte, useAnalytes } from 'context/analytes';
import { useProjectAnalyteLinks } from 'context/projectAnalyteLinks';

interface State {
  projectId: string;
  labAdapterKey: string;
  usedAnalyteIds: string[];
  filteredAnalytes: Analyte[];
}

const getInitialState = (): State => ({ projectId: '', labAdapterKey: '', usedAnalyteIds: [], filteredAnalytes: [] });

const useProjectAnalyteLinkModal = () => {
  const { getAnalytes, error: analytesError } = useAnalytes();
  const { createProjectAnalyteLink, error: projectAnalyteLinksError } = useProjectAnalyteLinks();
  const [modalVisible, setModalVisible] = useState(false);
  const [working, setWorking] = useState(false);

  const [settings, setSettings] = useState<State>(getInitialState());

  useEffect(() => {
    if (analytesError || projectAnalyteLinksError) {
      setWorking(false);
    }
  }, [analytesError, projectAnalyteLinksError]);

  const closeModal = () => {
    setModalVisible(false);
    setSettings(getInitialState());
  };

  const linkToProject = async (analyteIds: string[]): Promise<void> => {
    setWorking(true);

    let viewOrder = settings.usedAnalyteIds.length + 1;

    for (const analyteId of analyteIds) {
      await createProjectAnalyteLink({ projectId: settings.projectId, analyteId, viewOrder });

      viewOrder += 1;
    }

    closeModal();
  };

  const openModal = (projectId: string, labAdapterKey: string, usedAnalyteIds: string[]) => {
    setWorking(true);
    setModalVisible(true);

    getAnalytes({ labAdapterKey }, async (analytes) => {
      const availableAnalytes = analytes?.filter((a) => !usedAnalyteIds.includes(a.id)) || [];
      setSettings({ projectId, labAdapterKey, usedAnalyteIds, filteredAnalytes: availableAnalytes });
      setWorking(false);
    });
  };

  const Modal = (
    <ProjectAnalyteLinkModal
      analytes={settings.filteredAnalytes}
      visible={modalVisible}
      onOk={linkToProject}
      onCancel={closeModal}
      working={working}
    />
  );

  return { Modal, openModal };
};

export default useProjectAnalyteLinkModal;
