import React, { FC } from 'react';

import { Form, Input, Button, Typography } from 'antd';

import { LockOutlined } from 'components/icons';

interface IEnterOTPForm {
  loading: boolean;
  onSubmit: (code: string) => void;
}

const { Title } = Typography;

const EnterOTPForm: FC<IEnterOTPForm> = ({ loading, onSubmit }) => {
  const [form] = Form.useForm<{ code: string }>();

  const handleFormSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        onSubmit(values.code.trim());
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
  };

  return (
    <Form
      className="Login__Form"
      form={form}
      layout="vertical"
      name="EnterOTPForm"
      hideRequiredMark
      initialValues={{ code: '' }}
      onFinish={handleFormSubmit}
    >
      <Title level={4} type="secondary">
        Enter Authentication Passcode
      </Title>

      <Form.Item
        name="code"
        rules={[
          {
            required: true,
            min: 1,
            transform: (v) => (typeof v === 'string' ? v.trim() : v),
            message: 'Please input your security code!',
          },
        ]}
      >
        <Input prefix={<LockOutlined />} placeholder="Code" />
      </Form.Item>

      <Form.Item>
        <Button block type="primary" htmlType="submit" loading={loading}>
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default EnterOTPForm;
