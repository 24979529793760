import React, { FC, useEffect, useState } from 'react';

import { Button, Space } from 'antd';

import { PlusOutlined, ReloadOutlined, ClearOutlined } from 'components/icons';
import { Spinner } from 'components/index';
import { useContacts } from 'context/contacts';
import { useCustomers } from 'context/customers';
import { useProjectContacts } from 'context/projectContacts';
import { useProjects } from 'context/projects';
import { useUsers } from 'context/users';
import { GetUsersParameters, User } from 'context/users/types';
import useUserModal from 'hooks/useUserModal';

import { USER_ROLES } from 'constants/index';
import { useAuth } from 'context/auth';
import { PagingConfig, FetchDataParameters } from './types';
import UserList from './UserList';
import 'less/components/Users.less';

const DEFAULTS: FetchDataParameters = {
  page: 1,
  pageLength: 100,
  sortBy: 'createdAt',
  sortOrder: 'desc',
  search: null,
  includeTotalCount: true,
};

const UsersContainer: FC<any> = () => {
  const { profile } = useAuth();
  const isAdmin = (profile?.role || '') === USER_ROLES.internalAdmin;

  const {
    getUsers,
    users,
    deleteUser,
    resetIdentity,
    updateUser,
    resetTemporaryPassword,
    resetUsers,
    loading: usersLoading,
  } = useUsers();
  const { getCustomers, customers, resetCustomers, loading: customersLoading } = useCustomers();
  const { getProjects, projects, resetProjects, loading: projectsLoading } = useProjects();
  const {
    projectContacts,
    loadProjectContacts,
    resetProjectContacts,
    loading: projectContactsLoading,
  } = useProjectContacts();
  const { resetContacts } = useContacts();
  const { Modal: UserModal, onCreateUser, onEditUser } = useUserModal({ customers });
  const [resetFitlers, setResetFitlers] = useState(false);
  const [totalUserCount, setTotalUserCount] = useState(0);
  const [fetchParams, setFetchParams] = useState<FetchDataParameters>({
    ...DEFAULTS,
  });

  const fetchData = async (params: FetchDataParameters): Promise<void> => {
    const { page, pageLength, sortBy, sortOrder, includeTotalCount } = params;
    const reqParams: GetUsersParameters = { page, pageLength, sortBy, sortOrder, includeTotalCount };

    if (params.search !== null) {
      const { search } = params;

      if (search.by === 'username') {
        reqParams.usernameLike = search.term;
      } else if (search.by === 'firstName') {
        reqParams.firstNameLike = search.term;
      } else if (search.by === 'lastName') {
        reqParams.lastNameLike = search.term;
      } else if (search.by === 'role') {
        reqParams.roles = search.term;
      } else if (search.by === 'isActive') {
        reqParams.isActive = search.term === 'true';
      } else if (search.by === 'customers') {
        await loadProjectContacts({ customerIds: search.term }, async (loadedProjectContacts) => {
          if (!loadedProjectContacts || loadedProjectContacts.length === 0) {
            reqParams.ids = '';
          } else {
            const userIds = [...new Set(loadedProjectContacts.map((p) => p.userId))];
            reqParams.ids = userIds.join(',');
          }
        });

        if (reqParams.ids === '') {
          setTotalUserCount(0);
          resetProjectContacts();
          resetUsers();
          return; // important
        }
      } else if (search.by === 'projects') {
        await loadProjectContacts({ projectIds: search.term }, async (loadedProjectContacts) => {
          if (!loadedProjectContacts || loadedProjectContacts.length === 0) {
            reqParams.ids = '';
          } else {
            const userIds = [...new Set(loadedProjectContacts.map((p) => p.userId))];
            reqParams.ids = userIds.join(',');
          }
        });

        if (reqParams.ids === '') {
          setTotalUserCount(0);
          resetProjectContacts();
          resetUsers();
          return; // important
        }
      }
    }

    await getUsers(reqParams, async (loadedUsers, pagingInfo) => {
      if (!loadedUsers) {
        resetUsers();
        resetProjectContacts();
        resetContacts();
        return;
      }

      if (params.includeTotalCount && pagingInfo) {
        setTotalUserCount(pagingInfo.totalCount);
      }

      const userIds = [...new Set(loadedUsers.map((user) => user.id))];

      if (userIds.length === 0) {
        resetProjectContacts();
      } else {
        loadProjectContacts({ userIds: userIds.join(',') });
      }
    });
  };

  useEffect(() => {
    getCustomers();
    getProjects(false);

    return () => {
      resetUsers();
      resetCustomers();
      resetProjectContacts();
      resetProjects();
    };
  }, []);

  const reloadData = async (): Promise<void> => {
    await fetchData(fetchParams);
  };

  const handleActivityStatusChange = async (user: User): Promise<void> => {
    await updateUser(user.id, { isActive: !user.isActive });
    await reloadData();
  };

  const handleDelete = async (id: string): Promise<void> => {
    await deleteUser(id);
    await reloadData();
  };

  const handleIdentityReset = async (userId: string): Promise<void> => {
    await resetIdentity(userId);
  };

  const handleTemporaryPasswordReset = async (userId: string): Promise<void> => {
    await resetTemporaryPassword(userId);
  };

  const updatePaging = (params: PagingConfig): void => {
    const pageChanged = params.page !== fetchParams.page;
    const pageLengthChanged = params.pageLength !== fetchParams.pageLength;
    const sortChanged = params.sortBy !== fetchParams.sortBy || params.sortOrder !== fetchParams.sortOrder;
    const searchChanged = JSON.stringify(params.search) !== JSON.stringify(fetchParams.search);

    const configChanged = pageChanged || pageLengthChanged || sortChanged || searchChanged;
    const updateTotal = pageLengthChanged || sortChanged || searchChanged;

    if (configChanged) {
      if (updateTotal) {
        setFetchParams((s) => ({ ...s, ...params, page: 1, includeTotalCount: updateTotal }));
      } else {
        setFetchParams((s) => ({ ...s, ...params }));
      }
    }
  };

  useEffect(() => {
    fetchData(fetchParams);
  }, [JSON.stringify(fetchParams)]);

  useEffect(() => {
    setFetchParams({ ...DEFAULTS });
  }, [resetFitlers]);

  return (
    <div className="Users">
      <Space style={{ marginBottom: 15 }}>
        {isAdmin && (
          <Button
            data-testid="users-space_create-button"
            type="primary"
            icon={<PlusOutlined />}
            size="middle"
            onClick={() => onCreateUser(reloadData)}
          >
            Create user
          </Button>
        )}

        <Button
          data-testid="users-space_reload-button"
          type="default"
          icon={<ReloadOutlined />}
          size="middle"
          onClick={reloadData}
        >
          Reload
        </Button>

        <Button
          data-testid="users-space_reset-button"
          type="default"
          icon={<ClearOutlined />}
          size="middle"
          onClick={() => setResetFitlers((s) => !s)}
        >
          Reset all filters
        </Button>
      </Space>
      {UserModal}
      <Spinner spinning={usersLoading || customersLoading || projectContactsLoading || projectsLoading}>
        <UserList
          resetFilters={resetFitlers}
          doResetFilters={() => setResetFitlers((s) => !s)}
          users={users}
          projects={projects}
          projectContacts={projectContacts}
          customers={customers}
          onDeleteUser={handleDelete}
          onEditClick={(user) => onEditUser(user, reloadData)}
          onAccountStatusClick={handleActivityStatusChange}
          onResetUserIdentity={handleIdentityReset}
          onResetTemporaryPassword={handleTemporaryPasswordReset}
          updatePaging={updatePaging}
          totalUserCount={totalUserCount}
          fetchParams={fetchParams}
        />
      </Spinner>
    </div>
  );
};

export default UsersContainer;
