import React, { FC } from 'react';

import { Input } from 'antd';
import { ICustomerSupportValueState } from 'context/customerSupportValue';

const { TextArea } = Input;

interface IToggleableTextArea {
  editModeActive: boolean;
  placeholderText: string;
  fieldName: keyof ICustomerSupportValueState;
  currentState: ICustomerSupportValueState;
  testidPrefix: string;
  onChangeFn: (updateState: ICustomerSupportValueState) => void;
  textAreaRows?: number;
}

const ToggleableTextArea: FC<IToggleableTextArea> = ({
  editModeActive,
  placeholderText,
  fieldName,
  currentState,
  testidPrefix,
  onChangeFn,
  textAreaRows,
}) => {
  const currentValue = currentState[fieldName];

  return (
    <div style={{ display: 'flex' }}>
      {editModeActive ? (
        <TextArea
          data-testid={`${testidPrefix}_edit-${fieldName.replace(' ', '-')}`}
          className="Toggleable_Text"
          placeholder={placeholderText}
          rows={textAreaRows}
          size="small"
          value={(currentValue as string) || ''}
          onChange={(e) => {
            const updateState: ICustomerSupportValueState = { ...currentState, [fieldName]: e.currentTarget.value };
            onChangeFn(updateState);
          }}
          allowClear
        />
      ) : (
        <span
          className="Toggleable_Text Toggleable_TextSpan"
          data-testid={`${testidPrefix}_value-${fieldName.replace(' ', '-')}`}
        >
          {currentValue}
        </span>
      )}
    </div>
  );
};

export default ToggleableTextArea;
