import React, { createContext, useRef, useState } from 'react';
import * as fulfillmentOrdersApi from 'api/fulfillmentOrders';
import { loadAllPages } from 'utils/functions';
import { serializeForDisplay } from 'utils/issues';
import Notification from 'utils/notification';
import { CancelTokenSource, makeCancelTokenSource } from 'services/httpClient';
import * as issues from 'utils/issues';
import { FulfillmentOrder } from '@tassoinc/core-api-client';

type State = {
  fulfillmentOrders: FulfillmentOrder[];
  loading: boolean;
  error: string | null;
};

type Context = State & {
  getOrderFulfillmentOrders: (orderId: string, callback?: FulfillmentOrdersCallback) => Promise<void>;
  getFulfillmentOrders: (params: any, callback?: FulfillmentOrdersCallback, manualLoading?: boolean) => Promise<void>;
  resetFulfillmentOrders: () => void;
};

const initialState: State = {
  fulfillmentOrders: [],
  loading: false,
  error: '',
};

type FulfillmentOrdersCallback = (result: FulfillmentOrder[] | null) => Promise<void> | void;

const FulfillmentOrdersContext = createContext<Context>({
  ...initialState,
  getOrderFulfillmentOrders: async () => {},
  getFulfillmentOrders: async () => {},
  resetFulfillmentOrders: () => {},
});

const FulfillmentOrdersProvider = (props: any) => {
  const [state, setState] = useState<State>(initialState);

  const cancelTokenSource = useRef<CancelTokenSource | null>(null);

  const getOrderFulfillmentOrders = async (orderId: string, callback?: FulfillmentOrdersCallback): Promise<void> => {
    try {
      setState((s) => ({
        ...s,
        error: null,
        loading: true,
      }));

      type PayloadType = Parameters<typeof fulfillmentOrdersApi.get>[0];
      const fulfillmentOrders = await loadAllPages<FulfillmentOrder, PayloadType>(fulfillmentOrdersApi.get, {
        orderIds: [orderId],
      });
      setState((s) => ({
        ...s,
        fulfillmentOrders,
      }));

      if (callback) {
        await callback(fulfillmentOrders);
      }
    } catch (e: any) {
      const errorMessage = serializeForDisplay(e?.response?.data?.issues) || e.message;
      setState((s) => ({
        ...s,
        error: errorMessage,
      }));

      Notification({
        type: 'error',
        message: 'Failed to get fulfillment orders',
        description: errorMessage,
      });

      if (callback) {
        await callback(null);
      }
    } finally {
      setState((s) => ({
        ...s,
        loading: false,
      }));
    }
  };

  const getFulfillmentOrders = async (
    params: any,
    callback?: FulfillmentOrdersCallback,
    manualLoading?: boolean
  ): Promise<void> => {
    try {
      cancelTokenSource.current = makeCancelTokenSource();

      setState((s) => ({
        ...s,
        error: null,
        loading: manualLoading ? s.loading : true,
      }));

      type PayloadType = Parameters<typeof fulfillmentOrdersApi.get>[0];
      const fulfillmentOrders = await loadAllPages<FulfillmentOrder, PayloadType>(
        fulfillmentOrdersApi.get,
        params,
        {
          pageLength: 100,
          sortBy: 'createdAt',
          isDescending: true,
        },
        { cancelToken: cancelTokenSource.current.token }
      );

      setState((s) => ({
        ...s,
        fulfillmentOrders,
      }));

      if (callback) {
        await callback(fulfillmentOrders);
      }
    } catch (e) {
      cancelTokenSource.current = null;

      const errorMessage = issues.getErrorMessage(e);

      setState((s) => ({
        ...s,
        error: errorMessage,
      }));

      Notification({
        type: 'error',
        message: 'Cannot get fulfillment order list',
        description: errorMessage,
      });
    } finally {
      setState((s) => ({
        ...s,
        loading: false,
      }));
    }
  };

  const resetFulfillmentOrders = (): void => {
    setState({ ...initialState });
  };

  return (
    <FulfillmentOrdersContext.Provider
      value={{
        fulfillmentOrders: state.fulfillmentOrders,
        loading: state.loading,
        error: state.error,
        getOrderFulfillmentOrders,
        getFulfillmentOrders,
        resetFulfillmentOrders,
      }}
      {...props}
    />
  );
};

const useFulfillmentOrders = (): Context => React.useContext(FulfillmentOrdersContext);

export { FulfillmentOrdersProvider, useFulfillmentOrders };
