import React, { createContext, FC, useRef, useState } from 'react';
import { CancelTokenSource, makeCancelTokenSource } from 'services/index';

import { getErrorMessage } from 'utils/issues';
import Notification from 'utils/notification';
import * as api from '../api/customerSupportValues';
import {
  CreateCustomerSupportValue,
  CustomerSupportValueRequestOptions,
  UpdateCustomerSupportValue,
} from '../api/customerSupportValues';

export interface ICustomerSupportValueState {
  patientSupportAllowed: boolean;
  patientSupportRedirect?: string | null;
  discussTestResults: boolean;
  logisticsSupport: boolean;
  deviceTroubleshooting: boolean;
  issueReplacements: boolean;
  contactName?: string | null;
  contactPhone?: string | null;
  contactEmail?: string | null;
}

// Projects may not have Customer Support values yet, in which case, these standard
// core API fields won't exist until first POST.
export interface ICustomerSupportValue extends ICustomerSupportValueState {
  id?: string;
  createdAt?: string;
  updatedAt?: string | null;
  version?: string;
  customerId?: string;
  projectId?: string;
}

export type CustomerSupportValue = ICustomerSupportValue;

type SetCustomerSupportValueStateFunction = (state: ICustomerSupportValueState) => void;

type GetCustomerSupportValueFunction = (
  id: string,
  callback?: (customerSupportValue: CustomerSupportValue | null) => Promise<void>
) => Promise<void>;

type CreateCustomerSupportValueFunction = (
  data: CreateCustomerSupportValue,
  callback?: (customerSupportValue: CustomerSupportValue | null) => Promise<void>
) => Promise<void>;

type UpdateCustomerSupportValueFunction = (
  id: string,
  data: UpdateCustomerSupportValue,
  callback?: (customerSupportValue: CustomerSupportValue | null) => Promise<void>
) => Promise<void>;

type State = {
  customerSupportValue: ICustomerSupportValue;
  loading: boolean;
  error: string | null;
};

type Context = State & {
  setCustomerSupportValueState: SetCustomerSupportValueStateFunction;
  getCustomerSupportValue: GetCustomerSupportValueFunction;
  createCustomerSupportValue: CreateCustomerSupportValueFunction;
  updateCustomerSupportValue: UpdateCustomerSupportValueFunction;
  resetCustomerSupportValueState: () => void;
};

const initialState: ICustomerSupportValueState = {
  patientSupportAllowed: false,
  discussTestResults: false,
  logisticsSupport: false,
  deviceTroubleshooting: false,
  issueReplacements: false,
  patientSupportRedirect: null,
  contactName: null,
  contactPhone: null,
  contactEmail: null,
};

const getInitialState = (): State => ({
  customerSupportValue: { ...initialState },
  loading: false,
  error: null,
});

// Do not provide default values so that we can catch an unrendered provider.
const CustomerSupportValueContext = createContext(null);

const CustomerSupportValueProvider: FC<any> = (props) => {
  const [state, setState] = useState<State>(getInitialState());
  const cancelTokenSource = useRef<CancelTokenSource | null>(null);

  const setCustomerSupportValueState = (csv: CustomerSupportValue): void => {
    setState((s) => ({
      ...s,
      customerSupportValue: csv,
    }));
  };

  const getCustomerSupportValue: GetCustomerSupportValueFunction = async (id, callback): Promise<void> => {
    setState((s) => ({
      ...s,
      loading: true,
      error: null,
    }));

    try {
      cancelTokenSource.current = makeCancelTokenSource();

      const options: CustomerSupportValueRequestOptions = {
        cancelToken: cancelTokenSource.current.token,
      };

      const customerSupportValue = await api.getCustomerSupportValue(id, options);

      if (customerSupportValue.isCancelledByClient) {
        return;
      }

      setState((s) => ({
        ...s,
        customerSupportValue,
      }));

      if (callback) {
        await callback(customerSupportValue);
      }
    } catch (e) {
      const errorMessage = getErrorMessage(e);

      setState((s) => ({
        ...s,
        error: errorMessage,
      }));

      Notification({
        type: 'error',
        message: 'Failed to get CustomerSupportValue details',
        description: errorMessage,
      });

      if (callback) {
        await callback(null);
      }
    } finally {
      setState((s) => ({
        ...s,
        loading: false,
      }));
    }
  };

  const createCustomerSupportValue: CreateCustomerSupportValueFunction = async (data, callback): Promise<void> => {
    setState((s) => ({
      ...s,
      loading: true,
      error: null,
    }));

    try {
      cancelTokenSource.current = makeCancelTokenSource();

      const options: CustomerSupportValueRequestOptions = {
        cancelToken: cancelTokenSource.current.token,
      };

      const customerSupportValue = await api.createCustomerSupportValue(data, options);

      if (customerSupportValue.isCancelledByClient) {
        return;
      }

      Notification({ type: 'success', message: 'CustomerSupportValue Created!' });

      setState((s) => ({
        ...s,
        customerSupportValue,
      }));

      if (callback) {
        await callback(customerSupportValue);
      }
    } catch (e) {
      const errorMessage = getErrorMessage(e);

      setState((s) => ({
        ...s,
        error: errorMessage,
      }));

      Notification({
        type: 'error',
        message: 'Failed to create CustomerSupportValue',
        description: errorMessage,
      });

      if (callback) {
        await callback(null);
      }
    } finally {
      setState((s) => ({
        ...s,
        loading: false,
      }));
    }
  };

  const updateCustomerSupportValue: UpdateCustomerSupportValueFunction = async (id, data, callback): Promise<void> => {
    setState((s) => ({
      ...s,
      loading: true,
      error: null,
    }));

    try {
      cancelTokenSource.current = makeCancelTokenSource();

      const options: CustomerSupportValueRequestOptions = {
        cancelToken: cancelTokenSource.current.token,
      };

      const updatedCustomerSupportValue = await api.updateCustomerSupportValue(id, data, options);

      if (updatedCustomerSupportValue.isCancelledByClient) {
        return;
      }

      setState((s) => ({
        ...s,
        customerSupportValue: updatedCustomerSupportValue,
      }));

      if (callback) {
        await callback(updatedCustomerSupportValue);
      }
    } catch (e) {
      const errorMessage = getErrorMessage(e);

      setState((s) => ({
        ...s,
        error: errorMessage,
      }));

      Notification({
        type: 'error',
        message: 'Failed to update CustomerSupportValue',
        description: errorMessage,
      });

      if (callback) {
        await callback(null);
      }
    } finally {
      setState((s) => ({
        ...s,
        loading: false,
      }));
    }
  };

  const resetCustomerSupportValueState = (): void => {
    setState(getInitialState());
  };

  return (
    <CustomerSupportValueContext.Provider
      value={{
        customerSupportValue: state.customerSupportValue,
        loading: state.loading,
        error: state.error,
        setCustomerSupportValueState,
        getCustomerSupportValue,
        createCustomerSupportValue,
        updateCustomerSupportValue,
        resetCustomerSupportValueState,
      }}
      {...props}
    />
  );
};

const useCustomerSupportValues = (): Context => {
  const ctx = React.useContext(CustomerSupportValueContext);

  if (!ctx) {
    throw new Error('useCustomerSupportValues hook used without CustomerSupportValueContext provider rendered.');
  }

  return ctx;
};

export { CustomerSupportValueProvider, useCustomerSupportValues };
