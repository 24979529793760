import React, { FC, useState, createContext } from 'react';

import { manifestsApi } from 'api';
import { loadAllPages } from 'utils/functions';
import * as issues from 'utils/issues';
import Notification from 'utils/notification';
import { ICreateManifest } from 'api/manifests';
import { MANIFEST } from 'constants/index';
import moment from 'moment';
import { getErrorMessage } from 'utils/issues';

export interface IManifest {
  id: string;
  shipperType: string;
  origin: string;
  shipDate: string;
  totalPieces: number | null;
  scanForms: string | null;
  createdAt: string;
  updatedAt: string | null;
}

interface IState {
  manifests: IManifest[];
  manifest: string;
  todayShipmentCount: number;
  loading: boolean;
  todayShipmentCountLoading: boolean;
  listLoaded: boolean;
  error: any;
}

interface IContext extends IState {
  getManifests: (callback?: any) => Promise<void>;
  createManifest: (data: manifestsApi.ICreateManifest, callback?: any) => Promise<void>;
  onCreateManifest: (data: manifestsApi.ICreateManifest, callback?: any) => Promise<void>;
  getManifest: (id: string, callback?: any) => Promise<void>;
  getShippingCountForToday: (callback?: any) => Promise<void>;
}

const sortByCreatedAt = (a: IManifest, b: IManifest): number => {
  const d1 = new Date(a.createdAt).getTime();
  const d2 = new Date(b.createdAt).getTime();

  if (d1 === d2) {
    return 0;
  }

  return d1 > d2 ? -1 : 1;
};

const getInitialState = (): IState => ({
  manifests: [],
  manifest: '',
  todayShipmentCount: 0,
  loading: false,
  todayShipmentCountLoading: false,
  listLoaded: false,
  error: null,
});

const ManifestsContext = createContext<IContext>({
  ...getInitialState(),
  getManifests: async () => {},
  createManifest: async () => {},
  onCreateManifest: async () => {},
  getManifest: async () => {},
  getShippingCountForToday: async () => {},
});

const ManifestsProvider: FC = (props: any) => {
  const [state, setState] = useState<IState>({ ...getInitialState() });

  const getManifests = async (callback: (items: IManifest[] | null) => void): Promise<void> => {
    try {
      setState((s) => ({
        ...s,
        loading: true,
        error: null,
      }));

      const manifests = await loadAllPages<any, any>(
        manifestsApi.getManifests,
        {},
        { pageLength: 50, sortBy: 'createdAt', isDescending: true }
      );

      setState((s) => ({
        ...s,
        loading: false,
        manifests: manifests.sort(sortByCreatedAt),
        listLoaded: true,
      }));

      if (callback) {
        await callback(manifests);
      }
    } catch (e: any) {
      setState((s) => ({
        ...s,
        loading: false,
        error: getErrorMessage(e),
      }));

      Notification({
        type: 'error',
        message: 'Cannot get manifests list',
        description: issues.serializeForDisplay(e?.response?.data?.issues) || e.message,
      });

      if (callback) {
        await callback(null);
      }
    }
  };

  const getShippingCountForToday = async (callback?: any): Promise<void> => {
    setState((s) => ({
      ...s,
      loading: true,
      todayShipmentCountLoading: true,
    }));

    try {
      const formattedTodayDate = moment().format('YYYY-MM-DD');
      const result = await manifestsApi.getShippingLabelsByOpenManifestDate(formattedTodayDate, MANIFEST.origin);

      setState((s) => ({
        ...s,
        todayShipmentCount: parseInt(result.paging.totalCount, 10),
        loading: false,
        todayShipmentCountLoading: false,
      }));

      if (callback) {
        await callback(result);
      }
    } catch (e: any) {
      console.log(e);

      setState((s) => ({
        ...s,
        loading: false,
        todayShipmentCountLoading: false,
        error: getErrorMessage(e),
      }));

      Notification({
        type: 'error',
        message: 'Shipping Label count was not fetched',
        description: issues.serializeForDisplay(e?.response?.data?.issues) || e.message,
      });

      if (callback) {
        await callback(null);
      }
    }
  };

  const createManifest = async (
    data: manifestsApi.ICreateManifest,
    callback: (newItem: IManifest | null) => void
  ): Promise<void> => {
    setState((s) => ({
      ...s,
      loading: true,
    }));

    try {
      const manifest = await manifestsApi.createManifest(data);

      setState((s) => ({
        ...s,
        loading: false,
        manifests: [...s.manifests, manifest].sort(sortByCreatedAt),
      }));

      Notification({ type: 'success', message: 'Manifest was successfully created' });

      if (callback) {
        await callback(manifest);
      }

      // Fetch updated shipping count for today
      getShippingCountForToday();
    } catch (e: any) {
      console.log(e);

      setState((s) => ({
        ...s,
        loading: false,
        error: getErrorMessage(e),
      }));

      Notification({
        type: 'error',
        message: 'Manifest was not created',
        description: getErrorMessage(e),
      });

      if (callback) {
        await callback(null);
      }
    }
  };
  const onCreateManifest = (data: ICreateManifest) => createManifest(data, (newItem) => newItem);

  const getManifest = async (id: string, callback?: any): Promise<void> => {
    setState((s) => ({
      ...s,
      loading: true,
    }));

    try {
      const manifest = await manifestsApi.getManifestById(id);

      setState((s) => ({
        ...s,
        loading: false,
      }));

      Notification({ type: 'success', message: 'Manifest was successfully fetched' });

      if (callback) {
        await callback(manifest);
      }
    } catch (e: any) {
      console.log(e);

      setState((s) => ({
        ...s,
        loading: false,
        error: getErrorMessage(e),
      }));

      Notification({
        type: 'error',
        message: 'Manifest was not fetched',
        description: getErrorMessage(e),
      });

      if (callback) {
        await callback(null);
      }
    }
  };

  return (
    <ManifestsContext.Provider
      value={{
        manifests: state.manifests,
        todayShipmentCount: state.todayShipmentCount,
        loading: state.loading,
        listLoaded: state.listLoaded,
        error: state.error,
        getManifests,
        createManifest,
        onCreateManifest,
        getManifest,
        getShippingCountForToday,
        todayShipmentCountLoading: state.todayShipmentCountLoading,
      }}
      {...props}
    />
  );
};

const useManifests = (): IContext => React.useContext(ManifestsContext);

export { ManifestsProvider, useManifests };
