import React, { FC, ReactElement } from 'react';

import { Space, Typography, Button, /* Popconfirm, */ Descriptions } from 'antd';

import { /* DeleteOutlined, */ EditOutlined, CloudDownloadOutlined } from 'components/icons';
import { LAB_ADAPTER_KEYS, USER_ROLES } from 'constants/index';
import { Laboratory } from 'context/laboratories';
import { useAuth } from 'context/auth';

interface ILaboratoryInformation {
  laboratory: Laboratory;
  onEdit: () => void;
  onRemove: () => void;
  refreshLab: (labId: string, callback?: (lab: Laboratory | null) => void) => Promise<void>;
}

const { Text } = Typography;

const makeAddress = (lab: Laboratory): ReactElement => {
  const parts = `${lab.attentionName}\n${lab.address1}\n${lab.address2}\n${lab.city} ${lab.district1} ${lab.postalCode}`
    .replace(/ +/g, ' ') // replace multiple spaces with one
    .trim() // trim whitespaces on start and end
    .split(/\n+/g); // split on new line

  return (
    <>
      {parts.map((p) => (
        <div key={p}>{p}</div>
      ))}
    </>
  );
};

const LaboratoryInformation: FC<ILaboratoryInformation> = ({ laboratory, onEdit, /* onRemove, */ refreshLab }) => (
  <div className="LaboratoryInformation">
    <Space direction="vertical">
      <Descriptions
        title={laboratory.name}
        bordered
        size="small"
        column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
      >
        <Descriptions.Item label="Shipping address">{makeAddress(laboratory)}</Descriptions.Item>
        <Descriptions.Item label="Phone number">
          <Text>{laboratory.phoneNumber || ''}</Text>
        </Descriptions.Item>
        <Descriptions.Item label="Email">
          <Text>{laboratory.email || ''}</Text>
        </Descriptions.Item>
        <Descriptions.Item label="Adapter key">
          <Text>
            {laboratory.adapterKey && laboratory.adapterKey in LAB_ADAPTER_KEYS
              ? LAB_ADAPTER_KEYS[laboratory.adapterKey as keyof typeof LAB_ADAPTER_KEYS]
              : laboratory.adapterKey || ''}
          </Text>
        </Descriptions.Item>
        <Descriptions.Item label="Requisition Form Template">
          <Text>
            {laboratory.reqFormKey ? (
              <Button
                type="link"
                size="small"
                icon={<CloudDownloadOutlined />}
                onClick={(e) => {
                  e.preventDefault();
                  refreshLab(laboratory.id, (lab: Laboratory | null) => {
                    // eslint-disable-next-line security/detect-non-literal-fs-filename
                    window.open(lab!.reqFormUrl, '_blank');
                  });
                }}
              >
                Download current form
              </Button>
            ) : (
              ''
            )}
          </Text>
        </Descriptions.Item>
        <Descriptions.Item label="Requisition Form Configuration">
          <Text>{laboratory.textConfig ? <code>{JSON.stringify(laboratory.textConfig)}</code> : ''}</Text>
        </Descriptions.Item>
        <Descriptions.Item label="USPS MID">
          <Text>{laboratory.uspsMid || ''}</Text>
        </Descriptions.Item>
        <Descriptions.Item label="USPS Merchant Account Id">
          <Text>{laboratory.uspsMerchantAccountId || ''}</Text>
        </Descriptions.Item>
        <Descriptions.Item label="USPS Merchant Account Code">
          <Text>{laboratory.uspsMerchantAccountCode || ''}</Text>
        </Descriptions.Item>
      </Descriptions>

      <div className="LaboratoryInformation__ActionBtns">
        {(useAuth().profile?.role || '') === USER_ROLES.internalAdmin && (
          <Button size="middle" onClick={onEdit} icon={<EditOutlined />}>
            Edit laboratory
          </Button>
        )}

        {/* <Popconfirm
          title="Are you sure you want to delete this laboratory?"
          onConfirm={onRemove}
          okText="Yes"
          cancelText="No"
        >
          <Button size="middle" icon={<DeleteOutlined />} danger style={{ marginLeft: '15px' }}>
            Delete
          </Button>
        </Popconfirm> */}
      </div>
    </Space>
  </div>
);

export default LaboratoryInformation;
