import {
  OrderTemplate,
  OrderTemplateFulfillmentType,
  OrderTemplateResponsePropertiesItemsInner,
  OtiBox,
  OtiDeviceKit,
} from '@tassoinc/core-api-client';
import { KIT_ITEM_TYPES, OrderTemplateSchemaTypes } from 'constants/index';
import { IKitItem } from 'context/kitItems';
import { NEW_OT_ID } from 'context/orderTemplates';
import { Analyte } from 'context/analytes';

/**
 * Recursively scans all Order Template items and returns those matching the given schemaType.
 */
export const findOrderTemplateItemsBySchemaType = (
  items: OrderTemplateResponsePropertiesItemsInner[],
  schemaType: string
): OrderTemplateResponsePropertiesItemsInner[] => {
  if (!items || items.length === 0) {
    return [];
  }

  const results: OrderTemplateResponsePropertiesItemsInner[] = [];

  for (const item of items) {
    const innerResult = findOrderTemplateItemsBySchemaType((item as any).items, schemaType);

    results.push(...innerResult);

    if (item.schemaType === schemaType) {
      results.push(item);
    }
  }

  return results;
};

export const mapKitItemTypeToSchemaType = (
  kitItem: IKitItem
): typeof OrderTemplateSchemaTypes[keyof typeof OrderTemplateSchemaTypes] => {
  switch (kitItem.kitItemType) {
    case KIT_ITEM_TYPES.printable:
      return OrderTemplateSchemaTypes.PRINTABLE;
    case KIT_ITEM_TYPES.packingInstructions:
      return OrderTemplateSchemaTypes.PACKING_INSTRUCTIONS;
    case KIT_ITEM_TYPES.box:
      return OrderTemplateSchemaTypes.BOX;
    case KIT_ITEM_TYPES.deviceKit:
      return OrderTemplateSchemaTypes.DEVICE_KIT;
    case KIT_ITEM_TYPES.shippingLabel:
      return OrderTemplateSchemaTypes.SHIPPING_LABEL;
    default:
      return OrderTemplateSchemaTypes.GENERIC;
  }
};

export const mapSchemaTypeToLabel = (schemaType: string): string => {
  switch (schemaType) {
    case OrderTemplateSchemaTypes.ANALYTE:
      return 'Analyte';
    case OrderTemplateSchemaTypes.BOX:
      return 'Box';
    case OrderTemplateSchemaTypes.DEVICE_KIT:
      return 'Device Kit';
    case OrderTemplateSchemaTypes.GENERIC:
      return 'Generic';
    case OrderTemplateSchemaTypes.PACKING_INSTRUCTIONS:
      return 'Packing Instructions';
    case OrderTemplateSchemaTypes.PRINTABLE:
      return 'Printable';
    case OrderTemplateSchemaTypes.SHIPPING_LABEL:
      return 'Shipping Label';
    default:
      return schemaType || 'SCHEMA TYPE NOT SET';
  }
};

export function hasItems(
  it: OrderTemplateResponsePropertiesItemsInner | OrderTemplate
): it is OtiBox | OtiDeviceKit | OrderTemplate {
  return 'items' in it;
}

export function canAddChildren(item: OrderTemplateResponsePropertiesItemsInner): boolean {
  if (item.schemaType === OrderTemplateSchemaTypes.DEVICE_KIT) {
    return true;
  }

  if (item.schemaType === OrderTemplateSchemaTypes.BOX) {
    return true;
  }

  return false;
}

export function otiFactory(kitItem: IKitItem, orderTemplateId: string): OrderTemplateResponsePropertiesItemsInner {
  const base = {
    coreApiId: kitItem.id,
    coreApiType: 'kitItem',
    schemaType: mapKitItemTypeToSchemaType(kitItem),
    orderTemplateId,
  };
  if (canAddChildren(base)) {
    (base as any).items = [];
  }
  return base;
}

export function newOrderTemplate(
  projectId: string,
  fulfillmentType: OrderTemplateFulfillmentType = OrderTemplateFulfillmentType.Tasso
): OrderTemplate {
  return {
    name: 'new template',
    items: [],
    id: NEW_OT_ID,
    createdAt: '',
    projectId,
    templateId: 'new',
    fulfillmentType,
    isCustomerOrderable: true,
  };
}

export const mapName = (
  item: OrderTemplateResponsePropertiesItemsInner,
  analytes: Analyte[],
  kitItems: IKitItem[]
): string => {
  if (item.schemaType === OrderTemplateSchemaTypes.ANALYTE) {
    const analyte = analytes.find((a) => a.id === item.coreApiId);
    if (analyte) {
      return `${analyte.friendlyname}`;
    }
  } else if (item.coreApiType === 'kitItem') {
    const kitItem = kitItems.find((k) => k.id === item.coreApiId);
    if (kitItem) {
      return ` ${kitItem.key} ${kitItem.description}`;
    }
  }

  return 'Unknown - Please Contact Support';
};

/**
 * Recursively loops through all order template items and finds the one that matches
 * the given orderTemplateId value.
 */
export const findItemByOrderTemplateId = (
  orderTemplateId: string,
  items: any[]
): OrderTemplateResponsePropertiesItemsInner | null => {
  if (!items || items.length === 0) {
    return null;
  }

  for (const item of items) {
    const result = findItemByOrderTemplateId(orderTemplateId, item.items);

    if (result) {
      return result;
    }

    if (item.orderTemplateId === orderTemplateId) {
      return item;
    }
  }

  return null;
};

export const formatError = (error: string) => {
  const value = `${error.charAt(0).toUpperCase()}${error.slice(1)}`;
  if (value.endsWith('.')) {
    return value;
  }
  return `${value}.`;
};

export const innerDeleteItem = (
  target: OrderTemplateResponsePropertiesItemsInner,
  items: OrderTemplateResponsePropertiesItemsInner[]
): OrderTemplateResponsePropertiesItemsInner[] => {
  if (!items) {
    return items;
  }
  if (!target.orderTemplateId) {
    throw Error('Programmer error. Target ordertemplateId undefined.');
  }
  if (items.find((i) => i.orderTemplateId === target.orderTemplateId)) {
    return items.filter((i) => i.orderTemplateId !== target.orderTemplateId);
  }

  items.forEach((item) => {
    const updated = item;
    if (hasItems(updated) && updated.items) {
      const innerItems = updated.items;
      updated.items = innerDeleteItem(target, innerItems);
    }
  });

  return items;
};
