import { Select } from 'antd';
import { SummarizedOrderTemplate } from 'features/Projects/Project/ProjectShipments';

import React, { FC } from 'react';

type ISelectOrderTemplate = {
  summarizedOrderTemplates: SummarizedOrderTemplate[];
  selectedOrderTemplateId: string;
  setSelectedOrderTemplateId: (id: string) => void;
};
const SelectOrderOrderTemplate: FC<ISelectOrderTemplate> = ({
  summarizedOrderTemplates,
  selectedOrderTemplateId,
  setSelectedOrderTemplateId,
}) => (
  <>
    <Select
      value={selectedOrderTemplateId}
      style={{ width: 450, border: '1px solid' }}
      onSelect={(templateId: string) => setSelectedOrderTemplateId(templateId)}
    >
      {summarizedOrderTemplates?.map((t) => (
        <Select.Option key={t.id} value={t.id}>
          {t.name}
        </Select.Option>
      ))}
    </Select>
  </>
);

export default SelectOrderOrderTemplate;
