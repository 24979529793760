import React, { FC, useEffect } from 'react';
import { IProject } from 'context/projects';
import { Spinner } from 'components';
import { ICustomerSupportValueState, useCustomerSupportValues } from 'context/customerSupportValue';
import CustomerSupportDetails from 'components/CustomerSupportDetails';

const CustomerSupport: FC<{ project: IProject }> = ({ project }) => {
  const {
    customerSupportValue,
    createCustomerSupportValue,
    updateCustomerSupportValue,
    setCustomerSupportValueState,
    loading,
    error,
  } = useCustomerSupportValues();

  const preferProjectCustomerSupportValues = () => {
    // Prefer the updatedAt date since it's guaranteed to be more recent than createdAt. If the CSV hasn't been
    // created for the project yet, this will be undefined.
    const projectDate = project?.customerSupportValue?.updatedAt ?? project?.customerSupportValue?.createdAt;

    // Once the CSV has been created for the project, updatedAt will change with each Save. Meanwhile, the
    // timestamps on the project only update when the project is reloaded (done when navigating away and back).
    const csvDate = customerSupportValue.updatedAt;

    // Return true if we have a projectDate but not csvDate (meaning we have a CSV from the project but haven't set state
    // on customerSupportValue yet) or if we have both dates but the project has a newer timestamp.
    return (
      (projectDate && !csvDate) ||
      (projectDate && csvDate && new Date(projectDate).getTime() > new Date(csvDate).getTime())
    );
  };

  useEffect(() => {
    if (preferProjectCustomerSupportValues()) {
      setCustomerSupportValueState(project.customerSupportValue!);
    }
  }, [project]);

  const handleCreateOrUpdate = async (csv: ICustomerSupportValueState) => {
    if (!csv) {
      return;
    }

    const value = {
      patientSupportAllowed: csv.patientSupportAllowed,
      discussTestResults: csv.discussTestResults,
      logisticsSupport: csv.logisticsSupport,
      deviceTroubleshooting: csv.deviceTroubleshooting,
      issueReplacements: csv.issueReplacements,
      patientSupportRedirect: csv.patientSupportRedirect ?? null,
      contactName: csv.contactName ?? null,
      contactPhone: csv.contactPhone && csv.contactPhone.length > 0 ? csv.contactPhone : null,
      contactEmail: csv.contactEmail ?? null,
    };

    if (project.customerSupportValueId) {
      // Already exists, we update.
      updateCustomerSupportValue(project.customerSupportValueId, value);
      return;
    }

    createCustomerSupportValue({ ...value, projectId: project.id });
  };

  return (
    <div
      style={{
        background: '#ffffff',
        height: 'calc(100vh - 64px - 64px - 48px - 64px)',
        overflowY: 'auto',
      }}
    >
      <Spinner spinning={loading}>
        <CustomerSupportDetails
          customerSupportValueState={customerSupportValue}
          submitFailed={!!error}
          onSave={handleCreateOrUpdate}
        />
      </Spinner>
    </div>
  );
};

export default CustomerSupport;
