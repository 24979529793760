import React, { FC, useState, createContext } from 'react';

import { loadAllPages } from 'utils/functions';
import { getErrorMessage } from 'utils/issues';

import { projectKitItemsApi as api } from '../api';
import Notification from '../utils/notification';
import { IKitItem } from './kitItems';

export interface IProjectKitItem {
  id: string;
  projectId: string;
  kitItemId: string;
  kitItem?: IKitItem;
  viewOrder: number;
  createdAt: string;
  updatedAt: string | null;
  quantity: number;
}

interface State {
  projectKitItems: IProjectKitItem[];
  projectKitItem: IProjectKitItem | null;
  loading: boolean;
  error: string | null;
}

interface Context extends State {
  getProjectKitItems: (projectId: string, callback?: any) => Promise<void>;
  getProjectKitItem: (id: string, callback?: any) => Promise<void>;
  createProjectKitItem: (data: api.ICreateProjectKitItemData, callback?: any) => Promise<void>;
  updateProjectKitItem: (id: string, data: api.IUpdateProjectKitItemData, options?: any) => Promise<void>;
  bulkUpdateProjectKitItems: (
    updateItems: { [projectKitItemId: string]: api.IUpdateProjectKitItemData },
    callback?: any
  ) => Promise<void>;
  deleteProjectKitItem: (id: string, callback?: any) => Promise<void>;
  resetProjectKitItems: () => void;
}

const getInitialState = (): State => ({ projectKitItems: [], projectKitItem: null, loading: false, error: null });

const sortByViewOrder = (a: IProjectKitItem, b: IProjectKitItem): -1 | 0 | 1 => {
  if (a.viewOrder === b.viewOrder) {
    return 0;
  }
  return a.viewOrder > b.viewOrder ? 1 : -1;
};

const ProjectKitItemsContext = createContext<Context>({
  ...getInitialState(),
  getProjectKitItems: async () => {},
  getProjectKitItem: async () => {},
  createProjectKitItem: async () => {},
  updateProjectKitItem: async () => {},
  bulkUpdateProjectKitItems: async () => {},
  deleteProjectKitItem: async () => {},
  resetProjectKitItems: () => {},
});

const ProjectKitItemsProvider: FC = (props: any) => {
  const [state, setState] = useState<State>(getInitialState());

  const getProjectKitItems = async (
    projectId: string,
    callback: (items: IProjectKitItem[] | null) => void
  ): Promise<void> => {
    try {
      setState((s) => ({
        ...s,
        loading: true,
        error: null,
      }));

      type PayloadType = Parameters<typeof api.getProjectKitItems>[0];
      const items = await loadAllPages<IProjectKitItem, PayloadType>(api.getProjectKitItems, projectId, {
        pageLength: 50,
        sortBy: 'createdAt',
        isDescending: false,
      });

      setState((s) => ({
        ...s,
        loading: false,
        projectKitItems: items.sort(sortByViewOrder),
      }));

      if (callback) {
        await callback(items);
      }
    } catch (e) {
      const errorMessage = getErrorMessage(e);

      setState((s) => ({
        ...s,
        loading: false,
        error: errorMessage,
      }));

      Notification({
        duration: 2,
        type: 'error',
        message: 'Cannot get kit items',
        description: errorMessage,
      });

      if (callback) {
        await callback(null);
      }
    }
  };

  const getProjectKitItem = async (id: string, callback: (item: IProjectKitItem | null) => void): Promise<void> => {
    setState((s) => ({
      ...s,
      loading: true,
      error: null,
    }));

    try {
      const item = await api.getProjectKitItem(id);

      setState((s) => ({
        ...s,
        loading: false,
        projectKitItem: item,
      }));

      if (callback) {
        await callback(item);
      }
    } catch (e) {
      const errorMessage = getErrorMessage(e);

      setState((s) => ({
        ...s,
        loading: false,
        error: errorMessage,
      }));

      Notification({
        type: 'error',
        message: 'Cannot get kit item details',
        description: errorMessage,
      });

      if (callback) {
        await callback(null);
      }
    }
  };

  const createProjectKitItem = async (
    data: api.ICreateProjectKitItemData,
    callback: (newItem: IProjectKitItem | null) => void
  ): Promise<void> => {
    setState((s) => ({
      ...s,
      loading: true,
      error: null,
    }));

    try {
      const item = await api.createProjectKitItem(data);

      setState((s) => ({
        ...s,
        loading: false,
        projectKitItems: [...s.projectKitItems, item].sort(sortByViewOrder),
      }));

      Notification({ type: 'success', message: 'Kit item linked to project' });

      if (callback) {
        callback(item);
      }
    } catch (e) {
      const errorMessage = getErrorMessage(e);

      setState((s) => ({
        ...s,
        loading: false,
        error: errorMessage,
      }));

      Notification({
        type: 'error',
        message: 'Kit item link not created',
        description: errorMessage,
      });

      if (callback) {
        callback(null);
      }
    }
  };

  type UpdateProjectKitItemPrams = { showNotification?: boolean; callback?: (newItem: IProjectKitItem | null) => void };
  const updateProjectKitItem = async (
    id: string,
    data: api.IUpdateProjectKitItemData,
    inputParams?: UpdateProjectKitItemPrams
  ): Promise<void> => {
    const params = {
      showNotification:
        inputParams && typeof inputParams.showNotification === 'boolean' ? inputParams.showNotification : true,
      callback: inputParams && typeof inputParams.callback === 'function' ? inputParams.callback : undefined,
    };

    setState((s) => ({
      ...s,
      loading: true,
      error: null,
    }));

    try {
      const updatedItem = await api.updateProjectKitItem(id, data);

      setState((s) => ({
        ...s,
        loading: false,
        projectKitItems: s.projectKitItems.map((item) => (item.id === id ? updatedItem : item)),
        projectKitItem: s.projectKitItem && s.projectKitItem.id === id ? updatedItem : s.projectKitItem,
      }));

      if (params.showNotification) {
        Notification({ type: 'success', message: 'Kit item link updated' });
      }

      if (params.callback) {
        await params.callback(updatedItem);
      }
    } catch (e) {
      const errorMessage = getErrorMessage(e);

      setState((s) => ({
        ...s,
        loading: false,
        error: errorMessage,
      }));

      if (params.showNotification) {
        Notification({
          type: 'error',
          message: 'Kit item link not updated',
          description: errorMessage,
        });
      }

      if (params.callback) {
        params.callback(null);
      }
    }
  };

  const bulkUpdateProjectKitItems = async (
    updateItems: { [projectKitItemId: string]: api.IUpdateProjectKitItemData },
    callback?: any
  ): Promise<void> => {
    setState((s) => ({
      ...s,
      loading: true,
      error: null,
    }));

    try {
      const results: any[] = await Promise.all(
        Object.keys(updateItems).map((id) => api.updateProjectKitItem(id, updateItems[id]))
      );

      const items = [...state.projectKitItems];

      results.forEach((r) => {
        const i = items.findIndex((x) => x.id === r.id);
        items[i] = { ...items[i], ...r };
      });

      setState((s) => ({
        ...s,
        loading: false,
        projectKitItems: items,
      }));

      Notification({ type: 'success', message: `Kit item${results.length === 1 ? '' : 's'} links updated` });

      if (callback) {
        await callback();
      }
    } catch (e) {
      const errorMessage = getErrorMessage(e);

      setState((s) => ({
        ...s,
        loading: false,
        error: errorMessage,
      }));

      Notification({
        type: 'error',
        message: 'Kit items links not updated',
        description: errorMessage,
      });

      if (callback) {
        await callback();
      }
    }
  };

  const deleteProjectKitItem = async (
    id: string,
    callback: (newItem: IProjectKitItem | null) => void
  ): Promise<void> => {
    setState((s) => ({
      ...s,
      loading: true,
      error: null,
    }));

    try {
      const deletedItem = await api.deleteProjectKitItem(id);

      const items = [...state.projectKitItems].filter((item) => item.id !== id);

      setState((s) => ({
        ...s,
        loading: false,
        kitItems: items,
      }));

      Notification({ type: 'success', message: 'Kit item link deleted' });

      if (callback) {
        await callback(deletedItem);
      }
    } catch (e) {
      const errorMessage = getErrorMessage(e);

      setState((s) => ({
        ...s,
        loading: false,
        error: errorMessage,
      }));

      Notification({
        type: 'error',
        message: 'Kit item link not deleted',
        description: errorMessage,
      });

      if (callback) {
        await callback(null);
      }
    }
  };

  const resetProjectKitItems = (): void => {
    setState(getInitialState());
  };

  return (
    <ProjectKitItemsContext.Provider
      value={{
        projectKitItems: state.projectKitItems,
        projectKitItem: state.projectKitItem,
        loading: state.loading,
        error: state.error,
        getProjectKitItems,
        getProjectKitItem,
        createProjectKitItem,
        updateProjectKitItem,
        bulkUpdateProjectKitItems,
        deleteProjectKitItem,
        resetProjectKitItems,
      }}
      {...props}
    />
  );
};

const useProjectKitItems = (): Context => React.useContext(ProjectKitItemsContext);

export { ProjectKitItemsProvider, useProjectKitItems };
