import React, { FC, ReactNode } from 'react';

import { Spin } from 'antd';

interface ISpinner {
  spinning: boolean;
  tip?: string;
  children?: ReactNode;
  size?: 'small' | 'large' | 'default';
}

const Spinner: FC<ISpinner> = ({ spinning, tip = 'Loading...', size = 'default', children }) => (
  <Spin spinning={spinning} tip={tip || undefined} wrapperClassName="Spinner" size={size}>
    {children}
  </Spin>
);

export default Spinner;
