import React, { FC, useCallback, useEffect, useState, useMemo } from 'react';

import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table/interface';
import { useHistory } from 'react-router-dom';

import { ROUTES } from 'constants/routes';
import { ProjectMetrics } from 'context/projectMetrics';
import { IProject } from 'context/projects';
import useColumnSearch from 'hooks/useColumnSearch';
import { USER_ROLES, PROJECT_STATUSES, DISTRIBUTION_MODELS } from 'constants/index';
import { useAuth } from 'context/auth';

interface Props {
  projects: IProject[];
  metrics: ProjectMetrics[];
  loading: boolean;
  type: string;
}

interface TableRow {
  id: string;
  name: string;
  customerName: string;
  distributionModel: string;
  deviceCount: bigint;
  readyToShipCount: bigint;
}

const ProjectsList: FC<Props> = ({ projects, metrics, loading, type }) => {
  const history = useHistory();
  const { getColumnSearchProps } = useColumnSearch<TableRow>();
  const [metricsHash, setMetricsHash] = useState<Record<string, number>>({});
  const { profile } = useAuth();
  const isShipper = (profile?.role || '') === USER_ROLES.internalShipper;

  const columns: ColumnsType<TableRow> = useMemo(
    () => [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        sorter: (a, b) => a.name.localeCompare(b.name),
        sortDirections: ['descend', 'ascend'],
        ...getColumnSearchProps('name', null, true),
      },
      {
        title: 'Customer',
        dataIndex: 'customerName',
        key: 'customerName',
        sorter: (a, b) => a.customerName.localeCompare(b.customerName),
        sortDirections: ['descend', 'ascend'],
        ...getColumnSearchProps('customerName', null, true),
      },
      {
        title: 'Distribution Model',
        dataIndex: 'distributionModel',
        key: 'distributionModel',
        sorter: (a, b) => a.distributionModel.localeCompare(b.distributionModel),
        sortDirections: ['descend', 'ascend'],
        filters: [
          {
            text: DISTRIBUTION_MODELS.directToPatient,
            value: DISTRIBUTION_MODELS.directToPatient,
          },
          {
            text: DISTRIBUTION_MODELS.atCustomerSite,
            value: DISTRIBUTION_MODELS.atCustomerSite,
          },
        ],
        onFilter: (value, record) => record.distributionModel.indexOf(value as string) === 0,
      },
      {
        title: 'Device Count',
        key: 'deviceCount',
        dataIndex: 'deviceCount',
        render: (_, record) => record.deviceCount.toString(),
        sorter: (a, b) => {
          const v1 = a.deviceCount;
          const v2 = b.deviceCount;

          if (v1 === v2) {
            return 0;
          }

          return v1 > v2 ? 1 : -1;
        },
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Ready To Ship Count',
        key: 'readyToShipCount',
        dataIndex: 'readyToShipCount',
        render: (_, record) => record.readyToShipCount.toString(),
        sorter: (a, b) => {
          const v1 = a.readyToShipCount;
          const v2 = b.readyToShipCount;

          if (v1 === v2) {
            return 0;
          }

          return v1 > v2 ? 1 : -1;
        },
        sortDirections: ['descend', 'ascend'],
        defaultSortOrder: isShipper ? 'descend' : undefined,
        defaultFilteredValue: isShipper ? ['0'] : undefined,
        onFilter: (_, record) => (isShipper ? record.readyToShipCount !== BigInt(0) : true),
      },
    ],
    [getColumnSearchProps]
  );

  const openProjectInNewWindow = (projectId: string): void => {
    // eslint-disable-next-line security/detect-non-literal-fs-filename
    window.open(`${ROUTES.projects}/${projectId}`);
  };

  const onRowClick = (row: TableRow) => ({
    onMouseUp: (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      // mouse wheel release (click)
      if (e.button === 1) {
        openProjectInNewWindow(row.id);
      }
    },
    onClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      if (e.ctrlKey || e.metaKey) {
        openProjectInNewWindow(row.id);
        return;
      }

      history.push(`${ROUTES.projects}/${row.id}`);
    },
  });

  useEffect(() => {
    const hash = metrics.reduce(
      (acc, projectMetrics, index) => ({ ...acc, [projectMetrics.projectId]: index }),
      {} as Record<string, number>
    );

    setMetricsHash(hash);
  }, [metrics]);

  const getMetrics = useCallback(
    (projectId: string) =>
      typeof metricsHash[projectId as keyof typeof metricsHash] === 'number'
        ? metrics[metricsHash[projectId as keyof typeof metricsHash]] || null
        : null,
    [metricsHash]
  );

  const dataSource: TableRow[] = useMemo(
    () =>
      loading
        ? []
        : projects.map((project) => {
            const projectMetrics = getMetrics(project.id);
            return {
              id: project.id,
              name: project.name,
              customerName: project.customer?.name || '',
              distributionModel: DISTRIBUTION_MODELS[project.distributionModel],
              deviceCount:
                projectMetrics && typeof projectMetrics.deviceCount === 'bigint'
                  ? projectMetrics.deviceCount
                  : BigInt(0),
              readyToShipCount:
                projectMetrics &&
                typeof projectMetrics.devicesWhereStatusIsReadyToShipCount === 'bigint' &&
                (project.allowFulfillment || !isShipper) // if allowFulfillment is disabled, show 0 devices for readyToShipCount for shippers
                  ? projectMetrics.devicesWhereStatusIsReadyToShipCount
                  : BigInt(0),
            };
          }),
    [loading, projects, metrics, metricsHash]
  );

  return (
    <div className="ProjectsList">
      <Table
        rowKey="id"
        columns={columns}
        dataSource={dataSource}
        loading={loading}
        onRow={onRowClick}
        showSorterTooltip={false}
        tableLayout="fixed"
        pagination={{
          defaultPageSize: 100,
          showTotal: (total) =>
            `${total} ${type === PROJECT_STATUSES.archived ? 'archived' : 'open'} project${total === 1 ? '' : 's'}`,
          size: 'default',
          showSizeChanger: true,
          hideOnSinglePage: false,
          responsive: true,
          pageSizeOptions: ['10', '20', '50', '100', '250', '500'],
        }}
      />
    </div>
  );
};

export default ProjectsList;
