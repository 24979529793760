/* eslint-disable no-template-curly-in-string */
import React, { FC, useEffect, useRef } from 'react';

import { Form, Input, Modal, Select, FormInstance } from 'antd';

import { UpdateEmailTemplatePayload } from 'api/notificationTemplates';
import { Customer } from 'context/customers';
import { SmsTemplate } from 'context/notificationTemplates/types';

type Props = {
  template?: SmsTemplate;
  visible: boolean;
  loading: boolean;
  customers: Customer[];
  onClose: () => void;
  createTemplate: (data: any, modalCallback: () => void) => void;
  updateTemplate: (data: UpdateEmailTemplatePayload, modalCallback: () => void) => void;
};

const SmsTemplateModal: FC<Props> = ({
  template,
  visible,
  loading,
  customers,
  onClose,
  createTemplate,
  updateTemplate,
}) => {
  const form = useRef<FormInstance>(null);

  const handleClose = (): void => {
    form.current?.resetFields();
    onClose();
  };

  const handleSubmit = (): void => {
    form.current
      ?.validateFields()
      .then((values) => {
        const filteredValues = Object.keys(values).reduce(
          (acc, k) => ({ ...acc, [k]: values[k]?.trim() ?? null }),
          {} as Record<string, any>
        );
        if (filteredValues.parameters) {
          filteredValues.parameters = JSON.parse(filteredValues.parameters);
        }

        // If template already exists, this is an update
        if (template) {
          updateTemplate(filteredValues, handleClose);
        } else {
          createTemplate(filteredValues, handleClose);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const validateMessages = {
    required: '${label} is required!',
  };

  useEffect(() => {
    if (visible) {
      form.current?.setFieldsValue({
        templateName: template?.templateName || '',
        description: template?.description || '',
        body: template?.body || '',
        parameters: template?.parameters ? JSON.stringify(template?.parameters, null, 2) : '',
        customerId: template?.customerId || '',
      });
    }
  }, [visible]);

  return (
    <Modal
      visible={visible}
      wrapClassName="Modal AnalyteModal"
      title={template ? 'Update SMS template' : 'Create SMS template'}
      okText={template ? 'Update' : 'Create'}
      cancelText="Cancel"
      onCancel={handleClose}
      onOk={handleSubmit}
      confirmLoading={loading}
      centered
      width={500}
    >
      <Form
        ref={form}
        className="Form AnalyteModal__Form"
        layout="vertical"
        validateMessages={validateMessages}
        requiredMark="optional"
      >
        <Form.Item name="templateName" label="Template name" required rules={[{ required: true }]}>
          <Input placeholder="Enter template name" />
        </Form.Item>

        <Form.Item name="description" label="Template description">
          <Input placeholder="Enter template description" />
        </Form.Item>

        <Form.Item name="body" label="SMS body" required rules={[{ required: true }]}>
          <Input.TextArea rows={5} placeholder="Enter SMS body as plain text" />
        </Form.Item>

        <Form.Item
          name="parameters"
          label="Default values (JSON)"
          rules={[
            {
              validator: (_rule, value) => {
                if (value === '') {
                  return Promise.resolve();
                }
                try {
                  JSON.parse(value);
                  return Promise.resolve();
                } catch (e) {
                  return Promise.reject('Invalid JSON object');
                }
              },
            },
          ]}
        >
          <Input.TextArea rows={5} placeholder="Enter default values" />
        </Form.Item>

        <Form.Item name="customerId" label="Customer">
          <Select placeholder="Select customer">
            <Select.Option value="" key="none">
              {' '}
            </Select.Option>
            {customers.map((customer) => (
              <Select.Option value={customer.id} key={customer.id}>
                {customer.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default SmsTemplateModal;
