/* eslint-disable jsx-a11y/anchor-is-valid,jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';

import { Button, Form, Input, Modal, Select } from 'antd';

import { OrderTemplateFulfillmentType, OtiShippingLabel } from '@tassoinc/core-api-client';
import {
  PACKAGE1_TYPE_LABELS,
  Package1Type,
  DISTRIBUTION_MODEL_SOURCES,
  BIOTOUCH_UNSUPPORTED_PACKAGE1_KEYS,
} from 'constants/index';
import LaboratorySelectField from 'components/CreateProjectModal/LaboratorySelectField';
import { Laboratory } from 'context/laboratories';
import { IProject } from 'context/projects';

interface Props {
  value: OtiShippingLabel | null;
  onCancel: () => void;
  onSave: (result: OtiShippingLabel) => void;
  visible: boolean;
  project?: IProject;
  fulfillmentType: OrderTemplateFulfillmentType;
}

const EditShippingModal: React.FC<Props> = ({ value, onCancel, onSave, visible, project, fulfillmentType }) => {
  const [form] = Form.useForm();
  const [canSave, setCanSave] = useState(true);
  const [destination, setDestination] = useState<Partial<Laboratory> | undefined>(
    value?.destination ? ({ id: value?.destination?.foreignId } as Partial<Laboratory>) : undefined
  );
  const [destinationEnabled, setDestinationEnabled] = useState(false);

  const handleCancel = (): void => {
    form.resetFields();
    setDestination(undefined);
    onCancel();
  };

  const handleSave = async (): Promise<void> => {
    if (!canSave) {
      return;
    }
    if (value !== null) {
      form.validateFields().then((values) => {
        const updated = value;
        updated.accountNumber = values.accountNumber;
        updated.service = values.service;
        updated.referenceNumber = values.referenceNumber;
        updated.destinationType = values.destinationType;
        if (value.destinationType === 'patient') {
          updated.destination = undefined;
        } else {
          updated.destination = { foreignId: destination?.id, foreignType: 'laboratory' };
        }
        onSave(updated);
        form.resetFields();
      });
    }
  };
  const handleFormChange = () => {
    const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);
    if (hasErrors) {
      setCanSave(false);
      return;
    }
    if (form.getFieldValue('destinationType') === 'laboratory' && !destination) {
      setCanSave(false);
      return;
    }
    setCanSave(true);
  };
  useEffect(() => {
    if (value !== null) {
      form.setFieldsValue({ service: value.service });
      form.setFieldsValue({ accountNumber: value.accountNumber });
      form.setFieldsValue({ referenceNumber: value.referenceNumber });
      form.setFieldsValue({ destinationType: value.destinationType });
      form.setFieldsValue({ destination: value.destination?.foreignId });
      if (value.destination) {
        setDestination({ id: value.destination?.foreignId } as Partial<Laboratory>);
      } else {
        setDestination(undefined);
      }
      setDestinationEnabled(value.destinationType === 'laboratory');
    }
  }, [visible]);

  // TODO new up a non packageX label set!
  const options = PACKAGE1_TYPE_LABELS;
  let selectableShippingLabels = Object.keys(options);
  if (fulfillmentType === OrderTemplateFulfillmentType.BioTouch) {
    // Don't allow selecting unsupported shipping speeds in biotouch templates
    selectableShippingLabels = selectableShippingLabels.filter(
      (entry) => !BIOTOUCH_UNSUPPORTED_PACKAGE1_KEYS.includes(entry)
    );
  }

  return (
    <Modal
      wrapClassName="Modal"
      title="Shipping Configuration"
      className="EditShippingModal"
      visible={visible}
      onCancel={handleCancel}
      onOk={handleSave}
      footer={[
        <Button onClick={handleCancel}>Cancel</Button>,
        <Button type="primary" onClick={handleSave} disabled={!canSave}>
          Update
        </Button>,
      ]}
    >
      <Form form={form} className="ShippingLabelForm" onFieldsChange={handleFormChange} layout="vertical" requiredMark>
        <Form.Item
          label="Service"
          name="service"
          rules={[
            {
              required: true,
              message: 'Please select service',
            },
          ]}
        >
          <Select placeholder="Select shipping speed" showArrow value={value?.service}>
            {selectableShippingLabels.map((k) => (
              <Select.Option value={k} key={k}>
                {options[k as Package1Type]}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Destination Type"
          name="destinationType"
          rules={[
            {
              required: true,
              message: 'Please select destination type',
            },
          ]}
        >
          <Select
            placeholder="Select destination type"
            showArrow
            value={value?.destinationType}
            onChange={(item) => {
              setDestinationEnabled(item === 'laboratory');
            }}
          >
            <Select.Option value="laboratory">Laboratory</Select.Option>
            {project?.distributionModel !== DISTRIBUTION_MODEL_SOURCES.atCustomerSite && (
              <Select.Option value="patient">Patient</Select.Option>
            )}
            {project?.distributionModel === DISTRIBUTION_MODEL_SOURCES.atCustomerSite && (
              <Select.Option value="site">Site</Select.Option>
            )}
          </Select>
        </Form.Item>
        <Form.Item
          rules={[
            {
              required: destinationEnabled,
              message: 'Please select a lab',
            },
          ]}
          label="Select Destination Lab"
          name="destination"
        >
          <LaboratorySelectField
            onChange={(lab: Laboratory) => {
              setDestination(lab);
              handleFormChange();
            }}
            isDisabled={!destinationEnabled}
            selectionId={destination?.id as string}
            selectOnly
          />
        </Form.Item>

        <Form.Item name="referenceNumber" label="Shipping Reference Number">
          <Input value={value?.referenceNumber} placeholder="Enter Reference Number if necessary" />
        </Form.Item>
        <Form.Item name="accountNumber" label="Shipping Account Number">
          <Input value={value?.accountNumber} placeholder="Enter Account Number if necessary" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditShippingModal;
