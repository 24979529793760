import React from 'react';
import { InputNumber } from 'antd';

interface Props {
  value?: number;
  orderTemplateId: string;
  readOnly?: boolean;
  onChange: (orderTemplateId: string, value: number) => void;
}

const Quantity: React.FC<Props> = ({ value, orderTemplateId, readOnly, onChange }) => {
  const handleChange = (v: number | null): void => {
    onChange(orderTemplateId, v ?? 1);
  };

  const parseValue = (displayValue: string | undefined): number => {
    const num = parseInt(displayValue ?? '1', 10);

    return Number.isNaN(num) ? 1 : num;
  };

  return (
    <InputNumber min={1} defaultValue={value ?? 1} onChange={handleChange} parser={parseValue} readOnly={readOnly} />
  );
};

export default Quantity;
