import React, { FC, useState, createContext } from 'react';

import { projectsApi } from 'api';
import { DISTRIBUTION_MODELS, Package1Type, Package2Type } from 'constants/index';
import { loadAllPages, normalizePagingResponse } from 'utils/functions';

import { getErrorMessage } from 'utils/issues';
import Notification from 'utils/notification';

import { LabelIdentifierSource, PagingInfo, PhysicianApprovalModes, ProjectPurposes } from 'utils/types';
import { getIdPools } from 'api/idPools';
import { Customer } from './customers';
import { IKitItem } from './kitItems';
import { EmailTemplate, SmsTemplate } from './notificationTemplates/types';
import { CustomerSupportValue } from './customerSupportValue';

export type OrderTemplatesViewOrder = { templateId: string; default?: boolean }[];

export interface IProjectKitItem extends IKitItem {
  kitItemId: string;
  viewOrder: number;
  addedToProjectAt: string;
  projectId: number;
}

export type PatientReminderThresholds = Record<
  string,
  { smsTemplateId: string | null; emailTemplateId: string | null }
>;

export type PatientPropertyState = 'required' | 'optional' | 'hidden';
export interface PatientPropertyConfig {
  firstName?: PatientPropertyState;

  dob?: PatientPropertyState;

  assignedSex?: PatientPropertyState;

  gender?: PatientPropertyState;

  address2?: PatientPropertyState;

  phoneNumber?: PatientPropertyState;

  email?: PatientPropertyState;

  subjectId?: PatientPropertyState;

  race?: PatientPropertyState;

  physicianDetails?: PatientPropertyState;
}

export interface IProject {
  id: string;
  name: string;
  code: string;
  projectType: string;
  createdAt: string;
  updatedAt: string;
  customerId: string;
  customer?: Customer;
  deliveryFax: string;
  deviceType: string;
  labelingStrategy: string;
  deliveryType: string;
  deliveryEmail: string;
  package1Type: Package1Type;
  package2Type: Package2Type;
  status: string;
  isPatientReminderSmsEnabled: boolean;
  isPatientReminderEmailEnabled: boolean;
  patientReminderAtPatientThresholds: PatientReminderThresholds | null;
  patientReminderSmsTemplateName: string;
  patientReminderEmailTemplateName: string;
  packingInstructions: string;
  initialSalesOrder: string;
  replacementSalesOrder: string;
  deviceShippedEmailTemplateId: string | null;
  deviceShippedSmsTemplateId: string | null;
  deviceShippedEmailTemplate?: EmailTemplate;
  deviceShippedSmsTemplate?: SmsTemplate;
  customerLetterKey: string | null;
  customerLetterUrl: string | null;
  customerLetterUploadInfo?: string;
  labelTextConfig: any[] | null;
  distributionModel: keyof typeof DISTRIBUTION_MODELS;
  customerLetterTextConfig: any[] | null;
  // 'none' is a deprecated labelIdentifierSource value that exists in old projects
  labelIdentifierSource: LabelIdentifierSource | 'none';
  purpose: ProjectPurposes;
  physicianApprovalMode: PhysicianApprovalModes;
  upsAccountIdToPatient: string | null;
  upsAccountIdToLab: string | null;
  customAttributeDefinitions: CustomAttributeDefinitionProject[] | null;
  customerReferenceNumber: string | null;
  patientExperienceEnabled: boolean;
  useOrderTemplates: boolean;
  orderTemplatesViewOrder: OrderTemplatesViewOrder;
  allowOrdering: boolean;
  allowFulfillment: boolean;
  customerSupportValueId: string | null;
  customerSupportValue?: CustomerSupportValue;
  resultsExpected: boolean;
  patientPropertyConfig: PatientPropertyConfig | null;
}

export interface CustomAttributeDefinitionProject {
  name: string;
  displayName?: string;
  required?: boolean;
  maxLength?: number;
  allowedValues?: string[];
}

export type Project = IProject;

export type LoadProjectsParams = {
  page: number;
  pageLength: number;
  includeTotalCount: boolean;
  sortBy: string;
  sortOrder: 'asc' | 'desc';
  nameLike?: string;
  skipStateUpdate?: boolean;
};

type LoadProjectsFunction = (
  params?: LoadProjectsParams,
  callback?: (customers: Project[] | null, paging?: PagingInfo) => Promise<void>
) => Promise<void>;

interface State {
  projects: IProject[];
  project: IProject | null;
  error: null | string;
  loading: boolean;
}

type SuccessCallback = (project: IProject) => Promise<void>;
type SuccessCallbackMany = (project: IProject[]) => Promise<void>;

export type UpdateProjectOptions = {
  manualLoading?: boolean;
  suppressNotification?: 'error' | 'success' | 'both';
  callback?: (project: IProject | null) => Promise<void>;
};
type UpdateProjectFunction = (
  id: string,
  data: projectsApi.UpdateProjectPayload,
  options?: UpdateProjectOptions
) => Promise<void>;

export type GetProjectOptions = {
  hydration?: string[];
  manualLoading?: boolean;
  callback?: GetProjectCallback;
};
type GetProjectCallback = (project: IProject | null) => Promise<void>;
type GetProjectFunction = (id: string, options?: GetProjectOptions) => Promise<void>;

type SetLoadingFunction = (isLoading: boolean) => void;

type Context = State & {
  loadProjects: LoadProjectsFunction;
  getProjects: (includeCustomer: boolean, callback?: SuccessCallbackMany) => Promise<void>;
  getProject: GetProjectFunction;
  createProject: (data: projectsApi.CreateProjectPayload | FormData, callback?: SuccessCallback) => Promise<void>;
  updateProject: UpdateProjectFunction;
  resetProject: () => void;
  resetProjects: () => void;
  setLoading: SetLoadingFunction;
};

const sortByProjectName = (a: IProject, b: IProject): number => a.name.localeCompare(b.name, ['en-US']);

const getInitialValues = (): State => ({
  projects: [],
  project: null,
  error: null,
  loading: false,
});

const ProjectsContext = createContext<Context>({
  ...getInitialValues(),
  loadProjects: async () => {},
  getProjects: async () => {},
  getProject: async () => {},
  createProject: async () => {},
  updateProject: async () => {},
  resetProject: () => {},
  resetProjects: () => {},
  setLoading: () => {},
});

const ProjectsProvider: FC<any> = (props) => {
  const [state, setState] = useState<State>(getInitialValues());

  const loadProjects: LoadProjectsFunction = async (params, callback) => {
    const skipStateUpdate = params?.skipStateUpdate === true;

    if (!skipStateUpdate) {
      setState((s) => ({
        ...s,
        loading: true,
        error: null,
      }));
    }

    const sortBy = params?.sortBy || 'createdAt';
    const isDescending = params && params.sortOrder ? params.sortOrder === 'desc' : false;

    const query: Record<string, string> = {};

    if (params) {
      if (typeof params.nameLike === 'string') {
        query.nameLike = params.nameLike;
      }
    }

    let paging: PagingInfo;

    try {
      const data = await projectsApi.loadProjects(query, {
        page: params?.page || 1,
        pageLength: params?.pageLength || 20,
        sortBy,
        isDescending,
        includeTotalCount: params?.includeTotalCount || false,
      });

      const { results } = data;

      paging = normalizePagingResponse(data.paging);

      if (!skipStateUpdate) {
        setState((s) => ({
          ...s,
          projects: results,
        }));
      }

      if (callback) {
        await callback(results, paging);
      }
    } catch (e) {
      const errorMessage = getErrorMessage(e);

      if (!skipStateUpdate) {
        setState((s) => ({
          ...s,
          error: errorMessage,
        }));
      }

      Notification({
        type: 'error',
        message: 'Failed to load projects',
        description: errorMessage,
      });

      if (callback) {
        await callback(null);
      }
    } finally {
      if (!skipStateUpdate) {
        setState((s) => ({
          ...s,
          loading: false,
        }));
      }
    }
  };

  const getProjects = async (includeCustomer: boolean, callback?: SuccessCallbackMany): Promise<void> => {
    try {
      setState((prevState) => ({
        ...prevState,
        loading: true,
        error: null,
      }));

      type PayloadType = Parameters<typeof projectsApi.getProjects>[0];
      const projects = await loadAllPages<IProject, PayloadType>(projectsApi.getProjects, includeCustomer, {
        pageLength: 50,
        sortBy: 'createdAt',
        isDescending: false,
      });

      setState((s) => ({
        ...s,
        projects: projects.sort(sortByProjectName),
        loading: false,
      }));

      if (callback) {
        await callback(projects);
      }
    } catch (e) {
      const errorMessage = getErrorMessage(e);

      setState((s) => ({
        ...s,
        loading: false,
        error: errorMessage,
      }));

      Notification({
        type: 'error',
        message: 'Cannot get projects',
        description: errorMessage,
      });
    }
  };

  const getProject: GetProjectFunction = async (id, options = {}): Promise<void> => {
    try {
      setState((s) => ({
        ...s,
        loading: options.manualLoading ? s.loading : true,
        error: null,
      }));

      const project = await projectsApi.getProject(id, options.hydration ?? []);

      setState((s) => ({
        ...s,
        project,
      }));

      if (options.callback) {
        await options.callback(project);
      }
    } catch (e) {
      const errorMessage = getErrorMessage(e);

      setState((s) => ({
        ...s,
        error: errorMessage,
      }));

      Notification({
        type: 'error',
        message: 'Cannot get project details',
        description: errorMessage,
      });

      if (options.callback) {
        await options.callback(null);
      }
    } finally {
      if (!options.manualLoading) {
        setState((s) => ({
          ...s,
          loading: false,
        }));
      }
    }
  };

  const resetProject = (): void => {
    setState((s) => ({
      ...s,
      project: null,
    }));
  };

  const createProject = async (
    data: projectsApi.CreateProjectPayload | FormData,
    callback?: SuccessCallback
  ): Promise<void> => {
    try {
      setState((s) => ({
        ...s,
        loading: true,
        error: null,
      }));

      const project = await projectsApi.createProject(data);

      setState((s) => ({
        ...s,
        projects: [...s.projects, project].sort(sortByProjectName),
        loading: false,
      }));

      if (callback) {
        await callback(project);
      }

      Notification({
        type: 'success',
        message: 'Project created',
      });
    } catch (e) {
      const errorMessage = getErrorMessage(e);

      setState((s) => ({
        ...s,
        loading: false,
        error: errorMessage,
      }));

      Notification({
        type: 'error',
        message: 'Cannot get project details',
        description: errorMessage,
      });
    }
  };

  const updateProject: UpdateProjectFunction = async (id, data, options = {}): Promise<void> => {
    setState((s) => ({
      ...s,
      loading: options.manualLoading ? s.loading : true,
      error: null,
    }));

    try {
      const updatedProject: IProject = await projectsApi.updateProject(id, data);

      setState((s) => ({
        ...s,
        projects: s.projects.map((project) => (project.id === id ? updatedProject : project)).sort(sortByProjectName),
        project: s.project && s.project.id === id ? updatedProject : s.project,
      }));

      const suppressNotification =
        options.suppressNotification === 'success' || options.suppressNotification === 'both';
      if (suppressNotification) {
        Notification({
          type: 'success',
          message: 'Project successfully updated',
        });
      }

      if (options.callback) {
        await options.callback(updatedProject);
      }
    } catch (e) {
      const errorMessage = getErrorMessage(e);

      setState((s) => ({
        ...s,
        error: errorMessage,
      }));

      const suppressNotification = options.suppressNotification === 'error' || options.suppressNotification === 'both';

      if (!suppressNotification) {
        Notification({
          type: 'error',
          message: 'Cannot update project',
          description: errorMessage,
        });
      }

      if (options.callback) {
        await options.callback(null);
      }
    } finally {
      if (!options.manualLoading) {
        setState((s) => ({
          ...s,
          loading: false,
        }));
      }
    }
  };

  const resetProjects = (): void => {
    setState(getInitialValues());
  };

  const setLoading: SetLoadingFunction = (isLoading) => {
    setState((s) => ({ ...s, loading: isLoading }));
  };

  return (
    <ProjectsContext.Provider
      value={{
        projects: state.projects,
        project: state.project,
        loading: state.loading,
        error: state.error,
        loadProjects,
        getProjects,
        getProject,
        createProject,
        updateProject,
        resetProject,
        resetProjects,
        setLoading,
        getIdPools,
      }}
      {...props}
    />
  );
};

const useProjects = (): Context => React.useContext(ProjectsContext);

export { ProjectsProvider, useProjects };
