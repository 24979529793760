import { Button, Select, Space } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { EditOutlined, SaveOutlined, CloseOutlined } from 'components/icons';

interface Props {
  title: string;
  currentValue: string;
  options: { value: string; label: string }[];
  onSave: (value: string) => Promise<boolean>;
}

/**
 * This component is used to edit a value that's supposed to come from
 * a predefined list of values. It generates a dropdown with these values.
 * In a nutshell, it's a glorified select box with some custom styling and
 * common functionality (common to the project settings page).
 */
export const SelectableValue: FC<Props> = (props) => {
  const [value, setValue] = useState(props.currentValue);
  const [isEditing, setIsEditing] = useState(false);

  const handleSave = async (): Promise<void> => {
    const successfullyUpdated = await props.onSave(value);
    if (successfullyUpdated) {
      setIsEditing(false);
    }
  };

  useEffect(() => {
    setValue(props.currentValue);
  }, [props.currentValue, isEditing]);

  return (
    <div className="ProjectInformation__InfoSection">
      <h3>{props.title}</h3>
      {isEditing ? (
        <>
          <div className="SelectableValue">
            <Space direction="vertical">
              <Select
                data-testid={`select-${props.title.replaceAll(' ', '-')}`}
                onSelect={(v: string) => {
                  setValue(v);
                }}
                showArrow
                value={value}
                className="SelectableValueDropdown"
                options={props.options}
              />
            </Space>
          </div>
          <Button
            data-testid={`button-${props.title.replaceAll(' ', '-')}`}
            size="small"
            icon={<SaveOutlined />}
            type="link"
            className="SelectableValueSave"
            onClick={() => handleSave()}
          />

          <Button
            size="small"
            icon={<CloseOutlined />}
            type="link"
            data-testid={`cancel-button-${props.title.replaceAll(' ', '-')}`}
            onClick={() => {
              setIsEditing(false);
            }}
            className="SelectableValueClose"
          />
        </>
      ) : (
        <p data-testid={`edit-${props.title.replaceAll(' ', '-')}`}>
          {props.options.find((option) => option.value === props.currentValue)?.label ?? props.currentValue}{' '}
          <EditOutlined
            data-testid={`edit-button-${props.title.replaceAll(' ', '-')}`}
            className="EditAction"
            onClick={() => {
              setIsEditing(true);
            }}
          />
        </p>
      )}
    </div>
  );
};

export default SelectableValue;
