import { CancelToken, AxiosRequestConfig } from 'axios';
import { handleResponseError, httpClient } from 'services/index';
import { PagingRequest } from 'utils/types';
import { filterCancelled } from '../utils/filterCancelled';

export type OrderRequestOptions = {
  hydrate?: string[];
  cancelToken?: CancelToken;
};

const prepareRequest = (existingConfig: AxiosRequestConfig, options?: OrderRequestOptions): AxiosRequestConfig => {
  const req: AxiosRequestConfig = { ...existingConfig };

  if (options) {
    if (options.hydrate && options.hydrate.length > 0) {
      req.headers = { ...(req.headers || {}), 'tasso-hydration': options.hydrate.join(',') };
    }
    if (options.cancelToken) {
      req.cancelToken = options.cancelToken;
    }
  }

  return req;
};

export type GetOrdersPayload = {
  query: Record<string, any>;
  options?: OrderRequestOptions;
};
export const getOrders = (payload: GetOrdersPayload, paging: PagingRequest): Promise<any> => {
  const requestOptions = prepareRequest({ params: { ...filterCancelled(payload.query), ...paging } }, payload.options);

  return httpClient
    .get('/orders', requestOptions)
    .then((_) => _.data)
    .catch(handleResponseError);
};

export const getOrder = (id: string, options?: OrderRequestOptions): Promise<any> => {
  const requestOptions = prepareRequest({}, options);

  return httpClient
    .get(`/orders/${id}`, requestOptions)
    .then((_) => _.data.results)
    .catch(handleResponseError);
};

export type CreateOrderPayload = {
  patientId?: string;
  deviceBarcode?: string;
  orderTemplateId: string | undefined;
  customAttributeValues?: Record<string, string>;
  siteId?: string;
  quantity?: number;
};

export const createOrder = (data: CreateOrderPayload, options?: OrderRequestOptions): Promise<any> => {
  const requestOptions = prepareRequest({}, options);

  return httpClient
    .post('/orders', data, requestOptions)
    .then((_) => _.data.results)
    .catch(handleResponseError);
};

export type UpdateOrderPayload = {
  name?: string;
  customerId?: string;
};

export const updateOrder = (id: string, data: UpdateOrderPayload, options?: OrderRequestOptions): Promise<any> => {
  const requestOptions = prepareRequest({}, options);

  return httpClient
    .patch(`/orders/${id}`, data, requestOptions)
    .then((_) => _.data.results)
    .catch(handleResponseError);
};

export const cancelOrder = (id: string, options?: OrderRequestOptions): Promise<any> => {
  const requestOptions = prepareRequest({}, options);
  const cancelOrderBody = { status: 'cancelled' };

  return httpClient
    .patch(`/orders/${id}`, cancelOrderBody, requestOptions)
    .then((_) => _.data.results)
    .catch(handleResponseError);
};

export const deleteOrder = (id: string, options?: OrderRequestOptions): Promise<any> => {
  const requestOptions = prepareRequest({}, options);

  return httpClient
    .delete(`/orders/${id}`, requestOptions)
    .then((_) => _.data.results)
    .catch(handleResponseError);
};
