import React, { FC, useEffect, useState } from 'react';

import { Modal, Select } from 'antd';

import { ICustomAttributeDefinition } from 'context/customAttributeDefinitions';

interface Props {
  customAttributeDefinitions: ICustomAttributeDefinition[];
  visible: boolean;
  working: boolean;
  onOk: (attributeIds: string[]) => Promise<void>;
  onCancel: () => void;
}

const OrderTemplateCustomAttributeSelectModal: FC<Props> = ({
  customAttributeDefinitions,
  visible,
  working,
  onOk,
  onCancel,
}) => {
  const [selectedValues, setSelectedValues] = useState<string[]>([]);

  useEffect(() => {
    setSelectedValues([]);
  }, [visible]);

  const handleSubmit = () => {
    if (selectedValues.length > 0) {
      onOk(selectedValues);
    }
  };

  const handleChange = (values: string[]): void => {
    setSelectedValues(values);
  };

  return (
    <Modal
      wrapClassName="Modal CustomAttributeSelectModal"
      visible={visible}
      title="Select existing custom attributes"
      okText="Select attributes"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={handleSubmit}
      confirmLoading={working}
      centered
      width="400px"
      forceRender
      okButtonProps={{ disabled: selectedValues.length === 0 }}
    >
      <Select
        data-testid="select-custom-attr"
        className="multiSelect"
        mode="multiple"
        showSearch
        showArrow
        value={selectedValues}
        onChange={handleChange}
        placeholder="Select attributes"
        style={{ width: '100%' }}
        loading={working}
      >
        {customAttributeDefinitions.map((item) => (
          <Select.Option key={item.id} value={item.id} data-testid={item.name}>
            {item.name}
          </Select.Option>
        ))}
      </Select>
    </Modal>
  );
};

export default OrderTemplateCustomAttributeSelectModal;
