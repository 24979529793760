import React, { useEffect } from 'react';

import { ShippingLabelsModal } from 'components/ShippingLabelsModal';
import { useDownloads } from 'context/downloads';
import { LABEL_STATUS, useShippingLabels } from 'context/shippingLabels';

const useShippingLabelsModal: any = () => {
  const { shippingLabels, resetLabels, createMode, disposition, showModal } = useShippingLabels();
  const { downloadShippingLabels, loading: downloadInProgress } = useDownloads();

  const itemsReady = shippingLabels.every((label) => label.status !== LABEL_STATUS.inProgress);
  const itemsSuccess = shippingLabels.every((label) => label.status === LABEL_STATUS.success);
  const deviceIds = shippingLabels.map((label) => label.deviceId);

  useEffect(() => {
    if (createMode && itemsReady && itemsSuccess && deviceIds.length > 0) {
      // auto-request pdf file generation
      downloadShippingLabels(deviceIds, disposition);
    }
  }, [itemsReady]);

  const Modal = (
    <ShippingLabelsModal
      labels={shippingLabels}
      onCancel={resetLabels}
      createMode={createMode}
      itemsReady={itemsReady}
      itemsSuccess={itemsSuccess}
      downloadInProgress={downloadInProgress}
      showModal={showModal}
      onGeneratePdf={() => downloadShippingLabels(deviceIds, disposition)}
    />
  );

  return { Modal };
};

export default useShippingLabelsModal;
