import React, { FC } from 'react';

import { Form, Input, Button, Typography } from 'antd';
import { useHistory } from 'react-router-dom';

import { MailOutlined } from 'components/icons';
import { ROUTES } from 'constants/routes';

interface IForgotPasswordForm {
  loading: boolean;
  onSubmit: (email: string) => void;
}

const { Title } = Typography;

const ForgotPasswordForm: FC<IForgotPasswordForm> = ({ loading, onSubmit }) => {
  const history = useHistory();
  const [form] = Form.useForm();

  const navigateToLogin = () => {
    history.push(ROUTES.login);
  };

  const handleFormSubmit = () => {
    form
      .validateFields()
      .then((values: any) => {
        onSubmit(values.email);
      })
      .catch((info: any) => {
        console.log('Validate Failed:', info);
      });
  };

  return (
    <Form
      className="ForgotPassword__Form"
      form={form}
      layout="vertical"
      name="ForgotPasswordForm"
      hideRequiredMark
      initialValues={{ email: '' }}
      onFinish={handleFormSubmit}
    >
      <Title level={4} type="secondary">
        Forgot password
      </Title>

      <Form.Item
        name="email"
        rules={[
          {
            required: true,
            message: 'Please input your email!',
          },
          {
            type: 'email',
            message: 'Invalid Email format!',
          },
        ]}
      >
        <Input prefix={<MailOutlined />} placeholder="Enter your Email" />
      </Form.Item>

      <Form.Item>
        <Button block type="primary" htmlType="submit" loading={loading}>
          Submit
        </Button>
      </Form.Item>

      <Form.Item>
        <Button block type="link" onClick={navigateToLogin}>
          Back to Login
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ForgotPasswordForm;
