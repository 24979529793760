/* eslint-disable jsx-a11y/anchor-is-valid,jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';

import { FileTextOutlined } from '@ant-design/icons';
import Link from 'antd/lib/typography/Link';
import clsx from 'clsx';
import TextConfigModal from './TextConfigModal';

interface Props {
  value: Record<string, any>[] | null;
  disabled: boolean;
  onChange: (textConfig: Record<string, any>[] | null) => void;
}

const TextConfig: React.FC<Props> = ({ value, disabled, onChange }) => {
  const [editMode, setEditMode] = useState(false);

  const cancelEditMode = (): void => {
    if (disabled) {
      return;
    }

    setEditMode(false);
  };

  const enableEditMode = (): void => {
    if (disabled) {
      return;
    }

    setEditMode(true);
  };

  return (
    <>
      <span className={clsx({ TextConfigLink: true, Selected: !!value })} onClick={enableEditMode}>
        <Link>Text overlay</Link> <FileTextOutlined />
      </span>

      <TextConfigModal
        open={editMode}
        value={value}
        onCancel={() => setEditMode(false)}
        onSave={(v) => {
          onChange(v);
          cancelEditMode();
        }}
      />
    </>
  );
};

export default TextConfig;
