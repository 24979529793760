import React, { useReducer, useCallback } from 'react';

import { CreateUserPayload } from 'api/users';
import UserModal from 'components/UserModal';
import { Customer } from 'context/customers';
import { useUsers } from 'context/users';
import { User } from 'context/users/types';
import { makeReducer } from 'utils/functions';

type UserCallback = (user: User) => Promise<void>;

type State = {
  user: User | null;
  visible: boolean;
  lockedCustomerId: string | null;
  callback?: UserCallback;
};

const useUserModal = ({ customers }: { customers: Customer[] }) => {
  const { createUser, updateUser, loading: usersLoading } = useUsers();
  const [state, setState] = useReducer(makeReducer<State>(), {
    user: null,
    visible: false,
    lockedCustomerId: null,
    callback: undefined,
  });

  const onCreateUser = (callback?: UserCallback): void => {
    setState({ user: null, visible: true, lockedCustomerId: null, callback });
  };

  const onEditUser = (user: User, callback?: UserCallback): void => {
    setState({ user, visible: true, lockedCustomerId: null, callback });
  };

  const onCreateContactUser = (customerId: string, callback?: UserCallback): void => {
    setState({ user: null, visible: true, lockedCustomerId: customerId, callback });
  };

  const onEditContactUser = (user: User, callback?: UserCallback): void => {
    setState({ user, visible: true, lockedCustomerId: user.customerId, callback });
  };

  const resetModal = (): void => {
    setState({ user: null, visible: false, lockedCustomerId: null, callback: undefined });
  };

  const createUserFn = useCallback(
    (data: CreateUserPayload, modalCallback: UserCallback): void => {
      createUser(data, async (user: User) => {
        await modalCallback(user);
      });
    },
    [state]
  );

  const Modal = (
    <UserModal
      visible={state.visible}
      user={state.user}
      customers={customers}
      onClose={resetModal}
      loading={usersLoading}
      createUser={createUserFn}
      updateUser={updateUser}
      createUpdateCallback={state.callback}
      lockedCustomerId={state.lockedCustomerId}
    />
  );

  return { Modal, onCreateUser, onEditUser, onCreateContactUser, onEditContactUser, resetModal };
};

export default useUserModal;
