import React, { useState } from 'react';

import { Form } from 'antd';

import CustomerModal from 'components/CustomerModal';
import { Customer, useCustomers } from 'context/customers';

const useCustomerModal: any = () => {
  const { createCustomer, updateCustomer, deleteCustomer } = useCustomers();
  const [form] = Form.useForm();
  const [customerModalVisible, setCustomerModalVisible] = useState(false);
  const [customerModalPopulated, setCustomerModalPopulated] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState<Customer | null>(null);

  const openCustomerModal = () => {
    setCustomerModalVisible(true);
  };

  const closeCustomerModal = () => {
    setCustomerModalVisible(false);
    form.resetFields();
    setSelectedCustomer(null);
  };

  const callback = async () => {
    setCustomerModalPopulated(true);
    closeCustomerModal();
  };

  const handleCreateCustomer = (customer: Customer) => {
    createCustomer(customer, callback);
  };

  const handleEditCustomer = (customer: Customer) => {
    updateCustomer(selectedCustomer?.id || '', customer, callback);
  };

  const handleDeleteCustomer = (id: string) => {
    deleteCustomer(id, callback);
  };

  /* ----------------------- FOR EXPORT ------------------------- */

  const onCreateCustomer = () => {
    setCustomerModalPopulated(false);
    setSelectedCustomer(null);
    form.setFieldsValue({});
    openCustomerModal();
  };

  const onEditCustomer = async (customer: Customer) => {
    setCustomerModalPopulated(false);
    setSelectedCustomer(customer);
    form.setFieldsValue(customer);
    openCustomerModal();
  };

  const onDeleteCustomer = (id: string) => {
    setCustomerModalPopulated(false);
    handleDeleteCustomer(id);
  };

  const Modal = (
    <CustomerModal
      form={form}
      customer={selectedCustomer}
      visible={customerModalVisible}
      onOk={selectedCustomer ? handleEditCustomer : handleCreateCustomer}
      onCancel={closeCustomerModal}
    />
  );

  return { Modal, onCreateCustomer, onEditCustomer, onDeleteCustomer, customerModalPopulated };
};

export default useCustomerModal;
