/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable security/detect-non-literal-fs-filename */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { FC, useState } from 'react';

import { Button, Popconfirm, Input, Select, Table, Upload, InputNumber, Divider, Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table';

import { uploadFileToS3, waitForUploadedFile } from 'utils/fileUploading';

import {
  EditOutlined,
  PlusCircleOutlined,
  DeleteOutlined,
  SaveOutlined,
  CloseOutlined,
  UploadOutlined,
  CloudDownloadOutlined,
} from 'components/icons';
import SortableTable from 'components/SortableTable';
import TextConfigHelpModal from 'components/TextConfigHelpModal';
import {
  DISTRIBUTION_MODELS,
  LABELING_STRATEGIES_LABELS,
  LABEL_IDENTIFIER_SOURCE_LABELS,
  PACKAGE1_TYPE_LABELS,
  PACKAGE2_TYPE_LABELS,
  PROJECT_PHYSICIAN_APPROVAL_MODES,
  PROJECT_PURPOSES,
  PROJECT_TYPE_LABELS,
  PROJECT_STATUSES,
  KIT_ITEM_TYPES_LABELS,
  USER_ROLES,
  FeatureFlags,
} from 'constants/index';
import { Laboratory } from 'context/laboratories';
import { EmailTemplate, SmsTemplate } from 'context/notificationTemplates/types';
import { ProjectAnalyteLink } from 'context/projectAnalyteLinks';
import { ProjectContact } from 'context/projectContacts/types';
import { IProjectKitItem } from 'context/projectKitItems';
import { IProjectLaboratory } from 'context/projectLaboratories';
import {
  CustomAttributeDefinitionProject,
  GetProjectOptions,
  IProject,
  PatientReminderThresholds,
  UpdateProjectOptions,
} from 'context/projects';
import { User } from 'context/users/types';
import Notification from 'utils/notification';

import { IdPool } from 'context/idPools';
import { ProjectIdPoolLink } from 'context/projectIdPoolLinks';
import { LabelIdentifierSource, ProjectType } from 'utils/types';
import { OrderTemplate, OrderTemplateFulfillmentType } from '@tassoinc/core-api-client';
import { useAuth } from 'context/auth';
import { useFlag } from '@unleash/proxy-client-react';
import ProjectInformationItem from '../ProjectInformationItem';
import ContactList from './ContactList';
import PatientReminderThresholdsComponent, { DataRow } from './PatientReminderThresholds';
import SelectableValue from './SelectableValue';
import OrderTemplateList from './OrderTemplateList';
import { UnselectableValue } from './UnselectableValue';
import PatientProperty from './PatientProperty';

type Actions = {
  contact: {
    createUser: () => void;
    createContactLink: (userId: string) => void;
    deleteContactLink: (linkId: string) => void;
    editUser: (user: User) => void;
  };
  project: {
    setLoading: (isLoading: boolean) => void;
    get: (options: GetProjectOptions) => Promise<void>;
    update: (data: Record<string, any>, options?: UpdateProjectOptions) => Promise<boolean>;
  };
  laboratory: {
    update: (lab: Laboratory) => void;
    delete: (labId: string) => void;
  };
  kitItem: {
    create: () => void;
    update: (linkId: string, quantity: number) => void;
    updateOrder: (projectKitItemIds: string[]) => void;
    delete: (projectKitItemId: string) => void;
  };
  projectAnalyteLinks: {
    create: () => void;
    updateOrder: (linkIds: string[]) => void;
    delete: (linkId: string) => void;
  };
  projectIdPoolLinks: {
    update: (idPoolId: string) => Promise<void>;
  };
  customAttributes: {
    create: () => void;
    edit: (customAttributeDefinition: CustomAttributeDefinitionProject) => void;
    delete: (name: string) => void;
  };
  orderTemplates: {
    create: (projectId: string, fulfillmentType: OrderTemplateFulfillmentType) => void;
    edit: (orderTemplate: OrderTemplate) => void;
    delete: (orderTemplate: OrderTemplate) => void;
  };
};

interface IProjectInformation {
  project: IProject;
  contacts: User[];
  projectContacts: ProjectContact[];
  kitItems: IProjectKitItem[];
  projectAnalyteLinks: ProjectAnalyteLink[];
  laboratories: IProjectLaboratory[];
  actions: Actions;
  emailTemplates: EmailTemplate[];
  smsTemplates: SmsTemplate[];
  idPools: IdPool[];
  projectIdPoolLinks: ProjectIdPoolLink[];
  orderTemplates: OrderTemplate[];
}

const DEVICE_TYPE = {
  M20: 'M20',
  SST: 'SST',
  tassoPlus: 'Tasso+',
};

const ProjectInformation: FC<IProjectInformation> = ({
  project,
  contacts,
  projectContacts,
  kitItems,
  laboratories,
  actions,
  emailTemplates,
  smsTemplates,
  projectAnalyteLinks,
  idPools,
  projectIdPoolLinks,
  orderTemplates,
}) => {
  const [editField, setEditField] = useState<{ name: string; value: any }>({
    value: '',
    name: '',
  });
  const { profile } = useAuth();
  const isAdmin = (profile?.role || '') === USER_ROLES.internalAdmin;
  const [showTextConfigHelp, setShowTextConfigHelp] = useState(false);
  const { useOrderTemplates, allowOrdering, allowFulfillment } = project;
  // Patient Config Feature flag.
  const isPatientPropertyConfigEnabled = useFlag(FeatureFlags.PatientPropertyConfig);

  return (
    <div className="ProjectInformation">
      <h2 className="ProjectInformation__Heading">Project Information</h2>
      <div style={{ display: 'flex' }}>
        <td style={{ flex: 1 }}>
          <h3>Project Name</h3>
          {editField.name === 'name' ? (
            <div>
              <Input.TextArea
                data-testid="edit-textarea-Project-name"
                rows={10}
                placeholder={project.name}
                size="small"
                value={editField.value}
                style={{
                  fontSize: '0.8em',
                  height: '80px',
                  overflowY: 'auto',
                  padding: '4px',
                }}
                onChange={(e) =>
                  setEditField({
                    name: 'name',
                    value: e.currentTarget.value === '' ? '' : e.currentTarget.value,
                  })
                }
              />
              {isAdmin && (
                <Button
                  data-testid="save-button-Project-name"
                  type="link"
                  onClick={() => {
                    const v = editField.value.trim();
                    actions.project.update(
                      { name: v === '' ? null : v },
                      { callback: async () => setEditField({ name: '', value: '' }) }
                    );
                  }}
                >
                  <SaveOutlined />
                </Button>
              )}
              {isAdmin && (
                <Button
                  data-testid="cancel-button-Project-name"
                  type="link"
                  onClick={() => setEditField({ value: '', name: '' })}
                >
                  <CloseOutlined />
                </Button>
              )}
            </div>
          ) : (
            <p>
              <p>{project.name}</p>
              {isAdmin && (
                <EditOutlined
                  data-testid="edit-action-Project-name"
                  className="EditAction"
                  onClick={() =>
                    setEditField({
                      name: 'name',
                      value: project.name ? project.name : '',
                    })
                  }
                />
              )}
            </p>
          )}
        </td>
        <td style={{ flex: 1 }}>
          {isAdmin ? (
            <SelectableValue
              title="Status"
              currentValue={project.status}
              options={[
                { label: 'Open', value: PROJECT_STATUSES.open },
                { label: 'Archived', value: PROJECT_STATUSES.archived },
              ]}
              onSave={(newValue: string) => actions.project.update({ status: newValue })}
            />
          ) : (
            <UnselectableValue
              title="Status"
              currentValue={project.status}
              options={[
                { label: 'Open', value: PROJECT_STATUSES.open },
                { label: 'Archived', value: PROJECT_STATUSES.archived },
              ]}
            />
          )}
        </td>
        <td style={{ flex: 1 }}>
          {isAdmin ? (
            <SelectableValue
              title="Allow Ordering"
              currentValue={`${allowOrdering}`}
              options={[
                { label: 'Yes', value: 'true' },
                { label: 'No', value: 'false' },
              ]}
              onSave={(newValue: string) => actions.project.update({ allowOrdering: newValue === 'true' })}
            />
          ) : (
            <UnselectableValue
              title="Allow Ordering"
              currentValue={`${allowOrdering}`}
              options={[
                { label: 'Yes', value: 'true' },
                { label: 'No', value: 'false' },
              ]}
            />
          )}
        </td>
        <td style={{ flex: 1 }}>
          {isAdmin ? (
            <SelectableValue
              title="Allow Fulfillment"
              currentValue={`${allowFulfillment}`}
              options={[
                { label: 'Yes', value: 'true' },
                { label: 'No', value: 'false' },
              ]}
              onSave={(newValue: string) => actions.project.update({ allowFulfillment: newValue === 'true' })}
            />
          ) : (
            <UnselectableValue
              title="Allow Fulfillment"
              currentValue={`${allowFulfillment}`}
              options={[
                { label: 'Yes', value: 'true' },
                { label: 'No', value: 'false' },
              ]}
            />
          )}
        </td>
        <td style={{ flex: 1 }}>
          {isAdmin ? (
            <SelectableValue
              title="Results Expected"
              currentValue={`${project.resultsExpected}`}
              options={[
                { label: 'Yes', value: 'true' },
                { label: 'No', value: 'false' },
              ]}
              onSave={(newValue: string) => actions.project.update({ resultsExpected: newValue === 'true' })}
            />
          ) : (
            <UnselectableValue
              title="Results Expected"
              currentValue={`${project.resultsExpected}`}
              options={[
                { label: 'Yes', value: 'true' },
                { label: 'No', value: 'false' },
              ]}
            />
          )}
        </td>
      </div>

      <hr />

      {isPatientPropertyConfigEnabled && (
        <>
          <PatientProperty project={project} isAdmin={isAdmin} updateProject={actions.project.update} />
          <hr />
        </>
      )}

      <div className="ProjectInformation__InfoSection">
        <h3>
          Project Contacts{' '}
          {isAdmin && (
            <Button type="link" onClick={actions.contact.createUser}>
              <PlusCircleOutlined />
            </Button>
          )}
        </h3>

        <ContactList
          contacts={contacts}
          projectContacts={projectContacts}
          createContactLink={actions.contact.createContactLink}
          deleteContactLink={actions.contact.deleteContactLink}
          editUser={actions.contact.editUser}
        />
      </div>

      <hr />

      <div className="ProjectInformation__InfoSection">
        <h3>Project Code</h3>
        <p>{project.code}</p>
      </div>

      <hr />

      {isAdmin ? (
        <SelectableValue
          title="Project Type"
          currentValue={project.projectType}
          options={Object.keys(PROJECT_TYPE_LABELS).map((t) => ({
            label: PROJECT_TYPE_LABELS[t as ProjectType],
            value: t,
          }))}
          onSave={(newValue: string) =>
            actions.project.update({
              projectType: newValue,
            })
          }
        />
      ) : (
        <UnselectableValue
          title="Project Type"
          currentValue={project.projectType}
          options={Object.keys(PROJECT_TYPE_LABELS).map((t) => ({
            label: PROJECT_TYPE_LABELS[t as ProjectType],
            value: t,
          }))}
        />
      )}

      <hr />

      {isAdmin ? (
        <SelectableValue
          title="Project Purpose"
          currentValue={project.purpose}
          options={Object.keys(PROJECT_PURPOSES).map((t) => ({
            label: PROJECT_PURPOSES[t as keyof typeof PROJECT_PURPOSES],
            value: t,
          }))}
          onSave={(newValue: string) =>
            actions.project.update({
              purpose: newValue,
              // If the purpose changes to 'research', set 'physicianApprovalMode' to 'none'
              physicianApprovalMode: newValue === 'research' ? 'none' : project.physicianApprovalMode,
            })
          }
        />
      ) : (
        <UnselectableValue
          title="Project Purpose"
          currentValue={project.purpose}
          options={Object.keys(PROJECT_PURPOSES).map((t) => ({
            label: PROJECT_PURPOSES[t as keyof typeof PROJECT_PURPOSES],
            value: t,
          }))}
        />
      )}

      <hr />

      {isAdmin ? (
        <SelectableValue
          title="Patient Experience"
          currentValue={`${project.patientExperienceEnabled}`}
          options={[
            { label: 'Enabled', value: 'true' },
            { label: 'Disabled', value: 'false' },
          ]}
          onSave={(newValue: string) => actions.project.update({ patientExperienceEnabled: newValue === 'true' })}
        />
      ) : (
        <UnselectableValue
          title="Patient Experience"
          currentValue={`${project.patientExperienceEnabled}`}
          options={[
            { label: 'Enabled', value: 'true' },
            { label: 'Disabled', value: 'false' },
          ]}
        />
      )}

      <hr />

      {project.purpose === 'diagnosticTesting' && (
        <>
          <hr />

          {isAdmin ? (
            <SelectableValue
              title="Physician Approval Mode"
              currentValue={project.physicianApprovalMode}
              options={Object.keys(PROJECT_PHYSICIAN_APPROVAL_MODES).map((t) => ({
                label: PROJECT_PHYSICIAN_APPROVAL_MODES[t as keyof typeof PROJECT_PHYSICIAN_APPROVAL_MODES],
                value: t,
              }))}
              onSave={(newValue: string) =>
                actions.project.update({
                  physicianApprovalMode: newValue,
                })
              }
            />
          ) : (
            <UnselectableValue
              title="Physician Approval Mode"
              currentValue={project.physicianApprovalMode}
              options={Object.keys(PROJECT_PHYSICIAN_APPROVAL_MODES).map((t) => ({
                label: PROJECT_PHYSICIAN_APPROVAL_MODES[t as keyof typeof PROJECT_PHYSICIAN_APPROVAL_MODES],
                value: t,
              }))}
            />
          )}
        </>
      )}

      <hr />

      {isAdmin ? (
        <SelectableValue
          title="Device Type"
          currentValue={project.deviceType}
          options={Object.keys(DEVICE_TYPE).map((t) => ({
            label: DEVICE_TYPE[t as keyof typeof DEVICE_TYPE],
            value: t,
          }))}
          onSave={(newValue: string) =>
            actions.project.update({
              deviceType: newValue,
            })
          }
        />
      ) : (
        <UnselectableValue
          title="Device Type"
          currentValue={project.deviceType}
          options={Object.keys(DEVICE_TYPE).map((t) => ({
            label: DEVICE_TYPE[t as keyof typeof DEVICE_TYPE],
            value: t,
          }))}
        />
      )}

      <hr />

      <div className="ProjectInformation__InfoSection">
        <h3>Distribution Model</h3>
        <p>{DISTRIBUTION_MODELS[project.distributionModel as keyof typeof DISTRIBUTION_MODELS] || ''}</p>
      </div>

      <hr />

      {isAdmin ? (
        <SelectableValue
          title="Label Identifier Source"
          currentValue={project.labelIdentifierSource}
          options={(project.projectType === 'onDemandTesting'
            ? ['none', 'hrid', 'barcodePrelabeled']
            : Object.keys(LABEL_IDENTIFIER_SOURCE_LABELS)
          ).map((t) => ({
            label: LABEL_IDENTIFIER_SOURCE_LABELS[t as LabelIdentifierSource],
            value: t,
          }))}
          onSave={(newValue: string) =>
            actions.project.update({
              labelIdentifierSource: newValue,
            })
          }
        />
      ) : (
        <UnselectableValue
          title="Label Identifier Source"
          currentValue={project.labelIdentifierSource}
          options={(project.projectType === 'onDemandTesting'
            ? ['none', 'hrid', 'barcodePrelabeled']
            : Object.keys(LABEL_IDENTIFIER_SOURCE_LABELS)
          ).map((t) => ({
            label: LABEL_IDENTIFIER_SOURCE_LABELS[t as LabelIdentifierSource],
            value: t,
          }))}
        />
      )}

      <hr />

      {project.labelIdentifierSource === 'identifierPool' && (
        <>
          <div className="ProjectInformation__InfoSection">
            <h3>Id Pool</h3>
            {editField.name === 'idPoolId' ? (
              <>
                <Select
                  allowClear
                  showSearch
                  showArrow
                  style={{ width: 300 }}
                  defaultValue={editField.value}
                  options={[
                    { value: '', label: <em>No Id Pool</em> },
                    ...idPools.map((pool) => ({ value: pool.id, label: pool.name })),
                  ]}
                  onSelect={(_value: any, option: { value: any }) => {
                    setEditField({
                      name: 'idPoolId',
                      value: option.value,
                    });
                  }}
                  onClear={() => setEditField({ name: 'idPoolId', value: '' })}
                  filterOption={(input, option) =>
                    (option as any).label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                />
                {isAdmin && (
                  <Button
                    size="small"
                    icon={<SaveOutlined />}
                    type="link"
                    style={{ marginLeft: '10px' }}
                    onClick={() => {
                      actions.projectIdPoolLinks.update(editField.value.trim() || '');
                      setEditField({ name: '', value: '' });
                    }}
                  />
                )}

                {isAdmin && (
                  <Button
                    size="small"
                    icon={<CloseOutlined />}
                    type="link"
                    onClick={() => setEditField({ value: '', name: '' })}
                    style={{ marginLeft: '5px' }}
                  />
                )}
              </>
            ) : (
              <p>
                {projectIdPoolLinks[0]?.idPool?.name || <em>No Id Pool</em>}{' '}
                {isAdmin && (
                  <EditOutlined
                    className="EditAction"
                    onClick={() =>
                      setEditField({
                        name: 'idPoolId',
                        value: projectIdPoolLinks[0]?.idPoolId || '',
                      })
                    }
                  />
                )}
                {!isAdmin && <p>-</p>}
              </p>
            )}
          </div>

          <hr />
        </>
      )}

      {isAdmin ? (
        <SelectableValue
          title="Labeling Strategy"
          currentValue={project.labelingStrategy}
          options={Object.keys(LABELING_STRATEGIES_LABELS).map((t) => ({
            label: LABELING_STRATEGIES_LABELS[t as keyof typeof LABELING_STRATEGIES_LABELS],
            value: t,
          }))}
          onSave={(newValue: string) =>
            actions.project.update({
              labelingStrategy: newValue,
            })
          }
        />
      ) : (
        <UnselectableValue
          title="Labeling Strategy"
          currentValue={project.labelingStrategy}
          options={Object.keys(LABELING_STRATEGIES_LABELS).map((t) => ({
            label: LABELING_STRATEGIES_LABELS[t as keyof typeof LABELING_STRATEGIES_LABELS],
            value: t,
          }))}
        />
      )}

      <hr />

      <div className="ProjectInformation__InfoSection">
        <h3>Device barcode label overlay (textConfig)</h3>
        {editField.name === 'labelTextConfig' ? (
          <div>
            <Input.TextArea
              data-testid="edit-value-Device-barcode-label-overlay-(textConfig)"
              rows={10}
              placeholder="Enter textConfig"
              size="small"
              value={editField.value}
              onChange={(e) =>
                setEditField({
                  name: 'labelTextConfig',
                  value: e.currentTarget.value === '' ? '' : e.currentTarget.value,
                })
              }
              allowClear
            />
            {isAdmin && (
              <Button type="link" onClick={() => setShowTextConfigHelp(true)}>
                Help
              </Button>
            )}
            <br />
            <br />
            {isAdmin && (
              <Button
                data-testid="button-Device-barcode-label-overlay-(textConfig)"
                type="link"
                style={{ marginRight: '5px' }}
                onClick={() => {
                  const v = editField.value.trim();
                  actions.project.update(
                    { labelTextConfig: v === '' ? null : JSON.parse(v) },
                    { callback: async () => setEditField({ name: '', value: '' }) }
                  );
                }}
              >
                <SaveOutlined />
              </Button>
            )}
            {isAdmin && (
              <Button
                data-testid="cancel-button-Device-barcode-label-overlay-(textConfig)"
                type="link"
                onClick={() => setEditField({ value: '', name: '' })}
              >
                <CloseOutlined />
              </Button>
            )}
          </div>
        ) : (
          <p>
            {project.labelTextConfig ? (
              <pre
                data-testid="edit-Device-barcode-label-overlay-(textConfig)"
                style={{
                  fontSize: '0.8em',
                  maxHeight: '300px',
                  overflowY: 'auto',
                  padding: '5px',
                  background: '#efefef',
                }}
              >
                {JSON.stringify(project.labelTextConfig, null, 2)}
              </pre>
            ) : (
              <span data-testid="edit-Device-barcode-label-overlay-(textConfig)" />
            )}{' '}
            {isAdmin && (
              <EditOutlined
                data-testid="edit-button-Device-barcode-label-overlay-(textConfig)"
                className="EditAction"
                onClick={() =>
                  setEditField({
                    name: 'labelTextConfig',
                    value: project.labelTextConfig ? JSON.stringify(project.labelTextConfig, null, 4) : '',
                  })
                }
              />
            )}
            {!isAdmin && <p>-</p>}
          </p>
        )}
        <TextConfigHelpModal open={showTextConfigHelp} onClose={() => setShowTextConfigHelp(false)} />
      </div>

      <hr />

      <div className="ProjectInformation__InfoSection">
        <h3>Initial Sales Order</h3>
        {editField.name === 'initialSalesOrder' ? (
          <div>
            <Input
              data-testid="edit-value-Initial-Sales-Order"
              style={{ width: '200px', marginRight: '8px' }}
              placeholder="Enter initial sales order"
              size="small"
              value={editField.value}
              onChange={(e) =>
                setEditField({
                  name: 'initialSalesOrder',
                  value: e.currentTarget.value === '' ? '' : e.currentTarget.value,
                })
              }
              allowClear
            />
            {isAdmin && (
              <Button
                type="link"
                style={{ marginRight: '5px' }}
                onClick={() => {
                  actions.project.update({ initialSalesOrder: editField.value.trim() });
                  setEditField({ name: '', value: '' });
                }}
                data-testid="button-Initial-Sales-Order"
              >
                <SaveOutlined />
              </Button>
            )}
            {isAdmin && (
              <Button
                type="link"
                onClick={() => setEditField({ value: '', name: '' })}
                data-testid="cancel-button-Initial-Sales-Order"
              >
                <CloseOutlined />
              </Button>
            )}
          </div>
        ) : (
          <p>
            <span data-testid="edit-Initial-Sales-Order">{project.initialSalesOrder || ''} </span>
            {isAdmin && (
              <EditOutlined
                data-testid="edit-button-Initial-Sales-Order"
                className="EditAction"
                onClick={() => setEditField({ name: 'initialSalesOrder', value: project.initialSalesOrder || '' })}
              />
            )}
            {!isAdmin && <p>-</p>}
          </p>
        )}
      </div>

      <hr />

      <div className="ProjectInformation__InfoSection">
        <h3>Replacement Sales Order</h3>
        {editField.name === 'replacementSalesOrder' ? (
          <div>
            <Input
              data-testid="edit-value-Replacement-Sales-Order"
              style={{ width: '200px', marginRight: '8px' }}
              placeholder="Enter replacement sales order"
              size="small"
              value={editField.value}
              onChange={(e) =>
                setEditField({
                  name: 'replacementSalesOrder',
                  value: e.currentTarget.value === '' ? '' : e.currentTarget.value,
                })
              }
              allowClear
            />
            {isAdmin && (
              <Button
                data-testid="button-Replacement-Sales-Order"
                type="link"
                style={{ marginRight: '5px' }}
                onClick={() => {
                  actions.project.update({ replacementSalesOrder: editField.value.trim() });
                  setEditField({ name: '', value: '' });
                }}
              >
                <SaveOutlined />
              </Button>
            )}
            {isAdmin && (
              <Button
                type="link"
                onClick={() => setEditField({ value: '', name: '' })}
                data-testid="cancel-button-Replacement-Sales-Order"
              >
                <CloseOutlined />
              </Button>
            )}
          </div>
        ) : (
          <p>
            <span data-testid="edit-Replacement-Sales-Order">{project.replacementSalesOrder || ''} </span>
            {isAdmin && (
              <EditOutlined
                data-testid="edit-button-Replacement-Sales-Order"
                className="EditAction"
                onClick={() =>
                  setEditField({ name: 'replacementSalesOrder', value: project.replacementSalesOrder || '' })
                }
              />
            )}
            {!isAdmin && <p>-</p>}
          </p>
        )}
      </div>

      {!useOrderTemplates && (
        <>
          <hr />

          {isAdmin ? (
            <SelectableValue
              title="Inbound shipping speed (patient &rarr; lab)"
              currentValue={project.package2Type}
              options={Object.keys(PACKAGE2_TYPE_LABELS).map((t) => ({
                label: PACKAGE2_TYPE_LABELS[t as keyof typeof PACKAGE2_TYPE_LABELS],
                value: t,
              }))}
              onSave={(newValue: string) =>
                actions.project.update({
                  package2Type: newValue,
                })
              }
            />
          ) : (
            <UnselectableValue
              title="Inbound shipping speed (patient &rarr; lab)"
              currentValue={project.package2Type}
              options={Object.keys(PACKAGE2_TYPE_LABELS).map((t) => ({
                label: PACKAGE2_TYPE_LABELS[t as keyof typeof PACKAGE2_TYPE_LABELS],
                value: t,
              }))}
            />
          )}
        </>
      )}

      {!useOrderTemplates && (
        <>
          <hr />

          {(project.package2Type || '').startsWith('ups') && (
            <>
              <div className="ProjectInformation__InfoSection">
                <h3>
                  Separate UPS Account Id for inbound shipping (patient &rarr; lab){' '}
                  <Tooltip title="Overrides the default billing account for UPS for the patient to lab shipment.">
                    <QuestionCircleOutlined />
                  </Tooltip>
                </h3>

                {editField.name === 'upsAccountIdToLab' ? (
                  <div>
                    <Input
                      data-testid="edit-value-Separate-UPS-Account-Id-for-inbound-shipping-(patient-→-lab)"
                      style={{ width: '200px', marginRight: '8px' }}
                      placeholder="Account Id"
                      size="small"
                      value={editField.value}
                      onChange={(e) =>
                        setEditField({
                          name: 'upsAccountIdToLab',
                          value: e.currentTarget.value === '' ? '' : e.currentTarget.value,
                        })
                      }
                      allowClear
                    />
                    {isAdmin && (
                      <Button
                        data-testid="button-Separate-UPS-Account-Id-for-inbound-shipping-(patient-→-lab)"
                        type="link"
                        style={{ marginRight: '5px' }}
                        onClick={() => {
                          actions.project.update({ upsAccountIdToLab: editField.value.trim() || null });
                          setEditField({ name: '', value: '' });
                        }}
                      >
                        <SaveOutlined />
                      </Button>
                    )}
                    {isAdmin && (
                      <Button
                        data-testid="cancel-button-Separate-UPS-Account-Id-for-inbound-shipping-(patient-→-lab)"
                        type="link"
                        onClick={() => setEditField({ value: '', name: '' })}
                      >
                        <CloseOutlined />
                      </Button>
                    )}
                  </div>
                ) : (
                  <div>
                    <span data-testid="edit-Separate-UPS-Account-Id-for-inbound-shipping-(patient-→-lab)">
                      {project.upsAccountIdToLab || ''}{' '}
                    </span>
                    {isAdmin && (
                      <EditOutlined
                        data-testid="edit-button-Separate-UPS-Account-Id-for-inbound-shipping-(patient-→-lab)"
                        className="EditAction"
                        onClick={() =>
                          setEditField({ name: 'upsAccountIdToLab', value: project.upsAccountIdToLab || '' })
                        }
                      />
                    )}
                    {!isAdmin && <p>-</p>}
                  </div>
                )}
              </div>
              <hr />
            </>
          )}

          {isAdmin ? (
            <SelectableValue
              title="Outbound shipping speed (Tasso &rarr; patient)"
              currentValue={project.package1Type}
              options={Object.keys(PACKAGE1_TYPE_LABELS).map((t) => ({
                label: PACKAGE1_TYPE_LABELS[t as keyof typeof PACKAGE1_TYPE_LABELS],
                value: t,
              }))}
              onSave={(newValue: string) =>
                actions.project.update({
                  package1Type: newValue,
                })
              }
            />
          ) : (
            <UnselectableValue
              title="Outbound shipping speed (Tasso &rarr; patient)"
              currentValue={project.package1Type}
              options={Object.keys(PACKAGE1_TYPE_LABELS).map((t) => ({
                label: PACKAGE1_TYPE_LABELS[t as keyof typeof PACKAGE1_TYPE_LABELS],
                value: t,
              }))}
            />
          )}
        </>
      )}

      {!useOrderTemplates && (
        <>
          <hr />

          {(project.package1Type || '').startsWith('ups') && (
            <>
              <div className="ProjectInformation__InfoSection">
                <h3>
                  Separate UPS Account Id for outbound shipping (Tasso &rarr; patient){' '}
                  <Tooltip title="Overrides the default billing account for UPS for the Tasso to patient shipment.">
                    <QuestionCircleOutlined />
                  </Tooltip>
                </h3>

                {editField.name === 'upsAccountIdToPatient' ? (
                  <div>
                    <Input
                      data-testid="edit-value-Separate-UPS-Account-Id-for-outbound-shipping-(Tasso-→-patient)"
                      style={{ width: '200px', marginRight: '8px' }}
                      placeholder="Account Id"
                      size="small"
                      value={editField.value}
                      onChange={(e) =>
                        setEditField({
                          name: 'upsAccountIdToPatient',
                          value: e.currentTarget.value === '' ? '' : e.currentTarget.value,
                        })
                      }
                      allowClear
                    />
                    {isAdmin && (
                      <Button
                        data-testid="button-Separate-UPS-Account-Id-for-outbound-shipping-(Tasso-→-patient)"
                        type="link"
                        style={{ marginRight: '5px' }}
                        onClick={() => {
                          actions.project.update({ upsAccountIdToPatient: editField.value.trim() || null });
                          setEditField({ name: '', value: '' });
                        }}
                      >
                        <SaveOutlined />
                      </Button>
                    )}
                    {isAdmin && (
                      <Button
                        data-testid="cancel-button-Separate-UPS-Account-Id-for-outbound-shipping-(Tasso-→-patient)"
                        type="link"
                        onClick={() => setEditField({ value: '', name: '' })}
                      >
                        <CloseOutlined />
                      </Button>
                    )}
                  </div>
                ) : (
                  <div>
                    <span data-testid="edit-Separate-UPS-Account-Id-for-outbound-shipping-(Tasso-→-patient)">
                      {project.upsAccountIdToPatient || ''}{' '}
                    </span>
                    {isAdmin && (
                      <EditOutlined
                        data-testid="edit-button-Separate-UPS-Account-Id-for-outbound-shipping-(Tasso-→-patient)"
                        className="EditAction"
                        onClick={() =>
                          setEditField({ name: 'upsAccountIdToPatient', value: project.upsAccountIdToPatient || '' })
                        }
                      />
                    )}{' '}
                    {!isAdmin && <p>-</p>}
                  </div>
                )}
              </div>
              <hr />
            </>
          )}
        </>
      )}

      {!useOrderTemplates && (
        <div className="ProjectInformation__InfoSection">
          <h3>Shipping Reference Number</h3>
          {editField.name === 'customerReferenceNumber' ? (
            <div>
              <Input
                data-testid="edit-value-Shipping-Reference-Number"
                style={{ width: '200px', marginRight: '8px' }}
                placeholder="Enter reference number"
                size="small"
                value={editField.value}
                onChange={(e) =>
                  setEditField({
                    name: 'customerReferenceNumber',
                    value: e.currentTarget.value === '' ? '' : e.currentTarget.value,
                  })
                }
                allowClear
              />
              {isAdmin && (
                <Button
                  data-testid="button-Shipping-Reference-Number"
                  type="link"
                  style={{ marginRight: '5px' }}
                  onClick={() => {
                    actions.project.update({ customerReferenceNumber: editField.value.trim() || null });
                    setEditField({ name: '', value: '' });
                  }}
                >
                  <SaveOutlined />
                </Button>
              )}
              {isAdmin && (
                <Button
                  data-testid="cancel-button-Shipping-Reference-Number"
                  type="link"
                  onClick={() => setEditField({ value: '', name: '' })}
                >
                  <CloseOutlined />
                </Button>
              )}
            </div>
          ) : (
            <p>
              <span data-testid="edit-Shipping-Reference-Number">{project.customerReferenceNumber || ''} </span>
              {isAdmin && (
                <EditOutlined
                  data-testid="edit-button-Shipping-Reference-Number"
                  className="EditAction"
                  onClick={() =>
                    setEditField({ name: 'customerReferenceNumber', value: project.customerReferenceNumber || '' })
                  }
                />
              )}{' '}
              {!isAdmin && <p>-</p>}
            </p>
          )}
        </div>
      )}

      <hr />

      <div className="ProjectInformation__InfoSection">
        <h3>Testing Laboratories ({laboratories.length})</h3>

        {laboratories.length > 0 &&
          laboratories.map((lab) =>
            isAdmin ? (
              <ProjectInformationItem
                key={lab.id}
                itemName={lab.laboratory!.name || ''}
                onEdit={() => actions.laboratory.update(lab.laboratory!)}
              />
            ) : (
              <div className="ProjectInformationItem">
                <p>{lab.laboratory!.name || ''}</p>
              </div>
            )
          )}
      </div>

      {!useOrderTemplates && (
        <>
          <hr />
          <div className="ProjectInformation__InfoSection">
            <h3>
              Analytes{' '}
              {isAdmin && (
                <Button type="link" onClick={actions.projectAnalyteLinks.create}>
                  <PlusCircleOutlined />
                </Button>
              )}
            </h3>

            {projectAnalyteLinks.length > 0 ? (
              <SortableTable
                size="small"
                onSort={(items: ProjectAnalyteLink[]) =>
                  actions.projectAnalyteLinks.updateOrder(items.map((el) => el.id))
                }
                data={projectAnalyteLinks.sort((a, b) => (a.viewOrder > b.viewOrder ? 1 : -1))}
                columns={
                  [
                    {
                      title: 'Analyte id',
                      dataIndex: ['analyte', 'id'],
                      className: 'drag-visible',
                      key: 'analyteId',
                    },
                    {
                      title: 'Analyte name',
                      dataIndex: ['analyte', 'friendlyname'],
                      key: 'analyteName',
                    },
                    {
                      title: 'LOINC name',
                      dataIndex: ['analyte', 'LOINC_longname'],
                      key: 'analyteLoincName',
                    },
                    {
                      render: (item) => (
                        <div>
                          <Popconfirm
                            title="Unlink analyte from project?"
                            okText="Yes"
                            cancelText="No"
                            onConfirm={() => actions.projectAnalyteLinks.delete(item.id)}
                          >
                            {isAdmin && <DeleteOutlined className="DeleteAction" style={{ marginLeft: '20px' }} />}
                          </Popconfirm>
                        </div>
                      ),
                    },
                  ] as ColumnsType<ProjectAnalyteLink>
                }
              />
            ) : null}
          </div>
        </>
      )}

      <hr />

      <div className="ProjectInformation__InfoSection">
        <h3>Result Delivery</h3>

        <p>{project.deliveryFax || '-'}</p>
      </div>

      <hr />

      <div className="ProjectInformation__InfoSection">
        <h3>Packing Instructions</h3>
        {editField.name === 'packingInstructions' ? (
          <div>
            <Input.TextArea
              data-testid="edit-value-Packing-Instructions"
              rows={4}
              style={{ width: '200px', marginRight: '8px' }}
              placeholder="Enter packing instructions"
              size="small"
              value={editField.value}
              onChange={(e) =>
                setEditField({
                  name: 'packingInstructions',
                  value: e.currentTarget.value === '' ? '' : e.currentTarget.value,
                })
              }
              allowClear
            />
            {isAdmin && (
              <Button
                data-testid="button-Packing-Instructions"
                type="link"
                style={{ marginRight: '5px' }}
                onClick={() => {
                  actions.project.update({ packingInstructions: editField.value.trim() });
                  setEditField({ name: '', value: '' });
                }}
              >
                <SaveOutlined />
              </Button>
            )}
            {isAdmin && (
              <Button
                data-testid="cancel-button-Packing-Instructions"
                type="link"
                onClick={() => setEditField({ value: '', name: '' })}
              >
                <CloseOutlined />
              </Button>
            )}
          </div>
        ) : (
          <p>
            <span data-testid="edit-Packing-Instructions">{project.packingInstructions || ''} </span>
            {isAdmin && (
              <EditOutlined
                data-testid="edit-button-Packing-Instructions"
                className="EditAction"
                onClick={() => setEditField({ name: 'packingInstructions', value: project.packingInstructions || '' })}
              />
            )}{' '}
            {!isAdmin && <p>-</p>}
          </p>
        )}
      </div>

      {!useOrderTemplates && (
        <>
          <hr />

          <div className="ProjectInformation__InfoSection">
            <h3>
              Kit Items{' '}
              {isAdmin && (
                <Button type="link" onClick={actions.kitItem.create}>
                  <PlusCircleOutlined />
                </Button>
              )}
            </h3>

            {kitItems.length > 0 ? (
              <SortableTable
                size="small"
                onSort={(items: IProjectKitItem[]) => actions.kitItem.updateOrder(items.map((el) => el.id))}
                data={kitItems.sort((a, b) => (a.viewOrder > b.viewOrder ? 1 : -1)) as IProjectKitItem[]}
                columns={
                  [
                    {
                      title: 'Key',
                      dataIndex: ['kitItem', 'key'],
                      className: 'drag-visible',
                      key: 'key',
                    },
                    {
                      title: 'Part number',
                      dataIndex: ['kitItem', 'partNumber'],
                      key: 'partNumber',
                    },
                    {
                      title: 'Lot number',
                      dataIndex: ['kitItem', 'lotNumber'],
                      key: 'lotNumber',
                    },
                    {
                      title: 'Kit Item Type',
                      dataIndex: ['kitItem', 'kitItemType'],
                      key: 'kitItemType',
                      render: (value) =>
                        value ? KIT_ITEM_TYPES_LABELS[value as keyof typeof KIT_ITEM_TYPES_LABELS] : 'None',
                    },
                    {
                      title: 'Description',
                      dataIndex: ['kitItem', 'description'],
                      key: 'description',
                    },
                    {
                      title: 'Quantity',
                      dataIndex: 'quantity',
                      key: 'quantity',
                      width: 150,
                      render: (_, record) => {
                        const fieldId = `kitItemQuantity${record.id}`;

                        if (editField.name === fieldId) {
                          return (
                            <>
                              <InputNumber
                                style={{ width: '65px', marginRight: '8px' }}
                                min={1}
                                max={32767}
                                size="small"
                                defaultValue={editField.value}
                                autoFocus
                                onChange={(value) => {
                                  setEditField({
                                    name: fieldId,
                                    value,
                                  });
                                }}
                              />
                              {isAdmin && (
                                <Button
                                  type="link"
                                  style={{ marginRight: '5px' }}
                                  onClick={() => {
                                    const v = parseInt(editField.value, 10);
                                    if (Number.isNaN(v) || v < 1) {
                                      Notification({
                                        type: 'error',
                                        message: 'Quantity must be a valid integer',
                                        description: '',
                                      });
                                      return;
                                    }
                                    actions.kitItem.update(record.id, v);
                                    setEditField({ name: '', value: '' });
                                  }}
                                >
                                  <SaveOutlined />
                                </Button>
                              )}
                              {isAdmin && (
                                <Button type="link" onClick={() => setEditField({ value: '', name: '' })}>
                                  <CloseOutlined />
                                </Button>
                              )}
                            </>
                          );
                        }

                        return (
                          <span>
                            {record.quantity}{' '}
                            {isAdmin && (
                              <EditOutlined
                                className="EditAction"
                                onClick={() => setEditField({ name: fieldId, value: record.quantity })}
                              />
                            )}{' '}
                            {!isAdmin && <p>-</p>}
                          </span>
                        );
                      },
                    },
                    {
                      render: (item: any) => (
                        <div>
                          <Popconfirm
                            title="Unlink kit item from project?"
                            okText="Yes"
                            cancelText="No"
                            onConfirm={() => actions.kitItem.delete(item.id)}
                          >
                            {isAdmin && <DeleteOutlined className="DeleteAction" style={{ marginLeft: '20px' }} />}
                          </Popconfirm>
                        </div>
                      ),
                    },
                  ] as ColumnsType<IProjectKitItem>
                }
              />
            ) : null}
          </div>
        </>
      )}

      <hr />

      <div className="ProjectInformation__InfoSection">
        <h3>Device Shipped Email Template</h3>
        {editField.name === 'deviceShippedEmailTemplateId' ? (
          <>
            <Select
              allowClear
              showSearch
              showArrow
              style={{ width: 300 }}
              defaultValue={editField.value}
              options={[
                { value: '', label: <em>No template</em> },
                ...emailTemplates.map((tpl) => ({ value: tpl.id, label: tpl.templateName })),
              ]}
              onSelect={(_value: any, option: { value: any }) => {
                setEditField({
                  name: 'deviceShippedEmailTemplateId',
                  value: option.value,
                });
              }}
              onClear={() => setEditField({ name: 'deviceShippedEmailTemplateId', value: '' })}
              filterOption={(input, option) => (option as any).label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            />
            {isAdmin && (
              <Button
                size="small"
                icon={<SaveOutlined />}
                type="link"
                style={{ marginLeft: '10px' }}
                onClick={() => {
                  actions.project.update({ deviceShippedEmailTemplateId: editField.value.trim() || null });
                  setEditField({ name: '', value: '' });
                }}
              />
            )}

            {isAdmin && (
              <Button
                size="small"
                icon={<CloseOutlined />}
                type="link"
                onClick={() => setEditField({ value: '', name: '' })}
                style={{ marginLeft: '5px' }}
              />
            )}
          </>
        ) : (
          <p>
            {project.deviceShippedEmailTemplate ? project.deviceShippedEmailTemplate.templateName : ''}{' '}
            {isAdmin && (
              <EditOutlined
                className="EditAction"
                onClick={() =>
                  setEditField({
                    name: 'deviceShippedEmailTemplateId',
                    value: project.deviceShippedEmailTemplateId || '',
                  })
                }
              />
            )}
            {!isAdmin && <p>-</p>}
          </p>
        )}
      </div>

      <hr />

      <div className="ProjectInformation__InfoSection">
        <h3>Device Shipped SMS Template</h3>
        {editField.name === 'deviceShippedSmsTemplateId' ? (
          <>
            <Select
              allowClear
              showSearch
              showArrow
              style={{ width: 300 }}
              defaultValue={editField.value}
              options={[
                { value: '', label: <em>No template</em> },
                ...smsTemplates.map((tpl) => ({ value: tpl.id, label: tpl.templateName })),
              ]}
              onSelect={(_value: string, option: { value: string }) => {
                setEditField({
                  name: 'deviceShippedSmsTemplateId',
                  value: option.value,
                });
              }}
              onClear={() => setEditField({ name: 'deviceShippedSmsTemplateId', value: '' })}
              filterOption={(input, option) => (option as any).label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            />
            {isAdmin && (
              <Button
                size="small"
                icon={<SaveOutlined />}
                type="link"
                style={{ marginLeft: '10px' }}
                onClick={() => {
                  actions.project.update({ deviceShippedSmsTemplateId: editField.value.trim() || null });
                  setEditField({ name: '', value: '' });
                }}
              />
            )}

            <Button
              size="small"
              icon={<CloseOutlined />}
              type="link"
              onClick={() => setEditField({ value: '', name: '' })}
              style={{ marginLeft: '5px' }}
            />
          </>
        ) : (
          <p>
            {project.deviceShippedSmsTemplate ? project.deviceShippedSmsTemplate.templateName : ''}{' '}
            {isAdmin && (
              <EditOutlined
                className="EditAction"
                onClick={() =>
                  setEditField({
                    name: 'deviceShippedSmsTemplateId',
                    value: project.deviceShippedSmsTemplateId || '',
                  })
                }
              />
            )}
            {!isAdmin && <p>-</p>}
          </p>
        )}
      </div>

      <hr />

      <div className="ProjectInformation__InfoSection">
        <h3>Patient Reminder &#34;At Patient&#34; Thresholds</h3>
        {editField.name === 'patientReminderThresholds' ? (
          <div>
            <PatientReminderThresholdsComponent
              editMode
              thresholds={project.patientReminderAtPatientThresholds}
              emailTemplates={emailTemplates}
              smsTemplates={smsTemplates}
              onChange={(data) => {
                setEditField({ name: 'patientReminderThresholds', value: data });
              }}
            />
            <br />
            {isAdmin && (
              <Button
                type="primary"
                icon={<SaveOutlined />}
                style={{ padding: '4px 15px' }}
                onClick={() => {
                  const data: DataRow[] = editField.value;
                  const hasData = data.length > 0;

                  let payload: PatientReminderThresholds | null;

                  if (hasData) {
                    payload = {};
                    for (const row of data) {
                      if (!/^\d+$/.test(row.hours)) {
                        Notification({
                          type: 'error',
                          message: 'All hour values must be integers',
                          description: '',
                        });
                        return;
                      }

                      payload[row.hours] = {
                        emailTemplateId: row.emailTemplateId || null,
                        smsTemplateId: row.smsTemplateId || null,
                      };
                    }
                  } else {
                    payload = null;
                  }

                  actions.project.update({ patientReminderAtPatientThresholds: payload });
                  setEditField({ name: '', value: '' });
                }}
              >
                Save
              </Button>
            )}
            {isAdmin && (
              <Button
                type="default"
                icon={<CloseOutlined />}
                onClick={() => setEditField({ value: '', name: '' })}
                style={{ marginLeft: '15px', padding: '4px 15px' }}
              >
                Cancel
              </Button>
            )}
          </div>
        ) : (
          <div>
            <PatientReminderThresholdsComponent
              editMode={false}
              thresholds={project.patientReminderAtPatientThresholds}
              emailTemplates={emailTemplates}
              smsTemplates={smsTemplates}
              onChange={() => {}}
            />
            <br />
            {isAdmin && (
              <Button
                icon={<EditOutlined />}
                style={{ padding: '4px 15px' }}
                onClick={() => setEditField({ name: 'patientReminderThresholds', value: '' })}
              >
                Edit thresholds
              </Button>
            )}
          </div>
        )}
      </div>

      {!useOrderTemplates && (
        <>
          <hr />

          <div className="ProjectInformation__InfoSection">
            <h3>Customer letter</h3>
            {editField.name === 'customerLetterTextConfig' ? (
              <div>
                <Input.TextArea
                  rows={10}
                  placeholder="Enter customer letter textConfig"
                  size="small"
                  value={editField.value}
                  onChange={(e) =>
                    setEditField({
                      name: 'customerLetterTextConfig',
                      value: e.currentTarget.value === '' ? '' : e.currentTarget.value,
                    })
                  }
                  allowClear
                />
                {isAdmin && (
                  <Button type="link" onClick={() => setShowTextConfigHelp(true)}>
                    Help
                  </Button>
                )}
                <br />
                <br />
                {isAdmin && (
                  <Button
                    type="link"
                    style={{ marginRight: '5px' }}
                    onClick={() => {
                      const v = editField.value.trim();
                      actions.project.update(
                        { customerLetterTextConfig: v === '' ? null : JSON.parse(v) },
                        { callback: async () => setEditField({ name: '', value: '' }) }
                      );
                    }}
                  >
                    <SaveOutlined />
                  </Button>
                )}
                {isAdmin && (
                  <Button type="link" onClick={() => setEditField({ value: '', name: '' })}>
                    <CloseOutlined />
                  </Button>
                )}
              </div>
            ) : (
              <p>
                {project.customerLetterTextConfig ? (
                  <pre
                    style={{
                      fontSize: '0.8em',
                      maxHeight: '300px',
                      overflowY: 'auto',
                      padding: '5px',
                      background: '#efefef',
                    }}
                  >
                    {JSON.stringify(project.customerLetterTextConfig, null, 2)}
                  </pre>
                ) : (
                  ''
                )}{' '}
                {isAdmin && (
                  <EditOutlined
                    className="EditAction"
                    onClick={() =>
                      setEditField({
                        name: 'customerLetterTextConfig',
                        value: project.customerLetterTextConfig
                          ? JSON.stringify(project.customerLetterTextConfig, null, 4)
                          : '',
                      })
                    }
                  />
                )}{' '}
                {!isAdmin && <p>-</p>}
              </p>
            )}

            <Divider />

            {project.customerLetterKey !== null && (
              <div>
                <CloudDownloadOutlined />{' '}
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    actions.project.get({
                      hydration: ['customerLetterUrl'],
                      callback: async (p) => {
                        if (p!.customerLetterUrl) {
                          window.open(p!.customerLetterUrl, '_blank');
                        }
                      },
                    });
                  }}
                >
                  Download letter
                </a>
              </div>
            )}

            {project.customerLetterKey === null && (
              <div>
                <em>Customer letter not found</em>
              </div>
            )}

            <br />

            <Upload
              accept=".pdf,application/pdf"
              maxCount={1}
              showUploadList={false}
              beforeUpload={async (file) => {
                actions.project.setLoading(true);

                // This request won't actually update anything on the project
                // (other than the expiresAt field) but the response will contain
                // S3 information required for the actual file upload.
                const updatedProject = await new Promise<IProject | null>((resolve) => {
                  actions.project.update(
                    { customerLetterFilename: file.name },
                    {
                      manualLoading: true,
                      callback: async (responseData) => {
                        resolve(responseData);
                      },
                    }
                  );
                });

                // Using uploading information (url and post fields) obtained
                // in the previous request, send the file to S3 now.
                await uploadFileToS3(updatedProject!.customerLetterUploadInfo!, file);

                // Now that the file is in S3, wait for the S3 trigger (lambda)
                // to process the upload and update the project record with the
                // file key.
                const loadCustomerLetterKey = () =>
                  new Promise<boolean>((resolve) => {
                    actions.project.get({
                      manualLoading: true,
                      callback: async (responseData) => {
                        resolve(!!responseData?.customerLetterKey);
                      },
                    });
                  });

                await waitForUploadedFile(loadCustomerLetterKey);

                actions.project.setLoading(false);

                return false;
              }}
            >
              {isAdmin && (
                <Button icon={<UploadOutlined />} style={{ padding: '4px 15px' }}>
                  Upload
                </Button>
              )}
            </Upload>
            {project.customerLetterKey !== null && (
              <Popconfirm
                title="Are you sure you want to delete the existing customer letter?"
                onConfirm={() => {
                  actions.project.update({ customerLetterFilename: null });
                }}
              >
                {isAdmin && (
                  <Button type="link" danger style={{ marginLeft: '10px' }}>
                    Delete
                  </Button>
                )}
              </Popconfirm>
            )}
          </div>
        </>
      )}

      {!useOrderTemplates && (
        <>
          <hr />

          <div className="ProjectInformation__InfoSection__CustomAttributes">
            <h3>
              Custom Order Attributes{' '}
              {isAdmin && (
                <Button
                  type="link"
                  onClick={() => {
                    actions.customAttributes.create();
                  }}
                >
                  <PlusCircleOutlined />
                </Button>
              )}
              {(project.customAttributeDefinitions?.length ?? 0) > 0 ? (
                <Table
                  dataSource={project?.customAttributeDefinitions?.sort()}
                  columns={
                    [
                      { title: 'Attribute name', dataIndex: ['name'] },
                      { title: 'Display name', dataIndex: ['displayName'] },
                      {
                        title: 'Required?',
                        dataIndex: ['required'],
                        render: (_, item: any) => <div>{item.required ? 'Yes' : 'No'}</div>,
                      },
                      { title: 'Maximum Length', dataIndex: ['maxLength'] },
                      {
                        title: 'Drop Down List Values',
                        dataIndex: ['allowedValues'],
                        render: (_, item) => <div>{item.allowedValues ? item.allowedValues.join(',') : ''}</div>,
                      },
                      {
                        title: 'Actions',
                        render: (item) => (
                          <div>
                            {isAdmin && (
                              <Button
                                type="link"
                                onClick={() => {
                                  actions.customAttributes.edit(item);
                                }}
                              >
                                Edit
                              </Button>
                            )}
                            <Popconfirm
                              title="Delete attribute from project?"
                              okText="Yes"
                              cancelText="No"
                              onConfirm={() => actions.customAttributes.delete(item.name)}
                            >
                              {isAdmin && <Button type="link">Delete</Button>}
                            </Popconfirm>
                          </div>
                        ),
                      },
                    ] as ColumnsType<CustomAttributeDefinitionProject>
                  }
                />
              ) : null}
            </h3>
          </div>
        </>
      )}

      <hr />

      {isAdmin ? (
        <SelectableValue
          title="Use Order Templates"
          currentValue={`${useOrderTemplates}`}
          options={[
            { label: 'Yes', value: 'true' },
            { label: 'No', value: 'false' },
          ]}
          onSave={(newValue: string) => actions.project.update({ useOrderTemplates: newValue === 'true' })}
        />
      ) : (
        <UnselectableValue
          title="Use Order Templates"
          currentValue={`${useOrderTemplates}`}
          options={[
            { label: 'Yes', value: 'true' },
            { label: 'No', value: 'false' },
          ]}
        />
      )}

      {useOrderTemplates && (
        <>
          <hr />

          <div className="ProjectInformation__InfoSection">
            <h3>
              Order Templates{' '}
              {isAdmin && (
                <Button
                  data-testid="button-add-order-template"
                  type="link"
                  onClick={() => {
                    actions.orderTemplates.create(project.id, OrderTemplateFulfillmentType.Tasso);
                  }}
                >
                  <PlusCircleOutlined />
                </Button>
              )}
            </h3>
          </div>

          {(orderTemplates?.length ?? 0) > 0 ? (
            <OrderTemplateList
              orderTemplates={orderTemplates}
              orderTemplatesViewOrder={project.orderTemplatesViewOrder}
              onEditClick={actions.orderTemplates.edit}
              onOrderChange={(ids) => actions.project.update({ orderTemplatesViewOrder: ids })}
            />
          ) : null}
        </>
      )}
    </div>
  );
};

export default ProjectInformation;
