/* eslint-disable no-await-in-loop */
import React, { FC, useState, createContext, useRef } from 'react';

import { shipmentApi } from 'api';
import { makeCancelTokenSource, CancelTokenSource } from 'services';
import { isRequestCancelled } from 'services/httpClient';
import { loadAllPages } from 'utils/functions';
import * as issues from 'utils/issues';
import Notification from 'utils/notification';
import { Shipment } from '@tassoinc/core-api-client';

interface State {
  shipments: Shipment[];
  shipment: Shipment | null;
  error: string | null;
  loading: boolean;
}

type SingleResultCallback = (shipment: Shipment | null) => Promise<void> | void;
type MultipleResultsCallback = (shipments: Shipment[] | null) => Promise<void> | void;

type CreateShipmentFunction = (data: Record<string, any>, callback?: SingleResultCallback) => Promise<void>;
type GetShipmentsFunction = (params: Record<string, any>, callback?: MultipleResultsCallback) => Promise<void>;
type GetShipmentFunction = (id: string, callback?: SingleResultCallback) => Promise<void>;
type UpdateShipmentFunction = (id: string, data: Record<string, any>, callback?: SingleResultCallback) => Promise<void>;
type DeleteShipmentFunction = (id: string, callback?: SingleResultCallback) => Promise<void>;
type ResetShipmentsFunction = () => void;

interface Context extends State {
  createShipment: CreateShipmentFunction;
  updateShipment: UpdateShipmentFunction;
  getShipments: GetShipmentsFunction;
  getShipment: GetShipmentFunction;
  deleteShipment: DeleteShipmentFunction;
  resetShipments: ResetShipmentsFunction;
}

const getInitialState = (): State => ({
  shipments: [],
  shipment: null,
  error: null,
  loading: false,
});

const ShipmentsContext = createContext<Context>({
  ...getInitialState(),
  getShipments: async () => {},
  getShipment: async () => {},
  resetShipments: () => {},
  createShipment: async () => {},
  updateShipment: async () => {},
  deleteShipment: async () => {},
});

const ShipmentsProvider: FC = (props) => {
  const [state, setState] = useState<State>(getInitialState);
  const cancelTokenSource = useRef<CancelTokenSource | null>(null);

  const getShipments: GetShipmentsFunction = async (params, callback?) => {
    setState((s) => ({
      ...s,
      error: null,
      loading: true,
    }));

    try {
      cancelTokenSource.current = makeCancelTokenSource();

      const requestParams = typeof params === 'string' ? { projectIds: params } : params;

      const shipments: Shipment[] = [];

      if (typeof requestParams.ids === 'string') {
        const { ids, ...otherParams } = requestParams;
        const allIds = ids !== '' ? [...new Set(ids.split(','))] : [];
        const promises: Promise<any>[] = [];

        const resolvePromises = async (): Promise<boolean> => {
          const promisesToResolve = promises.splice(0, promises.length);
          const resolvedData = await Promise.all(promisesToResolve);
          for (let j = 0; j < resolvedData.length; j += 1) {
            if (isRequestCancelled(resolvedData[j])) {
              return false;
            }
            shipments.push(...resolvedData[j].results);
          }
          return true;
        };

        for (let i = 0; i < allIds.length; i += 50) {
          const idsSubset = allIds.slice(i, i + 50);
          promises.push(
            shipmentApi.getShipments(
              { ids: idsSubset.join(','), ...otherParams },
              {
                page: 1,
                pageLength: 50,
                includeTotalCount: true,
              },
              { cancelToken: cancelTokenSource.current.token }
            )
          );

          if (promises.length === 5) {
            if (!(await resolvePromises())) {
              return; // requests cancelled by client
            }
          }
        }

        if (promises.length > 0) {
          if (!(await resolvePromises())) {
            return; // requests cancelled by client
          }
        }
      } else {
        type PayloadType = Parameters<typeof shipmentApi.getShipments>[0];
        const results = await loadAllPages<Shipment, PayloadType>(
          shipmentApi.getShipments,
          requestParams,
          {
            pageLength: 50,
            sortBy: 'createdAt',
            isDescending: false,
          },
          { cancelToken: cancelTokenSource.current.token }
        );

        shipments.push(...results);
      }

      setState((s) => ({
        ...s,
        shipments,
      }));

      if (callback) {
        await callback(shipments);
      }
    } catch (e) {
      cancelTokenSource.current = null;

      const errorMessage = issues.getErrorMessage(e);

      setState((s) => ({
        ...s,
        error: errorMessage,
      }));

      Notification({
        type: 'error',
        message: 'Cannot get shipments',
        description: errorMessage,
      });

      if (callback) {
        await callback(null);
      }
    } finally {
      setState((s) => ({
        ...s,
        loading: false,
      }));
    }
  };

  const getShipment: GetShipmentFunction = async (id, callback?) => {
    try {
      const shipment: Shipment = await shipmentApi.getShipment(id);

      setState((s) => ({
        ...s,
        shipment,
      }));

      if (callback) {
        await callback(shipment);
      }
    } catch (e) {
      const errorMessage = issues.getErrorMessage(e);

      setState((s) => ({
        ...s,
        error: errorMessage,
      }));

      Notification({
        type: 'error',
        message: 'Cannot get shipment details',
        description: errorMessage,
      });

      if (callback) {
        await callback(null);
      }
    } finally {
      setState((s) => ({
        ...s,
        loading: false,
      }));
    }
  };

  const createShipment: CreateShipmentFunction = async (data, callback?) => {
    try {
      setState((s) => ({
        ...s,
        loading: true,
        error: null,
      }));

      const shipment: Shipment = await shipmentApi.createShipment(data);

      setState((s) => ({
        ...s,
        shipments: [...s.shipments, shipment],
      }));

      Notification({ type: 'success', message: 'Shipments successfully created' });

      if (callback) {
        await callback(shipment);
      }
    } catch (e) {
      const errorMessage = issues.getErrorMessage(e);

      setState((s) => ({
        ...s,
        error: errorMessage,
      }));

      Notification({
        type: 'error',
        message: 'Shipment was not created',
        description: errorMessage,
      });

      if (callback) {
        await callback(null);
      }
    } finally {
      setState((s) => ({
        ...s,
        loading: false,
      }));
    }
  };

  const updateShipment: UpdateShipmentFunction = async (id, data, callback?) => {
    try {
      setState((s) => ({
        ...s,
        loading: true,
        error: null,
      }));

      const shipment: Shipment = await shipmentApi.updateShipment(id, data);

      setState((s) => ({
        ...s,
        shipments: s.shipments.map((p) => (p.id === id ? shipment : p)),
        shipment: s.shipment && s.shipment.id === id ? shipment : s.shipment,
      }));

      Notification({ type: 'success', message: 'Shipment updated' });

      if (callback) {
        await callback(shipment);
      }
    } catch (e) {
      const errorMessage = issues.getErrorMessage(e);

      setState((s) => ({
        ...s,
        error: errorMessage,
      }));

      Notification({
        type: 'error',
        message: 'Shipment was not updated',
        description: errorMessage,
      });

      if (callback) {
        await callback(null);
      }
    } finally {
      setState((s) => ({
        ...s,
        loading: false,
      }));
    }
  };

  const deleteShipment: DeleteShipmentFunction = async (id, callback?) => {
    try {
      setState((s) => ({
        ...s,
        loading: true,
        error: null,
      }));

      const shipment: Shipment = await shipmentApi.deleteShipment(id);

      setState((s) => ({
        ...s,
        shipments: s.shipments.filter((p) => p.id !== id),
        shipment: s.shipment && s.shipment.id === id ? null : s.shipment,
      }));

      Notification({ type: 'success', message: 'Shipment deleted' });

      if (callback) {
        await callback(shipment);
      }
    } catch (e) {
      const errorMessage = issues.getErrorMessage(e);

      setState((s) => ({
        ...s,
        error: errorMessage,
      }));

      Notification({
        type: 'error',
        message: 'Shipment was not deleted',
        description: errorMessage,
      });

      if (callback) {
        await callback(null);
      }
    } finally {
      setState((s) => ({
        ...s,
        loading: false,
      }));
    }
  };

  const resetShipments: ResetShipmentsFunction = () => {
    if (cancelTokenSource.current) {
      cancelTokenSource.current.cancel();
    }
    setState(getInitialState());
  };

  return (
    <ShipmentsContext.Provider
      value={{
        shipments: state.shipments,
        shipment: state.shipment,
        loading: state.loading,
        error: state.error,
        getShipments,
        resetShipments,
        getShipment,
        createShipment,
        updateShipment,
        deleteShipment,
      }}
      {...props}
    />
  );
};

const useShipments = (): Context => React.useContext(ShipmentsContext);

export { ShipmentsProvider, useShipments };
