import React, { FC, useState, createContext, useRef } from 'react';
import { loadAllPages, normalizePagingResponse } from 'utils/functions';
import { makeCancelTokenSource, CancelTokenSource } from 'services/index';

import { getErrorMessage } from 'utils/issues';
import Notification from 'utils/notification';
import { PagingInfo } from 'utils/types';
import * as api from '../api/idPoolIdentifierImports';
import { IdPool } from './idPools';

export type IdPoolIdentifierImport = {
  id: string;
  idPoolId: string;
  userId: string;
  identifierCount: number;
  fileName: string;
  fileKey: string | null;
  status: string;
  errorDetails: string | null;
  createdAt: string;
  updatedAt: string | null;
  fileUrl?: string;
  uploadInfo?: string;

  // Optional hydrate-able properties
  idPool?: IdPool;
  user?: Record<string, any>;
};

export type GetAllParams = {
  page: number;
  pageLength: number;
  includeTotalCount: boolean;
  sortBy: string;
  sortOrder: 'asc' | 'desc';
  idPoolIds?: string[];
};

type Options = {
  hydration?: ('idPool' | 'user')[];
  skipStateUpdate?: boolean;
};

type SingleItemCallback = (item: IdPoolIdentifierImport | null) => Promise<void> | void;
type MultiItemCallback = (items: IdPoolIdentifierImport[] | null, paging: PagingInfo | null) => Promise<void> | void;

type CreateFunction = (
  data: api.CreatePayload['data'],
  options?: Options,
  callback?: SingleItemCallback
) => Promise<void>;

type GetAllFunction = (params?: GetAllParams, options?: Options, callback?: MultiItemCallback) => Promise<void>;

type GetByIdFunction = (id: string, config?: Options, callback?: SingleItemCallback) => Promise<void>;

type UpdateFunction = (
  id: string,
  data: Record<string, never>,
  options?: Options,
  callback?: SingleItemCallback
) => Promise<void>;

type DeleteFunction = (id: string, options: Options, callback?: SingleItemCallback) => Promise<void>;

type ResetFunction = () => void;

type State = {
  idPoolIdentifierImports: IdPoolIdentifierImport[];
  idPoolIdentifierImport: IdPoolIdentifierImport | null;
  loading: boolean;
  error: string | null;
};

type Context = State & {
  createIdPoolIdentifierImport: CreateFunction;
  getIdPoolIdentifierImports: GetAllFunction;
  getIdPoolIdentifierImport: GetByIdFunction;
  updateIdPoolIdentifierImport: UpdateFunction;
  deleteIdPoolIdentifierImport: DeleteFunction;
  resetIdPoolIdentifierImports: ResetFunction;
};

const getInitialState = (): State => ({
  idPoolIdentifierImports: [],
  idPoolIdentifierImport: null,
  loading: false,
  error: null,
});

const IdPoolIdentifierImportsContext = createContext<Context>({
  ...getInitialState(),
  getIdPoolIdentifierImports: async () => {},
  getIdPoolIdentifierImport: async () => {},
  createIdPoolIdentifierImport: async () => {},
  updateIdPoolIdentifierImport: async () => {},
  deleteIdPoolIdentifierImport: async () => {},
  resetIdPoolIdentifierImports: () => {},
});

const IdPoolIdentifierImportsProvider: FC<any> = (props) => {
  const [state, setState] = useState<State>(getInitialState);
  const cancelTokenSource = useRef<CancelTokenSource | null>(null);

  const createIdPoolIdentifierImport: CreateFunction = async (data, options = {}, callback) => {
    const skipStateUpdate = options.skipStateUpdate === true;

    if (!skipStateUpdate) {
      setState((s) => ({
        ...s,
        loading: true,
        error: null,
      }));
    }

    try {
      cancelTokenSource.current = makeCancelTokenSource();

      const payload: api.CreatePayload = {
        data,
        hydration: options.hydration,
        cancelToken: cancelTokenSource.current.token,
      };

      const newItem = await api.createIdPoolIdentifierImport(payload);

      if (newItem.isCancelledByClient) {
        return;
      }

      if (!skipStateUpdate) {
        setState((s) => ({
          ...s,
          idPoolIdentifierImports: [...s.idPoolIdentifierImports, newItem],
        }));
      }

      if (callback) {
        await callback(newItem);
      }
    } catch (e) {
      const errorMessage = getErrorMessage(e);

      if (!skipStateUpdate) {
        setState((s) => ({
          ...s,
          error: errorMessage,
        }));
      }

      Notification({
        type: 'error',
        message: 'Failed to create Id Pool Identifier Import',
        description: errorMessage,
      });

      if (callback) {
        await callback(null);
      }
    } finally {
      if (!skipStateUpdate) {
        setState((s) => ({
          ...s,
          loading: false,
        }));
      }
    }
  };

  const getIdPoolIdentifierImports: GetAllFunction = async (params, options = {}, callback) => {
    const skipStateUpdate = options.skipStateUpdate === true;

    if (!skipStateUpdate) {
      setState((s) => ({
        ...s,
        loading: true,
        error: null,
      }));
    }

    const page = params?.page || 1;
    const pageLength = params?.pageLength || 20;
    const includeTotalCount =
      params && typeof params.includeTotalCount === 'boolean' ? params.includeTotalCount : false;
    const sortBy = params?.sortBy || 'createdAt';
    const isDescending = params && params.sortOrder ? params.sortOrder === 'desc' : false;

    const query: Record<string, string> = {};

    if (params) {
      if (Array.isArray(params.idPoolIds)) {
        query.idPoolIds = params.idPoolIds.join(',');
      }
    }

    try {
      cancelTokenSource.current = makeCancelTokenSource();

      const payload: api.GetAllPayload = {
        hydration: options.hydration,
        cancelToken: cancelTokenSource.current.token,
        query,
      };

      let items: IdPoolIdentifierImport[];
      let paging: PagingInfo;

      if (pageLength === -1) {
        // Load all data
        type PayloadType = Parameters<typeof api.getIdPoolIdentifierImports>[0];
        items = await loadAllPages<IdPoolIdentifierImport, PayloadType>(
          api.getIdPoolIdentifierImports,
          payload,
          {
            pageLength: 100,
            sortBy,
            isDescending,
          },
          { cancelToken: cancelTokenSource.current.token }
        );

        paging = {
          page: 1,
          pageLength: items.length,
          totalCount: items.length,
          sortBy,
          sortOrder: isDescending ? 'desc' : 'asc',
        };
      } else {
        const data = await api.getIdPoolIdentifierImports(payload, {
          page,
          pageLength,
          sortBy,
          isDescending,
          includeTotalCount,
        });

        if (data.isCancelledByClient) {
          return;
        }

        items = data.results;

        paging = normalizePagingResponse(data.paging);
      }

      if (!skipStateUpdate) {
        setState((s) => ({
          ...s,
          idPoolIdentifierImports: items,
        }));
      }

      if (callback) {
        await callback(items, paging);
      }
    } catch (e) {
      const errorMessage = getErrorMessage(e);

      if (!skipStateUpdate) {
        setState((s) => ({
          ...s,
          error: errorMessage,
        }));
      }

      Notification({
        type: 'error',
        message: 'Failed to load Id Pool Identifier Imports',
        description: errorMessage,
      });

      if (callback) {
        await callback(null, null);
      }
    } finally {
      if (!skipStateUpdate) {
        setState((s) => ({
          ...s,
          loading: false,
        }));
      }
    }
  };

  const getIdPoolIdentifierImport: GetByIdFunction = async (id, options = {}, callback) => {
    const skipStateUpdate = options.skipStateUpdate === true;

    if (!skipStateUpdate) {
      setState((s) => ({
        ...s,
        loading: true,
        error: null,
      }));
    }

    try {
      cancelTokenSource.current = makeCancelTokenSource();

      const payload: api.GetByIdPayload = {
        id,
        hydration: options.hydration,
        cancelToken: cancelTokenSource.current.token,
      };

      const result = await api.getIdPoolIdentifierImport(payload);

      if (result.isCancelledByClient) {
        return;
      }

      const item = result as IdPoolIdentifierImport;

      if (!skipStateUpdate) {
        setState((s) => ({
          ...s,
          idPoolIdentifierImport: item,
        }));
      }

      if (callback) {
        await callback(item);
      }
    } catch (e) {
      const errorMessage = getErrorMessage(e);

      if (!skipStateUpdate) {
        setState((s) => ({
          ...s,
          error: errorMessage,
        }));
      }

      Notification({
        type: 'error',
        message: 'Failed to get Id Pool Identifier Import details',
        description: errorMessage,
      });

      if (callback) {
        await callback(null);
      }
    } finally {
      if (!skipStateUpdate) {
        setState((s) => ({
          ...s,
          loading: false,
        }));
      }
    }
  };

  const updateIdPoolIdentifierImport: UpdateFunction = async (id, data, options = {}, callback) => {
    const skipStateUpdate = options.skipStateUpdate === true;

    if (!skipStateUpdate) {
      setState((s) => ({
        ...s,
        loading: true,
        error: null,
      }));
    }

    try {
      cancelTokenSource.current = makeCancelTokenSource();

      const payload: api.UpdatePayload = {
        id,
        data,
        hydration: options.hydration,
        cancelToken: cancelTokenSource.current.token,
      };

      const item = await api.updateIdPoolIdentifierImport(payload);

      if (item.isCancelledByClient) {
        return;
      }

      if (!skipStateUpdate) {
        setState((s) => ({
          ...s,
          idPoolIdentifierImports: s.idPoolIdentifierImports.map((imp) => (imp.id === id ? item : imp)),
          idPoolIdentifierImport:
            s.idPoolIdentifierImport && s.idPoolIdentifierImport.id === id ? item : s.idPoolIdentifierImport,
        }));
      }

      if (callback) {
        await callback(item);
      }
    } catch (e) {
      const errorMessage = getErrorMessage(e);

      if (!skipStateUpdate) {
        setState((s) => ({
          ...s,
          error: errorMessage,
        }));
      }

      Notification({
        type: 'error',
        message: 'Failed to update Id Pool Identifier Import',
        description: errorMessage,
      });

      if (callback) {
        await callback(null);
      }
    } finally {
      if (!skipStateUpdate) {
        setState((s) => ({
          ...s,
          loading: false,
        }));
      }
    }
  };

  const deleteIdPoolIdentifierImport: DeleteFunction = async (id, options = {}, callback) => {
    const skipStateUpdate = options.skipStateUpdate === true;

    if (!skipStateUpdate) {
      setState((s) => ({
        ...s,
        loading: true,
        error: null,
      }));
    }

    try {
      cancelTokenSource.current = makeCancelTokenSource();

      const payload: api.DeletePayload = {
        id,
        hydration: options.hydration,
        cancelToken: cancelTokenSource.current.token,
      };

      const item = await api.deleteIdPoolIdentifierImport(payload);

      if (item.isCancelledByClient) {
        return;
      }

      if (!skipStateUpdate) {
        setState((s) => ({
          ...s,
          idPoolIdentifierImports: s.idPoolIdentifierImports.filter((imp) => imp.id !== id),
          idPoolIdentifierImport:
            s.idPoolIdentifierImport && s.idPoolIdentifierImport.id === id ? null : s.idPoolIdentifierImport,
        }));
      }

      if (callback) {
        await callback(item);
      }
    } catch (e) {
      const errorMessage = getErrorMessage(e);

      if (!skipStateUpdate) {
        setState((s) => ({
          ...s,
          error: errorMessage,
        }));
      }

      Notification({
        type: 'error',
        message: 'Failed to delete Id Pool Identifier Import',
        description: errorMessage,
      });

      if (callback) {
        await callback(null);
      }
    } finally {
      if (!skipStateUpdate) {
        setState((s) => ({
          ...s,
          loading: false,
        }));
      }
    }
  };

  const resetIdPoolIdentifierImports: ResetFunction = () => {
    if (cancelTokenSource.current) {
      cancelTokenSource.current.cancel();
      cancelTokenSource.current = null;
    }

    setState(getInitialState);
  };

  return (
    <IdPoolIdentifierImportsContext.Provider
      value={{
        idPoolIdentifierImports: state.idPoolIdentifierImports,
        idPoolIdentifierImport: state.idPoolIdentifierImport,
        loading: state.loading,
        error: state.error,
        getIdPoolIdentifierImports,
        getIdPoolIdentifierImport,
        createIdPoolIdentifierImport,
        updateIdPoolIdentifierImport,
        deleteIdPoolIdentifierImport,
        resetIdPoolIdentifierImports,
      }}
      {...props}
    />
  );
};

const useIdPoolIdentifierImports = (): Context => React.useContext(IdPoolIdentifierImportsContext);

export { IdPoolIdentifierImportsProvider, useIdPoolIdentifierImports };
