import React, { FC, useState, createContext, useRef } from 'react';
import { loadAllPages, loadByIdsInBatches, normalizePagingResponse } from 'utils/functions';
import { makeCancelTokenSource, CancelTokenSource } from 'services/index';

import { getErrorMessage } from 'utils/issues';
import Notification from 'utils/notification';
import { PagingInfo } from 'utils/types';
import { OrderFulfillmentType } from '@tassoinc/core-api-client';
import * as api from '../api/orders';
import { Customer } from './customers';
import { IPatient } from './patients';
import { Project } from './projects';

export interface IPrintable {
  fileKey: string;
  timestamp: string;
  kitItemId: string;
  uploadId: string;

  // Set when "tasso-hydration: printables" header is used.
  url?: string;
}

export type Order = {
  id: string;
  patientId: string | null;
  patient?: IPatient;
  batchOrderId: string | null;
  batchOrder?: any;
  customerId: string;
  customer?: Customer;
  projectId: string;
  project?: Project;
  userId: string | null;
  status: string;
  shipBy: string;
  analyteIds: string[];
  enterWorkflowRetryCount: number;
  createdAt: string;
  updatedAt: string | null;
  statusChangedAt: string | null;
  integratorId: string | null;
  orderWorkflowStepCompletionFlags: Record<string, string>;
  customerProvidedNpi: string | null;
  customerProvidedProviderFirstName: string | null;
  customerProvidedProviderLastName: string | null;
  replacesDeviceId: string | null;
  replacementHarmCaused: boolean | null;
  replacementReason: string | null;
  replacementDescription: string | null;
  customAttributeValues: Record<string, string>;
  printables: IPrintable[] | null;
  fulfillmentType: OrderFulfillmentType | null;
  orderTemplateId: string | null;
  orderNum: string | null;
};

type Options = {
  // "printables" does not set the "Content-Disposition" header
  // "printablesDownload" sets "Content-Disposition: attachment"

  hydrate?: ('customer' | 'printables' | 'printablesDownload')[];
};

export type GetOrderParams = {
  page?: number;
  pageLength?: number;
  includeTotalCount?: boolean;
  sortBy?: string;
  sortOrder?: 'asc' | 'desc';
  patientIds?: string[];
  ids?: string[];
};

export type GetOrdersForPatientsParams = {
  patientIds: string[];
};

export type GetOrdersByIdsParams = {
  ids: string[];
};

type GetOrdersFunction = (
  params?: GetOrderParams,
  config?: Options,
  callback?: (orders: Order[] | null, paging?: PagingInfo) => Promise<void>
) => Promise<void>;

type GetOrderFunction = (
  id: string,
  config?: Options,
  callback?: (order: Order | null) => Promise<void>
) => Promise<void>;

type CreateOrderFunction = (
  params: api.CreateOrderPayload,
  config?: Options,
  callback?: (order: Order | null) => Promise<void>
) => Promise<void>;

type DeleteOrderFunction = (
  id: string,
  config?: Options,
  callback?: (order: Order | null) => Promise<void>
) => Promise<void>;

type UpdateOrderFunction = (
  id: string,
  data: api.UpdateOrderPayload,
  config?: Options,
  callback?: (order: Order | null) => Promise<void>
) => Promise<void>;

type CancelOrderFunction = (
  id: string,
  config?: Options,
  callback?: (order: Order | null) => Promise<void>
) => Promise<void>;

type GetOrdersForPatientsFunction = (params: GetOrdersForPatientsParams) => Promise<Order[]>;
type GetOrdersByIdsFunction = (params: GetOrdersByIdsParams) => Promise<Order[]>;

type State = {
  orders: Order[];
  order: Order | null;
  loading: boolean;
  error: string | null;
};

type Context = State & {
  getOrders: GetOrdersFunction;
  getOrdersForPatients: GetOrdersForPatientsFunction;
  getOrdersByIds: GetOrdersByIdsFunction;
  getOrder: GetOrderFunction;
  createOrder: CreateOrderFunction;
  updateOrder: UpdateOrderFunction;
  cancelOrder: CancelOrderFunction;
  deleteOrder: DeleteOrderFunction;
  resetOrders: () => void;
};

const getInitialState = (): State => ({
  orders: [],
  order: null,
  loading: false,
  error: null,
});

const OrdersContext = createContext<Context>({
  ...getInitialState(),
  getOrders: async () => {},
  getOrdersForPatients: async () => [],
  getOrdersByIds: async () => [],
  getOrder: async () => {},
  createOrder: async () => {},
  updateOrder: async () => {},
  cancelOrder: async () => {},
  deleteOrder: async () => {},
  resetOrders: () => {},
});

const OrderProvider: FC<any> = (props) => {
  const [state, setState] = useState<State>(getInitialState);
  const cancelTokenSource = useRef<CancelTokenSource | null>(null);

  /**
   * Loads and returns orders for a given list of patients.
   * Does not modify state.
   */
  const getOrdersForPatients: GetOrdersForPatientsFunction = async (params) => {
    const { patientIds } = params;

    if (patientIds.length === 0) {
      return [];
    }

    try {
      const orders = await loadByIdsInBatches<Order>(api.getOrders, 'patientIds', patientIds, {}, true);

      return orders;
    } catch (e) {
      const errorMessage = getErrorMessage(e);

      setState((s) => ({
        ...s,
        error: errorMessage,
      }));

      Notification({
        type: 'error',
        message: 'Failed to load orders for patients',
        description: errorMessage,
      });

      return [];
    }
  };

  /**
   * Loads and returns orders for a given list of order ids.
   * Split the ids into batches so the request does not become too long
   * Does not modify state.
   */
  const getOrdersByIds: GetOrdersByIdsFunction = async (params) => {
    const { ids } = params;

    if (ids.length === 0) {
      return [];
    }

    try {
      const orders = await loadByIdsInBatches<Order>(api.getOrders, 'ids', ids, {}, true);

      return orders;
    } catch (e) {
      const errorMessage = getErrorMessage(e);

      setState((s) => ({
        ...s,
        error: errorMessage,
      }));

      Notification({
        type: 'error',
        message: 'Failed to load orders',
        description: errorMessage,
      });

      return [];
    }
  };

  const getOrders: GetOrdersFunction = async (params, config, callback) => {
    setState((s) => ({
      ...s,
      loading: true,
      error: null,
    }));

    const page = params?.page || 1;
    const pageLength = params?.pageLength || 20;
    const includeTotalCount =
      params && typeof params.includeTotalCount === 'boolean' ? params.includeTotalCount : false;
    const sortBy = params?.sortBy || 'createdAt';
    const isDescending = params && params.sortOrder ? params.sortOrder === 'desc' : false;

    const query: Record<string, string> = {};

    if (params) {
      if (Array.isArray(params.patientIds)) {
        query.patientIds = params.patientIds.join(',');
      }
      if (Array.isArray(params.ids)) {
        query.ids = params.ids.join(',');
      }
    }

    try {
      let paging: PagingInfo;
      let orders: Order[];

      cancelTokenSource.current = makeCancelTokenSource();

      const options: api.OrderRequestOptions = {
        hydrate: config?.hydrate || [],
        cancelToken: cancelTokenSource.current.token,
      };

      if (pageLength === -1) {
        // Load all data
        type PayloadType = Parameters<typeof api.getOrders>[0];
        orders = await loadAllPages<Order, PayloadType>(
          api.getOrders,
          { query, options },
          {
            pageLength: 100,
            sortBy,
            isDescending,
          },
          { cancelToken: cancelTokenSource.current.token }
        );

        paging = {
          page: 1,
          pageLength: orders.length,
          totalCount: orders.length,
          sortBy,
          sortOrder: isDescending ? 'desc' : 'asc',
        };
      } else {
        const data = await api.getOrders(
          { query, options },
          {
            page,
            pageLength,
            sortBy,
            isDescending,
            includeTotalCount,
          }
        );

        if (data.isCancelledByClient) {
          return;
        }

        orders = data.results;

        paging = normalizePagingResponse(data.paging);
      }

      setState((s) => ({
        ...s,
        orders,
      }));

      if (callback) {
        await callback(orders, paging);
      }
    } catch (e) {
      const errorMessage = getErrorMessage(e);

      setState((s) => ({
        ...s,
        error: errorMessage,
      }));

      Notification({
        type: 'error',
        message: 'Failed to load Orders',
        description: errorMessage,
      });

      if (callback) {
        await callback(null);
      }
    } finally {
      setState((s) => ({
        ...s,
        loading: false,
      }));
    }
  };

  const getOrder: GetOrderFunction = async (id, config, callback) => {
    setState((s) => ({
      ...s,
      loading: true,
      error: null,
    }));

    try {
      cancelTokenSource.current = makeCancelTokenSource();

      const options: api.OrderRequestOptions = {
        hydrate: config?.hydrate || [],
        cancelToken: cancelTokenSource.current.token,
      };

      const order = await api.getOrder(id, options);

      if (order.isCancelledByClient) {
        return;
      }

      setState((s) => ({
        ...s,
        order,
      }));

      if (callback) {
        await callback(order);
      }
    } catch (e) {
      const errorMessage = getErrorMessage(e);

      setState((s) => ({
        ...s,
        error: errorMessage,
      }));

      Notification({
        type: 'error',
        message: 'Failed to get Order details',
        description: errorMessage,
      });

      if (callback) {
        await callback(null);
      }
    } finally {
      setState((s) => ({
        ...s,
        loading: false,
      }));
    }
  };

  const createOrder: CreateOrderFunction = async (data, config, callback) => {
    setState((s) => ({
      ...s,
      loading: true,
      error: null,
    }));

    try {
      cancelTokenSource.current = makeCancelTokenSource();

      const options: api.OrderRequestOptions = {
        hydrate: config?.hydrate || [],
        cancelToken: cancelTokenSource.current.token,
      };

      const order = await api.createOrder(data, options);

      if (order.isCancelledByClient) {
        return;
      }

      Notification({ type: 'success', message: 'Order Created! Devices will show on page within 3 minutes.' });

      setState((s) => ({
        ...s,
        orders: [...s.orders, order],
      }));

      if (callback) {
        await callback(order);
      }
    } catch (e) {
      const errorMessage = getErrorMessage(e);

      setState((s) => ({
        ...s,
        error: errorMessage,
      }));

      Notification({
        type: 'error',
        message: 'Failed to create Order',
        description: errorMessage,
      });

      if (callback) {
        await callback(null);
      }
    } finally {
      setState((s) => ({
        ...s,
        loading: false,
      }));
    }
  };

  const updateOrder: UpdateOrderFunction = async (id, data, config, callback) => {
    setState((s) => ({
      ...s,
      loading: true,
      error: null,
    }));

    try {
      cancelTokenSource.current = makeCancelTokenSource();

      const options: api.OrderRequestOptions = {
        hydrate: config?.hydrate || [],
        cancelToken: cancelTokenSource.current.token,
      };

      const updatedOrder = await api.updateOrder(id, data, options);

      if (updatedOrder.isCancelledByClient) {
        return;
      }

      setState((s) => ({
        ...s,
        orders: s.orders.map((order) => (order.id === id ? updatedOrder : order)),
        order: s.order && s.order.id === id ? updatedOrder : s.order,
      }));

      if (callback) {
        await callback(updatedOrder);
      }
    } catch (e) {
      const errorMessage = getErrorMessage(e);

      setState((s) => ({
        ...s,
        error: errorMessage,
      }));

      Notification({
        type: 'error',
        message: 'Failed to update Order',
        description: errorMessage,
      });

      if (callback) {
        await callback(null);
      }
    } finally {
      setState((s) => ({
        ...s,
        loading: false,
      }));
    }
  };

  const cancelOrder: CancelOrderFunction = async (id, config, callback) => {
    setState((s) => ({
      ...s,
      loading: true,
      error: null,
    }));

    try {
      cancelTokenSource.current = makeCancelTokenSource();

      const options: api.OrderRequestOptions = {
        hydrate: config?.hydrate || [],
        cancelToken: cancelTokenSource.current.token,
      };

      const cancelledOrder = await api.cancelOrder(id, options);

      if (cancelledOrder.isCancelledByClient) {
        return;
      }

      setState((s) => ({
        ...s,
        orders: s.orders.filter((order) => order.id !== id),
        order: s.order && s.order.id === id ? null : s.order,
      }));

      if (callback) {
        await callback(cancelledOrder);
      }
    } catch (e) {
      const errorMessage = getErrorMessage(e);

      setState((s) => ({
        ...s,
        error: errorMessage,
      }));

      Notification({
        type: 'error',
        message: 'Failed to cancel Order(s)',
        description: errorMessage,
      });

      if (callback) {
        await callback(null);
      }
    } finally {
      setState((s) => ({
        ...s,
        loading: false,
      }));
    }
  };

  const deleteOrder: DeleteOrderFunction = async (id, config, callback) => {
    setState((s) => ({
      ...s,
      loading: true,
      error: null,
    }));

    try {
      cancelTokenSource.current = makeCancelTokenSource();

      const options: api.OrderRequestOptions = {
        hydrate: config?.hydrate || [],
        cancelToken: cancelTokenSource.current.token,
      };

      const deletedOrder = await api.deleteOrder(id, options);

      if (deletedOrder.isCancelledByClient) {
        return;
      }

      setState((s) => ({
        ...s,
        orders: s.orders.filter((order) => order.id !== id),
        order: s.order && s.order.id === id ? null : s.order,
      }));

      if (callback) {
        await callback(deletedOrder);
      }
    } catch (e) {
      const errorMessage = getErrorMessage(e);

      setState((s) => ({
        ...s,
        error: errorMessage,
      }));

      Notification({
        type: 'error',
        message: 'Failed to delete Order',
        description: errorMessage,
      });

      if (callback) {
        await callback(null);
      }
    } finally {
      setState((s) => ({
        ...s,
        loading: false,
      }));
    }
  };

  const resetOrders = (): void => {
    if (cancelTokenSource.current) {
      cancelTokenSource.current.cancel();
      cancelTokenSource.current = null;
    }
    setState(getInitialState);
  };

  return (
    <OrdersContext.Provider
      value={{
        orders: state.orders,
        order: state.order,
        loading: state.loading,
        error: state.error,
        getOrders,
        getOrdersForPatients,
        getOrdersByIds,
        getOrder,
        createOrder,
        updateOrder,
        cancelOrder,
        deleteOrder,
        resetOrders,
      }}
      {...props}
    />
  );
};

const useOrders = (): Context => React.useContext(OrdersContext);

export { OrderProvider, useOrders };
