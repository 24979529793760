import React, { FC, useState, useEffect, ReactNode } from 'react';

import { CreateProjectPayload } from 'api/projects';
import { Collapse, ProjectsList, SectionHeader, CreateProjectModal } from 'components';
import { PlusCircleOutlined } from 'components/icons';
import { PROJECT_STATUSES, ROUTES, USER_ROLES } from 'constants/index';
import { useAuth } from 'context/auth';
import { useProjectAnalyteLinks } from 'context/projectAnalyteLinks';
import { useProjectContacts } from 'context/projectContacts';
import { useProjectLaboratories } from 'context/projectLaboratories';
import { useProjectMetrics } from 'context/projectMetrics';
import { IProject, useProjects } from 'context/projects';
import { RcFile } from 'antd/lib/upload';
import { uploadFileToS3, waitForUploadedFile } from 'utils/fileUploading';

const Projects: FC = () => {
  const { profile } = useAuth();
  const isAdmin = (profile?.role || '') === USER_ROLES.internalAdmin;
  const isCustomerService = (profile?.role || '') === USER_ROLES.internalCustomerService;
  const isShipper = (profile?.role || '') === USER_ROLES.internalShipper;

  const { getProjects, getProject, createProject, resetProjects, projects, loading: projectsLoading } = useProjects();
  const { getProjectMetrics, metrics, resetProjectMetrics, loading: metricsLoading } = useProjectMetrics();
  const { resetProjectLaboratories } = useProjectLaboratories();
  const { createProjectContact, resetProjectContacts } = useProjectContacts();
  const { resetProjectAnalyteLinks } = useProjectAnalyteLinks();
  const [createProjectModalVisible, setCreateProjectModalVisible] = useState(false);

  useEffect(() => {
    resetProjects();
    resetProjectMetrics();
    resetProjectLaboratories();

    getProjects(true);
    getProjectMetrics();

    return () => {
      resetProjects();
      resetProjectMetrics();
      resetProjectLaboratories();
      resetProjectContacts();
      resetProjectAnalyteLinks();
    };
  }, []);

  const openCreateProjectModal = () => {
    setCreateProjectModalVisible(true);
  };

  const closeCreateProjectModal = () => {
    setCreateProjectModalVisible(false);
  };

  const breadcrumbNameMap = {
    [ROUTES.projects]: 'Projects',
  };

  const handleCreateProject = (e: any) => {
    e.stopPropagation();
    openCreateProjectModal();
  };

  const onCreateProjectCallback = () => {
    closeCreateProjectModal();
    getProjects(true);
  };

  const onCreateProject = (params: { customerLetterFile?: RcFile; [key: string]: any }) => {
    const projectData: CreateProjectPayload = {
      customerId: params.customerId,
      name: params.name,
      code: params.code,
      projectType: params.projectType,
      deviceType: params.deviceType,
      package1Type: params.package1Type,
      package2Type: params.package2Type,
      initialSalesOrder: params.initialSalesOrder,
      replacementSalesOrder: params.replacementSalesOrder,
      packingInstructions: params.packingInstructions,
      labelingStrategy: params.labelingStrategy,
      distributionModel: params.distributionModel,
      labelIdentifierSource: params.labelIdentifierSource,
      purpose: params.projectPurpose,
      physicianApprovalMode: params.physicianApprovalMode,
      upsAccountIdToPatient: params.upsAccountIdToPatient,
      upsAccountIdToLab: params.upsAccountIdToLab,
      customerReferenceNumber: params.customerReferenceNumber,
      patientExperienceEnabled: params.patientExperienceEnabled,
      analyteIds: params.analyteIds,
      idPoolIds: params.idPoolIds,
      kitItemIds: params.kitItemIds,
      laboratoryIds: [params.laboratoryId],
    };

    const filteredProjectData = Object.keys(projectData)
      .filter((k) => typeof projectData[k as keyof typeof projectData] !== 'undefined')
      .reduce((acc, k) => ({ ...acc, [k]: projectData[k as keyof typeof projectData] }), {} as CreateProjectPayload);

    const projectPayload: CreateProjectPayload = filteredProjectData;

    if (params.customerLetterFile) {
      projectPayload.customerLetterFilename = (params.customerLetterFile as RcFile).name;
    }

    createProject(projectPayload, async (project: IProject) => {
      if (params.customerLetterFile) {
        await uploadFileToS3(project.customerLetterUploadInfo!, params.customerLetterFile);

        // Ping API until there's a value in customerLetterKey,
        // which means the file is uploaded and processed.
        await waitForUploadedFile(
          () =>
            new Promise<boolean>((resolve) => {
              getProject(project.id, {
                callback: async (p) => {
                  resolve(!!p?.customerLetterKey);
                },
              });
            })
        );
      }

      if (params.contactId) {
        await createProjectContact({ projectId: project.id, userId: params.contactId });
      }
      onCreateProjectCallback();
    });
  };

  const openProjects = projects.filter((p) => p.status !== PROJECT_STATUSES.archived);
  const archivedProjects = projects.filter((p) => p.status === PROJECT_STATUSES.archived);

  return (
    <div className="Projects">
      <SectionHeader
        breadcrumbNameMap={breadcrumbNameMap}
        isAdmin={isAdmin}
        isCustomerService={isCustomerService}
        isShipper={isShipper}
      />

      <Collapse
        items={[
          {
            title: (
              <div className="Projects__ActiveTitle">
                Open projects
                {isAdmin && <PlusCircleOutlined onClick={handleCreateProject} data-testId="project-add-project-plus" />}
              </div>
            ) as ReactNode,
            content: (
              <ProjectsList
                loading={projectsLoading || metricsLoading}
                projects={openProjects}
                metrics={metrics}
                type={PROJECT_STATUSES.open}
              />
            ),
            extra: openProjects.length,
            key: 'open',
          },
          {
            title: 'Archived projects',
            content: (
              <ProjectsList
                loading={projectsLoading || metricsLoading}
                projects={archivedProjects}
                metrics={metrics}
                type={PROJECT_STATUSES.archived}
              />
            ),
            extra: archivedProjects.length,
            key: 'archived',
          },
        ]}
        initiallyCollapsed={false}
      />

      {isAdmin && createProjectModalVisible && (
        <CreateProjectModal
          visible
          onOk={onCreateProject}
          onCancel={closeCreateProjectModal}
          loading={projectsLoading || metricsLoading}
        />
      )}
    </div>
  );
};

export default Projects;
