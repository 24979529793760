import React, { FC, useEffect } from 'react';

import { Redirect, Switch, useRouteMatch, useParams } from 'react-router-dom';

import { SectionHeader, ProtectedRoute, ProjectNavigation, Spinner } from 'components/index';
import { DISTRIBUTION_MODEL_SOURCES, PROJECT_STATUSES, USER_ROLES } from 'constants/index';
import { ROUTES } from 'constants/routes';
import { useAuth } from 'context/auth';
import { IProject, useProjects } from 'context/projects';

import ArchivedProjectBanner from 'components/ArchivedProjectBanner';
import { Alert } from 'antd';
import { useCustomerSupportValues } from 'context/customerSupportValue';
import ProjectDetails from './ProjectDetails';
import ProjectDevices from './ProjectDevices';
import ProjectShipments from './ProjectShipments';
import UnassignedDevices from './UnassignedDevices';
import CustomerSupport from './CustomerSupport';

const getProjectName = (project: IProject | null): string => {
  if (!project) {
    return '';
  }

  if (project.status === PROJECT_STATUSES.archived) {
    return `${project.name} (archived)`;
  }

  return project.name;
};

const getDisabledMessageForProject = (project: IProject): { disabledMessage: string; disabledDescription: string } => {
  const { allowOrdering } = project;
  const { allowFulfillment } = project;
  const orderingAndFulfillmentDisabled = !allowFulfillment && !allowOrdering;
  let disabledMessage;
  let disabledDescription;
  if (orderingAndFulfillmentDisabled) {
    disabledMessage = 'Ordering and Fulfillment Disabled';
    disabledDescription =
      'Placing of orders and order fulfillment is disabled.  These must be enabled to place and fulfill orders.';
  } else if (!allowOrdering) {
    disabledMessage = 'Ordering Disabled';
    disabledDescription = 'Placing of orders is disabled.  This must be enabled to place orders.';
  } else {
    disabledMessage = 'Fulfillment Disabled';
    disabledDescription = 'Fulfillment of orders is disabled.  This must be enabled to fulfill orders.';
  }

  return { disabledMessage, disabledDescription };
};

const Project: FC = () => {
  const { projectId }: any = useParams();
  const { project, getProject, resetProject, loading } = useProjects();
  const { resetCustomerSupportValueState } = useCustomerSupportValues();
  const { path } = useRouteMatch();

  const { profile } = useAuth();

  const isInternalAdmin = (profile?.role || '') === USER_ROLES.internalAdmin;
  const isInternalShipper = (profile?.role || '') === USER_ROLES.internalShipper;
  const isCustomerService = (profile?.role || '') === USER_ROLES.internalCustomerService;
  const isShipper = (profile?.role || '') === USER_ROLES.internalShipper;

  useEffect(() => {
    const getProjectOptions =
      isInternalAdmin || isCustomerService
        ? { hydration: ['deviceShippedEmailTemplate', 'deviceShippedSmsTemplate', 'customerSupportValue'] }
        : {};

    getProject(projectId, getProjectOptions);

    return () => {
      resetProject();
      resetCustomerSupportValueState();
    };
  }, []);

  const breadcrumbNameMap = {
    [ROUTES.projects]: 'Projects',
    [`${ROUTES.projects}/${projectId}`]: getProjectName(project),
  };

  if (!project) {
    return null;
  }

  const { disabledMessage, disabledDescription } = getDisabledMessageForProject(project);

  return (
    <div className="Project">
      <Spinner spinning={loading}>
        <SectionHeader
          breadcrumbNameMap={breadcrumbNameMap}
          isAdmin={isInternalAdmin}
          isCustomerService={isCustomerService}
          isShipper={isShipper}
        />

        <ProjectNavigation distributionModel={project.distributionModel} />

        {project.status === PROJECT_STATUSES.archived && <ArchivedProjectBanner />}
        {(!project.allowOrdering || !project.allowFulfillment) && (
          <Alert message={disabledMessage} description={disabledDescription} type="warning" showIcon closable />
        )}

        <Switch>
          <ProtectedRoute path={`${path}/shipments`}>
            <ProjectShipments project={project} />
          </ProtectedRoute>

          {!isInternalShipper && (
            <ProtectedRoute path={`${path}/devices`}>
              <ProjectDevices />
            </ProtectedRoute>
          )}

          {project.distributionModel === DISTRIBUTION_MODEL_SOURCES.atCustomerSite && (
            <ProtectedRoute path={`${path}/unassigned-devices`}>
              <UnassignedDevices />
            </ProtectedRoute>
          )}

          {(isInternalAdmin || isCustomerService) && (
            <ProtectedRoute path={`${path}/details`}>
              <ProjectDetails project={project} />
            </ProtectedRoute>
          )}

          {(isInternalAdmin || isCustomerService) && (
            <ProtectedRoute path={`${path}/customer-support`}>
              <CustomerSupport project={project} />
            </ProtectedRoute>
          )}

          <Redirect to={`${path}/shipments`} />
        </Switch>
      </Spinner>
    </div>
  );
};

export default Project;
