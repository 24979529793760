import React, { FC, useEffect } from 'react';

import { Select, Button, Typography, Row, Col, Space } from 'antd';

import { PlusCircleOutlined } from 'components/icons';
import { useCustomers } from 'context/customers';
import useCustomerModal from 'hooks/useCustomerModal';

const { Option } = Select;

const { Text } = Typography;

interface ICustomerSelectField {
  onChange: (customerId: any) => void;
  value: string | null;
}

const CustomerSelectField: FC<ICustomerSelectField> = ({ onChange, value }) => {
  const { customers, getCustomers, loading } = useCustomers();
  const { Modal: CustomerModal, onCreateCustomer, customerModalPopulated } = useCustomerModal();

  useEffect(() => {
    getCustomers();
  }, []);

  useEffect(() => {
    if (customerModalPopulated) {
      getCustomers();
    }
  }, [customerModalPopulated]);

  const handleChange = (customer: any) => {
    onChange(customer);
  };

  return (
    <div className="SelectField">
      <Space direction="vertical">
        <Text strong>Customer</Text>

        <Row>
          <Col span={20}>
            <Select
              showSearch
              placeholder="Type or select customer"
              optionFilterProp="children"
              onChange={handleChange}
              loading={loading}
              value={value ?? undefined}
              data-testId="project-customer"
            >
              {customers.map((customer) => (
                <Option key={customer.id} value={customer.id}>
                  {customer.name}
                </Option>
              ))}
            </Select>
          </Col>

          <Col span={4}>
            <Button type="link" onClick={onCreateCustomer}>
              <PlusCircleOutlined />
            </Button>
          </Col>
        </Row>
      </Space>

      {CustomerModal}
    </div>
  );
};

export default CustomerSelectField;
