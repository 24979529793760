import React, { createContext, useReducer } from 'react';
import * as labOrdersApi from 'api/labOrders';
import { loadAllPages, makeReducer } from 'utils/functions';
import { serializeForDisplay } from 'utils/issues';
import Notification from 'utils/notification';
import { IDevice } from './devices';
import { IPatient } from './patients';
import { IProject } from './projects';
import { Customer } from './customers';
import { Laboratory } from './laboratories';
import { Analyte } from './analytes';

export interface ILabOrder {
  id: string;
  deviceId: string;
  device: IDevice | null;
  patientId: string;
  patient: IPatient | null;
  projectId: string;
  project: IProject | null;
  customerId: string;
  customer: Customer | null;
  laboratoryId: string;
  laboratory: Laboratory | null;
  doNotAct: boolean;
  labStatus: string;
  labOrderId: string;
  analyteIds: string[];
  analytes: Analyte[];
  laboratoryAdapterKey: string;
  order: Record<string, any>;
  orderHistory: Record<string, any>[];
}

type State = {
  labOrders: ILabOrder[];
  loading: boolean;
  error: string;
};

type Context = State & {
  getLabOrders: (deviceId: string, callback?: any) => Promise<void>;
  resetLabOrders: () => void;
};

const initialState: State = {
  labOrders: [],
  loading: false,
  error: '',
};

const LabOrdersContext = createContext<Context>({
  ...initialState,
  getLabOrders: async () => {},
  resetLabOrders: () => {},
});

const LabOrdersProvider = (props: any) => {
  const [state, setState] = useReducer(makeReducer<State>(), initialState);

  const getLabOrders = async (deviceId: string, callback?: any): Promise<void> => {
    try {
      setState({ loading: true });

      type PayloadType = Parameters<typeof labOrdersApi.getLabOrders>[0];
      const labOrders = await loadAllPages<ILabOrder, PayloadType>(
        labOrdersApi.getLabOrders,
        { deviceIds: deviceId },
        { pageLength: 50, sortBy: 'createdAt', isDescending: true }
      );

      setState({
        labOrders,
        loading: false,
      });

      if (callback) {
        await callback(labOrders);
      }
    } catch (e: any) {
      const errorMessage = serializeForDisplay(e?.response?.data?.issues) || e.message;
      setState({
        loading: false,
        error: errorMessage,
      });

      Notification({
        type: 'error',
        message: 'Failed to get device events',
        description: errorMessage,
      });
    }
  };

  const resetLabOrders = (): void => {
    setState({ ...initialState });
  };

  return (
    <LabOrdersContext.Provider
      value={{
        labOrders: state.labOrders,
        loading: state.loading,
        error: state.error,
        getLabOrders,
        resetLabOrders,
      }}
      {...props}
    />
  );
};

const useLabOrders = (): Context => React.useContext(LabOrdersContext);

export { LabOrdersProvider, useLabOrders };
