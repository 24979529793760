import { httpClient } from 'services/index';
import { PagingRequest } from 'utils/types';

export const getUploads = (payload: Record<string, any>, paging: PagingRequest): Promise<any> =>
  httpClient.get('/uploads', { params: { ...payload, ...paging } }).then((_) => _.data);

export const getUpload = (id: string): Promise<any> => httpClient.get(`/uploads/${id}`).then((_) => _.data.results);

export interface ICreateUpload {
  parentId: string;
  parentType: string;
  fileName: string;
  fileType: string;
  locale?: string;
  tag?: string | null;
  textConfig?: Record<string, any>[] | null;
}
export const createUpload = (data: ICreateUpload): Promise<any> =>
  httpClient.post('/uploads', data).then((_) => _.data.results);

export interface IUpdateUpload {
  locale?: string | null;
  tag?: string | null;
  textConfig?: Record<string, any>[] | null;
}

export const updateUpload = (id: string, data: IUpdateUpload): Promise<any> =>
  httpClient.patch(`/uploads/${id}`, data).then((_) => _.data.results);

export const deleteUpload = (id: string): Promise<any> =>
  httpClient.delete(`/uploads/${id}`).then((_) => _.data.results);
