import React, { FC } from 'react';

import { EditOutlined } from 'components/icons';

interface IProjectInformationItem {
  itemName: string;
  onDelete?: () => void;
  onEdit: () => void;
}

const ProjectInformationItem: FC<IProjectInformationItem> = ({ itemName, onEdit }) => (
  <div className="ProjectInformationItem">
    <p>
      {itemName} <EditOutlined className="EditAction" onClick={onEdit} />
    </p>
  </div>
);

export default ProjectInformationItem;
