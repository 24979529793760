import React, { FC, useEffect } from 'react';

import { Form, FormInstance, Input, InputNumber, Modal } from 'antd';

import { IdPoolIdentifier } from 'context/idPoolIdentifiers';

interface Fields {
  value: string;
  customOrder: string;
}

interface IdPoolIdentifierModalProps {
  form: FormInstance<Fields>;
  idPoolIdentifier: IdPoolIdentifier | null;
  visible: boolean;
  isSubmitting: boolean;
  onOk: (data: { value: string; customOrder?: number }) => void;
  onCancel: () => void;
}

const IdPoolIdentifierModal: FC<IdPoolIdentifierModalProps> = ({
  form,
  idPoolIdentifier,
  visible,
  isSubmitting,
  onOk,
  onCancel,
}) => {
  useEffect(() => {
    if (visible) {
      if (!idPoolIdentifier) {
        form.resetFields();
      }
    }
  }, [visible]);

  const handleFormSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        const customOrder = parseInt(values.customOrder, 10);
        onOk({
          value: (values.value || '').trim(),
          ...(Number.isNaN(customOrder) ? {} : { customOrder }),
        });
      })
      .catch((info) => {
        console.log('Validation failed:', info);
      });
  };

  const getTitle = (): string => {
    if (idPoolIdentifier) {
      return 'Edit Id Pool Identifier';
    }

    return 'Create Id Pool Identifier';
  };

  return (
    <Modal
      wrapClassName="Modal AnalyteModal"
      visible={visible}
      title={getTitle()}
      okText="Submit"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={handleFormSubmit}
      confirmLoading={isSubmitting}
      centered
      width="360px"
    >
      <Form
        className="Form AnalyteModal__Form"
        form={form}
        layout="vertical"
        name="IdPoolIdentifierModalForm"
        hideRequiredMark
      >
        <Form.Item
          name="value"
          label="Identifier value"
          rules={[{ required: true, message: 'Please enter the identifier value!' }]}
        >
          <Input placeholder="Enter a value" />
        </Form.Item>

        <Form.Item name="customOrder" label="Custom order">
          <InputNumber placeholder="Enter a value" min={0} style={{ width: '100%' }} stringMode />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default IdPoolIdentifierModal;
