import React, { FC, useEffect, useState } from 'react';

import { Modal, Form, DatePicker, Select, Input } from 'antd';
import moment from 'moment';

import DeviceLotDropdown from 'components/DeviceLotDropdown';
import { DEVICE_STATUS } from 'constants/index';
import { useDeviceLots } from 'context/deviceLots';
import { IDevice } from 'context/devices';

interface Props {
  device: IDevice | null;
  open?: boolean;
  loading: boolean;
  onClose: () => void;
  onSubmit: (deviceId: string, values: Record<string, string | null>) => Promise<void>;
}

const EditDeviceModal: FC<Props> = ({ device, open, loading, onClose, onSubmit }) => {
  const [form] = Form.useForm();

  const { deviceLots, getDeviceLots, addDeviceLot, loading: deviceLotsLoading } = useDeviceLots();

  const [selectedDeviceId, setSelectedDeviceId] = useState<string>('');

  const [isExpiresAtInThePast, setIsExpiresAtInThePast] = useState<boolean>(false);

  useEffect(() => {
    getDeviceLots();
  }, []);

  const handleSubmit = (values: any) => {
    const deviceLotId = selectedDeviceId || null;
    const expiresAt = values.expiresAt ? moment(values.expiresAt).format('YYYY-MM-DD') : null;
    const status = values.status || null;
    const barcode = values.barcode || null;

    const payload = { deviceLotId, expiresAt, status, barcode };

    onSubmit(device?.id || '', payload);

    onClose();
  };

  const handleAddLot = async (key: string): Promise<void> => {
    await addDeviceLot(key);
  };

  const handleSelectLot = (deviceLotId: string): void => {
    setSelectedDeviceId(deviceLotId);
  };

  const onExpiresAtChange = (value: moment.Moment | null) => {
    const dayOnlyValue = value?.format('YYYY-MM-DD');
    const dayOnlyToday = moment(moment.now()).format('YYYY-MM-DD');
    setIsExpiresAtInThePast(moment(dayOnlyValue).isBefore(dayOnlyToday));
  };

  const onFormCancel = () => {
    setIsExpiresAtInThePast(false);
    onClose();
  };

  useEffect(() => {
    const d: any = device || {};

    form.setFieldsValue({
      status: d.status || '',
      expiresAt: d.expiresAt ? moment(d.expiresAt) : '',
      createdAt: d.createdAt ? moment(d.createdAt) : '',
      barcode: d.barcode || '',
    });

    setSelectedDeviceId(d.deviceLotId || '');
  }, [device]);

  return (
    <Modal
      wrapClassName="Modal Normal"
      centered
      width="500px"
      visible={open}
      title="Edit device"
      onCancel={onFormCancel}
      cancelText="Cancel"
      okText="Update"
      onOk={() => form.submit()}
      confirmLoading={loading}
      okButtonProps={{ disabled: loading || isExpiresAtInThePast }}
      cancelButtonProps={{ disabled: loading }}
    >
      <Form form={form} onFinish={handleSubmit} labelCol={{ span: 6 }} wrapperCol={{ span: 20 }} labelAlign="left">
        <Form.Item label="ID">{device?.id}</Form.Item>
        <Form.Item label="Lot">
          <DeviceLotDropdown
            lots={deviceLots}
            selectedId={selectedDeviceId}
            onAdd={handleAddLot}
            onSelect={handleSelectLot}
            loading={deviceLotsLoading}
            style={{ width: '100%' }}
          />
        </Form.Item>
        <Form.Item
          name="expiresAt"
          label="Expiry date"
          validateStatus={isExpiresAtInThePast ? 'error' : undefined}
          help={isExpiresAtInThePast ? 'Expiry date cannot be in the past.' : undefined}
        >
          <DatePicker
            placeholder="YYYY-MM-DD"
            format="YYYY-MM-DD"
            style={{ width: '100%' }}
            onChange={onExpiresAtChange}
          />
        </Form.Item>
        <Form.Item name="status" label="Status">
          <Select
            disabled={!!device?.trackingNumberToLab}
            style={{ width: '100%' }}
            options={[
              { value: 'inStock', label: DEVICE_STATUS.inStock },
              { value: 'readyToShip', label: DEVICE_STATUS.readyToShip },
              { value: 'awaitingPickup', label: DEVICE_STATUS.awaitingPickup },
            ]}
          />
        </Form.Item>
        <Form.Item name="barcode" label="Barcode">
          <Input style={{ width: '100%' }} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditDeviceModal;
