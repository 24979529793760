import React, { FC } from 'react';

interface Props {
  title: string;
  currentValue: string;
  options: { value: string; label: string }[];
}

/**
 * This component is like a SelectableValue, but it is not editable
 */
export const UnselectableValue: FC<Props> = (props) => (
  <div className="ProjectInformation__InfoSection">
    <h3>{props.title}</h3>
    <p>{props.options.find((option) => option.value === props.currentValue)?.label ?? props.currentValue} </p>
  </div>
);

export default UnselectableValue;
