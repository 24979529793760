import React, { FC } from 'react';

import { useHistory, useRouteMatch, Switch, Route } from 'react-router-dom';

import BigLogoImg from 'assets/big_logo.png';
import { Footer } from 'components/index';
import { ROUTES } from 'constants/routes';
import { useAuth } from 'context/auth';

import ConfirmPasswordForm from './ConfirmPasswordForm';
import ForgotPasswordForm from './ForgotPasswordForm';

const ForgotPassword: FC = () => {
  const history = useHistory();
  const { path } = useRouteMatch();
  const { forgotPassword, confirmPassword, loading } = useAuth();

  const navigateToLogin = () => {
    history.push(ROUTES.login);
  };

  const handleForgotPassword = (email: string): void => {
    forgotPassword(email);
  };

  const handleConfirmPassword = (email: string, key: string, password: string): void => {
    confirmPassword(email, key, password, navigateToLogin);
  };

  return (
    <div className="ForgotPassword">
      <div className="ForgotPassword__Logo">
        <img src={BigLogoImg} alt="big-logo" />
      </div>

      <Switch>
        <Route exact path={path}>
          <ForgotPasswordForm onSubmit={handleForgotPassword} loading={loading} />
        </Route>

        <Route path={`${path}${ROUTES.confirmPassword}`}>
          <ConfirmPasswordForm onSubmit={handleConfirmPassword} loading={loading} />
        </Route>
      </Switch>
      <Footer />
    </div>
  );
};

export default ForgotPassword;
