import React, { FC, useState, createContext, useRef } from 'react';
import { loadAllPages, normalizePagingResponse } from 'utils/functions';
import { makeCancelTokenSource, CancelTokenSource } from 'services/index';

import { getErrorMessage } from 'utils/issues';
import Notification from 'utils/notification';
import { PagingInfo } from 'utils/types';
import * as api from '../api/idPools';
import { Customer } from './customers';

export type IdPool = {
  id: string;
  customerId: string;
  customer?: Customer;
  name: string;
  createdAt: string;
  updatedAt: string | null;
};

type Options = {
  hydrate?: 'customer'[];
};

export type GetIdPoolsParams = {
  page?: number;
  pageLength?: number;
  includeTotalCount?: boolean;
  sortBy?: string;
  sortOrder?: 'asc' | 'desc';
  nameLike?: string;
  customerIds?: string[];
};

type GetIdPoolsFunction = (
  params?: GetIdPoolsParams,
  config?: Options,
  callback?: (idPools: IdPool[] | null, paging?: PagingInfo) => Promise<void>
) => Promise<void>;

type GetIdPoolFunction = (
  id: string,
  config?: Options,
  callback?: (idPool: IdPool | null) => Promise<void>
) => Promise<void>;

type CreateIdPoolFunction = (
  params: api.CreateIdPoolPayload,
  config?: Options,
  callback?: (idPool: IdPool | null) => Promise<void>
) => Promise<void>;

type DeleteIdPoolFunction = (
  id: string,
  config?: Options,
  callback?: (idPool: IdPool | null) => Promise<void>
) => Promise<void>;

type UpdateIdPoolFunction = (
  id: string,
  data: api.UpdateIdPoolPayload,
  config?: Options,
  callback?: (idPool: IdPool | null) => Promise<void>
) => Promise<void>;

type State = {
  idPools: IdPool[];
  idPool: IdPool | null;
  loading: boolean;
  error: string | null;
};

type Context = State & {
  getIdPools: GetIdPoolsFunction;
  getIdPool: GetIdPoolFunction;
  createIdPool: CreateIdPoolFunction;
  updateIdPool: UpdateIdPoolFunction;
  deleteIdPool: DeleteIdPoolFunction;
  resetIdPools: () => void;
};

const getInitialState = (): State => ({
  idPools: [],
  idPool: null,
  loading: false,
  error: null,
});

const IdPoolsContext = createContext<Context>({
  ...getInitialState(),
  getIdPools: async () => {},
  getIdPool: async () => {},
  createIdPool: async () => {},
  updateIdPool: async () => {},
  deleteIdPool: async () => {},
  resetIdPools: () => {},
});

const IdPoolsProvider: FC<any> = (props) => {
  const [state, setState] = useState<State>(getInitialState);
  const cancelTokenSource = useRef<CancelTokenSource | null>(null);

  const getIdPools: GetIdPoolsFunction = async (params, config, callback) => {
    setState((s) => ({
      ...s,
      loading: true,
      error: null,
    }));

    const page = params?.page || 1;
    const pageLength = params?.pageLength || 20;
    const includeTotalCount =
      params && typeof params.includeTotalCount === 'boolean' ? params.includeTotalCount : false;
    const sortBy = params?.sortBy || 'createdAt';
    const isDescending = params && params.sortOrder ? params.sortOrder === 'desc' : false;

    const query: Record<string, string> = {};

    if (params) {
      if (typeof params.nameLike === 'string') {
        query.nameLike = params.nameLike;
      }

      if (Array.isArray(params.customerIds)) {
        query.customerIds = params.customerIds.join(',');
      }
    }

    try {
      let paging: PagingInfo;
      let idPools: IdPool[];

      cancelTokenSource.current = makeCancelTokenSource();

      const options: api.IdPoolRequestOptions = {
        hydrate: config?.hydrate || [],
        cancelToken: cancelTokenSource.current.token,
      };

      if (pageLength === -1) {
        // Load all data
        type PayloadType = Parameters<typeof api.getIdPools>[0];
        idPools = await loadAllPages<IdPool, PayloadType>(
          api.getIdPools,
          { query, options },
          {
            pageLength: 100,
            sortBy,
            isDescending,
          },
          { cancelToken: cancelTokenSource.current.token }
        );

        paging = {
          page: 1,
          pageLength: idPools.length,
          totalCount: idPools.length,
          sortBy,
          sortOrder: isDescending ? 'desc' : 'asc',
        };
      } else {
        const data = await api.getIdPools(
          { query, options },
          {
            page,
            pageLength,
            sortBy,
            isDescending,
            includeTotalCount,
          }
        );

        if (data.isCancelledByClient) {
          return;
        }

        idPools = data.results;

        paging = normalizePagingResponse(data.paging);
      }

      setState((s) => ({
        ...s,
        idPools,
      }));

      if (callback) {
        await callback(idPools, paging);
      }
    } catch (e) {
      const errorMessage = getErrorMessage(e);

      setState((s) => ({
        ...s,
        error: errorMessage,
      }));

      Notification({
        type: 'error',
        message: 'Failed to load Id Pools',
        description: errorMessage,
      });

      if (callback) {
        await callback(null);
      }
    } finally {
      setState((s) => ({
        ...s,
        loading: false,
      }));
    }
  };

  const getIdPool: GetIdPoolFunction = async (id, config, callback) => {
    const existingIdPool = state.idPools.find((idPool) => idPool.id === id);

    if (existingIdPool) {
      setState((s) => ({ ...s, idPool: existingIdPool }));
      if (callback) {
        await callback(existingIdPool);
      }
      return;
    }

    setState((s) => ({
      ...s,
      loading: true,
      error: null,
    }));

    try {
      cancelTokenSource.current = makeCancelTokenSource();

      const options: api.IdPoolRequestOptions = {
        hydrate: config?.hydrate || [],
        cancelToken: cancelTokenSource.current.token,
      };

      const idPool = await api.getIdPool(id, options);

      if (idPool.isCancelledByClient) {
        return;
      }

      setState((s) => ({
        ...s,
        idPool,
      }));

      if (callback) {
        await callback(idPool);
      }
    } catch (e) {
      const errorMessage = getErrorMessage(e);

      setState((s) => ({
        ...s,
        error: errorMessage,
      }));

      Notification({
        type: 'error',
        message: 'Failed to get Id Pool details',
        description: errorMessage,
      });

      if (callback) {
        await callback(null);
      }
    } finally {
      setState((s) => ({
        ...s,
        loading: false,
      }));
    }
  };

  const createIdPool: CreateIdPoolFunction = async (data, config, callback) => {
    setState((s) => ({
      ...s,
      loading: true,
      error: null,
    }));

    try {
      cancelTokenSource.current = makeCancelTokenSource();

      const options: api.IdPoolRequestOptions = {
        hydrate: config?.hydrate || [],
        cancelToken: cancelTokenSource.current.token,
      };

      const idPool = await api.createIdPool(data, options);

      if (idPool.isCancelledByClient) {
        return;
      }

      setState((s) => ({
        ...s,
        idPools: [...s.idPools, idPool],
      }));

      if (callback) {
        await callback(idPool);
      }
    } catch (e) {
      const errorMessage = getErrorMessage(e);

      setState((s) => ({
        ...s,
        error: errorMessage,
      }));

      Notification({
        type: 'error',
        message: 'Failed to create Id Pool',
        description: errorMessage,
      });

      if (callback) {
        await callback(null);
      }
    } finally {
      setState((s) => ({
        ...s,
        loading: false,
      }));
    }
  };

  const updateIdPool: UpdateIdPoolFunction = async (id, data, config, callback) => {
    setState((s) => ({
      ...s,
      loading: true,
      error: null,
    }));

    try {
      cancelTokenSource.current = makeCancelTokenSource();

      const options: api.IdPoolRequestOptions = {
        hydrate: config?.hydrate || [],
        cancelToken: cancelTokenSource.current.token,
      };

      const updatedIdPool = await api.updateIdPool(id, data, options);

      if (updatedIdPool.isCancelledByClient) {
        return;
      }

      setState((s) => ({
        ...s,
        idPools: s.idPools.map((idPool) => (idPool.id === id ? updatedIdPool : idPool)),
        idPool: s.idPool && s.idPool.id === id ? updatedIdPool : s.idPool,
      }));

      if (callback) {
        await callback(updatedIdPool);
      }
    } catch (e) {
      const errorMessage = getErrorMessage(e);

      setState((s) => ({
        ...s,
        error: errorMessage,
      }));

      Notification({
        type: 'error',
        message: 'Failed to update Id Pool',
        description: errorMessage,
      });

      if (callback) {
        await callback(null);
      }
    } finally {
      setState((s) => ({
        ...s,
        loading: false,
      }));
    }
  };

  const deleteIdPool: DeleteIdPoolFunction = async (id, config, callback) => {
    setState((s) => ({
      ...s,
      loading: true,
      error: null,
    }));

    try {
      cancelTokenSource.current = makeCancelTokenSource();

      const options: api.IdPoolRequestOptions = {
        hydrate: config?.hydrate || [],
        cancelToken: cancelTokenSource.current.token,
      };

      const deletedIdPool = await api.deleteIdPool(id, options);

      if (deletedIdPool.isCancelledByClient) {
        return;
      }

      setState((s) => ({
        ...s,
        idPools: s.idPools.filter((idPool) => idPool.id !== id),
        idPool: s.idPool && s.idPool.id === id ? null : s.idPool,
      }));

      if (callback) {
        await callback(deletedIdPool);
      }
    } catch (e) {
      const errorMessage = getErrorMessage(e);

      setState((s) => ({
        ...s,
        error: errorMessage,
      }));

      Notification({
        type: 'error',
        message: 'Failed to delete Id Pool',
        description: errorMessage,
      });

      if (callback) {
        await callback(null);
      }
    } finally {
      setState((s) => ({
        ...s,
        loading: false,
      }));
    }
  };

  const resetIdPools = (): void => {
    if (cancelTokenSource.current) {
      cancelTokenSource.current.cancel();
      cancelTokenSource.current = null;
    }
    setState(getInitialState);
  };

  return (
    <IdPoolsContext.Provider
      value={{
        idPools: state.idPools,
        idPool: state.idPool,
        loading: state.loading,
        error: state.error,
        getIdPools,
        getIdPool,
        createIdPool,
        updateIdPool,
        deleteIdPool,
        resetIdPools,
      }}
      {...props}
    />
  );
};

const useIdPools = (): Context => React.useContext(IdPoolsContext);

export { IdPoolsProvider, useIdPools };
