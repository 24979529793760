import React, { useReducer } from 'react';

import { makeReducer } from 'utils/functions';
import { CustomAttributeDefinitionProject, useProjects } from 'context/projects';
import CustomAttributeModal from 'components/CustomAttributeModal';
import { ICustomAttributeDefinition, useCustomAttributeDefinitions } from 'context/customAttributeDefinitions';

type State = {
  customAttributeDefinition: CustomAttributeDefinitionProject | ICustomAttributeDefinition | null;
  customerId: string | null;
  visible: boolean;
};

const useCustomAttributeModal = (useOrderTemplates = false) => {
  const { project, updateProject } = useProjects();
  const { updateCustomAttributeDefinition, createCustomAttributeDefinition } = useCustomAttributeDefinitions();

  const [state, setState] = useReducer(makeReducer<State>(), {
    customAttributeDefinition: null,
    customerId: null,
    visible: false,
  });

  const onCreateCustomAttribute = (customerId?: string): void => {
    setState({ customAttributeDefinition: null, visible: true, customerId });
  };

  const onEditCustomAttribute = (customAttributeDefinition: CustomAttributeDefinitionProject): void => {
    setState({ customAttributeDefinition, visible: true });
  };

  const onDeleteCustomAttribute = (attributeName: string): void => {
    if (project?.customAttributeDefinitions) {
      const definitions = project.customAttributeDefinitions.filter((record) => record.name !== attributeName);
      updateProject(project.id, { customAttributeDefinitions: definitions });
    }
  };

  const createCustomAttributeFcn = (customAttributeDefinition: CustomAttributeDefinitionProject): void => {
    if (useOrderTemplates) {
      const attribute = customAttributeDefinition as ICustomAttributeDefinition;
      attribute.customerId = state.customerId!;
      createCustomAttributeDefinition(customAttributeDefinition);
    } else if (project) {
      let definitions = project.customAttributeDefinitions ?? [];
      definitions = definitions.concat([customAttributeDefinition]);
      updateProject(project.id, { customAttributeDefinitions: definitions });
    }
  };

  const editCustomAttributeFcn = (customAttributeDefinition: CustomAttributeDefinitionProject, id?: string): void => {
    if (useOrderTemplates) {
      updateCustomAttributeDefinition(id!, customAttributeDefinition);
    } else if (project) {
      const definitions = project.customAttributeDefinitions ?? [];
      const index = definitions.findIndex((element) => element.name === customAttributeDefinition.name);
      definitions[index] = customAttributeDefinition;
      updateProject(project.id, { customAttributeDefinitions: definitions });
    }
  };

  const resetModal = (): void => {
    setState({ customAttributeDefinition: null, visible: false });
  };

  const customAttributeModal = (
    <CustomAttributeModal
      visible={state.visible}
      useOrderTemplates={useOrderTemplates}
      customAttributeDefinition={state.customAttributeDefinition}
      onClose={resetModal}
      createCustomAttributeFcn={createCustomAttributeFcn}
      editCustomAttributeFcn={editCustomAttributeFcn}
    />
  );

  return {
    customAttributeModal,
    onCreateCustomAttribute,
    onEditCustomAttribute,
    onDeleteCustomAttribute,
    resetModal,
  };
};

export default useCustomAttributeModal;
