import React, { FC, useEffect, useState } from 'react';

import { Button, Form, FormInstance, Input, Modal, Select, Tooltip, Upload } from 'antd';
import { IKitItem } from 'context/kitItems';
import { RcFile } from 'antd/lib/upload/interface';
import { LoadingOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import clsx from 'clsx';
import Spinner from 'components/Spinner';
import { KIT_ITEM_TYPES, KIT_ITEM_TYPES_LABELS } from 'constants/index';
import { UPLOAD_LOCALES } from 'constants/locales';
import FileManager from './FileManager';
import { UploadedFile, UploadEntry } from './types';
import KitItemSelector from './KitItemSelector';

interface IKitItemModal {
  form: FormInstance<{ existingItemId: string; description: string; kitItemType: string }>;
  projectId?: string;
  kitItem: IKitItem | null;
  kitItems: IKitItem[];
  visible: boolean;
  working: boolean;
  standalone: boolean;
  selectOnly: boolean;
  onOk: (newKitItemData: any, files: UploadEntry[], kitItemId: string) => void;
  onCancel: () => void;
}

const KitItemModal: FC<IKitItemModal> = ({
  form,
  projectId,
  kitItem,
  kitItems,
  visible,
  working,
  onOk,
  onCancel,
  standalone,
  selectOnly,
}) => {
  const [selectedValue, setSelectedValue] = useState('');
  const [isValidNewItem, setIsValidNewItem] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<UploadEntry[]>([]);
  const [showUploadButton, setShowUploadButton] = useState(false);
  const [showFileList, setShowFileList] = useState(false);
  const [kitItemType, setKitItemType] = useState('');

  useEffect(() => {
    if (!kitItem) {
      form.resetFields();
      setSelectedFiles([]);
      setShowUploadButton(false);
      setShowFileList(false);
    } else {
      const uploads = kitItem.uploads ?? [];

      // Select those uploads that are "ready" - files are fully processed.
      const readyUploads = uploads.filter((up) => up.status === 'ready');

      setSelectedFiles(
        readyUploads.map((item) => ({
          file: { uid: item.id, name: item.fileName } as UploadedFile,
          originalUpload: item,
          locale: item.locale ?? 'en',
          tag: item.tag,
          status: 'uploaded',
          textConfig: item.textConfig,
        }))
      );

      const supportsUploads =
        kitItem.kitItemType === KIT_ITEM_TYPES.printable || kitItem.kitItemType === KIT_ITEM_TYPES.packingInstructions;
      setShowUploadButton(supportsUploads);

      setKitItemType(kitItem.kitItemType ?? '');

      // Show file list regardless of kitItemType as long as
      // there are file to show.
      setShowFileList(readyUploads.length > 0);
    }
  }, [kitItem, visible]);

  const handleFormSubmit = () => {
    if (selectedValue !== '') {
      onOk(null, [], selectedValue);
      return;
    }

    form
      .validateFields()
      .then(
        (values: {
          key?: string;
          partNumber?: string;
          description?: string;
          lotNumber?: string;
          kitItemType?: string;
        }) => {
          onOk(
            {
              key: (values.key || '').trim(),
              partNumber: (values.partNumber || '').trim(),
              description: (values.description || '').trim(),
              lotNumber: (values.lotNumber || '').trim(),
              kitItemType: values.kitItemType || null,
            },
            selectedFiles,
            ''
          );
        }
      )
      .catch((info: any) => {
        console.log('Validation failed:', info);
      });
  };

  const handleSelectChange = (value: string): void => {
    setSelectedValue(value);
    form.resetFields(['key', 'partNumber', 'description', 'lotNumber', 'kitItemType']);
  };

  const handleTextChange = (): void => {
    setSelectedValue('');
    form.setFieldsValue({ existingItemId: '' });

    const formValues = form.getFieldsValue();

    setKitItemType(formValues.kitItemType);

    const supportsUploads =
      formValues.kitItemType === KIT_ITEM_TYPES.printable ||
      formValues.kitItemType === KIT_ITEM_TYPES.packingInstructions;
    setShowUploadButton(supportsUploads);

    const hasDescription = typeof formValues.description === 'string' && form.getFieldsValue().description.length > 0;
    setIsValidNewItem(hasDescription);
  };

  const getButtonLabel = (): string => {
    if (projectId) {
      if (selectedValue !== '') {
        return 'Link to project';
      }
      return 'Create and link to project';
    }

    return 'Save';
  };

  const getTitle = (): string => {
    if (selectOnly) {
      return 'Select Kit Item';
    }

    if (kitItem) {
      return 'Edit Kit Item';
    }

    return 'Create Kit Item';
  };

  const onFileChange = (file: RcFile): void => {
    // Try to use the next available locale. If all else fails use "en" again.
    const usedLocales = selectedFiles.map((f) => f.locale);
    const allLocales = Object.keys(UPLOAD_LOCALES);
    const availableLocales = allLocales.filter((loc) => !usedLocales.includes(loc));
    const locale = availableLocales.shift() ?? 'en';

    setSelectedFiles((s) => [...s, { file, originalUpload: null, locale, tag: null, status: 'new', textConfig: null }]);

    setShowFileList(true);
  };

  const updateFile = (
    fileUid: string,
    locale: string,
    tag: string | null,
    textConfig: Record<string, any>[] | null
  ): void => {
    setSelectedFiles((s) =>
      s.map((entry) => {
        if (entry.file.uid === fileUid) {
          return {
            file: entry.file,
            originalUpload: entry.originalUpload,
            locale,
            tag,
            status: entry.status,
            textConfig,
          };
        }

        return entry;
      })
    );
  };

  const deleteFile = (fileUid: string): void => {
    const fileToDelete = selectedFiles.find((entry) => entry.file.uid === fileUid)!;

    // Deleting an new file that's not uploaded yet, so simply
    // delete it from the list.
    if (fileToDelete.status === 'new') {
      setSelectedFiles((s) => s.filter((entry) => entry.file.uid !== fileUid));
    } else if (fileToDelete.status === 'deleted') {
      // Un-delete an existing and previously "deleted" file
      setSelectedFiles((s) =>
        s.map((entry) => {
          if (entry.file.uid === fileUid) {
            return { ...entry, status: 'uploaded' };
          }

          return entry;
        })
      );
    } else {
      // Mark uploaded file as "deleted"
      setSelectedFiles((s) =>
        s.map((entry) => {
          if (entry.file.uid === fileUid) {
            return { ...entry, status: 'deleted' };
          }

          return entry;
        })
      );
    }
  };

  return (
    <Modal
      wrapClassName={clsx(['Modal', 'KitItemModal', { Loading: working }])}
      visible={visible}
      title={getTitle()}
      okText={getButtonLabel()}
      cancelText="Cancel"
      onCancel={onCancel}
      centered
      width={800}
      forceRender
      okButtonProps={{ disabled: selectedValue === '' && !isValidNewItem }}
      footer={[
        ...(showUploadButton
          ? [
              <Upload
                disabled={working}
                className="AddFile"
                showUploadList={false}
                accept=".pdf,application/pdf"
                beforeUpload={(file) => {
                  onFileChange(file);

                  // Return false to skip auto upload
                  return false;
                }}
              >
                <Button disabled={working}>Add file</Button>{' '}
                <Tooltip
                  title={
                    <ul>
                      <li>Only PDF files.</li>
                      <li>Only US Letter size (8.5x11&#34;).</li>
                      <li>Only one file per language.</li>
                      <li>Maximum file size is 25 MB.</li>
                    </ul>
                  }
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </Upload>,
            ]
          : []),
        <Button key="cancelButton" onClick={() => onCancel()}>
          Cancel
        </Button>,
        <Button
          key="submitButton"
          type="primary"
          onClick={() => handleFormSubmit()}
          disabled={working || (selectOnly && selectedValue === '')}
        >
          Save {working && <LoadingOutlined />}
        </Button>,
      ]}
    >
      <Spinner spinning={working}>
        <div className="KiteItemModalBody">
          <Form form={form} layout="vertical" hideRequiredMark style={{ width: '100%' }}>
            {!standalone && (
              <>
                {selectOnly ? (
                  <KitItemSelector
                    kitItems={kitItems}
                    selectedValue={selectedValue}
                    handleSelectChange={handleSelectChange}
                    visible={visible}
                  />
                ) : (
                  <Form.Item name="existingItemId" label="Select an existing kit item">
                    <KitItemSelector
                      kitItems={kitItems}
                      selectedValue={selectedValue}
                      handleSelectChange={handleSelectChange}
                      visible={visible}
                      showDefaultOption
                    />
                  </Form.Item>
                )}

                {!selectOnly && (
                  <div style={{ textAlign: 'center', margin: '10px 0' }}>
                    <h4>- OR -</h4>
                  </div>
                )}
              </>
            )}
            {!selectOnly && (
              <div>
                <Form.Item
                  name="key"
                  label="Key"
                  style={{ opacity: selectedValue !== '' ? 0.7 : 1 }}
                  rules={[{ required: true, message: 'Please provide a key' }]}
                >
                  <Input placeholder="Enter key" onChange={handleTextChange} />
                </Form.Item>

                <Form.Item name="partNumber" label="Part number" style={{ opacity: selectedValue !== '' ? 0.7 : 1 }}>
                  <Input placeholder="Enter part number" onChange={handleTextChange} />
                </Form.Item>

                <Form.Item name="lotNumber" label="Lot number" style={{ opacity: selectedValue !== '' ? 0.7 : 1 }}>
                  <Input placeholder="Enter lot number" onChange={handleTextChange} />
                </Form.Item>

                <Form.Item
                  name="description"
                  label="Description"
                  rules={[{ required: true, message: 'Please provide a description' }]}
                  style={{ opacity: selectedValue !== '' ? 0.7 : 1 }}
                >
                  <Input placeholder="Enter description" onChange={handleTextChange} />
                </Form.Item>

                <Form.Item name="kitItemType" label="Kit Item Type" style={{ opacity: selectedValue !== '' ? 0.7 : 1 }}>
                  <Select
                    options={[
                      // { value: '', label: 'None' },
                      ...Object.keys(KIT_ITEM_TYPES_LABELS).map((k) => ({
                        value: k,
                        label: KIT_ITEM_TYPES_LABELS[k as keyof typeof KIT_ITEM_TYPES_LABELS],
                      })),
                    ]}
                    onChange={handleTextChange} // enables the save button!
                    allowClear
                    placeholder="Optional Kit Item Type"
                  />
                </Form.Item>
              </div>
            )}
          </Form>

          {showFileList && (
            <FileManager
              files={selectedFiles}
              updateFile={updateFile}
              deleteFile={deleteFile}
              working={working}
              hideTextConfig={kitItemType === KIT_ITEM_TYPES.packingInstructions}
            />
          )}
        </div>
      </Spinner>
    </Modal>
  );
};

export default KitItemModal;
