import React, { FC, useState, createContext, useRef } from 'react';
import { CancelTokenSource } from 'services/index';
import { getErrorMessage } from 'utils/issues';
import Notification from 'utils/notification';
import { OrderTemplate, OrderTemplateCreate, OrderTemplateUpdate } from '@tassoinc/core-api-client';

import { loadAllPages } from 'utils/functions';
import { orderTemplatesApi } from 'api';
import { ValidationError } from 'utils/types';

export const NEW_OT_ID = 'new';

type Options = {
  hydrate?: never[]; // not in use yet, update when necessary
};

type GetOrderTemplateFunction = (
  id: string,
  config?: Options,
  callback?: (orderTemplate: OrderTemplate | null) => Promise<void> | void
) => Promise<void>;

type GetOrderTemplatesForProjectFunction = (
  projectId: string,
  config?: Options,
  callback?: (orderTemplate: OrderTemplate[] | null) => Promise<void> | void
) => Promise<void>;

type CreateOrderTemplateFunction = (
  body: OrderTemplateCreate,
  config?: Options,
  callback?: (ot: OrderTemplate | null) => Promise<void> | void
) => Promise<void>;

type UpdateOrderTemplateFunction = (
  id: string,
  body: OrderTemplateUpdate,
  config?: Options,
  callback?: (ot: OrderTemplate | null) => Promise<void> | void
) => Promise<void>;

type ResetOrderTemplatesFunction = () => void;

type State = {
  orderTemplates: OrderTemplate[];
  orderTemplate: OrderTemplate | null;
  loading: boolean;
  error: string | null;
};

type Context = State & {
  getOrderTemplate: GetOrderTemplateFunction;
  getOrderTemplatesForProject: GetOrderTemplatesForProjectFunction;
  resetOrderTemplates: ResetOrderTemplatesFunction;
  createOrderTemplate: CreateOrderTemplateFunction;
  updateOrderTemplate: UpdateOrderTemplateFunction;
};

const getInitialState = (): State => ({
  orderTemplates: [],
  orderTemplate: null,
  loading: false,
  error: null,
});

const OrderTemplatesContext = createContext<Context>({
  ...getInitialState(),
  getOrderTemplate: async () => {},
  getOrderTemplatesForProject: async () => {},
  resetOrderTemplates: () => {},
  createOrderTemplate: async () => {},
  updateOrderTemplate: async () => {},
});

const OrderTemplatesProvider: FC<any> = (props) => {
  const [state, setState] = useState<State>(getInitialState);
  const cancelTokenSource = useRef<CancelTokenSource | null>(null);

  const getOrderTemplate: GetOrderTemplateFunction = async (id, _config, callback) => {
    setState((s) => ({
      ...s,
      loading: true,
      error: null,
    }));

    try {
      const orderTemplate = await orderTemplatesApi.getById(id);

      if (orderTemplate) {
        setState((s) => ({
          ...s,
          orderTemplate,
        }));

        if (callback) {
          await callback(orderTemplate);
        }
      }
    } catch (e) {
      const errorMessage = getErrorMessage(e);

      setState((s) => ({
        ...s,
        error: errorMessage,
      }));

      Notification({
        type: 'error',
        message: 'Failed to get Order Template details',
        description: errorMessage,
      });

      if (callback) {
        await callback(null);
      }
    } finally {
      setState((s) => ({
        ...s,
        loading: false,
      }));
    }
  };

  const getOrderTemplatesForProject: GetOrderTemplatesForProjectFunction = async (id, _config, callback) => {
    setState((s) => ({
      ...s,
      loading: true,
      error: null,
    }));

    try {
      const orderTemplates = await loadAllPages<any, any>(
        orderTemplatesApi.get,
        { projectIds: [id] },
        {
          pageLength: 50,
          sortBy: 'createdAt',
          isDescending: false,
        }
      );

      if (orderTemplates) {
        setState((s) => ({
          ...s,
          orderTemplates,
        }));

        if (callback) {
          await callback(orderTemplates);
        }
      }
    } catch (e) {
      const errorMessage = getErrorMessage(e);

      setState((s) => ({
        ...s,
        error: errorMessage,
      }));

      Notification({
        type: 'error',
        message: 'Failed to get Order Template details',
        description: errorMessage,
      });

      if (callback) {
        await callback(null);
      }
    } finally {
      setState((s) => ({
        ...s,
        loading: false,
      }));
    }
  };

  const createOrderTemplate: CreateOrderTemplateFunction = async (body, _options, callback) => {
    setState((s) => ({
      ...s,
      loading: true,
      error: null,
    }));

    try {
      const result = await orderTemplatesApi.insertOrderTemplate(body);

      if (result) {
        // remove any previous version
        let updated = state.orderTemplates.filter(
          (ot) => ot.projectId === result.projectId && ot.templateId !== result.templateId
        );
        // once we have a good save, remove any new entries from the list... this may be retired once dry-run is added.
        if (result.id !== NEW_OT_ID) {
          updated = updated.filter((ot) => ot.id !== NEW_OT_ID);
        }

        updated.push(result);
        setState((s) => ({
          ...s,
          orderTemplates: updated,
        }));

        if (callback) {
          await callback(result);
        }
      }
    } catch (e) {
      // Return original input + returned validation errors to display inside the modal
      const validationErrors: ValidationError[] = (e as any).response?.data?.issues?.errors ?? [];

      if (validationErrors.length > 0 && callback) {
        const errors = validationErrors.map(({ message }) => message);

        await callback({ ...body, errors } as any);

        return;
      }

      const errorMessage = getErrorMessage(e);

      setState((s) => ({
        ...s,
        error: errorMessage,
      }));

      Notification({
        type: 'error',
        message: 'Failed to get Order Template details',
        description: errorMessage,
      });

      if (callback) {
        await callback(null);
      }
    } finally {
      setState((s) => ({
        ...s,
        loading: false,
      }));
    }
  };

  const updateOrderTemplate: UpdateOrderTemplateFunction = async (id, body, _options, callback) => {
    setState((s) => ({
      ...s,
      loading: true,
      error: null,
    }));

    try {
      const result = await orderTemplatesApi.updateOrderTemplate(id, body);

      setState((s) => ({
        ...s,
        orderTemplates: [
          // Remove previous version of the template
          ...s.orderTemplates.filter((ot) => ot.projectId === result.projectId && ot.templateId !== result.templateId),
          // and add the newly created version
          result,
        ],
        orderTemplate:
          s.orderTemplate &&
          s.orderTemplate.projectId === result.projectId &&
          s.orderTemplate.templateId === result.templateId
            ? result
            : s.orderTemplate,
      }));

      if (callback) {
        await callback(result);
      }
    } catch (e) {
      // Return original input + returned validation errors to display inside the modal
      const validationErrors: ValidationError[] = (e as any).response?.data?.issues?.errors ?? [];

      if (validationErrors.length > 0 && callback) {
        const errors = validationErrors.map(({ message }) => message);

        await callback({ ...body, errors } as any);

        return;
      }

      const errorMessage = getErrorMessage(e);

      setState((s) => ({
        ...s,
        error: errorMessage,
      }));

      Notification({
        type: 'error',
        message: 'Failed to update Order Template',
        description: errorMessage,
      });

      if (callback) {
        await callback(null);
      }
    } finally {
      setState((s) => ({
        ...s,
        loading: false,
      }));
    }
  };

  const resetOrderTemplates: ResetOrderTemplatesFunction = (): void => {
    if (cancelTokenSource.current) {
      cancelTokenSource.current.cancel();
      cancelTokenSource.current = null;
    }
    setState(getInitialState);
  };

  return (
    <OrderTemplatesContext.Provider
      value={{
        orderTemplates: state.orderTemplates,
        orderTemplate: state.orderTemplate,
        loading: state.loading,
        error: state.error,
        getOrderTemplate,
        getOrderTemplatesForProject,
        resetOrderTemplates,
        createOrderTemplate,
        updateOrderTemplate,
      }}
      {...props}
    />
  );
};

const useOrderTemplates = (): Context => React.useContext(OrderTemplatesContext);

export { OrderTemplatesProvider, useOrderTemplates };
