import { httpClient } from 'services/index';

export const downloadBarcodeDisposition = 'attachment;filename=barcode.pdf';
export const downloadBarcode = (deviceIds: string[], disposition?: string): Promise<any> =>
  httpClient.post('/downloads/barcode', { deviceIds, disposition }).then((_) => _.data);

export const downloadOrderFulfillmentSummary = (deviceIds: string[], disposition?: string): Promise<any> =>
  httpClient.post('/downloads/orderFulfillmentSummary', { deviceIds, disposition }).then((_) => _.data);

export const downloadCustomerLetters = (deviceIds: string[], disposition?: string): Promise<any> =>
  httpClient.post('/downloads/customerLetter', { deviceIds, disposition }).then((_) => _.data);

export const downloadShippingLabels = (deviceIds: string[], disposition?: string): Promise<any> =>
  httpClient.post('/downloads/shippingLabel', { deviceIds, disposition }).then((_) => _.data);

export const downloadShippingLabelsV2 = (shipmentIds: string[], disposition?: string): Promise<any> =>
  httpClient.post('/downloads/shippingLabelV2', { shipmentIds, disposition }).then((_) => _.data);

export const downloadActivationLabels = (deviceIds: string[], disposition?: string): Promise<any> =>
  httpClient.post('/downloads/activationLabel', { deviceIds, disposition }).then((_) => _.data);

export const downloadRequisitionForm = (patientIds: string[], disposition?: string): Promise<any> =>
  httpClient.post('/downloads/requisitionForm', { patientIds, disposition }).then((_) => _.data);

export const downloadShipmentReport = (date?: string, disposition?: string): Promise<any> =>
  httpClient.post('/downloads/shipmentReport', { date, disposition }).then((_) => _.data);

export const isDownloadReady = (url: string): Promise<boolean> =>
  httpClient
    .head(url, { ignoreAuthorization: true } as any)
    .then((_) => _.status === 200)
    .catch(() => false);
