import React, { FC, useEffect } from 'react';
import { Modal, Input, Form } from 'antd';
import { Site } from '@tassoinc/core-api-client';
import validator from 'validator';

export type SiteState = {
  site: Site;
};

interface ISiteModalProps {
  form: any;
  visible: boolean;
  site: Site | null;
  customerId: string;
  onOk: (site: Site) => void;
  onClose: () => void;
}

const SiteModal: FC<ISiteModalProps> = ({ form, visible, site, onOk, onClose }) => {
  const handleClose = (): void => {
    onClose();
  };

  const isCreateMode = site === null;

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values: Site) => {
        onOk({ ...values });
      })
      .catch((info: any) => {
        console.log('Validate Failed:', info);
      });
  };

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        id: site && 'id' in site ? site.id : '',
        name: site?.name || '',
        attentionName: site && site.address && 'attentionName' in site.address ? site.address.attentionName : '',
        address1: site && site.address && 'address1' in site.address ? site.address.address1 : '',
        address2: site && site.address && 'address2' in site.address ? site.address.address2 : '',
        city: site && site.address && 'city' in site.address ? site.address.city : '',
        district1: site && site.address && 'district1' in site.address ? site.address.district1 : '',
        postalCode: site && site.address && 'postalCode' in site.address ? site.address.postalCode : '',
        phoneNumber:
          site && site.address && 'phoneNumber' in site.address && site.address.phoneNumber
            ? site.address.phoneNumber
            : '',
      });
    }
  }, [visible]);

  return (
    <>
      <Modal
        visible={visible}
        closable={false}
        wrapClassName="Modal SiteModal"
        title={isCreateMode ? 'Create Site' : 'Edit Site'}
        centered
        okText="Save"
        cancelText="Cancel"
        onOk={handleSubmit}
        onCancel={handleClose}
        width={360}
      >
        <Form className="Form SiteModal__Form" form={form} layout="vertical" name="SiteModalForm" hideRequiredMark>
          <Form.Item name="id" hidden />

          <Form.Item name="name" label="Site Name" rules={[{ required: true, message: 'Please enter site name!' }]}>
            <Input placeholder="Enter site name" />
          </Form.Item>

          <Form.Item name="attentionName" label="Attention Name">
            <Input placeholder="Enter attention name" />
          </Form.Item>

          <Form.Item
            name="phoneNumber"
            label="Phone Number"
            rules={[
              {
                validator: (_, phoneNumber = '') =>
                  // Currently, we only support patient phone numbers which are in the United States, so we're checking that it's a valid US phone number and also begins with country code '1'
                  phoneNumber.length === 0 || (phoneNumber[0] === '1' && validator.isMobilePhone(phoneNumber, 'en-US'))
                    ? Promise.resolve()
                    : Promise.reject(new Error()),
                message: 'Please enter a valid US phone number!',
              },
            ]}
          >
            <Input placeholder="Enter phone number" />
          </Form.Item>

          <Form.Item
            name="address1"
            label="Address 1"
            rules={[{ required: true, message: 'Please enter address line 1!' }]}
          >
            <Input placeholder="Enter address 1" />
          </Form.Item>

          <Form.Item name="address2" label="Address 2">
            <Input placeholder="Enter address 2" />
          </Form.Item>

          <Form.Item name="city" label="City" rules={[{ required: true, message: 'Please enter city!' }]}>
            <Input placeholder="Enter city" />
          </Form.Item>

          <Form.Item name="district1" label="State" rules={[{ required: true, message: 'Please enter state!' }]}>
            <Input placeholder="Enter state" />
          </Form.Item>

          <Form.Item name="postalCode" label="Zip Code" rules={[{ required: true, message: 'Please enter zip code!' }]}>
            <Input placeholder="Enter zip code" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default SiteModal;
