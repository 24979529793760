import { notification } from 'antd';

interface INotification {
  type: 'success' | 'info' | 'warning' | 'error';
  message: string;
  description?: string | React.ReactElement;
  duration?: number;
  key?: string;
}

const Notification = ({ type, message, description, duration = 4, key }: INotification): void =>
  notification[type]({ message, description, duration, key });

export const closeNotification = (key: string) => notification.close(key);

export default Notification;
