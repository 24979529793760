import { httpClient } from 'services/index';

export const exportPatients = (projectId: string): Promise<any> =>
  httpClient.post('/patientExportJobs', { projectId }).then((_) => _.data.results);

export const getPatientExportStatus = (exportId: string): Promise<any> =>
  httpClient.get(`/patientExportJobs/${exportId}`).then((_) => _.data.results);

export const getUserFile = (fileId: string): Promise<any> =>
  httpClient.get(`/userFiles/${fileId}`).then((_) => _.data.results);
