import React, { FC, useEffect, useState } from 'react';

import { Modal, Select } from 'antd';

import { Analyte } from 'context/analytes';

interface Props {
  analytes: Analyte[];
  visible: boolean;
  working: boolean;
  onOk: (analyteIds: string[]) => Promise<void>;
  onCancel: () => void;
}

const ProjectAnalyteLinkModal: FC<Props> = ({ analytes, visible, working, onOk, onCancel }) => {
  const [selectedValues, setSelectedValues] = useState<string[]>([]);

  useEffect(() => {
    setSelectedValues([]);
  }, [visible]);

  const handleSubmit = () => {
    if (selectedValues.length > 0) {
      onOk(selectedValues);
    }
  };

  const handleChange = (values: string[]): void => {
    setSelectedValues(values);
  };

  return (
    <Modal
      wrapClassName="Modal AnalyteModal"
      visible={visible}
      title="Link analytes"
      okText="Link analytes"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={handleSubmit}
      confirmLoading={working}
      centered
      width="400px"
      forceRender
      okButtonProps={{ disabled: selectedValues.length === 0 }}
    >
      <Select
        className="multiSelect"
        mode="multiple"
        showSearch
        showArrow
        value={selectedValues}
        onChange={handleChange}
        placeholder="Select analytes"
        optionFilterProp="children"
        filterOption={(input, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        filterSort={(optionA: any, optionB: any) =>
          optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
        }
        style={{ width: '100%' }}
        loading={working}
      >
        {analytes.map((item) => (
          <Select.Option key={item.id} value={item.id} data-testid={item.friendlyname}>
            {item.friendlyname}
          </Select.Option>
        ))}
      </Select>
    </Modal>
  );
};

export default ProjectAnalyteLinkModal;
