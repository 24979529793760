import React, { useRef } from 'react';

import { Input, Space, Button } from 'antd';
import { ColumnType } from 'antd/lib/table';
import DatePickerWithType from 'components/DatePickerWithType';

import { SearchOutlined } from 'components/icons';

type SearchCallback = (searchKey: string, term: string | null) => Promise<any>;
type ValidateValueCallback = (term: string | null) => string | null;

const useBackendColumnSearch = <T extends unknown>() => {
  const searchInputRef = useRef<Input>(null);

  const handleSearch = (
    selectedKeys: React.Key[],
    closeSearchbox: () => void,
    searchCallback: SearchCallback,
    searchKey: string,
    validateValueCallback?: ValidateValueCallback
  ): void => {
    const searchTerm = selectedKeys[0].toString();

    if (validateValueCallback) {
      const newValue = validateValueCallback(searchTerm);
      if (!newValue) {
        return;
      }
    } else if (searchTerm.length < 3) {
      return;
    }

    searchCallback(searchKey, searchTerm);
    closeSearchbox();
  };

  const handleReset = (closeSearchBox: () => void, searchCallback: SearchCallback, searchKey: string): void => {
    searchCallback(searchKey, null);
    closeSearchBox();
  };

  const getColumnSearchProps = (
    searchConfig: { by: string; term: string } | null,
    searchKey: string,
    searchCallback: SearchCallback,
    validateValueCallback?: ValidateValueCallback,
    inputType?: 'simple' | 'datepicker'
  ): ColumnType<T> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, clearFilters }) => (
      <div style={{ padding: 8 }}>
        {inputType === 'datepicker' ? (
          <DatePickerWithType
            style={{ width: 188, marginBottom: 8, display: 'block' }}
            value={(selectedKeys[0] as string) || null}
            onChange={(v) => setSelectedKeys(v ? [v] : [])}
            data-testid="column-search_datepicker"
          />
        ) : (
          <Input
            ref={searchInputRef}
            placeholder="Search..."
            value={selectedKeys[0] || ''}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
            data-testid="column-search_input"
          />
        )}
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, clearFilters!, searchCallback, searchKey, validateValueCallback)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
            disabled={selectedKeys.length === 0 || selectedKeys[0].toString().length < 3}
            data-testid="column-search_submit-button"
          >
            Search
          </Button>

          <Button
            onClick={() => handleReset(clearFilters!, searchCallback, searchKey)}
            size="small"
            style={{ width: 90 }}
            data-testid="column-search_reset-button"
          >
            Reset
          </Button>
        </Space>
      </div>
    ),

    filtered: searchConfig !== null && searchConfig.by === searchKey,

    filteredValue: searchConfig !== null && searchConfig.by === searchKey ? [searchConfig.term] : undefined,

    filterIcon: (filtered) => (
      <SearchOutlined
        style={filtered ? { color: '#1890ff', fontSize: '18px' } : undefined}
        data-testid={`shipments-search_${searchKey}`}
      />
    ),

    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          if (searchInputRef && searchInputRef.current) {
            searchInputRef.current.select();
          }
        });
      }
    },
  });

  return { getColumnSearchProps };
};

export default useBackendColumnSearch;
