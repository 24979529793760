import React, { FC, createContext, useReducer } from 'react';

import { notificationTemplatesApi } from 'api';
import {
  UpdateEmailTemplatePayload,
  CreateEmailTemplatePayload,
  CreateSmsTemplatePayload,
  UpdateSmsTemplatePayload,
} from 'api/notificationTemplates';
import { loadAllPages, makeReducer } from 'utils/functions';
import * as issues from 'utils/issues';
import Notification from 'utils/notification';

import { EmailTemplate, Context, State, SmsTemplate } from './types';

const getInitialState = (): State => ({
  emailTemplates: [],
  emailTemplate: null,
  smsTemplates: [],
  smsTemplate: null,
  error: null,
  loading: false,
});

const NotificationTemplatesContext = createContext<Context>({
  ...getInitialState(),
  getTemplates: async () => {},
  createEmailTemplate: async () => {},
  updateEmailTemplate: async () => {},
  createSmsTemplate: async () => {},
  updateSmsTemplate: async () => {},
  deleteEmailTemplate: async () => {},
  deleteSmsTemplate: async () => {},
  resetTemplates: () => {},
});

const NotificationTemplatesProvider: FC<any> = (props) => {
  const [state, setState] = useReducer(makeReducer<State>(), getInitialState());

  const getTemplates = async (queryParams: Record<string, any> = {}, callback?: any): Promise<void> => {
    try {
      setState({ error: null, loading: true });

      type EmailPayloadType = Parameters<typeof notificationTemplatesApi.getEmailTemplates>[0];
      const emailTemplates = await loadAllPages<EmailTemplate, EmailPayloadType>(
        notificationTemplatesApi.getEmailTemplates,
        queryParams,
        {
          pageLength: 100,
          sortBy: 'createdAt',
          isDescending: false,
        }
      );

      type SmsPayloadType = Parameters<typeof notificationTemplatesApi.getSmsTemplates>[0];
      const smsTemplates = await loadAllPages<SmsTemplate, SmsPayloadType>(
        notificationTemplatesApi.getSmsTemplates,
        queryParams,
        {
          pageLength: 100,
          sortBy: 'createdAt',
          isDescending: false,
        }
      );

      setState({
        emailTemplates,
        smsTemplates,
        loading: false,
      });

      if (callback) {
        await callback(emailTemplates, smsTemplates);
      }
    } catch (e: any) {
      const errorMsg = issues.serializeForDisplay(e?.response?.data?.issues) || e.message;

      setState({
        loading: false,
        error: errorMsg,
      });

      Notification({
        type: 'error',
        message: 'Cannot get all templates',
        description: errorMsg,
      });
    }
  };

  const createEmailTemplate = async (data: CreateEmailTemplatePayload, callback?: any): Promise<void> => {
    try {
      setState({ error: null, loading: true });

      const emailTemplate = await notificationTemplatesApi.createEmailTemplate(data);

      setState({
        emailTemplates: [...state.emailTemplates, emailTemplate],
        loading: false,
      });

      Notification({ message: 'Email template created', type: 'success', description: '' });

      if (callback) {
        await callback(emailTemplate);
      }
    } catch (e: any) {
      const errorMsg = issues.serializeForDisplay(e?.response?.data?.issues) || e.message;

      setState({
        loading: false,
        error: errorMsg,
      });

      Notification({
        type: 'error',
        message: 'Cannot create email template',
        description: errorMsg,
      });
    }
  };

  const updateEmailTemplate = async (id: string, data: UpdateEmailTemplatePayload, callback?: any): Promise<void> => {
    try {
      setState({ error: null, loading: true });

      const emailTemplate: EmailTemplate = await notificationTemplatesApi.updateEmailTemplate(id, data);

      setState({
        emailTemplates: state.emailTemplates.map((template) => (template.id === id ? { ...emailTemplate } : template)),
        loading: false,
      });

      Notification({ message: 'Email template updated', type: 'success', description: '' });

      if (callback) {
        await callback(emailTemplate);
      }
    } catch (e: any) {
      const errorMsg = issues.serializeForDisplay(e?.response?.data?.issues) || e.message;

      setState({
        loading: false,
        error: errorMsg,
      });

      Notification({
        type: 'error',
        message: 'Cannot update email template',
        description: errorMsg,
      });
    }
  };

  const deleteEmailTemplate = async (id: string, callback?: any): Promise<void> => {
    try {
      setState({ error: null, loading: true });

      const emailTemplate = await notificationTemplatesApi.deleteEmailTemplate(id);

      setState({
        emailTemplates: state.emailTemplates.filter((template) => template.id !== id),
        loading: false,
      });

      Notification({ message: 'Email template deleted', type: 'success', description: '' });

      if (callback) {
        await callback(emailTemplate);
      }
    } catch (e: any) {
      const errorMsg = issues.serializeForDisplay(e?.response?.data?.issues) || e.message;

      setState({
        loading: false,
        error: errorMsg,
      });

      Notification({
        type: 'error',
        message: 'Cannot delete email template',
        description: errorMsg,
      });
    }
  };

  const createSmsTemplate = async (data: CreateSmsTemplatePayload, callback?: any): Promise<void> => {
    try {
      setState({ error: null, loading: true });

      const smsTemplate = await notificationTemplatesApi.createSmsTemplate(data);

      setState({
        smsTemplates: [...state.smsTemplates, smsTemplate],
        loading: false,
      });

      Notification({ message: 'SMS template created', type: 'success', description: '' });

      if (callback) {
        await callback(smsTemplate);
      }
    } catch (e: any) {
      const errorMsg = issues.serializeForDisplay(e?.response?.data?.issues) || e.message;

      setState({
        loading: false,
        error: errorMsg,
      });

      Notification({
        type: 'error',
        message: 'Cannot create SMS template',
        description: errorMsg,
      });
    }
  };

  const updateSmsTemplate = async (id: string, data: UpdateSmsTemplatePayload, callback?: any): Promise<void> => {
    try {
      setState({ error: null, loading: true });

      const smsTemplate: SmsTemplate = await notificationTemplatesApi.updateSmsTemplate(id, data);

      setState({
        smsTemplates: state.smsTemplates.map((template) => (template.id === id ? { ...smsTemplate } : template)),
        loading: false,
      });

      Notification({ message: 'SMS template updated', type: 'success', description: '' });

      if (callback) {
        await callback(smsTemplate);
      }
    } catch (e: any) {
      const errorMsg = issues.serializeForDisplay(e?.response?.data?.issues) || e.message;

      setState({
        loading: false,
        error: errorMsg,
      });

      Notification({
        type: 'error',
        message: 'Cannot update SMS template',
        description: errorMsg,
      });
    }
  };

  const deleteSmsTemplate = async (id: string, callback?: any): Promise<void> => {
    try {
      setState({ error: null, loading: true });

      const smsTemplate = await notificationTemplatesApi.deleteSmsTemplate(id);

      setState({
        smsTemplates: state.smsTemplates.filter((template) => template.id !== id),
        loading: false,
      });

      Notification({ message: 'SMS template deleted', type: 'success', description: '' });

      if (callback) {
        await callback(smsTemplate);
      }
    } catch (e: any) {
      const errorMsg = issues.serializeForDisplay(e?.response?.data?.issues) || e.message;

      setState({
        loading: false,
        error: errorMsg,
      });

      Notification({
        type: 'error',
        message: 'Cannot delete SMS template',
        description: errorMsg,
      });
    }
  };

  const resetTemplates = (): void => {
    setState(getInitialState());
  };

  return (
    <NotificationTemplatesContext.Provider
      value={{
        emailTemplates: state.emailTemplates,
        emailTemplate: state.emailTemplate,
        smsTemplates: state.smsTemplates,
        smsTemplate: state.smsTemplate,
        loading: state.loading,
        error: state.error,
        getTemplates,
        createEmailTemplate,
        updateEmailTemplate,
        deleteEmailTemplate,
        createSmsTemplate,
        updateSmsTemplate,
        deleteSmsTemplate,
        resetTemplates,
      }}
      {...props}
    />
  );
};

const useNotificationTemplates = (): Context => React.useContext(NotificationTemplatesContext);

export { NotificationTemplatesProvider, useNotificationTemplates };
