import React, { FC } from 'react';

import { Menu } from 'antd';
import { useLocation, useHistory } from 'react-router-dom';

import {
  AppstoreAddOutlined,
  ContactsOutlined,
  ExperimentOutlined,
  BarsOutlined,
  UserOutlined,
  MessageOutlined,
} from 'components/icons';
import { ROUTES } from 'constants/routes';

const SettingsNavigation: FC = () => {
  const location = useLocation();
  const history = useHistory();

  const handleNavigateToSection = ({ key }: any) => {
    history.push(`${ROUTES.settings}/${key}`);
  };

  return (
    <div className="SettingsNavigation">
      <Menu
        onClick={handleNavigateToSection}
        selectedKeys={[location.pathname.split('/')[2] || 'customers']}
        mode="horizontal"
      >
        <Menu.Item key="customers" icon={<ContactsOutlined />}>
          Customers
        </Menu.Item>

        <Menu.Item key="laboratories" icon={<ExperimentOutlined />}>
          Laboratories
        </Menu.Item>

        <Menu.Item key="kit-items" icon={<BarsOutlined />}>
          Kit items
        </Menu.Item>

        <Menu.Item key="users" icon={<UserOutlined />}>
          Users
        </Menu.Item>

        <Menu.Item key="notification-templates" icon={<MessageOutlined />}>
          Notification templates
        </Menu.Item>

        <Menu.Item key="id-pools" icon={<AppstoreAddOutlined />}>
          Id Pools
        </Menu.Item>
      </Menu>
    </div>
  );
};

export default SettingsNavigation;
