/* eslint-disable camelcase */
import React, { FC, useRef, useState, createContext } from 'react';

import { analytesApi } from 'api';
import { makeCancelTokenSource, CancelTokenSource } from 'services';
import { loadAllPages } from 'utils/functions';
import { getErrorMessage } from 'utils/issues';
import Notification from 'utils/notification';

export interface Analyte {
  id: string;
  createdAt: null;
  updatedAt: null;
  friendlyname: string;
  LOINC_longname: string;
  mappings: {
    labkey: string;
    ordercode: string;
    resultcode: string;
    labname: string;
    scale: string;
    LOINC_resultcode: string;
    metadata?: { uom?: string };
  }[];
}

interface State {
  analytes: Analyte[];
  analyte: Analyte | null;
  loading: boolean;
  error: string | null;
}

type GetAnalytesCallback = (analytes: Analyte[] | null) => Promise<void>;
type GetAnalyteCallback = (analytes: Analyte | null) => Promise<void>;

interface Context extends State {
  getAnalytes: (params: analytesApi.GetAnalytesParams, callback?: GetAnalytesCallback) => Promise<void>;
  getAnalyte: (id: string, callback?: GetAnalyteCallback) => Promise<void>;
  resetAnalytes: () => void;
}

const getInitialState = (): State => ({ analytes: [], analyte: null, loading: false, error: null });

const sortByName = (a: Analyte, b: Analyte) => (a.friendlyname || '').localeCompare(b.friendlyname || '');

const AnalytesContext = createContext<Context>({
  ...getInitialState(),
  getAnalytes: async () => {},
  getAnalyte: async () => {},
  resetAnalytes: () => {},
});

const AnalytesProvider: FC<any> = (props) => {
  const [state, setState] = useState<State>({ ...getInitialState() });
  const cancelTokenSource = useRef<CancelTokenSource | null>(null);

  const getAnalytes = async (params: analytesApi.GetAnalytesParams, callback?: GetAnalytesCallback): Promise<void> => {
    setState((s) => ({
      ...s,
      loading: true,
      error: null,
    }));

    try {
      cancelTokenSource.current = makeCancelTokenSource();

      const analytes = await loadAllPages<Analyte, analytesApi.GetAnalytesParams>(
        analytesApi.getAnalytes,
        params,
        {
          pageLength: 100,
        },
        { cancelToken: cancelTokenSource.current.token }
      );

      setState((s) => ({
        ...s,
        analytes: analytes.sort(sortByName),
      }));

      if (callback) {
        await callback(analytes);
      }
    } catch (e) {
      cancelTokenSource.current = null;

      const errorMessage = getErrorMessage(e);

      setState((s) => ({
        ...s,
        error: errorMessage,
      }));

      Notification({
        type: 'error',
        message: 'Cannot get analytes',
        description: errorMessage,
      });

      if (callback) {
        await callback(null);
      }
    }

    setState((s) => ({
      ...s,
      loading: false,
    }));
  };

  const getAnalyte = async (id: string, callback?: GetAnalyteCallback): Promise<void> => {
    setState((s) => ({
      ...s,
      loading: true,
      error: null,
    }));

    try {
      const analyte: Analyte = await analytesApi.getAnalyte(id);

      setState((s) => ({
        ...s,
        analyte,
      }));

      if (callback) {
        await callback(analyte);
      }
    } catch (e) {
      const errorMessage = getErrorMessage(e);

      setState((s) => ({
        ...s,
        error: errorMessage,
      }));

      Notification({
        type: 'error',
        message: 'Cannot get analyte details',
        description: errorMessage,
      });

      if (callback) {
        await callback(null);
      }
    }

    setState((s) => ({
      ...s,
      loading: false,
    }));
  };

  const resetAnalytes = (): void => {
    if (cancelTokenSource.current) {
      cancelTokenSource.current.cancel();
      cancelTokenSource.current = null;
    }

    setState({ ...getInitialState() });
  };

  return (
    <AnalytesContext.Provider
      value={{
        analytes: state.analytes,
        analyte: state.analyte,
        loading: state.loading,
        error: state.error,
        getAnalytes,
        getAnalyte,
        resetAnalytes,
      }}
      {...props}
    />
  );
};

const useAnalytes = (): Context => React.useContext(AnalytesContext);

export { AnalytesProvider, useAnalytes };
