import React, { FC } from 'react';

import { Form, Input, Button, Space, Typography } from 'antd';
import QRCode from 'qrcode.react';

import { LockOutlined } from 'components/icons';

interface ISetupMFAForm {
  loading: boolean;
  onSubmit: (code: string) => void;
  email: string;
  code: string;
}
const { Title, Paragraph } = Typography;

const SetupMFAForm: FC<ISetupMFAForm> = ({ loading, onSubmit, email, code }) => {
  const [form] = Form.useForm();

  const handleFormSubmit = () => {
    form
      .validateFields()
      .then((values: any) => {
        onSubmit(values.setupCode);
      })
      .catch((info: any) => {
        console.log('Validate Failed:', info);
      });
  };

  return (
    <Form
      className="Login__Form"
      form={form}
      layout="vertical"
      name="SetupMFAForm"
      hideRequiredMark
      initialValues={{ code: '' }}
      onFinish={handleFormSubmit}
      style={{ maxWidth: '450px' }}
    >
      <Space direction="vertical" size="small" align="center" style={{ textAlign: 'center' }}>
        <Title level={4} type="secondary">
          Setup Multi-Factor Authentication
        </Title>

        <QRCode
          value={`otpauth://totp/Tasso:${email}?secret=${code}&issuer=Tasso`}
          size={256}
          level="L"
          renderAs="canvas"
        />

        <Paragraph style={{ textAlign: 'left' }}>
          Scan the QR code above in an iOS or Android authentication application to create a Tasso multi-factor
          authentication account. Duo, Microsoft Authenticator, or Google Authenticator are all common, free
          authentication applications that will work.
        </Paragraph>
        <Paragraph style={{ textAlign: 'left' }}>
          After scanning the QR code in the authentication application, you will be provided with a one-time passcode to
          enter.
        </Paragraph>

        <Form.Item
          name="setupCode"
          rules={[
            {
              required: true,
              message: 'Please enter your one-time passcode!',
            },
          ]}
        >
          <Input prefix={<LockOutlined />} placeholder="Enter one-time passcode" />
        </Form.Item>
      </Space>

      <Form.Item>
        <Button block type="primary" htmlType="submit" loading={loading}>
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default SetupMFAForm;
