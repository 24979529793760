import React, { FC, createContext, useReducer } from 'react';

import { deviceLotsApi as api } from 'api';
import { loadAllPages, makeReducer } from 'utils/functions';
import { getErrorMessage } from 'utils/issues';
import Notification from 'utils/notification';

export interface IDeviceLot {
  id: string;
  key: string;
  createddAt: string;
  updatedAt: string;
}

type State = {
  deviceLots: IDeviceLot[];
  deviceLot: IDeviceLot | null;
  error: null | string;
  loading: boolean;
};

export interface IDeviceLotsContext {
  deviceLots: IDeviceLot[];
  deviceLot: IDeviceLot | null;
  loading: boolean;
  error: any;
  getDeviceLots: (callback?: any) => Promise<void>;
  getDeviceLot: (id: string, callback?: any) => Promise<void>;
  addDeviceLot: (key: string, callback?: any) => Promise<void>;
  updateDeviceLot: (id: string, key: string, callback: any, showNotification?: boolean) => Promise<void>;
  resetDeviceLots: () => void;
}

const sortByName = (a: IDeviceLot, b: IDeviceLot) => a.key.localeCompare(b.key);

const getInitialState = (): State => ({
  deviceLots: [],
  deviceLot: null,
  error: null,
  loading: false,
});

const DeviceLotsContext = createContext<IDeviceLotsContext>({
  ...getInitialState(),
  getDeviceLots: async () => {},
  getDeviceLot: async () => {},
  addDeviceLot: async () => {},
  updateDeviceLot: async () => {},
  resetDeviceLots: () => {},
});

const DeviceLotsProvider: FC = (props) => {
  const [state, setState] = useReducer(makeReducer<State>(), getInitialState());

  const getDeviceLots = async (callback?: any): Promise<void> => {
    try {
      setState({
        loading: true,
        error: null,
      });

      const items = await loadAllPages<IDeviceLot, any>(
        api.getDeviceLots,
        {},
        { pageLength: 100, sortBy: 'createdAt', isDescending: false }
      );

      setState({
        deviceLots: items.sort(sortByName),
        loading: false,
      });

      if (callback) {
        await callback();
      }
    } catch (e) {
      const errorMessage = getErrorMessage(e);

      setState({
        loading: false,
        error: errorMessage,
      });

      Notification({
        type: 'error',
        message: 'Cannot get device lots',
        description: errorMessage,
      });
    }
  };

  const getDeviceLot = async (id: string, callback?: any): Promise<void> => {
    try {
      const item = (await api.getDeviceLot(id)) as IDeviceLot;

      setState({ deviceLot: item });

      if (callback) {
        await callback(item);
      }
    } catch (e) {
      const errorMessage = getErrorMessage(e);

      setState({
        loading: false,
        error: errorMessage,
      });

      Notification({
        type: 'error',
        message: 'Cannot get device lot',
        description: errorMessage,
      });
    }
  };

  const addDeviceLot = async (key: string, callback?: any): Promise<void> => {
    try {
      setState({ loading: true, error: null });

      const item = (await api.addDeviceLot(key)) as IDeviceLot;

      setState({
        deviceLots: [...state.deviceLots, item].sort(sortByName),
        loading: false,
      });

      Notification({
        type: 'success',
        message: 'Device lot created',
      });

      if (callback) {
        await callback(item);
      }
    } catch (e) {
      const errorMessage = getErrorMessage(e);

      setState({ loading: false, error: errorMessage });

      Notification({
        type: 'error',
        message: 'Cannot create device lot',
        description: errorMessage,
      });
    }
  };

  const updateDeviceLot = async (id: string, key: string, callback: any, showNotification = false): Promise<void> => {
    try {
      setState({ loading: true, error: null });

      const updatedItem = await api.updateDeviceLot(id, key);

      setState({
        deviceLots: state.deviceLots.map((lot) => (lot.id === id ? updatedItem : lot)),
        deviceLot: state.deviceLot && state.deviceLot.id === id ? updatedItem : state.deviceLot,
        loading: false,
      });

      if (showNotification) {
        Notification({ type: 'success', message: 'Device lot updated' });
      }

      if (callback) {
        await callback(updatedItem);
      }
    } catch (e) {
      const errorMessage = getErrorMessage(e);

      setState({ loading: false, error: errorMessage });

      Notification({
        type: 'error',
        message: 'Device lot not updated',
        description: errorMessage,
      });
    }
  };

  const resetDeviceLots = (): void => {
    setState(getInitialState());
  };

  return (
    <DeviceLotsContext.Provider
      value={{
        deviceLots: state.deviceLots,
        deviceLot: state.deviceLot,
        loading: state.loading,
        error: state.error,
        getDeviceLots,
        getDeviceLot,
        addDeviceLot,
        updateDeviceLot,
        resetDeviceLots,
      }}
      {...props}
    />
  );
};

const useDeviceLots = () => React.useContext(DeviceLotsContext);

export { DeviceLotsProvider, useDeviceLots };
