import Auth from '@aws-amplify/auth';

Auth.configure({
  region: process.env.SERVICE_REGION || '',
  userPoolId: process.env.COGNITO_POOL_ID || '',
  userPoolWebClientId: process.env.COGNITO_CLIENT_ID || '',
  mandatorySignIn: true,
  authenticationFlowType: 'USER_SRP_AUTH',
  clientMetadata: {
    client: 'AdminPortal',
  },
  endpoint: `https://cognito-idp-fips.${process.env.SERVICE_REGION || ''}.amazonaws.com`,
});

export default Auth;
