import React, { FC } from 'react';

import clsx from 'clsx';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';

import { ProtectedRoute, Layout } from 'components/index';
import { ROUTES, USER_ROLES } from 'constants/index';
import { useAuth } from 'context/auth';
import { Login, Projects, Settings, ForgotPassword, NotFound, PatientSearch, Manifests } from 'features/index';

const Router: FC = () => {
  const { profile } = useAuth();
  const isAdmin = (profile?.role || '') === USER_ROLES.internalAdmin;
  const isCustomerService = (profile?.role || '') === USER_ROLES.internalCustomerService;
  const isShipper = (profile?.role || '') === USER_ROLES.internalShipper;
  const { pathname } = useLocation();

  const isFreeHeightContainer =
    pathname.endsWith('/details') ||
    pathname.endsWith('/unassigned-devices') ||
    pathname.endsWith('/settings/users') ||
    /\/settings\/id-pools/.test(pathname);

  return (
    <Switch>
      <Redirect exact from="/" to={ROUTES.projects} />

      <Route exact path={ROUTES.login}>
        <Login />
      </Route>

      <Route path={ROUTES.resetPassword}>
        <ForgotPassword />
      </Route>

      <ProtectedRoute path={ROUTES.projects}>
        <Layout className={clsx('Projects', { freeHeight: isFreeHeightContainer })}>
          <Projects />
        </Layout>
      </ProtectedRoute>

      {(isAdmin || isCustomerService) && (
        <ProtectedRoute path={ROUTES.settings}>
          <Layout className={clsx({ freeHeight: isFreeHeightContainer })}>
            <Settings />
          </Layout>
        </ProtectedRoute>
      )}

      {(isAdmin || isCustomerService) && (
        <ProtectedRoute path={ROUTES.patientSearch}>
          <Layout className="freeHeight">
            <PatientSearch />
          </Layout>
        </ProtectedRoute>
      )}

      {(isAdmin || isCustomerService || isShipper) && (
        <ProtectedRoute path={ROUTES.manifests}>
          <Layout className={clsx('Projects', { freeHeight: isFreeHeightContainer })}>
            <Manifests />
          </Layout>
        </ProtectedRoute>
      )}

      <ProtectedRoute path="/*">
        <Layout>
          <NotFound />
        </Layout>
      </ProtectedRoute>
    </Switch>
  );
};

export default Router;
