import React, { FC } from 'react';

import { Switch, useRouteMatch } from 'react-router-dom';

import { ProtectedRoute } from 'components/index';

import Project from './Project';
import Projects from './Projects';

const ProjectsContainer: FC = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <ProtectedRoute exact path={path}>
        <Projects />
      </ProtectedRoute>

      <ProtectedRoute path={`${path}/:projectId`}>
        <Project />
      </ProtectedRoute>
    </Switch>
  );
};

export default ProjectsContainer;
