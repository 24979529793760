import React, { useState, useContext } from 'react';

// Context
const ManifestStatusContext = React.createContext<{ isManifestEnabled: boolean; toggleManifestStatus: () => void }>({
  isManifestEnabled: true,
  toggleManifestStatus: () => {},
});

// Provider
const ManifestStatusProvider = ({ children }: { children: React.ReactElement }) => {
  const [isManifestEnabled, setIsManifestEnabled] = useState(true);

  const toggleManifestStatus = () => {
    setIsManifestEnabled(!isManifestEnabled);
  };

  return (
    <ManifestStatusContext.Provider value={{ isManifestEnabled, toggleManifestStatus }}>
      {children}
    </ManifestStatusContext.Provider>
  );
};

const useManifestStatus = () => useContext(ManifestStatusContext);

export { useManifestStatus, ManifestStatusProvider };
