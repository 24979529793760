import React, { useRef } from 'react';

import { Input, Space, Button } from 'antd';
import { ColumnType } from 'antd/lib/table';
import { FilterConfirmProps } from 'antd/lib/table/interface';

import { SearchOutlined } from 'components/icons';
import { FilterFilled } from '@ant-design/icons';

const useIntegratedColumnSearch = <T extends unknown>() => {
  const searchInputRef = useRef<Input>(null);

  const handleSearch = (selectedKeys: React.Key[], confirm: (param: FilterConfirmProps) => void): void => {
    const searchTerm = selectedKeys[0].toString();

    if (searchTerm.length < 3) {
      return;
    }

    confirm({ closeDropdown: true });
  };

  const handleReset = (clearFilters: () => void): void => {
    clearFilters();
  };

  const getColumnSearchProps = (
    searchConfig: { by: string; term: string } | null,
    columnKey: string,
    columnType: 'search' | 'filter'
  ): ColumnType<T> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, clearFilters, confirm }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInputRef}
          placeholder="Search..."
          value={selectedKeys[0] || ''}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              handleSearch(selectedKeys, confirm!);
            }
          }}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
          data-testid="column-search_input"
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm!)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
            disabled={selectedKeys.length === 0 || selectedKeys[0].toString().length < 3}
            data-testid="column-search_submit-button"
          >
            Search
          </Button>

          <Button
            onClick={() => handleReset(clearFilters!)}
            size="small"
            style={{ width: 90 }}
            data-testid="column-search_reset-button"
          >
            Reset
          </Button>
        </Space>
      </div>
    ),

    filtered: searchConfig !== null && searchConfig.by === columnKey,

    filteredValue: searchConfig !== null && searchConfig.by === columnKey ? [searchConfig.term] : null,

    filterIcon: (filtered) =>
      columnType === 'search' ? (
        <SearchOutlined
          data-testid={`search-column_${columnKey}-icon`}
          style={filtered ? { color: '#1890ff', fontSize: '18px' } : undefined}
        />
      ) : (
        <FilterFilled
          data-testid={`filter-column_${columnKey}-icon`}
          style={filtered ? { color: '#1890ff' } : undefined}
        />
      ),

    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          if (searchInputRef && searchInputRef.current) {
            searchInputRef.current.select();
          }
        });
      }
    },
  });

  return { getColumnSearchProps };
};

export default useIntegratedColumnSearch;
