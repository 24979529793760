/* eslint-disable no-await-in-loop */
import React, { FC, useState, createContext, useRef } from 'react';

import { loadAllPages } from 'utils/functions';
import { getErrorMessage } from 'utils/issues';
import { makeCancelTokenSource, CancelTokenSource } from 'services/index';

import { isRequestCancelled } from 'services/httpClient';
import { projectIdPoolLinksApi as api } from '../api';
import Notification from '../utils/notification';
import { IdPool } from './idPools';
import { Project } from './projects';

export type ProjectIdPoolLink = {
  id: string;
  projectId: string;
  project?: Project;
  idPoolId: string;
  idPool?: IdPool;
  createdAt: string;
  updatedAt: string | null;
};

type State = {
  projectIdPoolLinks: ProjectIdPoolLink[];
  projectIdPoolLink: ProjectIdPoolLink | null;
  loading: boolean;
  error: string | null;
};

type Options = {
  hydrate?: ('project' | 'idPool')[];
};

interface GetProjectIdPoolLinksParams {
  projectIds?: string[];
  idPoolIds?: string[];
}
type GetProjectIdPoolLinksFunction = (
  params: GetProjectIdPoolLinksParams,
  config?: Options,
  callback?: (links: ProjectIdPoolLink[] | null) => Promise<void>
) => Promise<void>;

type GetProjectIdPoolLinkFunction = (
  id: string,
  config?: Options,
  callback?: (link: ProjectIdPoolLink | null) => Promise<void>
) => Promise<void>;

type CreateProjectIdPoolLinkFunction = (
  params: api.CreateProjectIdPoolLinkPayload,
  config?: Options,
  callback?: (link: ProjectIdPoolLink | null) => Promise<void>
) => Promise<void>;

type DeleteProjectIdPoolLinkFunction = (
  id: string,
  callback?: (link: ProjectIdPoolLink | null) => Promise<void>
) => Promise<void>;

type ResetProjectIdPoolLinksFunction = () => void;

type Context = State & {
  getProjectIdPoolLinks: GetProjectIdPoolLinksFunction;
  getProjectIdPoolLink: GetProjectIdPoolLinkFunction;
  createProjectIdPoolLink: CreateProjectIdPoolLinkFunction;
  deleteProjectIdPoolLink: DeleteProjectIdPoolLinkFunction;
  resetProjectIdPoolLinks: ResetProjectIdPoolLinksFunction;
};

const getInitialState = (): State => ({
  projectIdPoolLinks: [],
  projectIdPoolLink: null,
  error: null,
  loading: false,
});

const ProjectIdPoolLinksContext = createContext<Context>({
  ...getInitialState(),
  getProjectIdPoolLinks: async () => {},
  getProjectIdPoolLink: async () => {},
  createProjectIdPoolLink: async () => {},
  deleteProjectIdPoolLink: async () => {},
  resetProjectIdPoolLinks: () => {},
});

const ProjectIdPoolLinksProvider: FC<any> = (props) => {
  const [state, setState] = useState<State>(getInitialState());
  const cancelTokenSource = useRef<CancelTokenSource | null>(null);

  const getProjectIdPoolLinks: GetProjectIdPoolLinksFunction = async (params, config = {}, callback?) => {
    setState((s) => ({
      ...s,
      loading: true,
      error: null,
    }));

    cancelTokenSource.current = makeCancelTokenSource();

    const options: api.ProjectIdPoolLinksRequestOptions = {
      hydrate: config.hydrate ?? [],
      cancelToken: cancelTokenSource.current.token,
    };

    if (params.idPoolIds && params.projectIds) {
      // This check exists to avoid excessively long query strings and
      // potentially confusing lookups. What does it even mean to search
      // for pool ids and project ids at the same time?
      throw new Error('idPoolIds and projectIds cannot be used at the same time.');
    }

    let idSearchQuery: { field: string; values: string[] } | null = null;

    if (Array.isArray(params.idPoolIds)) {
      idSearchQuery = { field: 'idPoolIds', values: params.idPoolIds };
    }

    if (Array.isArray(params.projectIds)) {
      idSearchQuery = { field: 'projectIds', values: params.projectIds };
    }

    try {
      let projectIdPoolLinks: ProjectIdPoolLink[] = [];

      if (idSearchQuery) {
        // Search based query (searching by idPoolIds or projectIds)

        const promises: Promise<any>[] = [];

        const resolvePromises = async (): Promise<boolean> => {
          const promisesToResolve = promises.splice(0, promises.length);
          const resolvedData = await Promise.all(promisesToResolve);
          for (let j = 0; j < resolvedData.length; j += 1) {
            if (isRequestCancelled(resolvedData[j])) {
              return false;
            }
            projectIdPoolLinks.push(...resolvedData[j].results);
          }
          return true;
        };

        for (let i = 0; i < idSearchQuery.values.length; i += 50) {
          const valuesSubset = idSearchQuery.values.slice(i, i + 50);
          promises.push(
            api.getProjectIdPoolLinks(
              { query: { [idSearchQuery.field]: valuesSubset.join(',') }, options },
              {
                page: 1,
                // Results are being searched on at most 50 ids (projectId or idPoolId),
                // however one projectId could be linked to multiple pools.
                // So the returned result set may contain more than 50 items,
                // it's *not* a one-to-one relationship.
                // All matches should be returned. Use 1000 as pageLength to
                // mean "return all matches".
                pageLength: 1000,
                includeTotalCount: false,
              }
            )
          );

          if (promises.length === 5) {
            if (!(await resolvePromises())) {
              return; // requests cancelled by client
            }
          }
        }

        if (promises.length > 0) {
          if (!(await resolvePromises())) {
            return; // requests cancelled by client
          }
        }
      } else {
        // Load all data

        type PayloadType = Parameters<typeof api.getProjectIdPoolLinks>[0];
        projectIdPoolLinks = await loadAllPages<ProjectIdPoolLink, PayloadType>(
          api.getProjectIdPoolLinks,
          { query: {}, options },
          {
            pageLength: 100,
          },
          { cancelToken: cancelTokenSource.current.token }
        );
      }

      setState((s) => ({
        ...s,
        projectIdPoolLinks,
      }));

      if (callback) {
        await callback(projectIdPoolLinks);
      }
    } catch (e) {
      const errorMessage = getErrorMessage(e);

      setState((s) => ({
        ...s,
        error: errorMessage,
      }));

      Notification({
        type: 'error',
        message: 'Failed to load Project Id Pool Links',
        description: errorMessage,
      });

      if (callback) {
        await callback(null);
      }
    } finally {
      setState((s) => ({
        ...s,
        loading: false,
      }));
    }
  };

  const getProjectIdPoolLink: GetProjectIdPoolLinkFunction = async (id, config, callback) => {
    const existingProjectIdPoolLinks = state.projectIdPoolLinks.find(
      (projectIdPoolLink) => projectIdPoolLink.id === id
    );

    if (existingProjectIdPoolLinks) {
      if (callback) {
        await callback(existingProjectIdPoolLinks);
      }
      return;
    }

    setState((s) => ({
      ...s,
      loading: true,
      error: null,
    }));

    try {
      cancelTokenSource.current = makeCancelTokenSource();

      const options: api.ProjectIdPoolLinksRequestOptions = {
        hydrate: config?.hydrate || [],
        cancelToken: cancelTokenSource.current.token,
      };

      const projectIdPoolLink = await api.getProjectIdPoolLink(id, options);

      if (projectIdPoolLink.isCancelledByClient) {
        return;
      }

      setState((s) => ({
        ...s,
        projectIdPoolLink,
      }));

      if (callback) {
        await callback(projectIdPoolLink);
      }
    } catch (e) {
      const errorMessage = getErrorMessage(e);

      setState((s) => ({
        ...s,
        error: errorMessage,
      }));

      Notification({
        type: 'error',
        message: 'Failed to get Project Id Pool Link details',
        description: errorMessage,
      });

      if (callback) {
        await callback(null);
      }
    } finally {
      setState((s) => ({
        ...s,
        loading: false,
      }));
    }
  };

  const createProjectIdPoolLink: CreateProjectIdPoolLinkFunction = async (data, config, callback) => {
    setState((s) => ({
      ...s,
      loading: true,
      error: null,
    }));

    try {
      cancelTokenSource.current = makeCancelTokenSource();

      const options: api.ProjectIdPoolLinksRequestOptions = {
        hydrate: config?.hydrate || [],
        cancelToken: cancelTokenSource.current.token,
      };

      const projectIdPoolLink = await api.createProjectIdPoolLink(data, options);

      if (projectIdPoolLink.isCancelledByClient) {
        return;
      }

      setState((s) => ({
        ...s,
        projectIdPoolLinks: [...s.projectIdPoolLinks, projectIdPoolLink],
      }));

      if (callback) {
        await callback(projectIdPoolLink);
      }
    } catch (e) {
      const errorMessage = getErrorMessage(e);

      setState((s) => ({
        ...s,
        error: errorMessage,
      }));

      Notification({
        type: 'error',
        message: 'Failed to create Project Id Pool Link',
        description: errorMessage,
      });

      if (callback) {
        await callback(null);
      }
    } finally {
      setState((s) => ({
        ...s,
        loading: false,
      }));
    }
  };

  const deleteProjectIdPoolLink: DeleteProjectIdPoolLinkFunction = async (id, callback) => {
    setState((s) => ({
      ...s,
      loading: true,
      error: null,
    }));

    try {
      cancelTokenSource.current = makeCancelTokenSource();

      const deletedProjectIdPoolLink = await api.deleteProjectIdPoolLink(id, {
        cancelToken: cancelTokenSource.current.token,
      });

      if (deletedProjectIdPoolLink.isCancelledByClient) {
        return;
      }

      setState((s) => ({
        ...s,
        projectIdPoolLinks: s.projectIdPoolLinks.filter((projectIdPoolLink) => projectIdPoolLink.id !== id),
        projectIdPoolLink: s.projectIdPoolLink && s.projectIdPoolLink.id === id ? null : s.projectIdPoolLink,
      }));

      if (callback) {
        await callback(deletedProjectIdPoolLink);
      }
    } catch (e) {
      const errorMessage = getErrorMessage(e);

      setState((s) => ({
        ...s,
        error: errorMessage,
      }));

      Notification({
        type: 'error',
        message: 'Failed to delete Project Id Pool Link',
        description: errorMessage,
      });

      if (callback) {
        await callback(null);
      }
    } finally {
      setState((s) => ({
        ...s,
        loading: false,
      }));
    }
  };

  const resetProjectIdPoolLinks = (): void => {
    if (cancelTokenSource.current) {
      cancelTokenSource.current.cancel();
      cancelTokenSource.current = null;
    }
    setState(getInitialState);
  };

  return (
    <ProjectIdPoolLinksContext.Provider
      value={{
        projectIdPoolLinks: state.projectIdPoolLinks,
        projectIdPoolLink: state.projectIdPoolLink,
        loading: state.loading,
        error: state.error,
        getProjectIdPoolLinks,
        getProjectIdPoolLink,
        createProjectIdPoolLink,
        deleteProjectIdPoolLink,
        resetProjectIdPoolLinks,
      }}
      {...props}
    />
  );
};

const useProjectIdPoolLinks = (): Context => React.useContext(ProjectIdPoolLinksContext);

export { ProjectIdPoolLinksProvider, useProjectIdPoolLinks };
