import React, { FC, useState, createContext } from 'react';

import { uploadsApi } from 'api';
import { loadAllPages } from 'utils/functions';
import * as issues from 'utils/issues';
import Notification from 'utils/notification';

export interface IUpload {
  id: string;
  parentId: string;
  parentType: string;
  fileName: string;
  fileKey: string | null;
  fileSize: string | null;
  fileType: string;
  tag: string | null;
  locale: string;
  userId: string | null;
  status: string;
  notes: string | null;
  createdAt: string;
  updatedAt: string | null;
  deletedAt: string | null;
  fileUrl?: string;
  uploadInfo?: string;
  textConfig: Record<string, any>[] | null;
}

interface State {
  uploads: IUpload[];
  upload: IUpload | null;
  loading: boolean;
  error: string | null;
}

interface Options {
  skipStateUpdate?: boolean;
  manualLoading?: boolean;
}

interface GetUploadsParameters {
  parentIds?: string[];
  statuses?: string[];
}

type SingleResultCallback = (upload: IUpload | null) => Promise<void> | void;
type MultipleResultsCallback = (upload: IUpload[] | null) => Promise<void> | void;

type GetUploadOptions = Options & { hydration?: string[] };

type GetUploadsFunction = (
  params?: GetUploadsParameters,
  options?: Options,
  callback?: MultipleResultsCallback
) => Promise<void>;
type GetUploadFunction = (id: string, options?: GetUploadOptions, callback?: SingleResultCallback) => Promise<void>;
type CreateUploadFunction = (
  data: uploadsApi.ICreateUpload,
  options?: Options,
  callback?: SingleResultCallback
) => Promise<void>;
type UpdateUploadFunction = (
  id: string,
  data: uploadsApi.IUpdateUpload,
  options?: Options,
  callback?: SingleResultCallback
) => Promise<void>;
type DeleteUploadFunction = (id: string, options?: Options, callback?: SingleResultCallback) => Promise<void>;
type ResetUploadsFunction = () => void;

export interface Context extends State {
  getUploads: GetUploadsFunction;
  getUpload: GetUploadFunction;
  createUpload: CreateUploadFunction;
  updateUpload: UpdateUploadFunction;
  deleteUpload: DeleteUploadFunction;
  resetUploads: ResetUploadsFunction;
}

const getState = (): State => ({ uploads: [], upload: null, loading: false, error: null });

const UploadsContext = createContext<Context>({
  ...getState(),
  getUploads: async () => {},
  getUpload: async () => {},
  createUpload: async () => {},
  updateUpload: async () => {},
  deleteUpload: async () => {},
  resetUploads: () => {},
});

const UploadsProvider: FC = (props: any) => {
  const [state, setState] = useState<State>(getState);

  const getUploads: GetUploadsFunction = async (params = {}, options = {}, callback?) => {
    const skipStateUpdate = options.skipStateUpdate === true;
    const manualLoading = options.manualLoading === true;

    if (!skipStateUpdate) {
      setState((s) => ({
        ...s,
        loading: manualLoading ? s.loading : true,
        error: null,
      }));
    }

    try {
      const query: Record<string, string> = {};

      if (Array.isArray(params.parentIds) && params.parentIds.length > 0) {
        query.parentIds = params.parentIds.join(',');
      }

      if (Array.isArray(params.statuses) && params.statuses.length > 0) {
        query.statuses = params.statuses.join(',');
      }

      const items = await loadAllPages<any, any>(uploadsApi.getUploads, query, {
        pageLength: 100,
        sortBy: 'createdAt',
        isDescending: false,
      });

      if (!skipStateUpdate) {
        setState((s) => ({
          ...s,
          uploads: items,
        }));
      }

      if (callback) {
        await callback(items);
      }
    } catch (e) {
      const error = issues.getErrorMessage(e);

      if (!skipStateUpdate) {
        setState((s) => ({
          ...s,
          error,
        }));
      }

      Notification({
        type: 'error',
        message: 'Cannot get uploads',
        description: error,
      });

      if (callback) {
        await callback(null);
      }
    } finally {
      if (!skipStateUpdate) {
        setState((s) => ({
          ...s,
          loading: manualLoading ? s.loading : false,
        }));
      }
    }
  };

  const getUpload: GetUploadFunction = async (id: string, options = {}, callback?) => {
    const skipStateUpdate = options.skipStateUpdate === true;
    const manualLoading = options.manualLoading === true;

    if (!skipStateUpdate) {
      setState((s) => ({
        ...s,
        loading: manualLoading ? s.loading : true,
        error: null,
      }));
    }

    try {
      const item = await uploadsApi.getUpload(id);

      if (!skipStateUpdate) {
        setState((s) => ({
          ...s,
          upload: item,
        }));
      }

      if (callback) {
        await callback(item);
      }
    } catch (e) {
      const error = issues.getErrorMessage(e);

      if (!skipStateUpdate) {
        setState((s) => ({
          ...s,
          error,
        }));
      }

      Notification({
        type: 'error',
        message: 'Cannot get upload',
        description: error,
      });

      if (callback) {
        await callback(null);
      }
    } finally {
      if (!skipStateUpdate) {
        setState((s) => ({
          ...s,
          loading: manualLoading ? s.loading : false,
        }));
      }
    }
  };

  const createUpload: CreateUploadFunction = async (data, options = {}, callback?) => {
    const skipStateUpdate = options.skipStateUpdate === true;
    const manualLoading = options.manualLoading === true;

    if (!skipStateUpdate) {
      setState((s) => ({
        ...s,
        loading: manualLoading ? s.loading : true,
        error: null,
      }));
    }

    try {
      const item = await uploadsApi.createUpload(data);

      if (!skipStateUpdate) {
        setState((s) => ({
          ...s,
          uploads: [...s.uploads, item],
        }));
      }

      if (callback) {
        await callback(item);
      }
    } catch (e) {
      const error = issues.getErrorMessage(e);

      if (!skipStateUpdate) {
        setState((s) => ({
          ...s,
          error,
        }));
      }

      Notification({
        type: 'error',
        message: 'Upload was not created',
        description: error,
      });

      if (callback) {
        await callback(null);
      }
    } finally {
      if (!skipStateUpdate) {
        setState((s) => ({
          ...s,
          loading: manualLoading ? s.loading : false,
        }));
      }
    }
  };

  const updateUpload: UpdateUploadFunction = async (id, data, options = {}, callback?) => {
    const skipStateUpdate = options.skipStateUpdate === true;
    const manualLoading = options.manualLoading === true;

    if (!skipStateUpdate) {
      setState((s) => ({
        ...s,
        loading: manualLoading ? s.loading : true,
        error: null,
      }));
    }

    try {
      const updatedItem = await uploadsApi.updateUpload(id, data);

      const items = [...state.uploads];
      const foundItemIndex = items.findIndex((item) => item.id === id);
      if (foundItemIndex > -1) {
        items[foundItemIndex] = updatedItem;
      }

      if (!skipStateUpdate) {
        setState((s) => ({
          ...s,
          uploads: items,
        }));
      }

      if (callback) {
        await callback(updatedItem);
      }
    } catch (e) {
      const error = issues.getErrorMessage(e);

      if (!skipStateUpdate) {
        setState((s) => ({
          ...s,
          error,
        }));
      }

      Notification({
        type: 'error',
        message: 'Upload was not updated',
        description: error,
      });

      if (callback) {
        await callback(null);
      }
    } finally {
      if (!skipStateUpdate) {
        setState((s) => ({
          ...s,
          loading: manualLoading ? s.loading : false,
        }));
      }
    }
  };

  const deleteUpload: DeleteUploadFunction = async (id: string, options = {}, callback?) => {
    const skipStateUpdate = options.skipStateUpdate === true;
    const manualLoading = options.manualLoading === true;

    if (!skipStateUpdate) {
      setState((s) => ({
        ...s,
        loading: manualLoading ? s.loading : true,
        error: null,
      }));
    }

    try {
      const deletedItem = await uploadsApi.deleteUpload(id);

      const items = [...state.uploads].filter((item) => item.id !== id);

      if (!skipStateUpdate) {
        setState((s) => ({
          ...s,
          uploads: items,
        }));
      }

      if (callback) {
        await callback(deletedItem);
      }
    } catch (e) {
      const error = issues.getErrorMessage(e);

      if (!skipStateUpdate) {
        setState((s) => ({
          ...s,
          error,
        }));
      }

      Notification({
        type: 'error',
        message: 'Upload was not deleted',
        description: error,
      });

      if (callback) {
        await callback(null);
      }
    } finally {
      if (!skipStateUpdate) {
        setState((s) => ({
          ...s,
          loading: manualLoading ? s.loading : false,
        }));
      }
    }
  };

  const resetUploads: ResetUploadsFunction = () => {
    setState(getState());
  };

  return (
    <UploadsContext.Provider
      value={{
        uploads: state.uploads,
        upload: state.upload,
        loading: state.loading,
        error: state.error,
        getUploads,
        getUpload,
        createUpload,
        updateUpload,
        deleteUpload,
        resetUploads,
      }}
      {...props}
    />
  );
};

const useUploads = (): Context => React.useContext(UploadsContext);

export { UploadsProvider, useUploads };
