import React, { FC, useEffect, useState } from 'react';

import { Table } from 'antd';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';

import { MenuOutlined } from 'components/icons';

import './SortableTable.less';
import { SizeType } from 'antd/lib/config-provider/SizeContext';

const arrayMoveMutate = (array: any[], from: number, to: number): void => {
  const startIndex = from < 0 ? array.length + from : from;

  if (startIndex >= 0 && startIndex < array.length) {
    const endIndex = to < 0 ? array.length + to : to;

    const [item] = array.splice(from, 1);
    array.splice(endIndex, 0, item);
  }
};

const arrayMove = (array: any[], from: number, to: number): any[] => {
  const newArray = [...array];
  arrayMoveMutate(newArray, from, to);
  return newArray;
};

const DragHandle = SortableHandle(() => <MenuOutlined style={{ cursor: 'pointer', color: '#999' }} />);

const Item = SortableElement((props: any) => <tr {...props} />);
const Container = SortableContainer((props: any) => <tbody {...props} />);

interface ISortableTable {
  columns: any[];
  data: any[];
  onSort: (data: any) => void;
  size?: SizeType;
  datatestId?: string;
}

const SortableTable: FC<ISortableTable> = ({ columns, data, size = 'medium' as SizeType, onSort, datatestId }) => {
  const [localData, setLocalData] = useState<any[]>([]);
  const [localColumns, setLocalColumns] = useState<any[]>([]);

  useEffect(() => {
    setLocalData(data.map((item, index) => ({ ...item, index })));
  }, [data]);

  useEffect(() => {
    if (columns) {
      setLocalColumns([
        {
          title: 'Sort',
          dataIndex: 'viewOrder',
          width: 30,
          className: 'drag-visible',
          render: () => <DragHandle />,
        },
        ...columns,
      ]);
    }
  }, [columns]);

  const onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }): void => {
    if (oldIndex !== newIndex) {
      const newData = arrayMove(localData, oldIndex, newIndex).filter((el) => !!el);
      setLocalData(newData);
      onSort(newData);
    }
  };

  const DraggableContainer = (props: any) => (
    <Container useDragHandle disableAutoscroll helperClass="row-dragging" onSortEnd={onSortEnd} {...props} />
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const DraggableBodyRow = ({ className, style, ...restProps }: any) => {
    const index = localData.findIndex((x) => x.index === restProps['data-row-key']);
    return <Item index={index} {...restProps} />;
  };

  return (
    <Table
      data-testid={datatestId}
      pagination={false}
      dataSource={localData}
      columns={localColumns}
      rowKey="index"
      components={{
        body: {
          wrapper: DraggableContainer,
          row: DraggableBodyRow,
        },
      }}
      size={size}
    />
  );
};

export default SortableTable;
