/* eslint-disable no-await-in-loop */
import React, { FC, useState, createContext, useRef } from 'react';

import moment from 'moment';

import { devicesApi } from 'api';
import { IPatient } from 'context/patients';
import { makeCancelTokenSource, CancelTokenSource } from 'services';
import { isRequestCancelled } from 'services/httpClient';
import { loadAllPages, loadByIdsInBatches, normalizePagingResponse } from 'utils/functions';
import * as issues from 'utils/issues';
import Notification from 'utils/notification';
import { PagingInfo, PagingRequest } from 'utils/types';
import { Order } from '@tassoinc/core-api-client';
import { Laboratory } from './laboratories';
import { ILabOrder } from './labOrders';

/**
 * @param date {string} date as ISO formatted string. E.g., `2021-04-12T00:00:00.000Z`
 * @returns {string} date formatted as the `YYYY-MM-DD` string
 */
export const formatDate = (date: string) => {
  if (/^\d{4}-\d{2}-\d{2}$/.test(date)) {
    return date;
  }
  return moment(date).utc().format('YYYY-MM-DD');
};

export const formatDevice = (data: any): IDevice => ({
  ...data,
  lot: '',
  expiresAt: data.expiresAt ? formatDate(data.expiresAt) : '',
  createdAt: data.createdAt ? formatDate(data.createdAt) : '',
});

const sortByCreatedAt = (a: IDevice, b: IDevice): number => {
  const d1 = new Date(a.createdAt).getTime();
  const d2 = new Date(b.createdAt).getTime();

  if (d1 === d2) {
    return 0;
  }

  return d1 > d2 ? -1 : 1;
};

export interface IDevice {
  id: string;
  hrid: string;
  createdAt: string;
  updatedAt: string;
  deviceLotId: string;
  patientId: string;
  laboratoryId: string;
  laboratory: Laboratory | null;
  customerId: string;
  projectId: string;
  labOrderId: string;
  labOrder: ILabOrder | null;
  status: string;
  arrivedAtPatientAt: string;
  satisfiedPatientReminderThresholds: number[];
  lastTrackingUpdate: string;
  hasLabels: boolean;
  statusChangedAt: string;
  barcode: string;
  expiresAt: string;
  trackingNumberToPatient: string;
  trackingNumberToLab: string;
  replacementReason: string;
  replacementDescription: string;
  replacementHarmCaused: boolean;
  orderId: string | null;
  order?: Order;
  orderTemplateId: string | null;
  npi: string;
  providerFirstName: string;
  providerLastName: string;
  isReplaced: boolean;
  replacesDeviceId: string;
  activationCode: string;
  patientExperienceStatus: string;
  shipmentCode: string | null;
  deviceTypeInfo: DeviceTypeInfo | null;
}
type DeviceTypeInfo = {
  key: string | null;
  description: string | null;
  kitItemType: string | null;
  partNumber: string | null;
};
type State = {
  devices: IDevice[];
  device: IDevice | null;
  loading: boolean;
  error: string | null;
};

export interface IDeviceDetails {
  device: IDevice;
  patient: IPatient;
  events: any[];
}

interface LoadDevicesParameters {
  ids?: string[];
  projectId: string;
  patientIds?: string[];
  orderIds?: string[];
  patientIdNull?: boolean;
  statuses?: string[];
  sortBy: string;
  sortOrder: 'asc' | 'desc';
  page: number;
  pageLength: number;
  includeTotalCount: boolean;
  search: { by: string; term: string } | null;
  headers?: Record<string, string>;
  skipStateUpdate?: boolean;
}

interface LoadPatientDevicesParameters {
  patientIds: string[];
}

type LoadDevicesCallback = (devices: IDevice[] | null, paging: PagingInfo | null) => Promise<void>;
type LoadPatientDevicesCallback = (devices: IDevice[] | null) => Promise<void>;

type LoadDevicesFunction = (params: LoadDevicesParameters, callback?: LoadDevicesCallback) => Promise<void>;

interface AddDeviceParameters {
  projectId?: string;
  patientId?: string;
  status?: string;
  laboratoryId?: string;
  deviceType?: string;
  barcode?: string;
}
type AddDeviceCallback = (device: IDevice | null) => Promise<void>;
type AddDeviceFunction = (
  data: AddDeviceParameters,
  callback?: AddDeviceCallback,
  showNotification?: boolean,
  manualLoading?: boolean,
  updateDeviceList?: boolean
) => Promise<boolean>;

type Context = State & {
  loadDevices: LoadDevicesFunction;
  loadPatientDevices: (params: LoadPatientDevicesParameters, callback?: LoadPatientDevicesCallback) => Promise<void>;
  getDevices: (params?: any, callback?: any, manualLoading?: boolean) => Promise<void>;
  getDevice: (id: string, fromApi?: boolean, callback?: any) => Promise<void>;
  addDevice: AddDeviceFunction;
  updateDevice: (
    id: string,
    data: Record<string, any>,
    callback?: any,
    showNotification?: boolean,
    manualLoading?: boolean
  ) => Promise<void>;
  deleteDevice: (id: string, callback?: any, showNotification?: boolean, manualLoading?: boolean) => Promise<void>;
  resetDevices: () => void;
  resetDevice: () => void;
  addDeviceData: (devices: IDevice[]) => void;
  modifyDeviceData: (deviceId: string, data: Record<string, any>, fullReset?: boolean) => void;
  removeDeviceData: (deviceId: string) => void;
  setLoading: (isLoading: boolean) => void;
  clearCancelledDevice: (cancelledDeviceId: string) => void;
};

const getInitialState = (): State => ({
  devices: [],
  device: null,
  loading: false,
  error: null,
});

const DevicesContext = createContext<Context>({
  ...getInitialState(),
  loadDevices: async () => {},
  loadPatientDevices: async () => {},
  getDevices: async () => {},
  getDevice: async () => {},
  addDevice: async (): Promise<boolean> => false,
  updateDevice: async () => {},
  deleteDevice: async () => {},
  resetDevices: () => {},
  resetDevice: () => {},
  addDeviceData: () => {},
  modifyDeviceData: () => {},
  removeDeviceData: () => {},
  setLoading: () => {},
  clearCancelledDevice: () => {},
});

const DevicesProvider: FC<any> = (props) => {
  const [state, setState] = useState<State>(getInitialState());
  const cancelTokenSource = useRef<CancelTokenSource | null>(null);

  const loadDevices: LoadDevicesFunction = async (params, callback) => {
    setState((s) => ({
      ...s,
      error: null,
      loading: true,
    }));

    try {
      cancelTokenSource.current = makeCancelTokenSource();

      const sortBy = params.sortBy || 'createdAt';
      const isDescending = params.sortOrder !== 'asc';

      const query: Record<string, string> = {};

      if (params.projectId) {
        query.projectIds = params.projectId;
      }

      if (params.patientIds) {
        query.patientIds = params.patientIds.join(',');
      }

      if (params.orderIds) {
        query.orderIds = params.orderIds.join(',');
      }

      if (params.statuses) {
        query.statuses = params.statuses.join(',');
      }

      if (params.search) {
        query[params.search.by] = params.search.term;
      }

      if (typeof params.patientIdNull !== 'undefined') {
        query.patientIdNull = `${params.patientIdNull}`;
      }

      if (params.ids) {
        query.ids = params.ids.join(',');
      }

      const paging: PagingRequest = {
        page: params.page,
        pageLength: params.pageLength,
        sortBy,
        isDescending,
        includeTotalCount: params.includeTotalCount,
      };

      const data = await devicesApi.loadDevices({
        query,
        paging,
        configOverride: { cancelToken: cancelTokenSource.current.token, headers: params.headers || {} },
      });

      if (isRequestCancelled(data)) {
        // requests cancelled by client, don't do anything to state
        // component unmount logic should take care of state resetting
        return;
      }

      const formattedDevices = data.results.map(formatDevice);

      if (!params.skipStateUpdate) {
        setState((s) => ({
          ...s,
          devices: formattedDevices,
        }));
      }

      if (callback) {
        await callback(formattedDevices, normalizePagingResponse(data.paging));
      }
    } catch (e) {
      cancelTokenSource.current = null;

      const errorMessage = issues.getErrorMessage(e);

      setState((s) => ({
        ...s,
        error: errorMessage,
      }));

      Notification({
        type: 'error',
        message: 'Cannot load devices',
        description: errorMessage,
      });

      if (callback) {
        await callback(null, null);
      }
    }

    setState((s) => ({
      ...s,
      loading: false,
    }));
  };

  const loadPatientDevices = async (
    params: LoadPatientDevicesParameters,
    callback?: LoadPatientDevicesCallback
  ): Promise<void> => {
    setState((s) => ({
      ...s,
      error: null,
      loading: true,
    }));

    try {
      cancelTokenSource.current = makeCancelTokenSource();

      const { patientIds } = params;

      const promises: Promise<any>[] = [];
      const data: IDevice[][] = [];

      type PayloadType = Parameters<typeof devicesApi.getDevices>[0];

      for (let i = 0; i < patientIds.length; i += 50) {
        const patientIdsString = patientIds.slice(i, i + 50).join(',');

        promises.push(
          loadAllPages<IDevice, PayloadType>(
            devicesApi.getDevices,
            { patientIds: patientIdsString },
            {
              pageLength: 1000,
              sortBy: 'createdAt',
              isDescending: true,
            },
            { cancelToken: cancelTokenSource.current.token }
          )
        );

        if (promises.length === 5) {
          const promisesToResolve = promises.splice(0, promises.length);
          data.push(...(await Promise.all(promisesToResolve)));
        }
      }

      if (promises.length > 0) {
        const promisesToResolve = promises.splice(0, promises.length);
        data.push(...(await Promise.all(promisesToResolve)));
      }

      const results = data.reduce((acc, val) => [...acc, ...val], []);

      const formattedDevices = results.map(formatDevice).sort(sortByCreatedAt);

      setState((s) => ({
        ...s,
        devices: formattedDevices,
      }));

      if (callback) {
        await callback(formattedDevices);
      }
    } catch (e) {
      cancelTokenSource.current = null;

      const errorMessage = issues.getErrorMessage(e);

      setState((s) => ({
        ...s,
        error: errorMessage,
      }));

      Notification({
        type: 'error',
        message: 'Cannot load devices',
        description: errorMessage,
      });

      if (callback) {
        await callback(null);
      }
    }

    setState((s) => ({
      ...s,
      loading: false,
    }));
  };

  const getDevices = async (params: Record<string, string>, callback?: any, manualLoading?: boolean): Promise<void> => {
    try {
      cancelTokenSource.current = makeCancelTokenSource();

      setState((s) => ({
        ...s,
        error: null,
        loading: manualLoading ? s.loading : true,
      }));

      const devices: IDevice[] = [];

      if (typeof params.patientIds === 'string') {
        const { patientIds, ...otherParams } = params;
        const allPatientIds = patientIds !== '' ? [...new Set(patientIds.split(','))] : [];

        const results = await loadByIdsInBatches<IDevice>(
          devicesApi.getDevices,
          'patientIds',
          allPatientIds,
          otherParams,
          false,
          { cancelToken: cancelTokenSource.current.token }
        );

        devices.push(...results);
      } else {
        type PayloadType = Parameters<typeof devicesApi.getDevices>[0];
        const results = await loadAllPages<IDevice, PayloadType>(
          devicesApi.getDevices,
          params,
          {
            pageLength: 100,
            sortBy: 'createdAt',
            isDescending: true,
          },
          { cancelToken: cancelTokenSource.current.token }
        );
        devices.push(...results);
      }

      setState((s) => ({
        ...s,
        devices: devices.map(formatDevice),
        loading: manualLoading ? s.loading : false,
      }));

      if (callback) {
        await callback(devices);
      }
    } catch (e) {
      cancelTokenSource.current = null;

      const errorMessage = issues.getErrorMessage(e);

      setState((s) => ({
        ...s,
        loading: false,
        error: errorMessage,
      }));

      Notification({
        type: 'error',
        message: 'Cannot get devices list',
        description: errorMessage,
      });
    }
  };

  const getDevice = async (id: string, fromApi = false, callback?: any): Promise<void> => {
    try {
      let result: any;

      if (fromApi) {
        result = await devicesApi.getDevice(id);
      } else {
        result = state.devices.find((d) => d.id === id) || null;
      }

      setState((prevState) => ({
        ...prevState,
        device: result,
      }));

      if (callback) {
        await callback(result);
      }
    } catch (e) {
      const errorMessage = issues.getErrorMessage(e);

      setState((prevState) => ({
        ...prevState,
        loading: false,
        error: errorMessage,
      }));

      Notification({
        type: 'error',
        message: 'Cannot get device details',
        description: errorMessage,
      });
    }
  };

  const addDevice: AddDeviceFunction = async (
    data,
    callback,
    showNotification = true,
    manualLoading = false,
    updateDeviceList = true
  ): Promise<boolean> => {
    setState((s) => ({
      ...s,
      loading: manualLoading ? s.loading : true,
      error: null,
    }));

    try {
      const newDevice = (await devicesApi.addDevice(data)) as IDevice;

      if (updateDeviceList) {
        setState((s) => ({
          ...s,
          devices: [...s.devices, formatDevice(newDevice)].sort(sortByCreatedAt),
          loading: manualLoading ? s.loading : false,
        }));
      } else {
        setState((s) => ({
          ...s,
          loading: manualLoading ? s.loading : false,
        }));
      }

      if (showNotification) {
        Notification({
          type: 'success',
          message: 'New device was created!',
        });
      }

      if (callback) {
        await callback(newDevice);
      }
      return true;
    } catch (e) {
      const errorMessage = issues.getErrorMessage(e);

      setState((s) => ({
        ...s,
        error: errorMessage,
      }));

      Notification({
        type: 'error',
        message: 'Cannot create device',
        description: errorMessage,
      });

      if (callback) {
        await callback(null);
      }
      return false;
    } finally {
      setState((s) => ({
        ...s,
        loading: manualLoading ? s.loading : true,
      }));
    }
  };

  const updateDevice = async (
    id: string,
    data: Record<string, any>,
    callback?: any,
    showNotification = false,
    manualLoading = false
  ): Promise<void> => {
    try {
      setState((s) => ({
        ...s,
        loading: manualLoading ? s.loading : true,
        error: null,
      }));

      const device = await devicesApi.updateDevice(id, data);
      const formattedDevice = formatDevice(device);

      setState((s) => ({
        ...s,
        devices: s.devices.map((d) => (d.id === id ? { ...formattedDevice } : d)).sort(sortByCreatedAt),
        device: s.device && s.device.id === id ? { ...formattedDevice } : s.device,
        loading: manualLoading ? s.loading : false,
      }));

      if (showNotification) {
        Notification({ type: 'success', message: 'Device was successfully updated' });
      }

      if (callback) {
        await callback(device);
      }
    } catch (e) {
      const errorMessage = issues.getErrorMessage(e);

      setState((s) => ({
        ...s,
        loading: manualLoading ? s.loading : false,
        error: errorMessage,
      }));

      Notification({
        type: 'error',
        message: 'Device was not updated',
        description: errorMessage,
      });
    }
  };

  const deleteDevice = async (
    id: string,
    callback?: any,
    showNotification = false,
    manualLoading = false
  ): Promise<void> => {
    try {
      setState((s) => ({
        ...s,
        loading: manualLoading ? s.loading : true,
        error: null,
      }));

      const deletedDevice = await devicesApi.deleteDevice(id);

      setState((s) => ({
        ...s,
        devices: s.devices.filter((d) => d.id !== id),
        device: s.device && s.device.id === id ? null : s.device,
        loading: manualLoading ? s.loading : false,
      }));

      if (showNotification) {
        Notification({ type: 'success', message: 'Device successfully deleted' });
      }

      if (callback) {
        await callback(formatDevice(deletedDevice));
      }
    } catch (e) {
      const errorMessage = issues.getErrorMessage(e);

      setState((s) => ({
        ...s,
        loading: manualLoading ? s.loading : false,
        error: errorMessage,
      }));

      Notification({
        type: 'error',
        message: 'Device was not deleted',
        description: errorMessage,
      });
    }
  };

  const addDeviceData = (devices: IDevice[]): void => {
    setState((s) => ({ ...s, devices: [...s.devices, ...devices] }));
  };

  const clearCancelledDevice = (cancelledDeviceId: string): void => {
    setState((s) => ({
      ...s,
      devices: s.devices.filter((d) => cancelledDeviceId !== d.id),
      device: s.device && cancelledDeviceId === s.device.id ? null : s.device,
    }));
  };

  const modifyDeviceData = (deviceId: string, data: Record<string, any>, fullReset = false): void => {
    const deviceIndex = state.devices.findIndex((d) => d.id === deviceId);

    if (deviceIndex === -1) {
      return;
    }

    const newDevice = fullReset ? formatDevice(data) : { ...state.devices[deviceIndex], ...data };

    setState((s) => ({ ...s, devices: s.devices.map((d, idx) => (idx === deviceIndex ? newDevice : d)) }));
  };

  const removeDeviceData = (id: string): void => {
    setState((s) => ({ ...s, devices: s.devices.filter((d) => d.id !== id) }));
  };

  const resetDevices = (): void => {
    if (cancelTokenSource.current) {
      cancelTokenSource.current.cancel();
      cancelTokenSource.current = null;
    }
    setState(getInitialState());
  };

  const resetDevice = (): void => {
    setState((s) => ({
      ...s,
      device: null,
    }));
  };

  const setLoading = (isLoading: boolean): void => {
    setState((s) => ({ ...s, loading: isLoading }));
  };

  return (
    <DevicesContext.Provider
      value={{
        devices: state.devices,
        device: state.device,
        loading: state.loading,
        error: state.error,
        loadDevices,
        loadPatientDevices,
        getDevices,
        getDevice,
        addDevice,
        updateDevice,
        deleteDevice,
        resetDevices,
        resetDevice,
        addDeviceData,
        modifyDeviceData,
        removeDeviceData,
        setLoading,
        clearCancelledDevice,
      }}
      {...props}
    />
  );
};

const useDevices = (): Context => React.useContext(DevicesContext);

export { DevicesProvider, useDevices };
