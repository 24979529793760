import MD5 from 'md5.js';

export const MAX_DISCLAIMER_AGE_MS = 2_592_000_000; // 30 days in milliseconds
export const DISCLAIMER_STORAGE_PREFIX = 'LoginDisclaimer.';

export const makeDisclaimerKey = (email: string): string => {
  const md5 = new MD5();
  md5.update(email);
  const hash = md5.digest('hex');

  return `${DISCLAIMER_STORAGE_PREFIX}${hash}`;
};

export const clearOldDisclaimerKeys = (): void => {
  const currentTimestampMs = new Date().getTime();

  for (let i = 0; i < localStorage.length; i += 1) {
    const key = localStorage.key(i);
    if (key && key.startsWith(DISCLAIMER_STORAGE_PREFIX)) {
      const item = localStorage.getItem(key);

      if (item) {
        const json = JSON.parse(item);
        const createdAtTimestampMs: number = json.timestamp;
        if (createdAtTimestampMs + MAX_DISCLAIMER_AGE_MS < currentTimestampMs) {
          localStorage.removeItem(key);
        }
      }
    }
  }
};

export const checkDisclaimerAcceptance = (email: string): boolean => {
  const itemKey = makeDisclaimerKey(email);

  const item = localStorage.getItem(itemKey);
  if (!item) {
    return false;
  }

  const json = JSON.parse(item);
  const createdAtTimestampMs: number = json.timestamp;
  const currentTimestampMs = new Date().getTime();

  if (createdAtTimestampMs + MAX_DISCLAIMER_AGE_MS < currentTimestampMs) {
    localStorage.removeItem(itemKey);
    return false;
  }

  return true;
};

export const storeDisclaimerAcceptance = (email: string): void => {
  localStorage.setItem(makeDisclaimerKey(email), JSON.stringify({ timestamp: new Date().getTime() }));
};
