import React, { FC, useState, useEffect } from 'react';

import { Form, Input, Modal } from 'antd';

import { Customer } from 'context/customers';

interface ICustomerModal {
  form: any;
  customer: Customer | null;
  visible: boolean;
  onOk: (customer: any) => void;
  onCancel: () => void;
}

const CustomerModal: FC<ICustomerModal> = ({ form, customer, visible, onOk, onCancel }) => {
  const [confirmLoading, setConfirmLoading] = useState(false);

  useEffect(() => {
    if (visible) {
      setConfirmLoading(false);

      if (!customer) {
        form.resetFields();
      }
    }
  }, [visible]);

  const handleFormSubmit = () => {
    form
      .validateFields()
      .then((values: any) => {
        setConfirmLoading(true);
        onOk(values);
      })
      .catch((info: any) => {
        console.log('Validate Failed:', info);
      });
  };

  return (
    <Modal
      wrapClassName="Modal CustomerModal"
      visible={visible}
      title={customer ? 'Edit Customer' : 'Create Customer'}
      okText={customer ? 'Save' : 'Create'}
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={handleFormSubmit}
      confirmLoading={confirmLoading}
      centered
      width="360px"
      forceRender
    >
      <Form
        className="Form CustomerModal__Form"
        form={form}
        layout="vertical"
        name="CustomerModalForm"
        hideRequiredMark
      >
        <Form.Item name="name" label="Name" rules={[{ required: true, message: 'Please input a name!' }]}>
          <Input placeholder="Enter name" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CustomerModal;
