import { httpClient } from 'services/index';
import { PagingRequest } from 'utils/types';

export const getKitItems = (payload: Record<string, any>, paging: PagingRequest): Promise<any> =>
  httpClient.get(`/kitItems`, { params: { ...payload, ...paging } }).then((_) => _.data);

interface GetKitItemOptions {
  hydration?: string;
}

export const getKitItem = (id: string, options: GetKitItemOptions): Promise<any> => {
  const headers: Record<string, string> = {};

  if (options.hydration) {
    headers['tasso-hydration'] = options.hydration;
  }

  return httpClient.get(`/kitItems/${id}`, { headers }).then((_) => _.data.results);
};

export interface ICreateKitItem {
  key: string;
  partNumber: string;
  description: string;
  kitItemType: string;
}
export const createKitItem = (data: ICreateKitItem): Promise<any> =>
  httpClient.post('/kitItems', data).then((_) => _.data.results);

export const updateKitItem = (id: string, data: Partial<ICreateKitItem>): Promise<any> =>
  httpClient.patch(`/kitItems/${id}`, data).then((_) => _.data.results);

export const deleteKitItem = (id: string): Promise<any> =>
  httpClient.delete(`/kitItems/${id}`).then((_) => _.data.results);
