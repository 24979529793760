/* Nesting is our friend! */
/* https://github.com/facebook/react/issues/14620#issuecomment-455652349 */

import React, { FC } from 'react';
import { AnalytesProvider } from 'context/analytes';
import { AuthProvider } from 'context/auth';
import { ContactsProvider } from 'context/contacts';
import { CustomersProvider } from 'context/customers';
import { CustomerSupportValueProvider } from 'context/customerSupportValue';
import { DeviceEventsProvider } from 'context/deviceEvents';
import { DeviceLotsProvider } from 'context/deviceLots';
import { DevicesProvider } from 'context/devices';
import { DownloadsProvider } from 'context/downloads';
import { KitItemsProvider } from 'context/kitItems';
import { LaboratoriesProvider } from 'context/laboratories';
import { NotificationTemplatesProvider } from 'context/notificationTemplates';
import { PatientsProvider } from 'context/patients';
import { ProjectAnalyteLinksProvider } from 'context/projectAnalyteLinks';
import { ProjectContactsProvider } from 'context/projectContacts';
import { ProjectKitItemsProvider } from 'context/projectKitItems';
import { ProjectLaboratoriesProvider } from 'context/projectLaboratories';
import { ProjectMetricsProvider } from 'context/projectMetrics';
import { ProjectsProvider } from 'context/projects';
import { ShippingLabelsProvider } from 'context/shippingLabels';
import { ShippingLabelsV2Provider } from 'context/shippingLabelsV2';
import { UserFilesProvider } from 'context/userFiles';
import { UsersProvider } from 'context/users';
import { OrderTemplatesProvider } from 'context/orderTemplates';
import { IdPoolsProvider } from 'context/idPools';
import { IdPoolIdentifiersProvider } from 'context/idPoolIdentifiers';
import { IdPoolIdentifierImportsProvider } from 'context/idPoolIdentifierImports';
import { ProjectIdPoolLinksProvider } from 'context/projectIdPoolLinks';
import { OrderProvider } from 'context/orders';
import { ManifestsProvider } from 'context/manifests';
import { ManifestStatusProvider } from 'context/manifestStatus';
import { OneClickPrintStatusProvider } from 'context/oneClickPrint';
import { LabOrderEventsProvider } from 'context/labOrderEvents';
import { LabOrdersProvider } from 'context/labOrders';
import { UploadsProvider } from 'context/uploads';
import { CustomAttributeDefinitionsProvider } from 'context/customAttributeDefinitions';
import { ShipmentsProvider } from 'context/shipments';
import { FulfillmentOrdersProvider } from 'context/fulfillmentOrders';
import { SitesProvider } from 'context/sites';
import { FlagProvider, IConfig } from '@unleash/proxy-client-react';

const providers = [
  AuthProvider,
  ProjectsProvider,
  DeviceLotsProvider,
  DevicesProvider,
  DownloadsProvider,
  UserFilesProvider,
  ContactsProvider,
  ProjectContactsProvider,
  AnalytesProvider,
  KitItemsProvider,
  ProjectKitItemsProvider,
  LaboratoriesProvider,
  ProjectLaboratoriesProvider,
  PatientsProvider,
  CustomersProvider,
  CustomAttributeDefinitionsProvider,
  ShippingLabelsProvider,
  ShippingLabelsV2Provider,
  DeviceEventsProvider,
  LabOrderEventsProvider,
  LabOrdersProvider,
  ProjectMetricsProvider,
  UsersProvider,
  NotificationTemplatesProvider,
  ProjectAnalyteLinksProvider,
  IdPoolsProvider,
  IdPoolIdentifiersProvider,
  IdPoolIdentifierImportsProvider,
  ProjectIdPoolLinksProvider,
  OrderProvider,
  ManifestsProvider,
  ManifestStatusProvider,
  OneClickPrintStatusProvider,
  UploadsProvider,
  OrderTemplatesProvider,
  ShipmentsProvider,
  FulfillmentOrdersProvider,
  SitesProvider,
  CustomerSupportValueProvider,
];

interface AppProvidersProps {
  unleashConfig: IConfig;
}

const AppProviders: FC<AppProvidersProps> = ({ children, unleashConfig }) =>
  providers.reduce(
    (acc, Provider) => <Provider>{acc}</Provider>,
    <FlagProvider config={unleashConfig}>{children}</FlagProvider>
  );

export default AppProviders;
