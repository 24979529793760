/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';

import { Link } from 'react-router-dom';
import { DatePicker, Form, Popconfirm, Switch, Tooltip } from 'antd';

import {
  HomeOutlined,
  SettingOutlined,
  SearchOutlined,
  FileMarkdownOutlined,
  PrinterOutlined,
  RocketOutlined,
} from 'components/icons';
import { ROUTES } from 'constants/index';

import { useOneClickPrint } from 'context/oneClickPrint';
import moment from 'moment';
import { RangePickerProps } from 'antd/lib/date-picker';
import Breadcrumb from '../Breadcrumb';
import { useManifestStatus } from '../../context/manifestStatus';
import { useDownloads } from '../../context/downloads';

interface ISectionHeader {
  breadcrumbNameMap: any;
  isAdmin?: boolean;
  isCustomerService?: boolean;
  isShipper?: boolean;
}

const SectionHeader: React.FC<ISectionHeader> = ({
  breadcrumbNameMap,
  isAdmin = false,
  isCustomerService = false,
  isShipper = false,
}) => {
  const showManifest = isAdmin || isCustomerService || isShipper;
  const showSettingsAndPatientSearch = isAdmin || isCustomerService;
  const showShipmentReport = isAdmin || isShipper;

  const { isManifestEnabled, toggleManifestStatus } = useManifestStatus();
  const { isOneClickPrintEnabled, toggleOneClickPrintEnabled } = useOneClickPrint();
  const { downloadShipmentReport, loading: downloadInProgress } = useDownloads();

  const today = moment();
  const [selectedReportDate, setSelectedReportDate] = useState<moment.Moment | null>(today);

  const disabledDate: RangePickerProps['disabledDate'] = (current) =>
    // Can not select days after today
    current > today.endOf('day');

  const getShipmentReport = () => {
    if (downloadInProgress) {
      return;
    }

    if (!selectedReportDate) {
      return;
    }

    const reportDate = selectedReportDate.format('YYYY-MM-DD');
    downloadShipmentReport(reportDate, 'attachment');
  };

  const actions = [
    <div>
      <span className="manifest_toggle_text">
        <PrinterOutlined />
      </span>
      <Switch
        title="When enabled, printed items will be downloaded."
        style={{ backgroundColor: isOneClickPrintEnabled === true ? '#2e7d07' : '#797979d9' }}
        defaultChecked={isOneClickPrintEnabled === true ? true : undefined}
        onChange={() => toggleOneClickPrintEnabled()}
      />
    </div>,
    ...(showManifest
      ? [
          <div>
            <span className="manifest_toggle_text">Manifest</span>
            <Switch
              title="When enabled, shipping labels generated will apply to a shipping manifest."
              style={{ backgroundColor: isManifestEnabled === true ? '#2e7d07' : '#797979d9' }}
              defaultChecked={isManifestEnabled === true ? true : undefined}
              onChange={() => toggleManifestStatus()}
            />
          </div>,
        ]
      : []),
    showManifest ? (
      <Link to={ROUTES.manifests}>
        <FileMarkdownOutlined />
      </Link>
    ) : (
      ''
    ),
    showShipmentReport ? (
      <Tooltip title="Download the shipment report for a specific day">
        <Popconfirm
          title={
            <Form layout="vertical" fields={[{ name: ['reportDate'], value: selectedReportDate }]}>
              <Form.Item name="reportDate" label="Select Report Date">
                <DatePicker
                  placeholder="YYYY-MM-DD"
                  format="YYYY-MM-DD"
                  style={{ width: '100%' }}
                  showToday
                  showTime={false}
                  allowClear={false}
                  disabledDate={disabledDate}
                  onChange={(value) => {
                    setSelectedReportDate(value);
                  }}
                />
              </Form.Item>
            </Form>
          }
          okText="OK"
          cancelText="Cancel"
          onConfirm={() => {
            getShipmentReport();
            setSelectedReportDate(today);
          }}
          onCancel={() => {
            setSelectedReportDate(today);
          }}
          icon={<div />}
          overlayClassName="shipmentReportPopconfirm"
        >
          <RocketOutlined disabled={downloadInProgress} style={{ color: '#004e92' }} />
        </Popconfirm>
      </Tooltip>
    ) : (
      ''
    ),
    <Link to={ROUTES.projects}>
      <HomeOutlined />
    </Link>,
    ...(showSettingsAndPatientSearch
      ? [
          <Link to={ROUTES.patientSearch}>
            <SearchOutlined />
          </Link>,
          <Link to={ROUTES.settings}>
            <SettingOutlined />
          </Link>,
        ]
      : []),
  ];

  return (
    <div className="SectionHeader">
      <div className="SectionHeader__Breadcrumbs">
        <Breadcrumb breadcrumbNameMap={breadcrumbNameMap} />
      </div>

      <div className="SectionHeader__Actions">
        {actions.map((action, i) =>
          action ? (
            // eslint-disable-next-line react/no-array-index-key
            <div key={i} className="SectionHeader__Action">
              {action}
            </div>
          ) : null
        )}
      </div>
    </div>
  );
};

export default SectionHeader;
