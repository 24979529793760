import React, { FC } from 'react';

import { Modal, Typography } from 'antd';

interface Props {
  show: boolean;
  onAccept: () => void;
  onReject: () => void;
}

const LoginDisclaimer: FC<Props> = ({ show, onAccept, onReject }) => (
  <Modal
    okText="Accept"
    cancelText="Reject"
    onCancel={onReject}
    onOk={onAccept}
    visible={show}
    closable={false}
    centered
  >
    <Typography.Text>
      The application you are accessing contains highly confidential Protected Health Information (PHI). Please make
      sure you are in a private space and using a secure internet connection. By accessing this data, you agree to abide
      by the terms in your confidentiality agreement with Tasso, Inc.
    </Typography.Text>
  </Modal>
);

export default LoginDisclaimer;
