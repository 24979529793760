import { httpClient } from 'services/index';
import { PagingRequest } from 'utils/types';
import { AxiosRequestConfig, CancelToken } from 'axios';

export type RequestOptions = {
  hydration?: string[];
  cancelToken?: CancelToken;
};

const prepareRequest = (existingConfig: AxiosRequestConfig, options?: RequestOptions): AxiosRequestConfig => {
  const req: AxiosRequestConfig = { ...existingConfig };

  if (options) {
    if (options.hydration && options.hydration.length > 0) {
      req.headers = { ...(req.headers || {}), 'tasso-hydration': options.hydration.join(',') };
    }
    if (options.cancelToken) {
      req.cancelToken = options.cancelToken;
    }
  }

  return req;
};

interface CommonParams {
  hydration?: string[];
  cancelToken: CancelToken;
}

export interface CreatePayload extends CommonParams {
  data: {
    idPoolId: string;
    fileName: string;
  };
}

export interface GetAllPayload extends CommonParams {
  query: Record<string, string>;
}

export interface GetByIdPayload extends CommonParams {
  id: string;
}

export interface UpdatePayload extends CommonParams {
  id: string;
  data: Record<string, never>;
}

export interface DeletePayload extends CommonParams {
  id: string;
}

export const createIdPoolIdentifierImport = (params: CreatePayload): Promise<any> => {
  const req = prepareRequest({}, { cancelToken: params.cancelToken, hydration: params.hydration });

  return httpClient.post('/idPoolIdentifierImports', params.data, req).then((_) => _.data.results);
};

export const getIdPoolIdentifierImports = (params: GetAllPayload, paging: PagingRequest): Promise<any> => {
  const req = prepareRequest(
    { params: { ...params.query, ...paging } },
    { hydration: params.hydration, cancelToken: params.cancelToken }
  );

  return httpClient.get('/idPoolIdentifierImports', req).then((_) => _.data);
};

export const getIdPoolIdentifierImport = (params: GetByIdPayload): Promise<any> => {
  const req = prepareRequest({}, { cancelToken: params.cancelToken, hydration: params.hydration });

  return httpClient.get(`/idPoolIdentifierImports/${params.id}`, req).then((_) => _.data.results);
};

export const updateIdPoolIdentifierImport = (params: UpdatePayload): Promise<any> => {
  const req = prepareRequest({}, { cancelToken: params.cancelToken, hydration: params.hydration });

  return httpClient.patch(`/idPoolIdentifierImports/${params.id}`, params.data, req).then((_) => _.data.results);
};

export const deleteIdPoolIdentifierImport = (params: DeletePayload): Promise<any> => {
  const req = prepareRequest({}, { cancelToken: params.cancelToken, hydration: params.hydration });

  return httpClient.delete(`/idPoolIdentifierImports/${params.id}`, req).then((_) => _.data.results);
};
