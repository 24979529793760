import React, { useState } from 'react';

import { Form } from 'antd';

import DeviceBulkEditModal from 'components/DeviceBulkEditModal';
import { useDeviceLots } from 'context/deviceLots';
import { useDevices } from 'context/devices';
import Notification from 'utils/notification';

type Callback = () => Promise<void>;

const useDeviceBulkEditModal: any = () => {
  const { updateDevice, setLoading: setDeviceLoading } = useDevices();
  const { getDeviceLots, addDeviceLot, deviceLots, loading: deviceLotsLoading } = useDeviceLots();
  const [form] = Form.useForm();
  const [isVisible, setIsVisible] = useState(false);
  const [selectedDevices, setSelectedDevices] = useState<string[]>([]);
  const [updating, setUpdating] = useState<boolean>(false);
  const [deviceLotsLoaded, setDeviceLotsLoaded] = useState(false);
  const [submitCallback, setSubmitCallback] = useState<Callback | null>(null);

  const showModal = (deviceIds: string[], cb?: Callback): void => {
    if (!deviceLotsLoaded) {
      getDeviceLots();
      setDeviceLotsLoaded(true);
    }
    setIsVisible(true);
    setSelectedDevices(deviceIds);
    setSubmitCallback(() => cb || null);
  };

  const closeModal = () => {
    setIsVisible(false);
    setUpdating(false);
    setSubmitCallback(null);
    form.resetFields();
  };

  const handleSubmit = async (params: { deviceLotId: string | null; expiresAt: string | null }): Promise<void> => {
    setUpdating(true);
    setDeviceLoading(true);

    for (const id of selectedDevices) {
      // eslint-disable-next-line no-await-in-loop
      await updateDevice(id, params, false, false, true);
    }

    if (submitCallback) {
      await submitCallback();
    }

    setDeviceLoading(false);

    Notification({
      type: 'success',
      message: `${selectedDevices.length} device${selectedDevices.length === 1 ? '' : 's'} successfully updated.`,
    });

    closeModal();
  };

  const handleAddLot = async (key: string): Promise<void> => {
    addDeviceLot(key);
  };

  const Modal = (
    <DeviceBulkEditModal
      lots={deviceLots}
      devices={selectedDevices}
      form={form}
      visible={isVisible}
      loading={updating || deviceLotsLoading}
      onOk={handleSubmit}
      onCancel={closeModal}
      onAddLot={handleAddLot}
      onSelectLot={(deviceLotId) => form.setFieldsValue({ deviceLotId })}
    />
  );

  return { Modal, showModal };
};

export default useDeviceBulkEditModal;
