import React, { FC, useState, useEffect } from 'react';

import { Modal, InputNumber } from 'antd';

type Props = {
  open?: boolean;
  loading: boolean;
  onClose: () => void;
  onSubmit: (count: number) => void;
};

const CreateUnassignedDevicesModal: FC<Props> = ({ open, loading, onClose, onSubmit }) => {
  const [value, setValue] = useState<string>('');

  const handleSubmit = () => {
    if (loading) {
      return;
    }

    const v = parseInt(value, 10);
    if (Number.isNaN(v) || v < 1) {
      return;
    }
    onSubmit(v);
    onClose();
  };

  useEffect(() => {
    setValue('');
  }, [open]);

  return (
    <Modal
      wrapClassName="Modal Normal"
      centered
      width="460px"
      visible={open}
      title="Create unassigned devices"
      onCancel={onClose}
      cancelText="Cancel"
      okText={`Create ${value} device${value === '1' ? '' : 's'}`}
      onOk={handleSubmit}
      confirmLoading={loading}
      okButtonProps={{ disabled: loading || value === '' }}
      cancelButtonProps={{ disabled: loading }}
    >
      <p>Devices created using this modal will be linked to this project but will not be assigned to any patient.</p>
      <p>
        <strong>How many devices should be created?</strong>
      </p>
      <InputNumber
        value={value}
        size="large"
        onKeyUp={(e) => {
          const n = parseInt(e.currentTarget.value, 10);
          setValue(Number.isNaN(n) || n < 1 ? '' : `${n}`);
        }}
        autoFocus
        disabled={loading}
        bordered
        min="1"
        stringMode
      />
    </Modal>
  );
};

export default CreateUnassignedDevicesModal;
