import { httpClient } from 'services/index';
import { PagingRequest } from 'utils/types';

export const getUsers = (params: Record<string, string>, paging: PagingRequest): Promise<any> =>
  httpClient.get('/users', { params: { ...params, ...paging } }).then((_) => _.data);

export const getUser = (id: string): Promise<any> => httpClient.get(`/users/${id}`).then((_) => _.data.results);

export type CreateUserPayload = {
  username: string;
  role: string;
  customerId?: string;
  firstName?: string;
  lastName: string;
  phoneNumber: string;
};

export const createUser = (data: CreateUserPayload): Promise<any> =>
  httpClient.post('/users', data, { headers: { 'tasso-hydration': 'customer' } }).then((_) => _.data.results);

export type UpdateUserPayload = {
  firstName?: string | null;
  lastName?: string | null;
  phoneNumber?: string | null;
  role?: string;
  customerId?: string | null;
  isActive?: boolean;
};
export const updateUser = (id: string, data: UpdateUserPayload): Promise<any> =>
  httpClient.patch(`/users/${id}`, data, { headers: { 'tasso-hydration': 'customer' } }).then((_) => _.data.results);

export const deleteUser = (id: string): Promise<any> => httpClient.delete(`/users/${id}`).then((_) => _.data.results);

export const resetIdentity = (id: string): Promise<void> => httpClient.post(`/users/${id}/identity`);

export const resetTemporaryPassword = (id: string): Promise<void> => httpClient.post(`/users/${id}/temporary-password`);
