import React, { FC, useState, createContext, useRef } from 'react';
import { loadAllPages, normalizePagingResponse } from 'utils/functions';
import { makeCancelTokenSource, CancelTokenSource } from 'services/index';

import { getErrorMessage } from 'utils/issues';
import Notification from 'utils/notification';
import { PagingInfo } from 'utils/types';
import * as api from '../api/idPoolIdentifiers';
import { Customer } from './customers';
import { IDevice } from './devices';
import { IdPool } from './idPools';
import { Project } from './projects';

export type IdPoolIdentifier = {
  id: string;
  idPoolId: string;
  idPool: IdPool;
  customerId: string;
  customer?: Customer;
  deviceId: string;
  device: IDevice;
  projectId: string;
  project?: Project;
  value: string;
  customOrder: number;
  createdAt: string;
  updatedAt: string | null;
};

export type GetIdPoolIdentifiersParams = {
  page: number;
  pageLength: number;
  includeTotalCount: boolean;
  sortBy: string;
  sortOrder: 'asc' | 'desc';
  valueLike?: string;
  idPoolIds?: string[];
  customerIds?: string[];
  projectIds?: string[];
};

type Options = {
  hydrate?: ('customer' | 'idPool' | 'project' | 'device')[];
};

type GetIdPoolIdentifiersFunction = (
  params?: GetIdPoolIdentifiersParams,
  config?: Options,
  callback?: (idPoolIdentifiers: IdPoolIdentifier[] | null, paging?: PagingInfo) => Promise<void>
) => Promise<void>;

type GetIdPoolIdentifierFunction = (
  id: string,
  config?: Options,
  callback?: (idPoolIdentifier: IdPoolIdentifier | null) => Promise<void>
) => Promise<void>;

type CreateIdPoolIdentifierFunction = (
  params: api.CreateIdPoolIdentifierPayload,
  config?: Options,
  callback?: (idPoolIdentifier: IdPoolIdentifier | null) => Promise<void>
) => Promise<void>;

type DeleteIdPoolIdentifierFunction = (
  id: string,
  config: Options,
  callback?: (idPoolIdentifier: IdPoolIdentifier | null) => Promise<void>
) => Promise<void>;

type UpdateIdPoolIdentifierFunction = (
  id: string,
  data: api.UpdateIdPoolIdentifierPayload,
  config?: Options,
  callback?: (idPoolIdentifier: IdPoolIdentifier | null) => Promise<void>
) => Promise<void>;

type State = {
  idPoolIdentifiers: IdPoolIdentifier[];
  idPoolIdentifier: IdPoolIdentifier | null;
  loading: boolean;
  error: string | null;
};

type Context = State & {
  getIdPoolIdentifiers: GetIdPoolIdentifiersFunction;
  getIdPoolIdentifier: GetIdPoolIdentifierFunction;
  createIdPoolIdentifier: CreateIdPoolIdentifierFunction;
  updateIdPoolIdentifier: UpdateIdPoolIdentifierFunction;
  deleteIdPoolIdentifier: DeleteIdPoolIdentifierFunction;
  resetIdPoolIdentifiers: () => void;
};

const getInitialState = (): State => ({
  idPoolIdentifiers: [],
  idPoolIdentifier: null,
  loading: false,
  error: null,
});

const IdPoolIdentifiersContext = createContext<Context>({
  ...getInitialState(),
  getIdPoolIdentifiers: async () => {},
  getIdPoolIdentifier: async () => {},
  createIdPoolIdentifier: async () => {},
  updateIdPoolIdentifier: async () => {},
  deleteIdPoolIdentifier: async () => {},
  resetIdPoolIdentifiers: () => {},
});

const IdPoolIdentifiersProvider: FC<any> = (props) => {
  const [state, setState] = useState<State>(getInitialState);
  const cancelTokenSource = useRef<CancelTokenSource | null>(null);

  const getIdPoolIdentifiers: GetIdPoolIdentifiersFunction = async (params, config, callback) => {
    setState((s) => ({
      ...s,
      loading: true,
      error: null,
    }));

    const page = params?.page || 1;
    const pageLength = params?.pageLength || 20;
    const includeTotalCount =
      params && typeof params.includeTotalCount === 'boolean' ? params.includeTotalCount : false;
    const sortBy = params?.sortBy || 'createdAt';
    const isDescending = params && params.sortOrder ? params.sortOrder === 'desc' : false;

    const query: Record<string, string> = {};

    if (params) {
      if (typeof params.valueLike === 'string') {
        query.valueLike = params.valueLike;
      }

      if (Array.isArray(params.idPoolIds)) {
        query.idPoolIds = params.idPoolIds.join(',');
      }
      if (Array.isArray(params.customerIds)) {
        query.customerIds = params.customerIds.join(',');
      }
      if (Array.isArray(params.projectIds)) {
        query.projectIds = params.projectIds.join(',');
      }
    }

    try {
      cancelTokenSource.current = makeCancelTokenSource();

      const options: api.IdPoolIdentifiersRequestOptions = {
        hydrate: config?.hydrate || [],
        cancelToken: cancelTokenSource.current.token,
      };

      let idPoolIdentifiers: IdPoolIdentifier[];
      let paging: PagingInfo;

      if (pageLength === -1) {
        // Load all data
        type PayloadType = Parameters<typeof api.getIdPoolIdentifiers>[0];
        idPoolIdentifiers = await loadAllPages<IdPoolIdentifier, PayloadType>(
          api.getIdPoolIdentifiers,
          { query, options },
          {
            pageLength: 100,
            sortBy,
            isDescending,
          },
          { cancelToken: cancelTokenSource.current.token }
        );

        paging = {
          page: 1,
          pageLength: idPoolIdentifiers.length,
          totalCount: idPoolIdentifiers.length,
          sortBy,
          sortOrder: isDescending ? 'desc' : 'asc',
        };
      } else {
        const data = await api.getIdPoolIdentifiers(
          { query, options },
          {
            page,
            pageLength,
            sortBy,
            isDescending,
            includeTotalCount,
          }
        );

        if (data.isCancelledByClient) {
          return;
        }

        idPoolIdentifiers = data.results;

        paging = normalizePagingResponse(data.paging);
      }

      setState((s) => ({
        ...s,
        idPoolIdentifiers,
      }));

      if (callback) {
        await callback(idPoolIdentifiers, paging);
      }
    } catch (e) {
      const errorMessage = getErrorMessage(e);

      setState((s) => ({
        ...s,
        error: errorMessage,
      }));

      Notification({
        type: 'error',
        message: 'Failed to load Id Pool Identifiers',
        description: errorMessage,
      });

      if (callback) {
        await callback(null);
      }
    } finally {
      setState((s) => ({
        ...s,
        loading: false,
      }));
    }
  };

  const getIdPoolIdentifier: GetIdPoolIdentifierFunction = async (id, config, callback) => {
    const existingIdPoolIdentifier = state.idPoolIdentifiers.find((idPoolIdentifier) => idPoolIdentifier.id === id);

    if (existingIdPoolIdentifier) {
      if (callback) {
        await callback(existingIdPoolIdentifier);
      }
      return;
    }

    setState((s) => ({
      ...s,
      loading: true,
      error: null,
    }));

    try {
      cancelTokenSource.current = makeCancelTokenSource();

      const options: api.IdPoolIdentifiersRequestOptions = {
        hydrate: config?.hydrate || [],
        cancelToken: cancelTokenSource.current.token,
      };

      const idPoolIdentifier = await api.getIdPoolIdentifier(id, options);

      if (idPoolIdentifier.isCancelledByClient) {
        return;
      }

      setState((s) => ({
        ...s,
        idPoolIdentifier,
      }));

      if (callback) {
        await callback(idPoolIdentifier);
      }
    } catch (e) {
      const errorMessage = getErrorMessage(e);

      setState((s) => ({
        ...s,
        error: errorMessage,
      }));

      Notification({
        type: 'error',
        message: 'Failed to get Id Pool Identifier details',
        description: errorMessage,
      });

      if (callback) {
        await callback(null);
      }
    } finally {
      setState((s) => ({
        ...s,
        loading: false,
      }));
    }
  };

  const createIdPoolIdentifier: CreateIdPoolIdentifierFunction = async (data, config, callback) => {
    setState((s) => ({
      ...s,
      loading: true,
      error: null,
    }));

    try {
      cancelTokenSource.current = makeCancelTokenSource();

      const options: api.IdPoolIdentifiersRequestOptions = {
        hydrate: config?.hydrate || [],
        cancelToken: cancelTokenSource.current.token,
      };

      const idPoolIdentifier = await api.createIdPoolIdentifier(data, options);

      if (idPoolIdentifier.isCancelledByClient) {
        return;
      }

      setState((s) => ({
        ...s,
        idPoolIdentifiers: [...s.idPoolIdentifiers, idPoolIdentifier],
      }));

      if (callback) {
        await callback(idPoolIdentifier);
      }
    } catch (e) {
      const errorMessage = getErrorMessage(e);

      setState((s) => ({
        ...s,
        error: errorMessage,
      }));

      Notification({
        type: 'error',
        message: 'Failed to create Id Pool Identifier',
        description: errorMessage,
      });

      if (callback) {
        await callback(null);
      }
    } finally {
      setState((s) => ({
        ...s,
        loading: false,
      }));
    }
  };

  const updateIdPoolIdentifier: UpdateIdPoolIdentifierFunction = async (id, data, config, callback) => {
    setState((s) => ({
      ...s,
      loading: true,
      error: null,
    }));

    try {
      cancelTokenSource.current = makeCancelTokenSource();

      const options: api.IdPoolIdentifiersRequestOptions = {
        hydrate: config?.hydrate || [],
        cancelToken: cancelTokenSource.current.token,
      };

      const updatedIdPoolIdentifier = await api.updateIdPoolIdentifier(id, data, options);

      if (updatedIdPoolIdentifier.isCancelledByClient) {
        return;
      }

      setState((s) => ({
        ...s,
        idPoolIdentifiers: s.idPoolIdentifiers.map((idPoolIdentifier) =>
          idPoolIdentifier.id === id ? updatedIdPoolIdentifier : idPoolIdentifier
        ),
        idPoolIdentifier:
          s.idPoolIdentifier && s.idPoolIdentifier.id === id ? updatedIdPoolIdentifier : s.idPoolIdentifier,
      }));

      if (callback) {
        await callback(updatedIdPoolIdentifier);
      }
    } catch (e) {
      const errorMessage = getErrorMessage(e);

      setState((s) => ({
        ...s,
        error: errorMessage,
      }));

      Notification({
        type: 'error',
        message: 'Failed to update Id Pool Identifier',
        description: errorMessage,
      });

      if (callback) {
        await callback(null);
      }
    } finally {
      setState((s) => ({
        ...s,
        loading: false,
      }));
    }
  };

  const deleteIdPoolIdentifier: DeleteIdPoolIdentifierFunction = async (id, config, callback) => {
    setState((s) => ({
      ...s,
      loading: true,
      error: null,
    }));

    try {
      cancelTokenSource.current = makeCancelTokenSource();

      const options: api.IdPoolIdentifiersRequestOptions = {
        hydrate: config?.hydrate || [],
        cancelToken: cancelTokenSource.current.token,
      };

      const deletedIdPoolIdentifier = await api.deleteIdPoolIdentifier(id, options);

      if (deletedIdPoolIdentifier.isCancelledByClient) {
        return;
      }

      setState((s) => ({
        ...s,
        idPoolIdentifiers: s.idPoolIdentifiers.filter((idPoolIdentifier) => idPoolIdentifier.id !== id),
        idPoolIdentifier: s.idPoolIdentifier && s.idPoolIdentifier.id === id ? null : s.idPoolIdentifier,
      }));

      if (callback) {
        await callback(deletedIdPoolIdentifier);
      }
    } catch (e) {
      const errorMessage = getErrorMessage(e);

      setState((s) => ({
        ...s,
        error: errorMessage,
      }));

      Notification({
        type: 'error',
        message: 'Failed to delete Id Pool Identifier',
        description: errorMessage,
      });

      if (callback) {
        await callback(null);
      }
    } finally {
      setState((s) => ({
        ...s,
        loading: false,
      }));
    }
  };

  const resetIdPoolIdentifiers = (): void => {
    if (cancelTokenSource.current) {
      cancelTokenSource.current.cancel();
      cancelTokenSource.current = null;
    }

    setState(getInitialState);
  };

  return (
    <IdPoolIdentifiersContext.Provider
      value={{
        idPoolIdentifiers: state.idPoolIdentifiers,
        idPoolIdentifier: state.idPoolIdentifier,
        loading: state.loading,
        error: state.error,
        getIdPoolIdentifiers,
        getIdPoolIdentifier,
        createIdPoolIdentifier,
        updateIdPoolIdentifier,
        deleteIdPoolIdentifier,
        resetIdPoolIdentifiers,
      }}
      {...props}
    />
  );
};

const useIdPoolIdentifiers = (): Context => React.useContext(IdPoolIdentifiersContext);

export { IdPoolIdentifiersProvider, useIdPoolIdentifiers };
