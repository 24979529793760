import {
  Configuration,
  FulfillmentOrder,
  FulfillmentOrderApi,
  FulfillmentOrderApiListFulfillmentOrdersRequest,
} from '@tassoinc/core-api-client';
import { httpClient } from 'services/index';
import { PagingRequest, PagingResponse } from 'utils/types';

let apiClient: FulfillmentOrderApi | undefined;

async function getClient(): Promise<FulfillmentOrderApi> {
  if (apiClient) {
    return apiClient;
  }
  const config = new Configuration({
    basePath: process.env.API_URL,
  });
  apiClient = new FulfillmentOrderApi(config, undefined, httpClient);
  return apiClient;
}

export type FulfillmentOrderResponse = {
  paging: PagingResponse;
  results: Array<FulfillmentOrder>;
};

export async function get(
  model: FulfillmentOrderApiListFulfillmentOrdersRequest,
  paging: PagingRequest
): Promise<FulfillmentOrderResponse> {
  const client = await getClient();
  const result = await client.listFulfillmentOrders({
    ...model,
    page: paging.page,
    pageLength: paging.pageLength,
  });

  const response = {
    paging: {
      page: result.data.paging?.page ?? 1,
      pageLength: result.data.paging?.pageLength ?? 50,
      isDescending: (result.data.paging ?? ({} as any)).isDescending ?? false,
      sortBy: (result.data.paging ?? ({} as any)).sortBy ?? '',
    },
    results: result.data.results ?? [],
  };

  return response;
}
