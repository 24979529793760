import React, { FC } from 'react';

import { Button, Upload } from 'antd';
import { RcFile } from 'antd/lib/upload/interface';

import { UploadOutlined } from 'components/icons';

type Props = {
  onChange: (file: RcFile | null) => void;
};

const CustomerLetterUploadField: FC<Props> = ({ onChange }) => (
  <Upload
    accept=".pdf,application/pdf"
    maxCount={1}
    beforeUpload={(file) => {
      onChange(file);
      return false;
    }}
    onRemove={() => {
      onChange(null);
    }}
  >
    <Button icon={<UploadOutlined />} data-testId="project-customer-letter">
      Select file
    </Button>
  </Upload>
);

export default CustomerLetterUploadField;
