import React, { FC, useMemo, useEffect, useState } from 'react';

import { Select, Typography, Space, Input, Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

import { PACKAGE1_TYPES, PACKAGE2_TYPES, Package1Type, Package2Type } from 'constants/index';

const { Option } = Select;

const { Text } = Typography;

interface Props {
  upsAccountIdToPatient: string | null;
  upsAccountIdToLab: string | null;
  package1Type: Package1Type;
  package2Type: Package2Type;
  onChange: (upsAccountIdToPatient: string | null, upsAccountIdToLab: string | null) => void;
}

const BillingMode: FC<Props> = ({ package1Type, package2Type, upsAccountIdToPatient, upsAccountIdToLab, onChange }) => {
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [billingMode, setBillingMode] = useState<'default' | 'separate'>('default');

  const handleChange = (toPatient: string | null, toLab: string | null): void => {
    const toPatientValue = toPatient?.trim() || null;
    const toLabValue = toLab?.trim() || null;
    onChange(toPatientValue, toLabValue);
  };

  const changeBillingMode = (mode: any): void => {
    setBillingMode(mode);
    onChange(null, null);
  };

  const showUspToPatientField = useMemo(
    () =>
      Object.keys(PACKAGE1_TYPES)
        .filter((key) => key.startsWith('ups'))
        .includes(package1Type),
    [package1Type]
  );

  const showUspToLabField = useMemo(
    () =>
      Object.keys(PACKAGE2_TYPES)
        .filter((key) => key.startsWith('ups'))
        .includes(package2Type),
    [package2Type]
  );

  useEffect(() => {
    // If none of the fields can be displayed, don't allow third party billing at all.
    if (!showUspToPatientField && !showUspToLabField) {
      setBillingMode('default');
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [showUspToPatientField, showUspToLabField]);

  const isSeparate = billingMode === 'separate';

  useEffect(() => {
    if (upsAccountIdToPatient || upsAccountIdToLab) {
      setBillingMode('separate');
    }
  }, [upsAccountIdToPatient, upsAccountIdToLab]);

  return (
    <Space
      direction="vertical"
      style={isSeparate ? { background: '#f7f7f7', padding: '10px', borderRadius: '3px' } : undefined}
    >
      <Text strong>
        Billing mode{' '}
        <Tooltip title="At least one shipping leg must use UPS to enable this option. Overrides the default billing account for UPS.">
          <QuestionCircleOutlined />
        </Tooltip>
      </Text>

      <Select
        value={billingMode}
        disabled={isDisabled}
        onChange={(mode) => changeBillingMode(mode)}
        data-testId="project-billing-mode"
      >
        <Option value="default" key="default">
          Default
        </Option>
        <Option value="separate" key="separate">
          Separate
        </Option>
      </Select>

      {isSeparate ? (
        <>
          {showUspToPatientField && (
            <Space direction="vertical">
              <Text strong>UPS Account Id (Tasso &rarr; patient)</Text>

              <Input
                value={upsAccountIdToPatient ?? ''}
                onChange={(e: any) => handleChange(e.target.value, upsAccountIdToLab)}
                placeholder="Account Id"
              />
            </Space>
          )}

          {showUspToLabField && (
            <Space direction="vertical">
              <Text strong>UPS Account Id (patient &rarr; lab)</Text>

              <Input
                value={upsAccountIdToLab ?? ''}
                onChange={(e: any) => handleChange(upsAccountIdToPatient, e.target.value)}
                placeholder="Account Id"
              />
            </Space>
          )}
        </>
      ) : null}
    </Space>
  );
};

export default BillingMode;
