import React, { FC, useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';

import BigLogoImg from 'assets/big_logo.png';
import { LoginDisclaimer, Footer } from 'components/index';
import { ROUTES } from 'constants/routes';
import { useAuth } from 'context/auth';
import EnterOTPForm from 'features/Login/EnterOTPForm';
import LoginForm from 'features/Login/LoginForm';
import SetNewPassword from 'features/Login/SetNewPasswordForm';
import SetupMFAForm from 'features/Login/SetupMFAForm';
import { checkDisclaimerAcceptance, clearOldDisclaimerKeys, storeDisclaimerAcceptance } from 'utils/loginDisclaimer';

const Login: FC = () => {
  const history = useHistory();
  const { login, action, loading, profile } = useAuth();
  const [disclaimer, setDisclaimer] = useState<{ show: boolean; email: string; password: string }>({
    show: false,
    email: '',
    password: '',
  });

  useEffect(() => {
    if (profile && profile.username) {
      history.push(ROUTES.projects);
    }

    // Find and delete outdated entries to ensure storage usage doesn't grow uncontrollably
    clearOldDisclaimerKeys();
  }, []);

  const handleLogin = (email: string, password: string): void => {
    const isDisclaimerAccepted = checkDisclaimerAcceptance(email);

    if (isDisclaimerAccepted) {
      login(email, password);
    } else {
      setDisclaimer({ show: true, email, password });
    }
  };

  const handleDisclaimerAccept = (): void => {
    storeDisclaimerAcceptance(disclaimer.email);

    const { email, password } = disclaimer;

    setDisclaimer({
      show: false,
      email: '',
      password: '',
    });

    login(email, password);
  };

  const handleDisclaimerReject = (): void => {
    setDisclaimer({
      show: false,
      email: '',
      password: '',
    });
  };

  return (
    <div className="Login">
      <LoginDisclaimer show={disclaimer.show} onAccept={handleDisclaimerAccept} onReject={handleDisclaimerReject} />
      <div className="Login__Content">
        <div className="Login__Logo">
          <img src={BigLogoImg} alt="Tasso Inc. Logo" />
        </div>

        {action !== null && action.type === 'setPassword' && (
          <SetNewPassword loading={loading} onSubmit={action.callback} />
        )}

        {action !== null && action.type === 'enterCode' && (
          <EnterOTPForm loading={loading} onSubmit={action.callback} />
        )}

        {action !== null && action.type === 'setupMfa' && (
          <SetupMFAForm loading={loading} code={action.code} email={action.email} onSubmit={action.callback} />
        )}

        {action === null && <LoginForm loading={loading} onSubmit={handleLogin} />}

        <Footer />
      </div>
    </div>
  );
};

export default Login;
