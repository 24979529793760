import React, { FC } from 'react';

import { Layout } from 'antd';

const { Footer: AFooter } = Layout;

const Footer: FC = () => (
  <AFooter className="Footer">
    Tasso ©2020 &bull; All rights reserved &bull;{' '}
    <a href="https://www.tassoinc.com/security-vulnerability-disclosure" target="_blank" rel="noopener noreferrer">
      Security Vulnerability Disclosure
    </a>
  </AFooter>
);

export default Footer;
