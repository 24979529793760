/* eslint-disable no-await-in-loop */
import React, { FC, useEffect, useState } from 'react';

import { DeviceStatisticsGraph, ProjectInformation, Spinner } from 'components';
import { useContacts } from 'context/contacts';
import { useCustomers } from 'context/customers';
import { useDevices } from 'context/devices';
import { Laboratory } from 'context/laboratories';
import { useNotificationTemplates } from 'context/notificationTemplates';
import { useProjectAnalyteLinks } from 'context/projectAnalyteLinks';
import { useProjectContacts } from 'context/projectContacts';
import { useProjectKitItems } from 'context/projectKitItems';
import { useProjectLaboratories } from 'context/projectLaboratories';
import { useOrderTemplates } from 'context/orderTemplates';
import {
  CustomAttributeDefinitionProject,
  GetProjectOptions,
  IProject,
  UpdateProjectOptions,
  useProjects,
} from 'context/projects';
import { useIdPools } from 'context/idPools';
import { useProjectIdPoolLinks } from 'context/projectIdPoolLinks';
import { User } from 'context/users/types';
import useKitItemsModal from 'hooks/useKitItemsModal';
import useLaboratoryModal from 'hooks/useLaboratoryModal';
import useProjectAnalyteLinkModal from 'hooks/useProjectAnalyteLinkModal';
import useUserModal from 'hooks/useUserModal';
import useCustomAttributeModal from 'hooks/useCustomAttributeModal';
import Notification, { closeNotification } from 'utils/notification';

import { OrderTemplate, OrderTemplateFulfillmentType } from '@tassoinc/core-api-client';
import useOrderTemplateModal from 'hooks/useOrderTemplateModal';

const ProjectDetails: FC<{ project: IProject }> = ({ project }) => {
  const { devices, getDevices, resetDevices, loading: devicesLoading } = useDevices();
  const { getProject, updateProject, setLoading, loading: projectLoading } = useProjects();
  const { contacts, getContacts, resetContacts, loading: contactsLoading } = useContacts();
  const { customers, getCustomers, resetCustomers, loading: customersLoading } = useCustomers();
  const {
    projectIdPoolLinks,
    createProjectIdPoolLink,
    deleteProjectIdPoolLink,
    getProjectIdPoolLinks,
    resetProjectIdPoolLinks,
    loading: projectIdPoolLinksLoading,
  } = useProjectIdPoolLinks();
  const { idPools, getIdPools, resetIdPools, loading: idPoolsLoading } = useIdPools();

  const {
    projectContacts,
    createProjectContact,
    deleteProjectContact,
    getProjectContacts,
    resetProjectContacts,
    loading: projectContactsLoading,
  } = useProjectContacts();
  const {
    projectKitItems,
    getProjectKitItems,
    bulkUpdateProjectKitItems,
    updateProjectKitItem,
    resetProjectKitItems,
    loading: projectKitItemsLoading,
  } = useProjectKitItems();
  const {
    projectAnalyteLinks,
    getProjectAnalyteLinks,
    bulkUpdateProjectAnalyteLinks,
    deleteProjectAnalyteLink,
    resetProjectAnalyteLinks,
    loading: projectAnalyteLinksLoading,
  } = useProjectAnalyteLinks();
  const {
    projectLaboratories,
    getProjectLaboratories,
    resetProjectLaboratories,
    loading: projectLaboratoriesLoading,
  } = useProjectLaboratories();
  const {
    getTemplates,
    smsTemplates,
    emailTemplates,
    resetTemplates,
    loading: notificationTemplatesLoading,
  } = useNotificationTemplates();

  const {
    orderTemplates,
    getOrderTemplate,
    getOrderTemplatesForProject: getOrderTemplates,
    resetOrderTemplates,
  } = useOrderTemplates();

  const [deviceStatuses, setDevicesStatuses] = useState<{ [status: string]: number }>({});

  /**
   * Loads the latest Order Template for the project and checks if it
   * has errors. If errors are present, a notification is shown in the UI.
   *
   * This check applies only to projects with useOrderTemplates=true.
   */
  const checkOrderTemplate = async (p: IProject): Promise<void> => {
    if (!p.useOrderTemplates) {
      return;
    }

    await new Promise((resolve) => {
      getOrderTemplate(`${p.id}::legacy`, {}, (tpl) => {
        if (tpl?.errors && tpl.errors.length > 0) {
          const errors = tpl.errors.map((err, index) => `${index + 1}) ${err}`);

          Notification({
            type: 'error',
            message: 'Invalid Order Template',
            description: `${errors.length} error${errors.length === 1 ? '' : 's'} found: ${errors.join('; ')}`,
            duration: 0,
            key: 'legacyOrderTemplateError', // important to avoid multiple identical errors
          });
        } else {
          closeNotification('legacyOrderTemplateError');
        }

        resolve(true);
      });
    });
  };

  useEffect(() => {
    getDevices({ projectIds: project.id, patientIdNull: false });
    getProjectContacts(project.id, true, false);
    getProjectKitItems(project.id);
    getProjectLaboratories(project.id);
    getContacts({ customerId: project.customerId });
    getCustomers();
    getTemplates({ customerIds: project.customerId });
    getProjectAnalyteLinks(project.id);

    if (project.useOrderTemplates) {
      checkOrderTemplate(project);
      getOrderTemplates(project.id);
    }

    return () => {
      resetDevices();
      resetProjectContacts();
      resetProjectKitItems();
      resetProjectLaboratories();
      resetContacts();
      resetCustomers();
      resetTemplates();
      resetProjectAnalyteLinks();
      resetProjectIdPoolLinks();
      resetIdPools();
      resetOrderTemplates();
    };
  }, []);

  const { Modal: UserModal, onCreateContactUser, onEditContactUser } = useUserModal({ customers });
  const { orderTemplateModal, onCreateOrderTemplateModel, onEditOrderTemplateModel } = useOrderTemplateModal(
    project,
    orderTemplates
  );
  const { customAttributeModal, onCreateCustomAttribute, onEditCustomAttribute, onDeleteCustomAttribute } =
    useCustomAttributeModal();

  const {
    Modal: KitItemModal,
    onCreateKitItem,
    onDeleteKitItem,
  } = useKitItemsModal({
    fetchKitItems: true,
  });

  const { Modal: ProjectAnalyteLinkModal, openModal: openProjectAnalyteLinkModal } = useProjectAnalyteLinkModal();

  const {
    Modal: LaboratoryModal,
    onEditLaboratory,
    onDeleteLaboratory,
    laboratoryModalPopulated,
  } = useLaboratoryModal();

  useEffect(() => {
    if (!devicesLoading) {
      const statuses: { [status: string]: number } = {};
      devices.forEach((d) => {
        if (statuses[d.status]) {
          statuses[d.status] += 1;
        } else {
          statuses[d.status] = 1;
        }
      });
      setDevicesStatuses(statuses);
    }
  }, [devicesLoading]);

  useEffect(() => {
    getProjectLaboratories(project.id);
  }, [laboratoryModalPopulated]);

  const ACTIONS = {
    contact: {
      createUser: () =>
        onCreateContactUser(project.customerId, async (user: User) => {
          // once user is created, auto add to project
          await createProjectContact({ projectId: project.id, userId: user.id });
        }),
      editUser: onEditContactUser,
      createContactLink: (userId: string) => createProjectContact({ userId, projectId: project.id }),
      deleteContactLink: (linkId: string) => deleteProjectContact(linkId),
    },
    customAttributes: {
      create: () => {
        onCreateCustomAttribute();
      },
      edit: (customAttributeDefinition: CustomAttributeDefinitionProject) => {
        onEditCustomAttribute(customAttributeDefinition);
      },
      delete: (name: string) => {
        onDeleteCustomAttribute(name);
      },
    },
    orderTemplates: {
      create: (_projectId: string, fulfillmentType?: OrderTemplateFulfillmentType) => {
        onCreateOrderTemplateModel(project.id, project.customerId, fulfillmentType);
      },
      edit: (orderTemplate: OrderTemplate) => {
        onEditOrderTemplateModel(orderTemplate, project.id, project.customerId);
      },
      delete: (/* _orderTemplate: OrderTemplate */) => {},
    },
    device: {
      create: () => {},
      edit: () => {},
      delete: () => {},
    },
    laboratory: {
      update: (lab: Laboratory) => {
        onEditLaboratory(lab);
      },
      delete: onDeleteLaboratory,
    },
    kitItem: {
      create: () => onCreateKitItem(),
      delete: (projectKitItemId: string) => onDeleteKitItem(projectKitItemId, true),
      update: (linkId: string, quantity: number) => updateProjectKitItem(linkId, { quantity }),
      updateOrder: (projectKitItemIds: string[]) => {
        bulkUpdateProjectKitItems(
          projectKitItemIds.reduce((acc, val, index) => ({ ...acc, [val]: { viewOrder: index } }), {})
        );
      },
    },
    project: {
      setLoading: (isLoading: boolean) => {
        setLoading(isLoading);
      },

      get: (options: GetProjectOptions = {}) => getProject(project.id, options),

      update: async (data: Record<string, any>, options: UpdateProjectOptions = {}): Promise<boolean> => {
        let reloadOrderTemplates = false;

        // Only reload templates if useOrderTemplates is changing from false to true.
        if (project.useOrderTemplates === false && data.useOrderTemplates === true) {
          reloadOrderTemplates = true;
        }

        // If changing existing labelIdentifierSource from identifierPool to something else, remove all the existing links
        if (
          data.labelIdentifierSource &&
          data.labelIdentifierSource !== 'identifierPool' &&
          project.labelIdentifierSource === 'identifierPool'
        ) {
          for (let i = 0; i < projectIdPoolLinks.length; i += 1) {
            await deleteProjectIdPoolLink(projectIdPoolLinks[i].id);
          }
        }

        const updatedProject = await new Promise<IProject | null>((resolve) => {
          let customCallback: (updatedProject: IProject | null) => Promise<void>;

          if (options.callback) {
            customCallback = async (item: IProject | null) => {
              await options.callback!(item);
              resolve(item);
            };
          } else {
            customCallback = async (item: IProject | null) => {
              resolve(item);
            };
          }

          updateProject(project.id, data, { ...options, callback: customCallback });
        });

        if (reloadOrderTemplates && updatedProject) {
          await checkOrderTemplate(updatedProject);

          // Reload the list of templates as well.
          // This will be removed once project editing doesn't result in auto-conversion.
          await getOrderTemplates(updatedProject.id);
        }

        return updatedProject !== null;
      },
    },
    projectAnalyteLinks: {
      create: () =>
        openProjectAnalyteLinkModal(
          project.id,
          projectLaboratories[0]?.laboratory!.adapterKey || '',
          projectAnalyteLinks.map((a) => a.analyteId)
        ),
      updateOrder: (linksIds: string[]) => {
        bulkUpdateProjectAnalyteLinks(
          linksIds.reduce((acc, val, index) => ({ ...acc, [val]: { viewOrder: index } }), {})
        );
      },
      delete: (linkId: string) => deleteProjectAnalyteLink(linkId),
    },
    projectIdPoolLinks: {
      update: async (idPoolId: string): Promise<void> => {
        const alreadyExists = projectIdPoolLinks.find((link) => link.idPoolId === idPoolId);

        if (alreadyExists) {
          return;
        }

        for (let i = 0; i < projectIdPoolLinks.length; i += 1) {
          await deleteProjectIdPoolLink(projectIdPoolLinks[i].id);
        }

        if (idPoolId) {
          createProjectIdPoolLink({ projectId: project.id, idPoolId }, { hydrate: ['idPool'] });
        }
      },
    },
  };

  // Load id pools and project id pool links only when project is configured for it
  useEffect(() => {
    if (project.labelIdentifierSource === 'identifierPool') {
      getIdPools({
        customerIds: [project.customerId],
        pageLength: -1,
        sortBy: 'name',
        sortOrder: 'asc',
      });

      getProjectIdPoolLinks(
        {
          projectIds: [project.id],
        },
        { hydrate: ['idPool'] }
      );
    }
  }, [project.labelIdentifierSource]);

  const handleRefresh = async () => {
    await getDevices({ projectIds: project.id, patientIdNull: false });
  };

  const loading =
    devicesLoading ||
    contactsLoading ||
    projectContactsLoading ||
    projectKitItemsLoading ||
    projectLaboratoriesLoading ||
    customersLoading ||
    notificationTemplatesLoading ||
    projectAnalyteLinksLoading ||
    projectIdPoolLinksLoading ||
    idPoolsLoading ||
    projectLoading;

  return (
    <div className="ProjectDetails">
      <Spinner spinning={loading}>
        {Object.keys(deviceStatuses).length > 0 && (
          <DeviceStatisticsGraph statuses={deviceStatuses} refresh={handleRefresh} />
        )}

        <ProjectInformation
          project={project}
          projectContacts={projectContacts}
          contacts={contacts}
          laboratories={projectLaboratories}
          kitItems={projectKitItems}
          projectAnalyteLinks={projectAnalyteLinks}
          actions={ACTIONS}
          emailTemplates={emailTemplates}
          smsTemplates={smsTemplates}
          idPools={idPools}
          projectIdPoolLinks={projectIdPoolLinks}
          orderTemplates={orderTemplates}
        />

        {KitItemModal}

        {LaboratoryModal}

        {UserModal}

        {ProjectAnalyteLinkModal}

        {customAttributeModal}

        {orderTemplateModal}
      </Spinner>
    </div>
  );
};

export default ProjectDetails;
