/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { FC, useState, useEffect } from 'react';

import { Table, Dropdown, Menu, Button, Modal } from 'antd';
import {
  CheckCircleOutlined,
  EditOutlined,
  DeleteOutlined,
  EllipsisOutlined,
  ExclamationCircleOutlined,
  ClearOutlined,
  KeyOutlined,
  CloseCircleOutlined,
} from 'components/icons';
import { ColumnsType, FilterValue, SorterResult, TablePaginationConfig } from 'antd/lib/table/interface';
import { Link } from 'react-router-dom';

import { ALL_USER_ROLES, USER_ROLES } from 'constants/index';
import { useAuth } from 'context/auth';
import { Customer } from 'context/customers';
import { ProjectContact } from 'context/projectContacts/types';
import { IProject } from 'context/projects';
import { User } from 'context/users/types';
import useIntegratedColumnSearch from 'hooks/useIntegratedColumnSearch';

import { FetchDataParameters, PagingConfig, SearchConfig, SearchableColumnKey } from './types';

type TableRecord = User & {
  projects: { id: string; name: string }[];
};

type Props = {
  users: User[];
  customers: Customer[];
  projectContacts: ProjectContact[];
  projects: IProject[];
  onDeleteUser: (userId: string) => void;
  onResetUserIdentity: (userId: string) => void;
  onResetTemporaryPassword: (userId: string) => void;
  onEditClick: (user: User) => void;
  onAccountStatusClick: (user: User) => void;
  resetFilters: boolean;
  doResetFilters: () => void;
  updatePaging: (params: PagingConfig) => void;
  totalUserCount: number;
  fetchParams: FetchDataParameters;
};

const UserList: FC<Props> = ({
  users,
  customers,
  projectContacts,
  projects,
  onDeleteUser,
  onEditClick,
  onAccountStatusClick,
  onResetUserIdentity,
  onResetTemporaryPassword,
  resetFilters,
  updatePaging,
  totalUserCount,
  fetchParams,
}) => {
  const { profile } = useAuth();
  const isAdmin = (profile?.role || '') === USER_ROLES.internalAdmin;

  const [tablePagination, setTablePagination] = useState<TablePaginationConfig>({
    current: fetchParams.page,
    pageSizeOptions: ['10', '20', '50', '100'],
    pageSize: fetchParams.pageLength,
    showSizeChanger: true,
    showQuickJumper: true,
    showTotal: (n: number) => `${n} user${n === 1 ? '' : 's'}`,
  });

  const [sorting, setSorting] = useState<{
    key: string;
    order: 'ascend' | 'descend';
  }>({
    key: 'createdAt',
    order: 'descend',
  });

  const [customerMap, setCustomerMap] = useState<Record<string, number>>({});
  const [projectMap, setProjectMap] = useState<Record<string, number>>({});
  const [userProjectMap, setUserProjectMap] = useState<Record<string, number[]>>({});

  useEffect(() => {
    const hash: Record<string, number[]> = {};
    for (let i = 0; i < projectContacts.length; i += 1) {
      const row = projectContacts[i];
      if (row.userId in hash) {
        hash[row.userId].push(i);
      } else {
        hash[row.userId] = [i];
      }
    }
    setUserProjectMap(hash);
  }, [projectContacts]);

  const askConfirmDelete = (user: TableRecord): void => {
    Modal.confirm({
      icon: <ExclamationCircleOutlined />,
      content: 'You are about to delete a user. Continue?',
      onOk() {
        onDeleteUser(user.id);
      },
      okText: 'Delete user',
      cancelButtonProps: { 'data-testid': 'deleteUser-modal_cancel-button' } as any, // 'data-testid' is not a recognized key, but it gets passed along nonetheless.
      okButtonProps: { 'data-testid': 'deleteUser-modal_confirm-button' } as any, // 'data-testid' is not a recognized key, but it gets passed along nonetheless.
    });
  };

  const askConfirmReset = (user: TableRecord): void => {
    Modal.confirm({
      icon: <ExclamationCircleOutlined />,
      content: "You are about to reset user's password and MFA. Continue?",
      onOk() {
        onResetUserIdentity(user.id);
      },
      okText: 'Reset user',
      cancelButtonProps: { 'data-testid': 'resetIdentity-modal_cancel-button' } as any, // 'data-testid' is not a recognized key, but it gets passed along nonetheless.
      okButtonProps: { 'data-testid': 'resetIdentity-modal_confirm-button' } as any, // 'data-testid' is not a recognized key, but it gets passed along nonetheless.
    });
  };

  const askConfirmResetTemporaryPassword = (user: TableRecord): void => {
    Modal.confirm({
      icon: <ExclamationCircleOutlined />,
      content: "You are about to reset user's temporary password and resend the welcome email. Continue?",
      onOk() {
        onResetTemporaryPassword(user.id);
      },
      okText: 'Reset password',
      cancelButtonProps: { 'data-testid': 'resetTemporaryPassword-modal_cancel-button' } as any, // 'data-testid' is not a recognized key, but it gets passed along nonetheless.
      okButtonProps: { 'data-testid': 'resetTemporaryPassword-modal_confirm-button' } as any, // 'data-testid' is not a recognized key, but it gets passed along nonetheless.
    });
  };

  const askChangeAccountStatus = (user: TableRecord): void => {
    Modal.confirm({
      icon: <ExclamationCircleOutlined />,
      content: `You are about to ${user.isActive ? 'disable' : 'enable'} user's account. Continue?`,
      onOk() {
        onAccountStatusClick(user);
      },
      okText: user.isActive ? 'Disable account' : 'Enable account',
      cancelButtonProps: { 'data-testid': 'enableUser-modal_cancel-button' } as any, // 'data-testid' is not a recognized key, but it gets passed along nonetheless.
      okButtonProps: { 'data-testid': 'enableUser-modal_confirm-button' } as any, // 'data-testid' is not a recognized key, but it gets passed along nonetheless.
    });
  };

  const { getColumnSearchProps } = useIntegratedColumnSearch<TableRecord>();

  const handleTableChange = (
    paginationParams: TablePaginationConfig,
    filterParams: Record<string, FilterValue | null>,
    sorterParams: SorterResult<TableRecord> | SorterResult<TableRecord>[]
  ): void => {
    const page = paginationParams.current!;
    const pageLength = paginationParams.pageSize!;

    const sorter = sorterParams as SorterResult<TableRecord>;

    let sortBy: string;
    let sortOrder: 'asc' | 'desc';

    if (typeof sorter.order !== 'undefined') {
      sortBy = sorter.columnKey as string;
      sortOrder = sorter.order === 'ascend' ? 'asc' : 'desc';
    } else {
      sortBy = 'createdAt';
      sortOrder = 'desc';
    }

    setTablePagination((s) => ({ ...s, current: page, pageSize: pageLength }));
    setSorting({ key: sortBy, order: sortOrder === 'asc' ? 'ascend' : 'descend' });

    // ++ search
    let search: SearchConfig | null;
    const activeFilters = Object.keys(filterParams).filter((k) => filterParams[k] !== null);
    if (activeFilters.length > 1) {
      // allow only one filter at a time
      if (fetchParams.search !== null) {
        const alreadyExecutedFilterIndex = activeFilters.findIndex((f) => f === fetchParams.search!.by);
        if (alreadyExecutedFilterIndex > -1) {
          activeFilters.splice(alreadyExecutedFilterIndex, 1);
        }
      }
    }

    const newActiveFilterName = activeFilters.length === 1 ? activeFilters[0] : null;

    if (newActiveFilterName !== null) {
      const value = filterParams[newActiveFilterName] === null ? '' : filterParams[newActiveFilterName]!.join(',');
      search = {
        by: newActiveFilterName as SearchableColumnKey,
        term: value,
      };
    } else {
      search = null;
    }
    // -- search

    updatePaging({ page, pageLength, sortBy, sortOrder, search });
  };

  const getCustomerById = (customerId: string): Customer | null => {
    if (customerId in customerMap) {
      return customers[customerMap[customerId]];
    }
    return null;
  };

  useEffect(() => {
    setSorting({ key: 'createdAt', order: 'descend' });
  }, [resetFilters]);

  useEffect(() => {
    setTablePagination((s) => ({ ...s, total: totalUserCount }));
  }, [totalUserCount]);

  useEffect(() => {
    setTablePagination((s) => ({ ...s, current: fetchParams.page, pageSize: fetchParams.pageLength }));
  }, [fetchParams.page, fetchParams.pageLength]);

  useEffect(() => {
    const hash: Record<string, number> = {};
    for (let i = 0; i < customers.length; i += 1) {
      hash[customers[i].id] = i;
    }

    setCustomerMap(hash);
  }, [customers]);

  useEffect(() => {
    const hash: Record<string, number> = {};
    for (let i = 0; i < projects.length; i += 1) {
      hash[projects[i].id] = i;
    }

    setProjectMap(hash);
  }, [projects]);

  const filterFields = (key: string): { filtered?: boolean; filteredValue?: FilterValue | null } => {
    if (fetchParams.search !== null && fetchParams.search.by === key) {
      return { filtered: true, filteredValue: fetchParams.search.term.split(',') };
    }

    return { filtered: false, filteredValue: null };
  };

  const columns: ColumnsType<TableRecord> = [
    {
      key: 'username',
      title: 'Email',
      dataIndex: 'username',
      sorter: true,
      sortOrder: sorting.key === 'username' ? sorting.order : undefined,
      ...getColumnSearchProps(fetchParams.search, 'username', 'search'),
    },
    {
      key: 'firstName',
      title: 'First name',
      dataIndex: 'firstName',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      sortOrder: sorting.key === 'firstName' ? sorting.order : undefined,
      ...getColumnSearchProps(fetchParams.search, 'firstName', 'search'),
      render: (value) => value || '',
    },
    {
      key: 'lastName',
      title: 'Last name',
      dataIndex: 'lastName',
      sorter: true,
      sortOrder: sorting.key === 'lastName' ? sorting.order : undefined,
      ...getColumnSearchProps(fetchParams.search, 'lastName', 'search'),
      render: (value) => value || '',
    },
    {
      key: 'role',
      title: 'Role',
      dataIndex: 'role',
      filters: Object.keys(ALL_USER_ROLES).map((k) => ({
        text: ALL_USER_ROLES[k as keyof typeof ALL_USER_ROLES],
        value: k,
      })),
      filterIcon: getColumnSearchProps(fetchParams.search, 'role', 'filter').filterIcon,
      ...filterFields('role'),
      sorter: true,
      sortOrder: sorting.key === 'role' ? sorting.order : undefined,
      render: (value) => (value ? ALL_USER_ROLES[value as keyof typeof ALL_USER_ROLES] || '' : ''),
    },
    {
      key: 'customers',
      title: 'Customer',
      filters: customers?.map((c) => ({ text: c.name, value: c.id })),
      filterIcon: getColumnSearchProps(fetchParams.search, 'customer', 'filter').filterIcon,
      ...filterFields('customers'),
      render: (_row, record) => (record.customer ? record.customer.name : ''),
    },
    {
      key: 'projects',
      title: 'Project',
      filters: projects.map((p) => ({ text: p.name, value: p.id })),
      filterIcon: getColumnSearchProps(fetchParams.search, 'project', 'filter').filterIcon,
      ...filterFields('projects'),
      render: (_, record) => {
        if (record.projects.length === 0) {
          return '';
        }

        return (
          <Dropdown
            overlay={
              <Menu>
                {record.projects
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((p) => (
                    <Menu.Item key={p.id}>
                      <Link to={`/projects/${p.id}/details`} target="_blank">
                        {p.name}
                      </Link>
                    </Menu.Item>
                  ))}
              </Menu>
            }
          >
            <span className="expandProjects">
              {record.projects.length} project{record.projects.length === 1 ? '' : 's'}
            </span>
          </Dropdown>
        );
      },
    },
    {
      key: 'isActive',
      title: 'Enabled',
      filterMultiple: false,
      filters: [
        { text: 'Only enabled', value: true },
        { text: 'Only disabled', value: false },
      ],
      filterIcon: getColumnSearchProps(fetchParams.search, 'enabled', 'filter').filterIcon,
      ...filterFields('isActive'),
      className: 'centered_col_value',
      render: (_row, record) =>
        record.isActive ? (
          <CheckCircleOutlined title="User account is enabled" style={{ color: '#4BB543' }} />
        ) : (
          <CloseCircleOutlined title="User account is disabled" style={{ color: '#CC0000' }} />
        ),
    },
    isAdmin
      ? {
          key: 'actions',
          title: 'Actions',
          width: 72,
          render: (_, record) =>
            profile !== null && profile.id === record.id ? (
              <div className="Users_actions">
                <small>You</small>
              </div>
            ) : (
              <div className="Users_actions">
                <Dropdown
                  trigger={['click']}
                  overlay={
                    <Menu>
                      <Menu.Item
                        data-testid="userActions-menu_edit-button"
                        key="1"
                        icon={<EditOutlined />}
                        onClick={() => onEditClick(record)}
                      >
                        Edit
                      </Menu.Item>
                      <Menu.Item
                        data-testid="userActions-menu_enable-button"
                        key="2"
                        icon={record.isActive ? <CloseCircleOutlined /> : <CheckCircleOutlined />}
                        onClick={() => askChangeAccountStatus(record)}
                      >
                        {record.isActive ? 'Disable account' : 'Enable account'}
                      </Menu.Item>
                      <Menu.Item
                        data-testid="userActions-menu_resetIdentity-button"
                        key="3"
                        icon={<ClearOutlined />}
                        onClick={() => askConfirmReset(record)}
                      >
                        Reset identity
                      </Menu.Item>
                      <Menu.Item
                        data-testid="userActions-menu_resetTemporaryPassword-button"
                        key="4"
                        icon={<KeyOutlined />}
                        onClick={() => askConfirmResetTemporaryPassword(record)}
                      >
                        Reset temporary password
                      </Menu.Item>
                      <Menu.Item
                        data-testid="userActions-menu_delete-button"
                        key="5"
                        danger
                        icon={<DeleteOutlined />}
                        onClick={() => askConfirmDelete(record)}
                      >
                        Delete
                      </Menu.Item>
                    </Menu>
                  }
                >
                  <Button icon={<EllipsisOutlined />} type="text" size="middle" />
                </Dropdown>
              </div>
            ),
        }
      : {},
  ];
  // Hide empty columns if they exist
  const filteredColumns: ColumnsType<TableRecord> = columns.filter((column) => Object.keys(column).length > 0);

  return (
    <Table
      dataSource={users.map((u): TableRecord => {
        const customer = u.customerId ? getCustomerById(u.customerId) : null;

        let userProjects: IProject[];
        if (u.id in userProjectMap) {
          userProjects = userProjectMap[u.id]
            .map((rowIndex) => {
              const link = projectContacts[rowIndex];
              if (!link) {
                return null;
              }
              const { projectId } = link;

              const projectIndex = typeof projectMap[projectId] !== 'undefined' ? projectMap[projectId] : -1;
              if (projectIndex > -1) {
                return projects[projectIndex];
              }
              return null;
            })
            .filter((p) => p !== null) as IProject[];
        } else {
          userProjects = [];
        }

        return {
          ...u,
          projects: userProjects,
          customer: customer || undefined,
        };
      })}
      bordered
      size="middle"
      columns={filteredColumns}
      rowKey="id"
      pagination={tablePagination}
      onChange={handleTableChange}
      onRow={(record) => {
        if (!record.isActive) {
          return { className: 'inactive_row' };
        }
        return {};
      }}
    />
  );
};

export default UserList;
