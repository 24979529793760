import React, { FC, useState, createContext } from 'react';

import { loadAllPages } from 'utils/functions';
import * as issues from 'utils/issues';

import { projectLaboratoriesApi as api } from '../api';
import Notification from '../utils/notification';
import { Laboratory } from './laboratories';

export interface IProjectLaboratory {
  id: string;
  projectId: string;
  laboratoryId: string;
  laboratory?: Laboratory;
  createdAt: string;
  updatedAt: string | null;
}

type State = {
  projectLaboratories: IProjectLaboratory[];
  projectLaboratory: IProjectLaboratory | null;
  loading: boolean;
  error: null | string;
};

interface Options {
  skipStateUpdate?: boolean;
}

type MultipleResultCallback = (projectLaboratoryLinks: IProjectLaboratory[] | null) => Promise<void> | void;

type GetProjectLaboratoriesFunction = (
  projectId: string,
  options?: Options,
  callback?: MultipleResultCallback
) => Promise<void>;

type Context = State & {
  getProjectLaboratories: GetProjectLaboratoriesFunction;
  getProjectLaboratory: (id: string, callback?: any) => Promise<void>;
  createProjectLaboratory: (data: api.ICreateProjectLaboratoryData, callback?: any) => Promise<void>;
  updateProjectLaboratory: (id: string, data: api.IUpdateProjectLaboratoryData, options?: any) => Promise<void>;
  deleteProjectLaboratory: (id: string, callback?: any) => Promise<void>;
  resetProjectLaboratories: () => void;
};

const getInitialState = (): State => ({
  projectLaboratories: [],
  projectLaboratory: null,
  loading: false,
  error: null,
});

const ProjectLaboratoriesContext = createContext<Context>({
  ...getInitialState(),
  getProjectLaboratories: async () => {},
  getProjectLaboratory: async () => {},
  createProjectLaboratory: async () => {},
  updateProjectLaboratory: async () => {},
  deleteProjectLaboratory: async () => {},
  resetProjectLaboratories: () => {},
});

const ProjectLaboratoriesProvider: FC = (props: any) => {
  const [state, setState] = useState<State>(getInitialState());

  const getProjectLaboratories: GetProjectLaboratoriesFunction = async (
    projectId,
    options?,
    callback?
  ): Promise<void> => {
    const skipStateUpdate = options?.skipStateUpdate === true;

    if (!skipStateUpdate) {
      setState((s) => ({
        ...s,
        loading: true,
        error: null,
      }));
    }

    try {
      type PayloadType = Parameters<typeof api.getProjectLaboratories>[0];
      const items = await loadAllPages<IProjectLaboratory, PayloadType>(api.getProjectLaboratories, projectId, {
        pageLength: 50,
        sortBy: 'createdAt',
        isDescending: false,
      });

      if (!skipStateUpdate) {
        setState((s) => ({
          ...s,
          projectLaboratories: items,
        }));
      }

      if (callback) {
        await callback(items);
      }
    } catch (e: any) {
      if (!skipStateUpdate) {
        setState((s) => ({
          ...s,
          error: issues.serializeForDisplay(e?.response?.data?.issues) || e.message,
        }));
      }

      Notification({
        duration: 2,
        type: 'error',
        message: 'Cannot get project laboratories',
        description: issues.serializeForDisplay(e?.response?.data?.issues) || e.message,
      });

      if (callback) {
        await callback(null);
      }
    } finally {
      if (!skipStateUpdate) {
        setState((s) => ({
          ...s,
          loading: false,
        }));
      }
    }
  };

  const getProjectLaboratory = async (
    id: string,
    callback: (item: IProjectLaboratory | null) => void
  ): Promise<void> => {
    const existingItem = state.projectLaboratories.find((item) => item.id === id);

    if (existingItem) {
      await callback(existingItem);
      return;
    }

    setState((s) => ({
      ...s,
      loading: true,
    }));

    try {
      const item = await api.getProjectLaboratory(id);

      setState((s) => ({
        ...s,
        loading: false,
        projectLaboratory: item,
      }));

      if (callback) {
        await callback(item);
      }
    } catch (e: any) {
      setState((s) => ({
        ...s,
        loading: false,
        error: issues.serializeForDisplay(e?.response?.data?.issues) || e.message,
      }));

      Notification({
        type: 'error',
        message: 'Cannot get project laboratory details',
        description: issues.serializeForDisplay(e?.response?.data?.issues) || e.message,
      });

      if (callback) {
        await callback(null);
      }
    }
  };

  const createProjectLaboratory = async (
    data: api.ICreateProjectLaboratoryData,
    callback: (newItem: IProjectLaboratory | null) => void
  ): Promise<void> => {
    setState((s) => ({
      ...s,
      loading: true,
    }));

    try {
      const item = await api.createProjectLaboratory(data);

      setState((s) => ({
        ...s,
        loading: false,
        projectLaboratories: [...s.projectLaboratories, item],
      }));

      Notification({ type: 'success', message: 'Laboratory added to project' });

      if (callback) {
        callback(item);
      }
    } catch (e: any) {
      console.log(e);

      setState((s) => ({
        ...s,
        loading: false,
        error: issues.serializeForDisplay(e?.response?.data?.issues) || e.message,
      }));

      Notification({
        type: 'error',
        message: 'Project and laboratory not linked',
        description: issues.serializeForDisplay(e?.response?.data?.issues) || e.message,
      });

      if (callback) {
        callback(null);
      }
    }
  };

  const updateProjectLaboratory = async (
    id: string,
    data: api.IUpdateProjectLaboratoryData,
    {
      showNotification = true,
      callback,
    }: { showNotification?: boolean; callback: (newItem: IProjectLaboratory | null) => void }
  ): Promise<void> => {
    setState((s) => ({
      ...s,
      loading: true,
    }));

    try {
      const updatedItem = await api.updateProjectLaboratory(id, data);

      const items = [...state.projectLaboratories];
      const foundItemIndex = items.findIndex((item) => item.id === id);
      if (foundItemIndex > -1) {
        items[foundItemIndex] = updatedItem;
      }

      setState((s) => ({
        ...s,
        loading: false,
        projectLaboratories: items,
      }));

      if (showNotification) {
        Notification({ type: 'success', message: 'Project laboratory updated' });
      }

      if (callback) {
        await callback(updatedItem);
      }
    } catch (e: any) {
      setState((s) => ({
        ...s,
        loading: false,
        error: issues.serializeForDisplay(e?.response?.data?.issues) || e.message,
      }));

      if (showNotification) {
        Notification({
          type: 'error',
          message: 'Project laboratory not updated',
          description: issues.serializeForDisplay(e?.response?.data?.issues) || e.message,
        });
      }

      if (callback) {
        callback(null);
      }
    }
  };

  const deleteProjectLaboratory = async (
    id: string,
    callback: (newItem: IProjectLaboratory | null) => void
  ): Promise<void> => {
    setState((s) => ({
      ...s,
      loading: true,
    }));

    try {
      const deletedItem = await api.deleteProjectLaboratory(id);

      const items = [...state.projectLaboratories].filter((item) => item.id !== id);

      setState((s) => ({
        ...s,
        loading: false,
        projectLaboratories: items,
      }));

      Notification({ type: 'success', message: 'Project laboratory deleted' });

      if (callback) {
        await callback(deletedItem);
      }
    } catch (e: any) {
      setState((s) => ({
        ...s,
        loading: false,
        error: issues.serializeForDisplay(e?.response?.data?.issues) || e.message,
      }));

      Notification({
        type: 'error',
        message: 'Project laboratory not deleted',
        description: issues.serializeForDisplay(e?.response?.data?.issues) || e.message,
      });

      if (callback) {
        await callback(null);
      }
    }
  };

  const resetProjectLaboratories = (): void => {
    setState(getInitialState());
  };

  return (
    <ProjectLaboratoriesContext.Provider
      value={{
        projectLaboratories: state.projectLaboratories,
        projectLaboratory: state.projectLaboratory,
        loading: state.loading,
        error: state.error,
        getProjectLaboratories,
        getProjectLaboratory,
        createProjectLaboratory,
        updateProjectLaboratory,
        deleteProjectLaboratory,
        resetProjectLaboratories,
      }}
      {...props}
    />
  );
};

const useProjectLaboratories = (): Context => React.useContext(ProjectLaboratoriesContext);

export { ProjectLaboratoriesProvider, useProjectLaboratories };
