import React, { FC, useState, useEffect } from 'react';

import { Form, Input, Modal, Row, Col, Upload, Button, Select } from 'antd';
import { RcFile } from 'antd/lib/upload/interface';

import { UploadOutlined, CloudDownloadOutlined, DeleteOutlined } from 'components/icons';
import TextConfigHelpModal from 'components/TextConfigHelpModal';
import { LAB_ADAPTER_KEYS } from 'constants/index';
import { Laboratory } from 'context/laboratories';

interface ILaboratoryModal {
  form: any;
  laboratory: Laboratory | null;
  visible: boolean;
  loading: boolean;
  onOk: (laboratory: any, reqFormFile?: RcFile) => void;
  onCancel: () => void;
  refreshLab: (labId: string, callback?: (lab: Laboratory | null) => void) => Promise<void>;
}

const LaboratoryModal: FC<ILaboratoryModal> = ({ form, laboratory, visible, onOk, onCancel, loading, refreshLab }) => {
  const [selectedReqForm, setSelectedReqForm] = useState<RcFile | null>(null);
  const [deleteReqForm, setDeleteReqForm] = useState<boolean>(false);
  const [showTextConfigHelp, setShowTextConfigHelp] = useState<boolean>(false);

  useEffect(() => {
    if (visible) {
      setSelectedReqForm(null);
      setDeleteReqForm(false);

      if (!laboratory) {
        form.resetFields();
      }
    }
  }, [visible]);

  const isValidConfig = (config: any): boolean => {
    try {
      const json = JSON.parse(`${config}`);
      if (!json || !Array.isArray(json)) {
        return false;
      }
      return true;
    } catch (_e) {
      return false;
    }
  };

  const handleFormSubmit = () => {
    const editMode = !!laboratory;

    form
      .validateFields()
      .then((values: any) => {
        const data: Record<string, any> = {};

        // REQUIRED fields
        data.name = values.name || '';
        data.address1 = values.address1 || '';
        data.city = values.city || '';
        data.district1 = values.district1 || '';
        data.postalCode = values.postalCode || '';
        data.country = 'US';
        data.email = values.email || '';
        data.textConfig = values.textConfig ? JSON.parse(values.textConfig) : null;

        // OPTIONAL fields
        const optionalFields: Record<string, string | null> = {
          attentionName: values.attentionName || null,
          address2: values.address2 || null,
          phoneNumber: values.phoneNumber ? values.phoneNumber.replace(/[^\d]/g, '') : null,
          uspsMid: values.uspsMid || null,
          uspsMerchantAccountId: values.uspsMerchantAccountId || null,
          adapterKey: values.adapterKey || null,
          uspsMerchantAccountCode: values.uspsMerchantAccountCode || null,
        };

        if (deleteReqForm) {
          data.reqFormUploadFilename = null;
        } else if (selectedReqForm) {
          data.reqFormUploadFilename = selectedReqForm.name;
        }

        // if creating new lab drop all null values and allow API to use defaults
        const filteredOptionalValues: Record<string, string> = editMode
          ? optionalFields
          : Object.keys(optionalFields)
              .filter((k) => optionalFields[k] !== null)
              .reduce((acc, k) => ({ ...acc, [k]: optionalFields[k] }), {});

        Object.keys(filteredOptionalValues).forEach((k) => {
          data[k] = filteredOptionalValues[k];
        });

        onOk(data, selectedReqForm ?? undefined);
      })
      .catch((info: any) => {
        console.log('Validate Failed:', info, selectedReqForm);
      });
  };

  const handleFileSelect = (file: RcFile) => {
    setSelectedReqForm(file);
    setDeleteReqForm(false);

    return false;
  };

  const handleRemoveFile = () => {
    setSelectedReqForm(null);
    setDeleteReqForm(false);
  };

  return (
    <>
      <Modal
        wrapClassName="Modal LaboratoryModal"
        visible={visible}
        title={laboratory ? 'Edit Laboratory' : 'Create Laboratory'}
        okText={laboratory ? 'Save' : 'Create'}
        cancelText="Cancel"
        onCancel={onCancel}
        onOk={handleFormSubmit}
        confirmLoading={loading}
        centered
        width="720px"
        forceRender
      >
        <Form
          className="Form LaboratoryModal__Form"
          form={form}
          layout="vertical"
          name="LaboratoryModalForm"
          hideRequiredMark
        >
          <Row gutter={16}>
            <Col sm={24} md={12} lg={12}>
              <Form.Item name="name" label="Name" rules={[{ required: true, message: 'Please input a name!' }]}>
                <Input placeholder="Enter name" />
              </Form.Item>
              <Form.Item name="attentionName" label="Attention Name">
                <Input placeholder="Enter Attention Name" />
              </Form.Item>
              <Form.Item
                name="address1"
                label="Shipping address #1"
                rules={[{ required: true, message: 'Please input address 1!' }]}
              >
                <Input placeholder="Enter address #1" />
              </Form.Item>

              <Form.Item name="address2" label="Shipping address #2">
                <Input placeholder="Enter address #2" />
              </Form.Item>

              <Form.Item
                name="city"
                label="Shipping city"
                rules={[{ required: true, message: 'Please input shipping city!' }]}
              >
                <Input placeholder="Enter city" />
              </Form.Item>

              <Form.Item
                name="district1"
                label="Shipping state"
                rules={[{ required: true, message: 'Please input shipping state!' }]}
              >
                <Input placeholder="Enter state" />
              </Form.Item>

              <Form.Item
                name="postalCode"
                label="Shipping zip"
                rules={[{ required: true, message: 'Please input shipping zip!' }]}
              >
                <Input placeholder="Enter zip" />
              </Form.Item>

              <Form.Item name="phoneNumber" label="Phone (15417543010)">
                <Input placeholder="Enter phone" />
              </Form.Item>

              <Form.Item
                name="email"
                label="Email"
                rules={[
                  { type: 'email', message: 'Invalid email!' },
                  { required: true, message: 'Please input email!' },
                ]}
              >
                <Input placeholder="Enter email" />
              </Form.Item>
            </Col>

            <Col sm={24} md={12} lg={12}>
              <Form.Item name="adapterKey" label="Adapter key">
                <Select
                  options={[
                    { value: '', label: '' },
                    ...Object.keys(LAB_ADAPTER_KEYS).map((k) => ({
                      value: k,
                      label: LAB_ADAPTER_KEYS[k as keyof typeof LAB_ADAPTER_KEYS],
                    })),
                  ]}
                />
              </Form.Item>
              <Form.Item name="uspsMid" label="USPS MID">
                <Input placeholder="Enter USPS MID" />
              </Form.Item>
              <Form.Item name="uspsMerchantAccountId" label="USPS Merchant Account ID">
                <Input placeholder="Enter USPS Merchant Account ID" />
              </Form.Item>
              <Form.Item name="uspsMerchantAccountCode" label="USPS Merchant Account Code">
                <Input placeholder="Enter USPS Merchant Account Code" />
              </Form.Item>
              <Form.Item label="Requisition form template" style={{ textAlign: 'center' }}>
                {laboratory && laboratory.reqFormKey && selectedReqForm === null && (
                  <>
                    <Button
                      size="middle"
                      type="link"
                      style={{ marginBottom: '10px' }}
                      icon={<CloudDownloadOutlined />}
                      onClick={(e) => {
                        e.preventDefault();
                        refreshLab(laboratory.id, (lab: Laboratory | null) => {
                          // eslint-disable-next-line security/detect-non-literal-fs-filename
                          window.open(lab!.reqFormUrl, '_blank');
                        });
                      }}
                    >
                      Download current form
                    </Button>
                    <br />
                    <Button
                      size="middle"
                      type={deleteReqForm ? 'dashed' : 'default'}
                      style={{ marginBottom: '10px' }}
                      icon={<DeleteOutlined />}
                      onClick={() => setDeleteReqForm(!deleteReqForm)}
                    >
                      {deleteReqForm ? 'Form will be deleted. Click to cancel' : 'Delete current form'}
                    </Button>
                    {!deleteReqForm && (
                      <>
                        <br /> OR
                      </>
                    )}
                    <br />
                  </>
                )}

                {!deleteReqForm && (
                  <Upload
                    beforeUpload={(file) => handleFileSelect(file)}
                    onRemove={handleRemoveFile}
                    accept=".pdf"
                    fileList={
                      selectedReqForm === null
                        ? []
                        : [
                            {
                              uid: selectedReqForm.uid,
                              name: selectedReqForm.name,
                              size: selectedReqForm.size,
                              type: selectedReqForm.type,
                              originFileObj: selectedReqForm,
                            },
                          ]
                    }
                  >
                    {selectedReqForm === null && (
                      <Button size="middle" icon={<UploadOutlined />} style={{ marginTop: '10px' }}>
                        Upload {laboratory && laboratory.reqFormKey ? 'a new ' : ''}PDF file
                      </Button>
                    )}
                  </Upload>
                )}
              </Form.Item>
              <Form.Item
                name="textConfig"
                label="Text Config"
                help={
                  <>
                    <Button size="small" type="link" onClick={() => setShowTextConfigHelp((s) => !s)}>
                      Help
                    </Button>
                  </>
                }
                rules={[
                  () => ({
                    validator(_, value) {
                      if (!value || isValidConfig(value)) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('Invalid config object'));
                    },
                  }),
                ]}
              >
                <Input.TextArea rows={3} placeholder="Enter config" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
      <TextConfigHelpModal open={showTextConfigHelp} onClose={() => setShowTextConfigHelp(false)} />
    </>
  );
};

export default LaboratoryModal;
