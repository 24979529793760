import React, { createContext, useReducer } from 'react';
import * as labOrderEventsApi from 'api/labOrderEvents';
import { loadAllPages, makeReducer } from 'utils/functions';
import { serializeForDisplay } from 'utils/issues';
import Notification from 'utils/notification';
import { IDevice } from './devices';
import { IPatient } from './patients';
import { IProject } from './projects';
import { Customer } from './customers';
import { Laboratory } from './laboratories';
import { ILabOrder } from './labOrders';

export interface ILabOrderEvent {
  id: string;
  deviceId: string;
  device: IDevice | null;
  patientId: string;
  patient: IPatient | null;
  projectId: string;
  project: IProject | null;
  customerId: string;
  customer: Customer | null;
  labOrderId: string;
  labOrder: ILabOrder | null;
  laboratoryId: string;
  laboratory: Laboratory | null;
  labStatus?: string;
  info?: Record<string, any>;
  actor?: string;
  createdAt: string;
  updatedAt: string | null;
}

type State = {
  labOrderEvents: ILabOrderEvent[];
  loading: boolean;
  error: string;
};

type Context = State & {
  getLabOrderEvents: (labOrderId: string) => Promise<void>;
  resetLabOrderEvents: () => void;
};

const initialState: State = {
  labOrderEvents: [],
  loading: false,
  error: '',
};

const LabOrderEventsContext = createContext<Context>({
  ...initialState,
  getLabOrderEvents: async () => {},
  resetLabOrderEvents: () => {},
});

const LabOrderEventsProvider = (props: any) => {
  const [state, setState] = useReducer(makeReducer<State>(), initialState);

  const getLabOrderEvents = async (labOrderId: string, callback?: any): Promise<void> => {
    try {
      setState({ loading: true });

      type PayloadType = Parameters<typeof labOrderEventsApi.getLabOrderEvents>[0];
      const labOrderEvents = await loadAllPages<ILabOrderEvent, PayloadType>(
        labOrderEventsApi.getLabOrderEvents,
        { labOrderIds: labOrderId },
        { pageLength: 50, sortBy: 'createdAt', isDescending: true }
      );

      setState({
        labOrderEvents,
        loading: false,
      });

      if (callback) {
        await callback();
      }
    } catch (e: any) {
      const errorMessage = serializeForDisplay(e?.response?.data?.issues) || e.message;
      setState({
        loading: false,
        error: errorMessage,
      });

      Notification({
        type: 'error',
        message: 'Failed to get device events',
        description: errorMessage,
      });
    }
  };

  const resetLabOrderEvents = (): void => {
    setState({ ...initialState });
  };

  return (
    <LabOrderEventsContext.Provider
      value={{
        labOrderEvents: state.labOrderEvents,
        loading: state.loading,
        error: state.error,
        getLabOrderEvents,
        resetLabOrderEvents,
      }}
      {...props}
    />
  );
};

const useLabOrderEvents = (): Context => React.useContext(LabOrderEventsContext);

export { LabOrderEventsProvider, useLabOrderEvents };
