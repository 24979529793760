import { SaveOutlined, CloseOutlined, EditOutlined } from '@ant-design/icons';
import { RadioChangeEvent, Radio, Button } from 'antd';
import { IProject, PatientPropertyConfig } from 'context/projects';
import React, { FC, useState } from 'react';

type Props = {
  project: IProject;
  isAdmin: boolean;
  updateProject: (data: Record<string, any>) => Promise<boolean>;
};

const PatientProperty: FC<Props> = ({ project, isAdmin, updateProject }) => {
  const { patientPropertyConfig } = project;
  const patientPropertyOptions = [
    { label: 'Required', value: 'required' },
    { label: 'Optional', value: 'optional' },
    { label: 'Hidden', value: 'hidden' },
  ];
  const propertyDisplayNames: { [key in keyof PatientPropertyConfig]: string } = {
    firstName: 'First Name',
    dob: 'Date of Birth',
    assignedSex: 'Assigned Sex at Birth',
    gender: 'Gender',
    address2: 'Address 2',
    phoneNumber: 'Phone number',
    email: 'Email',
    subjectId: 'Subject Id',
    race: 'Race',
    physicianDetails: 'Physician Details',
  };
  const [currentPatientPropertyConfig, setPatientPropertyConfig] = useState<PatientPropertyConfig>({
    ...patientPropertyConfig,
  });
  const [isConfigEditing, setConfigEditing] = useState(false);

  const onChange = (e: RadioChangeEvent, property: keyof PatientPropertyConfig) => {
    setPatientPropertyConfig((prevState) => ({
      ...prevState,
      [property]: e.target.value,
    }));
  };

  const handleSave = async (): Promise<void> => {
    const successfullyUpdated = await updateProject({
      patientPropertyConfig: currentPatientPropertyConfig,
    });
    if (successfullyUpdated) {
      setConfigEditing(false);
    }
  };

  const handleCancel = () => {
    // Restore the value of buttons to the original values as they are in a stale state.
    setPatientPropertyConfig(patientPropertyConfig!);
    setConfigEditing(false);
  };

  return (
    <>
      <h3>Patient Properties </h3>
      <div style={{ maxWidth: '1200px', margin: 'left' }}>
        <div
          className="ProjectInformation__PatientProperties"
          // This creates a 2-column grid. The list of properties will be split between 2 columns.
          style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '0.2rem' }}
        >
          {Object.entries(propertyDisplayNames).map(([property, displayName], index) => (
            <React.Fragment key={property}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span style={{ minWidth: '200px' }}>{displayName}</span>
                <Radio.Group
                  onChange={(event) => onChange(event, property as keyof PatientPropertyConfig)}
                  options={patientPropertyOptions}
                  value={currentPatientPropertyConfig[property as keyof PatientPropertyConfig]}
                  disabled={!isConfigEditing}
                  optionType="button"
                  buttonStyle="solid"
                  size="small"
                  data-testid={`patient-config-${property}`}
                />
              </div>
              {
                // Creates a line break after every 2 properties (1 row).
                index % 2 === 1 && <hr style={{ gridColumn: 'span 2' }} />
              }
            </React.Fragment>
          ))}
        </div>
        <div style={{ marginTop: '1rem' }}>
          {isConfigEditing ? (
            <>
              <Button
                icon={<SaveOutlined />}
                type="primary"
                data-testid="button-save-patient-config"
                className="SelectableValueSave"
                onClick={() => handleSave()}
              >
                Save
              </Button>
              <Button
                icon={<CloseOutlined />}
                type="default"
                data-testid="button-cancel-patient-config"
                style={{ marginLeft: '20px' }}
                onClick={() => handleCancel()}
              >
                Cancel
              </Button>
            </>
          ) : (
            <>
              <Button
                icon={<EditOutlined />}
                data-testid="button-edit-patient-config"
                // style={{ padding: '4px 15px', marginTop: '10px' }}
                disabled={!isAdmin}
                onClick={() => setConfigEditing(true)}
              >
                Edit Patient Configuration
              </Button>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default PatientProperty;
