import React, { FC } from 'react';

import { Select, Typography, Space } from 'antd';

import { LABELING_STRATEGIES_LABELS } from 'constants/index';

type Props = {
  onChange: (deviceId: any) => void;
  hideTitle?: boolean;
  showInline?: boolean;
  value: string | null;
};

const LabelingStrategySelectField: FC<Props> = ({ onChange, hideTitle = false, showInline = false, value }) => {
  const handleSelect = (v: any) => {
    onChange(v);
  };

  return (
    <div className="SelectField" style={showInline ? { display: 'inline-block' } : {}}>
      <Space direction="vertical">
        {!hideTitle && <Typography.Text strong>Labeling Strategy</Typography.Text>}

        <Select
          onSelect={handleSelect}
          placeholder="Select labeling strategy"
          value={value ?? undefined}
          data-testId="project-labeling-strategy"
        >
          {Object.keys(LABELING_STRATEGIES_LABELS).map((k) => (
            <Select.Option value={k} key={k}>
              {LABELING_STRATEGIES_LABELS[k as keyof typeof LABELING_STRATEGIES_LABELS]}
            </Select.Option>
          ))}
        </Select>
      </Space>
    </div>
  );
};

export default LabelingStrategySelectField;
