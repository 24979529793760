import { CancelToken, AxiosRequestConfig } from 'axios';
import { handleResponseError, httpClient } from 'services/index';
import { PagingRequest } from 'utils/types';

export type IdPoolRequestOptions = {
  hydrate?: string[];
  cancelToken?: CancelToken;
};

const prepareRequest = (existingConfig: AxiosRequestConfig, options?: IdPoolRequestOptions): AxiosRequestConfig => {
  const req: AxiosRequestConfig = { ...existingConfig };

  if (options) {
    if (options.hydrate && options.hydrate.length > 0) {
      req.headers = { ...(req.headers || {}), 'tasso-hydration': options.hydrate.join(',') };
    }
    if (options.cancelToken) {
      req.cancelToken = options.cancelToken;
    }
  }

  return req;
};

export type GetIdPoolsPayload = {
  query: Record<string, any>;
  options?: IdPoolRequestOptions;
};
export const getIdPools = (payload: GetIdPoolsPayload, paging: PagingRequest): Promise<any> => {
  const requestOptions = prepareRequest({ params: { ...payload.query, ...paging } }, payload.options);

  return httpClient
    .get('/idPools', requestOptions)
    .then((_) => _.data)
    .catch(handleResponseError);
};

export const getIdPool = (id: string, options?: IdPoolRequestOptions): Promise<any> => {
  const requestOptions = prepareRequest({}, options);

  return httpClient
    .get(`/idPools/${id}`, requestOptions)
    .then((_) => _.data.results)
    .catch(handleResponseError);
};

export type CreateIdPoolPayload = {
  name: string;
  customerId: string;
};
export const createIdPool = (data: CreateIdPoolPayload, options?: IdPoolRequestOptions): Promise<any> => {
  const requestOptions = prepareRequest({}, options);

  return httpClient
    .post('/idPools', data, requestOptions)
    .then((_) => _.data.results)
    .catch(handleResponseError);
};

export type UpdateIdPoolPayload = {
  name?: string;
  customerId?: string;
};

export const updateIdPool = (id: string, data: UpdateIdPoolPayload, options?: IdPoolRequestOptions): Promise<any> => {
  const requestOptions = prepareRequest({}, options);

  return httpClient
    .patch(`/idPools/${id}`, data, requestOptions)
    .then((_) => _.data.results)
    .catch(handleResponseError);
};

export const deleteIdPool = (id: string, options?: IdPoolRequestOptions): Promise<any> => {
  const requestOptions = prepareRequest({}, options);

  return httpClient
    .delete(`/idPools/${id}`, requestOptions)
    .then((_) => _.data.results)
    .catch(handleResponseError);
};
