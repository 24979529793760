import React, { FC, useEffect, useMemo, useState } from 'react';

import { Form, Input, Modal, Radio, Select, Typography } from 'antd';
import { DEVICE_REPLACEMENT_REASONS } from 'constants/index';
import { CustomAttributeDefinitionProject, IProject } from 'context/projects';
import { Order } from 'context/orders';
import { ICustomAttributeDefinition } from 'context/customAttributeDefinitions';
import { OrderTemplate } from '@tassoinc/core-api-client';
import { getDefaultOrderTemplate, sortOrderTemplates } from 'utils/orderTemplates';
import CustomAttributesEntry, { CUSTOM_ATTRIBUTE_FIELD_PREFIX } from './CustomAttributeEntry';

type Props = {
  patientCount: number;
  deviceCount: number;
  customAttributeDefinitions: Map<string, CustomAttributeDefinitionProject[] | ICustomAttributeDefinition[]>;
  project: IProject;
  orderTemplates: OrderTemplate[];
  ordersToReplace: Order[];
  onCancel: () => void;
  onOk: (
    harmCaused: boolean,
    reason: string,
    description: string,
    customAttributeValues: Record<string, string>,
    orderTemplateId: string | null
  ) => Promise<void>;
};

const ReplacementReasonModal: FC<Props> = ({
  patientCount,
  deviceCount,
  customAttributeDefinitions,
  project,
  orderTemplates,
  onCancel,
  onOk,
}) => {
  const [form] = Form.useForm();
  const [reason, setReason] = useState<string>('unknown');
  const [description, setDescription] = useState<string>('');
  const [harmCaused, setHarmCaused] = useState<boolean | null>(null);
  const [canSubmit, setCanSubmit] = useState(false);
  const [selectedOrderTemplateId, setSelectedOrderTemplateId] = useState<string | null>(null);

  const handleCancel = () => {
    onCancel();
    setSelectedOrderTemplateId(null);
  };

  const handleOk = () => {
    if (!canSubmit) {
      return;
    }
    form
      .validateFields()
      .then((formData) => {
        const customAttributes = Object.keys(formData).reduce<Record<string, string>>((acc, fieldName) => {
          if (fieldName.startsWith(CUSTOM_ATTRIBUTE_FIELD_PREFIX)) {
            const cleanFieldName = fieldName.replace(CUSTOM_ATTRIBUTE_FIELD_PREFIX, '');
            acc[cleanFieldName] = formData[fieldName];
          }

          return acc;
        }, {});

        if (reason) {
          onOk(harmCaused as boolean, reason, description, customAttributes, selectedOrderTemplateId);
          handleCancel();
        }
      })
      .catch((info: any) => {
        console.log('Validate Failed:', info);
      });
  };

  const getReplacementValues = (): { label: string; value: string }[] => {
    const results: { label: string; value: string }[] = [{ label: '', value: '' }];
    const keys = Object.keys(DEVICE_REPLACEMENT_REASONS);
    for (let i = 0; i < keys.length; i += 1) {
      const key = keys[i] as keyof typeof DEVICE_REPLACEMENT_REASONS;
      if (typeof DEVICE_REPLACEMENT_REASONS[key] === 'object') {
        results.push(
          ...(DEVICE_REPLACEMENT_REASONS[key] as { label: string; value: string }[]).map((config) => ({
            label: config.label,
            value: `${key}_${config.value}`,
          }))
        );
      } else {
        results.push({ label: DEVICE_REPLACEMENT_REASONS[key] as string, value: key });
      }
    }

    return results;
  };

  useEffect(() => {
    if (deviceCount > 0) {
      setHarmCaused(null);
      setReason('');
      setDescription('');
      form.resetFields();
    }
  }, [deviceCount]);

  useEffect(() => {
    const isHarmCausedSelected = typeof harmCaused === 'boolean';

    if (reason === 'unknown') {
      setCanSubmit(isHarmCausedSelected && description.length > 0);
    } else {
      setCanSubmit(isHarmCausedSelected && reason !== '');
    }
  }, [harmCaused, reason, description]);

  const sortedOrderTemplates = useMemo(
    () => sortOrderTemplates(orderTemplates, project.orderTemplatesViewOrder),
    [orderTemplates, project]
  );

  const defaultOrderTemplateId = useMemo(() => {
    const defaultTemplate = getDefaultOrderTemplate(orderTemplates, project.orderTemplatesViewOrder);

    return defaultTemplate?.id;
  }, [orderTemplates, project]);

  useEffect(() => {
    setSelectedOrderTemplateId(defaultOrderTemplateId ?? null);
  }, [defaultOrderTemplateId]);

  // Remove all custom attributes values from the form and reset them whenever selected order template changes.
  // Each order template comes with its own configuration, it's important to reset it on template change.
  useEffect(() => {
    const formData = form.getFieldsValue();

    const customAttributeFields = Object.keys(formData).filter((v) => v.startsWith(CUSTOM_ATTRIBUTE_FIELD_PREFIX));

    form.resetFields(customAttributeFields);
  }, [selectedOrderTemplateId]);

  return (
    <Modal
      title="Device Replacement"
      visible={deviceCount > 0}
      onOk={handleOk}
      onCancel={handleCancel}
      okButtonProps={{ disabled: !canSubmit }}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          harmCaused: null,
          reason: '',
          description: '',
          orderTemplateId: defaultOrderTemplateId,
        }}
        onValuesChange={(_, b) => {
          setHarmCaused(b.harmCaused ?? null);
          setReason(b.reason ?? '');
          setDescription(b.description ?? '');
        }}
      >
        <p>
          To create {patientCount} new device{patientCount === 1 ? '' : 's'}, {deviceCount} existing device
          {deviceCount === 1 ? '' : 's'} need
          {deviceCount === 1 ? 's' : ''} to be marked as <strong>replaced</strong>.
        </p>

        <Form.Item label="Was harm caused?" name="harmCaused" rules={[{ required: true, message: 'Required' }]}>
          <Radio.Group
            options={[
              { value: true, label: 'Yes' },
              { value: false, label: 'No' },
            ]}
            optionType="button"
            buttonStyle="solid"
            name="harmCaused"
          />
        </Form.Item>

        {typeof harmCaused === 'boolean' && (
          <>
            {harmCaused === true && (
              <Typography>
                In the event of harm caused, please call Tasso Customer Support at{' '}
                <Typography.Link href="tel:14087088451">408-708-8451</Typography.Link> to report.
              </Typography>
            )}
            {harmCaused === false && (
              <>
                <Form.Item
                  label="Replacement reason"
                  name="reason"
                  rules={[{ required: true, message: 'Reason is required' }]}
                >
                  <Select style={{ width: '100%' }} options={getReplacementValues()} />
                </Form.Item>

                <Form.Item
                  label="Description"
                  name="description"
                  rules={[{ required: reason === 'unknown', message: 'Description is required' }]}
                >
                  <Input.TextArea rows={4} required={reason === 'unknown'} />
                </Form.Item>
                {project.useOrderTemplates && (
                  <Form.Item
                    name="orderTemplateId"
                    label="Order Configuration"
                    rules={[
                      {
                        required: true,
                        message: 'Please select order config',
                      },
                    ]}
                  >
                    <Select
                      placeholder="Please select an order configuration"
                      onChange={(v) => setSelectedOrderTemplateId(v)}
                    >
                      {sortedOrderTemplates
                        .filter((template) => !template.isCustomerOrderable === false)
                        .map((orderTemplate) => (
                          <Select.Option key={orderTemplate.id} value={orderTemplate.id}>
                            {orderTemplate.name}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                )}

                <CustomAttributesEntry
                  attributeDefinitions={customAttributeDefinitions}
                  selectedOrderTemplateId={selectedOrderTemplateId}
                  useOrderTemplates={project.useOrderTemplates}
                />
              </>
            )}
          </>
        )}
      </Form>
    </Modal>
  );
};

export default ReplacementReasonModal;
