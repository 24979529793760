import React, { FC } from 'react';

import { IDeviceLot } from 'context/deviceLots';
import { IDevice } from 'context/devices';
import { IPatient } from 'context/patients';
import { DeviceEvent } from 'utils/types';

import { IProject } from 'context/projects';
import { ILabOrderEvent } from 'context/labOrderEvents';
import { FulfillmentOrder } from '@tassoinc/core-api-client';
import WithoutResultForm from './WithoutResultForm';

interface IDeviceDetailsModal {
  device: IDevice;
  patient: IPatient | null;
  project: IProject | null;
  lot: IDeviceLot | null;
  visible: boolean;
  events: DeviceEvent[];
  labOrderEvents: ILabOrderEvent[];
  fulfillmentOrder: FulfillmentOrder | null;
  onCancel: () => void;
}

const DeviceDetailsModal: FC<IDeviceDetailsModal> = ({
  device,
  patient,
  project,
  lot,
  visible,
  events,
  labOrderEvents,
  fulfillmentOrder,
  onCancel,
}) => (
  <WithoutResultForm
    device={device}
    patient={patient}
    project={project}
    lot={lot}
    events={events}
    labOrderEvents={labOrderEvents}
    visible={visible}
    fulfillmentOrder={fulfillmentOrder}
    onCancel={onCancel}
  />
);

export default DeviceDetailsModal;
