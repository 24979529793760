import React, { FC, useEffect } from 'react';

import { Select, Space, Typography } from 'antd';

import { Analyte, useAnalytes } from 'context/analytes';

const { Option } = Select;

const { Text } = Typography;

interface IAnalyteSelectField {
  labAdapterKey: string;
  values: string[];
  onChange: (analyteIds: string[]) => void;
}

const AnalyteSelectField: FC<IAnalyteSelectField> = ({ onChange, labAdapterKey, values }) => {
  const { analytes, getAnalytes, resetAnalytes, loading } = useAnalytes();

  useEffect(
    () => () => {
      // cleanup on unmount
      resetAnalytes();
    },
    []
  );
  useEffect(() => {
    if (labAdapterKey) {
      getAnalytes({ labAdapterKey });
    } else {
      resetAnalytes();
    }
  }, [labAdapterKey]);

  const handleChange = (analyteIds: string[]) => {
    onChange(analyteIds);
  };

  return (
    <div className="SelectField">
      <Space direction="vertical">
        <Text strong>Analytes</Text>

        <Select
          className="multiSelect"
          mode="multiple"
          showSearch
          onChange={handleChange}
          placeholder="Select analytes"
          loading={loading}
          showArrow
          value={values}
          filterOption={(input, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          filterSort={(optionA: any, optionB: any) =>
            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
          }
          data-testId="project-analytes"
        >
          {analytes.map((analyte: Analyte) => (
            <Option key={analyte.id} value={analyte.id}>
              {analyte.friendlyname}
            </Option>
          ))}
        </Select>
      </Space>
    </div>
  );
};

export default AnalyteSelectField;
