/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { FC, useEffect } from 'react';

import { Button, Space } from 'antd';

import { FileAddOutlined, UndoOutlined, CloudDownloadOutlined, LoadingOutlined } from 'components/icons';
import { usePatients } from 'context/patients';
import { useProjects } from 'context/projects';
import { useUserFiles } from 'context/userFiles';
import Notification from 'utils/notification';
import { useAuth } from 'context/auth';
import { USER_ROLES } from 'constants/index';

interface IImportRecipients {
  updatePatients: () => void;
}

const ImportRecipients: FC<IImportRecipients> = ({ updatePatients }) => {
  const { project, getProject } = useProjects();
  const { loading: patientsLoading } = usePatients();

  const { downloadPatients, loading: userFilesLoading, error: downloadPatientsError } = useUserFiles();

  const { profile } = useAuth();
  const isAdmin = (profile?.role || '') === USER_ROLES.internalAdmin;

  useEffect(() => {
    if (downloadPatientsError) {
      Notification({
        duration: 7,
        type: 'error',
        message: 'Patient export failed',
        description: downloadPatientsError,
      });
    }
  }, [downloadPatientsError]);

  return (
    <div className="ImportRecipients">
      <Space>
        <Button disabled={patientsLoading} type="link" onClick={updatePatients}>
          <UndoOutlined /> Update recipients list
        </Button>
        {isAdmin && (
          <Button type="link" disabled={userFilesLoading} onClick={() => downloadPatients(project!.id)}>
            <FileAddOutlined /> Download as CSV
          </Button>
        )}
        {userFilesLoading && <LoadingOutlined />}
        {project !== null && project.customerLetterKey !== null && (
          <Button
            type="link"
            icon={<CloudDownloadOutlined />}
            onClick={(e) => {
              e.preventDefault();
              getProject(project!.id, {
                hydration: ['customerLetterUrl'],
                callback: async (p) => {
                  if (p!.customerLetterUrl) {
                    // eslint-disable-next-line security/detect-non-literal-fs-filename
                    window.open(p!.customerLetterUrl, '_blank');
                  }
                },
              });
            }}
          >
            Download customer letter
          </Button>
        )}
      </Space>
    </div>
  );
};

export default ImportRecipients;
