import React, { FC } from 'react';

import { Layout as ALayout } from 'antd';
import clsx from 'clsx';

import Footer from '../Footer';
import Header from '../Header';

const { Content } = ALayout;

interface Props {
  children: React.ReactNode;
  className?: string;
}

const Layout: FC<Props> = ({ children, className }) => (
  <ALayout className={clsx('Layout', className)}>
    <Header />

    <Content className="Layout__Content">{children}</Content>

    <Footer />
  </ALayout>
);

export default Layout;
