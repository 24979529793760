import React, { FC, useState, useEffect } from 'react';

import { Typography, Button } from 'antd';
import { BarChart, XAxis, Tooltip, YAxis, Bar, ResponsiveContainer } from 'recharts';
import { ContentType } from 'recharts/types/component/Tooltip';

import { SyncOutlined } from 'components/icons';
import { DEVICE_STATUS } from 'constants/index';

const USED_DEVICE_STATUS = {
  inStock: DEVICE_STATUS.inStock,
  readyToShip: DEVICE_STATUS.readyToShip,
  awaitingPickup: DEVICE_STATUS.awaitingPickup,
  inTransitToPatient: DEVICE_STATUS.inTransitToPatient,
  atPatient: DEVICE_STATUS.atPatient,
  inTransitToLab: DEVICE_STATUS.inTransitToLab,
  atLab: DEVICE_STATUS.atLab,
};

const { Paragraph } = Typography;

interface IDeviceStatisticsGraph {
  statuses: { [status: string]: number };
  refresh: () => void;
}

const DeviceStatisticsGraph: FC<IDeviceStatisticsGraph> = ({ statuses, refresh }) => {
  const [graphData, setGraphData] = useState<any[]>([]);

  useEffect(() => {
    const values = Object.keys(USED_DEVICE_STATUS).reduce(
      (acc, statusCode: string) => [
        ...acc,
        { name: USED_DEVICE_STATUS[statusCode as keyof typeof USED_DEVICE_STATUS], amount: statuses[statusCode] || 0 },
      ],
      [] as { name: string; amount: number }[]
    );

    setGraphData(values);
  }, [statuses]);

  const total = Object.values(statuses).reduce((acc, val) => acc + val, 0);

  const renderXAxisLabel = ({ x, y, payload: { index, value } }: any): any => (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={-24} textAnchor="middle" fill="#666" fontSize="14px">
        {value}
      </text>

      <text x={0} y={0} textAnchor="middle" fill="#333" fontWeight="500">
        {graphData[index].amount} ({((graphData[index].amount / total) * 100).toFixed(2)}
        %)
      </text>
    </g>
  );

  const renderTooltip: ContentType<any, any> | null = ({ active, payload, label }) =>
    active ? (
      <div className="Graph_Tooltip">
        <span className="Graph_Tooltip__Label">
          {label}: {payload ? payload[0].value : '-'} devices
        </span>
      </div>
    ) : null;

  const deviceTicks: number[] = [];

  if (statuses) {
    let maxValue = Math.max(...Object.values(statuses));
    maxValue = Math.floor(maxValue / 50) * 50 + 100;

    for (let i = 0; i < maxValue; i += 50) {
      deviceTicks.push(i);
    }
  }

  return (
    <div className="DeviceStatisticsGraph">
      <h2 className="DeviceStatisticsGraph__Heading">
        Device Statistics{' '}
        <Button
          icon={<SyncOutlined />}
          onClick={refresh}
          shape="circle"
          size="middle"
          type="text"
          title="Refresh device counts now"
        />
      </h2>

      <div className="DeviceStatisticsGraph__GraphContainer">
        {statuses ? (
          <ResponsiveContainer>
            <BarChart data={graphData} margin={{ top: 16, left: 8, right: 40, bottom: 24 }} barCategoryGap="4">
              <XAxis padding={{ left: 20, right: 20 }} dataKey="name" tick={renderXAxisLabel} orientation="top" />

              <YAxis type="number" domain={[deviceTicks[0], deviceTicks[deviceTicks.length - 1]]} ticks={deviceTicks} />

              <Tooltip content={renderTooltip} />

              <Bar className="Graph_Bar" radius={4} dataKey="amount" fill="#004e92" background={{ fill: '#eeeeee' }} />
            </BarChart>
          </ResponsiveContainer>
        ) : (
          <Paragraph type="secondary">No statistics available.</Paragraph>
        )}
      </div>
    </div>
  );
};

export default DeviceStatisticsGraph;
