import { httpClient } from 'services/index';
import { PagingRequest } from 'utils/types';

export interface GetAnalytesParams {
  labAdapterKey?: string;
}
export const getAnalytes = (params: GetAnalytesParams, paging: PagingRequest): Promise<any> =>
  httpClient.get('/analytes', { params: { ...params, ...paging } }).then((_) => _.data);

export const getAnalyte = (id: string): Promise<any> => httpClient.get(`/analytes/${id}`).then((_) => _.data);
