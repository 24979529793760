import React, { FC } from 'react';

import { Select, Typography, Space } from 'antd';

const { Option } = Select;

const { Text } = Typography;

interface IDeviceSelectField {
  onChange: (deviceId: any) => void;
  value: string | null;
}

const DeviceSelectField: FC<IDeviceSelectField> = ({ onChange, value }) => {
  const handleSelect = (deviceType: any) => {
    onChange(deviceType);
  };

  return (
    <div className="SelectField">
      <Space direction="vertical">
        <Text strong>Device</Text>

        <Select
          showSearch
          onSelect={handleSelect}
          placeholder="Select device type"
          value={value ?? undefined}
          data-testId="project-device"
        >
          <Option value="M20">M20</Option>
          <Option value="SST">SST</Option>
          <Option value="tassoPlus">Tasso+</Option>
        </Select>
      </Space>
    </div>
  );
};

export default DeviceSelectField;
