import moment from 'moment';

const databaseToJsDate = (str: string): Date => new Date(`${str.replace('Z', '')}+00:00`);

export const getUnixTimestamp = (str: string): number => {
  const date = databaseToJsDate(str);
  return Math.floor(date.getTime() / 1000);
};

/**
 * Converts an ISO formated date string to YYYY-MM-DD HH-MM in local timezone
 */
export const getDateTime = (str: string): string => {
  const date = databaseToJsDate(str);

  const datePart = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date
    .getDate()
    .toString()
    .padStart(2, '0')}`;

  const timePart = `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;

  return `${datePart} ${timePart}`;
};

/**
 * Convert a single date value (full or partial) to a date range.
 *
 * For example, "2000-01" (January 2000) becomes:
 * ['2000-01-01T00:00:00.000Z', '2000-01-31T23:59:59.999Z']
 *
 */
export const dateToRange = (date: string): string[] | null => {
  let fromDate: string;
  let toDate: string;

  if (/^\d{4}$/.test(date)) {
    fromDate = `${date}-01-01`;
    toDate = `${date}-12-31`;
  } else if (/^\d{4}-\d{2}$/.test(date)) {
    fromDate = `${date}-01`;

    const lastDay = moment.utc(`${date}-01T00:00:00.000Z`).endOf('month').date();
    toDate = `${date}-${lastDay}`;
  } else if (/^\d{4}-\d{2}-\d{2}$/.test(date)) {
    fromDate = date;
    toDate = date;
  } else {
    fromDate = '';
    toDate = '';
  }

  if (fromDate && toDate) {
    return [`${fromDate}T00:00:00.000Z`, `${toDate}T23:59:59.999Z`];
  }

  return null;
};
