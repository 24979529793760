/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useReducer, useCallback } from 'react';

import {
  CreateEmailTemplatePayload,
  CreateSmsTemplatePayload,
  UpdateEmailTemplatePayload,
  UpdateSmsTemplatePayload,
} from 'api/notificationTemplates';
import { EmailTemplateModal, SmsTemplateModal } from 'components/NotificationTemplatesModal';
import { Customer } from 'context/customers';
import { useNotificationTemplates } from 'context/notificationTemplates';
import { EmailTemplate, SmsTemplate } from 'context/notificationTemplates/types';
import { makeReducer } from 'utils/functions';

type Template = EmailTemplate | SmsTemplate;

type State = {
  emailModalVisible: boolean;
  smsModalVisible: boolean;
  activeTemplate: Template | null;
};

const isEmailTemplate = (template: Template): boolean => 'htmlBody' in template;

const useNotificationTemplatesModal = ({ customers }: { customers: Customer[] }) => {
  const {
    createEmailTemplate,
    updateEmailTemplate,
    createSmsTemplate,
    updateSmsTemplate,
    deleteEmailTemplate,
    deleteSmsTemplate,
    loading: notificationTemplatesLoading,
  } = useNotificationTemplates();
  const [state, setState] = useReducer(makeReducer<State>(), {
    emailModalVisible: false,
    smsModalVisible: false,
    activeTemplate: null,
  });

  const onCreateEmailTemplate = (): void => {
    setState({ emailModalVisible: true, smsModalVisible: false, activeTemplate: null });
  };

  const onCreateSmsTemplate = (): void => {
    setState({ emailModalVisible: false, smsModalVisible: true, activeTemplate: null });
  };

  const onEditClick = (template: Template): void => {
    setState({
      activeTemplate: template,
      ...(isEmailTemplate(template)
        ? { emailModalVisible: true, smsModalVisible: false }
        : { emailModalVisible: false, smsModalVisible: true }),
    });
  };

  const resetModal = (): void => {
    setState({ emailModalVisible: false, smsModalVisible: false, activeTemplate: null });
  };

  const createEmailTemplateFn = useCallback(
    (data: CreateEmailTemplatePayload, modalCallback: () => void): void => {
      createEmailTemplate(data, async () => {
        modalCallback();
      });
    },
    [state, createEmailTemplate]
  );

  const updateEmailTemplateFn = useCallback(
    (data: UpdateEmailTemplatePayload, modalCallback: () => void): void => {
      updateEmailTemplate(state.activeTemplate!.id, data, async () => {
        modalCallback();
      });
    },
    [state, updateEmailTemplate]
  );

  const createSmsTemplateFn = useCallback(
    (data: CreateSmsTemplatePayload, modalCallback: () => void): void => {
      createSmsTemplate(data, async () => {
        modalCallback();
      });
    },
    [state, createSmsTemplate]
  );

  const updateSmsTemplateFn = useCallback(
    (data: UpdateSmsTemplatePayload, modalCallback: () => void): void => {
      updateSmsTemplate(state.activeTemplate!.id, data, async () => {
        modalCallback();
      });
    },
    [state, updateSmsTemplate]
  );

  const onDeleteTemplate = useCallback(
    (id: string, type: 'EMAIL' | 'SMS'): void => {
      const deleteTemplate = type === 'EMAIL' ? deleteEmailTemplate : deleteSmsTemplate;
      deleteTemplate(id);
    },
    [deleteEmailTemplate, deleteSmsTemplate]
  );

  const Modal = (
    <>
      <EmailTemplateModal
        template={
          state.activeTemplate && isEmailTemplate(state.activeTemplate)
            ? (state.activeTemplate as EmailTemplate)
            : undefined
        }
        visible={state.emailModalVisible}
        customers={customers}
        onClose={resetModal}
        loading={notificationTemplatesLoading}
        createTemplate={createEmailTemplateFn}
        updateTemplate={updateEmailTemplateFn}
      />
      <SmsTemplateModal
        template={
          state.activeTemplate && !isEmailTemplate(state.activeTemplate)
            ? (state.activeTemplate as SmsTemplate)
            : undefined
        }
        visible={state.smsModalVisible}
        customers={customers}
        onClose={resetModal}
        loading={notificationTemplatesLoading}
        createTemplate={createSmsTemplateFn}
        updateTemplate={updateSmsTemplateFn}
      />
    </>
  );

  return { Modal, onCreateEmailTemplate, onCreateSmsTemplate, onEditClick, onDeleteTemplate, resetModal };
};

export default useNotificationTemplatesModal;
