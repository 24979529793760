import React, { useState, useEffect, useMemo, useCallback, useRef, useLayoutEffect } from 'react';

import { Alert, Button, Dropdown, Menu, Modal, Table, Tooltip } from 'antd';
import { ColumnType, SortOrder, TablePaginationConfig } from 'antd/lib/table/interface';
import moment from 'moment';
import { Link } from 'react-router-dom';

import { DeviceRow } from 'components/DevicesList/types';
import { DEVICE_STATUS, PATIENT_EXPERIENCE_STATUS } from 'constants/index';
import { IDeviceLot } from 'context/deviceLots';
import { IDevice } from 'context/devices';
import { IPatient } from 'context/patients';
import { DevicePagingConfig } from 'features/Projects/Project/ProjectDevices';
import useBackendColumnFilter from 'hooks/useBackendColumnFilter';
import useBackendColumnSearch from 'hooks/useBackendColumnSearch';
import useWindowSize from 'hooks/useWindowSize';
import { getReplacementReason } from 'utils/functions';
import { EllipsisOutlined, ExclamationCircleOutlined, EyeOutlined, RedoOutlined } from '@ant-design/icons';
import { IProject } from 'context/projects';

const statusFilters = Object.entries(DEVICE_STATUS).map(([key, value]) => ({ label: value, value: key }));

/**
 * @param date {string} date as a string in the `YYYY-MM-DD` format. E.g., `2001-11-20`
 * @returns {string} timestamp in milliseconds
 */
export const dateToTimestamp = (date: string): number => {
  const y = parseInt(date.substr(0, 4), 10);
  const m = parseInt(date.substr(4, 2), 10) - 1; // we need month index here which starts at 0
  const d = parseInt(date.substr(-2), 10);

  const theDate = new Date(y, m, d, 0, 0, 0, 0);

  return theDate.getTime();
};

interface IDevicesList {
  devices: IDevice[];
  deviceLots: IDeviceLot[];
  patients: IPatient[];
  onDeviceUpdate: (deviceId: string, values: Record<string, string | null>) => Promise<void>;
  loading: boolean;
  onDeviceClick: any;
  isFiltered: boolean;
  project: IProject | null;
  patientExperienceEnabled: boolean;
  updatePagingConfig: (config: Partial<DevicePagingConfig>) => void;
  totalDeviceCount: number;
  devicePagingConfig: DevicePagingConfig | null;
}

const StatusTooltipText: React.FC<{ row: DeviceRow }> = ({ row }) => (
  <>
    {row.isReplaced && <div style={{ marginBottom: '10px' }}>{getReplacementReason(row.replacementReason)}</div>}
    <div>Last update: {moment(row.statusChangedAt.slice(0, -1)).utc().format('YYYY-MM-DD HH:mm')}</div>
  </>
);

const DevicesList: React.FC<IDevicesList> = ({
  devices,
  deviceLots,
  patients,
  onDeviceUpdate,
  loading,
  onDeviceClick,
  isFiltered,
  project,
  patientExperienceEnabled,
  updatePagingConfig,
  totalDeviceCount,
  devicePagingConfig,
}) => {
  const tableWrapRef = useRef<HTMLDivElement>(null);
  const windowSize = useWindowSize();

  const { getColumnSearchProps } = useBackendColumnSearch<DeviceRow>();
  const { getColumnSearchProps: getColumnFilterProps } = useBackendColumnFilter<DeviceRow>();

  const [patientHash, setPatientHash] = useState<Record<string, number>>({});
  const [deviceLotsHash, setDeviceLotsHash] = useState<Record<string, number>>({});
  const [tableHeight, setTableHeight] = useState<number>(windowSize.height);

  const [tablePagination, setTablePagination] = useState<TablePaginationConfig>({
    current: 0,
    defaultCurrent: 1,
    pageSizeOptions: ['10', '20', '50', '100'],
    defaultPageSize: 100,
    hideOnSinglePage: false,
    showSizeChanger: true,
    showQuickJumper: true,
    showTotal: (n) => `Total ${n} devices`,
  });

  useLayoutEffect(() => {
    if (tableWrapRef.current) {
      const rowContainer = tableWrapRef.current.querySelector('.ant-table-body');
      if (!rowContainer) {
        return;
      }
      const box = rowContainer.getBoundingClientRect();

      // 64px is the height of the footer, 32px height of pagination, 32px margin around pagination
      setTableHeight(windowSize.height - box.top - 64 - 32 - 32);
    }
  }, [windowSize]);

  useEffect(() => {
    const hash = patients.reduce((acc, val, index) => ({ ...acc, [val.id]: index }), {});
    setPatientHash(hash);
  }, [patients]);

  useEffect(() => {
    const hash = deviceLots.reduce((acc, val, index) => ({ ...acc, [val.id]: index }), {});
    setDeviceLotsHash(hash);
  }, [deviceLots]);

  useEffect(() => {
    setTablePagination((s) => ({ ...s, total: totalDeviceCount }));
  }, [totalDeviceCount]);

  const resetPagination = () => {
    updatePagingConfig({
      page: tablePagination.defaultCurrent!,
      pageLength: tablePagination.defaultPageSize!,
      sortBy: 'createdAt',
      sortOrder: 'desc',
      includeTotalCount: true,
      search: null,
    });

    setTablePagination((s) => ({
      ...s,
      current: tablePagination.defaultCurrent!,
      pageSize: tablePagination.defaultPageSize!,
    }));
  };

  useEffect(() => {
    resetPagination();
  }, []);

  const getPatient = useCallback((id: string) => patients[patientHash[id]], [patients, patientHash]);

  const searchConfig = devicePagingConfig?.search || null;

  const askConfirmResetActivationCode = (record: DeviceRow): void => {
    Modal.confirm({
      title: 'Replace activation code',
      icon: <ExclamationCircleOutlined />,
      content: (
        <span>
          You are about to <strong>replace</strong> the existing device activation code with a new automatically
          generated one. Continue?
        </span>
      ),
      onOk() {
        onDeviceUpdate(record.id, { activationCode: 'GENERATE' });
      },
      okText: 'Replace code',
    });
  };

  const searchByKey = async (searchKey: string, term: string | null) => {
    if (searchConfig !== null && searchConfig.by === searchKey) {
      // search previously performed on this field, now reset or update it
      if (term === null) {
        resetPagination();
      } else {
        updatePagingConfig({ search: { by: searchKey, term }, page: 1, includeTotalCount: true });
      }
    } else if (term) {
      updatePagingConfig({ search: { by: searchKey, term }, page: 1, includeTotalCount: true });
      setTablePagination((s) => ({ ...s, current: 1 }));
    }
  };

  const columns: ColumnType<DeviceRow>[] = [
    ...(project?.patientPropertyConfig?.firstName !== 'hidden'
      ? [
          {
            title: 'First name',
            key: 'firstName',
            dataIndex: 'firstName',
          },
        ]
      : ([] as any)),
    {
      title: 'Last name',
      key: 'lastName',
      dataIndex: 'lastName',
    },
    {
      title: 'Barcode',
      key: 'barcode',
      dataIndex: 'barcode',
      ...getColumnSearchProps(searchConfig, 'barcodeLike', searchByKey),
    },
    {
      title: 'Activation code',
      key: 'activationCode',
      dataIndex: 'activationCode',
      ...getColumnSearchProps(searchConfig, 'activationCodeLike', searchByKey),
    },
    {
      title: 'Activation status',
      key: 'patientExperienceStatus',
      dataIndex: 'patientExperienceStatus',
    },
    {
      title: 'Lot',
      key: 'lot',
      dataIndex: 'lot',
    },
    {
      title: 'Expires',
      key: 'expiresAt',
      dataIndex: 'expiresAt',
      sorter: true,
      sortDirections: ['descend', 'ascend'] as SortOrder[],
      ...getColumnSearchProps(searchConfig, 'expiresAt', searchByKey),
    },
    {
      title: 'Status',
      key: 'status',
      render: (_value: any, record: DeviceRow) => (
        <Tooltip placement="bottom" title={<StatusTooltipText row={record} />}>
          <span>
            {record.status ? DEVICE_STATUS[record.status as keyof typeof DEVICE_STATUS] || record.status : ''}
          </span>
        </Tooltip>
      ),
      ...getColumnFilterProps(searchConfig, 'statuses', statusFilters, searchByKey),
    },
    {
      title: 'Tracking To Patient',
      key: 'trackingNumberToPatient',
      dataIndex: 'trackingNumberToPatient',
      ...getColumnSearchProps(searchConfig, 'trackingNumberToPatientLike', searchByKey),
    },
    {
      title: 'Tracking To Lab',
      key: 'trackingNumberToLab',
      dataIndex: 'trackingNumberToLab',
      ...getColumnSearchProps(searchConfig, 'trackingNumberToLabLike', searchByKey),
    },
    {
      title: 'Fulfillment Type',
      key: 'fulfillmentType',
      render: (_value: any, record: DeviceRow) => <span>{record.order?.fulfillmentType}</span>,
    },
    {
      key: 'actions',
      title: 'Actions',
      align: 'center',
      width: 72,
      className: 'actionsColumn',
      onCell: () => ({
        onClick: (e) => {
          e.stopPropagation();
        },
      }),
      render: (_value: any, record: DeviceRow, index) => (
        <Dropdown
          trigger={['click']}
          overlay={
            <Menu
              onClick={(e) => {
                e.domEvent.stopPropagation();
              }}
            >
              <Menu.Item
                key="1"
                icon={<EyeOutlined />}
                onClick={() => onDeviceClick(record)}
                data-testid="device-table-actions-menu-view-details-option"
              >
                View details
              </Menu.Item>
              {patientExperienceEnabled && (
                <Menu.Item
                  key="2"
                  icon={<RedoOutlined />}
                  onClick={() => {
                    askConfirmResetActivationCode(record);
                  }}
                  data-testid="device-table-actions-menu-replace-activation-code-option"
                >
                  Replace activation code
                </Menu.Item>
              )}
            </Menu>
          }
        >
          <Button
            icon={<EllipsisOutlined />}
            type="text"
            size="middle"
            data-testid={`device-table-row-${index}-actions-button`}
            onClick={(e) => {
              e.stopPropagation();
            }}
          />
        </Dropdown>
      ),
    },
  ];

  const onRowClick = (row: any) => ({
    onClick: () => {
      onDeviceClick(row);
    },
  });

  const onTableChange = (pagination: TablePaginationConfig, _filters: any, sorter: any) => {
    setTablePagination((s) => ({ ...s, current: pagination.current, pageSize: pagination.pageSize }));

    const sortBy = sorter.columnKey || 'createdAt';
    const sortOrder = sorter.order === 'ascend' ? 'asc' : 'desc';

    const shouldUpdateSettings =
      devicePagingConfig === null ||
      devicePagingConfig.page !== pagination.current ||
      devicePagingConfig.pageLength !== (pagination.pageSize || pagination.defaultPageSize) ||
      devicePagingConfig.sortBy !== sortBy ||
      devicePagingConfig.sortOrder !== sortOrder;

    if (shouldUpdateSettings) {
      updatePagingConfig({
        page: pagination.current!,
        pageLength: pagination.pageSize!,
        sortBy,
        sortOrder,
        includeTotalCount: devicePagingConfig === null || devicePagingConfig.page === 1,
      });
    }
  };

  const pepareDevice = useCallback(
    (device: IDevice): DeviceRow => {
      const patient = getPatient(device.patientId) || {};

      return {
        id: device.id,
        lot:
          device.deviceLotId && deviceLotsHash[device.deviceLotId]
            ? deviceLots[deviceLotsHash[device.deviceLotId]]?.key || ''
            : '',
        barcode: device.barcode || '',
        activationCode: device.activationCode || '',
        patientExperienceStatus:
          PATIENT_EXPERIENCE_STATUS[device.patientExperienceStatus as keyof typeof PATIENT_EXPERIENCE_STATUS] ||
          device.patientExperienceStatus ||
          '',
        firstName: patient.firstName || '',
        lastName: patient.lastName || '',
        trackingNumberToPatient: device.trackingNumberToPatient || '',
        trackingNumberToLab: device.trackingNumberToLab || '',
        expiresAt: device.expiresAt || '',
        createdAt: device.createdAt || '',
        status: device.status || '',
        statusChangedAt: device.statusChangedAt || '',
        replacementReason: device.replacementReason || '',
        isReplaced: device.isReplaced,
        order: device.order,
      };
    },
    [getPatient, deviceLots, deviceLotsHash]
  );

  const dataSource = useMemo(() => (loading ? undefined : devices.map(pepareDevice)), [devices, loading, pepareDevice]);

  return (
    <div ref={tableWrapRef} className="DevicesList">
      {isFiltered && project && (
        <Alert
          showIcon
          style={{ margin: '30px 0' }}
          type="info"
          message={
            <>
              Showing devices for one patient.{' '}
              <Link
                to={`/projects/${project.id}/devices`}
                onClick={() => {
                  resetPagination();
                  window.dispatchEvent(new Event('resize'));
                }}
              >
                <Button type="link" size="small">
                  Show all patients&#39; devices
                </Button>
              </Link>
            </>
          }
        />
      )}
      <Table
        rowKey={(rowData, index) => `devices-table-row-index-${index}-deviceid-${rowData.id}`}
        columns={columns}
        dataSource={dataSource}
        loading={loading}
        onRow={onRowClick}
        scroll={{ y: tableHeight }}
        showSorterTooltip={false}
        onChange={onTableChange}
        pagination={tablePagination}
        size="small"
        bordered
      />
    </div>
  );
};

export default DevicesList;
