import React, { FC, useState, useEffect } from 'react';

import { Table, Button, Input, Select } from 'antd';
import { ColumnsType } from 'antd/lib/table/Table';

import { PlusCircleOutlined } from 'components/icons';
import { EmailTemplate, SmsTemplate } from 'context/notificationTemplates/types';
import { PatientReminderThresholds } from 'context/projects';

export type DataRow = { hours: string; smsTemplateId: string | null; emailTemplateId: string | null };

type Props = {
  editMode: boolean;
  thresholds: PatientReminderThresholds | null;
  emailTemplates: EmailTemplate[];
  smsTemplates: SmsTemplate[];
  onChange: (data: DataRow[]) => void;
};

const PatientReminderThresholdsComponent: FC<Props> = ({
  editMode,
  thresholds,
  emailTemplates,
  smsTemplates,
  onChange,
}) => {
  const [data, setData] = useState<DataRow[]>([]);

  useEffect(() => {
    const newDataset: DataRow[] = [];

    if (thresholds) {
      const keys = Object.keys(thresholds);
      for (const key of keys) {
        newDataset.push({
          hours: key,
          emailTemplateId: thresholds[key].emailTemplateId || '',
          smsTemplateId: thresholds[key].smsTemplateId || '',
        });
      }
    }

    setData(newDataset);
  }, [thresholds]);

  useEffect(() => {
    onChange(data);
  }, [data]);

  const updateData = (
    index: number,
    field: string & ('hours' | 'emailTemplateId' | 'smsTemplateId'),
    value: string
  ): void => {
    setData((d) => [...d.map((row, rowIndex) => (index === rowIndex ? { ...row, [field]: value } : row))]);
  };

  const getTemplateName = (templates: (SmsTemplate | EmailTemplate)[], id: string): string => {
    if (id) {
      return templates.find((tpl) => tpl.id === id)?.templateName || `WARNING: missing template ${id}`;
    }
    return '';
  };

  return (
    <>
      <Table
        dataSource={data}
        bordered
        size="small"
        columns={(
          [
            {
              key: 'hours',
              title: 'Hours',
              dataIndex: 'hours',
              width: 80,
              render: (_value, record, index) =>
                editMode ? (
                  <Input
                    value={record.hours}
                    onChange={(e) => {
                      const { value } = e.currentTarget;
                      setData([...data.map((row, rowIndex) => (index === rowIndex ? { ...row, hours: value } : row))]);
                    }}
                  />
                ) : (
                  <span>{record.hours}</span>
                ),
            },
            {
              key: 'emailTemplateId',
              title: 'Email template',
              dataIndex: 'emailTemplateId',
              render: (_value, record, recordIndex) =>
                editMode ? (
                  <Select
                    allowClear
                    showSearch
                    showArrow
                    style={{ width: 300 }}
                    defaultValue={record.emailTemplateId || ''}
                    options={emailTemplates.map((tpl) => ({ value: tpl.id, label: tpl.templateName }))}
                    onSelect={(_value2: string, option: { value: string }) => {
                      updateData(recordIndex, 'emailTemplateId', option.value);
                    }}
                    onClear={() => {
                      updateData(recordIndex, 'emailTemplateId', '');
                    }}
                    filterOption={(input, option) =>
                      (option as any).label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  />
                ) : (
                  <span>{getTemplateName(emailTemplates, record.emailTemplateId || '')}</span>
                ),
            },
            {
              key: 'smslTemplateId',
              title: 'SMS template',
              dataIndex: 'smslTemplateId',
              render: (_value, record, recordIndex) =>
                editMode ? (
                  <Select
                    allowClear
                    showSearch
                    showArrow
                    style={{ width: 300 }}
                    defaultValue={record.smsTemplateId || ''}
                    options={smsTemplates.map((tpl) => ({ value: tpl.id, label: tpl.templateName }))}
                    onSelect={(_value2: string, option: { value: string }) => {
                      updateData(recordIndex, 'smsTemplateId', option.value);
                    }}
                    onClear={() => {
                      updateData(recordIndex, 'smsTemplateId', '');
                    }}
                    filterOption={(input, option) =>
                      (option as any).label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  />
                ) : (
                  <span>{getTemplateName(smsTemplates, record.smsTemplateId || '')}</span>
                ),
            },
            {
              key: 'actions',
              title: 'Actions',
              align: 'center',
              width: 70,
              render: (_value, _record, recordIndex) => (
                <Button
                  type="link"
                  onClick={() => {
                    setData((d) => [...d.filter((_row, rowIndex) => rowIndex !== recordIndex)]);
                  }}
                >
                  Delete
                </Button>
              ),
            },
          ] as ColumnsType<DataRow>
        ).filter((col) => editMode || (!editMode && col.key !== 'actions'))}
        rowKey="id"
        pagination={false}
        style={{ maxWidth: '800px', width: '100%' }}
        footer={() =>
          editMode ? (
            <Button
              size="middle"
              type="link"
              icon={<PlusCircleOutlined />}
              style={{ padding: '5px 10px' }}
              onClick={() => {
                setData([...data, { hours: '', emailTemplateId: '', smsTemplateId: '' }]);
              }}
            >
              New threshold
            </Button>
          ) : undefined
        }
      />
    </>
  );
};

export default PatientReminderThresholdsComponent;
