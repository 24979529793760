import React, { FC, useEffect, useMemo, useState } from 'react';

import { Form, Modal, Select } from 'antd';
import { CustomAttributeDefinitionProject, IProject } from 'context/projects';
import { ICustomAttributeDefinition } from 'context/customAttributeDefinitions';
import { OrderTemplate } from '@tassoinc/core-api-client';
import { sortOrderTemplates } from 'utils/orderTemplates';
import CustomAttributesEntry, { CUSTOM_ATTRIBUTE_FIELD_PREFIX } from './CustomAttributeEntry';

type Props = {
  open: boolean;
  customAttributeDefinitions: Map<string, CustomAttributeDefinitionProject[] | ICustomAttributeDefinition[]>;
  project: IProject;
  orderTemplates: OrderTemplate[];
  onOk: (customAttributeValues: Record<string, string>, selectedOrderTemplate: string | null) => void;
  onCancel: () => void;
};

const PlaceOrderModal: FC<Props> = ({ open, customAttributeDefinitions, project, orderTemplates, onOk, onCancel }) => {
  const [form] = Form.useForm();
  const [selectedOrderTemplateId, setSelectedOrderTemplateId] = useState<string | null>(null);

  const handleCancel = () => {
    onCancel();
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((formData) => {
        const customAttributes = Object.keys(formData).reduce<Record<string, string>>((acc, fieldName) => {
          if (fieldName.startsWith(CUSTOM_ATTRIBUTE_FIELD_PREFIX)) {
            const cleanFieldName = fieldName.replace(CUSTOM_ATTRIBUTE_FIELD_PREFIX, '');
            acc[cleanFieldName] = formData[fieldName];
          }

          return acc;
        }, {});

        onOk(customAttributes, selectedOrderTemplateId);
        handleCancel();
      })
      .catch((info: any) => {
        console.log('Validate Failed:', info);
      });
  };

  useEffect(() => {
    if (open) {
      setSelectedOrderTemplateId(null);
      form.resetFields();
    }
  }, [open]);

  const sortedOrderTemplates = useMemo(
    () => sortOrderTemplates(orderTemplates, project.orderTemplatesViewOrder),
    [orderTemplates, project]
  );

  // Remove all custom attributes values from the form and reset them whenever selected order template changes.
  // Each order template comes with its own configuration, it's important to reset it on template change.
  useEffect(() => {
    const formData = form.getFieldsValue();

    const customAttributeFields = Object.keys(formData).filter((v) => v.startsWith('order:'));

    form.resetFields(customAttributeFields);
  }, [selectedOrderTemplateId]);

  return (
    <Modal title="Place Order" visible={open} onOk={handleOk} onCancel={handleCancel}>
      <Form form={form} layout="vertical" initialValues={{ customAttributeValues: {} }}>
        {project.useOrderTemplates && (
          <Form.Item
            name="orderTemplateId"
            label="Order Configuration"
            rules={[
              {
                required: true,
                message: 'Please select order config',
              },
            ]}
          >
            <Select placeholder="Please select an order configuration" onChange={(v) => setSelectedOrderTemplateId(v)}>
              {sortedOrderTemplates
                .filter((template) => !template.isCustomerOrderable === false)
                .map((orderTemplate) => (
                  <Select.Option key={orderTemplate.id} value={orderTemplate.id}>
                    {orderTemplate.name}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        )}

        <CustomAttributesEntry
          attributeDefinitions={customAttributeDefinitions}
          selectedOrderTemplateId={selectedOrderTemplateId}
          useOrderTemplates={project.useOrderTemplates}
        />
      </Form>
    </Modal>
  );
};

export default PlaceOrderModal;
