import React, { FC, useEffect, useState } from 'react';

import { Button, Form, FormInstance, Modal, Upload, Typography } from 'antd';
import { RcFile, UploadChangeParam } from 'antd/lib/upload/interface';
import { UploadOutlined } from 'components/icons';

interface IdPoolIdentifierUploadModalProps {
  form: FormInstance<{ upload: UploadChangeParam<RcFile> }>;
  visible: boolean;
  isSubmitting: boolean;
  onOk: (file: RcFile) => void;
  onCancel: () => void;
}

const IdPoolIdentifierUploadModal: FC<IdPoolIdentifierUploadModalProps> = ({
  form,
  visible,
  isSubmitting,
  onOk,
  onCancel,
}) => {
  const [selectedFile, setSelectedFile] = useState<RcFile | null>(null);

  const handleFormSubmit = () => {
    if (isSubmitting) {
      return;
    }

    if (selectedFile) {
      onOk(selectedFile);
    }
  };

  const handleFileSelect = (file: RcFile) => {
    setSelectedFile(file);
    return false;
  };

  const handleRemoveFile = () => {
    setSelectedFile(null);

    return true; // tells Upload to remove file from the list
  };

  useEffect(() => {
    form.resetFields();
    setSelectedFile(null);
  }, [visible]);

  return (
    <Modal
      wrapClassName="Modal AnalyteModal"
      visible={visible}
      title="Upload Id Pool Identifiers"
      okText="Submit"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={handleFormSubmit}
      confirmLoading={isSubmitting}
      okButtonProps={{
        disabled: selectedFile === null || isSubmitting,
      }}
      centered
      width="360px"
    >
      <Form className="Form AnalyteModal__Form" form={form} layout="vertical" name="IdPoolIdentifierModalForm">
        <Typography.Text style={{ marginBottom: 16 }}>
          File requirements:
          <ul>
            <li>text/csv file type</li>
            <li>one column</li>
            <li>no header</li>
            <li>at most 1,000 rows</li>
            <li>row order determines consumption order</li>
          </ul>
        </Typography.Text>

        <Form.Item name="upload">
          <Upload
            beforeUpload={(file) => handleFileSelect(file)}
            onRemove={handleRemoveFile}
            accept=".csv"
            fileList={
              selectedFile === null
                ? []
                : [
                    {
                      uid: selectedFile.uid,
                      name: selectedFile.name,
                      size: selectedFile.size,
                      type: selectedFile.type,
                      originFileObj: selectedFile,
                    },
                  ]
            }
            disabled={isSubmitting}
          >
            {selectedFile === null && (
              <Button size="middle" icon={<UploadOutlined />} disabled={isSubmitting}>
                Select .csv file
              </Button>
            )}
          </Upload>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default IdPoolIdentifierUploadModal;
