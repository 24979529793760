import React, { FC } from 'react';

import { Button, Form, Input, Typography } from 'antd';
import { useLocation, useHistory, Redirect } from 'react-router-dom';

import { LockOutlined, MailOutlined } from 'components/icons';
import { ROUTES } from 'constants/index';
import Notification from 'utils/notification';

interface IConfirmPasswordForm {
  loading: boolean;
  onSubmit: (email: string, key: string, password: string) => void;
}

const { Title } = Typography;

const ConfirmPasswordForm: FC<IConfirmPasswordForm> = ({ loading, onSubmit }) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const query = new URLSearchParams(useLocation().search);
  const key = query.get('key');

  const navigateToLogin = () => {
    history.push(ROUTES.login);
  };

  const handleFormSubmit = () => {
    form
      .validateFields()
      .then((values: any) => {
        onSubmit(values.email || '', key || '', values.password);
      })
      .catch((info: any) => {
        console.log('Validate Failed:', info);
      });
  };

  if (!key) {
    Notification({ type: 'error', message: 'Invalid link!' });

    return <Redirect to={ROUTES.login} />;
  }

  return (
    <Form
      className="ForgotPassword__Form"
      form={form}
      layout="vertical"
      name="SetNewPasswordForm"
      hideRequiredMark
      initialValues={{ password: '', confirmPassword: '' }}
      onFinish={handleFormSubmit}
    >
      <Title level={4} type="secondary">
        Create new password
      </Title>

      <Form.Item
        name="email"
        rules={[
          {
            required: true,
            message: 'Please input your email!',
          },
          {
            type: 'email',
            message: 'Invalid Email format!',
          },
        ]}
      >
        <Input prefix={<MailOutlined />} placeholder="Enter your Email" />
      </Form.Item>

      <Form.Item
        name="password"
        rules={[
          {
            required: true,
            message: 'Please input your password!',
          },
          {
            min: 8,
            message: 'Password must be at least 8 characters long!',
          },
        ]}
      >
        <Input.Password prefix={<LockOutlined />} placeholder="Enter new password" />
      </Form.Item>

      <Form.Item
        name="confirmPassword"
        dependencies={['password']}
        rules={[
          {
            required: true,
            message: 'Please confirm your password!',
          },
          ({ getFieldValue }) => ({
            validator(_rule, value): Promise<any> {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }

              // eslint-disable-next-line prefer-promise-reject-errors
              return Promise.reject('Two passwords must match!');
            },
          }),
        ]}
      >
        <Input.Password prefix={<LockOutlined />} placeholder="Confirm new password" />
      </Form.Item>

      <Form.Item>
        <Button block type="primary" htmlType="submit" loading={loading}>
          Submit
        </Button>
      </Form.Item>

      <Form.Item>
        <Button block type="link" onClick={navigateToLogin}>
          Back to Login
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ConfirmPasswordForm;
