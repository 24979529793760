import { httpClient, RequestConfig, handleResponseError } from 'services';
import { PagingRequest } from 'utils/types';

export interface LoadPatientsParameters {
  query?: Record<string, any>;
}

export const loadPatients = (
  params: LoadPatientsParameters,
  paging: PagingRequest,
  configOverride: RequestConfig = {}
): Promise<any> =>
  httpClient
    .get('/patients', { params: { ...(params.query ? params.query : {}), ...paging }, ...configOverride })
    .then((_) => _.data)
    .catch(handleResponseError);

export const getPatients = (
  params: Record<string, string>,
  paging: PagingRequest,
  configOverride: RequestConfig = {}
): Promise<any> =>
  httpClient
    .get('/patients', { params: { ...params, ...paging }, ...configOverride })
    .then((_) => _.data)
    .catch(handleResponseError);

export const getPatient = (id: any): Promise<any> => httpClient.get(`/patients/${id}`).then((_) => _.data.results);

export const createPatient = (data: any): Promise<any> =>
  httpClient.post('/patients', data).then((_) => _.data.results);

export const updatePatient = (id: any, data: any): Promise<any> =>
  httpClient.patch(`/patients/${id}`, data).then((_) => _.data.results);

export const deletePatient = (id: string): Promise<any> =>
  httpClient.delete(`/patients/${id}`).then((_) => _.data.results);
