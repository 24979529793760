import { httpClient } from 'services/index';
import { PagingRequest } from 'utils/types';

export const getLaboratories = (payload: Record<string, any>, paging: PagingRequest): Promise<any> =>
  httpClient.get('/laboratories', { params: { ...payload, ...paging } }).then((_) => _.data);

export const getLaboratory = (id: string, headers: Record<string, any> = {}): Promise<any> =>
  httpClient.get(`/laboratories/${id}`, { headers }).then((_) => _.data.results);

export const createLaboratory = (data: any): Promise<any> =>
  httpClient.post('/laboratories', data).then((_) => _.data.results);

export const updateLaboratory = (id: string, data: any, headers: Record<string, string> = {}): Promise<any> =>
  httpClient
    .patch(`/laboratories/${id}`, data, {
      headers,
    })
    .then((_) => _.data.results);

export const deleteLaboratory = (id: string): Promise<any> =>
  httpClient.delete(`/laboratories/${id}`).then((_) => _.data.results);
