/* eslint-disable react/jsx-no-target-blank */
import React, { FC } from 'react';

import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';

import { IProject } from 'context/projects';

interface IProjectRecipients {
  projects: IProject[];
}

const CustomerProjectsList: FC<IProjectRecipients> = ({ projects }) => {
  const columns: ColumnsType<IProject> = [
    {
      key: 'name',
      dataIndex: 'name',
      title: 'Name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ['descend', 'ascend'],
      render: (_, record) => (
        <a href={`/projects/${record.id}`} target="_blank" rel="noopener">
          {record.name}
        </a>
      ),
    },
    {
      key: 'status',
      dataIndex: 'status',
      title: 'Status',
    },
  ];

  if (projects.length === 0) {
    return <p>Customer is not associated with any projects</p>;
  }

  return (
    <div className="CustomerProjectsList">
      <h3 className="CustomerProjectsList__Heading">Projects</h3>

      <Table
        rowKey="id"
        size="small"
        columns={columns}
        bordered
        dataSource={projects}
        showSorterTooltip={false}
        scroll={{ y: 'calc(100vh - 362px)' }}
        pagination={false}
      />
    </div>
  );
};

export default CustomerProjectsList;
