import React, { FC } from 'react';

import { Select, Typography, Space } from 'antd';

import { DISTRIBUTION_MODELS } from 'constants/index';

type DistributionModel = keyof typeof DISTRIBUTION_MODELS;

type Props = {
  onChange: (model: DistributionModel) => void;
  value: string;
};

const DistributionModelSelectField: FC<Props> = ({ onChange, value }) => {
  const handleSelect = (v: string) => {
    onChange(v as DistributionModel);
  };

  return (
    <div className="SelectField">
      <Space direction="vertical">
        <Typography.Text strong>Distribution Model</Typography.Text>

        <Select
          onSelect={handleSelect}
          placeholder="Select distribution model"
          value={value}
          options={Object.keys(DISTRIBUTION_MODELS).map((k) => ({
            label: DISTRIBUTION_MODELS[k as DistributionModel],
            value: k,
          }))}
          data-testId="project-distribution-model"
        />
      </Space>
    </div>
  );
};

export default DistributionModelSelectField;
