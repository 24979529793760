import React, { useState, useEffect } from 'react';

import { ICustomAttributeDefinition, useCustomAttributeDefinitions } from 'context/customAttributeDefinitions';
import OrderTemplateCustomAttributeSelectModal from 'components/OrderTemplateCustomAttributeSelectModal';

interface State {
  usedAnalyteIds: string[];
  customAttributeDefinitions: ICustomAttributeDefinition[];
  onAttributeChange: (analyteIds: string[]) => void;
}

const getInitialState = (): State => ({
  usedAnalyteIds: [],
  customAttributeDefinitions: [],
  onAttributeChange: () => {},
});

const useCustomAttributeSelectModal = (onAttributeChange: (a: string[]) => void) => {
  const { getCustomAttributeDefinitions, error } = useCustomAttributeDefinitions();

  const [modalVisible, setModalVisible] = useState(false);
  const [working, setWorking] = useState(false);

  const [settings, setSettings] = useState<State>(getInitialState());

  useEffect(() => {
    if (error) {
      setWorking(false);
    }
  }, [error]);

  const closeModal = () => {
    setModalVisible(false);
    setSettings(getInitialState());
  };

  const handleChange = async (attributeIds: string[]): Promise<void> => {
    onAttributeChange(attributeIds);
    closeModal();
  };

  const openModal = (customerId: string, onChange: (customAttributeDefinitionIds: string[]) => void) => {
    setWorking(true);
    setModalVisible(true);

    getCustomAttributeDefinitions(customerId, async (attributes) => {
      setSettings({
        usedAnalyteIds: [],
        customAttributeDefinitions: attributes?.filter((attrib) => !attrib.deprecated) || [],
        onAttributeChange: onChange,
      });
      setWorking(false);
    });
  };

  const Modal = (
    <OrderTemplateCustomAttributeSelectModal
      customAttributeDefinitions={settings.customAttributeDefinitions}
      visible={modalVisible}
      onOk={handleChange}
      onCancel={closeModal}
      working={working}
    />
  );

  return { Modal, openModal };
};

export default useCustomAttributeSelectModal;
