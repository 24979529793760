import { httpClient } from 'services/index';
import { PagingRequest } from 'utils/types';

export interface GetProjectMetricsParams {
  query: { projectIds?: string };
}
export const getProjectMetrics = (params: GetProjectMetricsParams, paging: PagingRequest): Promise<any> =>
  httpClient
    .get('/projectMetrics', {
      params: { ...params.query, ...paging },
    })
    .then((_) => _.data);
