import axios, { AxiosHeaders, AxiosRequestConfig, CancelTokenSource, InternalAxiosRequestConfig } from 'axios';

import Auth from '../utils/cognito';

type CustomAxiosRequestConfig = InternalAxiosRequestConfig & {
  ignoreAuthorization: boolean;
};

const addAuthHeaderInterceptor = async (
  axiosConfig: InternalAxiosRequestConfig
): Promise<InternalAxiosRequestConfig> => {
  const config = { ...axiosConfig } as CustomAxiosRequestConfig;

  const baseURL = localStorage.getItem('CORE_API_URL');
  if (baseURL) {
    config.baseURL = baseURL;
  }

  if (config.ignoreAuthorization) {
    return config;
  }

  try {
    const session = await Auth.currentSession();
    const token = session.getIdToken().getJwtToken();

    if (token) {
      if (config.headers) {
        config.headers.Authorization = `Bearer ${token}`;
      } else {
        config.headers = AxiosHeaders.concat(config.headers, { Authorization: `Bearer ${token}` });
      }
    } else {
      console.log('Error: invalid token');
    }
  } catch (e) {
    console.log('Error: not authenticated', e);
  }

  return config;
};

const httpClient = axios.create({
  baseURL: process.env.API_URL,
});

httpClient.interceptors.request.use(addAuthHeaderInterceptor);

const responseErrorLogger = async (error: any): Promise<any> => {
  if (axios.isCancel(error)) {
    // this means we're manually cancelling a request - it's not an error per se, so don't log anything
    // simply return it
    return Promise.reject(error);
  }

  let methodStr;
  let urlStr;

  if (error.response) {
    methodStr = error.response.config.method ? error.response.config.method.toUpperCase() : '';
    urlStr = (error.response.config.baseURL || '') + (error.response.config.url || '');
    console.error(`Response error: ${methodStr} ${urlStr}:`, error.response);
  } else if (error.config) {
    methodStr = error.config.method ? error.config.method.toUpperCase() : '';
    urlStr = (error.config.baseURL || '') + (error.config.url || '');
    console.error(`Response error: ${methodStr} ${urlStr}:`, error);
  } else {
    console.error(error);
  }

  return Promise.reject(error);
};

httpClient.interceptors.response.use(undefined, responseErrorLogger);

export const makeCancelTokenSource = (): CancelTokenSource => axios.CancelToken.source();

export type RequestConfig = AxiosRequestConfig;
export type { CancelTokenSource } from 'axios';

export type ClientCancelError = { isCancelledByClient: boolean };

export const isRequestCancelled = <T = any>(obj: ClientCancelError | T): obj is ClientCancelError => {
  if (typeof obj === 'object' && obj !== null) {
    return (obj as ClientCancelError).isCancelledByClient === true;
  }
  return false;
};

// If the error is Cancel (meaning we cancelled the request manually), return an object
// Otherwise rethrow the error and let upstream logic handle it
export const handleResponseError = (error: Error): ClientCancelError => {
  if (axios.isCancel(error)) {
    return { isCancelledByClient: true };
  }
  throw error;
};

export default httpClient;
