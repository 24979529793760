/* eslint-disable jsx-a11y/anchor-is-valid,jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';

import { Input } from 'antd';
import { TagOutlined } from '@ant-design/icons';
import Link from 'antd/lib/typography/Link';
import clsx from 'clsx';

interface TagSelectorProps {
  value: string | null;
  disabled: boolean;
  onChange: (type: string | null) => void;
}

const TagSelector: React.FC<TagSelectorProps> = ({ value, disabled, onChange }) => {
  const [edit, setEdit] = useState(false);

  const cancelEdit = (): void => {
    if (disabled) {
      return;
    }

    setEdit(false);
  };

  const enableEdit = (): void => {
    if (disabled) {
      return;
    }

    setEdit(true);
  };

  const cancelOnKeyPress = (e: React.KeyboardEvent) => {
    if (e.code === 'Enter') {
      cancelEdit();
    }
  };

  if (!edit) {
    if (value) {
      return (
        <span className={clsx({ TagSelector: true, Selected: true })} onClick={enableEdit}>
          <Link>{value}</Link> <TagOutlined />
        </span>
      );
    }

    return (
      <span className={clsx({ TagSelector: true })} onClick={enableEdit}>
        <Link>Add tag</Link> <TagOutlined />
      </span>
    );
  }

  return (
    <span className={clsx({ TagSelector: true })}>
      <Input
        disabled={disabled}
        autoFocus
        onBlur={cancelEdit}
        onKeyPress={cancelOnKeyPress}
        style={{ width: 'auto' }}
        size="small"
        value={value || ''}
        onChange={(e) => onChange(e.currentTarget.value || null)}
      />{' '}
      <TagOutlined />
    </span>
  );
};

export default TagSelector;
