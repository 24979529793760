import React, { FC, createContext, useState } from 'react';

import { projectMetricsApi } from 'api';
import { loadAllPages } from 'utils/functions';
import * as issues from 'utils/issues';
import Notification from 'utils/notification';

type ProjectMetricsApiResponse = {
  id: string;
  createdAt: string;
  updatedAt: string | null;
  projectId: string;
  customerId: string;
  deviceCount: string;
  devicesWhereStatusIsInStockCount: string;
  devicesWhereStatusIsReadyToShipCount: string;
  devicesWhereStatusIsAwaitingPickupCount: string;
  devicesWhereStatusIsInTransitToPatientCount: string;
  devicesWhereStatusIsAtPatientCount: string;
  devicesWhereStatusIsInTransitToLabCount: string;
  devicesWhereStatusIsAtLabCount: string;
  devicesWhereStatusIsResultsReadyCount: string;
  devicesWhereStatusIsReturnToSenderCount: string;
  devicesWhereStatusIsUnknownCount: string;
  devicesWhereStatusIsErrorCount: string;
  devicesWhereStatusIsDeletedCount: string;
  devicesWhereStatusIsReplacedCount: string;
};

export type ProjectMetrics = {
  id: string;
  createdAt: string;
  updatedAt: string | null;
  projectId: string;
  customerId: string;
  deviceCount: bigint;
  devicesWhereStatusIsInStockCount: bigint;
  devicesWhereStatusIsReadyToShipCount: bigint;
  devicesWhereStatusIsAwaitingPickupCount: bigint;
  devicesWhereStatusIsInTransitToPatientCount: bigint;
  devicesWhereStatusIsAtPatientCount: bigint;
  devicesWhereStatusIsInTransitToLabCount: bigint;
  devicesWhereStatusIsAtLabCount: bigint;
  devicesWhereStatusIsResultsReadyCount: bigint;
  devicesWhereStatusIsReturnToSenderCount: bigint;
  devicesWhereStatusIsUnknownCount: bigint;
  devicesWhereStatusIsErrorCount: bigint;
  devicesWhereStatusIsDeletedCount: bigint;
  devicesWhereStatusIsReplacedCount: bigint;
};

type State = {
  metrics: ProjectMetrics[];
  loading: boolean;
  error: string | null;
};

interface GetProjectMetricsParams {
  projectIds?: string[];
}
type GetProjectMetricsCallback = (metrics: ProjectMetrics[] | null) => Promise<void>;
type GetProjectMetricsFunction = (params?: GetProjectMetricsParams, cb?: GetProjectMetricsCallback) => Promise<void>;

type ResetProjectMetricsFunction = () => void;

type Context = State & {
  getProjectMetrics: GetProjectMetricsFunction;
  resetProjectMetrics: ResetProjectMetricsFunction;
};

const getInitialState = (): State => ({
  metrics: [],
  loading: false,
  error: null,
});

const ProjectMetricsContext = createContext<Context>({
  ...getInitialState(),
  getProjectMetrics: async () => {},
  resetProjectMetrics: () => {},
});

const ProjectMetricsProvider: FC<any> = (props) => {
  const [state, setState] = useState(getInitialState);

  const getProjectMetrics: GetProjectMetricsFunction = async (params = {}, callback) => {
    setState((s) => ({ ...s, error: null, loading: true }));

    const query: Record<string, any> = {};

    if (params.projectIds) {
      query.projectIds = params.projectIds.join(',');
    }

    try {
      type PayloadType = Parameters<typeof projectMetricsApi.getProjectMetrics>[0];
      const metrics = await loadAllPages<ProjectMetricsApiResponse, PayloadType>(
        projectMetricsApi.getProjectMetrics,
        { query },
        {
          pageLength: 100,
          sortBy: 'createdAt',
          isDescending: false,
        }
      );

      const formattedMetrics = metrics.map((projectMetrics) =>
        Object.keys(projectMetrics).reduce(
          (acc, k) => ({
            ...acc,
            [k]: k.endsWith('Count')
              ? BigInt(projectMetrics[k as keyof typeof projectMetrics]!)
              : projectMetrics[k as keyof typeof projectMetrics],
          }),
          {} as ProjectMetrics
        )
      );

      setState((s) => ({ ...s, metrics: formattedMetrics }));

      if (callback) {
        await callback(formattedMetrics);
      }
    } catch (e) {
      const err = issues.getErrorMessage(e);

      setState((s) => ({ ...s, error: err }));

      Notification({
        type: 'error',
        message: 'Failed to load project metrics',
        description: err,
      });

      if (callback) {
        await callback(null);
      }
    } finally {
      setState((s) => ({ ...s, loading: false }));
    }
  };

  const resetProjectMetrics: ResetProjectMetricsFunction = () => {
    setState(getInitialState);
  };

  return (
    <ProjectMetricsContext.Provider
      value={{
        metrics: state.metrics,
        loading: state.loading,
        error: state.error,
        getProjectMetrics,
        resetProjectMetrics,
      }}
      {...props}
    />
  );
};

const useProjectMetrics = (): Context => React.useContext(ProjectMetricsContext);

export { ProjectMetricsProvider, useProjectMetrics };
