import React, { useEffect } from 'react';

import { ShippingLabelsV2Modal } from 'components/ShippingLabelsV2Modal';
import { useDownloads } from 'context/downloads';
import { LABEL_STATUS, useShippingLabelsV2 } from 'context/shippingLabelsV2';

const useShippingLabelsV2Modal = () => {
  const { labels, resetLabelsV2, createMode, disposition, showModal } = useShippingLabelsV2();
  const { downloadShippingLabelsV2, loading: downloadInProgress } = useDownloads();

  const itemsReady = labels.every((label) => label.status !== LABEL_STATUS.inProgress);
  const itemsSuccess = labels.every((label) => label.status === LABEL_STATUS.success);
  const shipmentIds = labels.map((label) => label.shipmentId);

  useEffect(() => {
    if (createMode && itemsReady && itemsSuccess && shipmentIds.length > 0) {
      // auto-request pdf file generation
      downloadShippingLabelsV2(shipmentIds, disposition);
    }
  }, [itemsReady]);

  const Modal = (
    <ShippingLabelsV2Modal
      labels={labels}
      onCancel={resetLabelsV2}
      createMode={createMode}
      itemsReady={itemsReady}
      itemsSuccess={itemsSuccess}
      downloadInProgress={downloadInProgress}
      showModal={showModal}
      onGeneratePdf={() => downloadShippingLabelsV2(shipmentIds, disposition)}
    />
  );

  return { Modal };
};

export default useShippingLabelsV2Modal;
