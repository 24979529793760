/* eslint-disable no-await-in-loop */
import React, { useState, useEffect } from 'react';

import ProjectAnalyteLinkModal from 'components/ProjectAnalyteLinkModal';
import { Analyte, useAnalytes } from 'context/analytes';

interface State {
  labAdapterKey: string | undefined;
  usedAnalyteIds: string[];
  filteredAnalytes: Analyte[];
  onAnalyteChange: (analyteIds: string[]) => void;
}

const getInitialState = (): State => ({
  labAdapterKey: undefined,
  usedAnalyteIds: [],
  filteredAnalytes: [],
  onAnalyteChange: () => {},
});

const useAnalyteSelectModal = (onAnalyteChange: (a: string[]) => void) => {
  const { getAnalytes, error: analytesError } = useAnalytes();

  const [modalVisible, setModalVisible] = useState(false);
  const [working, setWorking] = useState(false);

  const [settings, setSettings] = useState<State>(getInitialState());

  useEffect(() => {
    if (analytesError) {
      setWorking(false);
    }
  }, [analytesError]);

  const closeModal = () => {
    setModalVisible(false);
    setSettings(getInitialState());
  };

  const handleChange = async (analyteIds: string[]): Promise<void> => {
    onAnalyteChange(analyteIds);
    closeModal();
  };

  const openModal = (
    labAdapterKey: string | undefined,
    usedAnalyteIds: string[],
    onChange: (analyteId: string[]) => void
  ) => {
    setWorking(true);
    setModalVisible(true);

    getAnalytes({ labAdapterKey }, async (analytes) => {
      const availableAnalytes = analytes?.filter((a) => !usedAnalyteIds.includes(a.id)) || [];
      setSettings({ labAdapterKey, usedAnalyteIds, filteredAnalytes: availableAnalytes, onAnalyteChange: onChange });
      setWorking(false);
    });
  };

  const Modal = (
    <ProjectAnalyteLinkModal
      analytes={settings.filteredAnalytes}
      visible={modalVisible}
      onOk={handleChange}
      onCancel={closeModal}
      working={working}
    />
  );

  return { Modal, openModal };
};

export default useAnalyteSelectModal;
