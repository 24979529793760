import React, { FC, useState, useEffect, useRef } from 'react';

import { Modal, InputNumber, Select, Form, FormInstance } from 'antd';
import { CustomAttributeDefinitionProject, IProject } from 'context/projects';
import { OrderTemplate, Site } from '@tassoinc/core-api-client';
import { useOrderTemplates } from 'context/orderTemplates';
import { useSites } from 'context/sites';
import { getDefaultOrderTemplate } from 'utils/orderTemplates';
import { CreateOrderPayload } from 'api/orders';
import Notification from 'utils/notification';
import { useOrders } from 'context/orders';
import { ICustomAttributeDefinition } from 'context/customAttributeDefinitions';
import { generateCustomAttributeElements, getSelectedCustomAttributes } from 'utils/customAttributeDefinitions';

type Props = {
  open?: boolean;
  loading: boolean;
  onClose: () => void;
  project: IProject | null;
  orderTemplates: OrderTemplate[];
  sites: Site[];
  customAttributeDefinitions: Map<string, ICustomAttributeDefinition[] | CustomAttributeDefinitionProject[]>;
};

const CreateBulkOrderModal: FC<Props> = ({ open, loading, onClose, project, customAttributeDefinitions }) => {
  const [value, setValue] = useState<number>(0);
  const form = useRef<FormInstance>(null);
  const { orderTemplates, getOrderTemplatesForProject, resetOrderTemplates } = useOrderTemplates();
  const { sites, getSitesForCustomer, resetSites } = useSites();
  const { createOrder } = useOrders();
  const [selectedOrderTemplateId, setSelectedOrderTemplateId] = useState<string | null>(null);
  const [showCustomAttributeFields, setShowCustomAttributeFields] = useState<boolean>(false);
  const [selectedCustomAttributes, setSelectedCustomAttributes] = useState<
    ICustomAttributeDefinition[] | CustomAttributeDefinitionProject[]
  >([]);

  useEffect(() => {
    // Set default order template
    const defaultTemplate = getDefaultOrderTemplate(orderTemplates, project!.orderTemplatesViewOrder);
    if (!selectedOrderTemplateId) {
      setSelectedOrderTemplateId(defaultTemplate?.id || null);
      form.current?.setFieldsValue({ orderTemplateId: defaultTemplate?.id });
    }
  }, [orderTemplates, project, customAttributeDefinitions]);

  useEffect(() => {
    if (!customAttributeDefinitions) {
      return;
    }
    const attributes = getSelectedCustomAttributes(selectedOrderTemplateId, project, customAttributeDefinitions);
    setSelectedCustomAttributes(attributes);
    setShowCustomAttributeFields(project?.distributionModel === 'atCustomerSite' && attributes.length > 0);
  }, [customAttributeDefinitions, selectedOrderTemplateId, project]);

  const handleSubmit = (): void => {
    const customAttributeValues: Record<string, any> = {};
    form.current
      ?.validateFields()
      .then((values: CreateOrderPayload) => {
        const allKeys = Object.keys(values);
        const customAttributeKeys = allKeys.filter((key) => key.startsWith('order:'));

        customAttributeKeys.forEach((key) => {
          const attributeName = key.replace('order:', '');
          customAttributeValues[attributeName] = values[key as keyof typeof values];
        });

        const filteredValues = allKeys
          .filter((key) => !customAttributeKeys.includes(key))
          .reduce((acc, key) => ({ ...acc, [key]: values[key as keyof typeof values] }), {}) as any;

        const withProject = {
          ...filteredValues,
          customAttributeValues: Object.keys(customAttributeValues).length > 0 ? customAttributeValues : undefined,
          projectId: project?.id,
        };

        createOrder(withProject).catch((e: any) => {
          console.log('ERROR CAUGHT');
          console.log(e);
          Notification({ type: 'error', message: e.message });
        });
        onClose();
      })
      .catch((e: any) => {
        console.log('Validation failed:');
        console.log(e);
      });
  };

  useEffect(() => {
    if (open) {
      form.current?.setFieldsValue({
        templateName: '',
        siteId: '',
        quantity: '',
      });
      selectedCustomAttributes.forEach((customAttribute) =>
        form.current?.setFieldsValue({ [`order:${customAttribute.name}`]: '' })
      );
    }
  }, [open]);

  useEffect(() => {
    getOrderTemplatesForProject(project!.id);
    getSitesForCustomer(project!.customerId);

    return () => {
      resetOrderTemplates();
      resetSites();
    };
  }, []);

  return (
    <Modal
      wrapClassName="Modal Normal"
      centered
      forceRender
      width="460px"
      visible={open}
      title="Create Unassigned Orders"
      onCancel={onClose}
      cancelText="Cancel"
      okText={`Create ${value} device${value === 1 ? '' : 's'}`}
      onOk={handleSubmit}
      confirmLoading={loading}
      okButtonProps={{ disabled: loading || value === 0 }}
      cancelButtonProps={{ disabled: loading }}
    >
      <Form ref={form} layout="vertical">
        <p>Orders placed using this modal will be linked to this project but will not be assigned to a patient.</p>

        <Form.Item name="orderTemplateId" label="Order Configuration">
          <Select placeholder="Please select an order configuration" onSelect={setSelectedOrderTemplateId}>
            {orderTemplates
              .filter((template) => !template.isCustomerOrderable === false)
              .map((orderTemplate) => (
                <Select.Option key={orderTemplate.id} value={orderTemplate.id}>
                  {orderTemplate.name}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="siteId"
          label="Site"
          rules={[
            {
              required: true,
              message: `Please input site!`,
            },
          ]}
        >
          <Select placeholder="Please select a site">
            {sites.map((site) => (
              <Select.Option key={site.id} value={site.id}>
                {site.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item name="quantity" label="Quantity" required>
          <InputNumber
            size="large"
            onChange={(e) => {
              setValue(Number.isNaN(e) || e! < 1 ? 0 : e!);
            }}
            disabled={loading}
            bordered
            min={0}
            max={1000}
            step={value < 100 ? 10 : 20}
          />
        </Form.Item>

        {showCustomAttributeFields && (
          <>
            <h3>Custom Order Attributes</h3>
            {generateCustomAttributeElements(selectedCustomAttributes)}
          </>
        )}
      </Form>
    </Modal>
  );
};

export default CreateBulkOrderModal;
