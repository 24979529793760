import { httpClient, RequestConfig, handleResponseError } from 'services';
import { PagingRequest } from 'utils/types';

export const getCustomAttributeDefinitions = (
  params: Record<string, string>,
  paging: PagingRequest,
  configOverride: RequestConfig = {}
): Promise<any> =>
  httpClient
    .get('/customAttributeDefinitions', { params: { ...params, ...paging }, ...configOverride })
    .then((_) => _.data)
    .catch(handleResponseError);

export const getCustomAttributeDefinition = (id: any): Promise<any> =>
  httpClient.get(`/customAttributeDefinitions/${id}`).then((_) => _.data.results);

export const createCustomAttributeDefinition = (data: any): Promise<any> =>
  httpClient.post('/customAttributeDefinitions', data).then((_) => _.data.results);

export const updateCustomAttributeDefinition = (id: any, data: any): Promise<any> =>
  httpClient.patch(`/customAttributeDefinitions/${id}`, data).then((_) => _.data.results);
