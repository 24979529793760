import React, { FC, useEffect } from 'react';

import { Select, Button, Typography, Row, Col, Space } from 'antd';

import { PlusCircleOutlined } from 'components/icons';
import { useContacts } from 'context/contacts';
import { User } from 'context/users/types';
import useContactModal from 'hooks/useContactModal';

const { Option } = Select;

const { Text } = Typography;

interface IContactSelectField {
  onChange: (contactId: any) => void;
  customerId: string | null;
  value: string | null;
}

const ContactSelectField: FC<IContactSelectField> = ({ onChange, customerId, value }) => {
  const { contacts, getContacts, loading } = useContacts();
  const { Modal: ContactModal, onCreateContact } = useContactModal();

  useEffect(() => {
    if (customerId) {
      getContacts({ customerId, roles: ['customerAdmin'] });
    }
  }, [customerId]);

  const handleSelect = (contactId: any) => {
    onChange(contactId);
  };

  return (
    <div className="SelectField">
      <Space direction="vertical">
        <Text strong>Contact</Text>

        <Row>
          <Col span={20}>
            <Select
              disabled={!customerId}
              showSearch
              onSelect={handleSelect}
              placeholder="Select contact"
              loading={loading}
              value={value ?? undefined}
              data-testId="project-contact"
            >
              {contacts.map((contact: User) => (
                <Option key={contact.id} value={contact.id}>
                  {contact.firstName || contact.lastName
                    ? `${contact.firstName || ''} ${contact.lastName || ''}`.trim()
                    : contact.username}
                </Option>
              ))}
            </Select>
          </Col>

          <Col span={4}>
            <Button type="link" disabled={!customerId} onClick={() => onCreateContact(customerId)}>
              <PlusCircleOutlined />
            </Button>
          </Col>
        </Row>
      </Space>

      {ContactModal}
    </div>
  );
};

export default ContactSelectField;
