import React, { FC, useState, useEffect } from 'react';

import { Table, Dropdown, Menu, Button, Modal, Popover } from 'antd';
import { FilterValue, SorterResult, TablePaginationConfig } from 'antd/lib/table/interface';
import { Link } from 'react-router-dom';

import { EditOutlined, DeleteOutlined, EllipsisOutlined, ExclamationCircleOutlined } from 'components/icons';
import { Customer } from 'context/customers';
import { EmailTemplate, SmsTemplate } from 'context/notificationTemplates/types';
import { IProject } from 'context/projects';
import useColumnSearch from 'hooks/useColumnSearch';
import { useAuth } from 'context/auth';
import { USER_ROLES } from 'constants/index';

type TemplateType = 'EMAIL' | 'SMS';
type Template = (EmailTemplate | SmsTemplate) & { type: TemplateType };

type Props = {
  projects: IProject[];
  emailTemplates: EmailTemplate[];
  smsTemplates: SmsTemplate[];
  customers: Customer[];
  onEditClick: (template: Template) => void;
  onDeleteTemplate: (id: string, type: TemplateType) => void;
  resetFilters: boolean;
};

const TemplateList: FC<Props> = ({
  projects,
  customers,
  emailTemplates,
  smsTemplates,
  resetFilters,
  onEditClick,
  onDeleteTemplate,
}) => {
  const [tableFilters, setTableFilters] = useState<{
    sorter: SorterResult<Template>;
    filters: Record<string, FilterValue | null>;
  }>({
    sorter: {},
    filters: {},
  });

  const [projectUsage, setProjectUsage] = useState<Record<string, number[]>>({});

  const askConfirmDelete = (template: Template): void => {
    Modal.confirm({
      icon: <ExclamationCircleOutlined />,
      content: 'You are about to delete a template. Continue?',
      onOk() {
        onDeleteTemplate(template.id, template.type);
      },
      okText: 'Delete template',
    });
  };

  const { getColumnSearchProps } = useColumnSearch<Template>();

  const handleTableChange = (
    _pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<Template> | SorterResult<Template>[]
  ): void => {
    setTableFilters({ sorter: Array.isArray(sorter) ? sorter[0] : sorter, filters });
  };

  useEffect(() => {
    setTableFilters({ sorter: {}, filters: {} });
  }, [resetFilters]);

  useEffect(() => {
    const data: Record<string, number[]> = {};
    let index = 0;
    for (const project of projects) {
      if (project.deviceShippedEmailTemplateId) {
        if (!data[project.deviceShippedEmailTemplateId]) {
          data[project.deviceShippedEmailTemplateId] = [];
        }
        data[project.deviceShippedEmailTemplateId].push(index);
      }

      if (project.deviceShippedSmsTemplateId) {
        if (!data[project.deviceShippedSmsTemplateId]) {
          data[project.deviceShippedSmsTemplateId] = [];
        }
        data[project.deviceShippedSmsTemplateId].push(index);
      }

      if (project.patientReminderAtPatientThresholds) {
        const keys = Object.keys(project.patientReminderAtPatientThresholds);
        for (const key of keys) {
          const templates = project.patientReminderAtPatientThresholds[key];

          if (templates.emailTemplateId) {
            if (!data[templates.emailTemplateId]) {
              data[templates.emailTemplateId] = [];
            }
            data[templates.emailTemplateId].push(index);
          }

          if (templates.smsTemplateId) {
            if (!data[templates.smsTemplateId]) {
              data[templates.smsTemplateId] = [];
            }
            data[templates.smsTemplateId].push(index);
          }
        }
      }

      index += 1;
    }

    // keep only unique index
    Object.keys(data).forEach((key) => {
      const x = data[key];
      data[key] = [...new Set(x)];
    });

    setProjectUsage(data);
  }, [projects]);

  const currentSorter = tableFilters.sorter || {};
  const currentFilters = tableFilters.filters || {};

  const getCustomer = (customerId: string) => customers.find((c) => c.id === customerId) || null;

  const getProjects = (templateId: string): IProject[] => {
    if (templateId in projectUsage) {
      return projectUsage[templateId].map((pIndex) => projects[pIndex]).filter((p) => !!p);
    }

    return [];
  };

  return (
    <Table
      dataSource={[
        ...emailTemplates.map((tpl) => ({ ...tpl, type: 'EMAIL' as TemplateType })),
        ...smsTemplates.map((tpl) => ({ ...tpl, type: 'SMS' as TemplateType })),
      ]}
      bordered
      scroll={{ y: `calc(100vh - 410px)` }}
      size="middle"
      columns={[
        {
          key: 'templateName',
          title: 'Template name',
          dataIndex: 'templateName',
          sorter: (a, b) => a.templateName.localeCompare(b.templateName),
          sortOrder: currentSorter.columnKey === 'templateName' ? currentSorter.order : undefined,
          ...getColumnSearchProps('templateName', null, false),
          filteredValue: currentFilters.templateName || null,
        },
        {
          key: 'description',
          title: 'Description',
          dataIndex: 'description',
          sorter: (a, b) => (a.description || '').localeCompare(b.description || ''),
          sortOrder: currentSorter.columnKey === 'description' ? currentSorter.order : undefined,
          ...getColumnSearchProps('description', null, false),
          filteredValue: currentFilters.description || null,
          render: (value) => value || '',
        },
        {
          key: 'type',
          title: 'Type',
          dataIndex: 'type',
          sorter: (a, b) => (a.type || '').localeCompare(b.type || ''),
          sortOrder: currentSorter.columnKey === 'type' ? currentSorter.order : undefined,
          filters: [
            { text: 'EMAIL', value: 'EMAIL' },
            { text: 'SMS', value: 'SMS' },
          ],
          onFilter: (value, record) => record.type.indexOf(value as string) === 0,
          filteredValue: currentFilters.type || null,
          filterMultiple: false,
        },
        {
          key: 'customer',
          title: 'Customer',
          dataIndex: ['customer', 'name'],
          filters: customers.length > 0 ? customers.map((c) => ({ text: c.name, value: c.id })) : undefined,
          onFilter: (value, record) => (record.customerId || '').indexOf(value as string) === 0,
          filteredValue: currentFilters.customer || null,
          sorter: (a, b) => {
            const str1 = a.customerId ? getCustomer(a.customerId)?.name || '' : '';
            const str2 = b.customerId ? getCustomer(b.customerId)?.name || '' : '';
            return str1.localeCompare(str2);
          },
          sortOrder: currentSorter.columnKey === 'customer' ? currentSorter.order : undefined,
          render: (_row, record) => (record && record.customerId ? getCustomer(record.customerId)?.name || '' : ''),
        },
        {
          key: 'projectCount',
          title: 'Used in projects',
          dataIndex: 'projectCount',
          sorter: (a, b) => {
            const projects1 = getProjects(a.id).length;
            const projects2 = getProjects(b.id).length;

            return projects1 - projects2;
          },
          sortOrder: currentSorter.columnKey === 'projectCount' ? currentSorter.order : undefined,
          render: (_row, record) => {
            const projectList = getProjects(record.id);
            if (projectList.length === 0) {
              return 0;
            }
            const names = (
              <div className="Users_tooltip_content">
                <ul>
                  {projectList.map((p) => (
                    <li key={p.id}>
                      <Link to={`/projects/${p.id}/details`} target="_blank">
                        {p.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            );
            return (
              <Popover title="Projects" className="Users_tooltip" content={names}>
                {projectList.length}
              </Popover>
            );
          },
        },
        {
          title: 'Actions',
          width: 72,
          render: (_value, record: Template) =>
            // eslint-disable-next-line react-hooks/rules-of-hooks
            (useAuth().profile?.role || '') === USER_ROLES.internalAdmin && (
              <div className="Users_actions">
                <Dropdown
                  trigger={['click']}
                  overlay={
                    <Menu>
                      <Menu.Item key="1" icon={<EditOutlined />} onClick={() => onEditClick(record)}>
                        Edit
                      </Menu.Item>
                      <Menu.Item key="3" danger icon={<DeleteOutlined />} onClick={() => askConfirmDelete(record)}>
                        Delete
                      </Menu.Item>
                    </Menu>
                  }
                >
                  <Button icon={<EllipsisOutlined />} type="text" size="middle" />
                </Dropdown>
              </div>
            ),
        },
      ]}
      rowKey="id"
      pagination={{
        defaultPageSize: 20,
        showTotal: (n: number) => `${n} template${n === 1 ? '' : 's'} in total`,
        defaultCurrent: 1,
        showSizeChanger: true,
      }}
      onChange={handleTableChange}
      showSorterTooltip={false}
    />
  );
};

export default TemplateList;
