import React, { FC } from 'react';

import { Button, Modal, Table, Tooltip } from 'antd';

import {
  CheckCircleTwoTone,
  LoadingOutlined,
  CloseCircleTwoTone,
  MinusCircleOutlined,
  WarningTwoTone,
} from 'components/icons';
import { LABEL_STATUS } from 'context/shippingLabels';

type Label = {
  deviceId: string;
  status: keyof typeof LABEL_STATUS;
  message: string;
};

type ShippingLabelsModalProps = {
  labels: Label[];
  onCancel: () => void;
  onGeneratePdf: () => void;
  createMode: boolean;
  itemsReady: boolean;
  itemsSuccess: boolean;
  downloadInProgress: boolean;
  showModal: boolean;
};

export const ShippingLabelsModal: FC<ShippingLabelsModalProps> = ({
  labels,
  onCancel,
  createMode,
  itemsReady,
  itemsSuccess,
  onGeneratePdf,
  downloadInProgress,
  showModal,
}) => (
  <Modal
    title={createMode ? 'Create shipping labels' : 'Cancel shipping labels'}
    visible={showModal}
    centered
    width={600}
    onCancel={onCancel}
    closable={false}
    maskClosable={false}
    footer={
      createMode
        ? [
            <Button key="close" onClick={onCancel} disabled={!itemsReady || downloadInProgress}>
              Close
            </Button>,
            <Button
              key="generatePDF"
              onClick={onGeneratePdf}
              disabled={!itemsReady || downloadInProgress || !itemsSuccess}
              type="primary"
            >
              {downloadInProgress && <LoadingOutlined spin />} Generate PDF
            </Button>,
          ]
        : [
            <Button key="close" onClick={onCancel} disabled={!itemsReady}>
              Close
            </Button>,
          ]
    }
  >
    {createMode && (
      <p>
        Labels are generated only once unless manually cancelled. Multiple requests <em>do not</em> generate new labels
        for the same device.
      </p>
    )}
    <Table
      rowKey="deviceId"
      columns={[
        {
          title: 'Device Id',
          dataIndex: 'deviceId',
          key: 'deviceId',
        },
        {
          title: 'Status',
          dataIndex: 'status',
          key: 'status',
          align: 'center',
          render: (value: boolean, row: Label) => {
            if (row.status === LABEL_STATUS.inProgress) {
              return (
                <Tooltip title="Loading, please wait">
                  <LoadingOutlined />
                </Tooltip>
              );
            }
            if (row.status === LABEL_STATUS.error) {
              return (
                <Tooltip title={row.message} color="red">
                  <CloseCircleTwoTone twoToneColor="red" />
                </Tooltip>
              );
            }
            if (row.status === LABEL_STATUS.warning) {
              return (
                <Tooltip title={row.message} color="orange">
                  <WarningTwoTone twoToneColor="orange" />
                </Tooltip>
              );
            }
            return value ? (
              <Tooltip title={row.message} color="#52c41a">
                <CheckCircleTwoTone twoToneColor="#52c41a" />
              </Tooltip>
            ) : (
              <Tooltip title={row.message} color="gray">
                <MinusCircleOutlined />
              </Tooltip>
            );
          },
        },
      ]}
      dataSource={labels}
      pagination={false}
      showHeader={false}
    />
  </Modal>
);
