import React, { MouseEvent } from 'react';

import { Layout, Menu, Dropdown } from 'antd';
import { Link } from 'react-router-dom';

import LogoImg from 'assets/logo.png';
import { DownOutlined } from 'components/icons';
import { ROUTES } from 'constants/routes';
import { useAuth } from 'context/auth';

const { Header: AHeader } = Layout;

const Header: React.FC = () => {
  const { profile, logout } = useAuth();

  const handleLogout = (e: MouseEvent) => {
    e.preventDefault();
    logout();
  };

  const menu = (
    <Menu>
      <Menu.Item key="1">
        <a data-testid="account-menu_logout-button" href="/" onClick={handleLogout}>
          Logout
        </a>
      </Menu.Item>
    </Menu>
  );

  const showActionsMenu = profile !== null;

  return (
    <AHeader className="Header">
      <div className="Logo">
        <Link to={ROUTES.projects}>
          <img src={LogoImg} alt="Tasso Inc. logo" />
        </Link>
      </div>

      {showActionsMenu && (
        <div className="Actions">
          <Dropdown overlay={menu} trigger={['click']}>
            <a href="/" onClick={(e) => e.preventDefault()}>
              {profile?.username} <DownOutlined />
            </a>
          </Dropdown>
        </div>
      )}
    </AHeader>
  );
};

export default Header;
