import React, { FC, useState, createContext } from 'react';

import { customersApi } from 'api';
import { loadAllPages, normalizePagingResponse } from 'utils/functions';
import { getErrorMessage } from 'utils/issues';
import Notification from 'utils/notification';
import { PagingInfo } from 'utils/types';

export interface Customer {
  id: string;
  name: string;
  createdAt: string;
  updatedAt: string | null;
}

type Callback = (customers: Customer[] | null) => Promise<void>;

export type LoadCustomersParams = {
  page: number;
  pageLength: number;
  includeTotalCount: boolean;
  sortBy: string;
  sortOrder: 'asc' | 'desc';
  nameLike?: string;
};

type LoadCustomersFunction = (
  params?: LoadCustomersParams,
  callback?: (customers: Customer[] | null, paging?: PagingInfo) => Promise<void>
) => Promise<void>;
type GetCustomersFunction = () => Promise<void>;
type GetCustomerFunction = (customerId: string) => Promise<void>;
type CreateCustomerFunction = (data: Record<string, any>, callback?: Callback) => Promise<void>;
type UpdateCustomerFunction = (customerId: string, data: Record<string, any>, callback?: Callback) => Promise<void>;
type DeleteCustomerFunction = (customerId: string, callback?: Callback) => Promise<void>;
type ResetCustomersFuction = () => void;

interface State {
  customers: Customer[];
  customer: Customer | null;
  loading: boolean;
  error: string | null;
}

interface Context extends State {
  loadCustomers: LoadCustomersFunction;
  getCustomers: GetCustomersFunction;
  getCustomer: GetCustomerFunction;
  createCustomer: CreateCustomerFunction;
  updateCustomer: UpdateCustomerFunction;
  deleteCustomer: DeleteCustomerFunction;
  resetCustomers: ResetCustomersFuction;
}

const getInitialState = (): State => ({
  customers: [],
  customer: null,
  loading: false,
  error: null,
});

const CustomersContext = createContext<Context>({
  ...getInitialState(),
  loadCustomers: async () => {},
  getCustomers: async () => {},
  getCustomer: async () => {},
  createCustomer: async () => {},
  updateCustomer: async () => {},
  deleteCustomer: async () => {},
  resetCustomers: () => {},
});

const CustomersProvider: FC = (props) => {
  const [state, setState] = useState<State>(getInitialState());

  const getCustomers: GetCustomersFunction = async () => {
    setState((s) => ({
      ...s,
      loading: true,
      error: null,
    }));

    try {
      type PayloadType = Parameters<typeof customersApi.getCustomers>[0];
      const customers = await loadAllPages<Customer, PayloadType>(
        customersApi.getCustomers,
        {},
        {
          pageLength: 100,
          sortBy: 'name',
          isDescending: false,
        }
      );

      setState((s) => ({
        ...s,
        customers,
      }));
    } catch (e) {
      const errorMessage = getErrorMessage(e);

      setState((s) => ({
        ...s,
        error: errorMessage,
      }));

      Notification({
        type: 'error',
        message: 'Cannot get customers',
        description: errorMessage,
      });
    }

    setState((s) => ({
      ...s,
      loading: false,
    }));
  };

  const loadCustomers: LoadCustomersFunction = async (params, callback) => {
    setState((s) => ({
      ...s,
      loading: true,
      error: null,
    }));

    const sortBy = params?.sortBy || 'createdAt';
    const isDescending = params && params.sortOrder ? params.sortOrder === 'desc' : false;

    const query: Record<string, string> = {};

    if (params) {
      if (typeof params.nameLike === 'string') {
        query.nameLike = params.nameLike;
      }
    }

    let paging: PagingInfo;

    try {
      const data = await customersApi.getCustomers(query, {
        page: params?.page || 1,
        pageLength: params?.pageLength || 20,
        sortBy,
        isDescending,
        includeTotalCount: params?.includeTotalCount || false,
      });

      const { results } = data;

      paging = normalizePagingResponse(data.paging);

      setState((s) => ({
        ...s,
        customers: results,
      }));

      if (callback) {
        await callback(results, paging);
      }
    } catch (e) {
      const errorMessage = getErrorMessage(e);

      setState((s) => ({
        ...s,
        error: errorMessage,
      }));

      Notification({
        type: 'error',
        message: 'Failed to load customers',
        description: errorMessage,
      });

      if (callback) {
        await callback(null);
      }
    } finally {
      setState((s) => ({
        ...s,
        loading: false,
      }));
    }
  };

  const getCustomer: GetCustomerFunction = async (id) => {
    setState((s) => ({
      ...s,
      loading: true,
      error: null,
    }));

    try {
      const customer = await customersApi.getCustomer(id);

      setState((s) => ({
        ...s,
        customer,
      }));
    } catch (e) {
      const errorMessage = getErrorMessage(e);

      setState((s) => ({
        ...s,
        error: errorMessage,
      }));

      Notification({
        type: 'error',
        message: 'Cannot get customer details',
        description: errorMessage,
      });
    }

    setState((s) => ({
      ...s,
      loading: false,
    }));
  };

  const createCustomer: CreateCustomerFunction = async (data, callback?) => {
    setState((s) => ({
      ...s,
      loading: true,
      error: null,
    }));

    try {
      const newCustomer = await customersApi.createCustomer(data);

      setState((s) => ({
        ...s,
        customers: [...s.customers, newCustomer],
      }));

      Notification({ type: 'success', message: 'Customer successfully created' });

      if (callback) {
        await callback(newCustomer);
      }
    } catch (e) {
      const errorMessage = getErrorMessage(e);

      setState((s) => ({
        ...s,
        error: errorMessage,
      }));

      Notification({
        type: 'error',
        message: 'Customer was not created',
        description: errorMessage,
      });

      if (callback) {
        await callback(null);
      }
    }

    setState((s) => ({
      ...s,
      loading: false,
    }));
  };

  const updateCustomer: UpdateCustomerFunction = async (id, data, callback?) => {
    setState((s) => ({
      ...s,
      loading: true,
      error: null,
    }));

    try {
      const updatedCustomer = await customersApi.updateCustomer(id, data);

      setState((s) => ({
        ...s,
        customers: s.customers.map((c) => (c.id === id ? updatedCustomer : c)),
        customer: s.customer && s.customer.id === id ? updatedCustomer : s.customer,
      }));

      Notification({ type: 'success', message: 'Customer successfully updated' });

      if (callback) {
        await callback(updatedCustomer);
      }
    } catch (e) {
      const errorMessage = getErrorMessage(e);

      setState((s) => ({
        ...s,
        error: errorMessage,
      }));

      Notification({
        type: 'error',
        message: 'Customer was not updated',
        description: errorMessage,
      });

      if (callback) {
        await callback(null);
      }
    }

    setState((s) => ({
      ...s,
      loading: false,
    }));
  };

  const deleteCustomer: DeleteCustomerFunction = async (id, callback?) => {
    setState((s) => ({
      ...s,
      loading: true,
      error: null,
    }));

    try {
      const deletedCustomer = await customersApi.deleteCustomer(id);

      setState((s) => ({
        ...s,
        customers: s.customers.filter((c) => c.id !== id),
        customer: s.customer && s.customer.id === id ? null : s.customer,
      }));

      Notification({ type: 'success', message: 'Customer successfully deleted' });

      if (callback) {
        await callback(deletedCustomer);
      }
    } catch (e) {
      const errorMessage = getErrorMessage(e);

      setState((s) => ({
        ...s,
        error: errorMessage,
      }));

      Notification({
        type: 'error',
        message: 'Customer was not deleted',
        description: errorMessage,
      });

      if (callback) {
        await callback(null);
      }
    }

    setState((s) => ({
      ...s,
      loading: false,
    }));
  };

  const resetCustomers: ResetCustomersFuction = () => {
    setState(getInitialState());
  };

  return (
    <CustomersContext.Provider
      value={{
        customers: state.customers,
        customer: state.customer,
        loading: state.loading,
        error: state.error,
        loadCustomers,
        getCustomers,
        getCustomer,
        createCustomer,
        updateCustomer,
        deleteCustomer,
        resetCustomers,
      }}
      {...props}
    />
  );
};

const useCustomers = (): Context => React.useContext(CustomersContext);

export { CustomersProvider, useCustomers };
