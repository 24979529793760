import React, { FC, useReducer, createContext } from 'react';

import { shippingLabelsApi as api } from 'api';
import { serializeForDisplay, getErrorMessage } from 'utils/issues';

import { MANIFEST } from 'constants/index';
import moment from 'moment';
import { loadAllPages } from 'utils/functions';
import { IDevice } from './devices';
import { useManifestStatus } from './manifestStatus';

export const LABEL_STATUS = {
  success: 'success',
  warning: 'warning',
  error: 'error',
  inProgress: 'inProgress',
} as const;

export interface IShippingLabel {
  id: string;
  deviceId: string;
  device?: IDevice;
  createdAt: string;
  updatedAt: string;
  labelUriToPatient: string;
  labelUriToLab: string;
  toPatientShipDate: string;
  toPatientShipOrigin: string;
}

type ShippingLabel = {
  deviceId: string;
  status: keyof typeof LABEL_STATUS;
  message: string;
};

type State = {
  createMode: boolean;
  shippingLabels: ShippingLabel[];
  disposition: '';
  showModal: true;
};

type GetShippingLabelsCallback = (labels: IShippingLabel[]) => void;
type CancelShippingLabelsCallback = (deviceId: string) => void;

type Context = State & {
  createShippingLabel: (deviceId: string, disposition?: string, callback?: any, showModal?: boolean) => Promise<void>;
  getShippingLabels: (deviceIds: string[], callback?: GetShippingLabelsCallback) => Promise<void>;
  cancelShippingLabel: (deviceId: string, labelId: string, callback?: CancelShippingLabelsCallback) => Promise<void>;
  resetLabels: () => void;
};

const getInitialState = (): State => ({ createMode: true, shippingLabels: [], disposition: '', showModal: true });

const ShippingLabelsContext = createContext<Context>({
  ...getInitialState(),
  createShippingLabel: async () => {},
  getShippingLabels: async () => {},
  cancelShippingLabel: async () => {},
  resetLabels: () => {},
});

const reducer = (state: State, action: any): State => {
  switch (action.type) {
    case 'ADD_CREATE_LABEL':
      return {
        createMode: true,
        shippingLabels: [...state.shippingLabels, action.payload],
        disposition: action.payload.disposition,
        showModal: action.payload.showModal ?? true,
      };
    case 'ADD_CANCEL_LABEL':
      return {
        createMode: false,
        shippingLabels: [...state.shippingLabels, action.payload],
        disposition: action.payload.disposition,
        showModal: true,
      };
    case 'SUCCESS': {
      const { deviceId } = action.payload;
      const labelIndex = state.shippingLabels.findIndex((lbl) => lbl.deviceId === deviceId);

      return labelIndex > -1
        ? {
            ...state,
            shippingLabels: state.shippingLabels.map((lbl, idx) =>
              labelIndex === idx ? { ...lbl, status: LABEL_STATUS.success, message: action.payload.message } : lbl
            ),
          }
        : state;
    }
    case 'WARNING': {
      const { deviceId } = action.payload;
      const labelIndex = state.shippingLabels.findIndex((lbl) => lbl.deviceId === deviceId);

      return labelIndex > -1
        ? {
            ...state,
            shippingLabels: state.shippingLabels.map((lbl, idx) =>
              labelIndex === idx ? { ...lbl, status: LABEL_STATUS.warning, message: action.payload.message } : lbl
            ),
          }
        : state;
    }
    case 'ERROR': {
      const { deviceId } = action.payload;
      const labelIndex = state.shippingLabels.findIndex((lbl) => lbl.deviceId === deviceId);

      return labelIndex > -1
        ? {
            ...state,
            shippingLabels: state.shippingLabels.map((lbl, idx) =>
              labelIndex === idx ? { ...lbl, status: LABEL_STATUS.error, message: action.payload.message } : lbl
            ),
          }
        : state;
    }
    case 'UPDATE': {
      const { deviceId } = action.payload;
      const labelIndex = state.shippingLabels.findIndex((lbl) => lbl.deviceId === deviceId);

      return labelIndex > -1
        ? {
            ...state,
            shippingLabels: state.shippingLabels.map((lbl, idx) =>
              labelIndex === idx
                ? { ...lbl, status: action.payload.status, message: action.payload.message || '' }
                : lbl
            ),
          }
        : state;
    }
    case 'RESET':
      return getInitialState();
    default:
      return state;
  }
};

const ShippingLabelsProvider: FC<any> = (props) => {
  const [state, dispatch] = useReducer(reducer, getInitialState());
  const { isManifestEnabled } = useManifestStatus();

  const createShippingLabel = async (
    deviceId: string,
    disposition: string,
    callback?: any,
    showModal?: boolean
  ): Promise<void> => {
    const formattedTodayDate = moment().format('YYYY-MM-DD');

    dispatch({
      type: 'ADD_CREATE_LABEL',
      payload: {
        deviceId,
        disposition,
        message: '',
        status: LABEL_STATUS.inProgress,
        showModal: showModal ?? true,
        ...(isManifestEnabled && { toPatientShipDate: formattedTodayDate, toPatientShipOrigin: MANIFEST.origin }),
      },
    });

    try {
      const results = await api.createShippingLabel(
        {
          deviceId,
          toPatientShipDate: isManifestEnabled ? formattedTodayDate : undefined,
          toPatientShipOrigin: isManifestEnabled ? MANIFEST.origin : undefined,
        },
        { headers: { 'tasso-hydration': 'device' } }
      );

      dispatch({ type: 'SUCCESS', payload: { deviceId, message: 'Label is ready', disposition } });

      if (callback) {
        await callback(results.device);
      }
    } catch (e: any) {
      const results = e?.response?.data?.results || null;
      const issues = e?.response?.data?.issues || null;

      if (issues) {
        if (issues && issues.errors && issues.errors.length > 0) {
          const errorMessage = serializeForDisplay(issues) || '';

          if (errorMessage.toLowerCase() === 'device already has shipping labels.') {
            dispatch({ type: 'SUCCESS', payload: { deviceId, message: 'Label is ready', disposition } });
          } else {
            dispatch({ type: 'ERROR', payload: { deviceId, message: errorMessage } });
          }
        } else {
          dispatch({
            type: 'UPDATE',
            payload: {
              deviceId,
              success: results?.device?.hasLabels ? LABEL_STATUS.success : LABEL_STATUS.error,
              message: e.message,
              disposition,
            },
          });
        }
      } else {
        dispatch({
          type: 'ERROR',
          payload: { deviceId, message: getErrorMessage(e) },
        });
      }

      if (callback) {
        await callback(null);
      }
    }
  };

  const getShippingLabels = async (deviceIds: string[], callback?: GetShippingLabelsCallback): Promise<void> => {
    try {
      const results = await loadAllPages<IShippingLabel>(
        api.getShippingLabels,
        { deviceIds: deviceIds.join(',') },
        {
          pageLength: 50,
          sortBy: 'createdAt',
          isDescending: false,
        }
      );

      if (callback) {
        await callback(results);
      }
    } catch (e: any) {
      if (callback) {
        await callback([]);
      }
    }
  };

  const cancelShippingLabel = async (deviceId: string, labelId: string, callback?: any): Promise<void> => {
    dispatch({
      type: 'ADD_CANCEL_LABEL',
      payload: { deviceId, message: '', status: LABEL_STATUS.inProgress },
    });

    if (!labelId) {
      dispatch({ type: 'WARNING', payload: { deviceId, message: 'No label to cancel' } });
      if (callback) {
        await callback(null);
      }
      return;
    }

    try {
      const results = await api.cancelShippingLabel(labelId, { headers: { 'tasso-hydration': 'device' } });

      dispatch({ type: 'SUCCESS', payload: { deviceId, message: 'Label is cancelled' } });
      if (callback) {
        await callback(results.deviceId);
      }
    } catch (e: any) {
      const issues = e?.response?.data?.issues || null;

      if (issues && issues.errors && issues.errors.length > 0) {
        const errorMessage = serializeForDisplay(issues) || '';
        dispatch({ type: 'ERROR', payload: { deviceId, message: errorMessage } });
      } else {
        dispatch({ type: 'ERROR', payload: { deviceId, message: e.message } });
      }

      if (callback) {
        await callback(null);
      }
    }
  };

  const resetLabels = (): void => {
    dispatch({ type: 'RESET' });
  };

  return (
    <ShippingLabelsContext.Provider
      value={{
        createMode: state.createMode,
        shippingLabels: state.shippingLabels,
        disposition: state.disposition,
        showModal: state.showModal,
        createShippingLabel,
        getShippingLabels,
        cancelShippingLabel,
        resetLabels,
      }}
      {...props}
    />
  );
};

const useShippingLabels = (): Context => React.useContext(ShippingLabelsContext);

export { ShippingLabelsProvider, useShippingLabels };
