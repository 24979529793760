import React, { FC } from 'react';

import { Redirect, Switch, useRouteMatch } from 'react-router-dom';

import { ProtectedRoute, SectionHeader, SettingsNavigation } from 'components/index';
import { ROUTES } from 'constants/routes';

import { useAuth } from 'context/auth';
import { USER_ROLES } from 'constants/index';
import CustomersContainer from './Customers';
import KitItemsContainer from './KitItems';
import LaboratoriesContainer from './Laboratories';
import NotificationTemplatesContainer from './NotificationTemplates';
import UsersContainer from './Users';
import IdPoolIdentifiersContainer from './IdPoolIdentifiers';
import IdPoolsContainer from './IdPools';

const SettingsContainer: FC = () => {
  const { path } = useRouteMatch();

  const { profile } = useAuth();
  const isAdmin = (profile?.role || '') === USER_ROLES.internalAdmin;
  const isCustomerService = (profile?.role || '') === USER_ROLES.internalCustomerService;

  const breadcrumbNameMap = {
    [ROUTES.settings]: 'Settings',
  };

  return (
    <div className="Settings">
      <SectionHeader breadcrumbNameMap={breadcrumbNameMap} isAdmin={isAdmin} isCustomerService={isCustomerService} />

      <SettingsNavigation />

      <Switch>
        <ProtectedRoute exact path={`${path}/customers`}>
          <CustomersContainer />
        </ProtectedRoute>

        <ProtectedRoute path={`${path}/laboratories`}>
          <LaboratoriesContainer />
        </ProtectedRoute>

        <ProtectedRoute path={`${path}/kit-items`}>
          <KitItemsContainer />
        </ProtectedRoute>

        <ProtectedRoute path={`${path}/users`}>
          <UsersContainer />
        </ProtectedRoute>

        <ProtectedRoute path={`${path}/notification-templates`}>
          <NotificationTemplatesContainer />
        </ProtectedRoute>

        <ProtectedRoute path={`${path}/id-pools`} exact>
          <IdPoolsContainer />
        </ProtectedRoute>

        <ProtectedRoute path={`${path}/id-pools/:id`}>
          <IdPoolIdentifiersContainer />
        </ProtectedRoute>

        <Redirect to={`${path}/customers`} />
      </Switch>
    </div>
  );
};

export default SettingsContainer;
