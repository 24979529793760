import { KIT_ITEM_TYPES_LABELS } from 'constants/index';
import React, { useState, useEffect } from 'react';
import { Tag } from 'antd';

type KitItemType = keyof typeof KIT_ITEM_TYPES_LABELS;
type KitItemTypeWithAll = KitItemType | 'all';

interface Props {
  onSelect: (itemType: KitItemType[]) => void;
  visible: boolean;
}

const KitItemTypeFilter: React.FC<Props> = ({ onSelect, visible }) => {
  const [selectedTags, setSelectedTags] = useState<KitItemTypeWithAll[]>(['all']);

  const handleChange = (tag: KitItemTypeWithAll, checked: boolean) => {
    if (tag === 'all') {
      if (checked) {
        setSelectedTags(['all']);
      }

      onSelect([]);

      return;
    }

    const filteredTags = selectedTags.filter((t) => t !== 'all') as KitItemType[];

    const nextSelectedTags = checked ? [...filteredTags, tag] : filteredTags.filter((t) => t !== tag);

    if (nextSelectedTags.length === 0) {
      setSelectedTags(['all']);
      onSelect([]);
    } else {
      setSelectedTags(nextSelectedTags);
      onSelect(nextSelectedTags);
    }
  };

  const keys = Object.keys(KIT_ITEM_TYPES_LABELS) as (keyof typeof KIT_ITEM_TYPES_LABELS)[];

  // Reset state when selector is initially displayed.
  useEffect(() => {
    if (visible) {
      handleChange('all', true);
    }
  }, [visible]);

  return (
    <div style={{ marginTop: 5, marginBottom: 5 }}>
      <span style={{ marginRight: 8 }}>Filter by type:</span>
      <Tag.CheckableTag
        key="all"
        checked={selectedTags.indexOf('all') > -1}
        onChange={(checked) => handleChange('all', checked)}
      >
        All
      </Tag.CheckableTag>

      {keys.map((key) => (
        <Tag.CheckableTag
          key={key}
          checked={selectedTags.indexOf(key) > -1}
          onChange={(checked) => handleChange(key, checked)}
        >
          {KIT_ITEM_TYPES_LABELS[key]}
        </Tag.CheckableTag>
      ))}
    </div>
  );
};

export default KitItemTypeFilter;
