/* eslint-disable no-await-in-loop */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-bitwise */
import React, { FC, useEffect, useState, useRef } from 'react';

import { useLocation } from 'react-router-dom';

import { ImportRecipients, ShipmentRecipientsList } from 'components/index';
import { NO_ORDER_PLEASE, OrderTemplateSchemaTypes, USER_ROLES } from 'constants/index';
import { useAuth } from 'context/auth';
import { useDeviceLots } from 'context/deviceLots';
import { IDevice, useDevices } from 'context/devices';
import { Order, useOrders } from 'context/orders';
import { useDownloads } from 'context/downloads';
import { usePatients } from 'context/patients';
import { IProjectLaboratory, useProjectLaboratories } from 'context/projectLaboratories';
import { useOrderTemplates } from 'context/orderTemplates';
import { CustomAttributeDefinitionProject, IProject } from 'context/projects';
import { Laboratory, useLaboratories } from 'context/laboratories';
import { IUpload, useUploads } from 'context/uploads';
import { useShippingLabels, IShippingLabel } from 'context/shippingLabels';
import { useShippingLabelsV2 } from 'context/shippingLabelsV2';
import { useShipments } from 'context/shipments';
import usePatientModal from 'hooks/usePatientModal';
import useShippingLabelsModal from 'hooks/useShippingLabelsModal';
import useShippingLabelsV2Modal from 'hooks/useShippingLabelsV2Modal';

import { isPendingOrder, openDocument } from 'utils/functions';

import { ICustomAttributeDefinition, useCustomAttributeDefinitions } from 'context/customAttributeDefinitions';
import { OrderTemplate, Shipment } from '@tassoinc/core-api-client';
import { sortOrderTemplates } from 'utils/orderTemplates';
import { findOrderTemplateItemsBySchemaType } from 'components/OrderTemplateModal/functions';
import ReplacementReasonModal from './ReplacementReasonModal';
import PlaceOrderModal from './PlaceOrderModal';

interface ISelectedPatient {
  id: string;
  deviceIds: string[];
}

type ProjectShipmentsProps = {
  project: IProject;
};

export type SummarizedOrderTemplate = { id: string; name: string; templateId: string };

export interface PatientPagingConfig {
  page: number;
  pageLength: number;
  sortBy: string;
  sortOrder: 'asc' | 'desc';
  includeTotalCount: boolean;
  search: { by: string; term: string } | null;
}
export const FILTER_SHOW_ALL = 'all';
export const NONE_TEMPLATE = 'none';

/**
 * Keeps track of packing instruction that comes from Order Templates.
 * The key is the order template id. The value is the upload id that represents
 * the actual file with instructions
 */
type OrderTemplatePackingInstructions = Record<string, string>;

const computeSummarizedOrderTemplates = (
  orderTemplates: OrderTemplate[] | null,
  project: IProject,
  isAdmin: boolean,
  isCustomerService: boolean
): SummarizedOrderTemplate[] => {
  let result: SummarizedOrderTemplate[] = [];

  // Not loaded yet.
  if (orderTemplates === null) {
    return [];
  }
  for (const ot of orderTemplates) {
    // Strip version off so we get orders with all versions of this template
    // More info on how versions in Order Template IDs are encoded:
    // https://tassoinc.atlassian.net/wiki/spaces/CODE/pages/2026864642/Order+Configuration+template+Ids
    result.push({ id: ot.id.replace(/::v[0-9]+/, ''), name: ot.name, templateId: ot.templateId });
  }

  if (project.orderTemplatesViewOrder.length === 0) {
    result.sort((a, b) => a.name.localeCompare(b.name));
  } else {
    result = sortOrderTemplates(result, project.orderTemplatesViewOrder);
  }

  result.unshift({ id: 'none', name: 'None', templateId: '' });
  if (isAdmin || isCustomerService) {
    // Admin doesn't show numOrdersAwaitingShipment
    result.unshift({ id: FILTER_SHOW_ALL, name: 'All Configurations', templateId: '' });
  }
  return result;
};

const ProjectShipments: FC<ProjectShipmentsProps> = ({ project }) => {
  const projectId = project.id;

  // ++ handle query string
  const { search } = useLocation();
  const filterByPatientId: string | undefined = search
    ? new URLSearchParams(search).get('patientId') || undefined
    : undefined;
  // -- handle query string

  const { patients, loadPatients, deletePatient, resetPatients, loading: patientsLoading } = usePatients();
  const {
    downloadBarcode,
    downloadOrderFulfillmentSummary,
    downloadCustomerLetters,
    downloadRequisitionForm,
    downloadActivationLabels,
    loading: downloadLoading,
  } = useDownloads();
  const { createShippingLabel, getShippingLabels, cancelShippingLabel, shippingLabels } = useShippingLabels();
  const { createShippingLabelV2, cancelShippingLabelV2, labels: shippingLabelsV2 } = useShippingLabelsV2();
  const { Modal: ShippingLabelsModal } = useShippingLabelsModal();
  const { Modal: ShippingLabelsV2Modal } = useShippingLabelsV2Modal();
  const { Modal: PatientModal, onCreatePatient, onEditPatient, patientModalPopulated } = usePatientModal();
  const { getLaboratory } = useLaboratories();

  const { getCustomAttributeDefinitions } = useCustomAttributeDefinitions();
  const { getOrderTemplatesForProject, getOrderTemplate } = useOrderTemplates();
  const {
    loadPatientDevices,
    devices,
    deleteDevice,
    loading: devicesLoading,
    setLoading: setDeviceLoading,
    resetDevices,
    clearCancelledDevice,
  } = useDevices();

  const { createOrder, resetOrders, getOrder, cancelOrder, getOrdersByIds, getOrdersForPatients } = useOrders();
  const { getShipments, resetShipments } = useShipments();
  const { getUploads } = useUploads();

  const { getDeviceLots, deviceLots, resetDeviceLots } = useDeviceLots();
  const { projectLaboratories, getProjectLaboratories, resetProjectLaboratories } = useProjectLaboratories();
  const [selectedDeviceIds, setSelectedDeviceIds] = useState<string[]>([]);
  const [selectedPatients, setSelectedPatients] = useState<ISelectedPatient[]>([]);
  const [selectedPatientOrder, setSelectedPatientOrder] = useState<string[]>([]); // order of patients (UUIDs) as they're shown in table
  const [devicesToReplace, setDevicesToReplace] = useState<string[]>([]); // array of device ids that needs to be replaced
  const [ordersToReplace, setOrdersToReplace] = useState<Order[]>([]); // array of device ids that needs to be replaced
  const [patientsForInitialPlacement, setPatientsForInitialPlacement] = useState<string[]>([]); // array of device ids that needs to be replaced
  const [totalPatientCount, setTotalPatientCount] = useState(0);
  const [patientPagingConfig, setPatientPagingConfig] = useState<PatientPagingConfig | null>(null);
  const [patientOrders, setPatientOrders] = useState<Order[]>([]);
  const [labRecord, setLabRecord] = useState<Laboratory | null>(null);

  const [packingInstructions, setPackingInstructions] = useState<OrderTemplatePackingInstructions>({});

  /**
   * A control mechanism to prevent multiple simultaneous label generation calls.
   * Since label generation involves multiple API calls and rendering of a modal,
   * it's more reliable to use this simple boolean flag instead of relying on a
   * combination of other properties.
   */
  const isLabelGenerationInProgress = useRef(false);

  const [orderTemplates, setOrderTemplates] = useState<OrderTemplate[] | null>(null);
  const [customAttributeDefinitions, setCustomAttributeDefinitions] = useState<
    Map<string, ICustomAttributeDefinition[] | CustomAttributeDefinitionProject[]>
  >(new Map<string, ICustomAttributeDefinition[]>());
  const { profile } = useAuth();
  const isAdmin = (profile?.role || '') === USER_ROLES.internalAdmin;
  const isCustomerService = (profile?.role || '') === USER_ROLES.internalCustomerService;

  const summarizedOrderTemplates = orderTemplates
    ? computeSummarizedOrderTemplates(orderTemplates, project, isAdmin, isCustomerService)
    : [];

  const devicePatientSorter = (
    a: { id: string; patientId: string },
    b: { id: string; patientId: string }
  ): -1 | 0 | 1 => {
    const indexA = selectedPatientOrder.indexOf(a.patientId);
    const indexB = selectedPatientOrder.indexOf(b.patientId);
    if (indexA === indexB) {
      return 0;
    }
    return indexA > indexB ? 1 : -1;
  };

  const sortDevicesByPatientOrder = (deviceIds: string[]): string[] => {
    const deviceLookupHash = devices.reduce(
      (acc, val: { id: string }, index: number) => ({ ...acc, [val.id]: index }),
      {} as { [deviceId: string]: number }
    );
    const theDevices = deviceIds
      .map((id) => devices[deviceLookupHash[id]])
      .map(({ id, patientId }) => ({ id, patientId }));
    theDevices.sort(devicePatientSorter);
    return theDevices.map((d) => d.id);
  };

  const sortPatientsByPatientOrder = (patientIds: string[]): string[] =>
    patientIds.sort((a: string, b: string): number => {
      const indexA = selectedPatientOrder.indexOf(a);
      const indexB = selectedPatientOrder.indexOf(b);
      if (indexA === indexB) {
        return 0;
      }
      return indexA > indexB ? 1 : -1;
    });

  const [selectedOrderTemplateId, setSelectedOrderTemplateId] = useState<string>((): string => {
    if (isAdmin || isCustomerService) {
      return FILTER_SHOW_ALL;
    }
    if (!project.useOrderTemplates) {
      return NONE_TEMPLATE;
    }
    if (orderTemplates && orderTemplates.length > 0) {
      return orderTemplates[0].templateId ?? NONE_TEMPLATE;
    }
    return NONE_TEMPLATE;
  });

  /**
   * Given an list of orders looks up their corresponding Order Template entries.
   */
  const fetchOrderTemplatesForOrders = async (orderList: Order[]): Promise<OrderTemplate[]> => {
    const tempOrderTemplateIds = orderList.reduce<string[]>((acc, order) => {
      if (order.orderTemplateId) {
        const id = order.orderTemplateId.split('::').splice(0, 2).join('::');
        acc.push(id);
      }

      return acc;
    }, []);

    if (tempOrderTemplateIds.length === 0) {
      return [];
    }

    const orderTemplateIds = [...new Set(tempOrderTemplateIds)];

    const promises = orderTemplateIds.map(
      (id) =>
        new Promise<{ orderTemplateId: string; orderTemplate: OrderTemplate | null }>((resolve) => {
          getOrderTemplate(id, {}, (tpl) => {
            resolve({ orderTemplateId: id, orderTemplate: tpl });
          });
        })
    );

    const foundOrderTemplates: OrderTemplate[] = [];

    const results = await Promise.allSettled(promises);

    for (const result of results) {
      if (result.status === 'rejected') {
        console.error(`Failed to load Order Template: ${result.reason}.`);
      } else if (!result.value.orderTemplate) {
        console.log(`Failed to load Order Template with id ${result.value.orderTemplateId}.`);
      } else {
        foundOrderTemplates.push(result.value.orderTemplate);
      }
    }

    return foundOrderTemplates;
  };

  /**
   * Finds all "packing instructions" kit items and fetches the corresponding uploads.
   *
   * @returns object where keys are Order Template ids and values are upload ids
   */
  const fetchPackingInstructionsForOrderTemplates = async (
    templates: OrderTemplate[]
  ): Promise<Record<string, string>> => {
    const foundItems: Record<string, string> = {};

    for (const template of templates) {
      const items = findOrderTemplateItemsBySchemaType(template.items!, OrderTemplateSchemaTypes.PACKING_INSTRUCTIONS);
      if (items.length > 0) {
        if (items.length > 1) {
          console.warn('Found more than one packing instructions item.', { orderTemplateId: template.id });
        }

        const packingInstructionsItem = items[0];
        const packingInstructionsKitItemId = packingInstructionsItem.coreApiId;

        foundItems[template.id] = packingInstructionsKitItemId;
      }
    }

    const templateIds = Object.keys(foundItems);

    const promises = templateIds.map((templateId) => {
      const packingInstructionsKitItemId = foundItems[templateId];

      return new Promise<{ orderTemplateId: string; uploads: IUpload[] }>((resolve) => {
        getUploads(
          { parentIds: [packingInstructionsKitItemId], statuses: ['ready'] },
          { skipStateUpdate: true },
          (up) => {
            resolve({ orderTemplateId: templateId, uploads: up ?? [] });
          }
        );
      });
    });

    const resolvedPromises = await Promise.allSettled(promises);

    const results: Record<string, string> = {};

    for (const result of resolvedPromises) {
      if (result.status === 'rejected') {
        console.log(`Failed to fetch upload: ${result.reason}.`);
      } else {
        const { uploads, orderTemplateId } = result.value;

        if (uploads.length === 0) {
          console.warn('Uploads not found.', { orderTemplateId });
        } else {
          if (uploads.length > 1) {
            console.warn('Found more than 1 upload. Expected exactly 1.', { orderTemplateId });
          }

          results[orderTemplateId] = uploads[0].id;
        }
      }
    }

    return results;
  };

  const fetchPatients = async (params: {
    patientIds?: string[];
    projectId: string;
    page: number;
    pageLength: number;
    sortBy: string;
    sortOrder: 'asc' | 'desc';
    includeTotalCount: boolean;
    search: { by: string; term: string } | null;
  }): Promise<void> => {
    const deviceStatuses = isAdmin || isCustomerService ? {} : { deviceStatuses: ['readyToShip'] };

    const orderTemplateId = {
      orderTemplateId: selectedOrderTemplateId,
    };

    await loadPatients({ ...params, ...deviceStatuses, ...orderTemplateId }, async (returnedPatients, paging) => {
      if (!returnedPatients) {
        console.log('Failed to load patients');
        return;
      }
      if (paging && params.includeTotalCount) {
        setTotalPatientCount(paging.totalCount);
      }

      const patientIds = returnedPatients.map((p) => p.id);

      const foundOrdersForPatients = await getOrdersForPatients({ patientIds });
      const foundOrdersForPatientsIds = foundOrdersForPatients.map((order) => order.id);

      await loadPatientDevices({ patientIds }, async (patientDevices) => {
        resetOrders();

        let ordersByIds: Order[] = [];
        if (patientDevices && patientDevices.length) {
          // Get orders for devices where the order isn't assigned to a patient (bulk orders)
          const orderIds = patientDevices
            .filter((device) => device.orderId !== null)
            .map((device) => device.orderId!)
            // Filter out orders we already loaded through deviceIds
            .filter((orderId) => !foundOrdersForPatientsIds.includes(orderId));

          if (orderIds.length > 0) {
            ordersByIds = await getOrdersByIds({ ids: orderIds });
          }
        }

        const combinedOrders = ordersByIds ? ordersByIds.concat(foundOrdersForPatients) : foundOrdersForPatients;
        setPatientOrders(combinedOrders);

        const foundOrderTemplates = await fetchOrderTemplatesForOrders(combinedOrders);

        const instructions = await fetchPackingInstructionsForOrderTemplates(foundOrderTemplates);

        setPackingInstructions(instructions);
      });
    });
  };

  const loadPatientsAndDevices = async (
    includeTotalCount: undefined | boolean,
    resetSelection = true
  ): Promise<void> => {
    if (resetSelection) {
      setSelectedDeviceIds([]);
      setSelectedPatients([]);
    }

    fetchPatients({
      ...patientPagingConfig!,
      ...(filterByPatientId ? { patientIds: [filterByPatientId] } : {}),
      includeTotalCount:
        typeof includeTotalCount === 'boolean' ? includeTotalCount : patientPagingConfig!.includeTotalCount,
      projectId: project.id,
    });
  };

  const labCallback = (laboratory: Laboratory | null) => {
    setLabRecord(laboratory);
  };

  const projectLabCallback = (links: IProjectLaboratory[] | null) => {
    if (links) {
      getLaboratory(links[0].laboratoryId, {}, labCallback);
    }
  };

  useEffect(() => {
    getProjectLaboratories(projectId, undefined, projectLabCallback);
    getDeviceLots();
    getOrderTemplatesForProject(projectId, undefined, (ots) => {
      setOrderTemplates(ots);
      if (project.useOrderTemplates) {
        const customAttributesKeyedByOrderTemplateId = new Map<string, ICustomAttributeDefinition[]>();
        getCustomAttributeDefinitions(project.customerId, async (definitions) => {
          if (!definitions || definitions.length === 0) {
            setCustomAttributeDefinitions(customAttributesKeyedByOrderTemplateId);
            return;
          }

          for (const ot of ots ?? []) {
            const attachedAttributes = definitions!.filter((attrib) =>
              ot?.customAttributeDefinitionIds?.includes(attrib.id)
            );
            customAttributesKeyedByOrderTemplateId.set(ot.templateId, attachedAttributes);
          }
          setCustomAttributeDefinitions(customAttributesKeyedByOrderTemplateId);
        });
      }
    });
    if (!project.useOrderTemplates) {
      setCustomAttributeDefinitions(new Map([['none', project.customAttributeDefinitions ?? []]]));
    }

    return () => {
      resetPatients();
      resetDevices();
      resetDeviceLots();
      resetProjectLaboratories();
      resetOrders();
      resetShipments();
    };
  }, []);

  useEffect(() => {
    if (patientPagingConfig) {
      loadPatientsAndDevices(undefined);
    }
  }, [patientPagingConfig]);

  useEffect(() => {
    if (patientModalPopulated) {
      loadPatientsAndDevices(true);
    }
  }, [patientModalPopulated]);

  const reloadPage = async (resetSelection = true): Promise<void> => {
    loadPatientsAndDevices(true, resetSelection);
  };

  const onCreateShippingLabels = async (disposition?: string) => {
    if (isLabelGenerationInProgress.current) {
      return;
    }

    const selectedDevice = devices.find((d) => d.id === selectedDeviceIds[0]);

    if (!selectedDevice) {
      return;
    }

    isLabelGenerationInProgress.current = true;

    if (selectedDevice.orderTemplateId) {
      const shipmentCode = selectedDevice.shipmentCode ?? '';
      const shipmentCodeFirstLeg = shipmentCode.split('.')[0];

      const deviceShipments = await new Promise<Shipment[]>((resolve) => {
        getShipments(
          { orderIds: selectedDevice.orderId, shipmentCodeStartsWith: shipmentCodeFirstLeg },
          (shipmentMatches) => {
            resolve(shipmentMatches ?? []);
          }
        );
      });

      if (deviceShipments.length > 0) {
        await createShippingLabelV2(deviceShipments, disposition);
      }
    } else {
      await Promise.resolve(createShippingLabel(selectedDevice.id, disposition));
    }

    await reloadPage(false);

    isLabelGenerationInProgress.current = false;
  };

  const onCancelShippingLabel = async () => {
    const selectedDevice = devices.find((d) => d.id === selectedDeviceIds[0]);

    if (!selectedDevice) {
      return;
    }

    if (selectedDevice.orderTemplateId) {
      const shipmentCode = selectedDevice.shipmentCode ?? '';
      const shipmentCodeFirstLeg = shipmentCode.split('.')[0];

      const deviceShipments = await new Promise<Shipment[]>((resolve) => {
        getShipments(
          { orderIds: selectedDevice.orderId, shipmentCodeStartsWith: shipmentCodeFirstLeg },
          (shipmentMatches) => {
            resolve(shipmentMatches ?? []);
          }
        );
      });

      if (deviceShipments.length > 0) {
        await cancelShippingLabelV2(deviceShipments);
      }
    } else {
      const deviceIds = [selectedDevice.id];
      // Retrieve label ids by deviceIds in order to cancel by labelId
      const labels = await new Promise<IShippingLabel[]>((resolve) => {
        getShippingLabels([selectedDevice.id], async (result) => resolve(result));
      });
      const deviceIdToLabelIdMap: { [deviceId: string]: string } = {};

      labels.forEach((label: IShippingLabel) => {
        deviceIdToLabelIdMap[label.deviceId] = label.id;
      });

      await Promise.all(deviceIds.map((id: string) => cancelShippingLabel(id, deviceIdToLabelIdMap[id])));
    }

    await reloadPage(false);
  };

  const onGenerateRequisitionForm = (disposition?: string) => {
    downloadRequisitionForm(sortPatientsByPatientOrder(selectedPatients.map((p) => p.id)), disposition);
  };

  const onGenerateActivationLabels = (disposition?: string) => {
    downloadActivationLabels(sortDevicesByPatientOrder(selectedDeviceIds), disposition);
  };

  const onGenerateOrderSummaryForm = (disposition?: string) => {
    downloadOrderFulfillmentSummary(sortDevicesByPatientOrder(selectedDeviceIds), disposition);
  };

  const onGenerateCustomerLetters = (disposition?: string) => {
    downloadCustomerLetters(sortDevicesByPatientOrder(selectedDeviceIds), disposition);
  };

  const onGeneratePrintables = (download: boolean) => {
    const selectedDevice = devices.find((d) => d.id === selectedDeviceIds[0]);

    if (!selectedDevice || !selectedDevice.orderId) {
      return;
    }

    getOrder(
      selectedDevice.orderId,
      { hydrate: [download ? 'printablesDownload' : 'printables'] },
      async (theOrder) => {
        if (!theOrder || !theOrder.printables) {
          console.warn('No printables!', theOrder);
          return;
        }

        for (const doc of theOrder.printables) {
          openDocument(doc.url!, download);
        }
      }
    );
  };

  const onRemovePatients = async () => {
    await Promise.all(selectedPatients.map((p) => deletePatient(p.id)));
    loadPatientsAndDevices(true);
  };

  const placeOrder = async (
    replacementPayload: Record<string, any> | null = null,
    orderTemplateId: string | null
  ): Promise<void> => {
    await Promise.all(
      selectedPatients.map((p) => {
        const payload: any = {
          patientId: p.id,
        };

        // The selected patient should only ever have one device in deviceIds which is when a device is being replaced
        const oldDevice = p.deviceIds[0] ? devices.find((d) => d.id === p.deviceIds[0]) : undefined;

        if (oldDevice) {
          payload.replacesDeviceId = oldDevice.id;

          // include replacement information on the new order
          if (replacementPayload) {
            payload.replacementHarmCaused = replacementPayload.replacementHarmCaused;
            payload.replacementReason = replacementPayload.replacementReason;
            if (replacementPayload.replacementDescription) {
              payload.replacementDescription = replacementPayload.replacementDescription;
            }
          }

          // If NPI information exists on the device to be replaced, include it on the new order
          if (oldDevice.npi && oldDevice.providerFirstName && oldDevice.providerLastName) {
            payload.customerProvidedNpi = oldDevice.npi;
            payload.customerProvidedProviderFirstName = oldDevice.providerFirstName;
            payload.customerProvidedProviderLastName = oldDevice.providerLastName;
          }
        }

        if (replacementPayload?.customAttributeValues) {
          payload.customAttributeValues = replacementPayload.customAttributeValues;
        }
        if (orderTemplateId && orderTemplateId !== NO_ORDER_PLEASE && project.useOrderTemplates) {
          payload.orderTemplateId = orderTemplateId;
        }

        return createOrder(payload);
      })
    );

    await reloadPage(true);
  };

  const configHasCustomAttributes = () => {
    if (project.useOrderTemplates) {
      return true; // must select order config
    }

    return Boolean(project.customAttributeDefinitions?.length);
  };

  const isSinglePatientSelectedWithCustomAttributes = () =>
    selectedPatients.length === 1 && selectedPatients[0].deviceIds.length === 0 && configHasCustomAttributes();

  const onAddNewDevice = async (): Promise<void> => {
    if (isSinglePatientSelectedWithCustomAttributes()) {
      setPatientsForInitialPlacement([selectedPatients[0].id]);
      return;
    }

    const replaceIds: string[] = [];
    const initialPlacementIds: string[] = [];
    for (const aPatient of selectedPatients) {
      if (aPatient.deviceIds.length === 0) {
        initialPlacementIds.push(aPatient.id);
      }
      for (const oneDeviceId of aPatient.deviceIds) {
        const oneDevice = devices.find((d) => d.id === oneDeviceId);
        if (oneDevice && !oneDevice.isReplaced) {
          replaceIds.push(oneDeviceId);
        }
      }
    }

    setDevicesToReplace(replaceIds);

    const replacementDevices: IDevice[] = devices.filter((d) => replaceIds.includes(d.id));
    const replacementOrderIds: string[] = replacementDevices
      .map((d) => d.orderId)
      .filter((orderId) => orderId !== null) as string[];
    setOrdersToReplace(patientOrders.filter((o) => replacementOrderIds.includes(o.id)));

    if (replaceIds.length > 0) {
      // exit now because the user needs to provide a replacement reason first
      return;
    }

    setDeviceLoading(true);
    await placeOrder(null, selectedOrderTemplateId);
    setDeviceLoading(false);
  };
  const clearDeviceCallback =
    (device: IDevice) =>
    async (order: Order | null): Promise<void> => {
      if (order && device.orderId === order.id) {
        clearCancelledDevice(device.id);
      }
    };

  const onCancelOrder = async (): Promise<void> => {
    const promises: Promise<any>[] = [];
    for (let i = 0; i < selectedDeviceIds.length; i += 1) {
      const device = devices.find((dvc) => dvc.id === selectedDeviceIds[i]);
      const orderId = device?.orderId;
      if (orderId) {
        promises.push(cancelOrder(orderId, undefined, clearDeviceCallback(device)));
      }
    }

    await Promise.allSettled(promises);
  };

  const onDeleteDevice = async (): Promise<void> => {
    for (let i = 0; i < selectedDeviceIds.length; i += 1) {
      await deleteDevice(selectedDeviceIds[i]);
    }
  };

  const replaceAndCreateDevice = async (
    replacementHarmCaused: boolean,
    replacementReason: string,
    replacementDescription: string,
    customAttributeValues: Record<string, string>,
    orderTemplateId: string | null
  ): Promise<void> => {
    setDeviceLoading(true);

    const payload: Record<string, any> = {
      replacementHarmCaused,
      replacementReason,
    };

    if (replacementDescription) {
      payload.replacementDescription = replacementDescription;
    }

    if (Object.keys(customAttributeValues).length > 0) {
      payload.customAttributeValues = customAttributeValues;
    }

    await placeOrder(payload, orderTemplateId);
    setDeviceLoading(false);
  };

  const onGenerateBarcodes = (disposition?: string) => {
    downloadBarcode(sortDevicesByPatientOrder(selectedDeviceIds), disposition);
  };

  // Patients who have orders in which devices are still being processed.
  const patientIdsWithPendingOrders = [
    ...new Set(
      patientOrders
        .filter((order) => isPendingOrder(order, isAdmin, isCustomerService))
        .map((order) => order.patientId!)
    ),
  ];

  return (
    <div className="ProjectShipments">
      {(isAdmin || isCustomerService) && <ImportRecipients updatePatients={() => loadPatientsAndDevices(true)} />}

      <ShipmentRecipientsList
        isFiltered={!!filterByPatientId}
        projectId={projectId}
        patients={patients}
        devices={devices}
        deviceLots={deviceLots}
        loading={patientsLoading || devicesLoading || downloadLoading}
        selectedDeviceIds={selectedDeviceIds}
        onSelectDevices={setSelectedDeviceIds}
        selectedPatients={selectedPatients}
        onSelectPatients={setSelectedPatients}
        onSelectPatientOrder={setSelectedPatientOrder}
        createShippingLabels={onCreateShippingLabels}
        cancelShippingLabels={onCancelShippingLabel}
        generateRequisitionForm={onGenerateRequisitionForm}
        generateOrderSummaryForm={onGenerateOrderSummaryForm}
        generateCustomerLetters={onGenerateCustomerLetters}
        generateActivationLabels={onGenerateActivationLabels}
        generateBarcodes={onGenerateBarcodes}
        generatePrintables={onGeneratePrintables}
        packingInstructions={packingInstructions}
        removePatients={onRemovePatients}
        addNewDevice={onAddNewDevice}
        onCreatePatient={() => onCreatePatient(customAttributeDefinitions, reloadPage)}
        cancelOrder={onCancelOrder}
        deleteDevice={onDeleteDevice}
        onEditPatient={onEditPatient}
        totalPatientCount={totalPatientCount}
        pagingConfig={patientPagingConfig}
        updatePagingConfig={(c: Partial<PatientPagingConfig>) => setPatientPagingConfig((s) => ({ ...s!, ...c }))}
        reloadPage={reloadPage}
        project={project}
        projectLaboratories={projectLaboratories}
        laboratory={labRecord!}
        patientIdsWithPendingOrders={patientIdsWithPendingOrders}
        orders={patientOrders}
        summarizedOrderTemplates={summarizedOrderTemplates}
        selectedOrderTemplateId={selectedOrderTemplateId}
        setSelectedOrderTemplateId={setSelectedOrderTemplateId}
      />
      {PatientModal}
      <ReplacementReasonModal
        patientCount={selectedPatients.length}
        deviceCount={devicesToReplace.length}
        customAttributeDefinitions={customAttributeDefinitions}
        orderTemplates={orderTemplates ?? []}
        project={project}
        ordersToReplace={ordersToReplace}
        onCancel={() => {
          setDevicesToReplace([]);
          setOrdersToReplace([]);
        }}
        onOk={replaceAndCreateDevice}
      />
      <PlaceOrderModal
        open={patientsForInitialPlacement.length === 1}
        customAttributeDefinitions={customAttributeDefinitions}
        orderTemplates={orderTemplates ?? []}
        project={project}
        onOk={(customAttributeValues, orderTemplateId) => {
          placeOrder({ customAttributeValues }, orderTemplateId);
        }}
        onCancel={() => {
          setPatientsForInitialPlacement([]);
        }}
      />
      {shippingLabels.length > 0 && ShippingLabelsModal}
      {shippingLabelsV2.length > 0 && ShippingLabelsV2Modal}
    </div>
  );
};

export default ProjectShipments;
