export type CoreError = {
  name: string;
  property: string;
  message: string;
};

export type CoreIssues = {
  errors?: CoreError[];
};

export const serializeForDisplay = (issues: CoreIssues): string | undefined => {
  if (!issues || !issues.errors) {
    return undefined;
  }

  return issues.errors.map((e) => e.message).join('\n');
};

export const getErrorMessage = (error: any): string => {
  if (!error) {
    return 'Unknown error';
  }

  if (error.response) {
    if (error.response.data) {
      // Translation of 409 errors to an easier to understand language.
      if (error.response.status === 409) {
        if (error.response.data.message === 'Entity violates a unique constraint on property barcode') {
          return 'Barcode cannot be used because it has already been associated with another device.';
        }
      }

      if (error.response.data.issues) {
        return serializeForDisplay(error.response.data.issues) || '';
      }
      if (error.response.data.message) {
        return error.response.data.message;
      }
      return 'Unknown API error';
    }
    return 'Unknown HTTP error';
  }

  return error.message || 'Unknown error';
};
