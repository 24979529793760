import { httpClient } from 'services/index';
import { PagingRequest } from 'utils/types';

export const getCustomers = (payload: Record<string, any>, paging: PagingRequest): Promise<any> =>
  httpClient.get('/customers', { params: { ...payload, ...paging } }).then((_) => _.data);

export const getCustomer = (id: string): Promise<any> => httpClient.get(`/customers/${id}`).then((_) => _.data.results);

export const createCustomer = (data: Record<string, any>): Promise<any> =>
  httpClient.post('/customers', data).then((_) => _.data.results);

export const updateCustomer = (id: string, data: Record<string, any>): Promise<any> =>
  httpClient.patch(`/customers/${id}`, data).then((_) => _.data.results);

export const deleteCustomer = (id: string): Promise<any> => httpClient.delete(`/customers/${id}`).then((_) => _.data);
