import React, { FC, useEffect } from 'react';
import { Button, Table } from 'antd';
import SectionHeader from 'components/SectionHeader';

import { MANIFEST, USER_ROLES } from 'constants/index';
import { ROUTES } from 'constants/routes';
import { useAuth } from 'context/auth';

import Spinner from 'components/Spinner';
import { ColumnsType } from 'antd/lib/table';
import { IManifest, useManifests } from 'context/manifests';
import moment from 'moment';

interface ISingleManifest {
  results: {
    scanForms: [{ preSignedURL: string }];
  };
}

const Manifests: FC = () => {
  const { profile } = useAuth();
  const isAdmin = (profile?.role || '') === USER_ROLES.internalAdmin;
  const isCustomerService = (profile?.role || '') === USER_ROLES.internalCustomerService;
  const isShipper = (profile?.role || '') === USER_ROLES.internalShipper;
  const formattedTodayDate = moment().format('YYYY-MM-DD');
  const {
    getManifests,
    manifests,
    getShippingCountForToday,
    todayShipmentCount,
    loading: manifestsLoading,
    onCreateManifest,
    getManifest,
    todayShipmentCountLoading,
  } = useManifests();

  useEffect(() => {
    getManifests();
    getShippingCountForToday();
  }, []);

  const breadcrumbNameMap = {
    [ROUTES.projects]: 'Projects',
  };

  const getPdfUrl = (item: ISingleManifest) => {
    for (const scanForm of item?.results?.scanForms) {
      // eslint-disable-next-line security/detect-non-literal-fs-filename
      window.open(scanForm.preSignedURL, '_blank');
    }
  };

  const columns: ColumnsType<IManifest> = [
    {
      title: 'Ship Date',
      dataIndex: 'shipDate',
      key: 'shipDate',
    },
    {
      title: 'Source Zip',
      dataIndex: 'origin',
      key: 'origin',
    },
    {
      title: 'Shipment Count',
      dataIndex: 'totalPieces',
      key: 'totalPieces',
      render: (_, record) =>
        record.id !== 'today' ? (
          <span>
            {record.totalPieces} {record.totalPieces === 1 ? 'Shipment' : 'Shipments'}
          </span>
        ) : (
          <span>
            {todayShipmentCountLoading && <Spinner spinning={todayShipmentCountLoading} />}
            {!todayShipmentCountLoading && todayShipmentCount === 0 && 'No Shipments For Today'}
            {!todayShipmentCountLoading &&
              todayShipmentCount > 0 &&
              (todayShipmentCount === 1 ? `${todayShipmentCount} Shipment` : `${todayShipmentCount} Shipments`)}
          </span>
        ),
    },
    isAdmin || isShipper
      ? {
          title: 'Actions',
          dataIndex: 'id',
          key: 'id',
          render: (_, record) =>
            record.id !== 'today' ? (
              <Button type="link" download="test" onClick={() => getManifest(record.id, getPdfUrl)}>
                Print Manifest
              </Button>
            ) : (
              <Button
                type="default"
                onClick={() =>
                  onCreateManifest({
                    shipperType: MANIFEST.shipperType,
                    origin: MANIFEST.origin,
                    shipDate: formattedTodayDate,
                  })
                }
              >
                Generate Manifest
              </Button>
            ),
        }
      : {},
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (_, record) => (record.createdAt ? moment(record.createdAt).format('YYYY-MM-DD') : '-'),
    },
  ];

  const todayRow: IManifest = {
    shipDate: `${formattedTodayDate} (Today)`,
    origin: MANIFEST.origin,
    totalPieces: null,
    id: 'today',
    createdAt: '',
    shipperType: MANIFEST.shipperType,
    scanForms: null,
    updatedAt: null,
  };

  return (
    <div>
      <div className="Manifests">
        <Spinner spinning={manifestsLoading}>
          <div>
            <SectionHeader
              breadcrumbNameMap={breadcrumbNameMap}
              isAdmin={isAdmin}
              isCustomerService={isCustomerService}
              isShipper={isShipper}
            />
          </div>
          <h1>Manifests</h1>

          <Table
            rowKey="id"
            columns={columns.filter((column) => (Object.keys(column).length > 0 ? column : ''))} // Hide empty columns
            bordered
            dataSource={[todayRow, ...manifests]}
          />
        </Spinner>
      </div>
    </div>
  );
};

export default Manifests;
