import React, { useState, useEffect } from 'react';

import { OrderTemplate } from '@tassoinc/core-api-client';
import { useOrderTemplates } from 'context/orderTemplates';
import OrderTemplateAlternativeR777SelectModal from 'components/OrderTemplateAlternativeR777SelectModal';

interface State {
  orderTemplates: OrderTemplate[];
  onTemplateChange: (orderTemplateId: string) => void;
}

const getInitialState = (): State => ({
  orderTemplates: [],
  onTemplateChange: () => {},
});

const useOrderTemplateSelectModal = (onTemplateChange: (a: string) => void) => {
  const { getOrderTemplatesForProject, error } = useOrderTemplates();

  const [modalVisible, setModalVisible] = useState(false);
  const [linkingTemplate, setLinkingTemplate] = useState<OrderTemplate | undefined>(undefined);
  const [working, setWorking] = useState(false);

  const [settings, setSettings] = useState<State>(getInitialState());

  useEffect(() => {
    if (error) {
      setWorking(false);
    }
  }, [error]);

  const closeModal = () => {
    setModalVisible(false);
    setSettings(getInitialState());
  };

  const handleChange = async (orderTemplateId: string): Promise<void> => {
    onTemplateChange(orderTemplateId);
    closeModal();
  };

  const openModal = (
    projectId: string,
    linkingOrderTemplate: OrderTemplate,
    onChange: (orderTemplateId: string) => void
  ) => {
    setWorking(true);
    setModalVisible(true);
    setLinkingTemplate(linkingOrderTemplate);

    getOrderTemplatesForProject(projectId, undefined, async (templates) => {
      setSettings({
        orderTemplates: templates ?? [],
        onTemplateChange: onChange,
      });
      setWorking(false);
    });
  };

  const Modal = (
    <OrderTemplateAlternativeR777SelectModal
      orderTemplates={settings.orderTemplates}
      visible={modalVisible}
      linkingTemplate={linkingTemplate}
      onOk={handleChange}
      onCancel={closeModal}
      working={working}
    />
  );

  return { Modal, openModal };
};

export default useOrderTemplateSelectModal;
