import { OrderTemplate } from '@tassoinc/core-api-client';
import { OrderTemplatesViewOrder } from 'context/projects';

/**
 * Sorts an array of order templates by the `templateId` value.
 * The ordering information comes from the `project.orderTemplatesViewOrder` property.
 *
 * @returns a sorted shallow copy of order templates
 */
export const sortOrderTemplatesByTemplateId = <T extends { templateId: string }[]>(
  templates: T,
  viewConfig: OrderTemplatesViewOrder
): T => {
  // Map of values in the format templateId:positionInArray
  const map = viewConfig.reduce<Map<string, number>>((acc, item, index) => {
    acc.set(item.templateId, index);
    return acc;
  }, new Map());

  return ([...templates] as T).sort((a, b) => {
    const orderA = map.get(a.templateId) ?? Number.MAX_SAFE_INTEGER;
    const orderB = map.get(b.templateId) ?? Number.MAX_SAFE_INTEGER;

    if (orderA === orderB) {
      return 0;
    }

    return orderA > orderB ? 1 : -1;
  });
};

/**
 * Sorts order templates by view order config if it's available.
 * Otherwise sorts order templates alphabetically in ascending order by the `name` property.
 *
 * @returns a sorted shallow copy of order templates
 */
export const sortOrderTemplates = <T extends { templateId: string; name: string }[]>(
  orderTemplates: T,
  viewConfig: OrderTemplatesViewOrder
): T => {
  if (viewConfig.length === 0) {
    return ([...orderTemplates] as T).sort((a, b) => a.name.localeCompare(b.name));
  }
  return sortOrderTemplatesByTemplateId(orderTemplates, viewConfig);
};

/**
 * Given an array of order templates and a view configuration, find and return a "default"
 * template if one is set in the view config.
 *
 * If a default value isn't found, falls back to the "legacy" template being the default.
 */
export const getDefaultOrderTemplate = (
  orderTemplates: OrderTemplate[],
  viewConfig: OrderTemplatesViewOrder
): OrderTemplate | null => {
  let orderTemplate: OrderTemplate | null = null;

  const defaultItem = viewConfig.find((item) => Boolean(item.default));

  if (defaultItem) {
    orderTemplate = orderTemplates.find((ot) => ot.templateId === defaultItem.templateId) ?? null;
  }

  if (!orderTemplate) {
    orderTemplate = orderTemplates.find((ot) => ot.templateId === 'legacy') ?? null;
  }

  return orderTemplate;
};
