export const AUTH_TYPES = {
  setPassword: 'setPassword',
  setupMfa: 'setupMfa',
  enterCode: 'enterCode',
};

export const AWS_CHALLENGE_NAMES = {
  NEW_PASSWORD_REQUIRED: 'NEW_PASSWORD_REQUIRED',
  MFA_SETUP: 'MFA_SETUP',
  SOFTWARE_TOKEN_MFA: 'SOFTWARE_TOKEN_MFA',
};
