import React, { FC, useState, createContext } from 'react';

import { loadAllPages } from 'utils/functions';
import { getErrorMessage } from 'utils/issues';

import { projectAnalyteLinksApi as api } from '../api';
import Notification from '../utils/notification';
import { Analyte } from './analytes';
import { IProject } from './projects';

export interface ProjectAnalyteLink {
  id: string;
  projectId: string;
  analyteId: string;
  viewOrder: number;
  createdAt: string;
  updatedAt: string | null;
  analyte?: Analyte;
  project?: IProject;
}

interface State {
  projectAnalyteLinks: ProjectAnalyteLink[];
  projectAnalyteLink: ProjectAnalyteLink | null;
  loading: boolean;
  error: string | null;
}

type GetProjectAnalyteLinksCallback = (links: ProjectAnalyteLink[] | null) => Promise<void>;
type GetProjectAnalyteLinkCallback = (links: ProjectAnalyteLink | null) => Promise<void>;
type CreateProjectAnalyteLinkCallback = (links: ProjectAnalyteLink | null) => Promise<void>;
type UpdateProjectAnalyteLinkCallback = (links: ProjectAnalyteLink | null) => Promise<void>;
type DeleteProjectAnalyteLinkCallback = (links: ProjectAnalyteLink | null) => Promise<void>;

type GetProjectAnalyteLinksOptions = {
  hideNotification?: boolean;
  callback?: GetProjectAnalyteLinksCallback;
  skipStateUpdate?: boolean;
};
type GetProjectAnalyteLinkOptions = { hideNotification?: boolean; callback?: GetProjectAnalyteLinkCallback };
type CreateProjectAnalyteLinkOptions = { hideNotification?: boolean; callback?: CreateProjectAnalyteLinkCallback };
type UpdateProjectAnalyteLinkOptions = { hideNotification?: boolean; callback?: UpdateProjectAnalyteLinkCallback };
type BulkUpdateProjectAnalyteLinkOptions = { hideNotification?: boolean; callback?: GetProjectAnalyteLinksCallback };
type DeleteProjectAnalyteLinkOptions = { hideNotification?: boolean; callback?: DeleteProjectAnalyteLinkCallback };

interface Context extends State {
  getProjectAnalyteLinks: (projectId: string, options?: GetProjectAnalyteLinksOptions) => Promise<void>;
  getProjectAnalyteLink: (id: string, options?: GetProjectAnalyteLinkOptions) => Promise<void>;
  createProjectAnalyteLink: (
    data: api.CreateProjectAnalyteLinkPayload,
    options?: CreateProjectAnalyteLinkOptions
  ) => Promise<void>;
  updateProjectAnalyteLink: (
    id: string,
    data: api.UpdateProjectAnalyteLinkPayload,
    options?: UpdateProjectAnalyteLinkOptions
  ) => Promise<void>;
  bulkUpdateProjectAnalyteLinks: (
    updateLinks: { [projectAnalyteLinkId: string]: api.UpdateProjectAnalyteLinkPayload },
    options?: BulkUpdateProjectAnalyteLinkOptions
  ) => Promise<void>;
  deleteProjectAnalyteLink: (id: string, options?: DeleteProjectAnalyteLinkOptions) => Promise<void>;
  resetProjectAnalyteLinks: () => void;
}

const sortByViewOrder = (a: ProjectAnalyteLink, b: ProjectAnalyteLink): -1 | 0 | 1 => {
  if (a.viewOrder === b.viewOrder) {
    return 0;
  }
  return a.viewOrder > b.viewOrder ? 1 : -1;
};

const getInitialState = (): State => ({
  projectAnalyteLinks: [],
  projectAnalyteLink: null,
  error: null,
  loading: false,
});

const ProjectAnalyteLinksContext = createContext<Context>({
  ...getInitialState(),
  getProjectAnalyteLinks: async () => {},
  getProjectAnalyteLink: async () => {},
  createProjectAnalyteLink: async () => {},
  updateProjectAnalyteLink: async () => {},
  bulkUpdateProjectAnalyteLinks: async () => {},
  deleteProjectAnalyteLink: async () => {},
  resetProjectAnalyteLinks: () => {},
});

const ProjectAnalyteLinksProvider: FC<any> = (props) => {
  const [state, setState] = useState<State>({ ...getInitialState() });

  const getProjectAnalyteLinks = async (
    projectId: string,
    options: GetProjectAnalyteLinksOptions = {}
  ): Promise<void> => {
    const skipStateUpdate = options.skipStateUpdate === true;

    if (!skipStateUpdate) {
      setState((s) => ({
        ...s,
        loading: true,
        error: null,
      }));
    }

    try {
      const links = await loadAllPages<ProjectAnalyteLink, string>(api.getProjectAnalyteLinks, projectId, {
        pageLength: 100,
        sortBy: 'createdAt',
        isDescending: false,
      });

      if (!skipStateUpdate) {
        setState((s) => ({
          ...s,
          projectAnalyteLinks: links.sort(sortByViewOrder),
        }));
      }

      if (options.callback) {
        await options.callback(links);
      }
    } catch (e) {
      const errorMessage = getErrorMessage(e);

      if (!skipStateUpdate) {
        setState((s) => ({
          ...s,
          error: errorMessage,
        }));
      }

      if (!options.hideNotification) {
        Notification({
          duration: 2,
          type: 'error',
          message: 'Cannot get analyte links',
          description: errorMessage,
        });
      }

      if (options.callback) {
        await options.callback(null);
      }
    } finally {
      if (!skipStateUpdate) {
        setState((s) => ({
          ...s,
          loading: false,
        }));
      }
    }
  };

  const getProjectAnalyteLink = async (id: string, options: GetProjectAnalyteLinkOptions = {}): Promise<void> => {
    setState((s) => ({
      ...s,
      loading: true,
      error: null,
    }));

    try {
      const link = await api.getProjectAnalyteLink(id);

      setState((s) => ({
        ...s,
        projectAnalyteLink: link,
      }));

      if (options.callback) {
        await options.callback(link);
      }
    } catch (e) {
      const errorMessage = getErrorMessage(e);

      setState((s) => ({
        ...s,
        error: errorMessage,
      }));

      if (!options.hideNotification) {
        Notification({
          type: 'error',
          message: 'Cannot get analyte link details',
          description: errorMessage,
        });
      }

      if (options.callback) {
        await options.callback(null);
      }
    }

    setState((s) => ({
      ...s,
      loading: false,
    }));
  };

  const createProjectAnalyteLink = async (
    data: api.CreateProjectAnalyteLinkPayload,
    options: CreateProjectAnalyteLinkOptions = {}
  ): Promise<void> => {
    setState((s) => ({
      ...s,
      loading: true,
      error: null,
    }));

    try {
      const link = await api.createProjectAnalyteLink(data);

      setState((s) => ({
        ...s,
        projectAnalyteLinks: [...s.projectAnalyteLinks, link].sort(sortByViewOrder),
      }));

      if (!options.hideNotification) {
        Notification({ type: 'success', message: 'Analyte linked to project' });
      }

      if (options.callback) {
        await options.callback(link);
      }
    } catch (e) {
      const errorMessage = getErrorMessage(e);

      setState((s) => ({
        ...s,
        error: errorMessage,
      }));

      if (!options.hideNotification) {
        Notification({
          type: 'error',
          message: 'Analyte link not created',
          description: errorMessage,
        });
      }

      if (options.callback) {
        await options.callback(null);
      }
    }

    setState((s) => ({
      ...s,
      loading: false,
    }));
  };

  const updateProjectAnalyteLink = async (
    id: string,
    data: api.UpdateProjectAnalyteLinkPayload,
    options: UpdateProjectAnalyteLinkOptions = {}
  ): Promise<void> => {
    setState((s) => ({
      ...s,
      loading: true,
      error: null,
    }));

    try {
      const updatedLink = await api.updateProjectAnalyteLink(id, data);

      setState((s) => ({
        ...s,
        projectAnalyteLinks: s.projectAnalyteLinks
          .map((link) => (link.id === id ? updatedLink : link))
          .sort(sortByViewOrder),
        projectAnalyteLink: s.projectAnalyteLink && s.projectAnalyteLink.id === id ? updatedLink : s.projectAnalyteLink,
      }));

      if (!options.hideNotification) {
        Notification({ type: 'success', message: 'Analyte link updated' });
      }

      if (options.callback) {
        await options.callback(updatedLink);
      }
    } catch (e) {
      const errorMessage = getErrorMessage(e);

      setState((s) => ({
        ...s,
        error: errorMessage,
      }));

      if (!options.hideNotification) {
        Notification({
          type: 'error',
          message: 'Analyte link not updated',
          description: errorMessage,
        });
      }

      if (options.callback) {
        await options.callback(null);
      }
    }

    setState((s) => ({
      ...s,
      loading: false,
    }));
  };

  const bulkUpdateProjectAnalyteLinks = async (
    payload: { [projectAnalyteLinkId: string]: api.UpdateProjectAnalyteLinkPayload },
    options: BulkUpdateProjectAnalyteLinkOptions = {}
  ): Promise<void> => {
    setState((s) => ({
      ...s,
      loading: true,
      error: null,
    }));

    try {
      const updatedLinks: ProjectAnalyteLink[] = await Promise.all(
        Object.keys(payload).map((id) => api.updateProjectAnalyteLink(id, payload[id]))
      );

      const currentLinks = [...state.projectAnalyteLinks];

      updatedLinks.forEach((link) => {
        const i = currentLinks.findIndex((x) => x.id === link.id);
        if (i > -1) {
          currentLinks[i] = { ...currentLinks[i], ...link };
        }
      });

      setState((s) => ({
        ...s,
        projectAnalyteLinks: currentLinks,
        projectAnalyteLink: null,
      }));

      if (!options.hideNotification) {
        Notification({ type: 'success', message: `Analyte links updated` });
      }

      if (options.callback) {
        await options.callback(updatedLinks);
      }
    } catch (e) {
      const errorMessage = getErrorMessage(e);

      setState((s) => ({
        ...s,
        error: errorMessage,
      }));

      if (!options.hideNotification) {
        Notification({
          type: 'error',
          message: 'Analyte links not updated',
          description: errorMessage,
        });
      }

      if (options.callback) {
        await options.callback(null);
      }
    }

    setState((s) => ({
      ...s,
      loading: false,
    }));
  };

  const deleteProjectAnalyteLink = async (id: string, options: DeleteProjectAnalyteLinkOptions = {}): Promise<void> => {
    setState((s) => ({
      ...s,
      loading: true,
      error: null,
    }));

    try {
      const deletedLink = await api.deleteProjectAnalyteLink(id);

      setState((s) => ({
        ...s,
        projectAnalyteLinks: s.projectAnalyteLinks.filter((link) => link.id !== id),
        projectAnalyteLink: s.projectAnalyteLink && s.projectAnalyteLink.id === id ? null : s.projectAnalyteLink,
      }));

      if (!options.hideNotification) {
        Notification({ type: 'success', message: 'Analyte link deleted' });
      }

      if (options.callback) {
        await options.callback(deletedLink);
      }
    } catch (e) {
      const errorMessage = getErrorMessage(e);

      setState((s) => ({
        ...s,
        error: errorMessage,
      }));

      if (!options.hideNotification) {
        Notification({
          type: 'error',
          message: 'Analyte link not deleted',
          description: errorMessage,
        });
      }

      if (options.callback) {
        await options.callback(null);
      }
    }

    setState((s) => ({
      ...s,
      loading: false,
    }));
  };

  const resetProjectAnalyteLinks = (): void => {
    setState({ ...getInitialState() });
  };

  return (
    <ProjectAnalyteLinksContext.Provider
      value={{
        projectAnalyteLinks: state.projectAnalyteLinks,
        projectAnalyteLink: state.projectAnalyteLink,
        loading: state.loading,
        error: state.error,
        getProjectAnalyteLinks,
        getProjectAnalyteLink,
        createProjectAnalyteLink,
        updateProjectAnalyteLink,
        bulkUpdateProjectAnalyteLinks,
        deleteProjectAnalyteLink,
        resetProjectAnalyteLinks,
      }}
      {...props}
    />
  );
};

const useProjectAnalyteLinks = (): Context => React.useContext(ProjectAnalyteLinksContext);

export { ProjectAnalyteLinksProvider, useProjectAnalyteLinks };
