import { httpClient } from 'services/index';
import { PagingRequest } from 'utils/types';

// EMAIL

export const getEmailTemplates = (queryParams: Record<string, any>, paging: PagingRequest): Promise<any> =>
  httpClient.get('/emailTemplates', { params: { ...queryParams, ...paging } }).then((_) => _.data);

export const getEmailTemplate = (id: string): Promise<any> =>
  httpClient.get(`/emailTemplates/${id}`).then((_) => _.data.results);

export type CreateEmailTemplatePayload = {
  customerId?: string;
  templateName: string;
  description?: string;
  htmlBody: string;
  textBody: string;
  subject: string;
  parameters?: Record<string, any>;
};
export const createEmailTemplate = (data: CreateEmailTemplatePayload): Promise<any> =>
  httpClient.post('/emailTemplates', data).then((_) => _.data.results);

export type UpdateEmailTemplatePayload = {
  customerId?: string;
  templateName?: string;
  description?: string;
  htmlBody?: string;
  textBody?: string;
  subject?: string;
  parameters?: Record<string, any>;
};
export const updateEmailTemplate = (id: string, data: UpdateEmailTemplatePayload): Promise<any> =>
  httpClient.patch(`/emailTemplates/${id}`, data).then((_) => _.data.results);

export const deleteEmailTemplate = (id: string): Promise<any> =>
  httpClient.delete(`/emailTemplates/${id}`).then((_) => _.data.results);

// SMS
export const getSmsTemplates = (queryParams: Record<string, any>, paging: PagingRequest): Promise<any> =>
  httpClient.get('/smsTemplates', { params: { ...queryParams, ...paging } }).then((_) => _.data);

export const getSmsTemplate = (id: string): Promise<any> =>
  httpClient.get(`/smsTemplates/${id}`).then((_) => _.data.results);

export type CreateSmsTemplatePayload = {
  customerId?: string;
  templateName: string;
  description?: string;
  body: string;
  parameters?: Record<string, any>;
};
export const createSmsTemplate = (data: CreateSmsTemplatePayload): Promise<any> =>
  httpClient.post('/smsTemplates', data).then((_) => _.data.results);

export type UpdateSmsTemplatePayload = {
  customerId?: string;
  templateName?: string;
  description?: string;
  body?: string;
  parameters?: Record<string, any>;
};
export const updateSmsTemplate = (id: string, data: UpdateSmsTemplatePayload): Promise<any> =>
  httpClient.patch(`/smsTemplates/${id}`, data).then((_) => _.data.results);

export const deleteSmsTemplate = (id: string): Promise<any> =>
  httpClient.delete(`/smsTemplates/${id}`).then((_) => _.data.results);
