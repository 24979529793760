import React, { FC, useState, createContext } from 'react';

import { sitesApi } from 'api';
import { loadAllPages } from 'utils/functions';
import * as issues from 'utils/issues';
import Notification from 'utils/notification';
import { Site } from '@tassoinc/core-api-client';

interface State {
  sites: Site[];
  site: Site | null;
  error: string | null;
  loading: boolean;
}

type GetSitesForCustomerFunction = (
  customerId: string,
  callback?: (sites: Site[] | null) => Promise<void> | void
) => Promise<void>;
type GetSiteFunction = (id: string, callback?: (site: Site | null) => Promise<void>) => Promise<void>;
type CreateSiteFunction = (body: any, callback?: (site: any) => Promise<void>) => Promise<void>;
type UpdateSiteFunction = (id: string, body: any, callback?: (site: Site | null) => Promise<void>) => Promise<void>;
type DeleteSiteFunction = (id: string, callback?: (site: Site) => Promise<void>) => Promise<void>;

interface Context extends State {
  getSitesForCustomer: GetSitesForCustomerFunction;
  resetSites: () => void;
  getSite: GetSiteFunction;
  createSite: CreateSiteFunction;
  updateSite: UpdateSiteFunction;
  deleteSite: DeleteSiteFunction;
}

const getInitialState = (): State => ({
  sites: [],
  site: null,
  error: null,
  loading: false,
});

const SitesContext = createContext<Context>({
  ...getInitialState(),
  getSite: async () => {},
  resetSites: () => {},
  getSitesForCustomer: async () => {},
  createSite: async () => {},
  updateSite: async () => {},
  deleteSite: async () => {},
});

const SitesProvider: FC = (props) => {
  const [state, setState] = useState<State>(getInitialState);

  const resetSites = (): void => {
    setState(getInitialState());
  };

  const getSitesForCustomer: GetSitesForCustomerFunction = async (id, callback) => {
    setState((s) => ({
      ...s,
      loading: true,
      error: null,
    }));

    try {
      const sites = await loadAllPages<any, any>(
        sitesApi.get,
        { customerIds: [id] },
        {
          pageLength: 50,
          sortBy: 'createdAt',
          isDescending: false,
        }
      );

      if (sites) {
        setState((s) => ({
          ...s,
          sites,
        }));

        if (callback) {
          await callback(sites);
        }
      }
    } catch (e) {
      const errorMessage = issues.getErrorMessage(e);

      setState((s) => ({
        ...s,
        error: errorMessage,
      }));

      Notification({
        type: 'error',
        message: 'Failed to get sites.',
        description: errorMessage,
      });

      if (callback) {
        await callback(null);
      }
    } finally {
      setState((s) => ({
        ...s,
        loading: false,
      }));
    }
  };

  const getSite: GetSiteFunction = async (id, callback) => {
    setState((s) => ({
      ...s,
      loading: true,
      error: null,
    }));

    try {
      const site = await sitesApi.getById(id);
      if (site) {
        setState((s) => ({
          ...s,
          site,
        }));

        if (callback) {
          await callback(site);
        }
      }
    } catch (e) {
      const errorMessage = issues.getErrorMessage(e);

      setState((s) => ({
        ...s,
        error: errorMessage,
      }));

      Notification({
        type: 'error',
        message: 'Cannot get site details.',
        description: errorMessage,
      });

      if (callback) {
        await callback(null);
      }
    } finally {
      setState((s) => ({
        ...s,
        loading: false,
      }));
    }
  };

  const createSite: CreateSiteFunction = async (data, callback?) => {
    setState((s) => ({
      ...s,
      loading: true,
      error: null,
    }));

    try {
      const newSite = await sitesApi.insertSite(data);

      setState((s) => ({
        ...s,
        loading: false,
        sites: [...s.sites, newSite],
      }));

      Notification({ type: 'success', message: 'Site successfully created.' });

      if (callback) {
        await callback(newSite);
      }
    } catch (e) {
      const errorMessage = issues.getErrorMessage(e);

      setState((s) => ({
        ...s,
        error: errorMessage,
      }));

      Notification({
        type: 'error',
        message: 'Site was not created.',
        description: errorMessage,
      });

      if (callback) {
        await callback(null);
      }
    }

    setState((s) => ({
      ...s,
      loading: false,
    }));
  };

  const updateSite: UpdateSiteFunction = async (
    id: string,
    data,
    callback?: (site: Site) => Promise<void>
  ): Promise<void> => {
    try {
      setState((s) => ({
        ...s,
        loading: true,
        error: null,
      }));

      const updatedSite = await sitesApi.updateSite(id, data);

      setState((s) => ({
        ...s,
        sites: s.sites.map((site) => (site.id === id ? updatedSite : site)),
        loading: false,
      }));

      Notification({ type: 'success', message: 'Site successfully updated.' });

      if (callback) {
        await callback(updatedSite);
      }
    } catch (e) {
      const errorMessage = issues.getErrorMessage(e);

      setState((s) => ({
        ...s,
        loading: false,
        error: errorMessage,
      }));

      Notification({
        type: 'error',
        message: 'Site was not updated.',
        description: errorMessage,
      });
    }
  };

  const deleteSite = async (id: string, callback?: (site: Site) => Promise<void>): Promise<void> => {
    try {
      setState((s) => ({
        ...s,
        loading: true,
        error: null,
      }));

      const deletedSite = await sitesApi.deleteSite(id);

      setState((s) => ({
        ...s,
        sites: s.sites.filter((p) => p.id !== id),
        site: s.sites && s.site?.id === id ? null : s.site,
        loading: false,
      }));

      Notification({ type: 'success', message: 'Site successfully deleted.' });

      if (callback) {
        await callback(deletedSite);
      }
    } catch (e) {
      const errorMessage = issues.getErrorMessage(e);

      setState((s) => ({
        ...s,
        loading: false,
        error: errorMessage,
      }));

      Notification({
        type: 'error',
        message: 'Site was not deleted.',
        description: errorMessage,
      });
    }
  };

  return (
    <SitesContext.Provider
      value={{
        sites: state.sites,
        site: state.site,
        loading: state.loading,
        error: state.error,
        getSitesForCustomer,
        resetSites,
        getSite,
        createSite,
        updateSite,
        deleteSite,
      }}
      {...props}
    />
  );
};

const useSites = (): Context => React.useContext(SitesContext);

export { SitesProvider, useSites };
