import React from 'react';
import { UPLOAD_LOCALES } from 'constants/locales';

import { Select } from 'antd';
import clsx from 'clsx';

interface LocaleSelectorProps {
  value: string;
  usedValues: string[];
  disabled: boolean;
  onChange: (locale: string) => void;
}

const LocaleSelector: React.FC<LocaleSelectorProps> = ({ value, usedValues, disabled, onChange }) => {
  // Do not allow selecting the same locale for multiple file.
  // Leave only those options that are not in the `usedValues` list.
  const options = [
    ...Object.keys(UPLOAD_LOCALES)
      .filter((key) => !usedValues.includes(key) || key === value)
      .map((loc) => ({
        value: loc,
        label: UPLOAD_LOCALES[loc as keyof typeof UPLOAD_LOCALES],
      })),
  ];

  const hasSelectedValue = !!UPLOAD_LOCALES[value as keyof typeof UPLOAD_LOCALES];

  return (
    <Select
      disabled={disabled}
      size="small"
      showSearch
      className={clsx({ LocaleSelector: true, Selected: hasSelectedValue })}
      dropdownMatchSelectWidth={false}
      options={options}
      bordered={false}
      value={value || ''}
      onSelect={(v: string) => onChange(v)}
      defaultValue="en"
      optionFilterProp="children"
      filterOption={(input, option) =>
        ((option!.label as string | undefined) ?? '').toLowerCase().includes(input.toLowerCase())
      }
      filterSort={(optionA, optionB) =>
        ((optionA!.label as string | undefined) ?? '')
          .toLowerCase()
          .localeCompare(((optionB!.label as string | undefined) ?? '').toLowerCase())
      }
    />
  );
};

export default LocaleSelector;
