import React, { FC, useEffect, useState, createContext } from 'react';

import { Spin, Space, Row } from 'antd';
import { useHistory } from 'react-router-dom';

import { getUserDetails } from 'api/auth';
import IdleLogoutWarningModal from 'components/IdleLogoutWarningModal';
import { USER_ROLES } from 'constants/index';
import { ROUTES } from 'constants/routes';
import { AUTH_TYPES, AWS_CHALLENGE_NAMES } from 'features/Login/constants';
import Auth from 'utils/cognito';
import Notification, { closeNotification } from 'utils/notification';
import { IIdleTimer, useIdleTimer } from 'react-idle-timer';

// Automatically log the user out after this much time has passed. If number is 0, the check is disabled.
const userIdleTimeoutMs = Number(process.env.USER_IDLE_TIMEOUT_MINUTES || 15) * 60 * 1000;

// This is the time BEFORE userIdleTimeoutMs at which the warning prompt will open. `useIdleTimer` requires that that
// this value (`promptBeforeIdle`) be less than userIdleTimeoutMs (`timeout`). As we want to be able to disable the
// idle logout feature (setting userIdleTimeoutMs to 0) for ease of local development, we force this to -1 to prevent
// potential configuration issues.
const userIdleTimeoutWarningMs =
  userIdleTimeoutMs === 0 ? -1 : Number(process.env.USER_IDLE_TIMEOUT_WARNING_MINUTES || 5) * 60 * 1000;

// Limits how often the event handling logic for the IdleWarning and IdleTimeout event handlers can execute. The actual
// event listeners still respond to all events, but will only enter into the handling logic if at least this much time
// has lapsed since the last execution.
const userIdleEventHandlingThrottleMs = 5_000;

const autoLogoutNotificationHandle = 'userIdleLogoutNotificationKey';

enum IdleStateMessages {
  CloseIdleWarning,
  UserSelectedLogout,
}

// ++ auto-logout
let autoLogoutTimeout: any = null;

const cancelAutoLogoutCheck = (): void => {
  if (autoLogoutTimeout !== null) {
    clearTimeout(autoLogoutTimeout);
    autoLogoutTimeout = null;
  }
};

const autoLogoutCheck = async (
  logout: (params: { message: string; keepOpen: boolean }) => Promise<void>
): Promise<void> => {
  let isValidSession = false;

  try {
    const session = await Auth.currentSession();
    isValidSession = session.isValid();
  } catch (e: any) {
    console.log(e);
  }

  if (!isValidSession) {
    console.log('AUTOLOGOUT: session expired, logging out...');
    await logout({ message: 'Your session expired', keepOpen: true });
    return;
  }

  if (autoLogoutTimeout === null) {
    autoLogoutTimeout = setTimeout(async () => {
      autoLogoutTimeout = null;
      await autoLogoutCheck(logout);
    }, 5000);
  }
};
// -- auto-logout

// https://docs.amplify.aws/lib/auth/mfa/q/platform/js

export interface IUserProfile {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  role: string;
  username: string;
}

interface IAction {
  type: string;
  callback: (...args: any[]) => void;
  [k: string]: any;
}

export interface IAuthContext {
  profile: IUserProfile | null;
  action: IAction | null;
  login: (username: string, password: string) => Promise<void>;
  logout: (params?: { message: string; keepOpen: boolean }) => Promise<void>;
  forgotPassword: (email: string) => Promise<void>;
  confirmPassword: (email: string, key: string, password: string, callback?: any) => Promise<void>;
  loading: boolean;
  error: any;
}

const AuthContext = createContext<IAuthContext>({
  profile: null,
  action: null,
  login: async () => {},
  logout: async () => {},
  forgotPassword: async () => {},
  confirmPassword: async () => {},
  loading: false,
  error: null,
});

type AuthState = {
  action: IAction | null;
  profile: IUserProfile | null;
  error: string | null;
  loading: boolean;
  populated: boolean;
};

const AuthProvider: FC = (props) => {
  const history = useHistory();

  const [state, setState] = useState<AuthState>({
    action: null,
    profile: null,
    error: null,
    loading: true,
    populated: false,
  });

  const [idlePromptOpen, setIdlePromptOpen] = useState(false);

  const onIdle = async () => {
    if (!state.profile) {
      return;
    }

    // We do not need to explicitly call `pauseIdleTimer` in this handler because the timer is set to start manually.
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    await logout({
      message: 'For your security, you have been logged out due to inactivity.',
      keepOpen: true,
      handle: autoLogoutNotificationHandle,
    });
  };

  // Message handler used to keep tab state in-sync. The idleTimer library itself handles keeping the timers in sync,
  // which means it will call the timer handlers across all tabs for us (opening the warning across all tabs). This
  // handler is used to perform any synchronization logic needed following user interaction (i.e., if they click on
  // either of the buttons in the warning modal).
  // Messages are passed by calling the `message(..)` method returned by `useIdleTimer()`. Here we've typed the input
  // to `IdleStateMessages`, but `message(..)` is capable of passing values of any type (including objects).
  const onMessage = (message: IdleStateMessages, idleTimer?: IIdleTimer) => {
    if (!idleTimer) {
      return;
    }

    if (!state.profile) {
      // If this tab isn't logged in, do nothing. (Prevents accidental logout prompting.)
      return;
    }

    switch (message) {
      case IdleStateMessages.CloseIdleWarning:
        setIdlePromptOpen(false);
        break;
      case IdleStateMessages.UserSelectedLogout:
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        userSelectedLogoutHandler();
        break;
      default:
        break;
    }
  };

  // useIdleTimer has a default set of events (https://idletimer.dev/docs/api/props#events) that it listens for.
  const {
    start: startIdleTimer,
    pause: pauseIdleTimer,
    message: idleTimerMessageTabs,
  } = useIdleTimer({
    onIdle,
    onMessage,
    onActive: () => setIdlePromptOpen(false),
    onPrompt: () => {
      if (state.profile) {
        setIdlePromptOpen(true);
      }
    },
    timeout: userIdleTimeoutMs,
    promptBeforeIdle: userIdleTimeoutWarningMs,
    eventsThrottle: userIdleEventHandlingThrottleMs,
    startManually: true,
    stopOnIdle: true,
    crossTab: true,
    syncTimers: 500,
  });

  useEffect(() => {
    if (state.profile && userIdleTimeoutMs !== 0) {
      startIdleTimer();
    }
  }, [state.profile, startIdleTimer]);

  const logout = async (params?: { message: string; keepOpen: boolean; handle?: string }): Promise<void> => {
    cancelAutoLogoutCheck();
    setIdlePromptOpen(false);
    pauseIdleTimer();

    await Auth.signOut({ global: true });

    // Loop through ALL items in localStorage and delete those starting with 'CognitoIdentityServiceProvider.'
    // It is an attempt to remove ALL session data including bits left behind due to some session failures.
    for (let i = 0; i < localStorage.length; i += 1) {
      const itemKey = localStorage.key(i);
      if (itemKey && itemKey.startsWith('CognitoIdentityServiceProvider.')) {
        localStorage.removeItem(itemKey);
      }
    }

    setState({
      error: null,
      profile: null,
      action: null,
      loading: false,
      populated: true,
    });

    const message = params && params.message ? params.message : 'You successfully logged out';
    const duration = params && params.keepOpen ? 0 : 5;
    const key = params && params.handle ? params.handle : undefined;

    Notification({
      type: 'success',
      message,
      duration,
      key,
    });
  };

  const checkUserAccess = async (): Promise<null | IUserProfile> => {
    try {
      const userDetails: IUserProfile | null = (await getUserDetails()) as any;

      // can this user access the admin panel?
      if (
        userDetails === null ||
        (userDetails.role !== USER_ROLES.internalAdmin &&
          userDetails.role !== USER_ROLES.internalCustomerService &&
          userDetails.role !== USER_ROLES.internalShipper)
      ) {
        Notification({ type: 'error', message: 'Access denied', duration: 5 });

        await logout();

        return null;
      }

      return userDetails;
    } catch (e: any) {
      setState((s) => ({ ...s, error: e.message }));
      Notification({ type: 'error', message: e.message, duration: 5 });
    }

    return null;
  };

  const getLocalUser = async (): Promise<boolean> => {
    let user: string;

    try {
      const result = await Auth.currentAuthenticatedUser({
        bypassCache: true,
      });

      user = result.attributes.email;
      console.log('Local authenticated user:', user);
    } catch (e: any) {
      console.log(e);
      user = '';
    }

    let userProfile: IUserProfile | null = null;

    if (user !== '') {
      setState((s) => ({ ...s, loading: true }));

      userProfile = await checkUserAccess();

      if (userProfile !== null) {
        userProfile.email = userProfile.username;
      }

      autoLogoutCheck(logout);
    }

    setState((s) => ({
      ...s,
      profile: userProfile,
      populated: true,
      loading: false,
      action: null,
      error: null,
    }));

    return userProfile !== null;
  };

  const handleLoginResponse = async (user: any, email: string): Promise<void> => {
    if (user.getSignInUserSession() === null && typeof user.challengeName === 'string') {
      if (user.challengeName === AWS_CHALLENGE_NAMES.NEW_PASSWORD_REQUIRED) {
        const callback = async (newPassword: string): Promise<void> => {
          setState((prevState) => ({ ...prevState, loading: true }));

          try {
            const result = await Auth.completeNewPassword(user, newPassword, null);
            await handleLoginResponse(result, email);
          } catch (e: any) {
            Notification({ type: 'error', message: e.message });

            setState((prevState) => ({ ...prevState, loading: false, error: null }));
          }
        };

        setState((s) => ({ ...s, loading: false, action: { type: AUTH_TYPES.setPassword, callback } }));
      } else if (user.challengeName === AWS_CHALLENGE_NAMES.MFA_SETUP) {
        const callback = async (code: string): Promise<void> => {
          setState((prevState) => ({ ...prevState, loading: true }));

          try {
            await Auth.verifyTotpToken(user, code);
            await Auth.setPreferredMFA(user, 'TOTP');
            await handleLoginResponse(user, email);
          } catch (e: any) {
            Notification({ type: 'error', message: e.message });

            setState((prevState) => ({ ...prevState, loading: false, error: null }));
          }
        };

        const code = await Auth.setupTOTP(user);

        setState((s) => ({ ...s, loading: false, action: { type: AUTH_TYPES.setupMfa, callback, code, email } }));
      } else if (user.challengeName === AWS_CHALLENGE_NAMES.SOFTWARE_TOKEN_MFA) {
        const callback = async (code: string): Promise<void> => {
          setState((prevState) => ({ ...prevState, loading: true }));

          try {
            const result = await Auth.confirmSignIn(user, code, 'SOFTWARE_TOKEN_MFA');
            await handleLoginResponse(result, email);
          } catch (e: any) {
            Notification({ type: 'error', message: e.message });

            if (e.code === 'NotAuthorizedException') {
              setState((prevState) => ({ ...prevState, profile: null, action: null, loading: false, error: e }));
              return;
            }

            setState((prevState) => ({ ...prevState, loading: false, error: null }));
          }
        };

        setState((prevState) => ({ ...prevState, loading: false, action: { type: AUTH_TYPES.enterCode, callback } }));
      }
    } else {
      const autheticated = await getLocalUser();

      if (autheticated) {
        history.push(ROUTES.projects);
      } else {
        history.push(ROUTES.login);
      }

      setState((s) => ({ ...s, action: null, loading: false, error: null }));
    }
  };

  const login = async (email: string, password: string): Promise<void> => {
    setState((prevState) => ({ ...prevState, loading: true }));
    // If the auto-logout notification happens to be open, close it once the user starts
    // the login process again.
    closeNotification(autoLogoutNotificationHandle);

    try {
      const user = await Auth.signIn(email, password);

      await handleLoginResponse(user, email);
    } catch (e: any) {
      setState((prevState) => ({
        ...prevState,
        loading: false,
        error: e,
      }));

      Notification({ type: 'error', message: e.message });
    }
  };

  const forgotPassword = async (email: string): Promise<void> => {
    setState((prevState) => ({ ...prevState, loading: true }));

    try {
      await Auth.forgotPassword(email);

      Notification({ type: 'success', message: 'Check your mail for further instructions!' });

      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (e: any) {
      setState((prevState) => ({
        ...prevState,
        loading: false,
        error: e,
      }));

      Notification({ type: 'error', message: e.message });
    }
  };

  const confirmPassword = async (email: string, key: string, password: string, callback: any): Promise<void> => {
    setState((prevState) => ({ ...prevState, loading: true }));

    try {
      await Auth.forgotPasswordSubmit(email, key, password);

      Notification({ type: 'success', message: 'New password was successfully set!' });

      setState((prevState) => ({ ...prevState, loading: false }));

      if (callback) {
        await callback();
      }
    } catch (e: any) {
      setState((prevState) => ({
        ...prevState,
        loading: false,
        error: e,
      }));

      Notification({ type: 'error', message: e?.response?.data?.error?.message || e?.message });
    }
  };

  const userSelectedLogoutHandler = async () => {
    await logout({
      message: 'You have successfully logged out.',
      keepOpen: true,
      handle: autoLogoutNotificationHandle,
    });
  };

  useEffect(() => {
    getLocalUser();
  }, []);

  if (!state.populated) {
    return (
      <Row justify="center" align="middle" style={{ minHeight: '100vh' }}>
        <Space size="large" align="center">
          <Spin size="large" />
        </Space>
      </Row>
    );
  }

  return (
    <>
      <AuthContext.Provider
        value={{
          profile: state.profile,
          action: state.action,
          login,
          logout,
          forgotPassword,
          confirmPassword,
          loading: state.loading,
          error: state.error,
        }}
        {...props}
      />
      <IdleLogoutWarningModal
        visible={idlePromptOpen}
        onStayLoggedIn={() => {
          idleTimerMessageTabs(IdleStateMessages.CloseIdleWarning);
          setIdlePromptOpen(false);
          startIdleTimer();
        }}
        onLogout={async () => {
          idleTimerMessageTabs(IdleStateMessages.UserSelectedLogout);
          await userSelectedLogoutHandler();
        }}
      />
    </>
  );
};

const useAuth = (): IAuthContext => React.useContext(AuthContext);

export { AuthProvider, useAuth };
