import { httpClient, RequestConfig, handleResponseError } from 'services/index';
import { PagingRequest, PagingResponse } from 'utils/types';
import { filterCancelled } from '../utils/filterCancelled';

interface LoadDevicesParameters {
  query?: Record<string, string>;
  paging?: PagingRequest;
  configOverride?: RequestConfig;
}

interface LoadDevicesResponseData {
  paging: PagingResponse;
  results: any[];
}

type LoadDevicesFunction = (params: LoadDevicesParameters) => Promise<LoadDevicesResponseData>;

export const loadDevices: LoadDevicesFunction = (params) =>
  httpClient
    .get('/devices', {
      params: { ...filterCancelled(params.query), ...(params.paging || {}) },
      ...(params.configOverride || {}),
    })
    .then((_) => _.data)
    .catch(handleResponseError);

export const getDevices = (
  query: Record<string, any>,
  paging: PagingRequest,
  configOverride: RequestConfig = {}
): Promise<any> =>
  httpClient
    .get('/devices', { params: { ...filterCancelled(query), ...paging }, ...configOverride })
    .then((_) => _.data)
    .catch(handleResponseError);

export const getDevice = (id: string): Promise<any> => httpClient.get(`/devices/${id}`).then((_) => _.data.results);

export const addDevice = (data: any): Promise<any> => httpClient.post('/devices', data).then((_) => _.data.results);

export const updateDevice = (id: any, data: any): Promise<any> =>
  httpClient.patch(`/devices/${id}`, data).then((_) => _.data.results);

export const deleteDevice = (id: string): Promise<any> =>
  httpClient.delete(`/devices/${id}`).then((_) => _.data.results);
