import React, { useState } from 'react';

import { Form } from 'antd';

import ContactModal from 'components/ContactModal';
import { CreateContactData, useContacts } from 'context/contacts';
import { useProjectContacts } from 'context/projectContacts';
import { useProjects } from 'context/projects';
import { User } from 'context/users/types';

interface ContactValues {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  username: string;
}

const useContactModal: any = () => {
  const { project } = useProjects();
  const { createContact, updateContact, deleteContact, loading } = useContacts();
  const { createProjectContact, modifyProjectContact } = useProjectContacts();
  const [form] = Form.useForm();
  const [contactModalVisible, setContactModalVisible] = useState(false);
  const [selectedContact, setSelectedContact] = useState<User | null>(null);
  const [savedCustomerId, setSavedCustomerId] = useState('');
  const [selectedProjectContactId, setSelectedProjectContactId] = useState('');

  const openContactModal = () => {
    setContactModalVisible(true);
  };

  const closeContactModal = () => {
    setContactModalVisible(false);
    form.resetFields();
    setSelectedContact(null);
    setSelectedProjectContactId('');
  };

  const callback = () => {
    closeContactModal();
  };

  const handleCreateContact = (contact: ContactValues) => {
    const data: CreateContactData = {
      customerId: savedCustomerId,
      username: contact.username,
      lastName: contact.lastName,
      phoneNumber: contact.phoneNumber,
      role: 'customerAdmin',
    };

    if (contact.firstName) {
      data.firstName = contact.firstName;
    }

    createContact(data, async (newContact) => {
      if (project && newContact) {
        // if there's an active project, link contact to the project
        await createProjectContact({ projectId: project.id, userId: newContact.id });
      }
      callback();
    });
  };

  const handleEditContact = (contact: ContactValues) => {
    if (!selectedContact) {
      return;
    }

    const payload = {
      firstName: contact.firstName || null,
      lastName: contact.lastName,
      phoneNumber: contact.phoneNumber || null,
    };

    updateContact(selectedContact.id, payload, async (updatedContact) => {
      modifyProjectContact(selectedProjectContactId, updatedContact);
      callback();
    });
  };

  const handleDeleteContact = (id: any) => {
    deleteContact(id, async () => {
      callback();
    });
  };

  /* ----------------------- FOR EXPORT ------------------------- */

  const onCreateContact = (customerId: string) => {
    if (customerId) {
      setSavedCustomerId(customerId);
    }

    setSelectedContact(null);
    setSelectedProjectContactId('');
    form.setFieldsValue({});
    openContactModal();
  };

  const onEditContact = (projectContactId: string, contact: User) => {
    setSelectedContact(contact);
    setSelectedProjectContactId(projectContactId);
    form.setFieldsValue(contact);
    openContactModal();
  };

  const onDeleteContact = (id: any) => {
    handleDeleteContact(id);
  };

  const Modal = (
    <ContactModal
      form={form}
      contact={selectedContact}
      visible={contactModalVisible}
      onOk={selectedContact ? handleEditContact : handleCreateContact}
      onCancel={closeContactModal}
      loading={loading}
    />
  );

  return { Modal, onCreateContact, onEditContact, onDeleteContact };
};

export default useContactModal;
