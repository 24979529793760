/* eslint-disable react/jsx-no-target-blank */
import React, { FC, useState, useEffect } from 'react';

import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';

import { Customer } from 'context/customers';
import { IPatient } from 'context/patients';
import { IProject } from 'context/projects';
import { IDevice } from 'context/devices';
import { DEVICE_STATUS, DISTRIBUTION_MODEL_SOURCES, PATIENT_EXPERIENCE_STATUS } from 'constants/index';

interface CustomerRecord {
  customerId: string;
  customerName: string;
}

interface ProjectRecord {
  projectId: string;
  projectName: string;
  projectDistributionModel: string;
}

interface PatientRecord {
  patientId: string;
  firstName: string;
  lastName: string;
  subjectId: string;
}

interface TableRecord {
  deviceId: string;
  firstName: string;
  lastName: string;
  subjectId: string;
  projectId: string;
  patientId: string;
  projectName: string;
  projectDistributionModel: string;
  customerId: string;
  customerName: string;
  barcode: string;
  status: string;
  activationCode: string;
  patientExperienceStatus: string;
}

interface Props {
  patients: IPatient[];
  devices: IDevice[];
  projects: IProject[];
  customers: Customer[];
  loading: boolean;
}

const DeviceList: FC<Props> = ({ patients, devices, projects, customers, loading }) => {
  const [projectHash, setProjectHash] = useState<Record<string, number>>({});
  const [customerHash, setCustomerHash] = useState<Record<string, number>>({});
  const [patientHash, setPatientHash] = useState<Record<string, number>>({});

  useEffect(() => {
    const hash: Record<string, number> = {};
    for (let i = 0; i < projects.length; i += 1) {
      hash[projects[i].id] = i;
    }
    setProjectHash(hash);
  }, [projects]);

  useEffect(() => {
    const hash: Record<string, number> = {};
    for (let i = 0; i < customers.length; i += 1) {
      hash[customers[i].id] = i;
    }
    setCustomerHash(hash);
  }, [customers]);

  useEffect(() => {
    const hash: Record<string, number> = {};
    for (let i = 0; i < patients.length; i += 1) {
      hash[patients[i].id] = i;
    }
    setPatientHash(hash);
  }, [patients]);

  const getProject = (projectId: string): ProjectRecord => {
    const project = projectId && projectId in projectHash ? projects[projectHash[projectId]] || null : null;
    return {
      projectId: project?.id || '',
      projectName: project?.name || '',
      projectDistributionModel: project?.distributionModel || '',
    };
  };

  const getCustomer = (customerId: string): CustomerRecord => {
    const customer = customerId && customerId in customerHash ? customers[customerHash[customerId]] || null : null;
    return { customerId: customer?.id || '', customerName: customer?.name || '' };
  };

  const getPatient = (patientId: string): PatientRecord => {
    const patient = patientId && patientId in patientHash ? patients[patientHash[patientId]] || null : null;
    return {
      patientId: patient?.id || '',
      firstName: patient?.firstName || '',
      lastName: patient?.lastName || '',
      subjectId: patient?.subjectId || '',
    };
  };

  const columns: ColumnsType<TableRecord> = [
    {
      key: 'barcode',
      title: 'Barcode',
      className: 'no_break',
      render: (_, record) => {
        if (record.projectId) {
          return (
            <a
              href={`/projects/${record.projectId}/devices?deviceId=${record.deviceId}`}
              target="_blank"
              rel="noopener"
            >
              {record.barcode}
            </a>
          );
        }

        if (record.projectId && record.projectDistributionModel === DISTRIBUTION_MODEL_SOURCES.atCustomerSite) {
          return (
            <a
              href={`/projects/${record.projectId}/unassigned-devices?deviceId=${record.deviceId}`}
              target="_blank"
              rel="noopener"
            >
              {record.barcode}
            </a>
          );
        }

        return record.barcode;
      },
    },
    {
      key: 'status',
      title: 'Status',
      dataIndex: 'status',
      className: 'no_break',
    },
    {
      key: 'firstName',
      title: 'First name',
      render: (_, record) => {
        if (record.firstName && record.projectId && record.patientId) {
          return (
            <a
              href={`/projects/${record.projectId}/shipments?patientId=${record.patientId}`}
              target="_blank"
              rel="noopener"
            >
              {record.firstName}
            </a>
          );
        }
        return record.firstName;
      },
    },
    {
      key: 'lastName',
      dataIndex: 'lastName',
      title: 'Last name',
      render: (_, record) => {
        if (record.lastName && record.projectId && record.patientId) {
          return (
            <a
              href={`/projects/${record.projectId}/shipments?patientId=${record.patientId}`}
              target="_blank"
              rel="noopener"
            >
              {record.lastName}
            </a>
          );
        }
        return record.lastName;
      },
    },
    {
      key: 'subjectId',
      dataIndex: 'subjectId',
      title: 'Subject id',
    },
    {
      title: 'Activation code',
      key: 'activationCode',
      dataIndex: 'activationCode',
      render: (_, record) => {
        if (record.projectId) {
          return (
            <a
              href={`/projects/${record.projectId}/devices?deviceId=${record.deviceId}`}
              target="_blank"
              rel="noopener"
            >
              {record.activationCode}
            </a>
          );
        }

        return record.activationCode;
      },
    },
    {
      key: 'patientExperienceStatus',
      dataIndex: 'patientExperienceStatus',
      title: 'Activation status',
      className: 'no_break',
    },
    {
      key: 'project',
      title: 'Project',
      render: (_, record) => {
        if (record.projectId && record.projectName) {
          return (
            <a href={`/projects/${record.projectId}/shipments`} target="_blank" rel="noopener">
              {record.projectName}
            </a>
          );
        }
        return record.projectId || '';
      },
    },
    {
      key: 'customer',
      title: 'Customer',
      render: (_, record) => {
        if (record.customerId && record.customerName) {
          return record.customerName;
        }
        return record.customerId || '';
      },
    },
  ];

  return (
    <div>
      <Table
        className="search_results_table"
        loading={loading}
        columns={loading ? [] : columns}
        dataSource={devices.map((d) => ({
          deviceId: d.id,
          barcode: d.barcode,
          activationCode: d.activationCode,
          patientExperienceStatus:
            PATIENT_EXPERIENCE_STATUS[d.patientExperienceStatus as keyof typeof PATIENT_EXPERIENCE_STATUS] ||
            d.patientExperienceStatus ||
            '',
          status: DEVICE_STATUS[d.status as keyof typeof DEVICE_STATUS] || d.status || '',
          ...getProject(d.projectId),
          ...getCustomer(d.customerId),
          ...getPatient(d.patientId),
        }))}
        rowKey="deviceId"
        size="small"
        pagination={false}
        bordered
        showSorterTooltip={false}
      />
    </div>
  );
};

export default React.memo(DeviceList);
