import React, { createContext, useReducer } from 'react';

import * as deviceEventsApi from 'api/deviceEvents';
import { loadAllPages, makeReducer } from 'utils/functions';
import { serializeForDisplay } from 'utils/issues';
import Notification from 'utils/notification';
import { DeviceEvent } from 'utils/types';

type State = {
  deviceEvents: DeviceEvent[];
  loading: boolean;
  error: string;
};

type Context = State & {
  getDeviceEvents: (deviceId: string) => Promise<void>;
  resetDeviceEvents: () => void;
};

const initialState: State = {
  deviceEvents: [],
  loading: false,
  error: '',
};

const DeviceEventsContext = createContext<Context>({
  ...initialState,
  getDeviceEvents: async () => {},
  resetDeviceEvents: () => {},
});

const DeviceEventsProvider = (props: any) => {
  const [state, setState] = useReducer(makeReducer<State>(), initialState);

  const getDeviceEvents = async (deviceId: string, callback?: any): Promise<void> => {
    try {
      setState({ loading: true });

      type PayloadType = Parameters<typeof deviceEventsApi.getDeviceEvents>[0];
      const deviceEvents = await loadAllPages<DeviceEvent, PayloadType>(
        deviceEventsApi.getDeviceEvents,
        { deviceIds: deviceId },
        { pageLength: 50, sortBy: 'createdAt', isDescending: true }
      );

      setState({
        deviceEvents,
        loading: false,
      });

      if (callback) {
        await callback();
      }
    } catch (e: any) {
      const errorMessage = serializeForDisplay(e?.response?.data?.issues) || e.message;
      setState({
        loading: false,
        error: errorMessage,
      });

      Notification({
        type: 'error',
        message: 'Failed to get device events',
        description: errorMessage,
      });
    }
  };

  const resetDeviceEvents = (): void => {
    setState({ ...initialState });
  };

  return (
    <DeviceEventsContext.Provider
      value={{
        deviceEvents: state.deviceEvents,
        loading: state.loading,
        error: state.error,
        getDeviceEvents,
        resetDeviceEvents,
      }}
      {...props}
    />
  );
};

const useDeviceEvents = (): Context => React.useContext(DeviceEventsContext);

export { DeviceEventsProvider, useDeviceEvents };
