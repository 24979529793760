/* eslint-disable react/jsx-no-target-blank */
import React, { FC, useState, useEffect } from 'react';

import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';

import { Customer } from 'context/customers';
import { IPatient } from 'context/patients';
import { IProject } from 'context/projects';

interface TableRecord {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  subjectId: string;
  projectId: string | null;
  projectName: string | null;
  customerId: string | null;
  customerName: string | null;
}

interface Props {
  patients: IPatient[];
  projects: IProject[];
  customers: Customer[];
  loading: boolean;
}

const PatientList: FC<Props> = ({ patients, projects, customers, loading }) => {
  const [projectHash, setProjectHash] = useState<Record<string, number>>({});
  const [customerHash, setCustomerHash] = useState<Record<string, number>>({});

  useEffect(() => {
    const hash: Record<string, number> = {};
    for (let i = 0; i < projects.length; i += 1) {
      hash[projects[i].id] = i;
    }
    setProjectHash(hash);
  }, [projects]);

  useEffect(() => {
    const hash: Record<string, number> = {};
    for (let i = 0; i < customers.length; i += 1) {
      hash[customers[i].id] = i;
    }
    setCustomerHash(hash);
  }, [customers]);

  const getProject = (projectId: string): { projectId: string | null; projectName: string | null } => {
    const project = projectId && projectId in projectHash ? projects[projectHash[projectId]] || null : null;
    return { projectId: project?.id || null, projectName: project?.name || null };
  };

  const getCustomer = (customerId: string): { customerId: string | null; customerName: string | null } => {
    const customer = customerId && customerId in customerHash ? customers[customerHash[customerId]] || null : null;
    return { customerId: customer?.id || null, customerName: customer?.name || null };
  };

  const columns: ColumnsType<TableRecord> = [
    {
      key: 'firstName',
      title: 'First name',
      render: (_, record) => {
        if (record.firstName && record.projectId) {
          return (
            <a href={`/projects/${record.projectId}/shipments?patientId=${record.id}`} target="_blank" rel="noopener">
              {record.firstName}
            </a>
          );
        }
        return record.firstName || '';
      },
    },
    {
      key: 'lastName',
      dataIndex: 'lastName',
      title: 'Last name',
      render: (_, record) => {
        if (record.lastName && record.projectId) {
          return (
            <a href={`/projects/${record.projectId}/shipments?patientId=${record.id}`} target="_blank" rel="noopener">
              {record.lastName}
            </a>
          );
        }
        return record.lastName || '';
      },
    },
    {
      key: 'email',
      dataIndex: 'email',
      title: 'Email',
    },
    {
      key: 'subjectId',
      dataIndex: 'subjectId',
      title: 'Subject id',
    },
    {
      key: 'devices',
      title: 'Devices',
      render: (_, record) => {
        if (record.projectId) {
          return (
            <a href={`/projects/${record.projectId}/devices?patientId=${record.id}`} target="_blank" rel="noopener">
              View devices
            </a>
          );
        }
        return '';
      },
    },
    {
      key: 'project',
      title: 'Project',
      render: (_, record) => {
        if (record.projectId && record.projectName) {
          return (
            <a href={`/projects/${record.projectId}/shipments`} target="_blank" rel="noopener">
              {record.projectName}
            </a>
          );
        }
        return '';
      },
    },
    {
      key: 'customer',
      title: 'Customer',
      render: (_, record) => {
        if (record.customerId && record.customerName) {
          return record.customerName;
        }
        return '';
      },
    },
  ];

  return (
    <div>
      <Table
        loading={loading}
        columns={loading ? [] : columns}
        dataSource={patients.map((p) => ({
          id: p.id,
          firstName: p.firstName,
          lastName: p.lastName,
          email: p.email,
          subjectId: p.subjectId,
          ...getProject(p.projectId),
          ...getCustomer(p.customerId),
        }))}
        rowKey="id"
        size="small"
        pagination={false}
        bordered
        showSorterTooltip={false}
      />
    </div>
  );
};

export default React.memo(PatientList);
