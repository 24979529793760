import { AxiosRequestConfig } from 'axios';

import { httpClient } from 'services/index';
import { PagingRequest } from 'utils/types';

interface IShippingLabelData {
  deviceId: string;
  toPatientShipDate: string | undefined;
  toPatientShipOrigin: string | undefined;
}
export const downloadShippingLabelDisposition = 'attachment;filename=shipping_labels.pdf';

export const createShippingLabel = (data: IShippingLabelData, options?: AxiosRequestConfig): Promise<any> =>
  httpClient.post('/shippingLabels', { ...data }, options).then((_) => _.data.results);

export const getShippingLabels = (payload: Record<string, any>, paging: PagingRequest): Promise<any> =>
  httpClient.get('/shippingLabels', { params: { ...payload, ...paging } }).then((_) => _.data);

export const cancelShippingLabel = (labelId: string, options?: AxiosRequestConfig): Promise<any> =>
  httpClient.delete(`/shippingLabels/${labelId}`, options).then((_) => _.data.results);
