import { AxiosRequestConfig, CancelToken } from 'axios';
import { handleResponseError, httpClient } from 'services/index';
import { PagingRequest } from 'utils/types';

export type IdPoolIdentifiersRequestOptions = {
  hydrate?: string[];
  cancelToken?: CancelToken;
};

const prepareRequest = (
  existingConfig: AxiosRequestConfig,
  options?: IdPoolIdentifiersRequestOptions
): AxiosRequestConfig => {
  const req: AxiosRequestConfig = { ...existingConfig };

  if (options) {
    if (options.hydrate && options.hydrate.length > 0) {
      req.headers = { ...(req.headers || {}), 'tasso-hydration': options.hydrate.join(',') };
    }
    if (options.cancelToken) {
      req.cancelToken = options.cancelToken;
    }
  }

  return req;
};

export type GetIdPoolIdentifiersPayload = {
  query: Record<string, any>;
  options?: IdPoolIdentifiersRequestOptions;
};
export const getIdPoolIdentifiers = (payload: GetIdPoolIdentifiersPayload, paging: PagingRequest): Promise<any> => {
  const requestOptions = prepareRequest({ params: { ...payload.query, ...paging } }, payload.options);

  return httpClient
    .get('/idPoolIdentifiers', requestOptions)
    .then((_) => _.data)
    .catch(handleResponseError);
};

export const getIdPoolIdentifier = (id: string, options?: IdPoolIdentifiersRequestOptions): Promise<any> => {
  const requestOptions = prepareRequest({}, options);

  return httpClient
    .get(`/idPoolIdentifiers/${id}`, requestOptions)
    .then((_) => _.data.results)
    .catch(handleResponseError);
};

export type CreateIdPoolIdentifierPayload = {
  value: string;
  idPoolId: string;
  customOrder?: number;
};
export const createIdPoolIdentifier = (
  data: CreateIdPoolIdentifierPayload,
  options?: IdPoolIdentifiersRequestOptions
): Promise<any> => {
  const requestOptions = prepareRequest({}, options);

  return httpClient
    .post('/idPoolIdentifiers', data, requestOptions)
    .then((_) => _.data.results)
    .catch(handleResponseError);
};

export type UpdateIdPoolIdentifierPayload = {
  value?: string;
  idPoolId?: string;
  customOrder?: number;
};

export const updateIdPoolIdentifier = (
  id: string,
  data: UpdateIdPoolIdentifierPayload,
  options?: IdPoolIdentifiersRequestOptions
): Promise<any> => {
  const requestOptions = prepareRequest({}, options);

  return httpClient
    .patch(`/idPoolIdentifiers/${id}`, data, requestOptions)
    .then((_) => _.data.results)
    .catch(handleResponseError);
};

export const deleteIdPoolIdentifier = (id: string, options?: IdPoolIdentifiersRequestOptions): Promise<any> => {
  const requestOptions = prepareRequest({}, options);

  return httpClient
    .delete(`/idPoolIdentifiers/${id}`, requestOptions)
    .then((_) => _.data.results)
    .catch(handleResponseError);
};
