import { OrderTemplate } from '@tassoinc/core-api-client';
import { Button, Tag, Tooltip } from 'antd';
import { InfoCircleTwoTone } from '@ant-design/icons';
import React, { useState, useEffect } from 'react';

import SortableTable from 'components/SortableTable';

import { ColumnsType } from 'antd/lib/table';
import { OrderTemplatesViewOrder } from 'context/projects';
import { sortOrderTemplates } from 'utils/orderTemplates';
import Paragraph from 'antd/lib/typography/Paragraph';

interface DataType {
  id: string;
  name: string;
}

interface Props {
  orderTemplatesViewOrder: OrderTemplatesViewOrder;
  orderTemplates: OrderTemplate[];
  onEditClick: (orderTemplate: OrderTemplate) => void;
  onOrderChange: (templateIds: OrderTemplatesViewOrder) => Promise<boolean>;
}

// Return whether template is customer orderable
// If isCustomerOrderable is undefined, it is legacy and is orderable
const isCustomerOrderable = (template: OrderTemplate): boolean =>
  template.isCustomerOrderable || template.isCustomerOrderable === undefined;

const OrderTemplateList = ({ orderTemplates, orderTemplatesViewOrder, onEditClick, onOrderChange }: Props) => {
  const [dataSource, setDataSource] = useState(sortOrderTemplates(orderTemplates, orderTemplatesViewOrder));

  const isDefault = (templateId: string) =>
    Boolean(orderTemplatesViewOrder.find((item) => item.templateId === templateId)?.default);

  const handleEditClick = (orderTemplate: OrderTemplate): void => {
    onEditClick(orderTemplate);
  };

  const handleSetDefaultClick = (orderTemplate: OrderTemplate): void => {
    const data = dataSource.map((t) => {
      if (t.templateId === orderTemplate.templateId) {
        return { templateId: t.templateId, default: true };
      }
      return { templateId: t.templateId };
    });

    onOrderChange(data);
  };

  const columns: ColumnsType<DataType> = [
    {
      key: 'sort',
      width: '46px', // cell_padding (16px) * 2 + icon_width (14px)
    },
    {
      title: 'Name',
      render: (item) => (
        <>
          {item.name}{' '}
          {isDefault(item.templateId) ? (
            <Tooltip title="When order templates are presented as a dropdown the default option is used as the initial value.">
              <Tag color="#87d068">default</Tag>
            </Tooltip>
          ) : null}
        </>
      ),
    },
    {
      title: 'Actions',
      width: '1%',
      render: (item) => (
        <div style={{ whiteSpace: 'nowrap' }}>
          <Button type="link" onClick={() => handleEditClick(item)}>
            Edit
          </Button>

          {!isDefault(item.templateId) && isCustomerOrderable(item) && (
            <Button type="link" onClick={() => handleSetDefaultClick(item)}>
              Set default
            </Button>
          )}
        </div>
      ),
    },
  ];

  useEffect(() => {
    const currentView = orderTemplatesViewOrder.map((item) => item.templateId).join(',');
    const newView = dataSource.map((t) => t.templateId).join(',');

    if (currentView !== newView) {
      const data = dataSource.map((t) => {
        if (isDefault(t.templateId)) {
          return { templateId: t.templateId, default: true };
        }
        return {
          templateId: t.templateId,
        };
      });

      onOrderChange(data);
    }
  }, [dataSource]);

  useEffect(() => {
    setDataSource(sortOrderTemplates(orderTemplates, orderTemplatesViewOrder));
  }, [orderTemplates]);

  return (
    <div>
      <Paragraph>
        <InfoCircleTwoTone /> Drag and drop rows to change the display order of all order templates in selectors here
        and in the Customer Portal.
      </Paragraph>

      <SortableTable
        onSort={(items) => {
          setDataSource(items);
        }}
        data={dataSource}
        columns={columns}
        datatestId="tbl-order-templates"
      />
    </div>
  );
};

export default OrderTemplateList;
