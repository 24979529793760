import { httpClient } from 'services/index';
import { PagingRequest } from 'utils/types';

export const getDeviceLots = (payload: Record<string, any>, paging: PagingRequest): Promise<any> =>
  httpClient.get('/deviceLots', { params: { ...payload, ...paging } }).then((_) => _.data);

export const getDeviceLot = (id: string): Promise<any> =>
  httpClient.get(`/deviceLots/${id}`).then((_) => _.data.results);

export const addDeviceLot = (key: string): Promise<any> =>
  httpClient.post('/deviceLots', { key }).then((_) => _.data.results);

export const updateDeviceLot = (id: string, key: string): Promise<any> =>
  httpClient.patch(`/deviceLots/${id}`, { key }).then((_) => _.data.results);
