import React, { FC } from 'react';

import { Menu } from 'antd';
import { useLocation, useHistory, useParams } from 'react-router-dom';

import { MailOutlined, MobileOutlined, ProjectOutlined } from 'components/icons';
import { DISTRIBUTION_MODELS, DISTRIBUTION_MODEL_SOURCES, USER_ROLES } from 'constants/index';
import { ROUTES } from 'constants/routes';
import { useAuth } from 'context/auth';

const NavigationTab = {
  Shipments: 'shipments',
  Devices: 'devices',
  UnassignedDevices: 'unassigned-devices',
  Details: 'details',
  CustomerSupport: 'customer-support',
} as const;

interface Props {
  distributionModel?: keyof typeof DISTRIBUTION_MODELS;
}

const ProjectNavigation: FC<Props> = ({ distributionModel }) => {
  const location = useLocation();
  const history = useHistory();
  const { projectId }: any = useParams();
  const { profile } = useAuth();

  const isInternalAdmin = (profile?.role || '') === USER_ROLES.internalAdmin;
  const isInternalShipper = (profile?.role || '') === USER_ROLES.internalShipper;
  const isCustomerService = (profile?.role || '') === USER_ROLES.internalCustomerService;

  const handleNavigateToSection = ({ key }: { key: string }) => {
    history.push(`${ROUTES.projects}/${projectId}/${key}`);
  };

  return (
    <div className="ProjectNavigation">
      <Menu
        onClick={handleNavigateToSection}
        selectedKeys={[location.pathname.split('/').pop() || 'shipments']}
        mode="horizontal"
      >
        <Menu.Item key={NavigationTab.Shipments} icon={<MailOutlined />}>
          Shipments
        </Menu.Item>

        {!isInternalShipper && (
          <Menu.Item key={NavigationTab.Devices} icon={<MobileOutlined />}>
            Devices
          </Menu.Item>
        )}

        {distributionModel === DISTRIBUTION_MODEL_SOURCES.atCustomerSite && (
          <Menu.Item key={NavigationTab.UnassignedDevices} icon={<MobileOutlined />}>
            Unassigned devices
          </Menu.Item>
        )}

        {(isInternalAdmin || isCustomerService) && (
          <Menu.Item key={NavigationTab.Details} icon={<ProjectOutlined />}>
            Details
          </Menu.Item>
        )}

        {(isInternalAdmin || isCustomerService) && (
          <Menu.Item key={NavigationTab.CustomerSupport} icon={<ProjectOutlined />}>
            Support
          </Menu.Item>
        )}
      </Menu>
    </div>
  );
};

export default ProjectNavigation;
