import React, { FC } from 'react';

import { Select, Typography, Space } from 'antd';

const { Option } = Select;

const { Text } = Typography;

interface IPatientExperienceField {
  onChange: (deviceId: any) => void;
  value: boolean;
}

const PatientExperienceField: FC<IPatientExperienceField> = ({ onChange, value }) => {
  const handleSelect = (deviceType: any) => {
    onChange(deviceType);
  };

  return (
    <div className="PatientExperienceField">
      <Space direction="vertical">
        <Text strong>Patient Experience</Text>
        <Select onSelect={handleSelect} value={`${value}`} data-testId="project-patient-experience">
          <Option value="false">Disabled</Option>
          <Option value="true">Enabled</Option>
        </Select>
      </Space>
    </div>
  );
};

export default PatientExperienceField;
