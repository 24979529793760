import React, { FC, useState, createContext } from 'react';

import { customAttributeDefinitionsApi } from 'api';
import { loadAllPages } from 'utils/functions';
import * as issues from 'utils/issues';
import Notification from 'utils/notification';

export interface ICustomAttributeDefinition {
  id: string;
  createdAt: string;
  updatedAt: string;
  customerId: string;
  name: string;
  required: boolean | undefined;
  maxLength: number | undefined;
  allowedValues: string[] | undefined;
  deprecated: boolean | undefined;
}

type ApiCustomAttributeDefinition = {
  id: string;
  createdAt: string;
  updatedAt: string;
  customerId: string;
  name: string;
  required: boolean | null;
  maxLength: number | null;
  allowedValues: string[] | null;
  deprecated: boolean | null;
};

interface State {
  customAttributeDefinitions: ICustomAttributeDefinition[];
  customAttributeDefinition: ICustomAttributeDefinition | null;
  error: string | null;
  loading: boolean;
}

type GetCustomAttributeDefinitionsCallback = (
  customAttributeDefinitions: ICustomAttributeDefinition[] | null
) => Promise<void>;
type GetCustomAttributeDefinitionsFunction = (
  customerId: string,
  callback?: GetCustomAttributeDefinitionsCallback
) => Promise<void>;

interface Context extends State {
  getCustomAttributeDefinitions: GetCustomAttributeDefinitionsFunction;
  resetCustomAttributeDefinitions: () => void;
  getCustomAttributeDefinition: (
    id: string,
    callback?: (customAttributeDefinitions: ICustomAttributeDefinition) => Promise<void>
  ) => Promise<void>;
  createCustomAttributeDefinition: (
    data: Record<string, any>,
    callback?: (customAttributeDefinitions: ICustomAttributeDefinition) => Promise<void>
  ) => Promise<void>;
  updateCustomAttributeDefinition: (
    id: string,
    data: Record<string, any>,
    callback?: (customAttributeDefinitions: ICustomAttributeDefinition) => Promise<void>
  ) => Promise<void>;
}

const formatCustomAttributeDefinition = (item: ApiCustomAttributeDefinition): ICustomAttributeDefinition => ({
  id: item.id,
  createdAt: item.createdAt,
  updatedAt: item.updatedAt || '',
  customerId: item.customerId,
  name: item.name,
  required: item.required || false,
  maxLength: item.maxLength || undefined,
  allowedValues: item.allowedValues || [],
  deprecated: item.deprecated || undefined,
});

const getInitialState = (): State => ({
  customAttributeDefinitions: [],
  customAttributeDefinition: null,
  error: null,
  loading: false,
});

const CustomAttributeDefinitionsContext = createContext<Context>({
  ...getInitialState(),
  getCustomAttributeDefinitions: async () => {},
  resetCustomAttributeDefinitions: () => {},
  getCustomAttributeDefinition: async () => {},
  createCustomAttributeDefinition: async () => {},
  updateCustomAttributeDefinition: async () => {},
});

const CustomAttributeDefinitionsProvider: FC = (props) => {
  const [state, setState] = useState<State>(getInitialState);

  const getCustomAttributeDefinitions: GetCustomAttributeDefinitionsFunction = async (
    customerId,
    callback?
  ): Promise<void> => {
    try {
      setState((s) => ({
        ...s,
        error: null,
        loading: true,
      }));

      const requestParams = { customerIds: customerId };

      const customAttributeDefinitions: ApiCustomAttributeDefinition[] = [];

      type PayloadType = Parameters<typeof customAttributeDefinitionsApi.getCustomAttributeDefinitions>[0];
      const results = await loadAllPages<ApiCustomAttributeDefinition, PayloadType>(
        customAttributeDefinitionsApi.getCustomAttributeDefinitions,
        requestParams,
        {}
      );

      customAttributeDefinitions.push(...results);

      const formattedCustomAttributeDefinitions = customAttributeDefinitions.map(formatCustomAttributeDefinition);

      setState((s) => ({
        ...s,
        customAttributeDefinitions: customAttributeDefinitions.map(formatCustomAttributeDefinition),
      }));

      if (callback) {
        await callback(formattedCustomAttributeDefinitions);
      }
    } catch (e) {
      const errorMessage = issues.getErrorMessage(e);

      setState((s) => ({
        ...s,
        error: errorMessage,
      }));

      Notification({
        type: 'error',
        message: 'Cannot get custom attribute definitions',
        description: errorMessage,
      });

      if (callback) {
        await callback(null);
      }
    } finally {
      setState((s) => ({
        ...s,
        loading: false,
      }));
    }
  };

  const resetCustomAttributeDefinitions = (): void => {
    setState(getInitialState());
  };

  const getCustomAttributeDefinition = async (
    id: string,
    callback?: (customAttributeDefinition: ICustomAttributeDefinition) => Promise<void>
  ): Promise<void> => {
    try {
      const customAttributeDefinition: ApiCustomAttributeDefinition =
        await customAttributeDefinitionsApi.getCustomAttributeDefinition(id);
      const formattedCustomAttributeDefinition = formatCustomAttributeDefinition(customAttributeDefinition);

      setState((s) => ({
        ...s,
        customAttributeDefinition: formattedCustomAttributeDefinition,
      }));

      if (callback) {
        await callback(formattedCustomAttributeDefinition);
      }
    } catch (e) {
      const errorMessage = issues.getErrorMessage(e);

      setState((s) => ({
        ...s,
        loading: false,
        error: errorMessage,
      }));

      Notification({
        type: 'error',
        message: 'Cannot get custom attribute definition details',
        description: errorMessage,
      });
    }
  };

  const createCustomAttributeDefinition = async (
    data: Record<string, any>,
    callback?: (newAttribute: ICustomAttributeDefinition) => Promise<void>
  ): Promise<void> => {
    try {
      setState((s) => ({
        ...s,
        loading: true,
        error: null,
      }));

      const newAttribute: ApiCustomAttributeDefinition =
        await customAttributeDefinitionsApi.createCustomAttributeDefinition(data);
      const formattedCustomAttributeDefinition = formatCustomAttributeDefinition(newAttribute);

      setState((s) => ({
        ...s,
        loading: false,
        customAttributeDefinitions: [...s.customAttributeDefinitions, formattedCustomAttributeDefinition],
      }));

      Notification({ type: 'success', message: 'Custom attribute definition successfully created' });

      if (callback) {
        await callback(formattedCustomAttributeDefinition);
      }
    } catch (e) {
      const errorMessage = issues.getErrorMessage(e);

      setState((s) => ({
        ...s,
        loading: false,
        error: errorMessage,
      }));

      Notification({
        type: 'error',
        message: 'Custom attribute definition was not created',
        description: errorMessage,
      });
    }
  };

  const updateCustomAttributeDefinition = async (
    id: string,
    data: Record<string, any>,
    callback?: (customAttributeDefinition: ICustomAttributeDefinition) => Promise<void>
  ): Promise<void> => {
    try {
      setState((s) => ({
        ...s,
        loading: true,
        error: null,
      }));

      const updatedCustomAttributeDefinition: ApiCustomAttributeDefinition =
        await customAttributeDefinitionsApi.updateCustomAttributeDefinition(id, data);
      const formattedCustomAttributeDefinition = formatCustomAttributeDefinition(updatedCustomAttributeDefinition);

      setState((s) => ({
        ...s,
        customAttributeDefinitions: s.customAttributeDefinitions.map((p) =>
          p.id === id ? formattedCustomAttributeDefinition : p
        ),
        customAttributeDefinition:
          s.customAttributeDefinition && s.customAttributeDefinition.id === id
            ? formattedCustomAttributeDefinition
            : s.customAttributeDefinition,
        loading: false,
      }));

      Notification({ type: 'success', message: 'Custom attribute definition successfully updated' });

      if (callback) {
        await callback(formattedCustomAttributeDefinition);
      }
    } catch (e) {
      const errorMessage = issues.getErrorMessage(e);

      setState((s) => ({
        ...s,
        loading: false,
        error: errorMessage,
      }));

      Notification({
        type: 'error',
        message: 'Custom attribute definition was not updated',
        description: errorMessage,
      });
    }
  };

  return (
    <CustomAttributeDefinitionsContext.Provider
      value={{
        customAttributeDefinitions: state.customAttributeDefinitions,
        customAttributeDefinition: state.customAttributeDefinition,
        loading: state.loading,
        error: state.error,
        getCustomAttributeDefinitions,
        resetCustomAttributeDefinitions,
        getCustomAttributeDefinition,
        createCustomAttributeDefinition,
        updateCustomAttributeDefinition,
      }}
      {...props}
    />
  );
};

const useCustomAttributeDefinitions = (): Context => React.useContext(CustomAttributeDefinitionsContext);

export { CustomAttributeDefinitionsProvider, useCustomAttributeDefinitions };
