import { httpClient } from 'services/index';
import { PagingRequest } from 'utils/types';

export const getProjectLaboratories = (projectId: string, paging: PagingRequest): Promise<any> =>
  httpClient
    .get('/projectLaboratoryLinks', {
      params: { projectIds: projectId, ...paging },
      headers: { 'tasso-hydration': 'laboratory' },
    })
    .then((_) => _.data);

export const getProjectLaboratory = (id: string): Promise<any> =>
  httpClient
    .get(`/projectLaboratoryLinks/${id}`, { headers: { 'tasso-hydration': 'laboratory' } })
    .then((_) => _.data.results);

export interface ICreateProjectLaboratoryData {
  projectId: string;
  laboratoryId: string;
}
export const createProjectLaboratory = (data: ICreateProjectLaboratoryData): Promise<any> =>
  httpClient
    .post('/projectLaboratoryLinks', data, { headers: { 'tasso-hydration': 'laboratory' } })
    .then((_) => _.data.results);

export interface IUpdateProjectLaboratoryData {
  viewOrder: number;
}
export const updateProjectLaboratory = (id: string, data: IUpdateProjectLaboratoryData): Promise<any> =>
  httpClient
    .patch(`/projectLaboratoryLinks/${id}`, data, { headers: { 'tasso-hydration': 'laboratory' } })
    .then((_) => _.data.results);

export const deleteProjectLaboratory = (id: string): Promise<any> =>
  httpClient.delete(`/projectLaboratoryLinks/${id}`).then((_) => _.data.results);
