/* eslint-disable no-await-in-loop */
import { RcFile } from 'antd/lib/upload';
import { httpClient } from 'services/index';
import { wait } from './functions';

/**
 * Accepts S3 generated POST data (stringified JSON) object,
 * parses it and uses the obtained information to build a POST request
 * that will be sent directly to S3. The file that's being uploaded is sent
 * as part of this request.
 *
 * Returns `null` if everything was ok, string if there was an error.
 * The string will contain the error message.
 *
 * @param uploadInfo - encoded JSON string
 * @param file - file that's being uploaded
 */
export const uploadFileToS3 = async (uploadInfo: string, file: RcFile): Promise<string | null> => {
  const json = JSON.parse(uploadInfo);

  const formData = new FormData();
  formData.append('Content-Type', file.type);
  Object.entries(json.fields).forEach(([k, v]) => {
    formData.append(k, v as string);
  });
  formData.append('file', file);

  try {
    await httpClient.post(json.url, formData, { ignoreAuthorization: true } as any);
  } catch (e: any) {
    console.error('Upload error!', e);

    if (e.response) {
      if (e.response.data) {
        const { data } = e.response;
        // Try to extract an error message from the S3 response.
        const match = data.match(/<Message>([^<]+)/);
        if (match && match.length === 2) {
          return match[1];
        }
        return '';
      }

      return e.response.statusText ?? e.message;
    }

    return 'Error';
  }

  return null;
};

/**
 * Pings Core API until the expected response field has a value in it.
 * Times out after 30 seconds. Normally only a couple of seconds are needed.
 *
 * @param isFileReady - callback that fetches data and check file key field
 * @param fieldName - field to check on the received response
 */
export const waitForUploadedFile = async (checkFile: () => Promise<boolean>): Promise<void> => {
  let isFileReady: boolean;

  let maxAttempts = 30;

  do {
    isFileReady = await checkFile();

    maxAttempts -= 1;

    if (maxAttempts === 0) {
      console.error('Timeout on trying to get uploaded file information.');
      break;
    }

    await wait(1000);
  } while (!isFileReady);
};
