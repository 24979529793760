/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useState } from 'react';

import { Form } from 'antd';
import { RcFile } from 'antd/lib/upload/interface';

import LaboratoryModal from 'components/LaboratoryModal';
import { Laboratory, useLaboratories } from 'context/laboratories';

const useLaboratoryModal = () => {
  const { createLaboratory, updateLaboratory, deleteLaboratory, getLaboratory, loading } = useLaboratories();
  const [form] = Form.useForm();
  const [laboratoryModalVisible, setLaboratoryModalVisible] = useState(false);
  const [laboratoryModalPopulated, setLaboratoryModalPopulated] = useState(false);
  const [selectedLaboratory, setSelectedLaboratory] = useState<Laboratory | null>(null);

  const openLaboratoryModal = () => {
    setLaboratoryModalVisible(true);
  };

  const closeLaboratoryModal = () => {
    setLaboratoryModalVisible(false);
    form.resetFields();
    setSelectedLaboratory(null);
  };

  const callback = () => {
    setLaboratoryModalPopulated(true);
    closeLaboratoryModal();
  };

  const handleCreateLaboratory = (laboratory: Laboratory) => {
    createLaboratory(laboratory, callback);
  };

  const handleEditLaboratory = (laboratory: Laboratory, reqFormFile?: RcFile) => {
    updateLaboratory(selectedLaboratory!.id, laboratory, reqFormFile, callback);
  };

  const handleDeleteLaboratory = (id: any) => {
    deleteLaboratory(id, callback);
  };

  /* ----------------------- FOR EXPORT ------------------------- */

  const onCreateLaboratory = () => {
    setLaboratoryModalPopulated(false);
    setSelectedLaboratory(null);
    form.setFieldsValue({});
    openLaboratoryModal();
  };

  const onEditLaboratory = (laboratory: Laboratory) => {
    setLaboratoryModalPopulated(false);
    setSelectedLaboratory(laboratory);

    form.setFieldsValue({
      ...laboratory,
      ...(laboratory.textConfig ? { textConfig: JSON.stringify(laboratory.textConfig, null, 2) } : {}),
    });

    openLaboratoryModal();
  };

  const onDeleteLaboratory = (id: string) => {
    setLaboratoryModalPopulated(false);
    handleDeleteLaboratory(id);
  };

  const refreshLab = async (labId: string, cb?: (lab: Laboratory | null) => void): Promise<void> => {
    await getLaboratory(labId, { withReqFormUrl: true }, cb);
  };

  const Modal = (
    <LaboratoryModal
      form={form}
      laboratory={selectedLaboratory}
      visible={laboratoryModalVisible}
      onOk={selectedLaboratory ? handleEditLaboratory : handleCreateLaboratory}
      onCancel={closeLaboratoryModal}
      loading={loading}
      refreshLab={refreshLab}
    />
  );

  return { Modal, onCreateLaboratory, onEditLaboratory, onDeleteLaboratory, laboratoryModalPopulated };
};

export default useLaboratoryModal;
