import React, { useReducer } from 'react';

import { makeReducer } from 'utils/functions';

import { OrderTemplate, OrderTemplateFulfillmentType } from '@tassoinc/core-api-client';
import OrderTemplateModal from 'components/OrderTemplateModal';
import { useAnalytes } from 'context/analytes';
import { newOrderTemplate } from 'components/OrderTemplateModal/functions';
import { IProject } from 'context/projects';

type State = {
  projectId: string;
  customerId: string;
  orderTemplate: OrderTemplate;
  visible: boolean;
};

const useOrderTemplateModal = (project: IProject, templatesForProject: OrderTemplate[]) => {
  const [state, setState] = useReducer(makeReducer<State>(), {
    projectId: '',
    customerId: '',
    orderTemplate: newOrderTemplate(''),
    visible: false,
  });
  const { analytes, getAnalytes } = useAnalytes();
  const onCreateOrderTemplateModel = (
    projectId: string,
    customerId: string,
    fulfillmentType?: OrderTemplateFulfillmentType
  ): void => {
    setState({ projectId, orderTemplate: newOrderTemplate(projectId, fulfillmentType), customerId, visible: true });
  };

  const onEditOrderTemplateModel = (orderTemplate: OrderTemplate, projectId: string, customerId: string): void => {
    setState({ projectId, orderTemplate, customerId, visible: true });
  };

  const resetModal = (): void => {
    setState({ orderTemplate: {} as any, visible: false });
  };

  const orderTemplateModal = (
    <OrderTemplateModal
      visible={state.visible}
      orderTemplate={state.orderTemplate}
      onClose={resetModal}
      analytes={analytes}
      getAnalytes={getAnalytes}
      customerId={state.customerId}
      project={project}
      templatesForProject={templatesForProject}
    />
  );

  return {
    orderTemplateModal,
    onCreateOrderTemplateModel,
    onEditOrderTemplateModel,
    resetModal,
  };
};

export default useOrderTemplateModal;
