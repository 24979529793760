import { Configuration, Site, SiteApi, SiteApiListSitesRequest, SiteUpdate } from '@tassoinc/core-api-client';
import { httpClient } from 'services/index';
import { PagingRequest, PagingResponse } from 'utils/types';

let apiClient: SiteApi | undefined;

async function getClient(): Promise<SiteApi> {
  if (apiClient) {
    return apiClient;
  }

  const config = new Configuration({
    basePath: process.env.API_URL,
  });

  apiClient = new SiteApi(config, undefined, httpClient);
  return apiClient;
}

export type SiteResponse = {
  paging: PagingResponse;
  results: Array<Site>;
};

export async function get(model: SiteApiListSitesRequest, paging: PagingRequest): Promise<SiteResponse> {
  const client = await getClient();
  const result = await client.listSites({
    ...model,
    page: paging.page,
    pageLength: paging.pageLength,
  });

  const response = {
    paging: {
      page: result.data.paging?.page ?? 1,
      pageLength: result.data.paging?.pageLength ?? 50,
      isDescending: (result.data.paging ?? ({} as any)).isDescending ?? false,
      sortBy: (result.data.paging ?? ({} as any)).sortBy ?? '',
    },
    results: result.data.results ?? [],
  };
  return response;
}

export async function getById(id: string): Promise<Site | undefined> {
  const client = await getClient();
  const result = await client.getSite({ id });
  return result.data.results;
}

export const insertSite = async (body: Record<any, any>): Promise<Site> => {
  const client = await getClient();
  const payload = { ...body };
  const result = await client.createSite({
    siteCreate: payload as any,
  });
  return result.data.results!;
};

export const updateSite = async (id: string, body: SiteUpdate): Promise<Site> => {
  const client = await getClient();
  const payload = { ...body };
  const result = await client.updateSite({
    id,
    siteUpdate: payload,
  });

  return result.data.results!;
};

export const deleteSite = async (id: string): Promise<Site> => {
  const client = await getClient();

  const result = await client.deleteSite({
    id,
  });

  return result.data.results!;
};
