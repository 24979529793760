import React, { FC, useEffect } from 'react';

import { Select, Typography, Space } from 'antd';

import { LABEL_IDENTIFIER_SOURCE_LABELS, PROJECT_TYPES } from 'constants/index';
import { LabelIdentifierSource, ProjectType } from 'utils/types';

const { Text } = Typography;

interface Props {
  selectedValue: LabelIdentifierSource | null;
  projectType: ProjectType | null;
  hideTitle?: boolean;
  showInline?: boolean;
  onChange: (source: string | null) => void;
}

const LabelIdentifierSourceField: FC<Props> = ({
  selectedValue,
  projectType,
  onChange,
  hideTitle = false,
  showInline = false,
}) => {
  const options: Partial<LabelIdentifierSource>[] =
    projectType === 'onDemandTesting'
      ? ['hrid', 'barcodePrelabeled']
      : (Object.keys(LABEL_IDENTIFIER_SOURCE_LABELS) as LabelIdentifierSource[]);

  useEffect(() => {
    if (
      projectType === PROJECT_TYPES.onDemandTesting &&
      selectedValue !== 'hrid' &&
      selectedValue !== 'barcodePrelabeled'
    ) {
      onChange(null);
    }
  }, [projectType]);

  return (
    <div className="SelectField" style={showInline ? { display: 'inline-block' } : {}}>
      <Space direction="vertical">
        {!hideTitle && <Text strong>Label Identifier Source</Text>}

        <Select
          onSelect={(value: string) => onChange(value)}
          placeholder="Select label identifier source"
          showArrow
          value={selectedValue ?? undefined}
          style={{ minWidth: '16em' }}
          options={options.map((k) => ({ value: k, label: LABEL_IDENTIFIER_SOURCE_LABELS[k] }))}
          data-testId="project-label-identifier-source"
        />
      </Space>
    </div>
  );
};

export default LabelIdentifierSourceField;
