import React from 'react';

import { CustomAttributeDefinitionProject, IProject } from 'context/projects';
import { Form, Input, Select } from 'antd';
import { ICustomAttributeDefinition } from 'context/customAttributeDefinitions';

export const generateCustomAttributeElements = (attributeDefinitions: CustomAttributeDefinitionProject[]): any => {
  const results = attributeDefinitions.map((customAttributeDefinition: CustomAttributeDefinitionProject) =>
    customAttributeDefinition.allowedValues && customAttributeDefinition.allowedValues.length > 0 ? (
      // Categorical / drop down custom attribute selection
      <Form.Item
        key={customAttributeDefinition.name}
        name={`order:${customAttributeDefinition.name}`}
        label={customAttributeDefinition.displayName ?? customAttributeDefinition.name}
        rules={[
          {
            required: customAttributeDefinition.required,
            message: `Please input ${customAttributeDefinition.displayName ?? customAttributeDefinition.name}!`,
          },
        ]}
      >
        <Select>
          <Select.Option key="empty" value="">
            &nbsp;
          </Select.Option>

          {Object.values(customAttributeDefinition.allowedValues).map((category) => (
            <Select.Option key={category} value={category}>
              {category}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    ) : (
      // Free form custom attribute text field
      <Form.Item
        key={customAttributeDefinition.name}
        name={`order:${customAttributeDefinition.name}`}
        label={customAttributeDefinition.displayName ?? customAttributeDefinition.name}
        rules={[
          {
            required: customAttributeDefinition.required,
            message: `Please input ${customAttributeDefinition.displayName ?? customAttributeDefinition.name}!`,
          },
        ]}
      >
        <Input maxLength={customAttributeDefinition.maxLength || 1000} />
      </Form.Item>
    )
  );

  return results;
};

export function getSelectedCustomAttributes(
  selectedOrderTemplateId: string | null,
  project: IProject | null,
  customAttributeDefinitions: Map<string, ICustomAttributeDefinition[] | CustomAttributeDefinitionProject[]>
) {
  if (!project?.useOrderTemplates) {
    return customAttributeDefinitions.get('none') ?? [];
  }
  const defaultTemplateId = project.orderTemplatesViewOrder.find(
    (orderTemplateViewOrder) => orderTemplateViewOrder.default
  )?.templateId;
  if (!selectedOrderTemplateId && !defaultTemplateId) {
    return [];
  }
  const resolvedTemplateId = selectedOrderTemplateId !== null ? selectedOrderTemplateId : defaultTemplateId;

  // ℹ️ Info on Order Template ID segments: https://tassoinc.atlassian.net/wiki/spaces/CODE/pages/2026864642/Order+Configuration+template+Ids
  const segmentedId = resolvedTemplateId?.includes('::') ? resolvedTemplateId.split('::')[1] : resolvedTemplateId;

  const retrievedDefinitions = segmentedId ? customAttributeDefinitions.get(segmentedId) : undefined;
  return retrievedDefinitions ?? [];
}
