import { httpClient } from 'services/index';
import { PagingRequest } from 'utils/types';

interface LoadProjectContactsParameters {
  query: Record<string, string | number | boolean>;
  paging: PagingRequest;
}
export const loadProjectContacts = (params: LoadProjectContactsParameters): Promise<any> => {
  const { query, paging } = params;

  return httpClient
    .get('/projectContactUserLinks', {
      params: { ...query, ...paging },
    })
    .then((_) => _.data);
};

type GetProjectContactsPayload = {
  projectId: string | null;
  includeUser?: boolean;
  includeProject?: boolean;
};
export const getProjectContacts = (input: GetProjectContactsPayload, paging: PagingRequest): Promise<any> => {
  const params: Record<string, any> = { ...paging };
  if (input.projectId) {
    params.projectIds = input.projectId;
  }

  const hydration: string[] = [];

  if (input.includeUser) {
    hydration.push('user');
  }

  if (input.includeProject) {
    hydration.push('project');
  }

  return httpClient
    .get('/projectContactUserLinks', {
      params,
      headers: hydration.length > 0 ? { 'tasso-hydration': hydration.join(',') } : undefined,
    })
    .then((_) => _.data);
};

export const getProjectContact = (id: string): Promise<any> =>
  httpClient
    .get(`/projectContactUserLinks/${id}`, { headers: { 'tasso-hydration': 'user' } })
    .then((_) => _.data.results);

export interface ICreateProjectContactData {
  projectId: string;
  userId: string;
}
export const createProjectContact = (data: ICreateProjectContactData): Promise<any> =>
  httpClient
    .post('/projectContactUserLinks', data, { headers: { 'tasso-hydration': 'user' } })
    .then((_) => _.data.results);

export const deleteProjectContact = (id: string): Promise<any> =>
  httpClient.delete(`/projectContactUserLinks/${id}`).then((_) => _.data.results);
