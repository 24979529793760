/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from 'react';
import { FileAddOutlined, FileDoneOutlined, DeleteOutlined } from '@ant-design/icons';

import { useUploads } from 'context/uploads';
import clsx from 'clsx';
import Link from 'antd/lib/typography/Link';
import { openDocument } from 'utils/functions';
import LocaleSelector from './LocaleSelector';
import TagSelector from './TagSelector';
import TextConfig from './TextConfig';

import { UploadEntry } from './types';

interface OneRowProps extends UploadEntry {
  working: boolean;
  usedLocales: string[];
  hideTextConfig: boolean;
  onChange: (fileUid: string, locale: string, tag: string | null, textConfig: Record<string, any>[] | null) => void;
  onDelete: (fileUid: string) => void;
}

const OneRow: FC<OneRowProps> = ({
  file,
  locale,
  tag,
  status,
  textConfig,
  working,
  usedLocales,
  hideTextConfig,
  onChange,
  onDelete,
}) => {
  const { getUpload } = useUploads();

  const onLocaleChange = (value: string): void => {
    onChange(file.uid, value, tag, textConfig);
  };

  const onTypeChange = (value: string | null): void => {
    onChange(file.uid, locale, value, textConfig);
  };

  const onTextConfigChange = (value: Record<string, any>[] | null): void => {
    onChange(file.uid, locale, tag, value);
  };

  const downloadFile = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void => {
    e.preventDefault();

    getUpload(file.uid, {}, (upload) => {
      openDocument(upload!.fileUrl!);
    });
  };

  const handleDeleteClick = (): void => {
    if (working) {
      return;
    }

    onDelete(file.uid);
  };

  return (
    <div
      className={clsx([
        'OneFile',
        { Uploaded: status === 'uploaded', Deleted: status === 'deleted', Disabled: working },
      ])}
    >
      {status === 'new' && <FileAddOutlined className="FileIcon" title="New file. Click &#34;Save&#34; to upload." />}
      {status === 'deleted' && (
        <DeleteOutlined
          className="FileIcon"
          title="File marked for deletion. Click &#34;Save&#34; to delete."
          onClick={downloadFile}
        />
      )}
      {status === 'uploaded' && <FileDoneOutlined className="FileIcon" title="Uploaded file" onClick={downloadFile} />}

      <div className="FileInfo">
        <div className="FileName">
          {status === 'uploaded' || status === 'deleted' ? (
            <Link onClick={downloadFile}>{file.name}</Link>
          ) : (
            <>{file.name}</>
          )}
        </div>

        <LocaleSelector
          value={locale}
          usedValues={usedLocales}
          onChange={onLocaleChange}
          disabled={working || status === 'deleted'}
        />

        <TagSelector value={tag} onChange={onTypeChange} disabled={working || status === 'deleted'} />

        {!hideTextConfig && (
          <TextConfig disabled={working || status === 'deleted'} value={textConfig} onChange={onTextConfigChange} />
        )}

        <DeleteOutlined disabled={working} className="DeleteFile" onClick={handleDeleteClick} />
      </div>
    </div>
  );
};

interface Props {
  files: UploadEntry[];
  working: boolean;
  hideTextConfig?: boolean;
  updateFile: (fileUid: string, locale: string, type: string | null, textConfig: Record<string, any>[] | null) => void;
  deleteFile: (fileUid: string) => void;
}

const FileManager: FC<Props> = ({ files, working, hideTextConfig = false, updateFile, deleteFile }) => {
  const onChange = (
    fileUid: string,
    locale: string,
    type: string | null,
    textConfig: Record<string, any>[] | null
  ): void => {
    updateFile(fileUid, locale, type, hideTextConfig ? null : textConfig);
  };

  const onDelete = (fileUid: string) => {
    deleteFile(fileUid);
  };

  if (files.length === 0) {
    // Don't display anything if there are no uploads yet
    return null;
  }

  return (
    <div className="FileManager">
      <h2>Documents</h2>

      {files.map((fileInfo) => (
        <OneRow
          key={fileInfo.file.uid}
          file={fileInfo.file}
          locale={fileInfo.locale}
          tag={fileInfo.tag}
          status={fileInfo.status}
          originalUpload={fileInfo.originalUpload}
          textConfig={fileInfo.textConfig}
          usedLocales={files.map((file) => file.locale).filter((v) => !!v) as string[]}
          hideTextConfig={hideTextConfig}
          working={working}
          onChange={onChange}
          onDelete={onDelete}
        />
      ))}
    </div>
  );
};

export default FileManager;
