/* eslint-disable no-await-in-loop */
import React, { FC, useReducer, createContext } from 'react';

import { projectContactsApi as api } from 'api';
import { loadAllPages, makeReducer } from 'utils/functions';
import { getErrorMessage } from 'utils/issues';
import Notification from 'utils/notification';

import { Context, LoadProjectContactsFunction, ProjectContact, State } from './types';

const getInitialState = (): State => ({
  projectContacts: [],
  projectContact: null,
  loading: false,
  error: null,
});

const ProjectContactsContext = createContext<Context>({
  ...getInitialState(),
  loadProjectContacts: async () => {},
  getProjectContacts: async () => {},
  getProjectContact: async () => {},
  createProjectContact: async () => {},
  deleteProjectContact: async () => {},
  modifyProjectContact: () => {},
  resetProjectContacts: () => {},
});

const ProjectContactsProvider: FC = (props) => {
  const [state, setState] = useReducer(makeReducer<State>(), getInitialState());

  const loadProjectContacts: LoadProjectContactsFunction = async (params, callback?) => {
    const skipStateUpdate = params?.skipStateUpdate === true;

    if (!skipStateUpdate) {
      setState({ error: null, loading: true });
    }

    try {
      const { userIds, projectIds, customerIds } = params;

      const results: ProjectContact[] = [];
      const promises: Promise<any>[] = [];

      if (typeof userIds === 'string') {
        const userIdList = userIds === '' ? [] : userIds.split(',');

        for (let i = 0; i < userIdList.length; i += 50) {
          const userIdSubset = userIdList.slice(i, i + 50);
          promises.push(
            api.loadProjectContacts({
              query: { userIds: userIdSubset.join(',') },
              paging: { page: 1, pageLength: 50 },
            })
          );

          if (promises.length === 5) {
            const data = await Promise.all(promises.splice(0, promises.length));
            results.push(...data.reduce((acc, val) => [...acc, ...val.results], []));
          }
        }
      } else if (typeof projectIds === 'string') {
        const projectIdList = projectIds === '' ? [] : projectIds.split(',');

        for (let i = 0; i < projectIdList.length; i += 50) {
          const projectIdSubset = projectIdList.slice(i, i + 50);
          promises.push(
            api.loadProjectContacts({
              query: { projectIds: projectIdSubset.join(',') },
              paging: { page: 1, pageLength: 50 },
            })
          );

          if (promises.length === 5) {
            const data = await Promise.all(promises.splice(0, promises.length));
            results.push(...data.reduce((acc, val) => [...acc, ...val.results], []));
          }
        }
      } else if (typeof customerIds === 'string') {
        const customerIdList = customerIds === '' ? [] : customerIds.split(',');

        for (let i = 0; i < customerIdList.length; i += 50) {
          const customerIdSubset = customerIdList.slice(i, i + 50);
          promises.push(
            api.loadProjectContacts({
              query: { customerIds: customerIdSubset.join(',') },
              paging: { page: 1, pageLength: 50 },
            })
          );

          if (promises.length === 5) {
            const data = await Promise.all(promises.splice(0, promises.length));
            results.push(...data.reduce((acc, val) => [...acc, ...val.results], []));
          }
        }
      }

      if (promises.length > 0) {
        const data = await Promise.all(promises.splice(0, promises.length));
        results.push(...data.reduce((acc, val) => [...acc, ...val.results], []));
      }

      if (!skipStateUpdate) {
        setState({ projectContacts: results });
      }

      if (callback) {
        await callback(results);
      }
    } catch (e) {
      const errorMsg = getErrorMessage(e);

      if (!skipStateUpdate) {
        setState({
          error: errorMsg,
        });
      }

      Notification({
        type: 'error',
        message: 'Failed to get project contacts',
        description: errorMsg,
      });
    }

    if (!skipStateUpdate) {
      setState({ loading: false });
    }
  };

  const getProjectContacts = async (
    projectId: string | null,
    includeUser: boolean,
    includeProject: boolean
  ): Promise<void> => {
    try {
      setState({ error: null, loading: true });

      type PayloadType = Parameters<typeof api.getProjectContacts>[0];
      const items = await loadAllPages<ProjectContact, PayloadType>(
        api.getProjectContacts,
        { projectId, includeUser, includeProject },
        {
          pageLength: 50,
          sortBy: 'createdAt',
          isDescending: false,
        }
      );

      setState({
        loading: false,
        projectContacts: items,
      });
    } catch (e) {
      const errorMsg = getErrorMessage(e);

      setState({
        loading: false,
        error: errorMsg,
      });

      Notification({
        type: 'error',
        message: 'Failed to get project contacts',
        description: errorMsg,
      });
    }
  };

  const getProjectContact = async (id: string, callback: any): Promise<void> => {
    try {
      setState({ error: null, loading: true });

      const item = await api.getProjectContact(id);

      setState({
        projectContact: item,
        loading: false,
      });

      if (callback) {
        await callback();
      }
    } catch (e) {
      const errorMsg = getErrorMessage(e);

      setState({ loading: false, error: errorMsg });

      Notification({
        type: 'error',
        message: 'Failed to get project contact',
        description: errorMsg,
      });
    }
  };

  const createProjectContact = async (data: api.ICreateProjectContactData, callback: any): Promise<void> => {
    try {
      setState({ error: null, loading: true });

      const item = await api.createProjectContact(data);

      setState({
        projectContacts: [...state.projectContacts, item],
        loading: false,
      });

      Notification({ type: 'success', message: 'Contact added to project', duration: 1.5 });

      if (callback) {
        await callback();
      }
    } catch (e) {
      const errorMsg = getErrorMessage(e);

      setState({ loading: false, error: errorMsg });

      Notification({
        type: 'error',
        message: 'Failed to add contact to project',
        description: errorMsg,
      });
    }
  };

  const modifyProjectContact = (id: string, user: any): void => {
    setState({
      projectContacts: state.projectContacts.map((c) => (c.id === id ? { ...c, user } : c)),
    });
  };

  const deleteProjectContact = async (id: string, callback?: any): Promise<void> => {
    try {
      setState({ error: null, loading: true });

      await api.deleteProjectContact(id);

      setState({
        loading: false,
        projectContacts: state.projectContacts.filter((c) => c.id !== id),
        projectContact: state.projectContact && state.projectContact.id === id ? null : state.projectContact,
      });

      Notification({ type: 'success', message: 'Contact removed from project', duration: 1.5 });

      if (callback) {
        await callback();
      }
    } catch (e) {
      const errorMsg = getErrorMessage(e);

      setState({
        loading: false,
        error: errorMsg,
      });

      Notification({
        type: 'error',
        message: 'Failed to remove contact from project',
        description: errorMsg,
      });
    }
  };

  const resetProjectContacts = (): void => {
    setState(getInitialState());
  };

  return (
    <ProjectContactsContext.Provider
      value={{
        projectContacts: state.projectContacts,
        projectContact: state.projectContact,
        loading: state.loading,
        error: state.error,
        loadProjectContacts,
        getProjectContacts,
        getProjectContact,
        createProjectContact,
        deleteProjectContact,
        modifyProjectContact,
        resetProjectContacts,
      }}
      {...props}
    />
  );
};

const useProjectContacts = (): Context => React.useContext(ProjectContactsContext);

export { ProjectContactsProvider, useProjectContacts };
