import { httpClient } from 'services/index';
import { PagingRequest } from 'utils/types';

export interface GetContactsParameters {
  customerId: string;
  roles: string;
}

export const getContacts = (params: GetContactsParameters, paging: PagingRequest): Promise<any> => {
  const req: any = {
    customerIds: params.customerId,
  };

  if (params.roles !== '') {
    req.roles = params.roles;
  }

  return httpClient.get('/users', { params: { ...req, ...paging } }).then((_) => _.data);
};

export const getContact = (id: string): Promise<any> => httpClient.get(`/users/${id}`).then((_) => _.data.results);

export interface CreateContactParameters {
  customerId: string;
  username: string;
  role: string;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
}

export const createContact = (params: CreateContactParameters): Promise<any> =>
  httpClient.post('/users', params).then((_) => _.data.results);

export const updateContact = (id: string, data: Record<string, any>): Promise<any> =>
  httpClient.patch(`/users/${id}`, data).then((_) => _.data.results);

export const deleteContact = (id: string): Promise<any> =>
  httpClient.delete(`/users/${id}`).then((_) => _.data.results);
