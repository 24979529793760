import { DeviceStatus } from '@tassoinc/core-api-client';

export function filterCancelled(queryParams?: Record<string, string>): Record<string, string> {
  if (!queryParams) {
    return { notStatuses: DeviceStatus.Cancelled };
  }

  const filteredQueryParams = { ...queryParams };
  if (queryParams.notStatuses && !queryParams.notStatuses.includes(DeviceStatus.Cancelled)) {
    filteredQueryParams.notStatuses = `${queryParams.notStatuses},${DeviceStatus.Cancelled}`;
  } else {
    filteredQueryParams.notStatuses = DeviceStatus.Cancelled;
  }

  return filteredQueryParams;
}
