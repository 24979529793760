import { KIT_ITEM_TYPES_LABELS } from 'constants/index';
import React, { useEffect, useMemo, useState } from 'react';
import { Select } from 'antd';

import { IKitItem } from 'context/kitItems';
import KitItemTypeFilter from './KitItemTypeFilter';

/**
 * The returned string is used by the <Select> component to perform searches (filtering).
 * Whatever text is returned it will be searchable and will match the corresponding kit item.
 */
export const getFilterString = (kitItem: IKitItem): string => {
  const description = kitItem.description ?? '';
  const key = kitItem.key ?? '';
  const partNumber = kitItem.partNumber ?? '';
  const kitItemType = kitItem.kitItemType ?? '';
  const kitItemTypeLabel = KIT_ITEM_TYPES_LABELS[kitItemType as keyof typeof KIT_ITEM_TYPES_LABELS] ?? '';

  return `${description.toLowerCase()} ${key.toLowerCase()} ${partNumber.toLowerCase()} ${kitItemType.toLowerCase()} ${kitItemTypeLabel.toLowerCase()}`.trim();
};

interface Props {
  showDefaultOption?: boolean;
  visible: boolean;
  kitItems: IKitItem[];
  selectedValue: string;
  handleSelectChange: (value: string) => void;
}

const KitItemSelector: React.FC<Props> = ({
  showDefaultOption = false,
  visible,
  kitItems,
  selectedValue,
  handleSelectChange,
}) => {
  const [filter, setFilter] = useState<string[]>([]);

  const handleKitItemTypeSelect = (selectedTypes: (keyof typeof KIT_ITEM_TYPES_LABELS)[]): void => {
    setFilter(selectedTypes);
  };

  const filteredKitItems = useMemo(() => {
    if (filter.length === 0) {
      return kitItems;
    }

    return kitItems.filter((item) => filter.includes(item.kitItemType!));
  }, [filter, kitItems]);

  // Reset selected value to default when first show or when filters changes.
  useEffect(() => {
    handleSelectChange('');
  }, [visible, filter]);

  return (
    <>
      <KitItemTypeFilter onSelect={handleKitItemTypeSelect} visible={visible} />
      <Select
        data-testid="select-kit-item"
        showSearch
        bordered
        value={selectedValue || undefined}
        style={{ width: '100%' }}
        dropdownClassName="KitItemSelector"
        onSelect={handleSelectChange}
        defaultValue={showDefaultOption ? '' : undefined}
        placeholder="Select a kit item"
        optionFilterProp="children"
        optionLabelProp="label"
        filterOption={(input, option: any) => {
          const val = option.children[0]?.props?.children ?? '';
          return val.indexOf(input.toLowerCase()) >= 0;
        }}
        filterSort={(optionA: any, optionB: any) => {
          const valA = optionA.children[0]?.props?.children ?? '';
          const valB = optionB.children[0]?.props?.children ?? '';
          return valA.localeCompare(valB);
        }}
      >
        {showDefaultOption && (
          <Select.Option key="" value="" label="--- Existing kit items ---">
            <div className="kitItemDesc">--- Existing kit items ---</div>
          </Select.Option>
        )}
        {filteredKitItems.map((item) => (
          <Select.Option key={item.id} value={item.id} label={item.description}>
            <div className="filterContent">{getFilterString(item)}</div>
            <div className="kitItemDesc">{item.description}</div>
            <div className="kitItemMeta">
              Key: <span>{item.key}</span> Part #: <span>{item.partNumber}</span> Type:{' '}
              <span>{KIT_ITEM_TYPES_LABELS[item.kitItemType as keyof typeof KIT_ITEM_TYPES_LABELS]}</span>
            </div>
          </Select.Option>
        ))}
      </Select>
    </>
  );
};

export default KitItemSelector;
