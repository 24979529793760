import React from 'react';

import { Route, Redirect } from 'react-router-dom';

import { ROUTES } from 'constants/index';
import { useAuth } from 'context/auth';

interface IProtectedRoute {
  children: any;
  path: string;
  exact?: boolean;
}

const ProtectedRoute: React.FC<IProtectedRoute> = ({ children, path, exact, ...rest }) => {
  const { profile } = useAuth();

  return (
    <Route
      {...rest}
      path={path}
      exact={exact}
      render={({ location }) =>
        profile ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: ROUTES.login,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

export default ProtectedRoute;
