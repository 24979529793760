/* eslint-disable no-await-in-loop */
import React, { FC, createContext, useReducer } from 'react';

import { getErrorMessage } from 'utils/issues';
import * as userFilesApi from '../api/userFiles';
import { makeReducer, wait } from '../utils/functions';

export type Options = {
  maxWaitTimeMs?: number;
  delayBetweenRequestsMs?: number;
};

export type State = {
  error: string;
  loading: boolean;
};

type UserFilesContextType = {
  loading: boolean;
  error: string;
  downloadPatients: (projectId: string) => Promise<void>;
};

const initialState: State = {
  loading: false,
  error: '',
};

const UserFilesContext = createContext<UserFilesContextType>({
  ...initialState,
  downloadPatients: async () => {},
});

const UserFilesProvider: FC = (props) => {
  const [state, setState] = useReducer(makeReducer<State>(), initialState);

  const download = async (apiCall: () => Promise<any>, options: Options = {}): Promise<void> => {
    setState({
      loading: true,
      error: '',
    });

    const maxWaitTimeMs = typeof options.maxWaitTimeMs === 'number' ? options.maxWaitTimeMs : 10_000;
    const delayBetweenRequestsMs =
      typeof options.delayBetweenRequestsMs === 'number' ? options.delayBetweenRequestsMs : 1_000;

    let maxAttempts = Math.floor(maxWaitTimeMs / delayBetweenRequestsMs);

    try {
      const result = await apiCall();
      const exportId = result.id;

      let exportedFileId = '';
      let errorMessage = '';

      do {
        await wait(delayBetweenRequestsMs);

        const statusCheck = await userFilesApi.getPatientExportStatus(exportId);
        const { status } = statusCheck;

        if (status === 'failed') {
          errorMessage = statusCheck.info.errorMessage;
          break;
        }

        if (status === 'complete') {
          exportedFileId = statusCheck.exportedFileId;
          break;
        }

        maxAttempts -= 1;
      } while (maxAttempts >= 0);

      if (exportedFileId) {
        const fileResult = await userFilesApi.getUserFile(exportedFileId);
        const link = document.createElement('a');
        link.href = fileResult.downloadUrl;
        link.target = '_blank';
        link.rel = 'noopener';
        link.click();
      } else {
        setState({ error: errorMessage || 'Request timed out.' });
      }
    } catch (e) {
      setState({ error: getErrorMessage(e) });
      console.log(e);
    } finally {
      setState({
        loading: false,
      });
    }
  };

  const downloadPatients = async (projectId: string): Promise<void> =>
    download(() => userFilesApi.exportPatients(projectId), { maxWaitTimeMs: 900_000, delayBetweenRequestsMs: 2_000 });

  return (
    <UserFilesContext.Provider
      value={{
        loading: state.loading,
        error: state.error,
        downloadPatients,
      }}
      {...props}
    />
  );
};

const useUserFiles = (): UserFilesContextType => React.useContext(UserFilesContext);

export { UserFilesProvider, useUserFiles };
