import React, { useReducer } from 'react';

import { makeReducer } from 'utils/functions';

import { Site } from '@tassoinc/core-api-client';
import SiteModal from 'components/SiteModal';
import { useSites } from 'context/sites';
import { Form } from 'antd';

type State = {
  customerId: string;
  site: Site | null;
  visible: boolean;
};

const useSiteModal = () => {
  const [state, setState] = useReducer(makeReducer<State>(), {
    customerId: '',
    site: null,
    visible: false,
  });
  const [form] = Form.useForm();
  const { getSite } = useSites();

  const { createSite, updateSite } = useSites();

  const onCreateSiteModal = (customerId: string): void => {
    form.setFieldsValue({});

    setState({ site: null, customerId, visible: true });
  };

  const onEditSiteModal = (site: Site): void => {
    form.setFieldsValue({});
    getSite(site.id, async (detailedSite: any) => {
      setState({ site: detailedSite, customerId: site.customerId, visible: true });
    });
  };

  const resetModal = (): void => {
    setState({ site: null, visible: false });
  };

  const buildAddressFromForm = (site: Site, formInput: any): Site => {
    // eslint-disable-next-line no-param-reassign
    site.address = {
      name: formInput.name,
      attentionName: formInput.attentionName,
      phoneNumber: formInput.phoneNumber?.length > 0 ? formInput.phoneNumber : undefined,
      address1: formInput.address1,
      address2: formInput.address2,
      district1: formInput.district1,
      city: formInput.city,
      postalCode: formInput.postalCode,
      country: 'US',
    } as any;
    return site;
  };

  const handleCreateSite = (site: any) => {
    let newSite: Site = { name: site.name, customerId: state.customerId } as any;
    newSite = buildAddressFromForm(newSite, site);

    createSite(newSite);

    resetModal();
  };

  const handleEditSite = (site: any) => {
    let updatedSite: Site = { name: site.name } as any;
    updatedSite = buildAddressFromForm(updatedSite, site);
    updatedSite.address.entityType = 'site';
    updatedSite.address.entityId = site.id;
    updateSite(site.id, updatedSite);

    resetModal();
  };

  const siteModal = (
    <SiteModal
      form={form}
      visible={state.visible}
      site={state.site}
      onOk={state.site ? handleEditSite : handleCreateSite}
      onClose={resetModal}
      customerId={state.customerId}
    />
  );

  return {
    siteModal,
    onCreateSiteModal,
    onEditSiteModal,
    resetModal,
  };
};

export default useSiteModal;
