/* eslint-disable no-lonely-if */
import React, { FC, useEffect, useState } from 'react';

import { Tabs, /* Popconfirm, */ Button, Table, Popconfirm } from 'antd';

import { PlusOutlined, EditOutlined, PlusCircleOutlined, DeleteOutlined } from 'components/icons';
import { CustomerProjectsList, Spinner } from 'components/index';
import { Customer, useCustomers } from 'context/customers';
import { useProjects } from 'context/projects';
import useCustomerModal from 'hooks/useCustomerModal';
import { ICustomAttributeDefinition, useCustomAttributeDefinitions } from 'context/customAttributeDefinitions';
import { ColumnsType } from 'antd/lib/table';
import useCustomAttributeModal from 'hooks/useCustomAttributeModal';
import { useSites } from 'context/sites';
import useSiteModal from 'hooks/useSiteModal';
import { Site } from '@tassoinc/core-api-client';
import { useAuth } from 'context/auth';
import { USER_ROLES } from 'constants/index';

const { TabPane } = Tabs;

const CustomersContainer: FC = () => {
  const { getCustomers, customers, resetCustomers, loading: customersLoading } = useCustomers();
  const { projects, getProjects, resetProjects, loading: projectsLoading } = useProjects();
  const { customAttributeDefinitions, getCustomAttributeDefinitions, resetCustomAttributeDefinitions } =
    useCustomAttributeDefinitions();
  const { sites, getSitesForCustomer, deleteSite } = useSites();
  const [currentCustomer, setCurrentCustomer] = useState<Customer | null>(null);
  const { Modal: CustomerModal, onCreateCustomer, onEditCustomer /* , onDeleteCustomer */ } = useCustomerModal();
  const { updateCustomAttributeDefinition } = useCustomAttributeDefinitions();
  const { customAttributeModal, onCreateCustomAttribute, onEditCustomAttribute } = useCustomAttributeModal(true);
  const { siteModal, onCreateSiteModal, onEditSiteModal } = useSiteModal();

  const { profile } = useAuth();
  const isAdmin = (profile?.role || '') === USER_ROLES.internalAdmin;

  const actions = {
    customAttributeDefinitions: {
      create: (customerId: string) => {
        onCreateCustomAttribute(customerId);
      },
      edit: (customAttributeDefinition: ICustomAttributeDefinition) => {
        onEditCustomAttribute(customAttributeDefinition);
      },
      retire: (customAttributeDefinition: ICustomAttributeDefinition) => {
        const data = { deprecated: true };
        updateCustomAttributeDefinition(customAttributeDefinition.id, data);
      },
    },
    sites: {
      create: (customerId: string) => {
        onCreateSiteModal(customerId);
      },
      edit: (editSite: Site) => {
        onEditSiteModal(editSite);
      },
    },
  };

  useEffect(() => {
    getCustomers();
    getProjects(false);

    return () => {
      resetCustomers();
      resetProjects();
      resetCustomAttributeDefinitions();
    };
  }, []);

  useEffect(() => {
    if (currentCustomer) {
      getCustomAttributeDefinitions(currentCustomer.id);
      getSitesForCustomer(currentCustomer.id);
    }
  }, [currentCustomer]);

  const isLoading = customersLoading || projectsLoading;

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (currentCustomer === null) {
      // no customer selected
      if (customers.length > 0) {
        // customers loaded, selected the first one
        setCurrentCustomer(customers[0]);
      }
    } else {
      // customer already selected
      if (customers.length > 0) {
        // make sure it's still in the list of customers
        const isValid = !!customers.find((c) => c.id === currentCustomer.id);
        if (!isValid) {
          setCurrentCustomer(customers[0]);
        }
      } else {
        setCurrentCustomer(null);
      }
    }
  }, [isLoading]);

  const onTabChange = (key: string) => {
    setCurrentCustomer(customers.find((c) => c.id === key) || null);
  };

  const handleTabEdit = (_targetKey: any, action: any) => {
    if (action === 'add') {
      onCreateCustomer();
    }
  };

  return (
    <>
      <div className="Customers">
        <Spinner spinning={isLoading}>
          <Tabs
            tabPosition="left"
            size="small"
            type="editable-card"
            onChange={onTabChange}
            onEdit={handleTabEdit}
            activeKey={currentCustomer ? currentCustomer.id : undefined}
            addIcon={
              <>
                {isAdmin && (
                  <div>
                    <PlusOutlined /> Add customer
                  </div>
                )}
              </>
            }
          >
            {customers.map((customer) => (
              <TabPane tab={customer.name} key={customer.id}>
                <div className="customerContent">
                  <h3>{customer.name}</h3>
                  <CustomerProjectsList projects={projects.filter((p) => p.customerId === customer.id)} />

                  {isAdmin && (
                    <Button size="middle" onClick={() => onEditCustomer(customer)} icon={<EditOutlined />}>
                      Edit customer
                    </Button>
                  )}

                  <div className="customerContent">
                    <h3>
                      Customer Sites
                      {isAdmin && (
                        <Button
                          type="link"
                          onClick={() => {
                            actions.sites.create(customer.id);
                          }}
                        >
                          <PlusCircleOutlined />
                        </Button>
                      )}
                    </h3>

                    {(sites?.length ?? 0) > 0 ? (
                      <Table
                        style={{ paddingRight: '24px' }}
                        dataSource={sites.sort()}
                        columns={[
                          { title: 'Name', dataIndex: ['name'] },
                          isAdmin
                            ? {
                                width: '30%',
                                title: 'Actions',
                                render: (item: any) => (
                                  <div>
                                    <Button
                                      type="link"
                                      onClick={() => {
                                        actions.sites.edit(item);
                                      }}
                                    >
                                      Edit
                                    </Button>
                                    <Popconfirm
                                      title="Are you sure you want to delete this site?"
                                      onConfirm={() => {
                                        deleteSite(item.id);
                                      }}
                                      okText="Yes"
                                      cancelText="No"
                                    >
                                      <Button
                                        size="middle"
                                        icon={<DeleteOutlined />}
                                        danger
                                        style={{ marginLeft: '15px' }}
                                      >
                                        Delete
                                      </Button>
                                    </Popconfirm>
                                  </div>
                                ),
                              }
                            : {},
                        ].filter((column) => Object.keys(column).length > 0)} // Hide empty columns if they exist
                      />
                    ) : null}
                  </div>

                  <div className="customerContent">
                    <h3>
                      Custom Attribute Definitions
                      {isAdmin && (
                        <Button
                          type="link"
                          onClick={() => {
                            actions.customAttributeDefinitions.create(customer.id);
                          }}
                        >
                          <PlusCircleOutlined />
                        </Button>
                      )}
                    </h3>

                    {(customAttributeDefinitions?.length ?? 0) > 0 ? (
                      <Table
                        style={{ paddingRight: '24px' }}
                        dataSource={customAttributeDefinitions.sort()}
                        columns={
                          [
                            { title: 'Attribute name', dataIndex: ['name'] },
                            {
                              title: 'Required?',
                              dataIndex: ['required'],
                              render: (_: any, item: any) => <div>{item.required ? 'Yes' : 'No'}</div>,
                            },
                            { title: 'Maximum Length', dataIndex: ['maxLength'] },
                            {
                              title: 'Drop Down List Values',
                              dataIndex: ['allowedValues'],
                              render: (_: any, item: { allowedValues: any[] }) => (
                                <div>{item.allowedValues ? item.allowedValues.join(',') : ''}</div>
                              ),
                            },
                            {
                              title: 'Retired?',
                              dataIndex: ['deprecated'],
                              render: (_: any, item: any) => <div>{item.deprecated ? 'Yes' : 'No'}</div>,
                            },
                            isAdmin
                              ? {
                                  title: 'Actions',
                                  render: (item: any) =>
                                    !item.deprecated && (
                                      <div>
                                        <Button
                                          type="link"
                                          onClick={() => {
                                            actions.customAttributeDefinitions.edit(item);
                                          }}
                                        >
                                          Edit
                                        </Button>
                                        <Popconfirm
                                          title="Are you sure you want to retire attribute from customer?  You will no longer be able to use the attribute."
                                          okText="Yes"
                                          cancelText="No"
                                          onConfirm={() => {
                                            actions.customAttributeDefinitions.retire(item);
                                          }}
                                        >
                                          <Button type="link">Retire</Button>
                                        </Popconfirm>
                                      </div>
                                    ),
                                }
                              : {}, // An empty column. 'null' is not a valid value here
                          ].filter(
                            (column) => (Object.keys(column).length > 0 ? column : '') // Hide empty columns if they exist
                          ) as ColumnsType<ICustomAttributeDefinition>
                        }
                      />
                    ) : null}
                  </div>

                  {/*
                  <Popconfirm
                    title="Are you sure you want to delete this customer?"
                    onConfirm={() => onDeleteCustomer(customer.id)}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button size="middle" icon={<DeleteOutlined />} danger style={{ marginLeft: '15px' }}>
                      Delete
                    </Button>
                  </Popconfirm>
                */}
                </div>
              </TabPane>
            ))}
          </Tabs>
        </Spinner>
      </div>
      {CustomerModal}
      {customAttributeModal}
      {siteModal}
    </>
  );
};

export default CustomersContainer;
