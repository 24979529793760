import React, { FC } from 'react';

import { Modal } from 'antd';

interface IdleLogoutWarningModalProps {
  visible: boolean;
  onStayLoggedIn: () => void;
  onLogout: () => Promise<void>;
}

const IdleLogoutWarningModal: FC<IdleLogoutWarningModalProps> = ({ visible, onStayLoggedIn, onLogout }) => (
  <Modal
    visible={visible}
    closable={false}
    maskClosable={false}
    centered
    okText="Stay Logged In"
    cancelText="Log Me Out"
    onOk={onStayLoggedIn}
    onCancel={async () => {
      await onLogout();
    }}
    zIndex={1100}
  >
    <p>For your security, you are about to be logged out due to inactivity.</p>
  </Modal>
);

export default IdleLogoutWarningModal;
