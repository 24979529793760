import { httpClient } from 'services/index';
import { PagingRequest } from 'utils/types';

import { CreateProject, GetProject, GetProjects, UpdateProject } from './types';

export const loadProjects = (payload: Record<string, any>, paging: PagingRequest): Promise<any> =>
  httpClient.get('/projects', { params: { ...payload, ...paging } }).then((_) => _.data);

export const getProjects: GetProjects = (includeCustomer, paging) =>
  httpClient
    .get('/projects', { params: { ...paging }, headers: includeCustomer ? { 'tasso-hydration': 'customer' } : {} })
    .then((_) => _.data);

export const getProject: GetProject = (id, hydrateOptions) =>
  httpClient
    .get(
      `/projects/${id}`,
      hydrateOptions.length > 0 ? { headers: { 'tasso-hydration': hydrateOptions.join(',') } } : undefined
    )
    .then((_) => _.data.results);

export const createProject: CreateProject = (data) => httpClient.post('/projects', data).then((_) => _.data.results);

export const updateProject: UpdateProject = (id, data) =>
  httpClient
    .patch(`/projects/${id}`, data, {
      headers: { 'tasso-hydration': 'customer,deviceShippedEmailTemplate,deviceShippedSmsTemplate' },
    })
    .then((_) => _.data.results);
