import React, { FC, useEffect } from 'react';

import { Select, Button, Typography, Row, Col, Space } from 'antd';

import { PlusCircleOutlined } from 'components/icons';
import { Laboratory, useLaboratories } from 'context/laboratories';
import useLaboratoryModal from 'hooks/useLaboratoryModal';

const { Option } = Select;

const { Text } = Typography;

interface ILaboratorySelectField {
  onChange: (laboratory: Laboratory) => void;
  isDisabled: boolean;
  selectOnly: boolean;
  selectionId?: string | null;
}

const LaboratorySelectField: FC<ILaboratorySelectField> = ({ onChange, isDisabled, selectOnly, selectionId }) => {
  const { laboratories, getLaboratories, loading } = useLaboratories();
  const { Modal: LaboratoryModal, onCreateLaboratory, laboratoryModalPopulated } = useLaboratoryModal();

  useEffect(() => {
    // load initially
    getLaboratories();
  }, []);

  useEffect(() => {
    // reload when new labs are added
    if (laboratoryModalPopulated) {
      getLaboratories();
    }
  }, [laboratoryModalPopulated]);

  const handleChange = (selectedLabId: string): void => {
    const matchedLab = laboratories.find((lab) => lab.id === selectedLabId);
    if (matchedLab) {
      onChange(matchedLab);
    }
  };

  return (
    <div className="SelectField">
      <Space direction="vertical">
        {!selectOnly && <Text strong> Testing Laboratory</Text>}

        <Row>
          <Col span={selectOnly ? 24 : 20}>
            <Select
              data-testid="select-lab"
              showSearch
              placeholder="Type or select laboratory"
              optionFilterProp="children"
              onChange={handleChange}
              loading={loading}
              showArrow
              disabled={isDisabled}
              value={selectionId || undefined}
            >
              {laboratories.map((laboratory: Laboratory) => (
                <Option key={laboratory.id} value={laboratory.id}>
                  {laboratory.name}
                </Option>
              ))}
            </Select>
          </Col>
          {!selectOnly && (
            <Col span={4}>
              <Button type="link" onClick={onCreateLaboratory}>
                <PlusCircleOutlined />
              </Button>
            </Col>
          )}
        </Row>
      </Space>

      {LaboratoryModal}
    </div>
  );
};

export default LaboratorySelectField;
