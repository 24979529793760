import React, { useState, useContext } from 'react';

// Context
const OneClickPrintContext = React.createContext<{
  isOneClickPrintEnabled: boolean;
  toggleOneClickPrintEnabled: () => void;
}>({
  isOneClickPrintEnabled: true,
  toggleOneClickPrintEnabled: () => {},
});

// Provider
const OneClickPrintStatusProvider = ({ children }: { children: React.ReactElement }) => {
  const [isOneClickPrintEnabled, setOneClickPrintEnabled] = useState(false);

  const toggleOneClickPrintEnabled = () => {
    setOneClickPrintEnabled(!isOneClickPrintEnabled);
  };

  return (
    <OneClickPrintContext.Provider value={{ isOneClickPrintEnabled, toggleOneClickPrintEnabled }}>
      {children}
    </OneClickPrintContext.Provider>
  );
};

const useOneClickPrint = () => useContext(OneClickPrintContext);

export { useOneClickPrint, OneClickPrintStatusProvider };
