import React, { useState, useRef } from 'react';

import { Input, Space, Button, Tooltip } from 'antd';
import { ColumnType } from 'antd/lib/table';
import Highlighter from 'react-highlight-words';

import { SearchOutlined, InfoCircleOutlined } from 'components/icons';

const useColumnSearch = <T extends unknown>() => {
  type ColumnKey = keyof T;

  const searchInputRef = useRef<Input>(null);
  const [searchText, setSearchText] = useState<string>('');
  const [searchedColumn, setSearchedColumn] = useState<ColumnKey | null>(null);

  const handleSearch = (selectedKeys: React.Key[], confirm: () => void, dataIndex: ColumnKey): void => {
    confirm();
    setSearchText(selectedKeys[0].toString());
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters?: () => void): void => {
    setSearchText('');
    setSearchedColumn(null);
    if (clearFilters) {
      clearFilters();
    }
  };

  const getColumnSearchProps = (
    searchColumn: ColumnKey,
    tooltipColumn: ColumnKey | null = null,
    withHighlighter = true
  ): ColumnType<T> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInputRef}
          placeholder="Search..."
          value={selectedKeys[0] || ''}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, searchColumn)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
          data-testid="column-search_input"
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, searchColumn)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
            data-testid="column-search_submit-button"
          >
            Search
          </Button>

          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
            data-testid="column-search_reset-button"
          >
            Reset
          </Button>
        </Space>
      </div>
    ),

    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,

    onFilter: (value, record) =>
      record[searchColumn] ? `${record[searchColumn]}`.toLowerCase().includes(`${value}`.toLowerCase()) : false,

    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          if (searchInputRef && searchInputRef.current) {
            searchInputRef.current.select();
          }
        });
      }
    },

    render: (_row, record) => {
      const text = `${record[searchColumn]}` || '';

      if (tooltipColumn) {
        return (
          <Tooltip placement="bottom" title={record[tooltipColumn]}>
            <>
              {searchedColumn === searchColumn && text !== '' ? (
                <Highlighter
                  highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                  searchWords={[searchText]}
                  autoEscape
                  textToHighlight={text}
                />
              ) : (
                text
              )}{' '}
              {text && <InfoCircleOutlined />}
            </>
          </Tooltip>
        );
      }

      return withHighlighter && searchedColumn === searchColumn && text !== '' ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text}
        />
      ) : (
        text
      );
    },
  });

  return { getColumnSearchProps };
};

export default useColumnSearch;
