import React, { FC, useEffect, useState } from 'react';

import { Table, Button, Card, Popconfirm, TablePaginationConfig, Space, Dropdown, Menu } from 'antd';

import { EditOutlined, PlusCircleOutlined, DeleteOutlined, ReloadOutlined, ClearOutlined } from 'components/icons';
import { Spinner } from 'components/index';
import { GetIdPoolsParams, useIdPools } from 'context/idPools';
import { useCustomers } from 'context/customers';
import { useProjectIdPoolLinks } from 'context/projectIdPoolLinks';
import useIdPoolModal from 'hooks/useIdPoolModal';
import { ColumnsType, FilterValue, SorterResult } from 'antd/lib/table/interface';
import useIntegratedColumnSearch from 'hooks/useIntegratedColumnSearch';
import { Link } from 'react-router-dom';
import { Project } from 'context/projects';
import { useAuth } from 'context/auth';
import { USER_ROLES } from 'constants/index';
import { FetchDataParameters, PagingConfig, SearchableColumnKey, SearchConfig, TableRecord } from './types';

const DEFAULTS: FetchDataParameters = {
  page: 1,
  pageLength: 100,
  sortBy: 'createdAt',
  sortOrder: 'desc',
  search: null,
  includeTotalCount: true,
};

const IdPoolsContainer: FC = () => {
  const { profile } = useAuth();
  const isAdmin = (profile?.role || '') === USER_ROLES.internalAdmin;

  const { getIdPools, idPools, resetIdPools, deleteIdPool, loading: idPoolsLoading } = useIdPools();
  const { customers, getCustomers } = useCustomers();
  const { projectIdPoolLinks, getProjectIdPoolLinks, resetProjectIdPoolLinks } = useProjectIdPoolLinks();

  const { Modal: IdPoolModal, onCreateIdPool, onEditIdPool } = useIdPoolModal();

  const { getColumnSearchProps } = useIntegratedColumnSearch<TableRecord>();

  const [fetchParams, setFetchParams] = useState<FetchDataParameters>({
    ...DEFAULTS,
  });

  const [tablePagination, setTablePagination] = useState<TablePaginationConfig>({
    current: 0,
    defaultCurrent: fetchParams.page,
    defaultPageSize: fetchParams.pageLength,
    pageSizeOptions: ['10', '20', '50', '100'],
    hideOnSinglePage: false,
    showSizeChanger: true,
    showQuickJumper: true,
    showTotal: (n) => `${n} Id Pool${n === 1 ? '' : 's'}`,
  });

  const [sorting, setSorting] = useState<{
    key: string;
    order: 'ascend' | 'descend';
  }>({
    key: 'createdAt',
    order: 'descend',
  });

  const [totalIdPoolCount, setTotalIdPoolCount] = useState(0);

  const [resetFitlers, setResetFitlers] = useState(false);

  const [linksHash, setLinksHash] = useState<Record<string, number[]>>({});

  const fetchData = async (params: FetchDataParameters): Promise<void> => {
    const { page, pageLength, sortBy, sortOrder, includeTotalCount } = params;
    const reqParams: GetIdPoolsParams = { page, pageLength, sortBy, sortOrder, includeTotalCount };

    if (params.search !== null) {
      const { search } = params;

      if (search.by === 'name') {
        reqParams.nameLike = search.term;
      }

      if (search.by === 'customer' && search.term) {
        reqParams.customerIds = search.term.split(',');
      }
    }

    await getIdPools(reqParams, { hydrate: ['customer'] }, async (loadedPools, pagingInfo) => {
      if (!loadedPools) {
        resetIdPools();
        return;
      }

      if (params.includeTotalCount && pagingInfo) {
        setTotalIdPoolCount(pagingInfo.totalCount);
      }

      if (loadedPools.length > 0) {
        const idPoolIds = loadedPools.map((pool) => pool.id);
        getProjectIdPoolLinks({ idPoolIds }, { hydrate: ['project'] });
      } else {
        resetProjectIdPoolLinks();
      }
    });
  };

  const updatePaging = (params: PagingConfig): void => {
    const pageChanged = params.page !== fetchParams.page;
    const pageLengthChanged = params.pageLength !== fetchParams.pageLength;
    const sortChanged = params.sortBy !== fetchParams.sortBy || params.sortOrder !== fetchParams.sortOrder;
    const searchChanged = JSON.stringify(params.search) !== JSON.stringify(fetchParams.search);

    const configChanged = pageChanged || pageLengthChanged || sortChanged || searchChanged;
    const updateTotal = pageLengthChanged || sortChanged || searchChanged;

    if (configChanged) {
      if (updateTotal) {
        setFetchParams((s) => ({ ...s, ...params, page: 1, includeTotalCount: updateTotal }));
      } else {
        setFetchParams((s) => ({ ...s, ...params }));
      }
    }
  };

  const handleTableChange = (
    paginationParams: TablePaginationConfig,
    filterParams: Record<string, FilterValue | null>,
    sorterParams: SorterResult<TableRecord> | SorterResult<TableRecord>[]
  ): void => {
    const page = paginationParams.current!;
    const pageLength = paginationParams.pageSize!;

    const sorter = sorterParams as SorterResult<TableRecord>;

    let sortBy: string;
    let sortOrder: 'asc' | 'desc';

    if (typeof sorter.order !== 'undefined') {
      sortBy = sorter.columnKey as string;
      sortOrder = sorter.order === 'ascend' ? 'asc' : 'desc';
    } else {
      sortBy = 'createdAt';
      sortOrder = 'desc';
    }

    setTablePagination((s) => ({ ...s, current: page, pageSize: pageLength }));
    setSorting({ key: sortBy, order: sortOrder === 'asc' ? 'ascend' : 'descend' });

    // ++ search
    let search: SearchConfig | null;
    const activeFilters = Object.keys(filterParams).filter((k) => filterParams[k] !== null);
    if (activeFilters.length > 1) {
      // allow only one filter at a time
      if (fetchParams.search !== null) {
        const alreadyExecutedFilterIndex = activeFilters.findIndex((f) => f === fetchParams.search!.by);
        if (alreadyExecutedFilterIndex > -1) {
          activeFilters.splice(alreadyExecutedFilterIndex, 1);
        }
      }
    }

    const newActiveFilterName = activeFilters.length === 1 ? activeFilters[0] : null;

    if (newActiveFilterName !== null) {
      const value = filterParams[newActiveFilterName] === null ? '' : filterParams[newActiveFilterName]!.join(',');
      search = {
        by: newActiveFilterName as SearchableColumnKey,
        term: value,
      };
    } else {
      search = null;
    }
    // -- search

    updatePaging({ page, pageLength, sortBy, sortOrder, search });
  };

  const resetPagination = () => {
    updatePaging({
      page: tablePagination.defaultCurrent!,
      pageLength: tablePagination.defaultPageSize!,
      sortBy: 'createdAt',
      sortOrder: 'desc',
      search: null,
    });

    setTablePagination((s) => ({
      ...s,
      current: tablePagination.defaultCurrent!,
      pageSize: tablePagination.defaultPageSize!,
    }));
  };

  const reloadData = async (): Promise<void> => {
    await fetchData(fetchParams);
  };

  const filterFields = (key: string): { filtered?: boolean; filteredValue?: FilterValue | null } => {
    if (fetchParams.search !== null && fetchParams.search.by === key) {
      return { filtered: true, filteredValue: fetchParams.search.term.split(',') };
    }

    return { filtered: false, filteredValue: null };
  };

  const columns: ColumnsType<TableRecord> = [
    {
      key: 'name',
      title: 'Name',
      dataIndex: 'name',
      sorter: true,
      sortOrder: sorting.key === 'name' ? sorting.order : undefined,
      ...getColumnSearchProps(fetchParams.search, 'name', 'search'),
      render: (_row, record) => <Link to={`/settings/id-pools/${record.id}`}>{record.name}</Link>,
    },
    {
      key: 'customer',
      title: 'Customer',
      dataIndex: ['customer', 'name'],
      filters: customers.map((c) => ({ text: c.name, value: c.id })),
      ...filterFields('customer'),
    },
    {
      key: 'projects',
      title: 'Projects',
      render: (_, record) => {
        if (record.projects.length === 0) {
          return '';
        }

        return (
          <Dropdown
            overlay={
              <Menu>
                {record.projects
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((p) => (
                    <Menu.Item key={p.id}>
                      <Link to={`/projects/${p.id}/details`} target="_blank">
                        {p.name}
                      </Link>
                    </Menu.Item>
                  ))}
              </Menu>
            }
          >
            <span className="expandProjects">
              {record.projects.length} project{record.projects.length === 1 ? '' : 's'}
            </span>
          </Dropdown>
        );
      },
    },
    isAdmin
      ? {
          title: 'Actions',
          key: 'actions',
          className: 'IdPools_actions',
          width: 90,
          render: (_row: any, record) => (
            <>
              <EditOutlined className="EditAction" onClick={() => onEditIdPool(record)} />
              {record.projects.length === 0 ? (
                <Popconfirm
                  disabled={record.projects.length > 0}
                  title="Are you sure you want to delete this Id Pool?"
                  okText="Yes"
                  cancelText="No"
                  onConfirm={() => deleteIdPool(record.id, {}, async () => reloadData())}
                >
                  <DeleteOutlined className="DeleteAction" />
                </Popconfirm>
              ) : (
                <DeleteOutlined
                  className="DeleteAction disabled"
                  title="Id Pool is linked to project(s) and cannot be deleted"
                />
              )}
            </>
          ),
        }
      : {},
  ];
  // Hide empty columns if they exist
  columns.filter((column) => Object.keys(column).length > 0);

  const getProjectsByIdPoolId = (idPoolId: string): Project[] => {
    const indices = idPoolId in linksHash ? linksHash[idPoolId] : [];

    const result: Project[] = [];

    for (let i = 0; i < indices.length; i += 1) {
      const linkIndexInArray = indices[i];
      if (projectIdPoolLinks[linkIndexInArray]) {
        result.push(projectIdPoolLinks[linkIndexInArray].project!);
      }
    }

    return result;
  };

  // if fetch configuration changes, fetch data again
  useEffect(() => {
    fetchData(fetchParams);
  }, [JSON.stringify(fetchParams)]);

  // if total count change, update pagination in the table
  useEffect(() => {
    setTablePagination((s) => ({ ...s, total: totalIdPoolCount }));
  }, [totalIdPoolCount]);

  // if page or page length change, update table pagination
  useEffect(() => {
    setTablePagination((s) => ({ ...s, current: fetchParams.page, pageSize: fetchParams.pageLength }));
  }, [fetchParams.page, fetchParams.pageLength]);

  useEffect(() => {
    setSorting({ key: 'createdAt', order: 'descend' });
    setFetchParams({ ...DEFAULTS });
  }, [resetFitlers]);

  useEffect(() => {
    const hash: Record<string, number[]> = {};

    for (let i = 0; i < projectIdPoolLinks.length; i += 1) {
      const link = projectIdPoolLinks[i];
      if (link.idPoolId in hash) {
        hash[link.idPoolId].push(i);
      } else {
        hash[link.idPoolId] = [i];
      }
    }

    setLinksHash(hash);
  }, [projectIdPoolLinks]);

  useEffect(() => {
    getCustomers();
    resetPagination();
  }, []);

  return (
    <Card>
      <Space style={{ marginBottom: 15 }}>
        {isAdmin && (
          <Button type="primary" icon={<PlusCircleOutlined />} onClick={() => onCreateIdPool(reloadData)}>
            New Id Pool
          </Button>
        )}

        <Button type="default" icon={<ReloadOutlined />} size="middle" onClick={reloadData}>
          Reload
        </Button>

        <Button type="default" icon={<ClearOutlined />} size="middle" onClick={() => setResetFitlers((s) => !s)}>
          Reset all filters
        </Button>
      </Space>

      {IdPoolModal}

      <Spinner spinning={idPoolsLoading}>
        <Table
          rowKey="id"
          columns={columns}
          dataSource={idPools.map((pool) => ({ ...pool, projects: getProjectsByIdPoolId(pool.id) }))}
          bordered
          size="small"
          pagination={tablePagination}
          onChange={handleTableChange}
          showSorterTooltip={false}
          className="IdPools"
        />
      </Spinner>
    </Card>
  );
};

export default IdPoolsContainer;
