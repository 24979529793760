import React, { FC, useState, createContext } from 'react';

import { kitItemsApi } from 'api';
import { loadAllPages } from 'utils/functions';
import * as issues from 'utils/issues';
import Notification from 'utils/notification';
import { IUpload } from './uploads';

const sortByDescription = (a: IKitItem, b: IKitItem): -1 | 0 | 1 => {
  if (a.description === b.description) {
    return 0;
  }
  return a.description > b.description ? 1 : -1;
};

export interface IKitItem {
  id: string;
  partNumber: string | null;
  description: string;
  key: string;
  lotNumber: string | null;
  kitItemType: string | null;
  createdAt: string;
  updatedAt: string | null;
  uploads?: IUpload[];
}

interface IKitItemsState {
  kitItems: IKitItem[];
  kitItem: IKitItem | null;
  loading: boolean;
  listLoaded: boolean;
  error: any;
}

interface Options {
  hydration?: string[];
}

type SingleResultCallback = (kitItem: IKitItem | null) => Promise<void> | void;
type MultipleResultsCallback = (kitItem: IKitItem[] | null) => Promise<void> | void;

type GetKitItemsFunction = (callback?: MultipleResultsCallback) => Promise<void>;
type GetKitItemFunction = (id: string, options?: Options, callback?: SingleResultCallback) => Promise<void>;
type CreateKitItemFunction = (data: kitItemsApi.ICreateKitItem, callback?: SingleResultCallback) => Promise<void>;
type UpdateKitItemFunction = (
  id: string,
  data: kitItemsApi.ICreateKitItem,
  callback?: SingleResultCallback
) => Promise<void>;
type DeleteKitItemFunction = (id: string, callback?: SingleResultCallback) => Promise<void>;

export interface IKitItemsContext extends IKitItemsState {
  getKitItems: GetKitItemsFunction;
  getKitItem: GetKitItemFunction;
  createKitItem: CreateKitItemFunction;
  updateKitItem: UpdateKitItemFunction;
  deleteKitItem: DeleteKitItemFunction;
  listLoaded: boolean;
}

const KitItemsContext = createContext<IKitItemsContext>({
  kitItems: [],
  kitItem: null,
  loading: false,
  listLoaded: false,
  error: null,
  getKitItems: async () => {},
  getKitItem: async () => {},
  createKitItem: async () => {},
  updateKitItem: async () => {},
  deleteKitItem: async () => {},
});

const KitItemsProvider: FC = (props: any) => {
  const [state, setState] = useState<IKitItemsState>({
    kitItems: [] as IKitItem[],
    kitItem: null as IKitItem | null,
    error: null,
    loading: false,
    listLoaded: false,
  });

  const getKitItems = async (callback: (items: IKitItem[] | null) => void): Promise<void> => {
    try {
      setState((s) => ({
        ...s,
        loading: true,
      }));

      const items = await loadAllPages<any, any>(
        kitItemsApi.getKitItems,
        {},
        { pageLength: 50, sortBy: 'createdAt', isDescending: false }
      );

      setState((s) => ({
        ...s,
        loading: false,
        kitItems: items.sort(sortByDescription),
        listLoaded: true,
      }));

      if (callback) {
        await callback(items);
      }
    } catch (e: any) {
      setState((s) => ({
        ...s,
        loading: false,
        error: issues.serializeForDisplay(e?.response?.data?.issues) || e.message,
      }));

      Notification({
        type: 'error',
        message: 'Cannot get kit items list',
        description: issues.serializeForDisplay(e?.response?.data?.issues) || e.message,
      });

      if (callback) {
        await callback(null);
      }
    }
  };

  const getKitItem: GetKitItemFunction = async (id, options = {}, callback?) => {
    setState((s) => ({
      ...s,
      loading: true,
    }));

    try {
      const hydration = options.hydration?.join(',');

      const item = await kitItemsApi.getKitItem(id, {
        hydration,
      });

      setState((s) => ({
        ...s,
        kitItem: item,
      }));

      if (callback) {
        await callback(item);
      }
    } catch (e: any) {
      const error = issues.getErrorMessage(e);

      setState((s) => ({
        ...s,
        error,
      }));

      Notification({
        type: 'error',
        message: 'Cannot get kit item',
        description: error,
      });

      if (callback) {
        await callback(null);
      }
    } finally {
      setState((s) => ({
        ...s,
        loading: false,
      }));
    }
  };

  const createKitItem = async (
    data: kitItemsApi.ICreateKitItem,
    callback: (newItem: IKitItem | null) => void
  ): Promise<void> => {
    setState((s) => ({
      ...s,
      loading: true,
    }));

    try {
      const item = await kitItemsApi.createKitItem(data);

      setState((s) => ({
        ...s,
        loading: false,
        kitItems: [...s.kitItems, item].sort(sortByDescription),
      }));

      if (callback) {
        callback(item);
      }
    } catch (e: any) {
      console.log(e);

      setState((s) => ({
        ...s,
        loading: false,
        error: issues.serializeForDisplay(e?.response?.data?.issues) || e.message,
      }));

      Notification({
        type: 'error',
        message: 'Kit item was not created',
        description: issues.serializeForDisplay(e?.response?.data?.issues) || e.message,
      });

      if (callback) {
        callback(null);
      }
    }
  };

  const updateKitItem = async (
    id: string,
    data: Partial<kitItemsApi.ICreateKitItem>,
    callback: (newItem: IKitItem | null) => void
  ): Promise<void> => {
    setState((s) => ({
      ...s,
      loading: true,
    }));

    try {
      const updatedItem = await kitItemsApi.updateKitItem(id, data);

      const items = [...state.kitItems];
      const foundItemIndex = items.findIndex((item) => item.id === id);
      if (foundItemIndex > -1) {
        items[foundItemIndex] = updatedItem;
      }

      setState((s) => ({
        ...s,
        loading: false,
        kitItems: items,
      }));

      if (callback) {
        await callback(updatedItem);
      }
    } catch (e: any) {
      setState((s) => ({
        ...s,
        loading: false,
        error: issues.serializeForDisplay(e?.response?.data?.issues) || e.message,
      }));

      Notification({
        type: 'error',
        message: 'Kit item was not updated',
        description: issues.serializeForDisplay(e?.response?.data?.issues) || e.message,
      });

      if (callback) {
        callback(null);
      }
    }
  };

  const deleteKitItem = async (id: string, callback: (newItem: IKitItem | null) => void): Promise<void> => {
    setState((s) => ({
      ...s,
      loading: true,
    }));

    try {
      const deletedItem = await kitItemsApi.deleteKitItem(id);

      const items = [...state.kitItems].filter((item) => item.id !== id);

      setState((s) => ({
        ...s,
        loading: false,
        kitItems: items,
      }));

      if (callback) {
        await callback(deletedItem);
      }
    } catch (e: any) {
      setState((s) => ({
        ...s,
        loading: false,
        error: issues.serializeForDisplay(e?.response?.data?.issues) || e.message,
      }));

      Notification({
        type: 'error',
        message: 'Kit item was not deleted',
        description: issues.serializeForDisplay(e?.response?.data?.issues) || e.message,
      });

      if (callback) {
        await callback(null);
      }
    }
  };

  return (
    <KitItemsContext.Provider
      value={{
        kitItems: state.kitItems,
        kitItem: state.kitItem,
        loading: state.loading,
        listLoaded: state.listLoaded,
        error: state.error,
        getKitItems,
        getKitItem,
        createKitItem,
        updateKitItem,
        deleteKitItem,
      }}
      {...props}
    />
  );
};

const useKitItems = (): IKitItemsContext => React.useContext(KitItemsContext);

export { KitItemsProvider, useKitItems };
