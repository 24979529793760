import React, { FC, useEffect } from 'react';

import { Tabs } from 'antd';

import { PlusOutlined } from 'components/icons';
import { LaboratoryInformation, Spinner } from 'components/index';
import { Laboratory, useLaboratories } from 'context/laboratories';
import useLaboratoryModal from 'hooks/useLaboratoryModal';
import { useAuth } from 'context/auth';
import { USER_ROLES } from 'constants/index';

const { TabPane } = Tabs;

const LaboratoriesContainer: FC = () => {
  const { profile } = useAuth();
  const isAdmin = (profile?.role || '') === USER_ROLES.internalAdmin;

  const {
    getLaboratories,
    deleteLaboratory,
    getLaboratory,
    laboratories,
    loading: laboratoriesLoading,
  } = useLaboratories();
  const { Modal: LaboratoryModal, onEditLaboratory, onCreateLaboratory } = useLaboratoryModal();

  useEffect(() => {
    getLaboratories();
  }, []);

  const handleTabEdit = (_targetKey: any, action: any) => {
    if (action === 'add') {
      onCreateLaboratory();
    }
  };

  const handleDeleteLaboratory = (id: any) => {
    deleteLaboratory(id);
  };

  const handleEditLaboratory = (lab: Laboratory) => {
    onEditLaboratory(lab);
  };

  const refreshLab = async (labId: string, callback?: (lab: Laboratory | null) => void): Promise<void> => {
    await getLaboratory(labId, { withReqFormUrl: true }, callback);
  };

  return (
    <div className="Laboratories">
      <Spinner spinning={laboratoriesLoading}>
        <Tabs
          tabPosition="left"
          size="large"
          type="editable-card"
          onEdit={handleTabEdit}
          addIcon={
            <>
              {isAdmin && (
                <div>
                  <PlusOutlined /> Add laboratory
                </div>
              )}
            </>
          }
        >
          {laboratories.map((lab: Laboratory) => (
            <TabPane tab={lab.name} key={lab.id}>
              <LaboratoryInformation
                laboratory={lab}
                refreshLab={refreshLab}
                onEdit={() => handleEditLaboratory(lab)}
                onRemove={() => handleDeleteLaboratory(lab.id)}
              />
            </TabPane>
          ))}
        </Tabs>
      </Spinner>

      {LaboratoryModal}
    </div>
  );
};

export default LaboratoriesContainer;
