import { Form, Input, Select } from 'antd';
import { Rule } from 'antd/lib/form';
import { ICustomAttributeDefinition } from 'context/customAttributeDefinitions';
import { CustomAttributeDefinitionProject, useProjects } from 'context/projects';
import React, { useEffect, useState } from 'react';
import { getSelectedCustomAttributes } from 'utils/customAttributeDefinitions';

interface Props {
  attributeDefinitions: Map<string, ICustomAttributeDefinition[] | CustomAttributeDefinitionProject[]>;
  selectedOrderTemplateId: string | null;
  useOrderTemplates: boolean;
}

export const CUSTOM_ATTRIBUTE_FIELD_PREFIX = 'order:';

export const getValidationRules = (definition: CustomAttributeDefinitionProject): Rule[] => {
  const fieldLabel = definition.displayName ?? definition.name;
  const maxLength = definition.maxLength ?? 1000;

  const rules: Rule[] = [
    {
      max: maxLength,
      message: `Maximum length for ${fieldLabel} is ${maxLength}`,
    },
  ];

  if (definition.required) {
    rules.push({
      required: true,
      message: `${fieldLabel} is required`,
    });
  }

  return rules;
};

const CustomAttributesEntry: React.FC<Props> = ({ attributeDefinitions, selectedOrderTemplateId }) => {
  const { project } = useProjects();
  const [customAttributeDefinitions, setCustomAttributeDefinitions] = useState<
    ICustomAttributeDefinition[] | CustomAttributeDefinitionProject[]
  >([]);

  useEffect(() => {
    const definitions = getSelectedCustomAttributes(selectedOrderTemplateId, project, attributeDefinitions);

    setCustomAttributeDefinitions(definitions);
  }, [selectedOrderTemplateId]);

  const generateCustomAttributeElement = (customAttributeDefinition: CustomAttributeDefinitionProject) => {
    const definitionNameWithPrefix = `${CUSTOM_ATTRIBUTE_FIELD_PREFIX}${customAttributeDefinition.name}`;

    if (customAttributeDefinition.allowedValues && customAttributeDefinition.allowedValues.length > 0) {
      return (
        // Drop down selection attribute
        <Form.Item
          label={customAttributeDefinition.displayName ?? customAttributeDefinition.name}
          name={definitionNameWithPrefix}
          key={definitionNameWithPrefix}
          rules={getValidationRules(customAttributeDefinition)}
        >
          <Select
            options={[''].concat(customAttributeDefinition.allowedValues).map((category) => ({
              label: category,
              value: category,
            }))}
            style={{
              width: '100%',
            }}
          />
        </Form.Item>
      );
    }

    // Free text entry attribute
    return (
      <Form.Item
        label={customAttributeDefinition.displayName ?? customAttributeDefinition.name}
        name={definitionNameWithPrefix}
        key={definitionNameWithPrefix}
        rules={getValidationRules(customAttributeDefinition)}
      >
        <Input />
      </Form.Item>
    );
  };

  if (customAttributeDefinitions.length === 0) {
    return null;
  }

  return (
    <>
      <h3>Order Attributes</h3>

      {customAttributeDefinitions.map((d) => generateCustomAttributeElement(d))}
    </>
  );
};

export default CustomAttributesEntry;
