import React, { FC } from 'react';

import { Form, Input, Button, Typography } from 'antd';
import { useHistory } from 'react-router-dom';

import { UserOutlined, LockOutlined } from 'components/icons';
import { ROUTES } from 'constants/routes';

interface ILoginForm {
  loading: boolean;
  onSubmit: (email: string, password: string) => void;
}

const { Title } = Typography;

const LoginForm: FC<ILoginForm> = ({ loading, onSubmit }) => {
  const [form] = Form.useForm<{ email: string; password: string }>();
  const history = useHistory();

  const navigateToResetPassword = () => {
    history.push(ROUTES.resetPassword);
  };

  const handleFormSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        onSubmit(values.email.toLowerCase().trim(), values.password);
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
  };

  return (
    <Form
      className="Login__Form"
      form={form}
      layout="vertical"
      name="LoginForm"
      hideRequiredMark
      initialValues={{ email: '', password: '' }}
      onFinish={handleFormSubmit}
    >
      <Title level={4} type="secondary">
        Login
      </Title>

      <Form.Item name="email" rules={[{ required: true, whitespace: true, message: 'Please input your email!' }]}>
        <Input prefix={<UserOutlined />} placeholder="Email" />
      </Form.Item>

      <Form.Item
        name="password"
        rules={[
          {
            required: true,
            // Don't put the _real_ min or max values here to avoid revealing too much
            // information about authentication to a potential attacker.
            // We know at least 1 character is needed, that's all we can safely document here.
            min: 1,
            message: 'Please input your password!',
          },
        ]}
      >
        <Input.Password prefix={<LockOutlined />} placeholder="Password" />
      </Form.Item>

      <Form.Item>
        <Button block type="primary" htmlType="submit" loading={loading}>
          Log in
        </Button>
      </Form.Item>

      <Form.Item>
        <Button block type="link" onClick={navigateToResetPassword}>
          Forgot password
        </Button>
      </Form.Item>
    </Form>
  );
};

export default LoginForm;
