import React, { FC } from 'react';

import { Breadcrumb as ABreadcrumb } from 'antd';
import { Link, useLocation } from 'react-router-dom';

interface IBreadcrumb {
  breadcrumbNameMap: any;
}

const Breadcrumb: FC<IBreadcrumb> = ({ breadcrumbNameMap }) => {
  const location = useLocation();
  const pathSnippets = ['', ...location.pathname.split('/').filter((i) => !!i)];

  const breadcrumbItems = pathSnippets.map((_, index) => {
    const url = `/${pathSnippets
      .slice(0, index + 1)
      .filter((p) => p !== '')
      .join('/')}`;
    if (!breadcrumbNameMap[url]) {
      return null;
    }
    return (
      <ABreadcrumb.Item key={url}>
        <Link to={url}>{breadcrumbNameMap[url]}</Link>
      </ABreadcrumb.Item>
    );
  });

  return <ABreadcrumb>{breadcrumbItems}</ABreadcrumb>;
};

export default Breadcrumb;
