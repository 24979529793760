import { httpClient } from 'services/index';
import { PagingRequest } from 'utils/types';

export const getManifests = (payload: Record<string, any>, paging: PagingRequest): Promise<any> =>
  httpClient.get(`/shippingManifests`, { params: { ...payload, ...paging } }).then((_) => _.data);

export interface ICreateManifest {
  shipperType: string;
  origin: string;
  shipDate: string;
}
export const createManifest = (data: ICreateManifest): Promise<any> =>
  httpClient.post('/shippingManifests', data).then((_) => _.data.results);

export const getManifestById = (manifestId: string): Promise<any> =>
  httpClient.get(`/shippingManifests/${manifestId}`, { params: {} }).then((_) => _.data);

export const getShippingLabelsByOpenManifestDate = (shipDate: string, origin: string): Promise<any> =>
  httpClient
    .get(
      `/shippingLabels?toPatientManifestIdNull=true&toPatientShipDate=${shipDate}&toPatientShipOrigin=${origin}&includeTotalCount=true`,
      {
        params: {},
      }
    )
    .then((_) => _.data);
