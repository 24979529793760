import React, { useState } from 'react';

import { Form } from 'antd';

import IdPoolIdentifierUploadModal from 'components/IdPoolIdentifierUploadModal';
import { IdPoolIdentifierImport, useIdPoolIdentifierImports } from 'context/idPoolIdentifierImports';
import { uploadFileToS3, waitForUploadedFile } from 'utils/fileUploading';
import { RcFile } from 'antd/lib/upload';
import Notification from 'utils/notification';

type FinishedCallback = () => Promise<void>;

const useIdPoolIdentifierUploadModal = () => {
  const { createIdPoolIdentifierImport, getIdPoolIdentifierImport } = useIdPoolIdentifierImports();
  const [form] = Form.useForm();
  const [modalVisible, setModalVisible] = useState(false);
  const [working, setWorking] = useState(false);
  const [finishedCallback, setFinishedCallback] = useState<FinishedCallback | null>(null);
  const [idPoolId, setIdPoolId] = useState<string>('');

  const openModal = () => {
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
    form.resetFields();
    setIdPoolId('');
    setFinishedCallback(null);
  };

  const callback = () => {
    setWorking(false);
    closeModal();
  };

  const handleUploadIdPoolIdentifiers = async (file: RcFile) => {
    setWorking(true);

    const job = await new Promise<IdPoolIdentifierImport>((resolve) => {
      createIdPoolIdentifierImport({ idPoolId, fileName: file.name }, { skipStateUpdate: true }, (newItem) => {
        resolve(newItem!);
      });
    });

    await uploadFileToS3(job.uploadInfo!, file);

    let importSucceeded = true;

    await waitForUploadedFile(async () => {
      const result = await new Promise<IdPoolIdentifierImport>((resolve) => {
        getIdPoolIdentifierImport(job.id, { skipStateUpdate: true }, (item) => {
          resolve(item!);
        });
      });

      if (result.status === 'ERROR' || result.status === 'FAULT') {
        let errorName = 'Error';
        let errorMessage = '';

        // Check for validation errors
        try {
          const json = JSON.parse(result.errorDetails!);

          if (Array.isArray(json)) {
            errorName = 'File validation error';
            errorMessage = json.map((err: any) => err.message).join('\n\n');
          } else {
            errorMessage = result.errorDetails!;
          }
        } catch (e: any) {
          console.error(e);
          errorMessage = e.message;
        }

        Notification({
          type: 'error',
          message: errorName,
          description: errorMessage,
          duration: 10,
        });

        importSucceeded = false;
      }

      // Any status other than "CREATED" means the file has been processed.
      // Whether the upload failed or succeeded, it still means that the
      // upload is "finished".
      return result.status !== 'CREATED';
    });

    if (importSucceeded) {
      if (finishedCallback) {
        await finishedCallback();
      }

      callback();
    } else {
      setWorking(false);
    }
  };

  const onUploadIdPoolIdentifiers = (newIdPoolId: string, cb?: FinishedCallback) => {
    form.setFieldsValue({});
    setIdPoolId(newIdPoolId);
    setFinishedCallback(cb ? () => () => cb() : null);
    openModal();
  };

  const Modal = (
    <IdPoolIdentifierUploadModal
      form={form}
      visible={modalVisible}
      onOk={handleUploadIdPoolIdentifiers}
      onCancel={closeModal}
      isSubmitting={working}
    />
  );

  return { Modal, onUploadIdPoolIdentifiers };
};

export default useIdPoolIdentifierUploadModal;
