import React, { FC, useMemo, useCallback } from 'react';

import { Table, Switch, Button } from 'antd';

import { ALL_USER_ROLES } from 'constants/index';
import { ProjectContact } from 'context/projectContacts/types';
import { User } from 'context/users/types';
import { getDateTime, getUnixTimestamp } from 'utils/date';

type Props = {
  contacts: User[];
  projectContacts: ProjectContact[];
  createContactLink: (userId: string) => void;
  deleteContactLink: (linkId: string) => void;
  editUser: (user: User) => void;
};

const ContactList: FC<Props> = ({ contacts, projectContacts, createContactLink, deleteContactLink, editUser }) => {
  const userIdHash = useMemo(
    () => projectContacts.reduce((acc, val, idx) => ({ ...acc, [val.userId]: idx }), {} as Record<string, number>),
    [projectContacts]
  );

  const getLink = useCallback(
    (contact: User): ProjectContact | null => {
      const idx = contact.id in userIdHash ? userIdHash[contact.id] : -1;
      return idx > -1 ? projectContacts[idx] : null;
    },
    [userIdHash]
  );

  return (
    <Table
      dataSource={[...contacts]}
      bordered
      size="small"
      columns={[
        {
          key: 'username',
          title: 'Email',
          dataIndex: 'username',
          sorter: (a: User, b: User) => a.username.localeCompare(b.username),
        },
        {
          key: 'firstName',
          title: 'First name',
          dataIndex: 'firstName',
          sorter: (a: User, b: User) => (a.firstName || '').localeCompare(b.firstName || ''),
          render: (value) => value || '',
        },
        {
          key: 'lastName',
          title: 'Last name',
          dataIndex: 'lastName',
          sorter: (a: User, b: User) => (a.lastName || '').localeCompare(b.lastName || ''),
          render: (value) => value || '',
        },
        {
          key: 'role',
          title: 'Role',
          dataIndex: 'role',
          sorter: (a: User, b: User) => (a.role || '').localeCompare(b.role || ''),
          render: (value) =>
            value && value in ALL_USER_ROLES ? ALL_USER_ROLES[value as keyof typeof ALL_USER_ROLES] || '' : '',
        },
        {
          key: 'added',
          title: 'Added to project',
          sorter: (a: User, b: User) => {
            const link1 = getLink(a);
            const link2 = getLink(b);

            const ts1 = link1 ? getUnixTimestamp(link1.createdAt) : 0;
            const ts2 = link2 ? getUnixTimestamp(link2.createdAt) : 0;

            if (ts1 === ts2) {
              return 0;
            }

            return ts1 > ts2 ? 1 : -1;
          },
          render: (user: User) => {
            const link = getLink(user);
            return link ? getDateTime(link.createdAt) : '';
          },
        },
        {
          key: 'projectAccess',
          title: 'Project access',
          align: 'center',
          width: 160,
          sorter: (a: User, b: User) => {
            const id1 = getLink(a)?.id || '';
            const id2 = getLink(b)?.id || '';
            return id1.localeCompare(id2);
          },
          render: (contact: User) => {
            const linkId = getLink(contact)?.id || null;
            return (
              <Switch
                checked={linkId !== null}
                onChange={() => (linkId ? deleteContactLink(linkId) : createContactLink(contact.id))}
              />
            );
          },
        },
        {
          key: 'actions',
          title: 'Actions',
          align: 'center',
          width: 70,
          render: (contact: User) => (
            <Button type="link" onClick={() => editUser(contact)}>
              Edit
            </Button>
          ),
        },
      ]}
      rowKey="id"
      pagination={false}
      showSorterTooltip={false}
    />
  );
};

export default ContactList;
