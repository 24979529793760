import React, { FC } from 'react';

import { Modal } from 'antd';

import { IDeviceLot } from 'context/deviceLots';
import { IDevice } from 'context/devices';
import { IPatient } from 'context/patients';
import { DeviceEvent } from 'utils/types';

import { IProject } from 'context/projects';
import { ILabOrderEvent } from 'context/labOrderEvents';
import { FulfillmentOrder } from '@tassoinc/core-api-client';
import DeviceInformation from '../DeviceInformation';

type Props = {
  device: IDevice;
  patient: IPatient | null;
  project: IProject | null;
  lot: IDeviceLot | null;
  visible: boolean;
  events: DeviceEvent[];
  labOrderEvents: ILabOrderEvent[];
  fulfillmentOrder: FulfillmentOrder | null;
  onCancel: () => void;
};

const WithoutResultForm: FC<Props> = ({
  device,
  patient,
  project,
  lot,
  visible,
  events,
  labOrderEvents,
  fulfillmentOrder,
  onCancel,
}) => (
  <Modal
    wrapClassName="Modal DeviceDetailsModal"
    visible={visible}
    title="Device Details"
    onCancel={onCancel}
    centered
    width={900}
    footer={null}
  >
    <DeviceInformation
      device={device}
      labOrder={device.labOrder}
      labOrderEvents={labOrderEvents}
      laboratory={device.laboratory}
      patient={patient}
      lot={lot}
      events={events}
      project={project}
      fulfillmentOrder={fulfillmentOrder}
    />
  </Modal>
);

export default WithoutResultForm;
