import { CancelToken } from 'axios';
import { handleResponseError, httpClient } from 'services/index';

export interface CreateCustomerSupportValue {
  projectId: string;
  patientSupportAllowed: boolean;
  patientSupportRedirect?: string | null;
  discussTestResults: boolean;
  logisticsSupport: boolean;
  deviceTroubleshooting: boolean;
  issueReplacements: boolean;
  contactName?: string | null;
  contactPhone?: string | null;
  contactEmail?: string | null;
}

export interface UpdateCustomerSupportValue {
  patientSupportAllowed?: boolean;
  patientSupportRedirect?: string | null;
  discussTestResults?: boolean;
  logisticsSupport?: boolean;
  deviceTroubleshooting?: boolean;
  issueReplacements?: boolean;
  contactName?: string | null;
  contactPhone?: string | null;
  contactEmail?: string | null;
}

export type CustomerSupportValueRequestOptions = {
  cancelToken?: CancelToken;
};

export const getCustomerSupportValue = (id: string, options?: CustomerSupportValueRequestOptions): Promise<any> =>
  httpClient
    .get(`/customerSupportValues/${id}`, options?.cancelToken ? { cancelToken: options.cancelToken } : {})
    .then((_) => _.data.results)
    .catch(handleResponseError);

export const createCustomerSupportValue = (
  data: CreateCustomerSupportValue,
  options?: CustomerSupportValueRequestOptions
): Promise<any> =>
  httpClient
    .post('/customerSupportValues', data, options?.cancelToken ? { cancelToken: options.cancelToken } : {})
    .then((_) => _.data.results);

export const updateCustomerSupportValue = (
  id: string,
  data: UpdateCustomerSupportValue,
  options?: CustomerSupportValueRequestOptions
): Promise<any> =>
  httpClient
    .patch(`/customerSupportValues/${id}`, data, options?.cancelToken ? { cancelToken: options.cancelToken } : {})
    .then((_) => _.data.results);
