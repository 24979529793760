import React, { FC, useEffect, useState } from 'react';

import { Button, Space } from 'antd';

import { PlusOutlined, ReloadOutlined, ClearOutlined } from 'components/icons';
import { Spinner } from 'components/index';
import { useCustomers } from 'context/customers';
import { useNotificationTemplates } from 'context/notificationTemplates';
import { EmailTemplate, SmsTemplate } from 'context/notificationTemplates/types';
import { useProjects } from 'context/projects';
import useNotificationTemplatesModal from 'hooks/useNotificationTemplatesModal';

import 'less/components/Users.less';
import { useAuth } from 'context/auth';
import { USER_ROLES } from 'constants/index';
import TemplateList from './TemplateList';

const UsersContainer: FC<any> = () => {
  const { profile } = useAuth();
  const isAdmin = (profile?.role || '') === USER_ROLES.internalAdmin;
  const {
    getTemplates,
    emailTemplates,
    smsTemplates,
    loading: notificationTemplatesLoading,
  } = useNotificationTemplates();
  const { getProjects, projects, loading: projectsLoading } = useProjects();
  const { getCustomers, customers, loading: customersLoading } = useCustomers();
  const {
    onCreateEmailTemplate,
    onCreateSmsTemplate,
    onDeleteTemplate,
    Modal: NotificationTemplatesModals,
    onEditClick,
  } = useNotificationTemplatesModal({
    customers,
  });
  const [resetFilters, setResetFitlers] = useState(false);

  const fetchData = (): void => {
    getProjects(false);
    getTemplates();
    getCustomers();
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="Users">
      <Space style={{ marginBottom: 15 }}>
        {isAdmin && (
          <Button type="primary" icon={<PlusOutlined />} size="middle" onClick={onCreateEmailTemplate}>
            Create email template
          </Button>
        )}
        {isAdmin && (
          <Button type="primary" icon={<PlusOutlined />} size="middle" onClick={onCreateSmsTemplate}>
            Create SMS template
          </Button>
        )}

        <Button type="default" icon={<ReloadOutlined />} size="middle" onClick={fetchData}>
          Reload
        </Button>

        <Button type="default" icon={<ClearOutlined />} size="middle" onClick={() => setResetFitlers((s) => !s)}>
          Reset all filters
        </Button>
      </Space>
      {NotificationTemplatesModals}
      <Spinner spinning={notificationTemplatesLoading || customersLoading || projectsLoading}>
        <TemplateList
          projects={projects}
          resetFilters={resetFilters}
          emailTemplates={emailTemplates}
          smsTemplates={smsTemplates}
          customers={customers}
          onEditClick={(template: EmailTemplate | SmsTemplate) => onEditClick(template)}
          onDeleteTemplate={onDeleteTemplate}
        />
      </Spinner>
    </div>
  );
};

export default UsersContainer;
