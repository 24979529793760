import { httpClient } from 'services/index';
import { PagingRequest } from 'utils/types';

export const getProjectKitItems = (projectId: string, paging: PagingRequest): Promise<any> =>
  httpClient
    .get('/projectKitItemLinks', {
      params: { projectIds: projectId, ...paging },
      headers: { 'tasso-hydration': 'kitItem' },
    })
    .then((_) => _.data);

export const getProjectKitItem = (id: string): Promise<any> =>
  httpClient
    .get(`/projectKitItemLinks/${id}`, { headers: { 'tasso-hydration': 'kitItem' } })
    .then((_) => _.data.results);

export interface ICreateProjectKitItemData {
  projectId: string;
  kitItemId: string;
  viewOrder: number;
}
export const createProjectKitItem = (data: ICreateProjectKitItemData): Promise<any> =>
  httpClient
    .post('/projectKitItemLinks', data, { headers: { 'tasso-hydration': 'kitItem' } })
    .then((_) => _.data.results);

export interface IUpdateProjectKitItemData {
  viewOrder?: number;
  quantity?: number;
}
export const updateProjectKitItem = (id: string, data: IUpdateProjectKitItemData): Promise<any> =>
  httpClient
    .patch(`/projectKitItemLinks/${id}`, data, { headers: { 'tasso-hydration': 'kitItem' } })
    .then((_) => _.data.results);

export const deleteProjectKitItem = (id: string): Promise<any> =>
  httpClient.delete(`/projectKitItemLinks/${id}`).then((_) => _.data.results);
