import React, { FC } from 'react';

import { Select, Typography, Space } from 'antd';

import {
  DISTRIBUTION_MODELS,
  PACKAGE1_TYPE_LABELS,
  PACKAGE2_TYPE_LABELS,
  Package1Type,
  Package2Type,
  DISTRIBUTION_MODEL_SOURCES,
} from 'constants/index';

const { Option } = Select;

const { Text } = Typography;

type PackageType = Package1Type | Package2Type;

interface Props {
  packageType: 'toPatient' | 'toLab';
  distributionModel: keyof typeof DISTRIBUTION_MODELS;
  value: PackageType;
  onChange: (speed: PackageType) => void;
}

const ShippingSpeedSelectField: FC<Props> = ({ packageType, distributionModel, value, onChange }) => {
  const options = (packageType === 'toPatient' ? PACKAGE1_TYPE_LABELS : PACKAGE2_TYPE_LABELS) as Record<
    PackageType,
    string
  >;

  return (
    <Space
      direction="vertical"
      data-testId={
        packageType === 'toPatient'
          ? `project-outbound-shipping-speed-(Tasso-→-patient)`
          : `project-inbound-shipping-speed-(patient-→-lab)`
      }
    >
      <Text strong>
        {packageType === 'toPatient' ? (
          <>Outbound shipping speed (Tasso &rarr; patient)</>
        ) : (
          <>Inbound shipping speed (patient &rarr; lab)</>
        )}
      </Text>

      {distributionModel === DISTRIBUTION_MODEL_SOURCES.atCustomerSite && packageType === 'toPatient' && (
        <Select value="none" disabled>
          <Option value="none" key="none">
            None
          </Option>
        </Select>
      )}

      {distributionModel === DISTRIBUTION_MODEL_SOURCES.atCustomerSite && packageType === 'toLab' && (
        <Select value="priority" disabled>
          <Option value="priority" key="priority">
            {PACKAGE2_TYPE_LABELS.priority}
          </Option>
        </Select>
      )}

      {distributionModel !== DISTRIBUTION_MODEL_SOURCES.atCustomerSite && (
        <Select onSelect={(v: PackageType) => onChange(v)} placeholder="Select shipping speed" showArrow value={value}>
          {Object.keys(options).map((k) => (
            <Option value={k} key={k}>
              {options[k as PackageType]}
            </Option>
          ))}
        </Select>
      )}
    </Space>
  );
};

export default ShippingSpeedSelectField;
