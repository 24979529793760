import React, { FC, useReducer, createContext } from 'react';

import { shipmentApi } from 'api';
import { getErrorMessage } from 'utils/issues';

import { MANIFEST } from 'constants/index';
import moment from 'moment';
import { Shipment } from '@tassoinc/core-api-client';
import { useManifestStatus } from './manifestStatus';

export const LABEL_STATUS = {
  success: 'success',
  warning: 'warning',
  error: 'error',
  inProgress: 'inProgress',
} as const;

export type LabelInfo = {
  shipmentId: string;
  status: keyof typeof LABEL_STATUS;
  message: string;
};

type State = {
  createMode: boolean;
  labels: LabelInfo[];
  disposition: '';
  showModal: true;
};

type Context = State & {
  createShippingLabelV2: (shipments: Shipment[], disposition?: string, showModal?: boolean) => Promise<void>;
  cancelShippingLabelV2: (shipments: Shipment[]) => Promise<void>;
  resetLabelsV2: () => void;
};

const getInitialState = (): State => ({ createMode: true, labels: [], disposition: '', showModal: true });

const ShippingLabelsV2Context = createContext<Context>({
  ...getInitialState(),
  createShippingLabelV2: async () => {},
  cancelShippingLabelV2: async () => {},
  resetLabelsV2: () => {},
});

const reducer = (state: State, action: any): State => {
  switch (action.type) {
    case 'ADD_CREATE_LABEL': {
      return {
        createMode: true,
        labels: [...state.labels, ...action.payload.labels],
        disposition: action.payload.disposition,
        showModal: action.payload.showModal ?? true,
      };
    }
    case 'ADD_CANCEL_LABEL':
      return {
        createMode: false,
        labels: [...state.labels, ...action.payload.labels],
        disposition: action.payload.disposition,
        showModal: true,
      };
    case 'SUCCESS': {
      const { shipmentId } = action.payload;
      const labelIndex = state.labels.findIndex((lbl) => lbl.shipmentId === shipmentId);

      return labelIndex > -1
        ? {
            ...state,
            labels: state.labels.map((lbl, idx) =>
              labelIndex === idx
                ? { ...lbl, status: LABEL_STATUS.success, message: action.payload.message }
                : { ...lbl }
            ),
          }
        : state;
    }
    case 'ERROR': {
      const { shipmentId } = action.payload;
      const labelIndex = state.labels.findIndex((lbl) => lbl.shipmentId === shipmentId);

      return labelIndex > -1
        ? {
            ...state,
            labels: state.labels.map((lbl, idx) =>
              labelIndex === idx ? { ...lbl, status: LABEL_STATUS.error, message: action.payload.message } : { ...lbl }
            ),
          }
        : state;
    }
    case 'UPDATE': {
      const { shipmentId } = action.payload;
      const labelIndex = state.labels.findIndex((lbl) => lbl.shipmentId === shipmentId);

      return labelIndex > -1
        ? {
            ...state,
            labels: state.labels.map((lbl, idx) =>
              labelIndex === idx
                ? { ...lbl, status: action.payload.status, message: action.payload.message || '' }
                : lbl
            ),
          }
        : state;
    }
    case 'RESET':
      return getInitialState();
    default:
      return state;
  }
};

const ShippingLabelsV2Provider: FC<any> = (props) => {
  const [state, dispatch] = useReducer(reducer, getInitialState());
  const { isManifestEnabled } = useManifestStatus();

  const createShippingLabelV2 = async (
    shipments: Shipment[],
    disposition: string,
    showModal?: boolean
  ): Promise<void> => {
    const formattedTodayDate = moment().format('YYYY-MM-DD');

    dispatch({
      type: 'ADD_CREATE_LABEL',
      payload: {
        labels: shipments.map((s) => ({ shipmentId: s.id, status: LABEL_STATUS.inProgress, message: '' })),
        disposition,
        message: '',
        status: LABEL_STATUS.inProgress,
        showModal: showModal ?? true,
        ...(isManifestEnabled && { toPatientShipDate: formattedTodayDate, toPatientShipOrigin: MANIFEST.origin }),
      },
    });

    const promises: Promise<any>[] = [];

    for (const shipment of shipments) {
      const shipmentId = shipment.id;

      if (!shipment.trackingNumber) {
        promises.push(
          shipmentApi.updateShipment(shipmentId, {
            status: 'labelRequested',
          })
        );
      } else {
        dispatch({ type: 'SUCCESS', payload: { shipmentId: shipment.id, message: 'Label is ready', disposition } });
      }
    }

    if (promises.length > 0) {
      const results = await Promise.allSettled(promises);

      for (const result of results) {
        if (result.status === 'fulfilled') {
          const shipment = result.value;
          dispatch({ type: 'SUCCESS', payload: { shipmentId: shipment.id, message: 'Label is ready', disposition } });
        } else {
          // `url` looks like: /shipments/108d2d97-4af0-42d1-b493-a552b9bb6e17
          const shipmentId = result.reason.config.url.split('/').pop();

          dispatch({
            type: 'ERROR',
            payload: { shipmentId, message: getErrorMessage(result.reason) },
          });
        }
      }
    }
  };

  const cancelShippingLabelV2 = async (shipments: Shipment[]): Promise<void> => {
    dispatch({
      type: 'ADD_CANCEL_LABEL',
      payload: { labels: shipments.map((s) => ({ shipmentId: s.id, status: LABEL_STATUS.inProgress, message: '' })) },
    });

    const promises: Promise<any>[] = [];

    for (const shipment of shipments) {
      const shipmentId = shipment.id;

      if (shipment.trackingNumber) {
        promises.push(shipmentApi.updateShipment(shipmentId, { status: 'labelCancelled' }));
      } else {
        dispatch({ type: 'SUCCESS', payload: { shipmentId, message: 'Label is cancelled' } });
      }
    }

    if (promises.length > 0) {
      const results = await Promise.allSettled(promises);

      for (const result of results) {
        if (result.status === 'fulfilled') {
          const shipment = result.value;
          dispatch({ type: 'SUCCESS', payload: { shipmentId: shipment.id, message: 'Label is cancelled' } });
        } else {
          // `url` looks like: /shipments/108d2d97-4af0-42d1-b493-a552b9bb6e17
          const shipmentId = result.reason.config.url.split('/').pop();

          dispatch({
            type: 'ERROR',
            payload: { shipmentId, message: getErrorMessage(result.reason) },
          });
        }
      }
    }
  };

  const resetLabelsV2 = (): void => {
    dispatch({ type: 'RESET' });
  };

  return (
    <ShippingLabelsV2Context.Provider
      value={{
        createMode: state.createMode,
        labels: state.labels,
        disposition: state.disposition,
        showModal: state.showModal,
        createShippingLabelV2,
        cancelShippingLabelV2,
        resetLabelsV2,
      }}
      {...props}
    />
  );
};

const useShippingLabelsV2 = (): Context => React.useContext(ShippingLabelsV2Context);

export { ShippingLabelsV2Provider, useShippingLabelsV2 };
