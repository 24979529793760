import React, { useState } from 'react';

import { Form } from 'antd';

import IdPoolModal from 'components/IdPoolModal';
import { IdPool, useIdPools } from 'context/idPools';
import { useCustomers } from 'context/customers';

type FinishedCallback = () => Promise<void>;

const useIdPoolModal = () => {
  const { createIdPool, updateIdPool } = useIdPools();
  const { getCustomers, customers, loading: customersLoading } = useCustomers();
  const [form] = Form.useForm();
  const [modalVisible, setModalVisible] = useState(false);
  const [modalPopulated, setModalPopulated] = useState(false);
  const [selectedIdPool, setSelectedIdPool] = useState<IdPool | null>(null);
  const [working, setWorking] = useState(false);
  const [finishedCallback, setFinishedCallback] = useState<FinishedCallback | null>(null);

  const openModal = () => {
    setModalVisible(true);

    if (customers.length === 0 && !customersLoading) {
      getCustomers();
    }
  };

  const closeModal = () => {
    setModalVisible(false);
    form.resetFields();
    setSelectedIdPool(null);
    setFinishedCallback(null);
  };

  const callback = () => {
    setWorking(false);
    setModalPopulated(true);
    closeModal();
  };

  const handleCreateIdPool = (data: { name: string; customerId: string }) => {
    setWorking(true);

    createIdPool(data, { hydrate: ['customer'] }, async () => {
      if (finishedCallback) {
        await finishedCallback();
      }
      callback();
    });
  };

  const handleUpdateIdPool = (id: string, data: { name: string; customerId: string }) => {
    setWorking(true);

    // changing of customerId is not allowed, so don't send it in the update request
    updateIdPool(id, { name: data.name }, { hydrate: ['customer'] }, async () => {
      if (finishedCallback) {
        await finishedCallback();
      }
      callback();
    });
  };

  const onCreateIdPool = (cb?: FinishedCallback) => {
    setModalPopulated(false);
    setSelectedIdPool(null);
    form.setFieldsValue({});
    setFinishedCallback(cb ? () => () => cb() : null);
    openModal();
  };

  const onEditIdPool = (idPool: IdPool, cb?: FinishedCallback) => {
    setModalPopulated(false);
    setSelectedIdPool(idPool);
    form.setFieldsValue(idPool);
    setFinishedCallback(cb ? () => cb() : null);
    openModal();
  };

  const Modal = (
    <IdPoolModal
      form={form}
      idPool={selectedIdPool}
      customers={customers}
      visible={modalVisible}
      onOk={selectedIdPool ? (data) => handleUpdateIdPool(selectedIdPool.id, data) : handleCreateIdPool}
      onCancel={closeModal}
      working={working}
      isLoadingData={customersLoading}
    />
  );

  return { Modal, onCreateIdPool, onEditIdPool, modalPopulated };
};

export default useIdPoolModal;
