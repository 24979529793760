/* eslint-disable no-lonely-if */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { FC, useEffect, useState } from 'react';

import { Form, Button, Input, Select, Switch, Row, Divider, Tabs } from 'antd';

import { SectionHeader } from 'components';
import { ROUTES, USER_ROLES } from 'constants/index';
import { useAuth } from 'context/auth';
import { useCustomers } from 'context/customers';
import { IDevice, useDevices } from 'context/devices';
import { IPatient, usePatients } from 'context/patients';
import { useProjects } from 'context/projects';
import Notification from 'utils/notification';

import PatientList from './PatientList';
import DeviceList from './DeviceList';
import FulfillmentOrderSearch from './FulfillmentOrderSearch';

const PatientSearch: FC = () => {
  const { profile } = useAuth();
  const [form] = Form.useForm();

  const { customers, getCustomers, resetCustomers, loading: customersLoading } = useCustomers();
  const { patients, getPatients, resetPatients, loading: patientsLoading } = usePatients();
  const { projects, getProjects, resetProjects, loading: projectsLoading } = useProjects();
  const {
    devices,
    getDevices,
    resetDevices,
    addDeviceData,
    loading: devicesLoading,
    setLoading: setDevicesLoading,
  } = useDevices();

  const [searched, setSearched] = useState(false);

  const isAdmin = (profile?.role || '') === USER_ROLES.internalAdmin;
  const isCustomerService = (profile?.role || '') === USER_ROLES.internalCustomerService;
  const isShipper = (profile?.role || '') === USER_ROLES.internalShipper;
  const isSearching = patientsLoading || devicesLoading;

  const breadcrumbNameMap = {
    '/': 'Home',
    [ROUTES.patientSearch]: 'Device and patient search',
  };

  useEffect(() => {
    getCustomers();
    getProjects(false);

    return () => {
      resetCustomers();
      resetPatients();
      resetProjects();
      resetDevices();
    };
  }, []);

  const stopSearch = () => {
    resetPatients();
    resetDevices();
    setSearched(false);
  };

  const looksLikeHRID = (value: string): boolean => {
    if (value.length !== 10) {
      return false;
    }

    const v = parseInt(value, 16);
    return v.toString(16).padStart(10, '0').toUpperCase() === value;
  };

  const findDevicesByBarcodeOrTrackingNumber = async ({
    barcode,
    barcodeExact,
    trackingNumber,
    trackingNumberExact,
    activationCode,
    activationCodeExact,
    projectId,
    customerId,
  }: {
    barcode: string;
    barcodeExact: boolean;
    trackingNumber: string;
    trackingNumberExact: boolean;
    activationCode: string;
    activationCodeExact: boolean;
    projectId: string;
    customerId: string;
  }): Promise<IDevice[] | null> => {
    if (!barcode && !trackingNumber && !activationCode) {
      return [];
    }

    const results: IDevice[] = [];

    if (barcode && barcode.length < 3) {
      Notification({
        type: 'error',
        message: 'Barcode is too short',
        description: 'Enter at least 3 characters to search by barcode',
      });
      return null;
    }

    if (trackingNumber && trackingNumber.length < 3) {
      Notification({
        type: 'error',
        message: 'Tracking number is too short',
        description: 'Enter at least 3 characters to search by tracking number',
      });
      return null;
    }

    if (activationCode && activationCode.length < 3) {
      Notification({
        type: 'error',
        message: 'Activation code is too short',
        description: 'Enter at least 3 characters to search by activation code',
      });
      return null;
    }

    const q: any = {};

    if (barcode) {
      if (barcodeExact) {
        q.barcode = barcode;
      } else {
        q.barcodeLike = barcode;
      }
    }

    if (activationCode) {
      if (activationCodeExact) {
        q.activationCode = activationCode;
      } else {
        q.activationCodeLike = activationCode;
      }
    }

    // First search to patient tracking numbers and later, separately, by to lab tracking numbers.
    if (trackingNumber) {
      if (trackingNumberExact) {
        q.trackingNumberToPatient = trackingNumber;
      } else {
        q.trackingNumberToPatientLike = trackingNumber;
      }
    }

    if (projectId) {
      q.projectIds = projectId;
    }

    if (customerId) {
      q.customerIds = customerId;
    }

    setDevicesLoading(true);

    // This search will include searching by "to patient" tracking numbers
    await getDevices(
      q,
      async (foundDevices: IDevice[]) => {
        results.push(...foundDevices);
      },
      true
    );

    // Search to lab tracking numbers separately and merge search results
    if (trackingNumber) {
      delete q.trackingNumberToPatient;
      delete q.trackingNumberToPatientLike;

      if (trackingNumberExact) {
        q.trackingNumberToLab = trackingNumber;
      } else {
        q.trackingNumberToLabLike = trackingNumber;
      }

      await getDevices(
        q,
        async (foundDevicesResults: IDevice[] | null) => {
          const foundDevices = foundDevicesResults || [];
          // Because this getDevices call overwrote the devices state, we must restore devices that were found earlier
          const deviceIdsInState = foundDevices.map((d) => d.id);
          const missingDevices = results.filter((earlierDevice) => !deviceIdsInState.includes(earlierDevice.id));
          addDeviceData(missingDevices);
        },
        true
      );
    }

    const deviceHash = results.reduce<Record<string, boolean>>((acc, val) => ({ ...acc, [val.id]: true }), {});

    if (barcode && looksLikeHRID(barcode)) {
      delete q.barcode;
      delete q.barcodeLike;

      q.hrids = barcode;

      await getDevices(
        q,
        async (foundDevices: IDevice[]) => {
          for (let i = 0; i < foundDevices.length; i += 1) {
            if (!(foundDevices[i].id in deviceHash)) {
              results.push(foundDevices[i]);
              deviceHash[foundDevices[i].id] = true;
            }
          }
        },
        true
      );
    }

    setDevicesLoading(false);

    return results;
  };

  const showTooShortError = () =>
    Notification({
      type: 'error',
      message: 'Search query is too short',
      description: 'Enter at least 3 characters to search',
    });

  const performSearch = async (values: any) => {
    if (isSearching) {
      return;
    }

    stopSearch();

    const paramsToCheck = ['firstName', 'lastName', 'email', 'subjectId'];

    const queryParams: any = {};

    for (let i = 0; i < paramsToCheck.length; i += 1) {
      const checkParam = paramsToCheck[i];
      const value = (values[checkParam] || '').trim();

      if (value) {
        if (values[`${checkParam}Exact`]) {
          queryParams[checkParam] = value;
        } else {
          queryParams[`${checkParam}Like`] = value;
        }
      }
    }
    if (values.deviceBarcode || values.trackingNumber || values.deviceActivationCode) {
      // search devices first, then lookup patients
      const foundDevices = await findDevicesByBarcodeOrTrackingNumber({
        barcode: values.deviceBarcode,
        barcodeExact: !!values.deviceBarcodeExact,
        trackingNumber: values.trackingNumber,
        trackingNumberExact: !!values.trackingNumberExact,
        activationCode: values.deviceActivationCode,
        activationCodeExact: !!values.deviceActivationCodeExact,
        projectId: values.projectId,
        customerId: values.customerId,
      });

      if (!foundDevices) {
        setSearched(true);
        return;
      }

      const patientIds = foundDevices.map((d) => d.patientId).filter((id) => !!id);
      if (patientIds.length > 0) {
        const uniquePatientIds = [...new Set(patientIds)];
        queryParams.ids = uniquePatientIds.join(',');
      }

      if (Object.keys(queryParams).length > 0) {
        if (values.projectId) {
          queryParams.projectIds = values.projectId;
        }

        if (values.customerId) {
          queryParams.customerIds = values.customerId;
        }

        await getPatients(queryParams);
      }
    } else {
      // lookup patients first, then find devices
      const keys = Object.keys(queryParams);

      if (keys.length === 0) {
        showTooShortError();
        return;
      }

      if (keys.length === 1) {
        // disallow single field searches that are shorter than 3 characters
        const trimmedKey = keys[0].replace(/Like$/, '');
        if (paramsToCheck.includes(trimmedKey)) {
          const value = queryParams[keys[0]];
          if (value.length < 3) {
            showTooShortError();
            return;
          }
        }
      }

      if (values.projectId) {
        queryParams.projectIds = values.projectId;
      }

      if (values.customerId) {
        queryParams.customerIds = values.customerId;
      }

      const foundPatients: IPatient[] = [];
      await getPatients(queryParams, async (results) => {
        if (results) {
          foundPatients.push(...results);
        }
      });

      const patientIds = foundPatients.map((p) => p.id);
      if (patientIds.length > 0) {
        await getDevices({ patientIds: patientIds.join(',') });
      }
    }

    setSearched(true);
  };

  const resetForm = (): void => {
    resetPatients();
    form.resetFields();
    setSearched(false);
  };

  return (
    <div style={{ padding: '0 0 40px 0' }}>
      <SectionHeader
        breadcrumbNameMap={breadcrumbNameMap}
        isAdmin={isAdmin}
        isCustomerService={isCustomerService}
        isShipper={isShipper}
      />

      <h1>Find devices and patients by...</h1>
      <Tabs>
        <Tabs.TabPane tab="Device/Patient" key="devices">
          <Form
            form={form}
            size="middle"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 12 }}
            layout="horizontal"
            initialValues={{
              firstName: '',
              firstNameExact: false,
              lastName: '',
              lastNameExact: false,
              email: '',
              emailExact: false,
              subjectId: '',
              subjectIdExact: false,
              projectId: '',
              customerId: '',
              deviceBarcode: '',
              deviceBarcodeExact: false,
              deviceActivationCode: '',
              deviceActivationCodeExact: false,
              trackingNumber: '',
              trackingNumberExact: false,
            }}
            labelAlign="left"
            onFinish={performSearch}
          >
            <Form.Item label="patient's first name">
              <Input.Group>
                <Row align="middle">
                  <Form.Item name="firstName" noStyle>
                    <Input style={{ width: '70%' }} />
                  </Form.Item>
                  <Form.Item name="firstNameExact" noStyle>
                    <Switch
                      style={{ marginLeft: 10 }}
                      size="small"
                      checkedChildren="exact"
                      unCheckedChildren="exact"
                      title="Use exact matching"
                    />
                  </Form.Item>
                </Row>
              </Input.Group>
            </Form.Item>
            <Form.Item label="patient's last name">
              <Row align="middle">
                <Form.Item name="lastName" noStyle>
                  <Input style={{ width: '70%' }} />
                </Form.Item>
                <Form.Item name="lastNameExact" noStyle>
                  <Switch
                    style={{ marginLeft: '10px' }}
                    size="small"
                    checkedChildren="exact"
                    unCheckedChildren="exact"
                    title="Use exact matching"
                  />
                </Form.Item>
              </Row>
            </Form.Item>
            <Form.Item label="patient's email">
              <Input.Group>
                <Row align="middle">
                  <Form.Item name="email" noStyle>
                    <Input style={{ width: '70%' }} />
                  </Form.Item>
                  <Form.Item name="emailExact" noStyle>
                    <Switch
                      style={{ marginLeft: '10px' }}
                      size="small"
                      checkedChildren="exact"
                      unCheckedChildren="exact"
                      title="Use exact matching"
                    />
                  </Form.Item>
                </Row>
              </Input.Group>
            </Form.Item>
            <Form.Item label="subject id">
              <Input.Group>
                <Row align="middle">
                  <Form.Item name="subjectId" noStyle>
                    <Input style={{ width: '70%' }} />
                  </Form.Item>
                  <Form.Item name="subjectIdExact" noStyle>
                    <Switch
                      style={{ marginLeft: 10 }}
                      size="small"
                      checkedChildren="exact"
                      unCheckedChildren="exact"
                      title="Use exact matching"
                    />
                  </Form.Item>
                </Row>
              </Input.Group>
            </Form.Item>
            <Form.Item label="device barcode">
              <Input.Group>
                <Row align="middle">
                  <Form.Item name="deviceBarcode" noStyle>
                    <Input style={{ width: '70%' }} />
                  </Form.Item>
                  <Form.Item name="deviceBarcodeExact" noStyle>
                    <Switch
                      style={{ marginLeft: 10 }}
                      size="small"
                      checkedChildren="exact"
                      unCheckedChildren="exact"
                      title="Use exact matching"
                    />
                  </Form.Item>
                </Row>
              </Input.Group>
            </Form.Item>
            <Form.Item label="device activation code">
              <Input.Group>
                <Row align="middle">
                  <Form.Item name="deviceActivationCode" noStyle>
                    <Input style={{ width: '70%' }} />
                  </Form.Item>
                  <Form.Item name="deviceActivationCodeExact" noStyle>
                    <Switch
                      style={{ marginLeft: 10 }}
                      size="small"
                      checkedChildren="exact"
                      unCheckedChildren="exact"
                      title="Use exact matching"
                    />
                  </Form.Item>
                </Row>
              </Input.Group>
            </Form.Item>
            <Form.Item label="tracking number">
              <Input.Group>
                <Row align="middle">
                  <Form.Item name="trackingNumber" noStyle>
                    <Input style={{ width: '70%' }} />
                  </Form.Item>
                  <Form.Item name="trackingNumberExact" noStyle>
                    <Switch
                      style={{ marginLeft: 10 }}
                      size="small"
                      checkedChildren="exact"
                      unCheckedChildren="exact"
                      title="Use exact matching"
                    />
                  </Form.Item>
                </Row>
              </Input.Group>
            </Form.Item>
            <Form.Item label="customer" name="customerId">
              <Select
                style={{ width: '70%' }}
                loading={customersLoading}
                placeholder="All customers"
                onClear={() => setTimeout(() => form.resetFields(['customerId']))}
                allowClear
                showSearch
                filterOption={(input, option) =>
                  `${option?.label || ''}`.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                filterSort={(optionA, optionB) =>
                  `${optionA?.label || ''}`.toLowerCase().localeCompare(`${optionB?.label || ''}`.toLowerCase())
                }
                options={[
                  { value: '', label: 'All customers' },
                  ...customers.map((p) => ({ value: p.id, label: p.name })),
                ]}
              />
            </Form.Item>
            <Form.Item label="project" name="projectId">
              <Select
                style={{ width: '70%' }}
                loading={projectsLoading}
                placeholder="All projects"
                onClear={() => setTimeout(() => form.resetFields(['projectId']))}
                allowClear
                showSearch
                filterOption={(input, option) =>
                  `${option?.label || ''}`.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                filterSort={(optionA, optionB) =>
                  `${optionA?.label || ''}`.toLowerCase().localeCompare(`${optionB?.label || ''}`.toLowerCase())
                }
                options={[
                  { value: '', label: 'All projects' },
                  ...projects.map((p) => ({ value: p.id, label: p.name })),
                ]}
              />
            </Form.Item>

            <Form.Item colon={false} style={{ textAlign: 'right' }} labelCol={{ span: 4 }} label=" ">
              <div style={{ width: '70%' }}>
                <Button type="text" onClick={resetForm} style={{ marginRight: 10 }} htmlType="button">
                  Reset
                </Button>
                <Button type="primary" htmlType="submit" loading={isSearching} disabled={isSearching}>
                  Search
                </Button>
              </div>
            </Form.Item>
          </Form>

          {searched && <Divider />}

          {isSearching && (
            <h2>
              Searching...
              <Button style={{ marginLeft: 15 }} size="small" onClick={stopSearch}>
                Cancel
              </Button>
            </h2>
          )}
          {searched && !isSearching && (
            <h2>
              {patients.length > 50 || devices.length > 50 ? (
                <>
                  Showing only top 50 matches of {devices.length} device{devices.length === 1 ? '' : 's'} and{' '}
                  {patients.length} patient{patients.length === 1 ? '' : 's'} (refine your search)
                </>
              ) : (
                <>
                  Found {devices.length} device{devices.length === 1 ? '' : 's'} and {patients.length} patient
                  {patients.length === 1 ? '' : 's'}
                </>
              )}
            </h2>
          )}
          {searched && (patients.length > 0 || devices.length > 0) && (
            <>
              <Tabs
                defaultActiveKey="devices"
                tabBarExtraContent={{ left: <span>Show results by </span> }}
                className="result_tabs"
              >
                <Tabs.TabPane tab="Device" key="devices">
                  <DeviceList
                    patients={patients.slice(0, 50)}
                    devices={devices.slice(0, 50)}
                    projects={projects}
                    customers={customers}
                    loading={isSearching}
                  />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Patient" key="patients">
                  <PatientList
                    patients={patients.slice(0, 50)}
                    projects={projects}
                    customers={customers}
                    loading={isSearching}
                  />
                </Tabs.TabPane>
              </Tabs>
            </>
          )}
        </Tabs.TabPane>
        <Tabs.TabPane tab="Order/3PL" key="fulfillmentOrder">
          <FulfillmentOrderSearch />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
};

export default PatientSearch;
