/* eslint-disable jsx-a11y/anchor-is-valid,jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events */
import React, { useEffect, useRef, useState } from 'react';

import { Button, Form, FormInstance, Input, Modal } from 'antd';
import TextConfigHelpModal from 'components/TextConfigHelpModal';

import { QuestionCircleOutlined } from '@ant-design/icons';

interface Props {
  open: boolean;
  value: Record<string, any>[] | null;
  onCancel: () => void;
  onSave: (textConfig: Record<string, any>[] | null) => void;
}

const TextConfigModal: React.FC<Props> = ({ open, value, onCancel, onSave }) => {
  const form = useRef<FormInstance>(null);

  const [showTextConfigHelp, setShowTextConfigHelp] = useState(false);
  const [canSave, setCanSave] = useState(false);

  const isValidConfig = (config: any): boolean => {
    try {
      const json = JSON.parse(`${config}`);
      if (!json || !Array.isArray(json)) {
        return false;
      }
      return true;
    } catch (_e) {
      return false;
    }
  };

  const handleCancel = (): void => {
    form.current!.resetFields();
    onCancel();
  };

  const handleSave = async (): Promise<void> => {
    if (!canSave) {
      return;
    }

    form.current!.validateFields().then((values) => {
      onSave(values.textConfig ? JSON.parse(values.textConfig) : null);
      handleCancel();
    });
  };

  useEffect(() => {
    if (open) {
      form.current!.setFieldsValue({ textConfig: value ? JSON.stringify(value, undefined, 2) : undefined });
      form.current!.validateFields();
    }
  }, [open]);

  return (
    <Modal
      wrapClassName="Modal"
      title="Text overlay configuration"
      className="KitItemTextConfigModal"
      visible={open}
      onCancel={handleCancel}
      onOk={handleSave}
      footer={[
        <Button
          size="small"
          icon={<QuestionCircleOutlined />}
          type="link"
          className="HelpButton"
          onClick={() => setShowTextConfigHelp((s) => !s)}
        >
          Help
        </Button>,
        <Button onClick={handleCancel}>Cancel</Button>,
        <Button type="primary" onClick={handleSave} disabled={!canSave}>
          Save
        </Button>,
      ]}
    >
      <Form ref={form} className="TextConfigForm">
        <Form.Item
          name="textConfig"
          rules={[
            () => ({
              validator(_, v) {
                if (!v || isValidConfig(v)) {
                  setCanSave(true);
                  return Promise.resolve();
                }
                setCanSave(false);
                return Promise.reject(new Error('Invalid configuration object'));
              },
            }),
          ]}
        >
          <Input.TextArea rows={3} placeholder="Enter overlay text configuration" />
        </Form.Item>
      </Form>
      <TextConfigHelpModal open={showTextConfigHelp} onClose={() => setShowTextConfigHelp(false)} />
    </Modal>
  );
};

export default TextConfigModal;
