import {
  Configuration,
  OrderTemplateApi,
  OrderTemplateApiListOrderTemplatesRequest,
  OrderTemplate,
  OrderTemplateResponsePropertiesItemsInner,
  OrderTemplateCreate,
  OrderTemplateUpdate,
} from '@tassoinc/core-api-client';
import httpClient from 'services/httpClient';
import { PagingResponse, PagingRequest } from 'utils/types';

export type OrderTemplateResponse = {
  paging: PagingResponse;
  results: Array<OrderTemplate>;
};

let apiClient: OrderTemplateApi | undefined;

async function getClient(): Promise<OrderTemplateApi> {
  if (apiClient) {
    return apiClient;
  }
  const config = new Configuration({
    basePath: process.env.API_URL,
  });
  apiClient = new OrderTemplateApi(config, undefined, httpClient);
  return apiClient;
}

export async function get(
  model: OrderTemplateApiListOrderTemplatesRequest,
  paging: PagingRequest
): Promise<OrderTemplateResponse> {
  const client = await getClient();
  if (!model.projectIds) {
    throw Error('ProjectIds is required');
  }
  const result = await client.listOrderTemplates({
    projectIds: model.projectIds,
    page: paging.page,
    pageLength: paging.pageLength,
  });

  const response = {
    paging: {
      page: result.data.paging?.page ?? 1,
      pageLength: result.data.paging?.pageLength ?? 50,
      isDescending: (result.data.paging ?? ({} as any)).isDescending ?? false,
      sortBy: (result.data.paging ?? ({} as any)).sortBy ?? '',
    },
    results: result.data.results ?? [],
  };

  return response;
}

export async function getById(id: string): Promise<OrderTemplate | undefined> {
  const client = await getClient();
  const result = await client.getOrderTemplate({ id });
  return result.data.results;
}

/**
 * Recursively removes orderTemplateId from every item and returns.
 *
 * Returns a new object without modifying the input.
 */
const exciseOrderTemplateId = (
  items: OrderTemplateResponsePropertiesItemsInner[]
): Omit<OrderTemplateResponsePropertiesItemsInner, 'orderTemplateId'>[] =>
  [...items].map((item) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { orderTemplateId, ...rest } = item;

    if ((rest as any).items) {
      (rest as any).items = exciseOrderTemplateId((rest as any).items);
    }

    return rest;
  });

export const insertOrderTemplate = async (body: OrderTemplateCreate): Promise<OrderTemplate> => {
  const client = await getClient();

  const payload = { ...body };

  if (body.items) {
    payload.items = exciseOrderTemplateId(body.items);
  }

  const result = await client.createOrderTemplate({
    orderTemplateCreate: payload as any,
  });

  return result.data.results!;
};

export const updateOrderTemplate = async (id: string, body: OrderTemplateUpdate): Promise<OrderTemplate> => {
  const client = await getClient();

  const payload = { ...body };

  if (payload.items) {
    payload.items = exciseOrderTemplateId(payload.items);
  }

  const result = await client.updateOrderTemplate({
    id,
    orderTemplateUpdate: payload,
  });

  return result.data.results!;
};
